define('tinybeans-frontend/controllers/reset-password', ['exports', 'tinybeans-frontend/controllers/base-controller'], function (exports, _baseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    tokenValid: false,

    actions: {
      gotoResetPasswordPage: function gotoResetPasswordPage() {
        var self = this;
        self.transitionToRoute('reset-password-request');
      },

      save: function save() {
        var self = this;
        var error = {};
        if (!self.get('password') || self.get('password').length === 0) {
          error.global = 'Password cannot be empty';
          self.set('error', error);
        } else if (self.get('password') != self.get('passwordConfirm')) {
          error.global = 'Passwords must match';
          self.set('error', error);
        } else {
          this.API.resetPassword(this.get('password'), this.get('model.token'), function () {
            Ember.run.next(function () {
              self.transitionToRoute('login');
            });
          }, function () {
            error.global = 'Your password could not be reset. Please request another password reset and try again.';
            self.set('error', error);
          });
        }
      }

    }
  });
});