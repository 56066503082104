define('tinybeans-frontend/initializers/sentry', ['exports', 'tinybeans-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  // Allows us to access Ember ENV variable

  function initialize() /* application */{
    var env = _environment.default.environment; // our environment settings
    var defaults = { // default values in case 
      enableSentry: false,
      sampleRate: 0.1,
      tracesRate: 0,
      limitToApp: true,
      attachStacktrace: false
    };
    Leanplum.addVariablesChangedHandler(function () {
      // this handler fires when Leanplum and variables are ready
      var vars = Leanplum.getVariables(); // hold all the Leanplum variables
      var sentry_vars = vars.SentryEmber; // the specific Sentry variables we want

      // combine defaults in case Leanplum is being strange
      sentry_vars = _extends({}, defaults, sentry_vars);

      var blacklist_messages = vars.SentryEmber.blacklistErrors.messages; // hold the blacklist
      if (blacklist_messages) {
        // get the values of the blacklist array if it exists
        blacklist_messages = Object.values(blacklist_messages);
        // console.log(blacklist_messages);
      }
      if (sentry_vars.enableSentry) {
        // only init sentry if the feature flag is set
        console.log('loading sentry');
        console.log(sentry_vars);
        var debug = env !== 'production' ? true : false;
        try {
          Sentry.init({ // init sentry
            allowUrls: [/https?:\/\/((hinata|koji)\.)?tinybeans\.com/], // limit to our domain
            debug: debug,
            attachStacktrace: sentry_vars.attachStackTrace,
            sampleRate: sentry_vars.sampleRate,
            tracesSampleRate: sentry_vars.tracesRate,
            environment: env,
            dsn: 'https://f82b18fa873544058c20070c49bc3458@o335820.ingest.us.sentry.io/5202824',
            release: 'Tinybeans Ember App 1.2.0', beforeSend: function beforeSend(event, hint) {
              // Modify or drop the event here
              var error = hint.originalException; // hints hold useful info about errors
              if (error && error.message && blacklist_messages.length && blacklist_messages.includes(error.message)) {
                // suppress the error and prevent it from transmitting to sentry if it matches a blacklisted
                // phrase or sting  
                console.log("Supressing error from blacklist: " + error.message);
                return false;
              }

              return event; // return the event otherwise
            }
          });
        } catch (e) {
          console.log("sentry failed to load");
          console.log(sentry_vars);
        }
      } else {
        console.log('sentry disabled');
        console.log(sentry_vars);
      }
    });
  }

  exports.default = {
    after: 'leanplum', // try to load this after the Leanplum initializer
    initialize: initialize
  };
});