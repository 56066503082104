define('tinybeans-frontend/routes/reset-password', ['exports', 'tinybeans-frontend/routes/base-route', 'tinybeans-frontend/tracker'], function (exports, _baseRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    category: _tracker.ResetPassword.category,
    screen: _tracker.ResetPassword.screen,

    model: function model(params) {
      var accessToken = this.get('session.access_token') ? this.get('session.access_token') : null;
      var token = params.token;
      return Ember.RSVP.hash({
        token: token,
        tokenValid: this.API.validateResetPasswordToken(token),
        password: null,
        passwordConfirm: null,
        accessToken: accessToken
      });
    },

    afterModel: function afterModel(model) {
      if (model.accessToken) {
        this.get('session').invalidate();
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('tokenValid', model.tokenValid);
    }

  });
});