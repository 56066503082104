define('tinybeans-frontend/serializers/collection-entry', ['exports', 'ember-data/serializers/rest', 'ember-data/serializers/embedded-records-mixin'], function (exports, _rest, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _rest.default.extend(_embeddedRecordsMixin.default, {

    attrs: {
      entry: { embedded: 'always' }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      delete payload.status; // Status messes up the JSON parser

      if (payload.tag) {
        // If we fetched via the collection we need to finagle it as follows
        payload.collectionEntries = payload.tag.tagEntries;
        delete payload.tag;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  }

  // normalize(model, payload, prop) {
  //   if ( payload.entry ) { // Entry can come back as an id or full entry depending on the call made, just use entryId for consistency
  //     payload.entryId = payload.entry.id;
  //     delete payload.entry;
  //   }
  //
  //   if ( payload.entryId ) { // Make the name nicer
  //     payload.entry = payload.entryId;
  //     delete payload.entry;
  //   }
  //
  //   if ( payload.tagId ) {
  //     payload.tag = payload.tagId;
  //     delete payload.tagId;
  //   }
  //   return this._super(model, payload, prop);
  // }
  );
});