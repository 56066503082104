define("tinybeans-frontend/components/main/annoucement-popup", ["exports", "tinybeans-frontend/tracker"], function (exports, _tracker) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    submitting: false,

    init: function init() {
      this._super();
    },


    isMobile: Ember.computed(function () {
      return this.get("isMobile");
    }),

    actions: {
      close: function close() {
        this.send("setCookie");
      },
      onClick: function onClick() {
        if (typeof AF !== "undefined") AF('pba', 'event', { eventType: 'EVENT', eventValue: { 'label': 'Click on "Start Your Free Trial"', 'from_page': 'Journal Month' }, eventName: 'Click on "Start Your Free Trial"' });

        _tracker.Tracker.trackEvent({
          category: _tracker.Premium.category,
          screen: _tracker.Premium.list.screen,
          action: _tracker.Premium.list.startTrial
        });

        window.location.hash = '#/main/subscription-products/list';
        this.send("setCookie");
      },


      readCookie: function readCookie(key) {
        var value = document.cookie.match(new RegExp(key + "=([^;]+)")) || [];
        return decodeURIComponent(value[1] || "");
      },

      writeCookie: function writeCookie(key, value, expiration) {
        var expires = Ember.isEmpty(expiration) ? "" : "; expires=" + new Date(expiration).toUTCString();
        var secure = !!this._secureCookies ? ";secure" : "";
        var path = ";path=/";
        document.cookie = key + "=" + encodeURIComponent(value) + expires + secure + path;
      },

      setCookie: function setCookie() {
        this.send("writeCookie", "showedAnnouncementPopup", "true");
      }
    }
  });
});