define('tinybeans-frontend/controllers/main/subscription-products/list', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/tracker', 'tinybeans-frontend/config/environment'], function (exports, _baseController, _tracker, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    queryParams: ['coupon', 'isLegacy'],
    isLegacy: false,
    coupon: null,
    monthlyPriceId: "",
    annualPriceId: "",
    publishableKey: "",

    init: function init() {
      var _this = this;

      this._super();
      Ember.run.schedule("afterRender", this, function () {
        // this.set('dailyPriceId', ENV.stripeDailyPrice);
        _this.set('publishableKey', _environment.default.stripeSubKey);
      });
    },

    isStage: Ember.computed(function () {
      var current_url = window.location.host;
      if (current_url == "tinybeans.com") {
        return false;
      } else {
        return true;
      }
    }),

    createCheckoutSession: function createCheckoutSession(priceId) {
      var _this2 = this;

      var stripe = new Stripe(this.get('publishableKey'));
      var coupon = this.get('coupon');
      this.API.createCheckoutSession(priceId, coupon, function (data) {
        localStorage.setItem('currentPriceId', priceId);
        localStorage.setItem('sessionId', data.sessionId);
        stripe.redirectToCheckout({
          sessionId: data.sessionId
        }).then(_this2.handleResult);
      });
    },

    handleResult: function handleResult(result) {
      if (result.error) {
        this.showErrorMessage(result.error.message);
      }
    },

    handleFetchResult: function handleFetchResult(result) {
      var _this3 = this;

      if (!result.ok) {
        return result.json().then(function (json) {
          if (json.error && json.error.message) {
            throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
          }
        }).catch(function (err) {
          _this3.showErrorMessage(err);
          throw err;
        });
      }
      return result.json();
    },

    showErrorMessage: function showErrorMessage(message) {
      var error = void 0;
      error = {};
      error.name = message;
    },

    actions: {
      createCheckoutMonthly: function createCheckoutMonthly() {
        var priceId = this.get('monthlyPriceId');
        var self = this;

        self.GTM.trackEvent({
          'eventCategory': 'premium',
          'eventAction': 'subscription monthly button',
          'eventLabel': 'web'
        });

        var action = 'subscription monthly button';

        _tracker.Tracker.trackEvent({
          category: _tracker.Premium.list.category,
          screen: _tracker.Premium.list.screen,
          action: action
        });
        this.createCheckoutSession(priceId);
      },
      createCheckoutAnnual: function createCheckoutAnnual() {
        var priceId = this.get('annualPriceId');
        var self = this;

        self.GTM.trackEvent({
          'eventCategory': 'premium',
          'eventAction': 'subscription annual button',
          'eventLabel': 'web'
        });

        var action = 'subscription yearly button';

        _tracker.Tracker.trackEvent({
          category: _tracker.Premium.list.category,
          screen: _tracker.Premium.list.screen,
          action: action
        });
        this.createCheckoutSession(priceId);
      },
      billingInformation: function billingInformation() {
        this.API.billingSession().then(function (res) {
          window.location.replace(res.portalURL);
        });
      },
      scrollTop: function scrollTop() {
        Ember.$('html, body').animate({
          scrollTop: 0
        }, 500, "linear");
      },
      goToRedeemGiftCard: function goToRedeemGiftCard() {
        this.transitionToRoute("main.gift-card.redeem-gift-card");
      }
    }
  });
});