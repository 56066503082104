define('tinybeans-frontend/routes/login', ['exports', 'tinybeans-frontend/routes/base-route', 'tinybeans-frontend/tracker'], function (exports, _baseRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    category: _tracker.Login.category,
    screen: _tracker.Login.screen,
    session: Ember.inject.service('session'),

    beforeModel: function beforeModel(params) {
      if (this.get('session.isAuthenticated')) {
        if (params.queryParams.from) {
          window.location.href = params.queryParams.from;
        } else {
          this.transitionTo('main.journals.home');
        }
      }
    },

    model: function model() {
      return null;
    },

    afterModel: function afterModel() {
      setTimeout(function () {
        Ember.$('input').each(function (index, element) {
          Ember.$(element).val('');
        });
      }, 250);
    }
  });
});