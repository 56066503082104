define('tinybeans-frontend/controllers/main/children/milestones/complete-milestone', ['exports', 'consumer-web-cli/controllers/main/children/milestones/milestone-completion-base'], function (exports, _milestoneCompletionBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _milestoneCompletionBase.default.extend({
    me: null,
    child: null,
    milestone: null,
    completedChecklistItems: [],

    showSpecificDateOption: function () {
      return this.get('dateSelectionValue') == 'selectDate';
    }.property('dateSelectionValue'),

    descriptionWithLineBreaks: function () {
      var milestone = this.get('milestone');
      var safelyEscapedCaption = Ember.Handlebars.Utils.escapeExpression(milestone.description);
      var linebrokenCaption = safelyEscapedCaption.replace(/(?:\r\n|\r|\n)/g, '<br />');
      return new Ember.Handlebars.SafeString(linebrokenCaption);
    }.property('milestone'),

    isChecked: function () {
      var checklistItem = this.get('milestone');
      var completedChecklistItems = this.get('completedChecklistItems') || [];
      for (var i = 0; i < completedChecklistItems.length; i++) {
        var completedChecklistItem = completedChecklistItems[i];
        if (!completedChecklistItem.deleted && completedChecklistItem.checklistItemId === checklistItem.id) {
          return true;
        }
      }
      return false;
    }.property('completedChecklistItems', 'completedChecklistItems.@each'),

    completionDate: function () {
      var self = this;
      var completedMilestone = self.get('completedMilestone');
      if (completedMilestone) {
        var day = completedMilestone.day;
        var month = completedMilestone.month;
        var year = completedMilestone.year;
        if (day && month && year) {
          var dateFormat = self.globals.usersDateFormat(self.get('me.dateFormat'));
          var formattedDate = moment().year(year).month(month - 1).date(day).format(dateFormat);
          return formattedDate;
        }
      }
      return '';
    }.property('completedChecklistItems', 'completedChecklistItems.@each'),

    completedMilestone: function () {
      var checklistItem = this.get('milestone');
      var completedChecklistItems = this.get('completedChecklistItems') || [];
      for (var i = 0; i < completedChecklistItems.length; i++) {
        var completedChecklistItem = completedChecklistItems[i];
        if (!completedChecklistItem.deleted && completedChecklistItem.checklistItemId === checklistItem.id) {
          return completedChecklistItem;
        }
      }
      return;
    }.property('completedChecklistItems', 'completedChecklistItems.@each'),

    usersDateFormat: function () {
      return this.globals.usersDateFormat(this.get('me.dateStyle.format'));
    }.property('me.dateStyle.format'),

    saveDateChange: function saveDateChange(date) {
      var self = this;
      var child = self.get('child');
      var milestone = self.get('milestone');
      // If we have a date
      if (date) {
        // Set the milestones day, month, year properties
        milestone.day = date.date();
        milestone.month = date.month() + 1;
        milestone.year = date.year();
      } else {
        // Set the milestones day, month, year properties
        milestone.day = undefined;
        milestone.month = undefined;
        milestone.year = undefined;
      }

      self.API.addCompletedChecklistItem(child.id, milestone, function (data) {
        var completedChecklistItems = self.get('completedChecklistItems');
        completedChecklistItems.pushObject(data.completedChecklistItem);
        self.set('completedChecklistItems', completedChecklistItems);
        self.route.disconnectOutlet({ outlet: 'modal-dialog' });
        Ember.$(".modal-backdrop").hide();
      });
    },

    actions: {
      toggleCheckbox: function toggleCheckbox() {
        var self = this;
        // If it's not checked show the date picker dialog otherwise show the delete confirmation dialog
        if (!self.get('isChecked')) {
          self.route.render('main.children.milestones.date-selection-dialog', { outlet: 'modal-dialog' });
        } else {
          // Do the delete milestone popup
          bootbox.dialog({
            title: 'Delete milestone',
            message: 'You are about to delete this milestone. There is no undo. Are you sure?',
            onEscape: function onEscape() {},
            buttons: {
              ok: {
                label: 'OK',
                className: "btn-primary",
                callback: function callback() {
                  var child = self.get('child');
                  var milestone = self.get('milestone');
                  self.API.deleteCompletedChecklistItem(child.id, self.get('completedMilestone').id, function () {
                    // Remove that milestone from the list of completedChecklistItems
                    var completedChecklistItems = self.get('completedChecklistItems');
                    for (var i = 0; i < completedChecklistItems.length; i++) {
                      var completedChecklistItem = completedChecklistItems[i];
                      if (!completedChecklistItem.deleted && completedChecklistItem.checklistItemId === milestone.id) {
                        completedChecklistItems.replace(i, 1);
                        break;
                      }
                    }
                    self.set('completedChecklistItems', completedChecklistItems);
                  });
                }
              },
              cancel: {
                label: 'Cancel',
                className: "btn-mint",
                callback: function callback() {}
              }
            }
          });
        }
      }
    }
  });
});