define('tinybeans-frontend/controllers/main/journals/list', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/navigation'], function (exports, _baseController, _navigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_navigation.default, {
    actions: {
      view: function view(journal) {
        this.send('switchJournal', journal);
      },
      edit: function edit(journal) {
        this.send('gotoTopOfPage');
        this.transitionToRoute('main.journals.edit', journal.get('id'));
      },

      add: function add() {
        this.send('gotoTopOfPage');
        this.transitionToRoute('main.journals.add');
      },
      upgrade: function upgrade(journal) {
        this.send('gotoTopOfPage');
        this.transitionToRoute('main.subscription-products.list', { queryParams: { journalid: journal.get('id'), overviewOnly: false } });
      },
      showPremium: function showPremium(journal) {
        this.send('gotoTopOfPage');
        this.transitionToRoute('main.subscription-products.list', { queryParams: { journalid: journal.get('id'), overviewOnly: false } });
      }
    }
  });
});