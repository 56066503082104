define('tinybeans-frontend/adapters/collection', ['exports', 'tinybeans-frontend/adapters/application', 'ember-simple-auth/mixins/data-adapter-mixin', 'tinybeans-frontend/services/lotame'], function (exports, _application, _dataAdapterMixin, _lotame) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_dataAdapterMixin.default, {
    // Query means get followers for a journal (use query on journal instead?
    query: function query(store, type, _query) {
      var url = '/api/1/journals/' + _query.journalId + '/tags';
      return this.ajax(url, 'GET', {});
    },
    createRecord: function createRecord(store, type, snapshot) {
      var url = '/api/1/journals/' + snapshot.adapterOptions.journalId + '/tags';
      var data = this.serialize(snapshot);
      data = JSON.stringify(data);

      // const behavior = 'create album';
      // const lotame = new Lotame();
      // lotame.sendBehavior(behavior, undefined, this.get("globals.userId"));

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          data: data
        }).then(function (data) {
          Ember.run(null, resolve, data);
        }, function (jqXHR) {
          jqXHR.then = null; // tame jQuery's ill mannered promises
          Ember.run(null, reject, jqXHR);
        });
      });
    },
    findHasMany: function findHasMany(store, snapshot, url) {
      var id = snapshot.id;
      var type = snapshot.modelName;

      url = this.urlPrefix(url, this.buildURL(type, id, snapshot, 'findHasMany'));

      return this.ajax(url, 'GET');
    },


    queryRecord: function queryRecord(store, type, query) {
      var url = '/api/1/journals/' + query.journalId + '/tags/path/' + query.path;

      return this.ajax(url, 'GET');
    }
    // Backend doesn't return the collection object

    // updateRecord: function(store, type, snapshot) {
    //   var id = snapshot.id;
    //   let options = snapshot.adapterOptions;
    //   let endpoint = '';
    //   let data = '';
    //   if (options.addEntries) {
    //     endpoint = '/addEntries';
    //     data = {
    //       entryIds: options.addEntries
    //     };
    //   } else if (options.reorderEntries) {
    //     endpoint = '/reorderEntries';
    //     data = {
    //       tagEntryIds: options.reorderEntries
    //     };
    //   } else if (options.removeEntries) {
    //     endpoint = '/removeEntries';
    //     data = {
    //       tagIds: options.removeEntries
    //     };
    //   } else {
    //     data = this.serialize(snapshot);
    //   }
    //   var url = `/api/1/journals/${options.journalId}/tags/${id}${endpoint}`;
    //   data = JSON.stringify(data);
    //
    //   return new Ember.RSVP.Promise(function(resolve, reject) {
    //     return Ember.$.ajax({
    //       type: 'POST',
    //       url: url,
    //       dataType: 'json',
    //       data: data
    //     }).then(function(data) {
    //       Ember.run(null, resolve, data);
    //     }, function(jqXHR) {
    //       jqXHR.then = null; // tame jQuery's ill mannered promises
    //       Ember.run(null, reject, jqXHR);
    //     });
    //   });
    // }

  });
});