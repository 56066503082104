define('tinybeans-frontend/routes/social', ['exports', 'tinybeans-frontend/tracker'], function (exports, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    category: _tracker.Moments.category,
    screen: _tracker.Moments.social.screen,

    session: Ember.inject.service(),

    model: function model(params) {
      var entry_id = params.entry_id,
          uuid = params.uuid;

      var authenticated = this.get('session.isAuthenticated');
      return Ember.RSVP.hashSettled({
        authenticated: authenticated,
        me: authenticated ? this.store.find('user', 'me') : null,
        entry: this.store.findRecord('entry', entry_id, {
          adapterOptions: {
            uuid: uuid
          }
        })
      }).then(function (result) {
        return {
          authenticated: authenticated,
          me: result.me.value,
          entry: result.entry.value
        };
      });
    },
    afterModel: function afterModel(model) {
      if (!model.entry) {
        var href = window.location.href;
        this.transitionTo('not-found', href.substr(href.indexOf('#') + 2));
      }
    }
  });
});