define('tinybeans-frontend/routes/main/pets/edit', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker', 'lodash/lodash'], function (exports, _baseProtectedRoute, _tracker, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Pet.category,
    screen: _tracker.Pet.details.screen,

    model: function model(params) {
      var journalId = params.journal_id;
      var petId = params.pets_id;
      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        petId: petId,
        followings: this.modelFor('main').followings,
        journals: this.store.findAll('journal')
      });
    },
    afterModel: function afterModel(model) {
      model.pet = _lodash.default.find(model.journal.get('pets').toArray(), function (pet) {
        return pet.id === model.petId;
      });
    }
  });
});