define('tinybeans-frontend/routes/main/subscription/cancel', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash'], function (exports, _baseProtectedRoute, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  Ember.RSVP.on('error', function (error) {
    Ember.Logger.assert(false, error);
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        stats: this.API.getJournalStats(),
        isSubscribedUser: this.API.getActiveSubscription(),
        me: this.store.find('user', 'me'),
        followers: this.API.getEligibleFollowers(),
        seatInfo: this.API.getEligibleFollowers(),
        seated: Array
      });
    },


    actions: {},

    afterModel: function afterModel(model) {},

    setupController: function setupController(controller, model, error) {
      // Ember.Logger.debug(error.message);
      this._super(controller, model);
      var prices = { monthly: '7.99', yearly: '74.99' };
      // Leanplum
      Leanplum.addVariablesChangedHandler(function () {
        var vars = Leanplum.getVariables();
        if (vars.Paywall) {
          var paywall = vars.Paywall;
          var pricing = JSON.parse(paywall.products).aliases;
          prices = {};
          _lodash.default.each(pricing, function (price, idx) {
            var found = price.match(/\d+(.\d{1,2})?$/g)[0];
            if (0 === idx) {
              prices.monthly = found;
            }
            prices.yearly = found;
          });
        }
      });

      if (model.isSubscribedUser.subscription) {
        controller.set('startDate', moment(model.isSubscribedUser.startDate).format('MMMM Do YYYY'));
      }
      if (model.stats) {
        var stats = {};
        console.log(model.stats.journalStats.toArray());
        if (model.stats.length > 1) {
          _lodash.default.each(model.stats.journalStats.toArray(), function (journal) {
            console.log(journal);
            stats.commentsAmount += journal.commentsAmount;
            stats.childrenAmount += journal.childrenAmount;
            stats.followersAmount += journal.followersAmount;
            stats.memoriesAmount += journal.memoriesAmount;
            if (journal.toBeLimited) controller.set('toBeLimited', true);
          });
          controller.set('stats', stats);
        } else {
          controller.set('stats', model.stats.journalStats[0]);
        }
      }
      model.seated = this.getSeatsInfo(model.followers, model.me.id);
      if (model.seated.length > 0) {
        model.hasSeatedFollower = true;
        console.log("Seated: ", model.seated);
      }
      if (model.seated.length) {
        controller.set('seated', model.seated);
        controller.set('hasSeatedFollower', true);
      } else {
        controller.set('hasSeatedFollower', false);
      }
    },

    getSeatsInfo: function getSeatsInfo(followers, me) {
      var holders = [];
      _lodash.default.each(followers.caregivers, function (follower) {
        if (parseInt(me) === parseInt(follower.seatGivenBy)) {
          holders.push(follower.user);
        }
      });
      return holders;
    },


    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.setProperties({
          renew: undefined,
          subscriptionId: undefined
        });
      }
    }

  });
});