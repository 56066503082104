define('tinybeans-frontend/routes/main/products/list', ['exports', 'tinybeans-frontend/routes/base-route', 'tinybeans-frontend/tracker'], function (exports, _baseRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    category: _tracker.Products.category,
    screen: _tracker.Products.screen
  });
});