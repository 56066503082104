define('tinybeans-frontend/components/print-io', ['exports', 'tinybeans-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.run.next(function () {
        PIO.open({
          embedInElement: Ember.$('#printio')[0],
          iframeStyles: {
            // any CSS rule names and values. these will be applied last
            'max-width': '1400px',
            width: '100%',
            padding: '0px',
            height: '800px',
            'margin-top': '20px',
            'z-index': 1
          },
          // required -- api key
          recipeId: _environment.default.printioRecipe,
          url: _environment.default.printioURL,
          fns: {
            // function that handles custom photo source data
            // request is an object like { page: 1, folder: "" }
            // replyFn is a callback that takes an object like
            /// { id:"", page: 1, totalPages: 1, items: [] }
            /// where items contains objects like
            /// { picture: "http://hi.com/hi.jpg" } for images
            /// { id: "", isFolder: true, name: ""} for folders
            onPhotoSourceReq: function onPhotoSourceReq(request, replyFn) {
              // if the request is for the root we return the accessible journals the user has access to
              //console.log(request.folder);
              if (request.folder === "") {
                var accessibleJournals = [];
                // Can't use existing API methods here as it must be done synchronously
                Ember.$.ajax({
                  url: '/api/1/journals',
                  success: function success(result) {
                    accessibleJournals = result.journals;
                  },
                  async: false
                });
                var folderObjects = [];
                for (var i = 0; accessibleJournals && i < accessibleJournals.length; i++) {
                  // User might not have any journals
                  var journal = accessibleJournals[i];
                  folderObjects.push({
                    id: 'journal' + journal.id,
                    isFolder: true,
                    name: journal.title
                  });
                }

                replyFn({
                  id: "",
                  page: 1,
                  totalPages: 1,
                  items: folderObjects
                });
              } else if (request.folder.indexOf('journal') === 0 && request.folder.indexOf('byYear') === -1 && request.folder.indexOf('byChild') === -1 && request.folder.indexOf('year') === -1 && request.folder.indexOf('child') === -1) {
                // By year or by child
                var _folderObjects = [];
                _folderObjects.push({
                  id: request.folder + 'byYear',
                  isFolder: true,
                  name: 'By year'
                });
                _folderObjects.push({
                  id: request.folder + 'byChild',
                  isFolder: true,
                  name: 'By child'
                });
                replyFn({
                  id: "",
                  page: 1,
                  totalPages: 1,
                  items: _folderObjects
                });
              } else if (request.folder.indexOf('journal') === 0 && request.folder.indexOf('byYear') !== -1) {
                // Request the available years for this journal
                var folder = request.folder;
                var accessibleDateSlots = [];
                var journalStart = folder.indexOf('journal') + 'journal'.length;
                var byYearStart = folder.indexOf('byYear');
                var selectedJournalId = request.folder.substr(journalStart, byYearStart - journalStart);

                // Can't use existing API methods here as it must be done synchronously
                Ember.$.ajax({
                  url: '/api/1/journals/' + selectedJournalId + '/populatedMonths',
                  success: function success(result) {
                    accessibleDateSlots = result.dateSlots;
                  },
                  async: false
                });

                var foldersToShow = [];

                var contains = function contains(a, obj) {
                  for (var _i = 0; _i < a.length; _i++) {
                    if (a[_i].name == obj) {
                      return true;
                    }
                  }
                  return false;
                };

                for (var _i2 = 0; accessibleDateSlots && _i2 < accessibleDateSlots.length; _i2++) {
                  var dateSlotYear = accessibleDateSlots[_i2].year;
                  if (!contains(foldersToShow, dateSlotYear)) {
                    // Uses custom contains function above
                    foldersToShow.push({
                      id: 'journal' + selectedJournalId + 'year' + dateSlotYear,
                      isFolder: true,
                      name: dateSlotYear
                    });
                  }
                }

                replyFn({
                  id: request.folder,
                  page: 1,
                  totalPages: 1,
                  items: foldersToShow
                });
              } else if (request.folder.indexOf('journal') === 0 && request.folder.indexOf('byChild') !== -1) {
                var _folder = request.folder;
                var _journalStart = _folder.indexOf('journal') + 'journal'.length;
                var byChildStart = _folder.indexOf('byChild');
                var _selectedJournalId = request.folder.substr(_journalStart, byChildStart - _journalStart);

                // Request the children for this journal
                var _accessibleJournals = [];
                // Can't use existing API methods here as it must be done synchronously
                Ember.$.ajax({
                  url: '/api/1/journals',
                  success: function success(result) {
                    _accessibleJournals = result.journals;
                  },
                  async: false
                });

                var _foldersToShow = [];

                for (var _i3 = 0; _i3 < _accessibleJournals.length; _i3++) {
                  var _journal = _accessibleJournals[_i3];
                  if (_journal.id == _selectedJournalId) {
                    for (var j = 0; _journal.children && j < _journal.children.length; j++) {
                      var child = _journal.children[j];
                      _foldersToShow.push({
                        id: 'journal' + _selectedJournalId + 'child' + child.id,
                        isFolder: true,
                        name: child.firstName
                      });
                    }
                  }
                }

                replyFn({
                  id: request.folder,
                  page: 1,
                  totalPages: 1,
                  items: _foldersToShow
                });
              } else if (request.folder.indexOf('journal') === 0 && request.folder.indexOf('child') !== -1) {
                // Get all moments for the child
                var _folder2 = request.folder;
                var childEntries = [];
                var childStart = _folder2.indexOf('child');
                var selectedChildId = request.folder.substr(childStart + 'child'.length);
                var pageSize = 100;
                var numPages = 0;
                var _folderObjects2 = [];
                // Can't use existing API methods here as it must be done synchronously
                Ember.$.ajax({
                  url: '/api/1/children/' + selectedChildId + '/entries',
                  success: function success(result) {
                    childEntries = result.entries;
                    numPages = Math.floor(result.totalEntries / pageSize) + 1;
                  },
                  async: false
                });

                for (var _i4 = 0; childEntries && _i4 < childEntries.length; _i4++) {
                  var entry = childEntries[_i4];
                  if (entry.type == 'PHOTO') {
                    _folderObjects2.push({
                      picture: entry.blobs.l,
                      thumb: entry.blobs.t,
                      printUrl: entry.blobs.p
                    });
                  }
                }

                replyFn({
                  id: request.folder,
                  page: request.page,
                  totalPages: numPages,
                  items: _folderObjects2
                });
              } else if (request.folder.indexOf('journal') === 0 && request.folder.indexOf('year') !== -1 && request.folder.indexOf('month') === -1) {
                var _folder3 = request.folder;
                var _accessibleDateSlots = [];
                var _journalStart2 = _folder3.indexOf('journal') + 'journal'.length;
                var yearStart = _folder3.indexOf('year');
                var _selectedJournalId2 = request.folder.substr(_journalStart2, yearStart - _journalStart2);
                var selectedYear = request.folder.substr(yearStart + 'year'.length);
                // Can't use existing API methods here as it must be done synchronously
                Ember.$.ajax({
                  url: '/api/1/journals/' + _selectedJournalId2 + '/populatedMonths',
                  success: function success(result) {
                    _accessibleDateSlots = result.dateSlots;
                  },
                  async: false
                });

                var monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                var _folderObjects3 = [];
                for (var _i5 = 0; _accessibleDateSlots && _i5 < _accessibleDateSlots.length; _i5++) {
                  // User might have no accessibleDateSlots at all
                  if (selectedYear == _accessibleDateSlots[_i5].year) {
                    _folderObjects3.push({
                      id: request.folder + 'month' + _accessibleDateSlots[_i5].month,
                      isFolder: true,
                      name: monthShortNames[_accessibleDateSlots[_i5].month]
                    });
                  }
                }

                replyFn({
                  id: request.folder,
                  page: 1,
                  totalPages: 1,
                  items: _folderObjects3
                });
              } else if (request.folder.indexOf('journal') === 0 && request.folder.indexOf('year') !== -1 && request.folder.indexOf('month') !== -1) {
                // List items for that month (paginated)
                var _folder4 = request.folder;
                var currentEntries = [];
                var _pageSize = 100;
                var _numPages = 0;
                var _journalStart3 = _folder4.indexOf('journal') + 'journal'.length;
                var _yearStart = _folder4.indexOf('year');
                var monthStart = _folder4.indexOf('month');
                var _selectedJournalId3 = request.folder.substr(_journalStart3, _yearStart - _journalStart3);
                var _selectedYear = request.folder.substr(_yearStart + 'year'.length, monthStart - (_yearStart + 'year'.length));
                var selectedMonth = request.folder.substr(monthStart + 'month'.length);

                Ember.$.ajax({
                  url: ('/api/1/journals/' + _selectedJournalId3 + '/entries').params({
                    month: Number(selectedMonth) + 1,
                    year: _selectedYear,
                    page: request.page,
                    fetchSize: _pageSize
                  }),
                  success: function success(result) {
                    currentEntries = result.entries || [];
                    _numPages = Math.floor(result.totalEntries / _pageSize) + 1;
                  },
                  async: false
                });

                // Now for each entry
                var _folderObjects4 = [];
                for (var _i6 = 0; _i6 < currentEntries.length; _i6++) {
                  if (currentEntries[_i6].type == 'PHOTO') {
                    _folderObjects4.push({
                      picture: currentEntries[_i6].blobs.l,
                      thumb: currentEntries[_i6].blobs.t,
                      printUrl: currentEntries[_i6].blobs.p
                    });
                  }
                }

                replyFn({
                  id: request.folder,
                  page: request.page,
                  totalPages: _numPages,
                  items: _folderObjects4
                });
              }
            }
          },

          // set ordering/etc for photosources on upload page
          photosources: {
            local: {
              enabled: false
            },
            facebook: {
              enabled: false
            },
            instagram: {
              enabled: false
            },
            photobucket: {
              enabled: false
            },
            flickr: {
              enabled: false
            },
            google: {
              enabled: false
            },
            dropbox: {
              enabled: false
            },
            custom: {
              sortOrder: 1,
              enabled: true,
              iconUrl: 'https://s3-us-west-2.amazonaws.com/tinybeans-public/images/bean44.png',
              isInitiallyOpen: true
            }
          }

        });
      });
    }
  });
});