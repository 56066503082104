define('tinybeans-frontend/components/main/subscriptions/journal-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    subscriptions: Ember.computed('journalSubscriptions', 'journalSubscriptions.@each', function () {
      return this.get('journalSubscriptions').toArray().sort(function (a, b) {
        return a.get('startDate') - b.get('startDate');
      });
    }),

    hasASubscription: function () {
      return this.get('subscriptions').length > 0;
    }.property('controller.subscriptions', 'controller.subscriptions.@each'),

    actions: {
      changeRenewalType: function changeRenewalType(subscription, renewalType) {
        var self = this;
        this.API.changeRenewalType(subscription.id, renewalType, function () {
          // self.sendAction('reloadApplicationRoute');
          subscription.set('renewal', renewalType.toUpperCase());
        }, function (xhr, statusText, e) {
          self.onApiError(xhr, statusText, e);
        });
      },
      upgradeToYearly: function upgradeToYearly(journalId, subscription) {
        this.upgradeToYearly(journalId, subscription);
      }
    }
  });
});