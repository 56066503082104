define('tinybeans-frontend/controllers/main/offers/offer', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'tinybeans-frontend/config/environment', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _environment, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {

    monthlyPriceId: "",
    annualPriceId: "",
    publishableKey: "",
    coupon_code: null,

    queryParams: ["utm_campaign", "offer_partner", "coupon_code_monthly", "coupon_code_yearly", "exp_date",, "show_free", "discount", "description", "is_new_user", "monthly_offer", "yearly_offer", "landing_page_title", "hide_save_money", "hide_best_value"],

    init: function init() {
      var _this = this;

      this._super();
      // remove stripe info incase they're coming backwards from checkout
      localStorage.removeItem('currentPriceId');
      localStorage.removeItem('sessionId');
      Ember.run.schedule("afterRender", this, function () {
        _this.set('publishableKey', _environment.default.stripeSubKey);

        _tracker.Tracker.trackEvent({
          category: _tracker.Coupons.category,
          screen: 'offer page',
          action: 'subscription screen view',
          parameters: { CampaignName: _this.get('campaign').name }
        });

        Ember.$('.offer-toggle').click(function (e) {
          e.preventDefault();
          if (!Ember.$(this).hasClass('active')) {
            Ember.$(this).addClass('active');
            if (Ember.$(this).hasClass('yearly')) {
              Ember.$('.offer-toggle.monthly').removeClass('active');
              Ember.$('.offer-card.yearly').css('display', 'flex');
              Ember.$('.offer-card.monthly').css('display', 'none');
            }
            if (Ember.$(this).hasClass('monthly')) {
              Ember.$('.offer-toggle.yearly').removeClass('active');
              Ember.$('.offer-card.yearly').css('display', 'none');
              Ember.$('.offer-card.monthly').css('display', 'flex');
            }
          }
        });
      });
    },

    addHashQueryParam: function addHashQueryParam(url, key, value) {
      var existing = url.lastIndexOf('?') > url.lastIndexOf('#') ? url.substr(url.lastIndexOf('?') + 1) : '';

      var query = new URLSearchParams(existing);
      query.set(key, value);
      return url.replace('?' + existing, '') + '?' + query.toString();
    },
    createCheckoutSession: function createCheckoutSession(priceId, coupon) {
      var _this2 = this;

      var stripe = new Stripe(this.get('publishableKey'));
      var currentUrl = encodeURI(window.location.href.toString());
      var urlObject = new URL(currentUrl);
      var protocol = urlObject.protocol;
      var host = urlObject.host;
      var url = protocol + '//' + host + '/app/#/main/offers/confirmation';
      var offer_url = window.location.href;
      var campaign = this.get('campaign');
      url = this.addHashQueryParam(url, 'exp_date', campaign.exp_date);
      url = this.addHashQueryParam(url, 'discount_type', campaign.discount_type);
      url = this.addHashQueryParam(url, 'offer_type', campaign.offer_type);
      url = this.addHashQueryParam(url, 'monthly_offer', campaign.monthly_amount);
      url = this.addHashQueryParam(url, 'yearly_offer', campaign.yearly_amount);
      url = this.addHashQueryParam(url, 'utm_campaign', campaign.name);
      this.API.createCheckoutSessionCustom(priceId, coupon, url, offer_url, function (data) {
        localStorage.setItem('currentPriceId', priceId);
        localStorage.setItem('sessionId', data.sessionId);
        stripe.redirectToCheckout({
          sessionId: data.sessionId
        }).then(_this2.handleResult);
      });
    },
    handleResult: function handleResult(result) {
      if (result.error) {
        this.showErrorMessage(result.error.message);
      }
    },
    actions: {
      createCheckoutMonthly: function createCheckoutMonthly() {
        var priceId = this.get('monthlyPriceId');
        var self = this;
        var coupon = '';

        // set the offer type to monthly for confirmation display
        this.set('campaign.offer_type', 'monthly');

        if (0 !== this.get('campaign').monthly_amount) {
          coupon = this.get('monthly_code');
        }
        self.GTM.trackEvent({
          'eventCategory': 'coupons',
          'eventAction': 'offer monthly button',
          'eventLabel': 'web'
        });
        var campaign = this.get('campaign');

        _tracker.Tracker.trackEvent({
          category: _tracker.Coupons.category,
          screen: 'offer page',
          action: 'purchase subscription',
          parameters: { 'CampaignName': campaign.name, 'SubscriptionType': 'monthly subscription' }
        });

        var action = 'onboarding monthly button';
        this.createCheckoutSession(priceId, coupon);
      },
      createCheckoutAnnual: function createCheckoutAnnual() {
        var priceId = this.get('annualPriceId');
        var self = this;
        var coupon = '';

        // set the offer type to annual for confirmation display
        this.set('campaign.offer_type', 'annually');

        if (0 !== this.get('campaign').yearly_amount) {
          coupon = this.get('yearly_code');
        }

        self.GTM.trackEvent({
          'eventCategory': 'coupons',
          'eventAction': 'offer annual button',
          'eventLabel': 'web'
        });
        var campaign = this.get('campaign');

        _tracker.Tracker.trackEvent({
          category: _tracker.Coupons.category,
          screen: 'offer page',
          action: 'purchase subscription',
          parameters: { 'CampaignName': campaign.name, 'SubscriptionType': 'annual subscription' }
        });

        var action = 'offer yearly button';
        this.createCheckoutSession(priceId, coupon);
      },
      continueFree: function continueFree() {
        var journalId = this.get('model.journal.id');
        var self = this;

        self.GTM.trackEvent({
          'eventCategory': 'offcouponser',
          'eventAction': 'offer free',
          'eventLabel': 'web'
        });

        var campaign = this.get('campaign');
        _tracker.Tracker.trackEvent({
          category: _tracker.Coupons.category,
          screen: 'offer page',
          action: 'continue for free button',
          parameters: { 'CampaignName': campaign.name }
        });

        this.transitionToRoute('main.offers.confirmation', { queryParams: { exp_date: campaign.exp_date, offer_type: 'free', utm_campaign: campaign.name } });
      }
    }

  });
});