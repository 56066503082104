define("tinybeans-frontend/templates/main/children/milestones/complete-milestone", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/children/milestones/complete-milestone.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "sparkle-effect", [], ["sparkleObjects", ["subexpr", "@mut", [["get", "sparkleObjects", ["loc", [null, [1, 32], [1, 46]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [1, 48]]], 0, 0], ["inline", "milestones/milestone-completion", [], ["sparkleObjects", ["subexpr", "@mut", [["get", "sparkleObjects", ["loc", [null, [2, 49], [2, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "parentRoute", ["subexpr", "@mut", [["get", "route", ["loc", [null, [2, 76], [2, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "oldScrollTop", ["subexpr", "@mut", [["get", "oldScrollTop", ["loc", [null, [2, 95], [2, 107]]], 0, 0, 0, 0]], [], [], 0, 0], "doMilestoneCompletion", ["subexpr", "@mut", [["get", "doMilestoneCompletion", ["loc", [null, [2, 130], [2, 151]]], 0, 0, 0, 0]], [], [], 0, 0], "canEditMilestones", ["subexpr", "@mut", [["get", "canEditMilestones", ["loc", [null, [2, 170], [2, 187]]], 0, 0, 0, 0]], [], [], 0, 0], "child", ["subexpr", "@mut", [["get", "child", ["loc", [null, [2, 194], [2, 199]]], 0, 0, 0, 0]], [], [], 0, 0], "milestone", ["subexpr", "@mut", [["get", "milestone", ["loc", [null, [2, 210], [2, 219]]], 0, 0, 0, 0]], [], [], 0, 0], "completedChecklistItems", ["subexpr", "@mut", [["get", "completedChecklistItems", ["loc", [null, [2, 244], [2, 267]]], 0, 0, 0, 0]], [], [], 0, 0], "me", ["subexpr", "@mut", [["get", "me", ["loc", [null, [2, 271], [2, 273]]], 0, 0, 0, 0]], [], [], 0, 0], "embeddedReturn", false], ["loc", [null, [2, 0], [2, 296]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});