define('tinybeans-frontend/routes/main/journals/no-journal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        me: this.store.find('user', 'me')
      });
    }
  });
});