define('tinybeans-frontend/controllers/main/onboarding/step-three', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash', 'tinybeans-frontend/tracker', 'tinybeans-frontend/services/lotame', 'tinybeans-frontend/config/environment'], function (exports, _baseController, _commonsMixin, _lodash, _tracker, _lotame, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    queryParams: ['journalId'],

    mainController: Ember.inject.controller('main'),
    monthController: Ember.inject.controller('main.journals.month'),
    entriesController: Ember.inject.controller('main.entries.list'),

    entriesToUpload: [],
    privateMode: false,
    share: false,

    monthlyPriceId: "",
    annualPriceId: "",
    publishableKey: "",

    init: function init() {
      var _this = this;

      this._super();
      this.GTM.trackEvent({
        'eventCategory': 'userInteraction',
        'eventAction': 'loadAddMomentPage'
      });
      Ember.run.schedule("afterRender", this, function () {
        // this.set('monthlyPriceId', ENV.stripeMonthlyPrice);
        // this.set('annualPriceId', ENV.stripeAnnualPrice);
        // this.set('dailyPriceId', ENV.stripeDailyPrice);
        _this.set('publishableKey', _environment.default.stripeSubKey);
      });
    },
    addNewEntry: function addNewEntry(file) {
      var entry = Ember.Object.create({
        elementId: Math.random().toString(36).substring(7),
        file: file,
        date: this.get('date'),
        children: []
      });
      this.get('entriesToUpload').pushObject(entry);
      return entry;
    },
    updateEntryDates: function updateEntryDates() {
      var _this2 = this;

      this.highlightDate('.entry-container .date-field');
      _lodash.default.each(this.get('entriesToUpload').toArray(), function (entry) {
        entry.set('date', _this2.get('date'));
      });
    },


    dateChanged: Ember.observer('date', function () {
      this.updateEntryDates();
    }),

    render: function render(element) {
      element.find('.preview').remove();
      var img = $('<img src=\'images/not-available.png\'>').width('100%').height('100%');
      element.append(img);
    },
    renderImage: function renderImage(file, element) {
      element.find('.preview').remove();
      var preview = document.createElement('div');
      $(preview).addClass('preview');
      preview.style.backgroundSize = 'contain';
      preview.style.backgroundRepeat = 'no-repeat';
      preview.style.backgroundPosition = 'top center';
      preview.style.height = '290px';
      element.append(preview);
      var reader = new FileReader();
      reader.onload = function (event) {
        preview.style.backgroundImage = 'url(' + event.target.result + ')';
      };
      reader.readAsDataURL(file);
    },


    specificDateSelected: Ember.computed('day', 'month', 'year', function () {
      return this.get('day') && this.get('month') && this.get('year');
    }),

    renderVideo: function renderVideo(file, element, entry) {
      var fileUrl = URL.createObjectURL(file);
      element.find('.preview').remove();
      var vid = $('<video controls class=\'preview\'><source id=\'vid-source\' src=\'' + fileUrl + '\' type=\'video/mp4\'></video>');
      var upgraded = this.get('model.me.hasMemoriesAccess');
      var oldPremium = this.get('model.journal.isUpgraded');
      var isSubscridbed = this.get('isSubscribedUser');
      var maxLengthOld = oldPremium ? 300 : 30;
      var self = this;
      vid[0].ondurationchange = function () {
        if (upgraded) {
          if (oldPremium) {
            element.append(vid);
          } else {
            if (isSubscridbed) {
              element.append(vid);
            } else {
              if (this.duration > maxLengthOld) {
                bootbox.dialog({
                  title: 'Uh oh.',
                  message: 'You can\'t add videos longer than ' + maxLengthOld + ' seconds.<br>' + (oldPremium ? 'Please reduce video  and try again.' : 'Either edit the video to under 30 seconds or <a href=\"/app/#/main/subscription-products/list?journalid=' + self.get('model.journal.id') + '\">upgrade your Tinybeans</a> to unlock longer videos.'),
                  onEscape: function onEscape() {},
                  buttons: {
                    ok: {
                      label: 'OK'
                    }
                  }
                });
                self.get('entriesToUpload').removeObject(entry);
              } else {
                element.append(vid);
              }
            }
          }
        } else {
          bootbox.dialog({
            title: 'Uh oh.',
            message: 'You can\'t add videos longer than ' + maxLengthOld + ' seconds.<br>' + (upgraded ? 'Please reduce video  and try again.' : 'Either edit the video to under 30 seconds or <a href=\"/app/#/main/subscription-products/list?journalid=' + self.get('model.journal.id') + '\">upgrade your Tinybeans</a> to unlock longer videos.'),
            onEscape: function onEscape() {},
            buttons: {
              ok: {
                label: 'OK'
              }
            }
          });
          self.get('entriesToUpload').removeObject(entry);
        }
      };
    },
    highlightDate: function highlightDate(selector) {
      $(selector).animate({
        'background-color': '#bdd6e6'
      }, 100, function () {
        $(selector).animate({
          'background-color': 'white'
        }, 5000);
      });
    },
    readExifDate: function readExifDate(entry) {
      var self = this;
      var file = entry.get('file');
      EXIF.getData(file, function () {
        var date = moment(EXIF.getTag(this, 'DateTimeOriginal') || EXIF.getTag(this, 'DateTime'), 'YYYY:MM:DD');
        if (!date.isValid()) {
          date = moment();
        }
        entry.set('date', date.format(self.get('model.me.dateFormat')));
        self.highlightDate('.entry-container #' + entry.get('elementId') + ' .date-field');
      });
    },
    loadFile: function loadFile(entry) {
      var _this3 = this;

      var file = entry.get('file');
      var elementId = entry.get('elementId');
      var tryAppend = function tryAppend() {
        var element = $('#' + elementId + ' .content');
        if (element.length) {
          if (file.type.match(/image.*/)) {
            _this3.renderImage(file, element);
          } else if (file.type.match(/video.*/)) {
            _this3.renderVideo(file, element, entry);
          } else {
            _this3.render(element);
          }
          return true;
        }
        return false;
      };
      if (!tryAppend()) {
        var retryInterval = window.setInterval(function () {
          if (tryAppend()) {
            window.clearInterval(retryInterval);
          }
        }, 500);
      }
      if (!this.get('specificDateSelected')) {
        this.readExifDate(entry);
      }
    },


    uploadEntry: function uploadEntry(entryIndex, entriesToUpload, canShare) {
      var _this4 = this;

      var self = this;
      self.get('globals').setProperties({
        fileNumber: entryIndex,
        totalFiles: entriesToUpload.length,
        uploadedPercentage: 0
      });
      if (entryIndex < entriesToUpload.length) {
        self.get('globals').set('showProgressBar', true);
        var entry = entriesToUpload.objectAt(entryIndex);
        var momentTime = entry.get('date') ? moment(entry.get('date'), self.get('model.me.dateFormat')) : moment();
        var newEntry = self.store.createRecord('entry', {
          day: momentTime.date(),
          month: momentTime.month() + 1,
          year: momentTime.year(),
          caption: entry.get('caption'),
          privateMode: entry.get('privateMode')
        });

        _lodash.default.each(entry.get('children').toArray(), function (child) {
          newEntry.get('children').pushObject(child);
        });

        // Pass the file and facebook share token separately as they are not part of the model but are used by the adapter
        newEntry.save({
          adapterOptions: {
            journalId: self.get('model.journal.id'),
            file: entry.get('file')
            // facebookToken: canShare ? entry.get('facebookToken') : undefined
          }
        }).then(function (response) {
          // if (entry.get('facebookToken')) {
          //   this.API.shareEntryOnFacebook(this.get('model.journal.id'), newEntry.get('id'), entry.get('facebookToken'), () => {}, this);
          // }
          var months = _this4.get('months');
          console.info($('#globalNotification').length);
          _this4.send('displayNotification', 'Moment added to <b>' + response.get('day') + ' ' + months[response.get('month')] + ' ' + response.get('year') + '</b>');

          var file = entry.file;
          var momentType = !file ? 'text' : file['type'].split('/')[0] === 'image' ? 'photo' : 'video';
          // const behavior = `moment added : type : ${momentType}`;
          // const lotame = new Lotame();
          // lotame.sendBehavior(behavior, undefined, this.get("globals.userId"));

          self.uploadEntry(entryIndex + 1, entriesToUpload, canShare); // Upload next file
        }, function (e) {
          if (e.status >= 400 && e.status <= 404) {
            bootbox.dialog({
              title: 'Uh oh.',
              message: 'Sorry we couldn\'t upload this file <b> ' + entry.file.name + ' </b>. Other files may have been uploaded. Please try again later or contact us for support (error code ' + e.status + ').',
              onEscape: function onEscape() {},
              buttons: {
                ok: {
                  label: 'OK'
                }
              }
            });
            self.uploadEntry(entryIndex + 1, entriesToUpload, canShare);
          } else {
            console.log('Retrying because...' + e);
            // Wait 5 seconds and retry
            Ember.run.later(function () {
              self.uploadEntry(entryIndex, entriesToUpload, canShare);
            }, 5000);
          }
        });
      } else {
        self.get('globals').set('showProgressBar', false);
        if (this.get('monthController.route')) {
          this.get('monthController.route').refresh();
        }
        if (this.get('entriesController.route')) {
          this.get('entriesController.route').refresh();
        }
      }
    },

    entryIsVideo: function entryIsVideo(entry) {
      // Image or video are not null
      if (entry.get('file')) {
        return entry.get('file').type.indexOf('video/') === 0;
      } else {
        return false;
      }
    },

    createVideoURL: function createVideoURL(entry) {
      // Load the video as a url
      return window.URL.createObjectURL(entry.file);
    },


    createCheckoutSession: function createCheckoutSession(priceId) {
      var _this5 = this;

      var stripe = new Stripe(this.get('publishableKey'));
      this.API.createCheckoutSession(priceId, null, function (data) {
        localStorage.setItem('currentPriceId', priceId);
        localStorage.setItem('sessionId', data.sessionId);
        stripe.redirectToCheckout({
          sessionId: data.sessionId
        }).then(_this5.handleResult);
      });
    },

    actions: {

      removeEntry: function removeEntry(entry) {
        this.get('entriesToUpload').removeObject(entry);
        if (!this.get('entriesToUpload.length')) {
          this.addNewEntry();
        }
      },

      toggleChild: function toggleChild(child, entry) {
        var entries = entry ? [entry] : this.get('entriesToUpload').toArray();

        _lodash.default.each(entries.toArray(), function (entry) {
          var childTagged = _lodash.default.any(entry.get('children').toArray(), function (c) {
            return c.id === child.id;
          });
          var numEntries = child.get('numEntries');
          if (childTagged) {
            entry.get('children').removeObject(child);
            child.set('numEntries', numEntries - 1);
          } else {
            entry.get('children').pushObject(child);
            child.set('numEntries', numEntries ? numEntries + 1 : 1);
          }
        });
      },
      useExifDates: function useExifDates() {
        var _this6 = this;

        this.set('specificDateSelected', false);
        $('#btn-exif-date').addClass('active');
        $('#set-date').removeClass('active');
        _lodash.default.each(this.get('entriesToUpload').toArray(), function (entry) {
          if (entry.file) {
            _this6.readExifDate(entry);
          }
        });
      },
      selectDate: function selectDate() {
        this.set('specificDateSelected', true);
        this.updateEntryDates();
      },
      addEntries: function addEntries() {
        var entriesToUpload = this.get('entriesToUpload');
        // Short circuit if user has exceeded or met limits
        this.API.getMemoriesLimit().then(function (limit) {
          console.log(limit);

          limit = limit.limit;
          if (limit.threshold > 0 && (limit.usedTimes >= limit.threshold || files.length > limit.threshold)) {
            //modal and cancel
            Ember.$(".limitReachedModal").modal("show");
            this.set("submitting", false);
            this.get('globals').set('showProgressBar', false);
            if (this.get('monthController.route')) {
              this.get('monthController.route').refresh();
            }
            if (this.get('entriesController.route')) {
              this.get('entriesController.route').refresh();
            }
            return;
          }
        });
        var lastEntry = entriesToUpload.objectAt(entriesToUpload.get('length') - 1);
        if (!lastEntry.get('caption') && !lastEntry.get('file')) {
          if (entriesToUpload.get('length') > 1) {
            entriesToUpload.removeObject(lastEntry);
          } else {
            lastEntry.set('error', 'You can\'t upload add an empty moment');
            return;
          }
        }

        //history.back();
        var journalId = this.get('model.journal.id');
        //var hasAddedChild = this.get("model.hasAddedChild");


        this.GTM.trackEvent({
          'eventCategory': 'onboarding-child-first',
          'eventAction': 'step-three-click',
          'eventLabel': 'add-moment'
        });

        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.step3.screen,
          action: _tracker.Onboarding.step3.saveButton
        });
        this.transitionToRoute('main.onboarding.step-four', journalId);

        var canShare = this.get('canShare');
        this.uploadEntry(0, Ember.copy(entriesToUpload), canShare);
      },
      filesAdded: function filesAdded(entry, files) {
        entry.set('file', files[0]);
        this.loadFile(entry);
        if (this.get('model.journal.isUpgraded')) {
          for (var i = 1; i < files.length; i++) {
            var _entry = this.addNewEntry(files[i]);
            this.loadFile(_entry);
          }
          this.addNewEntry();
        }
      },


      clickTrack: function clickTrack(itemClass) {
        var eventLabel = itemClass ? itemClass : "na";
        this.GTM.trackEvent({
          'eventCategory': 'onboarding subscription',
          'eventAction': "step-three-click-steps-indicator",
          'eventLabel': eventLabel
        });
      },

      cancel: function cancel() {
        var journalId = this.get('model.journal.id');
        if (typeof AF !== "undefined") AF('pba', 'event', { eventType: 'EVENT', eventValue: { 'label': 'Skip free trial', 'from_page': 'Step Three' }, eventName: 'Skip free trial' });
        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.step3.screen,
          action: _tracker.Onboarding.step3.skipButton
        });
        var d = new Date();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();
        this.transitionToRoute('main.journals.month', journalId, year, month);
      },
      purchaseSub: function purchaseSub() {
        //var journalId = this.get('model.journal.id');
        if (typeof AF !== "undefined") AF('pba', 'event', { eventType: 'EVENT', eventValue: { 'label': 'Sign up for free trial', 'from_page': 'Step Three' }, eventName: 'Sign up for free trial' });
        this.transitionToRoute('main.subscription-products.list');
        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.step3.screen,
          action: _tracker.Onboarding.step3.saveButton
        });
      },
      createCheckoutMonthly: function createCheckoutMonthly() {
        var priceId = this.get('monthlyPriceId');
        var self = this;

        self.GTM.trackEvent({
          'eventCategory': 'premium',
          'eventAction': 'onboarding monthly button',
          'eventLabel': 'web'
        });

        var action = 'onboarding monthly button';

        _tracker.Tracker.trackEvent({
          category: _tracker.Premium.list.category,
          screen: _tracker.Premium.list.screen,
          action: action
        });
        this.createCheckoutSession(priceId);
      },
      createCheckoutAnnual: function createCheckoutAnnual() {
        var priceId = this.get('annualPriceId');
        var self = this;

        self.GTM.trackEvent({
          'eventCategory': 'premium',
          'eventAction': 'onboarding annual button',
          'eventLabel': 'web'
        });

        var action = 'onboarding yearly button';

        _tracker.Tracker.trackEvent({
          category: _tracker.Premium.list.category,
          screen: _tracker.Premium.list.screen,
          action: action
        });
        this.createCheckoutSession(priceId);
      },
      goToRedeemGiftCard: function goToRedeemGiftCard() {
        this.transitionToRoute("main.gift-card.redeem-gift-card");
      }
    }
  });
});