define('tinybeans-frontend/routes/main/collections/organize', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.PhotoCollections.category,
    screen: _tracker.PhotoCollections.organize.screen,

    error: {},
    model: function model(params) {
      var journalId = params.journal_id;
      var path = params.path;
      return Ember.RSVP.hash({
        path: path,
        journalId: journalId,
        me: this.store.find('user', 'me'),
        collection: this.store.queryRecord('collection', { journalId: journalId, path: path }),
        journal: this.store.find('journal', journalId),
        followers: this.store.query('follower', { filter: { journalId: journalId } }),
        followings: this.modelFor('main').followings
      });
    },

    setupController: function setupController(controller, model) {
      var self = this;
      self._super(controller, model);
      model.collection.name = model.collection.name.length > 35 ? model.collection.name.substring(0, 35) + "..." : model.collection.name;
    }

  });
});