define('tinybeans-frontend/controllers/main/journals/edit', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _baseController, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    isActiveNavJournals: function isActiveNavJournals() {
      return true;
    },

    title: function () {
      var journal = this.get('model.journal');
      var title = journal.get('title') || this.t("Journal.Default_Title_Prompt");
      return title;
    }.property('model.journal.title'),

    saveButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? 'Please wait ...' : 'Save Changes';
    }.property('submitting'),

    ownsThisJournal: Ember.computed('property', function () {
      return this.get('model.user.id') === this.get('model.journal.user.id');
    }),

    isFollower: Ember.computed('model.following', function () {
      return !!this.get('model.following');
    }),

    actions: {
      save: function save() {
        var _this = this;

        var self = this;
        var journal = self.get('model.journal');
        var following = self.get('model.following');
        self.set('submitting', true);
        journal.save({
          adapterOptions: {
            emailFrequencyOnNewEvent: following.get('emailFrequencyOnNewEvent.name')
          }
        }).then(function () {
          _this.set('submitting', false);
          _this.transitionToRoute('main.journals.list');
        }, function () {
          _this.set('submitting', false);
          // TODO handle error
        });
      },

      unfollow: function unfollow() {
        var _this2 = this;

        var self = this;
        var journal = self.get('model.journal');
        var following = self.get('model.following');
        bootbox.dialog({
          title: this.t('Journal.Unfollow_Confirmation.Unfollow'),
          message: this.t('Journal.Unfollow_Confirmation.Unfollow_Text'),
          buttons: {
            ok: {
              label: 'OK',
              callback: function callback() {
                if (!_this2.get('ownsThisJournal')) {
                  following.destroyRecord({
                    adapterOptions: {
                      journalId: journal.id
                    }
                  }).then(function () {
                    _this2.transitionToRoute('main.journals.list');
                    _this2.send('reloadApplicationRoute');
                  }, _this2);
                }
              }
            },
            cancel: {
              label: this.t('Journal.Unfollow_Confirmation.Cancel'),
              className: "btn-cancel"
            }
          }
        });
      },

      gotoPremium: function gotoPremium() {
        Ember.$(window).scrollTop(0);
        this.transitionToRoute('main.subscription-products.list', {
          queryParams: {
            journalid: this.get("model.journal.id"),
            overviewOnly: false
          }
        });
      }
    }
  });
});