define('tinybeans-frontend/routes/main/collections/show', ['exports', 'tinybeans-frontend/routes/base-route', 'tinybeans-frontend/tracker'], function (exports, _baseRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    category: _tracker.PhotoCollections.category,
    screen: _tracker.PhotoCollections.show.screen,

    session: Ember.inject.service('session'),

    error: {},
    model: function model(params) {
      var authenticated = this.get('session.isAuthenticated');
      var journalId = params.journal_id;
      var path = params.path;

      return Ember.RSVP.hash({
        path: path,
        journalId: journalId,
        journal: authenticated ? this.store.find('journal', journalId) : null,
        collection: this.store.queryRecord('collection', {
          journalId: journalId,
          path: path
        }),
        me: authenticated ? this.store.find('user', 'me') : null,
        followings: authenticated ? this.store.findAll('following') : null
      });
      // return Ember.RSVP.hashSettled({
      //   //this page serves both public and non-public
      //   path: path,
      //   journalId: journalId,
      //   journal: authenticated ? this.store.find('journal', journalId) : null,
      //   collection: this.store.queryRecord('collection', {
      //     journalId: journalId,
      //     path: path
      //   }),
      //   me: authenticated ? this.store.find('user', 'me') : null,
      //   followings: authenticated ? this.store.findAll('following') : null,
      // }).then(result => {
      //   return {
      //     path: result.path.value,
      //     journalId: result.journalId.value,
      //     journal: result.journal ? result.journal.value : null,
      //     collection: result.collection.value,
      //     me: result.me ? result.me.value : null,
      //     followings: result.followings ? result.followings.value : null,
      //   }
      // })
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      //gets
      var d = new Date();
      var currentYear = d.getFullYear();
      var currentMonth = d.getMonth() + 1;

      // if (!model.collection) {
      //   let location = window.location.href;
      //   this.transitionTo('not-found', location.substr(location.indexOf('#') + 2));
      //   return;
      // }
      controller.set('collection', model.collection);
      controller.set('path', model.collection.get('path'));
      controller.set('year', currentYear);
      controller.set('month', currentMonth);
      controller.set('isMobile', this.globals.isMobile());

      controller.set('displayMode', true);
      controller.set('slideShowMode', false);

      controller.set('emailShareLink', emailShareLink());
      controller.set('facebookShareLink', facebookShareLink());
      controller.set('twitterShareLink', twitterShareLink());

      function emailShareLink() {
        if (model.journalId && model.path) {
          var collectionLink = "https://tinybeans.com/app/#/main/collections/" + model.journalId + "/" + model.path;
          return "mailto:?subject=I would like to invite you to check out the Tinybeans photo album I created.&body=Check out my Tinybeans photo collection at " + collectionLink;
        } else {
          return "/";
        }
      }

      function facebookShareLink() {
        if (model.journalId && model.path) {
          var collectionLink = "https%3A%2F%2Ftinybeans.com%2Fapp%2F%23%2Fmain%2Fcollections%2F" + model.journalId + "%2F" + model.path;
          return "https://www.facebook.com/sharer/sharer.php?u=" + collectionLink;
        } else {
          return "/";
        }
      }

      function twitterShareLink() {
        if (model.journalId && model.path) {
          var site = "https%3A%2F%2Ftinybeans.com";
          var collectionLink = "https%3A%2F%2Ftinybeans.com%2Fapp%2F%23%2Fmain%2Fcollections%2F" + model.journalId + "/" + model.path;
          return "https://twitter.com/intent/tweet?original_referer=" + site + "&text=Check out my Tinybeans photo album at&tw_p=tweetbutton&url=" + collectionLink;
        } else {
          return "/";
        }
      }

      if (model.collection.get('tagEntries')) {
        var earliestDate = new Date(model.collection.get('earliestEntry.year'), model.collection.get('earliestEntry.month') - 1, model.collection.get('earliestEntry.day'));
        var latestDate = new Date(model.collection.get('latestEntry.year'), model.collection.get('latestEntry.month') - 1, model.collection.get('latestEntry.day'));

        controller.set('tagEarliestEntryDate', model.collection.get('earliestEntry') ? moment(earliestDate).format('MMM D YYYY') : null);
        controller.set('tagLatestEntryDate', model.collection.get('latestEntry') ? moment(latestDate).format('MMM D YYYY') : null);
      }
    },

    init: function init() {
      Ember.$(document).ready(function () {
        //jquery stuff goes here
        Ember.$('#btn-stop-slide-show').hide();
      });
    },

    renderTemplate: function renderTemplate() {
      this.render('main/collections/show');
      Ember.run.next(function () {
        Ember.$(window).scrollTop(0);
        Ember.$("#flashMessage p").fadeOut(3000);
      }, 200);
    }

  });
});