define('tinybeans-frontend/routes/main/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // no model, afterModel etc get called in error substates, only setupController
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.RSVP.hash({
        me: this.store.find('user', 'me'),
        journals: this.store.findAll('journal')
      }).then(function (result) {
        controller.set('model.me', result.me);
        controller.set('model.journals', result.journals.filterBy('URL'));
      });
    },


    actions: {
      switchJournal: function switchJournal(journal) {
        this.transitionTo('main.journals.view', journal.id);
      }
    }

  });
});