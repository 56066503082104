define('tinybeans-frontend/routes/main/children/show', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Child.category,
    screen: _tracker.Child.screen,

    model: function model(params) {
      var childId = params.child_id;
      var journalId = params.journal_id;

      return Ember.RSVP.hash({
        rawChecklists: [],
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        child: this.store.find('child', childId),
        genders: this.API.listGenders(),
        weights: this.store.query('weight', {
          childId: childId
        }),
        heights: this.store.query('height', {
          childId: childId
        }),
        completedChecklistItems: this.store.query('completedChecklistItem', {
          childId: childId
        }),
        dashboardChecklistItems: this.store.query('checklistItem', {
          childId: childId
        }),
        subscribedChannelArticles: this.API.getSubscribedChannelArticles(childId),
        channelSubscriptions: this.store.query('channelSubscription', {
          childId: childId
        }),
        followings: this.store.findAll('following'),
        followers: this.store.query('follower', {
          filter: {
            journalId: journalId
          }
        })
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var child = model.child;
      var childDob = moment(new Date(child.dob));
      var now = moment();
      var childBorn = model.child.get('isBorn');
      var duration = childBorn ? moment.duration(now.diff(childDob)) : moment.duration(now.diff(now));
      var num_years = duration.get("years");
      var num_months = duration.get("months");

      if (childBorn) {
        var ageInMonthsOnly = num_years * 12 + num_months;
        var validAgeRanges = [0, 3, 6, 9, 12, 18, 24, 36, 48, 60, 72, 84];
        var ageRangeIndex = 0;
        for (var i = 1; i < validAgeRanges.length - 1; i++) {
          if (validAgeRanges[i] > ageInMonthsOnly) {
            ageRangeIndex = i - 1;
            break;
          }
        }

        controller.set('currentlySelectedAgeRangeIndex', ageRangeIndex);
        controller.set('currentStartAge', validAgeRanges[ageRangeIndex]);
        controller.set('currentEndAge', validAgeRanges[ageRangeIndex + 1]);
      }
    },

    actions: {
      reloadPage: function reloadPage() {
        this.refresh();
      }
    }
  });
});