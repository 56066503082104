define('tinybeans-frontend/models/pet', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    species: (0, _attr.default)('string'),
    breed: (0, _attr.default)('string'),
    size: (0, _attr.default)('string'),
    sex: (0, _attr.default)('string'),
    birthday: (0, _attr.default)('apidate'),
    adoptionDate: (0, _attr.default)('apidate'),
    avatars: (0, _attr.default)(), // belongsTo('blobs') makes the blobs object shared among all children!
    completedChecklistItems: (0, _relationships.hasMany)('completed-checklist-item', {
      async: true
    }),

    limitedFirstName: Ember.computed('name', function () {
      return this.get('name').length > 23 ? this.get('name').substring(0, 15) + "..." : this.get('name');
    }),

    isBorn: Ember.computed('birthday', function () {
      return moment() > moment(this.get('birthday'), 'YYYY-MM-DD');
    }),

    ageThisMonth: Ember.computed('birthday', function () {
      var dob = moment(this.get('birthday'), 'YYYY-MM-DD');
      var now = moment();
      var diff = now.diff(dob);
      if (diff > 0 && diff / 3600000 <= 24) {
        return 'New Arrival 🎉 😍';
      }
      return now > dob ? moment.preciseDiff(dob, now, false, 'DAY') : 'Add Birthday';
    }),

    ageInMonthsOnly: Ember.computed('property', function () {
      var dob = moment(this.get('adoptionDate'), 'YYYY-MM-DD');
      var now = moment();
      return now > dob ? moment.preciseDiff(dob, now, false, 'DAY') : 'Add adoption day';
    })
  });
});