define('tinybeans-frontend/controllers/main/journals/search', ['exports', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_commonsMixin.default, {
    // queryParams: ['setup'],
    needs: ['main'],

    resultCount: function () {
      return this.get('resultCount');
    }.property('resultCount'),

    hasResults: function () {
      return this.get('hasResults');
    }.property('hasResults'),

    showLoadMore: function () {
      return this.get('showLoadMore');
    }.property('showLoadMore'),

    pageNumber: function () {
      return this.get('pageNumber');
    }.property('pageNumber'),

    sortOrder: function () {
      return this.get('sortOrder');
    }.property('sortOrder'),

    resultEntries: function () {
      return this.get('resultEntries');
    }.property('resultEntries'),

    numberOfFollowersMinusOwner: function () {
      var realFollowers = this.get('numberOfFollowers');
      return realFollowers;
    }.property('numberOfFollowers'),

    isTinyScreen: function () {
      var width = $(window).width();
      return width <= 600;
    }.property(),

    isMobileScreen: function () {
      var width = $(window).width();
      return width <= 1210;
    }.property(),

    isOldPremium: Ember.computed('model.journal', function () {
      return this.globals.premiumTier(this.get('model.journal'));
    }),

    searchPlaceholder: Ember.computed('model.journal', function () {
      return this.get('model.journal.title') ? "Search " + this.get('model.journal.title') : "Search...";
    }),

    actions: {

      backToTop: function backToTop() {
        Ember.$(window).scrollTop(0);
      },

      searchKeyword: function searchKeyword() {
        Ember.$('#search-demo').hide();
        Ember.$('.spin').css('visibility', 'visible');
        Ember.$('#back-to-top-container').hide();

        var self = this;
        var keyword = Ember.$("#search").val();
        var currentSortOrder = this.get('sortOrder');
        var currentPageNumber = this.get('pageNumber');

        if (!keyword) {
          bootbox.dialog({
            title: "Type a word into the Search bar",
            message: "Add some key words in the search bar to find moments in your journal",
            buttons: {
              cancel: {
                label: 'Got it',
                className: 'btn btn-mint'
              }
            }
          });
        } else {
          var journalId = self.get("model.journalId");
          Ember.$('#no-result-message').html("");

          var sortOrder = currentSortOrder ? currentSortOrder : "DD";
          var pageNumber = currentPageNumber ? currentPageNumber : 1;

          if (pageNumber == 1) {
            self.set("resultEntries", null);
          }

          if (sortOrder == "DD") {
            Ember.$('#latestFirst').addClass("active");
            Ember.$('#earliestFirst').removeClass("active");
          } else {
            Ember.$('#latestFirst').removeClass("active");
            Ember.$('#earliestFirst').addClass("active");
          }

          var requestLength = 72;
          this.set('globals.searchKeyword', keyword);

          self.API.searchEntriesByKeyword(keyword, sortOrder, pageNumber, requestLength, journalId, function (data) {
            //success
            var resultEntries = data.entries ? data.entries : null;
            var count = data.count ? data.count : 0;
            self.set('resultCount', count);
            Ember.$('#result-count').show();

            if (data.entries != null && data.entries.length >= 1) {

              if (data.entries.length >= 10) {
                Ember.$('#back-to-top-container').show();
              }

              Ember.$('#searchSortOrder').show();
              self.set("hasResults", true);
              Ember.$("#no-result-container").hide();
              var months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

              Ember.$.each(resultEntries, function (idx, result) {
                var trimmedCaption = result.caption.length >= 60 ? result.caption.substring(0, 60) + "..." : result.caption;

                // result.captionString = highlightSearch(keyword, trimmedCaption);

                //highlight search keyword
                function highlightKeyword(keyword) {
                  var className = "highlightedKeyword";
                  //var regex = new RegExp("\\b"+keyword+"\\b", "gi");
                  var regex = new RegExp(keyword, "gi");
                  return trimmedCaption.replace(regex, function (matched) {
                    return "<span class=\"" + className + "\">" + matched + "</span>";
                  });
                }
                //highlight search keyword)
                result.captionString = Ember.String.htmlSafe(highlightKeyword(keyword));

                result.dateString = result.day + " " + months[result.month] + " " + result.year;
                result.isPhoto = result.type == "PHOTO";
                result.isVideo = result.type == "VIDEO";
                result.isText = result.type == "TEXT";
              });

              // function highlightSearch(userKeyword, captionString) {
              //   var text = userKeyword;
              //   var query = new RegExp("(\\b" + text + "\\b)", "gim");
              //   var e = captionString;
              //   var enew = e.replace(/(<em>|<\/em>)/igm, "");
              //   return enew;
              // }


              if (requestLength * (pageNumber - 1) + resultEntries.length < count) {
                self.set("showLoadMore", true);
              } else {
                self.set("showLoadMore", false);
              }

              var finalResultEntries = [];
              if (pageNumber > 1) {
                var existEntries = self.get('resultEntries');
                finalResultEntries = existEntries.pushObjects(resultEntries);
              } else {
                finalResultEntries = resultEntries;
              }
              self.set("resultEntries", finalResultEntries);
            } else {
              //case for no further results retrieved, return to previous entries
              if (pageNumber != 1) {
                return;
              } else {
                self.set("hasResults", false);
                self.set("showLoadMore", false);
                self.set("resultEntries", null);
                Ember.$('#no-result-message').html("We couldn't find anything for <b>" + keyword.toString() + "</b> in your captions. Want to try something else?");
                Ember.$('#no-result-container').show();
                Ember.$('#result-count').hide();
                Ember.$('#searchSortOrder').hide();
              }
            }

            Ember.$('.spin').css('visibility', 'hidden');
          }, function (xhr, statusText, e) {
            //fail        );
            self.onApiError(xhr, statusText, e);
          });
        }
        Ember.$('.spin').css('visibility', 'hidden');
      },

      loadMoreEntries: function loadMoreEntries() {
        var newPageNumber = this.get('pageNumber') + 1;
        this.set('pageNumber', newPageNumber);
        this.send('searchKeyword');
      },

      search: function search() {
        this.set("pageNumber", 1);
        this.send("searchKeyword");
      },

      sampleSearch: function sampleSearch(keyword) {
        this.globals.searchKeyword = keyword;
        Ember.$("#search").val(keyword);
        this.send("searchKeyword");
      },
      // TODO sort without another request
      orderDD: function orderDD() {
        this.set("sortOrder", "DD");
        this.set("pageNumber", 1);
        this.send("searchKeyword");
      },

      orderDA: function orderDA() {
        this.set("sortOrder", "DA");
        this.set("pageNumber", 1);
        this.send("searchKeyword");
      },

      gotoEntry: function gotoEntry(entry) {
        var self = this;
        self.transitionToRoute('main.entries.view', entry.id, entry.uuid);
      },

      gotoPremiumPage: function gotoPremiumPage(journalId) {
        var self = this;
        self.transitionToRoute('main.subscription-products.list', {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      }
    }
  });
});