define('tinybeans-frontend/adapters/child', ['exports', 'tinybeans-frontend/adapters/application', 'tinybeans-frontend/mixins/api-mixin', 'tinybeans-frontend/services/lotame'], function (exports, _application, _apiMixin, _lotame) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _application.default.extend(_apiMixin.default, {
    REST: Ember.inject.service(),

    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      var data = this.serialize(snapshot);
      data.journalId = parseInt(snapshot.adapterOptions.journalId);
      data.userRelationshipToChild = snapshot.adapterOptions.userRelationshipToChild;
      var file = snapshot.adapterOptions.file;

      //Send data to Lotame
      var birthdate = data.dob.split('-');
      var behavior = 'child added';
      var interest = [' : year of birth : ' + birthdate[0], ' : month of birth : ' + birthdate[1]].concat(_toConsumableArray(data.userRelationshipToChild ? [' : relationship to child : ' + data.userRelationshipToChild] : []), [' : gender : ' + data.gender]);

      // const lotame = new Lotame();
      // lotame.sendBehavior(behavior, interest, this.get("globals.userId"));
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('REST').POSTWITHFILE('/api/1/children', data, resolve, reject, file);
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this2 = this;

      var data = this.serialize(snapshot);
      data.journalId = parseInt(snapshot.adapterOptions.journalId);
      var file = snapshot.adapterOptions.file;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('REST').POSTWITHFILE('/api/1/children/' + snapshot.id, data, resolve, reject, file);
      });
    }
  });
});