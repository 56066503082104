define('tinybeans-frontend/models/collection-entry', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    URL: (0, _attr.default)('string'),
    sortOrder: (0, _attr.default)('number'),
    deleted: (0, _attr.default)('boolean'),
    timestamp: (0, _attr.default)('number'),
    pinnedTimestamp: (0, _attr.default)('number'),
    lastUpdatedTimestamp: (0, _attr.default)('number'),
    tag: (0, _relationships.belongsTo)('collection', { inverse: 'tagEntries' }),
    entry: (0, _relationships.belongsTo)('entry')
  });
});