define('tinybeans-frontend/routes/main/journals/add-moment', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker', 'tinybeans-frontend/mixins/commons-mixin', 'tinybeans-frontend/config/environment'], function (exports, _baseProtectedRoute, _tracker, _commonsMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend(_commonsMixin.default, {
    category: _tracker.AddMoment.category,
    screen: _tracker.AddMoment.screen,

    model: function model(params) {
      var journalId = params.journal_id || (this.modelFor('main').currentJournal ? this.modelFor('main').currentJournal.id : NaN);
      var date;
      if (params.year && params.month && params.day) {
        date = moment(new Date(params.year, params.month - 1, params.day));
      } else {
        date = moment();
      }
      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        memoriesAccess: this.API.hasMemoriesAccess(journalId),
        followings: this.modelFor('main').followings,
        memories_limit: this.API.getMemoriesLimit(),
        entries: this.store.query('entry', {
          filter: {
            journalId: journalId,
            year: date.year(),
            month: date.month() + 1,
            day: date.date()
          }
        }),
        followers: this.store.query('follower', {
          filter: {
            journalId: journalId
          }
        }),
        date: date.format(this.globals.usersDateFormat())
      });
    },
    setupController: function setupController(controller, model, error) {
      Ember.Logger.debug(error.message);
      this._super(controller, model);
      // Subscription LP Updated
      Leanplum.addVariablesChangedHandler(function () {
        var vars = Leanplum.getVariables();
        if (vars.Paywall) {
          var paywall = vars.Paywall;
          paywall = JSON.parse(paywall.products);
          controller.set('trialCopy', paywall.title);
          controller.set('trialFeatures', paywall.paidFeatures);
        } else {
          var paidFeatures = ['Unlimited video and photo storage', 'Private sharing of memories with friends and family', 'Free shipping on photo books', 'Events, advice, activities and more curated for your family'];
          controller.set('trialCopy', 'Try us risk-free for 30 days');
          controller.set('trialFeatures', paidFeatures);
        }
      });

      if (model.memoriesAccess.hasMemoriesAccess) {
        controller.set('isSubscribedUser', true);
      } else {
        controller.set('isSubscribedUser', false);
      }
      // Free User Limits
      if (model.memories_limit.limit.threshold < 0 || !(model.memories_limit.limit.threshold === model.memories_limit.limit.usedTimes)) {
        controller.set('canUploadMemories', true);
        if (model.memories_limit.limit.threshold < 0) {
          model.memories_limit.limit.usedTimes = 0;
        } else {
          if (model.memories_limit.limit.usedTimes < model.memories_limit.limit.threshold) {
            controller.set('uploadsRemaining', model.memories_limit.limit.threshold - model.memories_limit.limit.usedTimes);
          } else {
            controller.set('uploadsRemaining', "0");
          }
        }
      } else {
        controller.set('canUploadMemories', false);
        controller.set('uploadsRemaining', "0");
      }
      controller.set('memoriesThreshold', model.memories_limit.limit.threshold);
      controller.set('date', model.date);
      controller.set('entriesToUpload', []);
      controller.addNewEntry();
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({ year: undefined, month: undefined, day: undefined });
      }
    },


    actions: {
      error: function error(_error, transition) {
        console.log(_error);
        Sentry.captureException(_error, {
          tags: {
            section: "Add-Moment-Routes"
          }
        });
        //this.replaceWith('/not-found');
      },
      didTransition: function didTransition() {
        this._super();
        this.send('trackOnboarding', 'add-follower', 'load');
      }
    }
  });
});