define('tinybeans-frontend/routes/main/onboarding/step-four', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Onboarding.category,
    screen: _tracker.Onboarding.step4.screen,

    model: function model(params) {
      var journalId = params.journal_id || (this.modelFor('main').currentJournal ? this.modelFor('main').currentJournal.id : NaN);

      return Ember.RSVP.hash({
        journalId: journalId,

        //      hasAddedEntry: params.entryAdded,
        //      hasAddedChild: params.childAdded,

        journal: this.store.find('journal', journalId),
        invite: {
          firstName: null,
          lastName: null,
          emailAddress: null,
          viewEntries: true,
          addEntries: false,
          viewMilestones: true,
          editMilestones: false,
          message: null
        },
        relations: this.API.getFollowerRelations()
      });
    },


    setupController: function setupController(controller, model) {

      this._super(controller, model);

      controller.set('submitting', false);
      controller.set('showMessage', false);

      var journal = model.journal;

      if (journal) {
        controller.set('journalId', journal.id);
      }

      controller.set('journal', model.journal);
      controller.set('invite', model.invite);

      //controller.set("hasAddedEntry", model.hasAddedEntry ? model.hasAddedEntry: false);
      //controller.set("hasAddedChild", model.hasAddedChild ? model.hasAddedChild:false);
    },

    renderTemplate: function renderTemplate() {
      this.render('main/onboarding/step-four', { controller: 'main.onboarding.step-four' });
    },

    actions: {
      reloadPage: function reloadPage() {
        this.refresh();
      }
    }
  });
});