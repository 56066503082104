define('tinybeans-frontend/adapters/user', ['exports', 'tinybeans-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      var url = '/api/1/userByEntryAndToken/' + query.entryId + '/' + query.userToken;
      return this.ajax(url, 'GET');
    }
  });
});