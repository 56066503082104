define('tinybeans-frontend/routes/main/subscription-products/confirmation', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({

    model: function model(params) {
      var journalId = params.journal_id;
      return Ember.RSVP.hash({
        journalId: journalId,
        journal: this.store.get('journal', journalId)
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var journalId = model.journalId;
      controller.set('journalId', journalId ? journalId : null);
    }
  });
});