define("tinybeans-frontend/controllers/signup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      window.location.replace("/sign-up");
    }
  });
});