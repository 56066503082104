define('tinybeans-frontend/adapters/channel-subscription', ['exports', 'tinybeans-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    query: function query(store, type, _query) {
      var url = '/api/1/children/' + _query.childId + '/subscriptions-full';
      return this.ajax(url, 'GET');
    }
  });
});