define('tinybeans-frontend/adapters/following', ['exports', 'tinybeans-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var journalId = snapshot.adapterOptions.journalId;
      var url = '/api/1/followings/' + journalId;
      return this.ajax(url, 'DELETE', {});
    }
  });
});