define('tinybeans-frontend/routes/main/journals/add', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        me: this.store.find('user', 'me'),
        journal: this.store.createRecord('journal', {
          title: null,
          coverTheme: null
        }),
        myJournals: this.modelFor('main').myJournals,
        followings: this.modelFor('main').followings,
        journals: this.modelFor('main').journals,
        coverThemes: this.API.listCoverThemes()
      });
    },


    afterModel: function afterModel(model) {
      if (model.myJournals && model.myJournals.get('length') > 0) {
        this.transitionTo('main.journals.list');
      }
      model.journal.user = model.me;
    },

    setupController: function setupController(controller, model) {

      this._super(controller, model);

      var journal = model.journal ? model.journal : null;

      journal.set('coverTheme', model.coverThemes.objectAt(0));
    },

    renderTemplate: function renderTemplate() {
      this.render('main/journals/edit', {
        controller: 'main.journals.add'
      });
    }
  });
});