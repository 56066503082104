define('tinybeans-frontend/routes/main/journals/follower-list', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Followers.category,
    screen: _tracker.Followers.list.screen,

    model: function model(params) {
      var journalId = (params.journal_id === "undefined" ? undefined : params.journal_id) || (this.modelFor('main').currentJournal ? this.modelFor('main').currentJournal.id : NaN);
      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journals: this.store.findAll('journal'),
        followers: this.store.query('follower', {
          filter: {
            journalId: journalId
          }
        }),
        invitees: this.store.query('invitee', {
          filter: {
            journalId: journalId
          }
        }),
        followings: this.modelFor('main').followings,
        journal: this.store.find('journal', journalId),
        relations: this.API.getFollowerRelations()
      });
    },
    afterModel: function afterModel(model) {
      this.controllerFor('main').set('model.currentJournal', model.journal);
      var followerArray = model.followers.toArray();
      _lodash.default.each(followerArray, function (follower) {
        follower.relationship = follower.relationship || {};
      });
      // model.user = model.me;
    },
    deactivate: function deactivate() {
      Ember.$('.modal').modal('hide');
      this.disconnectOutlet({
        outlet: 'modal-dialog'
      });
    },


    setupController: function setupController(controller, model) {
      this._super(controller, model);

      //GROW-1755 fixes, please check again
      var journal = model.journals.objectAt(0);
      if (journal) {
        controller.set('journalId', journal.id);
      }
      //GROW-1755 fixes, please check again


      // commented out for bug fix

      // var currentJournal = model.currentJournal ? model.currentJournal : NaN;
      // if (currentJournal) {
      //   controller.get('controllers.main').set('currentJournal', currentJournal);
      // }

      // commented out for bug fix

      controller.set('noFollowerYet', !model.followers || model.followers.length === 1);
    }
  });
});