define('tinybeans-frontend/controllers/main/refer-friend/show', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _baseController, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {

    submitting: false,
    saveButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? 'Please wait ...' : 'Next';
    }.property('submitting'),

    actions: {
      showDetailsPopup: function showDetailsPopup(para) {
        var message = "";
        var referMessage = "For every parent you refer who starts their own Tinybeans Journal, we'll give you BOTH a free month of Tinybeans Premium.<br/><br/> Existing Premium users will get an extra month added to the end of their current subscription. <br/><br/>There's no limit on the number of friends you can refer, so feel free to go nuts! We'll email you when a friend signs up.";
        var screenMesssage = "Post a review of Tinybeans in a parenting forum such as <a href='http://www.thebump.com/community' target='_blank'>The Bump</a>, <a href='http://community.babycenter.com' target='_blank'>BabyCenter</a>, <a href='http://www.whattoexpect.com/forums/' target='_blank'>What to Expect</a>, <a href='https://www.reddit.com/r/babybumps' target='_blank'>Reddit</a> or a private <a href='https://www.facebook.com/' target='_blank'>Facebook</a> parenting group. <br/><br/>Then send a screenshot of the review to <a href='mailto:info@tinybeans.com?subject=Check%20out%20my%20screenshot'>info@tinybeans.com</a> and we'll give you a free month of Tinybeans Premium. <br/><br/>Existing Premium users will get an extra month added to the end of their current subscription. <br/><br/>Please note we AREN'T allowed to incentivise reviews in the App Store or Google Play.";
        var flyerMessage = "Register your postal address with us <a href='https://docs.google.com/forms/d/e/1FAIpQLSfuJN5AAQV68l_47eR5MCMw8rFRteLS1SDCEJJD6-845jRJag/viewform?usp=send_form' target='_blank'>here</a> and we will send you a pack of Tinybeans flyers to take to your local OB/GYN clinic, childcare, play center or anywhere where parents hang out. <br/><br/>Then send a fun photo of the flyers in their new home to <a href='mailto:info@tinybeans.com?subject=Share%20flyers'>info@tinybeans.com</a> and we'll give you a free month of Premium. Existing Premium users will get an extra month added to the end of their current subscription.";
        message = para == "refer" ? referMessage : para == "screen" ? screenMesssage : para == "flyer" ? flyerMessage : "";

        bootbox.dialog({
          title: 'How it works',
          message: message,
          buttons: {
            ok: {
              label: 'Close',
              className: 'btn btn-default'
            }
          }
        });
      },

      // showReferPopup: function() {
      //   this.set('displayReferPopup', true);
      // },
      //
      // hideReferPopup: function() {
      //   this.set('displayReferPopup', false);
      // },
      //
      //
      //
      // save: function() {
      //   //submitReferrals
      //   var self = this;
      //   var emailAddress = $('.referrralUserEmailAddress').val();
      //
      //   var emailValid = validateEmail(emailAddress);
      //   self.set('submitting', true);
      //
      //   if (!emailValid) {
      //     bootbox.dialog({
      //       title: 'Uh oh!',
      //       message: 'Double check the email you just typed.',
      //       buttons: {
      //         ok: {
      //           label: 'Close',
      //           className: 'btn btn-default'
      //         }
      //       }
      //     });
      //   } else {
      //
      //     self.API.submitReferrals(emailAddress,
      //       function() {
      //         $('.refer-form .alert-success').show().delay(5000).fadeOut(1000);
      //         self.transitionToRoute('main.refer-friend.show');
      //       },
      //       function() {
      //         $('.refer-form .alert-danger').show().delay(3000).fadeOut(1000);
      //         $('.refer-form .referrralUserEmailAddress').val('');
      //         self.transitionToRoute('main.refer-friend.show');
      //       });
      //
      //   }
      // },

      copyToClipboard: function copyToClipboard() {
        var self = this;
        var referralUrl = self.get('referralUrl');
        var $temp = $("<input>");

        $(".refer-form").append($temp);
        $temp.val(referralUrl).select();

        document.execCommand('copy');
        $temp.remove();

        $('.refer-form .btn-copy').text("copied");
      }

    }

  });
});