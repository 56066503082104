define('tinybeans-frontend/controllers/main/subscription/manage', ['exports', 'tinybeans-frontend/controllers/base-controller', 'lodash/lodash'], function (exports, _baseController, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({

    actions: {
      inviteFollower: function inviteFollower() {
        var self = this;
        this.transitionToRoute('main.journals.follower-list-invite', self.model.journalId);
      },
      onSelectEntityType: function onSelectEntityType(value) {
        if (value !== "none") {
          this.set('selectedPerson', value);
          this.set('awaitingSeat', false);
        } else {
          this.set('awaitingSeat', true);
        }
      },
      deleteSubscriptionSeat: function deleteSubscriptionSeat() {
        var followerID = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var self = this;
        if (!followerID) followerID = this.get('selectedPerson');
        if (followerID) {
          bootbox.dialog({
            centerVertical: true,
            className: 'tny-share-seat',
            title: '',
            message: 'Are you sure you want to remove this person?',
            buttons: {
              ok: {
                label: 'Remove',
                callback: function callback() {
                  var removed = self.API.removeSubscriptionSeat(followerID);
                  // console.log(removed);
                  self.set('hasSeatedFollower', false);
                  self.set('hasSeatsRemaining', undefined);
                  self.set('hasSeatsRemaining', true);
                  self.model.set('hasSeatsRemaining', { seatsLeft: 1 }); // have to fix this if we ever offer more than one seat
                  self.model.set('seated', null);
                  self.set('deletingSeat', true);
                  self.set('selectedPerson', "none");
                  self.route.refresh();
                }
              },
              cancel: {
                label: 'Cancel',
                className: "btn-cancel"
              }
            }
          });
        }
      },
      setSubscriptionSeat: function setSubscriptionSeat() {
        var followerId = this.get('selectedPerson');
        var seated = this.API.setSubscriptionSeat(followerId);
        var self = this;
        seated.then(function () {
          if (followerId) {
            // this.send('displayNotification', `Your subscription was successfully shared.`);
            self.set('hasSeatedFollower', true);
            self.set('hasSeatsRemaining', false); // Needs to change if we ever have more than one seat
            self.model.set('deletingSeat', false);
            self.route.refresh();
          } else {
            // console.log("No followerid");
          }
        });
      },
      upgradeFutureSubscription: function upgradeFutureSubscription() {
        this.transitionToRoute("main.subscription-products.list", { queryParams: { isLegacy: false } });
      }
    },
    getSeatsInfo: function getSeatsInfo(followers, me) {
      var assigned = false;
      var holders = [];
      _lodash.default.each(followers.caregivers, function (follower) {
        if (parseInt(me) === parseInt(follower.seatGivenBy)) {
          holders.push(follower.user);
        }
      });
      // console.log(holders);
      return holders;
    }
  });
});