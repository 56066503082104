define('tinybeans-frontend/controllers/main/subscription-products/select-journal', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/tracker'], function (exports, _baseController, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    queryParams: ['curSelectedProductId', 'journalid'],

    // subscriptionProducts: null,
    amountInCents: null,
    acceptTerms: false,
    showCouponOption: true,
    // journalId: null,
    couponApplied: false,
    // pleaseSelectJournal: false,

    moreThanOneJournal: function () {
      return this.get('model.followings.length') > 1;
    }.property('model.followings'),

    isExistingCustomer: function () {
      return !!this.get('card');
    }.property('card'),

    actions: {
      preValidate: function preValidate() {
        if (this.get('journalId')) {
          return true;
        }
        this.set('pleaseSelectJournal', true);
        var $form = Ember.$("form#payment-form");
        $form.find('button').prop("disabled", false);
        $form.find("input:visible").prop("disabled", false);
        $form.closest("#payment-details-modal").find(".progress").addClass("hide");
        $form.closest("#payment-details-modal").find("#progress .progress-bar").removeClass("active").addClass("hide");
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
        return false;
      },
      paymentCallback: function paymentCallback(data) {
        var _this = this;

        this.transitionToRoute('main.subscription-products.success').then(function (newRoute) {
          newRoute.controller.set('selectedProduct', _this.get('selectedProduct'));
          newRoute.controller.set('paymentTransaction', data.paymentTransaction);
          newRoute.controller.set('journalId', _this.get('journalId'));
        });
      },
      track: function track(what) {
        var action = 'unknown';
        switch (what) {
          case 'apply-coupon':
            action = _tracker.Premium.selectJournal.applyCoupon;
            break;
          case 'pay':
            action = _tracker.Premium.selectJournal.completePayment;
            break;
          default:
            break;
        }
        _tracker.Tracker.trackEvent({
          category: _tracker.Premium.category,
          screen: _tracker.Premium.selectJournal.screen,
          action: action
        });
      }
    }

  });
});