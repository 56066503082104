define('tinybeans-frontend/routes/main/subscription-products/voucher-success', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({

    model: function model(params) {
      var journalId = params.journal_id;
      return Ember.RSVP.hash({
        journalId: journalId,
        journal: this.store.get('journal', journalId),
        followings: this.store.findAll('following'),
        journals: this.store.findAll('journal')
      });
    },

    setupController: function setupController(controller, model) {
      var self = this;
      self._super(controller, model);
      var journalId = model.journalId;

      controller.set('journalId', journalId ? journalId : null);
      controller.set('message', "Your voucher has been successfully redeemed.");
    },

    actions: {
      transition: function transition() {
        if (this.get('journalId')) {
          this.transitionTo('main.journals.view', this.get('journalId'));
        } else {
          this.transitionTo('main.journals.home');
        }
      }
    }
  });
});