define('tinybeans-frontend/models/invitee', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cli-copyable'], function (exports, _model, _attr, _relationships, _emberCliCopyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_emberCliCopyable.default, {
    URL: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    emailAddress: (0, _attr.default)('string'),
    viewEntries: (0, _attr.default)('boolean'),
    addEntries: (0, _attr.default)('boolean'),
    viewMilestones: (0, _attr.default)('boolean'),
    editMilestones: (0, _attr.default)('boolean'),
    coOwner: (0, _attr.default)('boolean'),
    relationship: (0, _attr.default)(),
    emailFrequencyOnNewEvent: (0, _relationships.belongsTo)('nameLabel', { async: false }),
    timestamp: (0, _attr.default)('number'),
    journalId: (0, _relationships.belongsTo)('journal'),
    journal: (0, _relationships.belongsTo)('journal'), // used if we get followings (followings is the first thing loaded so it's good to use the embedded journal objects here)
    avatars: (0, _relationships.belongsTo)('blobs', { async: false }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName') || '';
      var lastName = this.get('lastName') || '';
      var fullName = firstName + ' ' + lastName;
      return '' + (fullName.length <= 18 ? fullName : fullName.substring(0, 18) + "...");
    }),
    pending: Ember.computed(function () {
      return true;
    }),

    order: Ember.computed('fullName', 'relationship', function () {
      var prefix = '';
      switch (this.get('relationship.name')) {
        case 'MOTHER':
          prefix = String.fromCharCode(0);
          break;
        case 'STEPMOTHER':
          prefix = String.fromCharCode(1);
          break;
        case 'BIRTHMOTHER':
          prefix = String.fromCharCode(2);
          break;
        case 'FATHER':
          prefix = String.fromCharCode(3);
          break;
        case 'STEPFATHER':
          prefix = String.fromCharCode(4);
          break;
        case 'BIRTHFATHER':
          prefix = String.fromCharCode(5);
          break;
        case 'GRANDMOTHER':
          prefix = String.fromCharCode(6);
          break;
        case 'GRANDFATHER':
          prefix = String.fromCharCode(7);
          break;
        default:
          break;
      }
      return prefix + this.get('fullName').toLowerCase();
    })
  });
});