define('tinybeans-frontend/components/main/payment-details/card-brand', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    cardBrand: function () {
      var card = this.get('card');
      var brand = card ? card.brand.toLowerCase() : "unknown";
      return "card_" + brand;
    }.property()
  });
});