define('tinybeans-frontend/controllers/main/collections/show', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {

    canSeeCollection: Ember.computed('model.journalId', function () {
      return this.get('model.collection.isPublic') || (this.get('model.me') ? this.get('canViewEntries') : false);
    }),

    tagName: Ember.computed('model.collection', function () {
      return this.get('model.collection.name.length') > 35 ? this.get('model.collection.name').substring(0, 35) + '...' : this.get('model.collection.name');
    }),

    tagDescription: Ember.computed('model.collection', function () {
      return this.get('model.collection.description.length') > 80 ? this.get('model.collection.description').substring(0, 80) + '...' : this.get('model.collection.description');
    }),

    tagEntries: Ember.computed('model.collection', function () {
      return _lodash.default.select(this.get('model.collection.tagEntries').toArray(), function (entry) {
        return !entry.get('deleted');
      });
    }),

    slideShowEntries: Ember.computed('tagEntries', function () {
      var startHorizontalPositions = ['left', 'left', 'left'];
      var startVerticalPositions = ['center', 'center', 'center'];
      var initialZooms = [0.6, 0.7, 0.8, 0.9];
      //  var initialZooms = [0.8, 0.9, 1, 1.1];
      //  var finalZooms = [0.85, 0.95, 1.05];
      var idx = 0;
      var entries = _lodash.default.map(this.get('tagEntries'), function (tagEntry) {
        if (tagEntry.get('entry.type') === 'PHOTO' && tagEntry.get('entry.attachmentType') !== "VIDEO") {

          var obj = {
            initialZoom: initialZooms[Math.floor(Math.random() * initialZooms.length)],
            textId: '#' + tagEntry.get('entry.id'),
            autoPlay: 10,
            orientationClass: 'slide-show-image ' + tagEntry.get('entry.orientation'),
            verticalPosition: 'center',
            date: new Date(tagEntry.get('entry.year'), tagEntry.get('entry.month') - 1, tagEntry.get('entry.day')),
            dataDuration: 2,
            // formattedDate: moment(tagEntry.get('entry.date')).format('MMM D, YYYY'),   this line adds 1 more month to the collection entry date
            formattedDate: moment(new Date(tagEntry.get('entry.year'), tagEntry.get('entry.month') - 1, tagEntry.get('entry.day'))).format('MMM D, YYYY'),
            textInitialLeft: 100,
            textInitialTop: 86,
            textInitialScale: 1,
            textFinalScale: 1,
            textFinalLeft: 100,
            textFinalTop: 86,
            textDuration: 0.5,
            textFadeStart: 0,
            textDelay: 0,
            textClass: 'bannerscollection_zoominout_text_line textElement' + idx++ + '_opportuneFullScreen',
            entry: tagEntry.get('entry')
          };

          obj.finalZoom = obj.initialZoom + (obj.initialZoom >= 0.8 ? -0.15 : 0.15);
          if (tagEntry.get('entry.orientation') === 'landscape') {
            obj.horizontalPosition = startHorizontalPositions[Math.floor(Math.random() * startHorizontalPositions.length)];
            obj.verticalPosition = 'center';
          } else if (tagEntry.get('entry.orientation') === 'portrait') {
            obj.horizontalPosition = 'left';
            obj.verticalPosition = startVerticalPositions[Math.floor(Math.random() * startVerticalPositions.length)];
          } else {
            obj.horizontalPosition = startHorizontalPositions[Math.floor(Math.random() * startHorizontalPositions.length)];
            obj.verticalPosition = startVerticalPositions[Math.floor(Math.random() * startVerticalPositions.length)];
          }
          return obj;
        } else {
          return null;
        }
      });
      return _lodash.default.compact(entries);
    }),

    actions: {

      backToTop: function backToTop() {
        Ember.$(window).scrollTop(0);
      },

      addMomentsToCollection: function addMomentsToCollection(journalId, path, year, month) {
        this.transitionToRoute('main.collections.edit', journalId, path, year, month);
        this.send('track', 'add-moment');
      },

      reorderCollection: function reorderCollection(journalId, path) {
        this.transitionToRoute('main.collections.organize', journalId, path);
        this.send('track', 'organize');
      },

      shareCollectionOnFacebook: function shareCollectionOnFacebook(collection) {
        var self = this;
        if (!self.get('facebookToken')) {
          self.API.loginToFacebook(function (data) {
            self.set('facebookToken', data.facebookAccessToken);
            self.API.shareCollectionOnFacebook(collection.tag.id, collection.tag.journalId, self.get('facebookToken'), function () {
              // collection.sharedOnFacebook.pushObject(true);
            }, self);
          });
        } else {
          self.API.shareCollectionOnFacebook(collection.tag.id, collection.tag.journalId, self.get('facebookToken'), function () {
            //entry.sharedOnFacebook.pushObject(true);
          }, self);
        }
      },

      startSlideShow: function startSlideShow() {
        Ember.$('.bannerscollection_zoominout_text_line').hide();
        Ember.$('#btn-start-slide-show').hide();
        Ember.$('#btn-stop-slide-show').show();
        Ember.$("#main-nav-wrapper").hide();
        this.set('slideShowMode', true);
        this.set('displayMode', false);
        this.send('track', 'start-slide-show');
      },

      stopSlideShow: function stopSlideShow() {
        Ember.$('#btn-stop-slide-show').hide();
        //location.reload();
        Ember.$('#btn-start-slide-show').show();
        Ember.$("#main-nav-wrapper").show();
        this.set('slideShowMode', false);
        this.set('displayMode', true);
      },

      editCollectionInfo: function editCollectionInfo() {

        var self = this;
        var journalId = self.get('model.journalId');
        var collection = self.get('collection');

        var form = Ember.$('<div/>').attr('id', 'editCollection').addClass('form-group');
        form.append(Ember.$('<input/>').attr('id', 'tagName').attr('placeholder', 'Album title').addClass('form-control').val(collection.get('name') !== 'Untitled' ? collection.get('name') : ''));
        form.append(Ember.$('<textarea/>').attr('id', 'tagDescription').attr('placeholder', 'Album description').addClass('form-control').val(collection.get('description') !== 'Untitled' ? collection.get('description') : ''));

        // this might need for future when collection can set private/public
        // form.append(Ember.$('<input/>').attr('id','isPublic').val(collection.tag.isPublic));

        this.send('track', 'edit-details');
        bootbox.dialog({
          title: 'Edit album title and description',
          message: form,
          buttons: {
            ok: {
              label: 'Save',
              className: 'btn-primary',
              callback: function callback() {

                if (!Ember.$('.bootbox').find('#tagName').val()) {
                  bootbox.dialog({
                    title: 'Oops',
                    message: 'Give your album a title. For example, first birthday',
                    buttons: {
                      ok: {
                        label: 'Got it',
                        className: 'btn btn-default'
                      }
                    }
                  });
                  return;
                }
                collection.set('name', Ember.$('.bootbox').find('#tagName').val());
                collection.set('description', Ember.$('.bootbox').find('#tagDescription').val());

                self.API.updateCollectionInfo(journalId, collection.get('id'), collection, function (data) {
                  //success
                  var newTagName = data.tag.name;
                  if (newTagName) {
                    newTagName = newTagName.length > 35 ? newTagName.substring(0, 35) + "..." : newTagName;
                  } else {
                    newTagName = "Untitled";
                  }
                  var newTagDescription = data.tag.description;
                  if (newTagDescription) {
                    newTagDescription = newTagDescription.length > 80 ? newTagDescription.substring(0, 80) + "..." : newTagDescription;
                  } else {
                    newTagDescription = "";
                  }

                  self.set('tagName', newTagName);
                  self.set('tagDescription', newTagDescription);
                  _tracker.Tracker.trackEvent({
                    category: _tracker.PhotoCollections.category,
                    screen: _tracker.PhotoCollections.details.screen,
                    action: _tracker.PhotoCollections.details.saveButton
                  });
                }, function () {
                  //fail
                  bootbox.dialog({
                    title: 'Fail',
                    buttons: {
                      ok: {
                        label: 'Close',
                        className: 'btn btn-default'
                      }
                    }
                  });
                });
              }
            },
            cancel: {
              label: 'Cancel',
              className: "btn-default",
              callback: function callback() {
                _tracker.Tracker.trackEvent({
                  category: _tracker.PhotoCollections.category,
                  screen: _tracker.PhotoCollections.details.screen,
                  action: _tracker.PhotoCollections.details.cancelButton
                });
              }
            }
          }
        });

        setTimeout(function () {
          Ember.$('.bootbox').find('input').focus();
        }, 1000);
      },

      showEntry: function showEntry(entry) {
        this.transitionToRoute('main.entries.view', entry.get('id'), entry.get('uuid'));
      },

      deleteCollection: function deleteCollection() {
        var self = this;
        var journalId = this.get('model.journalId');
        var tagId = this.get('model.collection.id');
        var path = this.get('model.path');

        var selectedEntryIds = [];
        selectedEntryIds.push(parseInt(tagId));

        bootbox.dialog({
          title: "Are you sure you want to delete this album?",
          message: "If you delete this album, you'll have to start from scratch again.",
          buttons: {
            ok: {
              label: 'Delete',
              className: 'btn-default',
              callback: function callback() {
                self.API.deleteCollection(journalId, selectedEntryIds, function () {
                  Ember.get(self, 'flashMessages').clearMessages();
                  Ember.get(self, 'flashMessages').add({
                    message: 'Album deleted',
                    timeout: 3000,
                    listMessage: true,
                    type: 'alert alert-success',
                    glyphiconClass: 'glyphicon glyphicon-ok'
                  });

                  self.send("reloadApplicationRoute");
                  self.transitionToRoute('main.collections.list', journalId);
                }, function () {
                  //delete fail
                  bootbox.dialog({
                    title: 'Oops! Cannot delete this album, try again later.',
                    buttons: {
                      cancel: {
                        label: 'Close',
                        className: 'btn-default',
                        callback: function callback() {
                          self.transitionToRoute('main.album.show', journalId, path);
                        }
                      }
                    }
                  });
                });
              }
            },
            cancel: {
              label: 'Cancel',
              className: 'btn-primary'
            }
          }
        });
        this.send('track', 'delete');
      },

      upgrade: function upgrade(journalId) {
        this.transitionToRoute('main.subscription-products.list', {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      },

      track: function track(what) {
        var action = 'unknown';

        switch (what) {
          case 'add-moment':
            action = _tracker.PhotoCollections.show.addMomentToCollectionButton;
            break;
          case 'start-slide-show':
            action = _tracker.PhotoCollections.show.slideShowButton;
            break;
          case 'share-twitter':
            action = _tracker.PhotoCollections.show.shareOnTwitterButton;
            break;
          case 'share-email':
            action = _tracker.PhotoCollections.show.shareOnEmailButton;
            break;
          case 'delete':
            action = _tracker.PhotoCollections.show.deleteCollectionButton;
            break;
          case 'organize':
            action = _tracker.PhotoCollections.show.openOrganizeCollectionButton;
            break;
          case 'edit-details':
            _tracker.Tracker.trackEvent({
              category: _tracker.PhotoCollections.category,
              screen: _tracker.PhotoCollections.details.screen,
              action: _tracker.PhotoCollections.details.screen + ' ' + _tracker.CommonActions.screenView
            });
            return;
          default:
            break;
        }
        _tracker.Tracker.trackEvent({
          category: _tracker.PhotoCollections.category,
          screen: _tracker.PhotoCollections.show.screen,
          action: action
        });
      }
    }
  });
});