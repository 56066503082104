define("tinybeans-frontend/templates/components/main/download-app-popup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 4
            },
            "end": {
              "line": 71,
              "column": 4
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/main/download-app-popup.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "download-app-popup-inner");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "images/download-app/group-3@2x.png");
          dom.setAttribute(el2, "width", "60");
          dom.setAttribute(el2, "height", "auto");
          dom.setAttribute(el2, "alt", "Tinybeans app logo");
          dom.setAttribute(el2, "align", "center");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h5");
          var el3 = dom.createTextNode("The best experience is on the Tinybeans app.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-primary btn-use-the-app");
          var el3 = dom.createTextNode("Use the app");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "type", "button");
          dom.setAttribute(el2, "class", "reminder-me-later-link");
          dom.setAttribute(el2, "data-dismiss", "modal");
          dom.setAttribute(el2, "aria-hidden", "true");
          var el3 = dom.createTextNode("Remind me later");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 5]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["checkoutDownloadApp"], [], ["loc", [null, [68, 56], [68, 88]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 73,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/components/main/download-app-popup.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n    .download-app-popup-container .modal-content {\n        background-color:rgb(255, 103, 83,0.8);\n    }\n\n    .download-app-popup-container .modal-body{\n        text-align:center;\n        padding:10px;\n    }\n    .download-app-popup-container .modal-body .download-app-popup-inner {\n        padding:10px;\n        margin:0 auto;\n    }\n    .download-app-popup-container .modal-body h5 {\n        font-weight: bold;\n        font-size:14pt;\n        text-align: center;\n        padding:10px;\n        color:#fff;\n    }\n    .download-app-popup-container .modal-body p {\n        padding:15px 0px 0px 0px;\n        font-size:13pt;\n    }\n    .download-app-popup-container .modal-header{\n        border:none!important;\n    }\n    .download-app-popup-container .close-download-app-popup{\n        background:none;\n        border:none;\n    }\n    .download-app-popup-container h5 {\n        color:#fff;\n        line-height: 140%;\n    }\n    .download-app-popup-container .modal-header .close {\n        margin-top: -2px;\n        opacity: 0.5;\n        color: #ffffff;\n    }\n    .download-app-popup-container .btn-use-the-app, .download-app-popup-container .btn-use-the-app:hover  {\n        border:2px solid #fff;\n        background:#fff;\n        color:var(--color-primary);\n        padding-left:40px;\n        padding-right:40px;\n        display: block;\n        text-align:center;\n        margin:0 auto;\n    }\n    .reminder-me-later-link, .reminder-me-later-link:hover {\n        color: #fff;\n        font-weight: bold;\n        cursor: pointer;\n        opacity: 1;\n        font-size: 12pt;\n        display: block;\n        margin: 10px auto;\n        background:none;\n        border:none;\n    }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "download-app-popup-container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["block", "modal-dialog", [], ["title", "", "saveButton", false, "closeButton", false, "close", "close"], 0, null, ["loc", [null, [64, 4], [71, 21]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});