define('tinybeans-frontend/components/date-picker', ['exports', 'tinybeans-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({

    dateFormat: 'YYYY-MM-DD',
    verticalOrientation: 'bottom',

    'data-date-format': function () {
      return this.get('dateFormat');
    }.property('dateFormat'),

    attributeBindings: ['data-date-format'],

    initialiseJs: function () {
      var _this = this;

      if (!Modernizr.touch || !Modernizr.inputtypes.date) {
        this.$().attr('type', 'text');
        this.$().datetimepicker({
          showTodayButton: true,
          format: this.dateFormat,
          language: _environment.default.LANGUAGE
        });
        this.$().on('click', function () {
          _this.$().data("DateTimePicker").show();
        });
        this.$().on('focusout', function () {
          _this.$().data("DateTimePicker").hide();
        });
      } else {
        this.$().attr('type', 'date');
        this.set('dateFormat', 'YYYY-MM-DD');
      }

      Ember.$(window).on("dp.show", function () {
        if (Ember.$(".entry-add-popup").length || Ember.$(".date-selection-element").length) {
          // Get the parents first child and align with that.
          Ember.$(".bootstrap-datetimepicker-widget").css('left', Ember.$(".bootstrap-datetimepicker-widget").parent().find('input').position().left);
          Ember.$(".bootstrap-datetimepicker-widget").css('margin-bottom', '20px');
          Ember.$(".bootstrap-datetimepicker-widget").css('z-index', '106');
        }
      });

      this.$().on('dp.change', function (e) {
        _this.set('value', moment(e.date).format(_this.get('dateFormat')));
      });
    }.on('didInsertElement'),

    update: function () {
      if (!Modernizr.touch || !Modernizr.inputtypes.date) {
        this.$().data("DateTimePicker").date(this.get('dateValue'));
      }
    }.observes('dateValue')
  });
});