define('tinybeans-frontend/models/comment', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    URL: (0, _attr.default)('string'),
    clientRef: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    details: (0, _attr.default)('string'),
    timestamp: (0, _attr.default)('number'),
    lastUpdatedTimestamp: (0, _attr.default)('number'),
    deleted: (0, _attr.default)('boolean'),
    user: (0, _attr.default)()
  });
});