define('tinybeans-frontend/components/image-with-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNameBindings: ['_class'],
    name: 'file',
    multiple: false,
    files: undefined,
    uploading: false,
    class: '',
    entryAdded: false,
    imageURL: '',
    defaultImageURL: null,

    _class: function () {
      return 'images-with-upload ' + this.get('class');
    }.property('class'),

    backgroundStyle: function () {
      var style = this.get('imageURL') ? "background-image: url('" + this.get('imageURL') + "'); background-size: cover; background-position: center;" : "background-image: url('" + this.get('defaultImageURL') + "'); background-size: cover; background-position: center;"; //'images/generic-baby_nobackground.png'
      return Ember.String.htmlSafe(style);
    }.property('entryAdded'),

    didInsertElement: function didInsertElement() {
      var self = this;
      var $inputFile = self.$().find('input[type="file"]');
      $inputFile.change(function () {
        var file = this.files[0];
        if (file) {
          var url = window.URL.createObjectURL(file); // Load the file as an object URL for local display
          self.set('entryAdded', false);
          self.set('entryAdded', true);
          self.set('imageURL', url);
        }
      });
    }
  });
});