define('tinybeans-frontend/controllers/main/pets/edit', ['exports', 'tinybeans-frontend/tracker'], function (exports, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      track: function track() {
        _tracker.Tracker.trackEvent({
          action: _tracker.Pet.details.saveButton,
          category: _tracker.Pet.category,
          screen: _tracker.Pet.details.screen
        });
      }
    }
  });
});