define('tinybeans-frontend/services/lotame', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Lotame = exports.Lotame = function () {
    function Lotame() {
      _classCallCheck(this, Lotame);

      this.hostname = document.location.hostname;
    }

    _createClass(Lotame, [{
      key: 'sendBehavior',
      value: function sendBehavior(behavior, interest, userId) {
        console.log('Sending to Lotame ', behavior);
        if (userId === undefined) return;

        var crrBehavior = this.hostname + ' : web : ' + behavior;
        if (interest) {
          interest = interest.map(function (element) {
            return crrBehavior + element;
          });
        }

        if (this.hostname.includes('tinybeans') && userId !== undefined) {
          var lotameData = {
            behaviors: {},
            thirdParty: {
              namespace: 'TYNB',
              value: userId
            }
          };

          console.log(lotameData);
          window.lotame_16297.cmd.push(function () {
            window.lotame_16297.collect(lotameData);
          });
        }
      }
    }]);

    return Lotame;
  }();
});