define('tinybeans-frontend/routes/mom365', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function model(params) {
      console.log(params);
      return Ember.RSVP.hash({ oneTimeToken: params.oneTimeToken });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // console.log(model);
      this.API.exchangeToken(model.oneTimeToken, function (response) {
        var accessToken = response.accessToken;
        _this.get('session').authenticate('authenticator:redirect', { accessToken: accessToken }).then(function () {
          window.location.href = '/private/main/journals/follower-list-invite-start?setup=true';
          // this.transitionTo('main.journals.follower-list-invite-start');
        });
      }, function (error) {
        console.log(error);
      });
    }
  });
});