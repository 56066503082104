define('tinybeans-frontend/controllers/main/billing/show', ['exports', 'tinybeans-frontend/controllers/base-controller', 'lodash/lodash'], function (exports, _baseController, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({

    queryParams: ['renew', 'subscriptionId'], // renew on update
    journal: null,
    subscriptions: [],
    subscriptionProducts: null,
    paymentTransactions: null,
    card: null,

    init: function init() {
      var _this = this;

      Ember.run.schedule('afterRender', this, function () {
        var params = _this.getProperties('renew', 'subscriptionId');
        if (params.renew && params.subscriptionId) {
          _this.set('showEditModal', true);
          $('#editCard').show();
        }
      });
    },


    planName: Ember.computed('model.subscriptionInfo', function () {
      var planName = this.get('model.subscriptionInfo').paymentTransaction.amountDisplay == '$4.99' ? 'Tinybeans Beanstalk Monthly Plan' : 'Tinybeans Beanstalk Annual Plan';
      return planName;
    }),

    isAuto: Ember.computed('model.subscriptionInfo', function () {
      var isAuto = this.get('model.subscriptionInfo').renewal == 'MANUAL' ? false : true;
      return isAuto;
    }),

    isExistingCustomer: function () {
      return !!this.get('model.storeCustomer.card');
    }.property('model.storeCustomer.card'),

    yearlySubscriptionProduct: Ember.computed('model.subscriptionProducts', function () {
      var subscriptionProducts = this.get('model.subscriptionProducts').toArray();
      return _lodash.default.find(subscriptionProducts, function (prod) {
        return prod.code === 'PRE12';
      });
    }),

    // selectedProduct: Ember.computed('pid', function() {
    //   var subscriptionProducts = this.get('model.subscriptionProducts').toArray();
    //   return _.find(subscriptionProducts, prod => {
    //     return prod.id === parseInt(this.get('pid'));
    //   });
    // }),

    journalSubscriptions: function journalSubscriptions(journalId) {
      return this.get('model.subscriptions')[journalId];
    },


    actions: {

      transitionToJournalRoute: function transitionToJournalRoute(journal) {
        this.transitionToRoute('main.journals.view', journal.id);
      },

      printReceipt: function printReceipt(paymentTransaction) {
        alert("Printing receipt: " + paymentTransaction.paymentId);
      },

      toggleChangeCard: function toggleChangeCard() {
        if (this.get('showEditModal')) {
          this.set('showEditModal', false);
          $('#editCard').hide('slow');
        } else {
          this.set('showEditModal', true);
          $('#editCard').show('slow');
        }
      },

      upgradeToYearly: function upgradeToYearly(journal, subscription) {
        var self = this;
        this.set('journalId', journal.id);
        this.set('subscription', subscription);
        this.set('showPurchaseModal', true);
        var eventAction = 'click_Yearly_upgrade';
        var eventLabel = self.globals.isIOS ? "ios" : self.globals.isAndroid ? "android" : "web";
        this.GTM.trackEvent({
          'eventCategory': 'premium_creditcard_dialogue',
          'eventAction': eventAction,
          'eventLabel': eventLabel
        });

        if (journal) {
          //log user click buy button action to backend
          self.API.logProductView(journal.id, this.get('yearlySubscriptionProduct.id'));
          //log user click buy button action to backend
        } else {
          bootbox.dialog({ // Really shouldn't happen, perhaps no journals in users listing at all in which case ask them to add one
            title: 'No journal selected',
            message: 'Please select a journal.',
            onEscape: function onEscape() {},
            buttons: {
              ok: {
                label: 'OK',
                callback: function callback() {
                  self.transitionToRoute('main.journals.list');
                }
              }
            }
          });
        }
      },

      done: function done() {
        this.set('showEditModal', false);
        $('#editCard').hide('slow');
        this.send('reloadPage');
      },
      closeModal: function closeModal() {
        $('.modal').modal('hide');
        this.set('showPurchaseModal', false);
        this.set('showEditModal', false);
      },
      paymentCallback: function paymentCallback() {
        this.send('closeModal');
        this.set('subscription.subscriptionProduct.code', 'PRE12');
      },
      billingInformation: function billingInformation() {
        this.API.billingSession().then(function (res) {
          window.location.replace(res.portalURL);
        });
      },
      cancellation: function cancellation() {
        this.transitionToRoute('main.subscription.cancel');
      }
    }

  });
});