define('tinybeans-frontend/services/globals', ['exports', 'lodash/lodash'], function (exports, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        lastRoutes: [],
        lastRouteObjects: [],
        isBrowser: true,
        isNative: false,
        isIOS: false,
        isAndroid: false,
        onboardingInitialComplete: null,
        uploadedPercentage: 0,
        dateFormat: null,

        dismissedNotifications: {},

        isMobile: function isMobile() {
            return window.isMobile;
        },

        isTinyMobile: function isTinyMobile() {
            // Will exclude iPads and other tablets
            var width = Ember.$(window).width(),
                height = Ember.$(window).height();
            return window.isMobile && Modernizr.touch && width <= 767 && height <= 1023;
        },

        usersDateFormat: function usersDateFormat() {
            if (!Modernizr.touch || !Modernizr.inputtypes.date) {
                return this.get('dateFormat').toUpperCase();
            } else {
                return 'YYYY-MM-DD';
            }
        },

        humanise: function humanise(str) {
            // "fooBAR" becomes "Foo bar"
            var lower = str.toLowerCase();
            return lower[0].toUpperCase() + lower.substring(1).replace(/([a-z])(?=[A-Z])/g, "$1 ");
        },

        setState: function setState(transition) {
            if (transition.queryParams.native === "true") {
                this.isNative = true;
                this.isBrowser = false;
            }
        },

        setSource: function setSource(transition) {
            if (transition.queryParams.source === "ios") {
                this.isIOS = true;
                this.isAndroid = false;
            }

            if (transition.queryParams.source === "android") {
                this.isIOS = false;
                this.isAndroid = true;
            }
        },

        getPremiumFeature: function getPremiumFeature(curFeatures, key) {
            return JSON.parse(curFeatures.get('scopeMap'))[key];
        },


        premiumTier: function premiumTier(journal) {
            if (journal && journal.get('features')) {
                return _lodash.default.any(journal.get('features').toArray(), function (feature) {
                    if (JSON.parse(feature.get('scopeMap'))['Tier'] === 'premium') {
                        return true;
                    }
                });
            }
            return false;
        },

        maxVideoUploadLength: function maxVideoUploadLength(journal) {
            var maxVideoLength = 30; // In seconds
            if (journal && journal.features && journal.features.length > 0) {
                for (var i = 0; i < journal.features.length; ++i) {
                    var video = this.getPremiumFeature(journal.features[i], 'Video');
                    if (video && video.uploadLength) {
                        if (video.uploadLength > maxVideoLength) {
                            maxVideoLength = video.uploadLength;
                        }
                    }
                }
            }
            return maxVideoLength;
        },

        maxVideoPlaybackLength: function maxVideoPlaybackLength(journal) {
            var maxVideoLength = 30; // In seconds
            if (journal && journal.features && journal.features.length > 0) {
                for (var i = 0; i < journal.features.length; ++i) {
                    var video = this.getPremiumFeature(journal.features[i], 'Video');
                    if (video && video.uploadLength) {
                        if (video.uploadLength > video.playbackLength) {
                            maxVideoLength = video.playbackLength;
                        }
                    }
                }
            }
            return maxVideoLength;
        },

        multipleUploadEnabled: function multipleUploadEnabled(journal) {
            if (journal && journal.features && journal.features.length > 0) {
                for (var i = 0; i < journal.features.length; ++i) {
                    var multipleUpload = this.getPremiumFeature(journal.features[i], 'MultipleUpload');
                    if (multipleUpload && multipleUpload.enabled) {
                        return true;
                    }
                }
            }
            return false;
        },

        maxFilesAtOnce: function maxFilesAtOnce(journal) {
            var maxFiles = 20;
            if (journal && journal.features && journal.features.length > 0) {
                for (var i = 0; i < journal.features.length; ++i) {
                    var multipleUpload = this.getPremiumFeature(journal.features[i], 'MultipleUpload');
                    if (multipleUpload && multipleUpload.maxFiles > maxFiles) {
                        maxFiles = multipleUpload.maxFiles;
                    }
                }
            }
            return maxFiles;
        },

        photoCollectionEnabled: function photoCollectionEnabled(journal) {
            var _this = this;

            if (journal && journal.get('features')) {
                return _lodash.default.any(journal.get('features').toArray(), function (feature) {
                    var photoCollection = _this.getPremiumFeature(feature, 'PhotoCollection');
                    return photoCollection && photoCollection.enabled;
                });
            }
            return false;
        },

        maxEntriesInCollection: function maxEntriesInCollection(journal) {
            var maxEntries = 0;
            if (journal && journal.features && journal.features.length > 0) {
                for (var i = 0; i < journal.features.length; ++i) {
                    var photoCollection = this.getPremiumFeature(journal.features[i], 'PhotoCollection');
                    if (photoCollection && photoCollection.maxEntriesInCollection > maxEntries) {
                        maxEntries = photoCollection.maxEntriesInCollection;
                    }
                }
            }
            return maxEntries;
        },

        maxCollections: function maxCollections(journal) {
            var maxCollections = 0;
            if (journal && journal.features && journal.features.length > 0) {
                for (var i = 0; i < journal.features.length; ++i) {
                    var photoCollection = this.getPremiumFeature(journal.features[i], 'PhotoCollection');
                    if (photoCollection && photoCollection.maxCollections > maxCollections) {
                        maxCollections = photoCollection.maxCollections;
                    }
                }
            }
            return maxCollections;
        },

        journalExportEnabled: function journalExportEnabled(journal) {
            var _this2 = this;

            if (journal && journal.get('features')) {
                return _lodash.default.any(journal.get('features').toArray(), function (feature) {
                    var journalExport = _this2.getPremiumFeature(feature, 'JournalExport');
                    return journalExport && journalExport.enabled;
                });
            }
            return false;
        }

    });
});