define('tinybeans-frontend/controllers/main/collections/organize', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    saveButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? 'Please wait ...' : 'Save changes';
    }.property('submitting'),

    collectionEnabled: Ember.computed(function () {
      return this.globals.photoCollectionEnabled(this.get('model.journal'));
    }),

    hasEntries: Ember.computed('model.collection', function () {
      return this.get('model.collection.tagEntries.length') > 0;
    }),

    tagEntries: Ember.computed('model.collection.tagEntries', 'model.collection.pinnedTagEntry', function () {
      var selectedIds = Ember.$('#selectedEntryId').text();
      var pinnedTagEntryId = this.get('model.collection.pinnedTagEntry.id');

      var entries = _lodash.default.map(this.get('model.collection.tagEntries').toArray(), function (tagEntry) {
        if (tagEntry.get('deleted')) return null;

        // tagEntry = tagEntry.toJSON();
        var entryId = "#" + tagEntry.id;
        tagEntry.setProperties({ initialSelectedClass: '', initialIconSelectedClass: 'glyphicon glyphicon-remove', pinClass: 'glyphicon glyphicon-pushpin' });
        if (tagEntry.id == pinnedTagEntryId) {
          tagEntry.set('pinClass', 'glyphicon glyphicon-pushpin active');
        }
        if (selectedIds.indexOf(entryId) != -1) {
          tagEntry.setProperties({ initialSelectedClass: 'selected', initialIconSelectedClass: 'glyphicon pre-select' });
        }
        return tagEntry;
      });
      return _lodash.default.compact(entries);
    }),

    actions: {

      pinMoment: function pinMoment(tagEntry) {
        var _this = this;

        var self = this;
        var tagEntryId = tagEntry.id;
        Ember.$('#' + tagEntryId).addClass("pinProcessing");
        // Ember.$('.glyphicon-pushpin').removeClass("active");


        bootbox.dialog({
          title: "Pin moment",
          message: "Pin this moment as the cover of the album",
          buttons: {
            ok: {
              label: "Ok",
              className: "btn-primary",
              callback: function callback() {
                self.API.pinEntryToCollection(tagEntryId, function (data) {
                  //success
                  self.set('submitting', false);
                  Ember.get(self, 'flashMessages').clearMessages();
                  Ember.get(self, 'flashMessages').add({
                    message: 'Moment pinned as cover photo',
                    timeout: 3000,
                    organizeMessage: true,
                    type: 'alert alert-success',
                    glyphiconClass: 'glyphicon glyphicon-ok'
                  });
                  _this.set('model.collection.pinnedTagEntry', _lodash.default.find(_this.get('model.collection.tagEntries').toArray(), function (tagEntry) {
                    return tagEntry.id == data.tagEntry.id;
                  }));
                }, function () {
                  self.set('submitting', false);
                });
              }
            },
            cancel: {
              label: "Cancel",
              className: "btn-default"
            }
          }
        });
      },

      deleteEntry: function deleteEntry(tagEntry) {
        var _this2 = this;

        var self = this;
        var tagId = self.get('model.collection').id;
        var journalId = self.get('model.journalId');

        var selectedEntryIds = [];

        selectedEntryIds.push(parseInt(tagEntry.id));

        bootbox.dialog({
          title: "Are you sure to remove this moment?",
          message: "This moment will still be available in your journal, it will only be removed from this album.",
          buttons: {
            ok: {
              label: "Ok",
              className: "btn-default",
              callback: function callback() {
                self.API.removeEntriesFromCollection(journalId, tagId, selectedEntryIds, function () {
                  //success
                  self.set('submitting', false);
                  Ember.get(self, 'flashMessages').clearMessages();
                  Ember.get(self, 'flashMessages').add({
                    message: 'Moment deleted from album',
                    timeout: 3000,
                    organizeMessage: true,
                    type: 'alert alert-success',
                    glyphiconClass: 'glyphicon glyphicon-ok'
                  });
                  var tagEntries = _lodash.default.reject(_this2.get('model.collection.tagEntries').toArray(), function (entry) {
                    return tagEntry.id == entry.id;
                  });
                  _this2.set('model.collection.tagEntries', tagEntries);
                  if (_this2.get('model.collection.pinnedTagEntry.id') === tagEntry.id) _this2.set('model.collection.pinnedTagEntry', tagEntries[0]);
                }, function () {
                  self.set('submitting', false);
                });
              }
            },
            cancel: {
              label: "Close",
              className: "btn-primary"
            }
          }
        });
      },

      save: function save() {
        var redirectRoute = 'show';
        this.send('saveOrder', redirectRoute);
        this.send('track', 'save');
      },

      saveOrder: function saveOrder(redirectRoute) {
        var self = this;
        var orderedEntries = [];
        var finalOrderedEntries = [];

        var selectedIds = Ember.$('#selectedEntryId').text();

        Ember.$('.moment').each(function () {
          var id = Ember.$(this).attr('id');
          orderedEntries.push(parseInt(id));
        });

        if (!selectedIds) {
          finalOrderedEntries = orderedEntries;
        } else {
          Ember.$.each(orderedEntries, function (idx, orderedEntry) {
            var orderedEntryId = "#" + orderedEntry;
            if (selectedIds.indexOf(orderedEntryId) == -1) {
              //if not found deleted entries, add to final list
              finalOrderedEntries.push(orderedEntry);
            }
          });
        }

        var tagId = self.get('model.collection').id;
        var journalId = self.get('model.journalId');
        var path = self.get('model.collection.path');

        if (redirectRoute != "self") {
          self.set('submitting', true);
        }

        self.API.saveReorderedCollection(journalId, tagId, finalOrderedEntries, function () {
          if (redirectRoute == 'self') {
            self.route.refresh();
          } else {
            self.set('submitting', false);
            Ember.get(self, 'flashMessages').clearMessages();
            Ember.get(self, 'flashMessages').add({
              message: 'Saved',
              timeout: 3000,
              showMessage: true,
              type: 'alert alert-success',
              glyphiconClass: 'glyphicon glyphicon-ok'
            });
            self.transitionToRoute('main.collections.show', journalId, path);
          }
        }, function () {
          self.set('submitting', false);
        });
      },

      backToCollection: function backToCollection(journalId, path) {
        this.get('flashMessages').clearMessages();
        this.transitionToRoute('main.collections.show', journalId, path);
        this.send('track', 'back');
      },

      upgrade: function upgrade(journalId) {
        this.transitionToRoute('main.subscription-products.list', {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      },

      track: function track(what) {
        var action = 'unknown';
        switch (what) {
          case 'save':
            action = _tracker.PhotoCollections.organize.saveButton;
            break;
          case 'back':
            action = _tracker.PhotoCollections.organize.backButton;
            break;
          default:
            break;
        }

        _tracker.Tracker.trackEvent({
          category: _tracker.PhotoCollections.category,
          screen: _tracker.PhotoCollections.organize,
          action: action
        });
      }
    }

  });
});