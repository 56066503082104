define('tinybeans-frontend/routes/main/journals/follower-list-invite-start', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model() {

      return Ember.RSVP.hash({
        journals: this.store.findAll('journal'),
        invite: {
          firstName: null,
          lastName: null,
          emailAddress: null,
          viewEntries: true,
          addEntries: false,
          viewMilestones: true,
          editMilestones: false,
          message: null
        },
        relations: this.API.getFollowerRelations()
      });
    },


    setupController: function setupController(controller, model) {

      this._super(controller, model);

      controller.set('submitting', false);
      controller.set('showMessage', false);

      var journal = model.journals.objectAt(0);
      if (journal) {
        controller.set('journalId', journal.id);
      }
    },

    renderTemplate: function renderTemplate() {
      this.render('main/journals/follower-list-invite-start', { controller: 'main.journals.follower-list-invite-start' });
    },

    actions: {
      reloadPage: function reloadPage() {
        this.refresh();
      }
    }
  });
});