define("tinybeans-frontend/controllers/main/journals/month", ["exports", "tinybeans-frontend/controllers/base-controller", "tinybeans-frontend/mixins/commons-mixin", "lodash/lodash", "tinybeans-frontend/tracker"], function (exports, _baseController, _commonsMixin, _lodash, _tracker) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    queryParams: ["setup", "invitationAccepted", "forceToRefresh"],
    notificationText: "",
    needs: ["main"],
    setup: false,
    entryWasAdded: false,
    entryPopupVisible: false,
    showSparkleEffects: false,
    showUpgradeModal: false,
    timelineMonths: [{
      month: 1,
      name: "Jan"
    }, {
      month: 2,
      name: "Feb"
    }, {
      month: 3,
      name: "Mar"
    }, {
      month: 4,
      name: "Apr"
    }, {
      month: 5,
      name: "May"
    }, {
      month: 6,
      name: "Jun"
    }, {
      month: 7,
      name: "Jul"
    }, {
      month: 8,
      name: "Aug"
    }, {
      month: 9,
      name: "Sep"
    }, {
      month: 10,
      name: "Oct"
    }, {
      month: 11,
      name: "Nov"
    }, {
      month: 12,
      name: "Dec"
    }],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce("afterRender", this, function () {
        if (_this.get("invitationAccepted")) {
          _this.send("displayNotification", "Yay! You're now following the " + _this.get("model.journal.title") + " journal!");
        }
      });

      Ember.run.scheduleOnce("afterRender", this, function () {
        var cookie = _this.readCookie("cookie-policy");
        if (cookie) {
          return false;
        } else {
          var cookieBanner = document.getElementById('cookieBanner');
          cookieBanner.classList.remove('cookie-hide');
          /* jshint unused:false */
          window.onscroll = function (e) {
            cookieBanner.classList.add('fade-out-bottom');
          };
        }
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
    },
    readCookie: function readCookie(key) {
      var value = document.cookie.match(new RegExp(key + "=([^;]+)")) || [];
      return decodeURIComponent(value[1] || "");
    },


    showPremiumBanner: Ember.computed("model.journal", function () {
      var cookie = this.readCookie("alreadyShownCheckOutPremium");
      return this.get("onboardingCompleted") && !this.get('model.me.hasMemoriesAccess') && !cookie;
    }),

    weeksFeaturedInMonth: function () {
      var model = this.get("model");

      var entries = model.get("entries").toArray();
      var month = model.get("month");
      var year = model.get("year");

      // initialise
      var firstOfMonth = new Date(year, month - 1, 1);
      var date = this.prevSunday(firstOfMonth);
      var weeks = [];

      var meAsFollower = this.get("meAsFollower");
      var isMyJournal = this.get("isMyJournal");

      // loop through the weeks spanning the current month
      do {
        var week = [];
        for (var day_of_week = 0; day_of_week < 7; day_of_week++) {
          var day_day = date.getDate();
          var day_month = date.getMonth() + 1;
          var day_year = date.getFullYear();
          var day_entries = this.entriesForDay(entries, day_day, day_month, day_year);
          var day_sameMonth = this.isSameMonth(date, month);
          var day_today = this.isToday(date);
          var day = Ember.Object.create({
            date: date,
            day: day_day,
            month: day_month,
            year: day_year,
            sameMonth: day_sameMonth,
            today: day_today,
            entries: day_entries,
            entryCount: day_entries.length,
            moreThanOneEntry: day_entries.length > 1,
            firstEntry: day_entries.length > 0 ? day_entries[0] : null,
            firstEntryIsPhoto: day_entries.length > 0 ? day_entries[0].get("type") === "PHOTO" && !day_entries[0].get("attachmentType") : false,
            firstEntryIsVideo: day_entries.length > 0 ? day_entries[0].get("type") === "PHOTO" && day_entries[0].get("attachmentType") === "VIDEO" : false,
            firstEntryIsText: day_entries.length > 0 ? day_entries[0].get("type") === "TEXT" : false,
            canAddMoments: isMyJournal || meAsFollower && meAsFollower.get("addEntries")
          });

          week.pushObject(day);
          date = this.addDay(date);
        }
        weeks.pushObject(week);
      } while (this.isSameMonth(date, month));
      return weeks;
    }.property("model.entries", "entryAdded"),

    isTinyScreen: function () {
      var width = $(window).width();
      return width <= 600;
    }.property(),

    prevSunday: function prevSunday(date) {
      var newDate = new Date(date.getTime());
      newDate.setDate(newDate.getDate() - newDate.getDay());
      return newDate;
    },

    entriesForDay: function entriesForDay(entries, day, month, year) {
      var entriesForDay = _lodash.default.filter(entries, function (entry) {
        return entry.get("day") === day && entry.get("month") === month && entry.get("year") === year;
      });
      if (entriesForDay.length === 0) return [];
      var maxIndex = -1,
          max = -1;
      for (var i = 0; i < entriesForDay.length; i++) {
        if (entriesForDay[i].get("pinnedTimestamp") > max) {
          max = entriesForDay[i].get("pinnedTimestamp");
          maxIndex = i;
        }
      }
      if (maxIndex > 0) {
        var temp = entriesForDay[0];
        entriesForDay[0] = entriesForDay[maxIndex];
        entriesForDay[maxIndex] = temp;
      }
      return entriesForDay;
    },

    isSameMonth: function isSameMonth(date, month) {
      return date.getMonth() === month - 1;
    },

    isToday: function isToday(date) {
      var now = new Date();
      return date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() && date.getDate() === now.getDate();
    },

    addDay: function addDay(date) {
      var newDate = new Date(date.getTime());
      newDate.setDate(newDate.getDate() + 1);
      if (date.getDate() === newDate.getDate()) {
        newDate.setDate(newDate.getDate() + 1); // Daylight savings fix
      }
      return newDate;
    },

    hasEntry: function () {
      var currentEntries = this.get("entries");
      return currentEntries && currentEntries.length > 0;
    }.property("model.entries"),

    showCompleteMessage: Ember.computed("onboardingCompleted", function () {
      return _lodash.default.size(this.get("model.followers")) < 2 && _lodash.default.size(this.get("model.journal.children")) < 2 && _lodash.default.size("model.entries") < 2;
    }),

    collectionEnabled: Ember.computed("model.journal", function () {
      return this.globals.photoCollectionEnabled(this.get("model.journal"));
    }),

    defaultCollection: Ember.computed("model.collections", function () {
      var collections = this.get("model.collections").toArray();
      if (collections) {
        var collection = _lodash.default.find(collections, function (c) {
          var sortOrder = c.get("sortOrder");
          return (sortOrder === 0 || sortOrder === 1) && !c.get("deleted");
        });
        if (collection) {
          if (collection.get("name.length") > 35) {
            collection.set("name", collection.get("name").substring(0, 25) + "...");
          }
          if (collection.get("pinnedEntry")) {
            collection.set("coverUrl", "background:url('" + collection.get("pinnedTagEntry.blobs.s2") + "') center center no-repeat;");
          } else if (collection.get("latestEntry")) {
            collection.set("coverUrl", "background:url('" + collection.get("latestEntry.blobs.s2") + "') center center no-repeat;");
          } else {
            collection.set("coverUrl", "background:url('images/collection-default-cover.gif') center center no-repeat;");
          }
          return collection;
        }
      }
      return null;
    }),

    // showAddFollowersPopup: function() {
    //   var self = this;
    //   var entryCountTrigger = self.get('entryCountTrigger');
    //   var followersTrigger = self.get('followersTrigger');
    //
    //   if (entryCountTrigger && followersTrigger) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }.property('showAddFollowersPopup', 'entryCountTrigger', 'followersTrigger'),

    monthStringLong: function () {
      var selectedMonth = this.get("model.month") - 1;
      var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return monthNames[selectedMonth];
    }.property("model.month"),

    monthString: function () {
      var selectedMonth = this.get("model.month") - 1;
      var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return monthShortNames[selectedMonth];
    }.property("model.month"),

    currentMonthString: function () {
      var now = new Date();
      var selectedMonth = now.getMonth();
      var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return monthShortNames[selectedMonth];
    }.property("model.month"),

    currentYear: function () {
      var now = new Date();
      return now.getFullYear();
    }.property("model.year"),

    calendarYear: function () {
      var selectedYear = this.get("model.year");
      return selectedYear;
    }.property("model.year"),

    children: Ember.computed("model.journal", function () {
      var children = this.get("model.journal.children").toArray();
      return children.sort(function (a, b) {
        return moment(b.get("dob"), "YYYY-MM-DD").diff(moment(a.get("dob"), "YYYY-MM-DD"));
      });
    }),

    pets: Ember.computed("model.journal", function () {
      var pets = this.get("model.journal.pets").toArray();
      return pets.sort(function (a, b) {
        return moment(b.get("dob"), "YYYY-MM-DD").diff(moment(a.get("dob"), "YYYY-MM-DD"));
      });
    }),

    trackAdsInfoClick: function trackAdsInfoClick() {
      var eventAction = "w_click";
      this.GTM.trackEvent({
        eventCategory: "adTracking",
        eventAction: eventAction,
        eventLabel: "moreInfo"
      });
    },

    actions: {

      upgradeSub: function upgradeSub() {
        if (typeof AF !== "undefined") AF('pba', 'event', { eventType: 'EVENT', eventValue: { 'label': 'Click on "Start Your Free Trial"', 'from_page': 'Journal Month' }, eventName: 'Click on "Start Your Free Trial"' });

        _tracker.Tracker.trackEvent({
          category: _tracker.Premium.category,
          screen: _tracker.Premium.list.screen,
          action: _tracker.Premium.list.startTrial
        });

        window.location.hash = '#/main/subscription-products/list';
      },

      trackAdsBannerClick: function trackAdsBannerClick() {
        var self = this;
        // console.log("clicked banner");
        var eventAction = "w_click";
        self.GTM.trackEvent({
          eventCategory: "adTracking",
          eventAction: eventAction,
          eventLabel: "calendarScreen"
        });
        if (typeof AF !== "undefined") AF('pba', 'event', { eventType: 'EVENT', eventCategory: "adTracking", eventValue: { 'label': 'calendarScreen', 'action': eventAction }, eventName: 'Click ad Link' });
      },


      why: function why() {
        var self = this;
        bootbox.dialog({
          title: "Why ads?",
          message: "<p class=''>Network ads allow us to keep providing you and your family with a much-loved free service.</p><p>To turn off ads like these, you can upgrade to Tinybeans Premium.</p>",
          buttons: {
            ok: {
              label: "Check out premium",
              className: "btn-primary",
              callback: function callback() {
                self.send("gotoPremium");
              }
            },
            cancel: {
              label: "Close",
              className: "btn-default"
            }
          }
        });

        this.trackAdsInfoClick();
      },

      showChild: function showChild(child) {
        if (this.get("canViewMilestones")) {
          var journalId = this.get("model.journalId");
          this.route.refresh();
          this.transitionToRoute("main.children.show", journalId, child.id, 0);
        }
      },

      showDate: function showDate(journalId, year, month) {
        // if (googletag.pubads && Ember.$("#div-gpt-ad-1477419963449-0").html() != "") {
        //   googletag.pubads().refresh([slot1]);
        // }
        // if (googletag.pubads && Ember.$("#div-gpt-ad-1477419963449-1").html() != "") {
        //   googletag.pubads().refresh([slot2]);
        // }
        // if (googletag.pubads && Ember.$("#div-gpt-ad-1477420034962-0").html() != "") {
        //   googletag.pubads().refresh([slot5]);
        // }
        if (Number(this.model.month) === Number(month) && Number(this.model.year) === Number(year)) {
          return; // Don't transition to current page
        }
        Ember.$("table.calendar").css("visibility", "hidden");
        Ember.$(".spin").css("visibility", "visible");

        this.transitionToRoute("main.journals.month", journalId, year, month);
      },

      showPrevMonth: function showPrevMonth() {
        this.send("gotoTopOfPage");
        Ember.$("table.calendar").css("visibility", "hidden");
        Ember.$(".spin").css("visibility", "visible");
        Ember.$("#mosaic-view-no-moment-container").css("visibility", "hidden");
        var date = new Date(this.get("model.year"), this.get("model.month") - 2, 1);

        this.transitionToRoute("main.journals.month", this.get("model.journalId"), date.getFullYear(), date.getMonth() + 1);
      },

      showNextMonth: function showNextMonth() {
        this.send("gotoTopOfPage");
        Ember.$("table.calendar").css("visibility", "hidden");
        Ember.$(".spin").css("visibility", "visible");
        Ember.$("#mosaic-view-no-moment-container").css("visibility", "hidden");
        var date = new Date(this.get("model.year"), this.get("model.month"), 1);

        this.transitionToRoute("main.journals.month", this.get("model.journalId"), date.getFullYear(), date.getMonth() + 1);
      },

      showPrevYear: function showPrevYear() {
        this.send("gotoTopOfPage");
        Ember.$("table.calendar").css("visibility", "hidden");
        Ember.$(".spin").css("visibility", "visible");
        Ember.$("#mosaic-view-no-moment-container").css("visibility", "hidden");
        var date = new Date(this.get("model.year"), 1, 1);

        this.transitionToRoute("main.journals.month", this.get("model.journalId"), date.getFullYear() - 1, this.get("model.month"));
      },

      showNextYear: function showNextYear() {
        this.send("gotoTopOfPage");
        Ember.$("table.calendar").css("visibility", "hidden");
        Ember.$(".spin").css("visibility", "visible");
        Ember.$("#mosaic-view-no-moment-container").css("visibility", "hidden");
        var date = new Date(this.get("model.year"), 1, 1);

        this.transitionToRoute("main.journals.month", this.get("model.journalId"), date.getFullYear() + 1, this.get("model.month"));
      },

      showDay: function showDay(day) {
        if (day.firstEntry) {
          if (day.month === Number(this.get("model.month"))) {
            this.send("gotoTopOfPage");
            this.transitionToRoute("main.entries.list", this.get("model.journalId"), day.year, day.month, day.day);
          }
        } else {
          if (day.month === Number(this.get("model.month"))) {
            this.send("gotoTopOfPage");
            this.transitionToRoute("main.entries.list", this.get("model.journalId"), day.year, day.month, day.day);
          }
        }
      },

      addMoment: function addMoment(date) {
        // check limits first
        this.API.getMemoriesLimit().then(function (limit) {
          console.log(limit);

          limit = limit.limit;
          if (limit.threshold > 0 && (limit.usedTimes >= limit.threshold || files.length > limit.threshold)) {
            //modal and cancel
            Ember.$(".limitReachedModal").modal("show");
            this.set("submitting", false);
            this.get('globals').set('showProgressBar', false);
            if (this.get('monthController.route')) {
              this.get('monthController.route').refresh();
            }
            if (this.get('entriesController.route')) {
              this.get('entriesController.route').refresh();
            }
            return;
          }
        });
        _tracker.Tracker.trackEvent({
          category: _tracker.Calendar.category,
          action: _tracker.Calendar.addMomentButton,
          screen: _tracker.Calendar.screen
        });
        if (date) {
          this.transitionToRoute("main.journals.add-moment", this.get("model.journalId"), {
            queryParams: {
              year: date.year,
              month: date.month,
              day: date.day
            }
          }).then(function (route) {
            route.set("prevRoute", "list");
          });
        } else {
          this.transitionToRoute("main.journals.add-moment", this.get("model.journalId")).then(function (route) {
            route.set("prevRoute", "list");
          });
        }
      },

      invite: function invite() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Calendar.category,
          action: _tracker.Calendar.openFollowerList,
          screen: _tracker.Calendar.screen
        });
        Ember.$(".modal").modal("hide");
        this.transitionToRoute("main.journals.follower-list-invite", this.get("model.journalId"));
      },

      closeSetupDialog: function closeSetupDialog() {
        this.set("setup", false);
      },

      // saveEmailAddress: function() {
      //   var self = this;
      //   var me = this.get('model.me');
      //   me.emailAddress = self.get('dialogEmailAddress');
      //   // Attempt to save the email address
      //   self.API.saveMe(undefined, me, function(data, statusText, xhr) {
      //     self.onApiSuccess(data, statusText, xhr);
      //     self.route.disconnectOutlet({
      //       outlet: 'modal-dialog'
      //     });
      //     Ember.$('.modal-backdrop').hide();
      //   }, function(xhr, statusText, e) {
      //     self.onApiError(xhr, statusText, e);
      //   });
      // },

      startInviting: function startInviting() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Calendar.category,
          action: _tracker.Calendar.openFollowerList,
          screen: _tracker.Calendar.screen
        });
        var self = this;
        self.route.disconnectOutlet({
          outlet: "modal-dialog"
        });
        Ember.$(".modal-backdrop").hide();
        this.transitionToRoute("main.journals.follower-list-invite", this.get("model.journalId"));
      },

      doPopup: function doPopup() {
        this.set("entryTimestamp", null);
        this.set("entryWasAdded", false);
        this.set("entryPopupVisible", true);
      },

      showPopup: function showPopup() {
        this.set("entryWasAdded", false);
        this.set("entryPopupVisible", true);
      },
      edit: function edit(child) {
        this.transitionToRoute("main.children.edit", child.id);
      },

      goToAddChild: function goToAddChild() {
        var journalId = this.get("model.journalId");
        this.transitionToRoute("main.children.list", journalId);
      },

      goToAddAChild: function goToAddAChild() {
        var journalId = this.get("model.journalId");
        this.transitionToRoute("main.children.add", journalId);
      },

      goToAddFollower: function goToAddFollower() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Calendar.category,
          action: _tracker.Calendar.openFollowerList,
          screen: _tracker.Calendar.screen
        });
        var journalId = this.get("model.journalId");
        this.transitionToRoute("main.journals.follower-list", journalId);
      },

      goToInviteFollower: function goToInviteFollower() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Calendar.category,
          action: _tracker.Calendar.openFollowerList,
          screen: _tracker.Calendar.screen
        });
        var journalId = this.get("model.journalId");
        this.transitionToRoute("main.journals.follower-list-invite", journalId);
      },

      showCurrentMonth: function showCurrentMonth() {
        var d = new Date();
        var currentYear = d.getFullYear();
        var currentMonth = d.getMonth() + 1;
        var journalId = this.get("model.journalId");

        this.transitionToRoute("main.journals.month", journalId, currentYear, currentMonth);
      },

      showMosaicView: function showMosaicView() {
        var self = this;
        self.set("showMosaic", true);
        Ember.$("#entry-calendar-view").hide();
        Ember.$("#entry-mosaic-container").show();
      },

      showCalendarView: function showCalendarView() {
        var self = this;
        self.set("showMosaic", false);

        Ember.$("#entry-calendar-view").show();
        Ember.$("#entry-mosaic-container").hide();
      },

      showEntry: function showEntry(entry) {
        this.transitionToRoute("main.entries.view", entry.id, entry.uuid);
      },

      gotoTopOfPage: function gotoTopOfPage() {
        Ember.$("html, body").animate({
          scrollTop: 0
        }, "slow");
      },

      gotoFamilyAndFriends: function gotoFamilyAndFriends() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Calendar.category,
          action: _tracker.Calendar.openFollowerList,
          screen: _tracker.Calendar.screen
        });
        var self = this;
        var journal = self.get("model.journal");
        this.send("gotoTopOfPage");
        this.transitionToRoute("main.journals.follower-list", journal.id);
      },

      gotoPremium: function gotoPremium() {
        var self = this;
        var journal = self.get("model.journal");
        var journalId = journal.id;

        self.send("gotoTopOfPage");
        self.transitionToRoute("main.subscription-products.list", {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      },

      gotoPremiumFromModal: function gotoPremiumFromModal() {
        var self = this;
        Ember.$(".modal").modal("hide");
        self.send("gotoPremium");
      },

      gotoFamilyAndFriendsFromCircle: function gotoFamilyAndFriendsFromCircle() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Calendar.category,
          action: _tracker.Calendar.openFollowerList,
          screen: _tracker.Calendar.screen
        });
        var self = this;
        var journal = self.get("model.journal");
        var followerCount = self.get("numberOfFollowers");

        //send google analytics tracking data
        var eventAction = "clickAddFollowerFromJournalCircle";
        self.GTM.trackEvent({
          eventCategory: "userInteraction",
          eventAction: eventAction
        });
        //send google analytics tracking data

        if (followerCount && followerCount >= 1) {
          this.send("gotoTopOfPage");
          this.transitionToRoute("main.journals.follower-list", journal.id);
        } else {
          this.send("gotoTopOfPage");
          this.transitionToRoute("main.journals.follower-list", journal.id);
        }
      },

      bannerAddMoment: function bannerAddMoment() {
        if (this.get('checkPremium')) {
          var self = this;
          //send google analytics tracking data
          var eventAction = "bannerAddMoment";
          self.GTM.trackEvent({
            eventCategory: "onboardingClick",
            eventAction: eventAction
          });
          //send google analytics tracking data

          self.send("addMoment");
        } else {
          Ember.$(".upgradeModal").modal("show");
        }
      },

      bannerAddFollower: function bannerAddFollower() {
        if (this.get('checkPremium')) {
          var self = this;
          //send google analytics tracking data
          var eventAction = "bannerAddFollower";
          self.GTM.trackEvent({
            eventCategory: "onboardingClick",
            eventAction: eventAction
          });
          self.send("goToInviteFollower");
        } else {

          Ember.$(".upgradeModal").modal("show");
          this.GTM.trackEvent({
            eventCategory: "premium",
            eventAction: "subscription overview view"
          });
        }
      },

      whyAds: function whyAds() {
        var self = this;
        bootbox.dialog({
          title: "Why ads?",
          message: "<p class=''>Network ads allow us to keep providing you and your family with a much-loved free service.</p><p>To turn off ads like these, you can upgrade to Tinybeans Premium.</p>",
          buttons: {
            ok: {
              label: "Check out premium",
              className: "btn-primary",
              callback: function callback() {
                self.send("gotoPremium");
              }
            },
            cancel: {
              label: "Close",
              className: "btn-default"
            }
          }
        });
      },

      bannerAddChild: function bannerAddChild() {
        var eventAction = "bannerAddChild";
        this.GTM.trackEvent({
          eventCategory: "onboardingClick",
          eventAction: eventAction
        });
        //send google analytics tracking data
        this.transitionToRoute("main.children.add", this.get("model.journalId"));
      },
      bannerAddPet: function bannerAddPet() {
        var eventAction = "bannerAddPet";
        this.GTM.trackEvent({
          eventCategory: "onboardingClick",
          eventAction: eventAction
        });
        //send google analytics tracking data
        this.transitionToRoute("main.pets.add", this.get("model.journalId"));
      },
      gotoReferFriend: function gotoReferFriend() {
        var eventAction = "click_refer_friend_ribbon";
        var label = "goto-button";

        this.GTM.trackEvent({
          eventCategory: "checkout_refer_friend_page",
          eventAction: eventAction,
          eventLabel: label
        });
        this.transitionToRoute("main.refer-friend.show");
      },
      closeReferFriendBanner: function closeReferFriendBanner() {
        this.set("showReferFriends", false);
        writeCookie("refer-friend-visited", true, 10);

        function writeCookie(key, value, expiration) {
          var expires = expiration ? "" : "; expires=" + new Date(expiration).toUTCString();
          //  var secure  = !!this._secureCookies ? ';secure' : '';
          var path = ";path=/";
          document.cookie = key + "=" + encodeURIComponent(value) + expires + path;
        }

        var eventAction = "close_refer_friend_ribbon";
        var label = "close-button";

        this.GTM.trackEvent({
          eventCategory: "close_refer_friend_page",
          eventAction: eventAction,
          eventLabel: label
        });
      },


      closeCheckOutPremium: function closeCheckOutPremium() {
        // var self = this;
        Ember.$("#check-out-premium").fadeOut("slow");

        writeCookie("alreadyShownCheckOutPremium", true, 10);

        function writeCookie(key, value, expiration) {
          var expires = expiration ? "" : "; expires=" + new Date(expiration).toUTCString();
          //  var secure  = !!this._secureCookies ? ';secure' : '';
          var path = ";path=/";
          document.cookie = key + "=" + encodeURIComponent(value) + expires + path;
        }

        var eventAction = "click_premium_banner_close_btn";
        //        var label = 'close_button';

        this.GTM.trackEvent({
          eventCategory: "checkout_premium_page",
          eventAction: eventAction
          //         'eventLabel':label
        });
      },

      gotoPremiumFromBanner: function gotoPremiumFromBanner() {
        //var self = this;
        var journal = this.get("model.journal");
        var journalId = journal.get("id");

        writeCookie("alreadyShownCheckOutPremium", true, 10);

        function writeCookie(key, value, expiration) {
          var expires = expiration ? "" : "; expires=" + new Date(expiration).toUTCString();
          //  var secure  = !!this._secureCookies ? ';secure' : '';
          var path = ";path=/";
          document.cookie = key + "=" + encodeURIComponent(value) + expires + path;
        }

        var eventAction = "click_premium_banner_checkout_btn";
        //        var label = 'check_out_button';

        this.GTM.trackEvent({
          eventCategory: "checkout_premium_page",
          eventAction: eventAction
          //           'eventLabel':label
        });

        this.send("gotoTopOfPage");
        this.transitionToRoute("main.subscription-products.list", {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      },

      onChildClick: function onChildClick() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Calendar.category,
          action: _tracker.Calendar.openChildPage,
          screen: _tracker.Calendar.screen
        });
      },

      onPetClick: function onPetClick() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Calendar.category,
          action: _tracker.Calendar.openPetPage,
          screen: _tracker.Calendar.screen
        });
      },

      setPrivacyCookie: function setPrivacyCookie() {
        var cookieBanner = document.getElementById('cookieBanner');

        writeCookie("cookie-policy", true, 1);

        function writeCookie(key, value, expiration) {
          var expires = expiration ? "" : "; expires=" + new Date(expiration).toUTCString();
          var path = ";path=/";
          document.cookie = key + "=" + encodeURIComponent(value) + expires + path;
        }
        cookieBanner.classList.add('cookie-hide');
      },

      close: function close() {
        this.$('.upgradeModal').modal('hide');
      }

    }
  });
});