define("tinybeans-frontend/instance-initializers/ember-i18n", ["exports", "ember-i18n/stream", "ember-i18n/legacy-helper", "tinybeans-frontend/config/environment"], function (exports, _stream, _legacyHelper, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-i18n',

    initialize: function initialize(appOrAppInstance) {
      if (_legacyHelper.default != null) {
        // Used for Ember < 1.13
        var i18n = appOrAppInstance.container.lookup('service:i18n');

        i18n.localeStream = new _stream.default(function () {
          return i18n.get('locale');
        });

        Ember.addObserver(i18n, 'locale', i18n, function () {
          this.localeStream.value(); // force the stream to be dirty
          this.localeStream.notify();
        });

        Ember.HTMLBars._registerHelper('t', _legacyHelper.default);
      }
    }
  };
});