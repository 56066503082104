define("tinybeans-frontend/templates/main/children/add", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/children/add.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "edit-child-info", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 24], [1, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "child", ["subexpr", "@mut", [["get", "model.child", ["loc", [null, [1, 36], [1, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "setup", ["subexpr", "@mut", [["get", "setup", ["loc", [null, [1, 54], [1, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "editing", false, "displayNotification", "displayNotification", "doTransition", "doTransition", "reloadPage", "reloadPage", "onSave", ["subexpr", "action", ["track"], [], ["loc", [null, [1, 175], [1, 191]]], 0, 0]], ["loc", [null, [1, 0], [1, 193]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});