define("tinybeans-frontend/templates/main/subscription/cancel", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 16
              },
              "end": {
                "line": 29,
                "column": 16
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/subscription/cancel.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "seat-form");
            dom.setAttribute(el1, "class", "clearfix");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "share-stat-item-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "user-details");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            var el5 = dom.createTextNode("\n                          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("h4");
            dom.setAttribute(el5, "class", "name");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                          Shared Subscription\n                        ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var element1 = dom.childAt(element0, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'src');
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1, 1]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["get", "seat.avatars.s", ["loc", [null, [20, 32], [20, 46]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "seat.fullName", ["loc", [null, [23, 43], [23, 60]]], 0, 0, 0, 0]],
          locals: ["seat"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 14
            },
            "end": {
              "line": 31,
              "column": 14
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/subscription/cancel.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "stat-item full");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "seated", ["loc", [null, [17, 24], [17, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 16], [29, 25]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 14
            },
            "end": {
              "line": 58,
              "column": 14
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/subscription/cancel.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "over-limit");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el2 = dom.createElement("svg");
          dom.setAttribute(el2, "width", "25");
          dom.setAttribute(el2, "height", "22");
          dom.setAttribute(el2, "viewBox", "0 0 25 22");
          dom.setAttribute(el2, "fill", "none");
          dom.setAttribute(el2, "xmlns", "http://www.w3.org/2000/svg");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("path");
          dom.setAttribute(el3, "d", "M14.0848 1.89752C13.9393 1.62824 13.7137 1.4014 13.4336 1.24277C13.1535 1.08414 12.83 1 12.5 1C12.17 1 11.8465 1.08414 11.5664 1.24277C11.2863 1.4014 11.0607 1.62824 10.9152 1.89752L1.65241 19.0513C1.541 19.2575 1.48919 19.4859 1.50188 19.7154C1.51457 19.9448 1.59137 20.1675 1.72496 20.3625C1.85856 20.5575 2.04456 20.7183 2.26548 20.8299C2.4864 20.9414 2.73495 21 2.98766 21H22.0124C22.2651 21 22.5136 20.9414 22.7345 20.8299C22.9554 20.7183 23.1415 20.5575 23.2751 20.3625C23.4087 20.1675 23.4854 19.9448 23.4981 19.7154C23.5108 19.4859 23.459 19.2575 23.3476 19.0513L14.0848 1.89752Z");
          dom.setAttribute(el3, "fill", "#322E39");
          dom.setAttribute(el3, "stroke", "#322E39");
          dom.setAttribute(el3, "stroke-width", "1.5");
          dom.setAttribute(el3, "stroke-linecap", "round");
          dom.setAttribute(el3, "stroke-linejoin", "round");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("path");
          dom.setAttribute(el3, "d", "M12.5011 16.0625C12.2291 16.0625 11.9632 16.136 11.7371 16.2734C11.5109 16.4107 11.3347 16.6056 11.2307 16.8339C11.1266 17.0623 11.0993 17.3138 11.1524 17.5563C11.2055 17.7987 11.3365 18.0215 11.5288 18.1963C11.7211 18.3711 11.9661 18.4903 12.2328 18.5386C12.4996 18.5868 12.776 18.5617 13.0273 18.4671C13.2786 18.3725 13.4934 18.2121 13.6445 18.0065C13.7956 17.801 13.8762 17.5595 13.8762 17.3122C13.8762 16.9808 13.7314 16.6632 13.4735 16.4288C13.2156 16.1944 12.8658 16.0625 12.5011 16.0625Z");
          dom.setAttribute(el3, "fill", "#FFF39A");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("path");
          dom.setAttribute(el3, "d", "M12.4995 14.3325V7.66626V14.3325Z");
          dom.setAttribute(el3, "fill", "#322E39");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("path");
          dom.setAttribute(el3, "d", "M12.4995 14.3325V7.66626");
          dom.setAttribute(el3, "stroke", "#FFF39A");
          dom.setAttribute(el3, "stroke-width", "1.5");
          dom.setAttribute(el3, "stroke-linecap", "round");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.setNamespace(null);
          var el2 = dom.createElement("h5");
          var el3 = dom.createTextNode("Your number of memories exceeds");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("our free plan limit");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("You have over 1000 photos and videos stored with Tinybeans which exceeds the storage limit on our free plan.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 113,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/subscription/cancel.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "subscription");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-offset-4 col-md-5");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "subscription-type cancel");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h1");
        dom.setAttribute(el6, "style", "text-align: center;");
        var el7 = dom.createTextNode("Hey, ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("!");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        dom.setAttribute(el6, "style", "text-align: center; font-size: 16px;");
        var el7 = dom.createTextNode("You've been with Tinybeans since ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(". You've kept your family connected all this time.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "id", "cancel-stats");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "stat-item");
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "class", "icon");
        var el8 = dom.createTextNode("👶");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Children");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "stat-item");
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "class", "icon");
        var el8 = dom.createTextNode("🧍‍♂️🧍‍♀️");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Family and Friends");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "stat-item");
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "class", "icon");
        var el8 = dom.createTextNode("📸");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Photos and Videos");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "stat-item");
        var el7 = dom.createElement("p");
        dom.setAttribute(el7, "class", "icon");
        var el8 = dom.createTextNode("💬");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createElement("h4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Comments");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "subscription-type cancel");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        dom.setAttribute(el6, "class", "text-center");
        dom.setAttribute(el6, "style", "font-size: 16px;");
        var el7 = dom.createTextNode("Though our free plan provides you with most of the features you love, canceling Tinybeans+ will limit your experience.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "id", "sub-features");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h3");
        dom.setAttribute(el6, "class", "text-left");
        var el7 = dom.createTextNode("You will lose access to:");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6, "class", "cancel");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Unlimited Uploads");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Ad free experience");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("2TB of storage");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Shared subscription");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Free shipping on all Photobooks");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "id", "help-text-title");
        var el6 = dom.createTextNode(" \n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h3");
        dom.setAttribute(el6, "class", "text-left");
        var el7 = dom.createTextNode("Do you wish to proceed?");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" \n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "id", "help-text");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el6 = dom.createElement("svg");
        dom.setAttribute(el6, "width", "25");
        dom.setAttribute(el6, "height", "22");
        dom.setAttribute(el6, "viewBox", "0 0 25 22");
        dom.setAttribute(el6, "fill", "none");
        dom.setAttribute(el6, "xmlns", "http://www.w3.org/2000/svg");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("path");
        dom.setAttribute(el7, "d", "M14.0848 1.89752C13.9393 1.62824 13.7137 1.4014 13.4336 1.24277C13.1535 1.08414 12.83 1 12.5 1C12.17 1 11.8465 1.08414 11.5664 1.24277C11.2863 1.4014 11.0607 1.62824 10.9152 1.89752L1.65241 19.0513C1.541 19.2575 1.48919 19.4859 1.50188 19.7154C1.51457 19.9448 1.59137 20.1675 1.72496 20.3625C1.85856 20.5575 2.04456 20.7183 2.26548 20.8299C2.4864 20.9414 2.73495 21 2.98766 21H22.0124C22.2651 21 22.5136 20.9414 22.7345 20.8299C22.9554 20.7183 23.1415 20.5575 23.2751 20.3625C23.4087 20.1675 23.4854 19.9448 23.4981 19.7154C23.5108 19.4859 23.459 19.2575 23.3476 19.0513L14.0848 1.89752Z");
        dom.setAttribute(el7, "fill", "#322E39");
        dom.setAttribute(el7, "stroke", "#322E39");
        dom.setAttribute(el7, "stroke-width", "1.5");
        dom.setAttribute(el7, "stroke-linecap", "round");
        dom.setAttribute(el7, "stroke-linejoin", "round");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("path");
        dom.setAttribute(el7, "d", "M12.5011 16.0625C12.2291 16.0625 11.9632 16.136 11.7371 16.2734C11.5109 16.4107 11.3347 16.6056 11.2307 16.8339C11.1266 17.0623 11.0993 17.3138 11.1524 17.5563C11.2055 17.7987 11.3365 18.0215 11.5288 18.1963C11.7211 18.3711 11.9661 18.4903 12.2328 18.5386C12.4996 18.5868 12.776 18.5617 13.0273 18.4671C13.2786 18.3725 13.4934 18.2121 13.6445 18.0065C13.7956 17.801 13.8762 17.5595 13.8762 17.3122C13.8762 16.9808 13.7314 16.6632 13.4735 16.4288C13.2156 16.1944 12.8658 16.0625 12.5011 16.0625Z");
        dom.setAttribute(el7, "fill", "#FFF39A");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("path");
        dom.setAttribute(el7, "d", "M12.4995 14.3325V7.66626V14.3325Z");
        dom.setAttribute(el7, "fill", "#322E39");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("path");
        dom.setAttribute(el7, "d", "M12.4995 14.3325V7.66626");
        dom.setAttribute(el7, "stroke", "#FFF39A");
        dom.setAttribute(el7, "stroke-width", "1.5");
        dom.setAttribute(el7, "stroke-linecap", "round");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.setNamespace(null);
        var el6 = dom.createElement("h5");
        var el7 = dom.createTextNode("If you have an App Store or Google Play subscription, please cancel through your device's subscription settings");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "text-center");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("btn");
        dom.setAttribute(el6, "class", "tny-btn");
        dom.setAttribute(el6, "style", "width: 100%;");
        var el7 = dom.createTextNode("Cancel Subscription");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "modal fade cancelSubModal");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "modal-dialog");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "modal-content");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-header");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "type", "button");
        dom.setAttribute(el5, "class", "close");
        dom.setAttribute(el5, "data-dismiss", "modal");
        dom.setAttribute(el5, "aria-hidden", "true");
        dom.setAttribute(el5, "style", "opacity: 1;");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6, "src", "https://d32onc8xm8gfkc.cloudfront.net/public/a3c8e489-274d-45aa-a3c7-84a1766c5612.svg");
        dom.setAttribute(el6, "alt", "");
        dom.setAttribute(el6, "width", "15px");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "cancel-info text-center");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Special offer ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "style", "color: #FF544B;");
        var el7 = dom.createTextNode("15%");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" off Tinybeans+");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("Keep your Tinybeans+ subscription benefits for an additional 12 months at $60.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("btn");
        dom.setAttribute(el5, "class", "tny-btn");
        var el6 = dom.createTextNode("Send me the offer!");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("btn");
        dom.setAttribute(el5, "class", "tny-btn inverse");
        var el6 = dom.createTextNode("Cancel Subscription");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "modal fade cancelStayModal");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "modal-dialog");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "modal-content");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-header");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "type", "button");
        dom.setAttribute(el5, "class", "close");
        dom.setAttribute(el5, "data-dismiss", "modal");
        dom.setAttribute(el5, "aria-hidden", "true");
        dom.setAttribute(el5, "style", "opacity: 1;");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6, "src", "https://d32onc8xm8gfkc.cloudfront.net/public/a3c8e489-274d-45aa-a3c7-84a1766c5612.svg");
        dom.setAttribute(el6, "alt", "");
        dom.setAttribute(el6, "width", "15px");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "cancel-info text-center");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("Thank you for staying with us 🥰");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("We've sent you an ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("strong");
        var el7 = dom.createTextNode("email");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" containing your promotion code along with redemption instructions.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("btn");
        dom.setAttribute(el5, "class", "tny-btn");
        dom.setAttribute(el5, "style", "width:100%;");
        var el6 = dom.createTextNode("Close");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1, 1, 1]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var element5 = dom.childAt(element2, [17, 3]);
        var element6 = dom.childAt(fragment, [2, 1, 1, 3]);
        var element7 = dom.childAt(element6, [5]);
        var element8 = dom.childAt(element6, [8]);
        var element9 = dom.childAt(fragment, [4, 1, 1, 3, 7]);
        var morphs = new Array(12);
        morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [1, 1, 0]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [3, 1, 0]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [5, 1, 0]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [7, 1, 0]), 0, 0);
        morphs[6] = dom.createMorphAt(element2, 5, 5);
        morphs[7] = dom.createMorphAt(element2, 11, 11);
        morphs[8] = dom.createElementMorph(element5);
        morphs[9] = dom.createElementMorph(element7);
        morphs[10] = dom.createElementMorph(element8);
        morphs[11] = dom.createElementMorph(element9);
        return morphs;
      },
      statements: [["content", "model.me.firstName", ["loc", [null, [6, 53], [6, 75]]], 0, 0, 0, 0], ["content", "startDate", ["loc", [null, [7, 97], [7, 110]]], 0, 0, 0, 0], ["content", "stats.childrenAmount", ["loc", [null, [10, 68], [10, 92]]], 0, 0, 0, 0], ["content", "stats.followersAmount", ["loc", [null, [11, 76], [11, 101]]], 0, 0, 0, 0], ["content", "stats.memoriesAmount", ["loc", [null, [12, 68], [12, 92]]], 0, 0, 0, 0], ["content", "stats.commentsAmount", ["loc", [null, [13, 68], [13, 92]]], 0, 0, 0, 0], ["block", "if", [["get", "hasSeatedFollower", ["loc", [null, [15, 20], [15, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [15, 14], [31, 21]]]], ["block", "if", [["get", "stats.toBeLimited", ["loc", [null, [47, 20], [47, 37]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [47, 14], [58, 21]]]], ["element", "action", ["cancelSub"], [], ["loc", [null, [73, 58], [73, 80]]], 0, 0], ["element", "action", ["sendOffer"], [], ["loc", [null, [91, 35], [91, 57]]], 0, 0], ["element", "action", ["cancelSub"], [], ["loc", [null, [91, 123], [91, 145]]], 0, 0], ["element", "action", ["closeCancel"], [], ["loc", [null, [108, 65], [108, 89]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});