define('tinybeans-frontend/adapters/pet', ['exports', 'tinybeans-frontend/adapters/application', 'tinybeans-frontend/mixins/api-mixin', 'tinybeans-frontend/services/lotame'], function (exports, _application, _apiMixin, _lotame) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _application.default.extend(_apiMixin.default, {
    REST: Ember.inject.service(),

    query: function query(store, type, _query) {
      var url = '/api/1/pets/' + _query.journalId;
      return this.ajax(url, 'GET', {});
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      var data = this.serialize(snapshot);
      data.journalId = parseInt(snapshot.adapterOptions.journalId);
      var url = '/api/1/pets/' + data.journalId;
      var file = snapshot.adapterOptions.file;

      var birthdate = data.birthday.split('-');
      var adoptionDate = data.adoptionDate;
      var behavior = 'pet added';
      var interest = [' : year of birth : ' + birthdate[0], ' : month of birth : ' + birthdate[1], ' : sex : ' + data.sex, ' : species : ' + data.species].concat(_toConsumableArray(data.size ? [' : size : ' + data.size] : []), _toConsumableArray(data.breed ? [' : breed : ' + data.breed] : []), _toConsumableArray(adoptionDate ? [' : adoption year : ' + adoptionDate.split('-')[0]] : []), _toConsumableArray(adoptionDate ? [' : adoption month : ' + adoptionDate.split('-')[1]] : []));

      new _lotame.Lotame().sendBehavior(behavior, interest, this.get("globals.userId"));

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('REST').addPetPost(url, data, resolve, reject, file);
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this2 = this;

      var data = this.serialize(snapshot);
      data.journalId = parseInt(snapshot.adapterOptions.journalId);
      data["id"] = snapshot.id;

      var url = '/api/1/pets/' + data.journalId;
      var file = snapshot.adapterOptions.file;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('REST').updatePetPut(url, data, resolve, reject, file);
      });
    }
  });
});