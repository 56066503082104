define('tinybeans-frontend/controllers/main/journals/follower-list', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _baseController, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {

    allFollowers: Ember.computed('model.followers', 'model.invitees', function () {
      var _this = this;

      var followers = this.get('model.followers').toArray();
      var invitees = this.get('model.invitees') ? this.get('model.invitees').toArray() : [];
      var followersOnly = followers.filter(function (f) {
        return f.get('user.id') !== _this.get('model.me.id');
      });
      var theFollowers = followersOnly.toArray().concat(invitees.toArray());
      theFollowers.sort(function (a, b) {
        if (a.get('order') < b.get('order')) return -1;
        if (a.get('order') > b.get('order')) return 1;
        return 0;
      });
      theFollowers.unshift(followers.find(function (f) {
        return f.get('user.id') === _this.get('model.me.id');
      }));
      return theFollowers;
    }),

    childNames: Ember.computed('model.journal', function () {
      var children = this.get('model.journal.children');
      children = children ? children.toArray() : undefined;
      return children && children.length ? children.map(function (child) {
        return child.get('firstName');
      }).join(', ').replace(/,(?=[^,]+$)/, ' and') + '\'s' : 'these';
    }),

    actions: {
      gotoTopOfPage: function gotoTopOfPage() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
      },
      invite: function invite(who) {
        this.send("gotoTopOfPage");
        this.GTM.trackEvent({
          'eventCategory': 'interact-with-follower-list-page',
          'eventAction': who ? 'click-follower-options' : 'add-follower',
          'eventLabel': who ? who : ''
        });
        this.transitionToRoute('main.journals.follower-list-invite', this.model.journalId);
      },
      saveFollower: function saveFollower(follower) {
        if (!this.get('editingDisabled')) {
          this.API.updateJournalFollower(this.get('model.journalId'), follower, function () {}, function () {});

          // follower.save({
          //   adapterOptions: {journalId: this.get('model.journalId')}
          // });
        }
      },


      showVideo: function showVideo() {
        Ember.$(".modal-backdrop").hide();
        bootbox.dialog({
          title: 'How Tinybeans works',
          message: '<div style="text-align:center"><iframe id="youtube-video" width="560" height="315" src="https://www.youtube.com/embed/Uf2hWH1KJHI" frameborder="0" allowfullscreen></iframe></div>',
          buttons: {
            cancel: {
              label: 'Close',
              className: "btn-primary"
            }
          }
        });
        this.GTM.trackEvent({
          'eventCategory': 'interact-with-follower-list-page',
          'eventAction': 'click',
          'eventLabel': 'how-it-works-button'
        });
      },

      removeFollower: function removeFollower(follower) {
        var _this2 = this;

        var journalId = this.get('model.journalId');
        follower.destroyRecord({ adapterOptions: { journalId: journalId } }).then(function () {
          _this2.get('model.followers').removeObject(follower);
          _this2.notifyPropertyChange('allFollowers');
        }, function (error) {
          console.log('error removing follower', error);
        });
        this.GTM.trackEvent({
          'eventCategory': 'interact-with-follower-list-page',
          'eventAction': 'click',
          'eventLabel': 'remove-follower-button'
        });
      }
    }

  });
});