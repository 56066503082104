define('tinybeans-frontend/routes/main/subscription-products/purchase-voucher', ['exports', 'tinybeans-frontend/routes/base-route', 'lodash/lodash'], function (exports, _baseRoute, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    session: Ember.inject.service('session'),

    model: function model(params) {
      var journalId = params.journal_id;
      var authenticated = this.get('session.isAuthenticated');

      return Ember.RSVP.hash({
        journalId: journalId,
        storeCustomer: authenticated ? this.API.getStoreCustomer() : null,
        journals: authenticated ? this.store.findAll('journal') : null,
        user: authenticated ? this.store.find('user', 'me') : null,
        giftCards: this.API.getGiftCards()
      });
    },

    setupController: function setupController(controller, model) {
      var self = this;
      self._super(controller, model);

      if (model.user) {
        controller.set('senderEmail', model.user.get('emailAddress'));
      }

      var giftCards = model.giftCards.giftcards;

      if (giftCards) {
        var formattedGiftCards = [];
        _lodash.default.each(giftCards, function (gCard) {
          if (gCard.baseColor) {
            gCard.backgroundColor = "background:" + gCard.baseColor + "!important;";
            gCard.textColor = "color:" + gCard.baseColor + "!important;";
          }
          gCard.id = Number(gCard.id);

          if (gCard.currency == "USD") {
            gCard.currencyUnit = "US$";
          }

          if (gCard.discountedAmountInCents == gCard.amountInCents) {
            gCard.showOriginalPrice = false;
          } else {
            gCard.showOriginalPrice = true;
          }
          gCard.price = Number(gCard.discountedAmountInCents) / 100;
          gCard.originalPrice = Number(gCard.amountInCents) / 100;
          formattedGiftCards.pushObject(gCard);
        });
        controller.set('giftCards', formattedGiftCards.slice(0, 1)); // only display the first gift card that returns from API, which is yearly
      }

      controller.set('selectedGiftCard', null);
      controller.set('recipientSameAsSender', false);

      if (model.storeCustomer) {
        model.customer = model.storeCustomer.customer;
        if (model.customer) {
          controller.set('card', model.customer.card);
        }
      }
    }
  });
});