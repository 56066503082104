define('tinybeans-frontend/components/main/entries/entry-child', ['exports', 'lodash/lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNameBindings: ['divClass'],
    attributeBindings: ['src'],
    targetObject: Ember.computed.alias('parentView'),

    divClass: function () {
      var editingTagged = this.get('editingTagged');
      var childTagged = this.get('childTagged');
      var hiddenConditionCss = !editingTagged && !childTagged ? 'hidden' : '';
      var canToggleCss = this.canToggleChild() ? 'cursor-pointer' : '';
      return 'child-selector ' + hiddenConditionCss + ' ' + canToggleCss;
    }.property('childTagged', 'editingTagged'),

    taggedStyle: function () {
      var tagged = this.get('childTagged');
      var editingTagged = this.get('editingTagged');
      if (tagged && editingTagged) {
        return "border: 3px solid orange; height: 34px; width: 34px; margin-left: 0px; margin-top: -2px;";
      } else {
        return "";
      }
    }.property('childTagged', 'editingTagged'),

    entryTitle: function () {
      var child = this.get('child');
      return this.canToggleChild() ? 'Select to tag ' + child.get('firstName') + ' in this moment' : '';
    }.property(),

    canToggleChild: function canToggleChild() {
      var isMyJournal = this.get('model.me.id') === this.get('model.journal.user.id');
      var isMine = this.get('entry.u.id') == this.get('model.me.id');
      return isMyJournal || isMine;
    },

    childTagged: Ember.computed('entry', 'entry.children.@each', function () {
      var _this = this;

      if (!this.get('entry.children')) return false;
      return _lodash.default.find(this.get('entry.children').toArray(), function (child) {
        return child.id === _this.get('child.id');
      });
    }),

    click: function click() {
      if (this.canToggleChild()) {
        if (this.get('childTagged')) this.get('entry.children').removeObject(this.get('child'));else this.get('entry.children').addObject(this.get('child'));

        this.sendAction('updateEntry', this.get('entry'));
      }
    }
  });
});