define('tinybeans-frontend/models/pet-checklist-item', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    contentUrl: (0, _attr.default)('string'),
    perc03: (0, _attr.default)('number'),
    perc10: (0, _attr.default)('number'),
    perc50: (0, _attr.default)('number'),
    perc90: (0, _attr.default)('number'),
    perc97: (0, _attr.default)('number'),
    male: (0, _attr.default)('boolean'),
    female: (0, _attr.default)('boolean'),
    deleted: (0, _attr.default)('boolean'),
    measured: (0, _attr.default)('boolean'),
    featureImageUrl: (0, _attr.default)('string'),
    thumbnailImageUrl: (0, _attr.default)('string'),
    tinybeansChallengeText: (0, _attr.default)('string'),
    tinybeansChallengeRating: (0, _attr.default)('string')
  });
});