define('tinybeans-frontend/components/main/journals/month/child-component', ['exports', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_commonsMixin.default, {

    milestoneCount: Ember.computed('model', 'child', function () {
      return this.get('model.checklistCountByChild')[this.get('child.id')] || 0;
    }),

    actions: {
      showChild: function showChild() {
        if (this.get('canViewMilestones')) {
          this.sendAction('doTransition', 'main.children.show', this.get('model.journalId'), this.get('child.id'), 0);
        }
        if (this.onclick) {
          this.onclick();
        }
      }
    }

  });
});