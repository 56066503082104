define('tinybeans-frontend/routes/main/journals/home', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/helpers/storage'], function (exports, _baseProtectedRoute, _storage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var lastViewedJournalStorage = new _storage.Storage('last-viewed-journal');

  exports.default = _baseProtectedRoute.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        me: this.store.find('user', 'me'),
        followings: this.modelFor('main').followings,
        journals: this.modelFor('main').journals
      });
    },

    afterModel: function afterModel(model) {
      var userId = model.me.get('id');
      var primaryOwnedJournal = model.journals.filterBy('user.id', userId).objectAt(0);
      var userLastViewedJournal = lastViewedJournalStorage.byUser(userId);

      if (primaryOwnedJournal) {
        this.transitionTo('main.journals.view', primaryOwnedJournal.get('id'));
      } else if (userLastViewedJournal.exists()) {
        this.transitionTo('main.journals.view', userLastViewedJournal.get());
      } else if (model.followings && model.followings.get('length') > 0) {
        this.transitionTo('main.journals.view', model.followings.objectAt(0).get('journal').get('id'));
      } else {
        this.transitionTo('main.journals.no-journal');
      }
    }
  });
});