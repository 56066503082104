define('tinybeans-frontend/adapters/pet-completed-checklist-item', ['exports', 'tinybeans-frontend/adapters/application', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _application, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_dataAdapterMixin.default, {
    queryRecord: function queryRecord(store, type, query) {
      var url = '/api/1/pets/' + query.petId + '/checklistItems';
      return this.ajax(url, 'GET', {});
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      var data = this.serialize(snapshot);
      var petId = snapshot.adapterOptions.petId;
      var url = '/api/1/pets/' + petId + '/checklistItem';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('REST').POST(url, data, resolve, reject);
      });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this2 = this;

      var petId = snapshot.adapterOptions.petId;
      var itemId = snapshot.record.get('checklistItemId');
      var url = '/api/1/pets/' + petId + '/checklistItems/' + itemId + '/uncomplete';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('REST').POST(url, {}, resolve, reject);
      });
    }
  });
});