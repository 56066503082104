define('tinybeans-frontend/adapters/journal', ['exports', 'tinybeans-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    session: Ember.inject.service(),

    query: function query(store, type, _query) {
      var url = '/api/1/journals/' + _query.filter;
      return this.ajax(url, 'GET');
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      if (!this.get('session.isAuthenticated')) {
        return { journal: { id: id } };
      }
      var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
      var query = this.buildQuery(snapshot);

      return Ember.RSVP.hash({
        journal: this.ajax(url, 'GET', { data: query })
      });
    },
    findAll: function findAll() {
      var url = '/api/1/journals';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.getJSON(url).then(function (data) {
          Ember.run(null, resolve, data);
        }, function (jqXHR) {
          this.set('session.isAuthenticated', false);
          jqXHR.then = null; // tame jQuery's ill mannered promises
          Ember.run(null, reject, jqXHR);
        });
      });
    },
    createRecord: function createRecord(store, type, snapshot) {
      var data = this.serialize(snapshot);
      var url = '/api/1/journals';

      return this.post(url, data);
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = this.serialize(snapshot);
      var id = snapshot.id;
      var url = '/api/1/journals/' + id;
      data.emailFrequencyOnNewEvent = snapshot.adapterOptions.emailFrequencyOnNewEvent || null;

      return this.post(url, data);
    },
    post: function post(url, data) {
      data.coverTheme = data.coverTheme.name;
      delete data.user;
      delete data.children;
      // Object too big when they are sent and backend doesn't use them

      data = JSON.stringify(data);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          data: data
        }).then(function (data) {
          Ember.run(null, resolve, data);
        }, function (jqXHR) {
          jqXHR.then = null; // tame jQuery's ill mannered promises
          Ember.run(null, reject, jqXHR);
        });
      });
    }
  });
});