define('tinybeans-frontend/routes/main/onboarding/step-two', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Onboarding.category,
    screen: _tracker.Onboarding.step2.screen,

    model: function model(params) {
      var journalId = params.journal_id;

      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        pet: this.store.createRecord('pet'),
        followings: this.modelFor('main').followings,
        journals: this.store.findAll('journal'),
        relations: this.API.getFollowerRelations()
      });
    },

    afterModel: function afterModel(model) {
      model.pet.set('name', model.me.get('name'));
      model.avatar = null;
    },

    actions: {
      reloadPage: function reloadPage() {
        this.refresh();
      }
    }

  });
});