define('tinybeans-frontend/components/random-entry-component', ['exports', 'tinybeans-frontend/mixins/commons-mixin', 'tinybeans-frontend/tracker'], function (exports, _commonsMixin, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_commonsMixin.default, {

    randomEntry: Ember.computed('model.randomEntry', function () {
      var randomEntry = this.get('model.randomEntry');

      //check if user is authenticated
      var authenticated = this.get('model.authenticated') || this.get('authenticated');

      if (randomEntry) {

        //if no random entry, not show
        //if random entry date is current date do not show

        var date = moment({
          day: randomEntry.day,
          month: randomEntry.month - 1,
          year: randomEntry.year
        });

        var dateClashing = randomEntry.day + "-" + randomEntry.month + "-" + randomEntry.year == this.get('model.day') + "-" + this.get('model.month') + "-" + this.get('model.year');

        //make sure if random entry is not empty & it's not the same date as the page date, then show it
        randomEntry.show = (randomEntry.day != null || randomEntry.id != null) && !dateClashing;

        randomEntry.monthYearLabel = date.fromNow();

        if (authenticated && this.get('hasAccessToJournal')) {

          if (randomEntry.blobs != null) {
            randomEntry.thumbnailImage = randomEntry.blobs.s2;
            randomEntry.showThumbnail = true;
          } else {
            return null;
          }
        } else {

          var thumbnailImageId = Math.floor(Math.random() * 22 + 1);
          randomEntry.thumbnailImage = "images/day-page/random-entry/random-entry-icon-" + thumbnailImageId + ".png";
          randomEntry.showThumbnail = false;
        }
      }

      return randomEntry;
    }),

    actions: {
      gotoDayView: function gotoDayView(journalId, year, month, day, itemName) {

        Ember.$(window).scrollTop(0);
        var authenticated = this.get('model.authenticated') || this.get('authenticated') ? true : false;

        _tracker.Tracker.trackEvent({
          action: _tracker.RandomEntry.leanplum.actions.flashbackModuleButton,
          eventLabel: itemName,
          loggedIn: authenticated
        });
        // console.log(`----RANDOM ENTRY----, Action: ${RandomEntry.leanplum.actions.flashbackModuleButton}, LOGGED IN: ${authenticated}, itemName: ${itemName}`);
        this.sendAction('gotoDayView', journalId, year, month, day);
      }
    }

  });
});