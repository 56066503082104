define('tinybeans-frontend/components/mobile-sidebar', ['exports', 'tinybeans-frontend/mixins/navigation', 'tinybeans-frontend/mixins/gift-guide-mixin'], function (exports, _navigation, _giftGuideMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_navigation.default, _giftGuideMixin.default, {

    hasOwnJournal: function () {
      var journals = this.get('journals');
      var me = this.get('me');
      for (var i = 0; i < journals.get('length'); i++) {
        if (journals.objectAt(i).get('user') === me) {
          return true;
        }
      }
      return false;
    }.property('me', 'journals.@each'),

    actions: {
      switchJournal: function switchJournal(journal) {
        this.sendAction('switchJournal', journal);
      },

      subscriptionProductsOverview: function subscriptionProductsOverview() {
        var currentJournal = this.get('controllers.main.currentJournal');
        this.transitionToRoute('main.subscription-products.list', {
          queryParams: {
            journalid: currentJournal.id,
            overviewOnly: false
          }
        });
      },

      transition: function transition(route, param) {
        this.send('closeSideNav');
        Ember.$(".navbar-my-details").addClass("active");
        switch (route) {
          case 'account':
            route = 'main.account.show';
            break;
          case 'billing':
            route = 'main.billing.show';
            break;
          case 'refer-friend':
            route = 'main.refer-friend.show';
            break;
          case 'collection-list':
            route = 'main.collections.list';
            break;
          case 'premium':
            route = 'main.subscription-products.list';
            break;
          case 'export':
            route = 'main.journals.export';
            break;
          case 'follower-list':
            route = 'main.journals.follower-list';
            break;
          case 'add-journal':
            route = 'main.journals.add';
            break;
          case 'journal-list':
            route = 'main.journals.home';
            break;
          case 'manage-journal':
            var currentJournal = this.get('currentJournal');
            if (currentJournal) {
              route = 'main.journals.edit';
              param = currentJournal.get('id');
            }
            break;
          case 'children':
            route = 'main.children.list';
            break;
          case 'products':
            route = 'main.products.list';
            break;
          case 'search':
            route = 'main.journals.search';
            break;
          default:

        }
        if (param) this.sendAction('doTransition', route, param);else this.sendAction('doTransition', route);
      },

      gotoPremiumPublic: function gotoPremiumPublic() {
        this.send('transition', 'premium', {
          queryParams: {
            overviewOnly: false
          }
        });
      },
      upgradeToPremium: function upgradeToPremium() {
        this.send('transition', 'premium', {
          queryParams: {
            journalid: this.get('currentJournal.id'),
            overviewOnly: false
          }
        });
      },


      logout: function logout() {
        this.sendAction('logout');
      },

      toggleSidebar: function toggleSidebar() {
        //  e.preventDefault();
        Ember.$("#wrapper").toggleClass("toggled");

        var hasToggled = Ember.$("#wrapper").hasClass("toggled");

        if (hasToggled) {
          Ember.$('#slide-show-control').hide();
          Ember.$('#trigger-layer').show();
        } else {
          Ember.$('#slide-show-control').show();
          Ember.$('#trigger-layer').hide();
        }
      }
    }
  });
});