define('tinybeans-frontend/components/ad-component-freestar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('isFooterAd')) {
        this.send('loadFooterAds');
      } else {
        this.send("loadAds");
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('isFooterAd')) {
        this.send('unloadFooterAds');
      }
      this._super.apply(this, arguments);
    },


    adId: Ember.computed("adId", function () {
      return this.get("adId");
    }),

    actions: {

      trackAdsInfoClick: function trackAdsInfoClick() {
        var self = this;
        // console.log("clicked i");
        var eventAction = "w_click";
        self.GTM.trackEvent({
          eventCategory: "adTracking",
          eventAction: eventAction,
          eventLabel: "moreInfo"
        });
      },

      trackAdsBannerClick: function trackAdsBannerClick() {
        var self = this;
        //console.log("clicked banner");
        var eventAction = "w_click";
        self.GTM.trackEvent({
          eventCategory: "adTracking",
          eventAction: eventAction,
          eventLabel: ""
        });
        if (typeof AF !== "undefined") AF('pba', 'event', { eventType: 'EVENT', eventCategory: "adTracking", eventValue: { 'label': '', 'action': eventAction }, eventName: 'Click ad Link' });
      },
      loadAds: function loadAds() {
        var self = this;
        var adId = self.get("adId");
        var freestar = window.freestar || {};
        freestar.queue = freestar.queue || [];
        freestar.queue.push(function () {
          freestar.newAdSlots({ placementName: adId, slotId: adId });
        });
      },
      loadFooterAds: function loadFooterAds() {
        var self = this;
        var adId = self.get("adId");
        var freestar = window.freestar || {};
        freestar.queue = freestar.queue || [];
        freestar.queue.push(function () {
          freestar.newStickyFooter(adId);
        });
      },
      unloadFooterAds: function unloadFooterAds() {
        var self = this;
        var adId = self.get("adId");
        var freestar = window.freestar || {};
        freestar.queue = freestar.queue || [];
        freestar.queue.push(function () {
          freestar.deleteStickyFooter(adId);
        });
      }
    }
  });
});