define("tinybeans-frontend/tracker/leanplum", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LP = {
    setUser: function setUser(user) {
      if (user) {
        Leanplum.setUserAttributes(user.id, { firstName: user.firstName, lastName: user.lastName, email: user.emailAddress });
      }
    },
    trackEvent: function trackEvent(event) {
      //console.log('leanplum tracking ', event);
      Leanplum.track(event.action, event);
    },
    addVariablesChangedHandler: function addVariablesChangedHandler(callback) {
      Leanplum.addVariablesChangedHandler(callback);
    },
    removeVariablesChangedHandler: function removeVariablesChangedHandler(callback) {
      Leanplum.removeVariablesChangedHandler(callback);
    }
  };

  exports.default = LP;
});