define('tinybeans-frontend/routes/main/subscription-products/list', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash', 'tinybeans-frontend/tracker', 'tinybeans-frontend/config/environment', 'tinybeans-frontend/utils/helper'], function (exports, _baseProtectedRoute, _lodash, _tracker, _environment, _helper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Premium.category,
    screen: _tracker.Premium.list.screen,

    session: Ember.inject.service('session'),

    model: function model(params) {
      var authenticated = this.get('session.isAuthenticated');

      var journalId = authenticated && this.modelFor('main').currentJournal ? this.modelFor('main').currentJournal.id : null;

      return Ember.RSVP.hash({
        subscriptionProducts: this.API.getSubscriptionProducts(),
        storeCustomer: authenticated ? this.API.getStoreCustomer() : null,
        journals: authenticated ? this.store.findAll('journal') : null,
        myJournals: authenticated ? this.store.query('journal', { filter: 'mine' }) : null,
        me: authenticated ? this.store.find('user', 'me') : null,
        journalId: journalId,
        selectedProduct: params.selectedProduct,
        isSubscribedUser: this.API.getActiveSubscription(),
        customer: null,
        card: null,
        memories_limit: this.API.getMemoriesLimit(),
        languages: navigator.languages,
        isLegacy: params.isLegacy
      });
    },

    deactivate: function deactivate() {
      Ember.$('.modal').modal('hide');
      this.disconnectOutlet({
        outlet: 'modal-dialog'
      });
    },

    afterModel: function afterModel(model, transition) {
      // It is possible to arrive here via a bootbox dialog
      bootbox.hideAll();
      Ember.$('body').removeClass('noscroll');

      this.globals.setState(transition);
      this.globals.setSource(transition);

      if (!model.journalId && model.myJournals) {
        var availableJournalId = _lodash.default.first(model.myJournals.toArray()) || _lodash.default.first(model.journals.toArray());
        model.journalId = availableJournalId.id;
      }

      if (model.storeCustomer) {
        model.customer = model.storeCustomer.customer;
        if (model.customer) {
          model.card = model.customer.card;
        }
      }

      var eventLabel = this.globals.isIOS ? "ios" : this.globals.isAndroid ? "android" : "web";
      var eventAction = this.globals.isNative ? transition.queryParams.origin : 'click_from_journal_view';
      this.GTM.trackEvent({
        'eventCategory': 'premium_choice_page',
        'eventAction': eventAction,
        'eventLabel': eventLabel
      });
    },

    setupController: function setupController(controller, model) {
      var self = this;
      self._super(controller, model);
      var language_code = model.languages[0] || 'en-US';
      controller.set('userLanguage', language_code);

      // Subscription LP Updated
      Leanplum.addVariablesChangedHandler(function () {
        var vars = Leanplum.getVariables();
        if (vars.Paywall) {
          var paywall_prices = '';
          if ('undefined' !== typeof vars.Paywall[language_code]) {
            paywall_prices = vars.Paywall[language_code];
          } else {
            paywall_prices = vars.Paywall['en-US']; // default or unhandled
          }
          var paywall_products = JSON.parse(vars.Paywall.newProducts);
          controller.set('titleDesktop', 'Upgrade your plan to enjoy all\nTinybeans+ subscription benefits');
          controller.set('titleMobile', 'Upgrade your plan to enjoy all Tinybeans+ subscription benefits');
          controller.set('paidFeatures', paywall_products.paidFeatures);
          controller.set('freeFeatures', paywall_products.freeFeatures);

          // Includes currency
          var prices = (0, _helper.calculatePrices)(paywall_prices);

          controller.set('monthlyPriceId', paywall_prices.monthlyStripePriceId);
          controller.set('annualPriceId', paywall_prices.yearlyStripePriceId);

          controller.set('prices', prices);
          controller.set('isLegacy', model.isLegacy);
        }
      });
      var subscription = model.isSubscribedUser.subscription;

      if (subscription) {
        controller.set('isSubscribedUser', true);
      } else {
        controller.set('isSubscribedUser', false);
      }

      if (model.subscriptionProducts) {
        var monthProduct = _lodash.default.find(model.subscriptionProducts, function (product) {
          return product.id == 9;
        });
        var yearProduct = _lodash.default.find(model.subscriptionProducts, function (product) {
          return product.id == 10;
        });
        var lifetimeProduct = _lodash.default.find(model.subscriptionProducts, function (product) {
          return product.id == 12;
        });

        if (monthProduct) {
          //   monthProduct['displayName'] = monthProduct.cycle.displayName;
          monthProduct['price'] = "$" + monthProduct.amountInCents / 100;
          monthProduct['frequency'] = "month";
          monthProduct['button_label'] = "Get Monthly";
          monthProduct['plan_name'] = "Monthly Membership";
          monthProduct['plan_subheading'] = "Recurring billing. Cancel anytime";
          monthProduct['unit'] = monthProduct.name;
          monthProduct['class'] = "col-lg-3 plan-month";
          monthProduct['bottom_class'] = "bottom-plan-month";
          monthProduct['showOnWeb'] = monthProduct.productStatus == "AVAILABLE" ? true : false;
        }

        if (yearProduct) {
          // yearProduct['displayName'] = yearProduct.cycle.displayName;
          yearProduct['price'] = yearProduct.amountInCents % (12 * 100) == 0 ? "$" + yearProduct.amountInCents / (12 * 100) : "$" + (Math.ceil(yearProduct.amountInCents / (10 * 12)) / 10).toFixed(2);
          yearProduct['frequency'] = "month";
          yearProduct['button_label'] = "Get Annual";
          yearProduct['plan_name'] = "Annual Membership";
          yearProduct['plan_subheading'] = "$50 billed annually. Cancel anytime";
          yearProduct['unit'] = yearProduct.name;
          yearProduct['class'] = "col-lg-3 plan-year";
          yearProduct['bottom_class'] = "bottom-plan-year";
          yearProduct['showOnWeb'] = yearProduct.productStatus == "AVAILABLE" ? true : false;
        }

        if (lifetimeProduct) {
          //  lifetimeProduct['displayName'] = lifetimeProduct.cycle.displayName;
          lifetimeProduct['price'] = "$" + lifetimeProduct.amountInCents / 100;
          lifetimeProduct['frequency'] = "One-time payment";
          lifetimeProduct['button_label'] = "Get Lifetime";
          lifetimeProduct['plan_name'] = "Lifetime Membership";
          lifetimeProduct['plan_subheading'] = "";
          lifetimeProduct['unit'] = lifetimeProduct.name;
          lifetimeProduct['class'] = "col-lg-3 plan-lifetime";
          lifetimeProduct['bottom_class'] = "bottom-plan-lifetime";
          lifetimeProduct['showOnWeb'] = lifetimeProduct.productStatus == "AVAILABLE" ? true : false;
        }
        controller.set("monthProduct", monthProduct);
        controller.set("yearProduct", yearProduct);
        controller.set("lifetimeProduct", lifetimeProduct);
      }
      if (subscription && subscription.isGifted) {
        controller.set('HasGiftedSubscription', true);
      }
    }

  });
});