define('tinybeans-frontend/routes/main/collections/list', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.PhotoCollections.category,
    screen: _tracker.PhotoCollections.list.screen,

    error: {},
    model: function model(params) {
      var journalId = params.journal_id;
      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        collections: this.store.query('collection', { journalId: journalId }),
        followers: this.store.findAll('follower'),
        followings: this.modelFor('main').followings
      });
    },

    setupController: function setupController(controller, model) {
      var self = this;
      this._super(controller, model);

      var d = new Date();
      var currentYear = d.getFullYear();
      var currentMonth = d.getMonth() + 1;

      controller.set('year', currentYear);
      controller.set('month', currentMonth);
      controller.set('noCollectionYet', model.collections.get('length') > 0 ? false : true);
      controller.set('collectionEnabled', self.globals.photoCollectionEnabled(model.journal));
    }
  });
});