define('tinybeans-frontend/components/observed-textarea', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextArea.extend({
    value: '',

    keyUp: function keyUp() {
      console.log('value is ' + this.get('value'));
      console.log('changed to ' + this.$().val());
    },

    change: function change() {
      console.log('value2 is ' + this.get('value'));
      console.log('changed2 to ' + this.$().val());
    }
  });
});