define("tinybeans-frontend/routes/event-signup", ["exports", "tinybeans-frontend/routes/base-route"], function (exports, _baseRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        user: {
          firstName: "",
          lastName: "",
          username: "",
          emailAddress: "",
          password: "webtemppass" + new Date().getTime(),
          phoneNumber: "",
          sendWelcome: true,
          campaign: this.get('campaign'),
          sendResetPassword: true,
          dateStyle: moment.localeData().longDateFormat('L').toUpperCase().replace(/\//g, '')
        }
      });
    }
  });
});