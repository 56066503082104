define('tinybeans-frontend/routes/main/themed-cards/macmillan/show', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController: function setupController(controller) {
            var model = this.modelFor('main');
            if (model) {
                model.journal = model.currentJournal;
                controller.set('model', model);
            }
        }
    });
});