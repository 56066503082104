define('tinybeans-frontend/adapters/weight', ['exports', 'tinybeans-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    query: function query(store, type, _query) {
      var url = '/api/1/children/' + _query.childId + '/weights';
      return this.ajax(url, 'GET');
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      var childId = snapshot.adapterOptions.childId;
      var url = '/api/1/children/' + childId + '/weights';
      var data = this.serialize(snapshot);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('REST').POST(url, data, resolve, reject);
      });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var childId = snapshot.adapterOptions.childId;
      var url = '/api/1/children/' + childId + '/weights/' + snapshot.id;
      return this.ajax(url, 'DELETE');
    }
  });
});