define('tinybeans-frontend/controllers/main/subscription-products/voucher', ['exports', 'tinybeans-frontend/controllers/base-controller'], function (exports, _baseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({

    queryParams: ['code', 'overviewOnly'],
    // queryParams: ['journalid', 'overviewOnly'],

    journalId: null,
    voucherApplied: false,
    moreThanOneJournal: function () {
      return this.get('followings').length > 1;
    }.property('followings'),

    actions: {
      applyVoucher: function applyVoucher() {
        var _this = this;

        var voucherCode = Ember.$("#voucherForm #voucherCodeHolder").val();
        var acceptTerms = Ember.$("#acceptTerms:checked").val();
        var selectedJournalId = Ember.$('input[name=selectableJournals]:checked', '#selectableJournals').val();
        var journalId;

        if (!selectedJournalId || selectedJournalId === "undefined") {
          Ember.$("#selectJournalResult").html("Please select journal");
        } else {
          journalId = Number(selectedJournalId);
          Ember.$("#selectJournalResult").html("");
        }

        if (!voucherCode) {
          Ember.$("#voucherForm #voucherResult").html("Invalid coupon");
        } else {
          Ember.$("#voucherForm #voucherResult").html("");
        }

        if (!acceptTerms) {
          Ember.$("#voucherForm #acceptTermsResult").html("Please accept our Terms and Conditions");
        } else {
          Ember.$("#voucherForm #acceptTermsResult").html("");
        }

        if (voucherCode && acceptTerms && journalId) {

          Ember.$("#voucherForm #voucherCodeHolder").prop('disabled', true);

          this.API.applyVoucher(voucherCode, journalId, function () {
            //success
            //Ember.$("#finalResult").fadeIn('fast').removeClass("alert-danger").addClass("alert-success").html(message).delay(5000).fadeOut(1000);
            _this.route.refresh();
            _this.transitionToRoute('main.subscription-products.voucher-success', journalId);
            Ember.$("#voucherForm #voucherCodeHolder").prop('disabled', false);
            // Ember.$("#voucherForm #voucherCodeHolder").disabled(true);
          }, function () {
            //error
            // Ember.$("#voucherForm #voucherCodeHolder").disabled(false);
            Ember.$("#finalResult").fadeIn('fast').removeClass("alert-success").addClass("alert-danger").html("Invalid voucher or the voucher has already been redeemed");
            Ember.$("#voucherForm #voucherCodeHolder").prop('disabled', false);
          });
        }
      }
    }
  });
});