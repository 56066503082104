define('tinybeans-frontend/routes/main/journals/index', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);

      if (model.myJournals && model.myJournals.length === 1 && model.journals.length === model.myJournals.length) {
        this.transitionTo('main.journals.view', model.myJournals[0].id);
      } else if (model.journals && model.journals.length === 1) {
        this.transitionTo('main.journals.view', model.journals[0].id);
      } else {
        this.transitionTo('main.journals.list');
      }
    }
  });
});