define('tinybeans-frontend/authenticators/access-token', ['exports', 'tinybeans-frontend/config/environment', 'tinybeans-frontend/authenticators/token'], function (exports, _environment, _token) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _token.default.extend({
    sendAuthenticate: function sendAuthenticate(identification, password, rememberMe, inviteToken) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var url = _environment.default.apiEndPoint + '/api/1/authenticate';
        var credentials = {
          clientId: _environment.default.clientID,
          deviceId: localStorage.getItem('__leanplum_device_id'),
          username: identification,
          password: password,
          inviteToken: inviteToken
        };
        var jsonString = JSON.stringify(credentials, null, 4);
        var ajaxRequest = Ember.$.ajax({
          url: url,
          type: 'post',
          data: jsonString,
          contentType: 'application/json',
          dataType: 'json'
        });
        ajaxRequest.then(function (data) {
          Ember.run(function () {
            data.rememberMe = rememberMe;
            data.access_token = data.accessToken;
            resolve(data);
          });
        }, function (xhr) {
          Ember.run(function () {
            reject(xhr.responseJSON || xhr.responseText);
          });
        });
      });
    },
    authenticate: function authenticate(options) {
      var identification = options.identification;
      var password = options.password;
      var rememberMe = options.rememberMe;
      var inviteToken = options.inviteToken;
      if (identification && password) {
        return this.sendAuthenticate(identification, password, rememberMe, inviteToken);
      } else {
        return new Ember.RSVP.reject();
      }
    }
  });
});