define('tinybeans-frontend/authenticators/play-cookie', ['exports', 'tinybeans-frontend/config/environment', 'tinybeans-frontend/authenticators/token'], function (exports, _environment, _token) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _token.default.extend({
    sendAuthenticate: function sendAuthenticate() {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var url = _environment.default.apiEndPoint + '/api/1/authenticateWithPlayCookie';
        var credentials = {
          clientId: _environment.default.clientID
        };
        var jsonString = JSON.stringify(credentials, null, 4);
        var ajaxRequest = Ember.$.ajax({
          url: url,
          type: 'post',
          data: jsonString,
          contentType: 'application/json',
          dataType: 'json'
        });
        ajaxRequest.then(function (data) {
          Ember.run(function () {
            data.rememberMe = true;
            data.access_token = data.accessToken;
            resolve(data);
          });
        }, function () {
          Ember.run(function () {
            reject();
          });
        });
      });
    },

    authenticate: function authenticate(options) {
      return this.sendAuthenticate(options);
    }
  });
});