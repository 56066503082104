define('tinybeans-frontend/components/main/pets/milestones/pets-summary', ['exports', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_commonsMixin.default, {

    canShare: Ember.computed(function () {
      return this.get('model.me') && (this.get('model.journal.user.id') == this.get('model.me.id') || this.get('meAsFollower.coOwner'));
    })
  });
});