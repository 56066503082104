define("tinybeans-frontend/templates/main/collections/add", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 20
                  },
                  "end": {
                    "line": 14,
                    "column": 20
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/collections/add.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "style", "margin-top:10px; margin-bottom:10px;");
                var el2 = dom.createElement("span");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var element3 = dom.childAt(element2, [0]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element2, 'class');
                morphs[1] = dom.createAttrMorph(element3, 'class');
                morphs[2] = dom.createMorphAt(element2, 2, 2);
                return morphs;
              },
              statements: [["attribute", "class", ["get", "flash.type", ["loc", [null, [13, 76], [13, 86]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "flash.glyphiconClass", ["loc", [null, [13, 105], [13, 125]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "flash.message", ["loc", [null, [13, 137], [13, 154]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 16
                },
                "end": {
                  "line": 15,
                  "column": 16
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/add.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "flash.addMessage", ["loc", [null, [12, 26], [12, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 20], [14, 27]]]]],
            locals: ["flash"],
            templates: [child0]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 24
                },
                "end": {
                  "line": 31,
                  "column": 94
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "help-block error");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["content", "error.name", ["loc", [null, [31, 73], [31, 87]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 24
                },
                "end": {
                  "line": 36,
                  "column": 108
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "help-block error");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["content", "error.description", ["loc", [null, [36, 80], [36, 101]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 15
                },
                "end": {
                  "line": 46,
                  "column": 94
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/add.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["global.label.cancel"], [], ["loc", [null, [46, 67], [46, 94]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 4
              },
              "end": {
                "line": 52,
                "column": 0
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/add.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "add-collection");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "container");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col-md-offset-2 col-md-8");
            dom.setAttribute(el4, "id", "flashMessage");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col-md-8 col-md-offset-2 text-center");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("h3");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("br");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col-md-8 col-md-offset-2");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("form");
            dom.setAttribute(el5, "role", "form");
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6, "class", "form-group");
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("label");
            dom.setAttribute(el7, "for", "collectionTitle");
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n\n                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("br");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("label");
            dom.setAttribute(el7, "for", "collectionTitle");
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                        ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                    ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col-md-8 col-md-offset-2 text-left");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("button");
            dom.setAttribute(el5, "type", "submit");
            dom.setAttribute(el5, "class", "btn btn-primary btn-big");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n               ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("br");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("br");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 1]);
            var element5 = dom.childAt(element4, [5, 1, 1, 1]);
            var element6 = dom.childAt(element4, [7, 1]);
            var element7 = dom.childAt(element6, [1]);
            var morphs = new Array(12);
            morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 1, 1]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
            morphs[3] = dom.createMorphAt(element5, 3, 3);
            morphs[4] = dom.createMorphAt(element5, 5, 5);
            morphs[5] = dom.createMorphAt(dom.childAt(element5, [9]), 0, 0);
            morphs[6] = dom.createMorphAt(element5, 11, 11);
            morphs[7] = dom.createMorphAt(element5, 13, 13);
            morphs[8] = dom.createAttrMorph(element7, 'disabled');
            morphs[9] = dom.createElementMorph(element7);
            morphs[10] = dom.createMorphAt(element7, 0, 0);
            morphs[11] = dom.createMorphAt(element6, 3, 3);
            return morphs;
          },
          statements: [["block", "each", [["get", "flashMessages.queue", ["loc", [null, [11, 24], [11, 43]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 16], [15, 25]]]], ["inline", "t", ["Collection.Add_Collection"], [], ["loc", [null, [20, 20], [20, 53]]], 0, 0], ["inline", "t", ["Collection.Add_Collection_Title"], [], ["loc", [null, [29, 53], [29, 92]]], 0, 0], ["inline", "input", [], ["id", "collectionName", "type", "text", "value", ["subexpr", "@mut", [["get", "model.collection.name", ["loc", [null, [30, 70], [30, 91]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "placeholder", "Album title"], ["loc", [null, [30, 24], [30, 141]]], 0, 0], ["block", "if", [["get", "error.name", ["loc", [null, [31, 30], [31, 40]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [31, 24], [31, 101]]]], ["inline", "t", ["Collection.Add_Collection_Description"], [], ["loc", [null, [34, 53], [34, 98]]], 0, 0], ["inline", "input", [], ["id", "collectionDescription", "type", "text", "value", ["subexpr", "@mut", [["get", "model.collection.description", ["loc", [null, [35, 77], [35, 105]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "placeholder", "Album description"], ["loc", [null, [35, 24], [35, 160]]], 0, 0], ["block", "if", [["get", "error.description", ["loc", [null, [36, 30], [36, 47]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [36, 24], [36, 115]]]], ["attribute", "disabled", ["get", "submitting", ["loc", [null, [45, 53], [45, 63]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["save"], [], ["loc", [null, [45, 24], [45, 41]]], 0, 0], ["content", "saveButtonLabel", ["loc", [null, [45, 113], [45, 132]]], 0, 0, 0, 0], ["block", "link-to", ["main.collections.list", ["get", "model.journalId", ["loc", [null, [46, 50], [46, 65]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [46, 15], [46, 106]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 0
              },
              "end": {
                "line": 54,
                "column": 0
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/add.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "text-center");
            dom.setAttribute(el1, "class", "no-access-message");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["Collection.No_Access_Message"], [], ["loc", [null, [53, 53], [53, 89]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 56,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/collections/add.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "canShare", ["loc", [null, [6, 10], [6, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [6, 4], [54, 7]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 0
            },
            "end": {
              "line": 63,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/collections/add.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          dom.setAttribute(el1, "style", "margin-top:30px;");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-offset-2 col-md-8 text-center");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          dom.setAttribute(el3, "class", "btn btn-primary btn-primary-reverse-outline btn-big");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["Collection.Upgrade_Message"], [], ["loc", [null, [59, 15], [59, 49]]], 0, 0], ["element", "action", ["upgrade", ["get", "model.journalId", ["loc", [null, [60, 43], [60, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [60, 24], [60, 60]]], 0, 0], ["inline", "t", ["Collection.Upgrade_Button_Label"], [], ["loc", [null, [60, 121], [60, 160]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 64,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/collections/add.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n    .navbar-photo-collections { font-weight:400;}\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "collectionEnabled", ["loc", [null, [5, 6], [5, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [5, 0], [63, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});