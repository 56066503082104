define('tinybeans-frontend/components/edit-pet-info-onboarding', ['exports', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component$exte;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend(_commonsMixin.default, (_Ember$Component$exte = {
    submitting: false,
    queryParams: ['childAdded'],
    measurementSystem: '',
    size: [],

    formattedBirthday: Ember.computed('model.pet.birthday', {
      get: function get() {
        return moment(this.get('model.pet.birthday'), 'YYYY-MM-DD').format(this.get('usersDateFormat'));
      },
      set: function set(key, value) {
        var formatted = moment(value, this.get('usersDateFormat')).format('YYYY-MM-DD');
        this.set('model.pet.birthday', formatted);
        return value;
      }
    }),

    formattedAdoption: Ember.computed('model.pet.adoptionDate', {
      get: function get() {
        return moment(this.get('model.pet.adoptionDate'), 'YYYY-MM-DD').format(this.get('usersDateFormat'));
      },
      set: function set(key, value) {
        var formatted = moment(value, this.get('usersDateFormat')).format('YYYY-MM-DD');
        this.set('model.pet.adoptionDate', formatted);
        return value;
      }
    }),

    saveButtonLabel: Ember.computed('submitting', function () {
      var submitting = this.get('submitting');
      return submitting ? this.t('global.label.wait') : this.t('global.label.save');
    })

  }, _defineProperty(_Ember$Component$exte, 'size', Ember.computed('userMeasurementSystem', function () {
    var measurementSystem = this.get('userMeasurementSystem.name');
    this.set('measurementSystem', measurementSystem);

    if (this.get('model.pet.species') === 'DOG') {
      if (measurementSystem === 'METRIC') {
        this.set('size', ["Small (0-9 kg)", "Medium (9-23 kg)", "Large ( 23 kg+)"]);
      } else {
        this.set('size', ["Small (0-20 lbs)", "Medium (20-50 lbs)", "Large (50+ lbs)"]);
      }
    } else {
      if (measurementSystem === 'METRIC') {
        this.set('size', ["Small (0-4 kg)", "Medium (4-6 kg)", "Large ( 6 kg+)"]);
      } else {
        this.set('size', ["Small (0-8 lbs)", "Medium (9-13 lbs)", "Large (14+ lbs)"]);
      }
    }
    return this.get('size');
  })), _defineProperty(_Ember$Component$exte, 'sex', Ember.computed('model.journal.user.id', function () {
    var sex = ["Male", "Female", "Unsure"];
    return sex;
  })), _defineProperty(_Ember$Component$exte, 'userID', Ember.computed('model.journal.user.id', function () {
    var userID = this.get('model.journal.user.id');
    return userID;
  })), _defineProperty(_Ember$Component$exte, 'actions', {
    toggleType: function toggleType(value) {
      this.get('model.pet').set('species', value.toUpperCase());
      if (value === "Other") {
        this.set('isOther', true);
      } else {
        this.set('isOther', false);
      }

      if (value === 'Dog') {
        if (this.get('measurementSystem') === 'METRIC') {
          this.set('size', ["Small (0-9 kg)", "Medium (9-23 kg)", "Large ( 23 kg+)"]);
        } else {
          this.set('size', ["Small (0-20 lbs)", "Medium (20-50 lbs)", "Large (50+ lbs)"]);
        }
      } else {
        if (this.get('measurementSystem') === 'METRIC') {
          this.set('size', ["Small (0-4 kg)", "Medium (4-6 kg)", "Large ( 6 kg+)"]);
        } else {
          this.set('size', ["Small (0-8 lbs)", "Medium (9-13 lbs)", "Large (14+ lbs)"]);
        }
      }

      this.get('model.pet').set('breed', "");
      this.get('model.pet').set('size', "");
      this.get('model.pet').set('sex', "");
      this.get('model.pet').set('birthday', "");
      this.get('model.pet').set('adoptionDate', "");
    },
    selectSize: function selectSize(value) {

      if (value.includes('Small')) {
        value = 'Small';
      }

      if (value.includes('Medium')) {
        value = 'Medium';
      }

      if (value.includes('Large')) {
        value = 'Large';
      }
      this.get('model.pet').set('size', value.toUpperCase());
    },
    selectSex: function selectSex(value) {
      this.get('model.pet').set('sex', value.toUpperCase());
    },
    deleteConfirm: function deleteConfirm() {
      var self = this;
      bootbox.dialog({
        title: this.t('Children.Delete_Confirm_Dialog.Remove_Child'),
        message: this.t('Children.Delete_Confirm_Dialog.Remove_Child_Text'),
        buttons: {
          ok: {
            label: 'OK',
            callback: function callback() {
              self.get('model.child').destroyRecord().then(function () {
                self.sendAction('doTransition', 'main.children.list', self.get('model.journal.id'));
              });
            }
          },
          cancel: {
            label: this.t('Children.Delete_Confirm_Dialog.Cancel'),
            className: "btn-cancel"
          }
        }
      });
    },
    cancel: function cancel() {
      var journalId = this.get('model.journal.id');
      this.sendAction('doTransition', 'main.onboarding.step-three', journalId);

      this.GTM.trackEvent({
        'eventCategory': 'onboarding-child-first',
        'eventAction': 'step-two-click',
        'eventLabel': 'skip-add-pet'
      });
      if (this.onSkip) {
        this.onSkip();
      }
    },
    save: function save(addMore) {
      var _this = this;

      this.GTM.trackEvent({
        'eventCategory': 'onboarding-pet-second',
        'eventAction': 'step-two-click',
        'eventLabel': 'add-pet'
      });

      this.set('submitting', true);
      var file = Ember.$('input[type=file]')[0].files[0];
      var journalId = this.get('model.journal.id');
      var error = void 0;
      var errorMessage = 'Sorry, this is required';

      if (this.get('model.pet.species') === 'OTHER') {
        this.get('model.pet').set('sex', 'UNSURE');
        this.get('model.pet').set('size', 'SMALL');
      }

      if (!this.get('model.pet.name')) {
        error = {};
        error.name = errorMessage;
      }

      if (!this.get('model.pet.species')) {
        this.get('model.pet').set('species', 'DOG');
      }

      if (!this.get('model.pet.sex')) {
        this.get('model.pet').set('sex', 'UNSURE');
      }

      if (!this.get('model.pet.size')) {
        this.get('model.pet').set('size', null);
      }

      if (!this.get('model.pet.birthday') || this.get('model.pet.birthday') === 'Invalid date') {
        error = error || {};
        error.birthday = errorMessage;
      }

      if (!this.get('model.pet.adoptionDate')) {
        if (moment(this.get('model.pet.adoptionDate')).isBefore(this.get('model.pet.birthday'))) {
          errorMessage = 'Adoption day cannot be prior to Birthday';
          error = error || {};
          error.adoptDate = errorMessage;
        }
      }

      if (error) {
        this.set('error', error);
        this.set('submitting', false);
        return;
      }

      var d = new Date();
      var month = d.getMonth() + 1;
      var year = d.getFullYear();

      this.get('model.pet').save({
        adapterOptions: {
          file: file,
          journalId: journalId
        }
      }).then(function (response) {
        console.log(response);
        //this.sendAction('displayNotification', `Pet <b>${response.get('name')}</b> was added successfully!`);
        if (_this.get('setup')) {
          if (addMore) {
            _this.sendAction('reloadPage');
          } else {
            _this.sendAction('doTransition', 'main.journals.month', journalId, year, month, {
              queryParams: {
                setup: _this.get('setup') || false
              }
            });
          }
        } else {
          _this.sendAction('doTransition', 'main.onboarding.step-three', journalId);
        }
        _this.set('submitting', false);
        if (_this.onSave) {
          _this.onSave();
        }
      }, function () {
        // this.onApiError(errors);
        _this.set('submitting', false);
      });
    }
  }), _Ember$Component$exte));
});