define("tinybeans-frontend/routes/logout", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function deleteAllCookies() {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }

  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),

    beforeModel: function beforeModel() {
      var _this = this;

      deleteAllCookies();
      if (this.get('session.isAuthenticated')) {
        this.get('session').invalidate().then(function () {
          _this.store.unloadAll();
          _this.transitionTo('/');
        });
      } else {
        this.transitionTo('/');
      }
    }
  });
});