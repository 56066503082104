define('tinybeans-frontend/routes/main/account/confirm-details', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Signup.category,
    screen: _tracker.Signup.confirmDetails.screen,

    model: function model() {
      return Ember.RSVP.hash({
        user: this.store.find('user', 'me'),
        followings: this.modelFor('main').followings,
        journals: this.modelFor('main').journals
      });
    },


    setupController: function setupController(controller, model) {

      this._super(controller, model);
      var user = model.user;
      controller.set('user', user);

      //set default first journal as the current journal
      var journal = model.journals[0] ? model.journals[0] : null;
      if (journal) {
        this.controllerFor("main").set('currentJournal', journal);
        // controller.get('controllers.main').set('currentJournal', journal);
      }
    }
  });
});