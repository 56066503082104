define("tinybeans-frontend/initializers/ember-i18n", ["exports", "tinybeans-frontend/instance-initializers/ember-i18n"], function (exports, _emberI18n) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: _emberI18n.default.name,

    initialize: function initialize() {
      var application = arguments[1] || arguments[0]; // depending on Ember version
      if (application.instanceInitializer) {
        return;
      }

      _emberI18n.default.initialize(application);
    }
  };
});