define('tinybeans-frontend/serializers/subscription', ['exports', 'tinybeans-frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      journal: { embedded: 'always' },
      paymentTransaction: { embedded: 'always' }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      delete payload.status; // Status messes up the JSON parser

      if ((requestType === 'query' || requestType === 'findAll') && !payload.subscriptions) {
        payload.subscriptions = [];
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});