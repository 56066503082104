define('tinybeans-frontend/helpers/storage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Storage = exports.Storage = function () {
    function Storage(baseKey) {
      _classCallCheck(this, Storage);

      this.baseKey = baseKey;
    }

    _createClass(Storage, [{
      key: 'byUser',
      value: function byUser(userId) {
        if (typeof userId === 'number') {
          userId = userId.toString();
        }

        if (typeof userId !== 'string' || userId.length === 0) {
          throw new Error('Invalid user id');
        }

        return new UserStorage(this.baseKey, userId);
      }
    }]);

    return Storage;
  }();

  var UserStorage = exports.UserStorage = function () {
    function UserStorage(baseKey, userId) {
      _classCallCheck(this, UserStorage);

      this.key = 'user:' + userId + ':' + baseKey;
    }

    _createClass(UserStorage, [{
      key: 'exists',
      value: function exists() {
        return localStorage.getItem(this.key) !== null;
      }
    }, {
      key: 'get',
      value: function get() {
        return JSON.parse(localStorage.getItem(this.key));
      }
    }, {
      key: 'set',
      value: function set(value) {
        return localStorage.setItem(this.key, JSON.stringify(value));
      }
    }]);

    return UserStorage;
  }();
});