define('tinybeans-frontend/adapters/follower', ['exports', 'tinybeans-frontend/adapters/application', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _application, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_dataAdapterMixin.default, {
    // Query means get followers for a journal (use query on journal instead?
    query: function query(store, type, _query) {
      var url = "/api/1/journals/" + _query.filter.journalId + "/followers";
      return this.ajax(url, 'GET', {});
    },
    findAll: function findAll() {
      var url = "/api/1/followings";
      return this.ajax(url, 'GET', {});
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var url = '/api/1/journals/' + snapshot.adapterOptions.journalId + '/followers/' + snapshot.id;
      return this.ajax(url, 'Delete', {});
    }

    // updateRecord(store, type, snapshot) {
    //   let serlialized = this.serialize(snapshot);
    //   var journalId = parseInt(snapshot.adapterOptions.journalId);
    //   var url = `/api/1/journals/${journalId}/followers/${ snapshot.id}`;
    //   var data = {
    //     addEntries: serlialized.addEntries,
    //     viewMilestones: serlialized.viewMilestones,
    //     editMilestones: serlialized.editMilestones,
    //     coOwner: serlialized.coOwner,
    //     relationship: serlialized.relationship ? serlialized.relationship.name : null
    //   };
    //   return new Ember.RSVP.Promise((resolve, reject) => {
    //     return this.get('REST').POST('/api/1/journals/' + journalId + '/followers/' + snapshot.id, data, resolve, reject);
    //   });
    //
    //
    //   // return new Ember.RSVP.Promise((resolve, reject) => {
    //   // return $.ajax({url: url, type: 'post', data: JSON.stringify(data), dataType: 'json'});
    //   // });
    // }

  });
});