define("tinybeans-frontend/routes/main/entries/view", ["exports", "tinybeans-frontend/routes/base-route", "tinybeans-frontend/tracker"], function (exports, _baseRoute, _tracker) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _baseRoute.default.extend({
    category: _tracker.Moments.category,
    screen: _tracker.Moments.entry.screen,

    session: Ember.inject.service(),

    model: function model(params, transition) {
      var entryId = params.entry_id;
      var uuid = params.uuid;

      var authenticated = this.get("session.isAuthenticated");
      var userToken = params.userToken;

      var currentJournalId = this.controllerFor("main").currentJournal.id;

      return Ember.RSVP.hashSettled({
        me: authenticated ? this.store.find("user", "me") : userToken ? this.store.queryRecord("user", {
          entryId: entryId,
          userToken: userToken
        }) : null,
        followings: authenticated ? this.modelFor("main").followings : null,
        entry: this.store.findRecord("entry", entryId, {
          adapterOptions: {
            uuid: uuid
          }
        }),
        segmentName: "",
        tips: this.API.getTips(),
        showLovePopup: false,
        randomEntry: this.API.getRandomEntry(entryId, uuid, function () {}, function () {}),
        singleEntryView: true,
        journals: authenticated ? this.modelFor("main").journals : null,
        ads: this.API.getAdsStatus(this.store.findRecord("entry", entryId, {
          adapterOptions: {
            uuid: uuid
          }
        }).get('journal.id'))
      }).then(function (result) {
        return {
          authenticated: authenticated,
          showLovePopup: false,
          me: result.me.value,
          followings: result.followings.value,
          entry: result.entry.value,
          tips: result.tips.value,
          randomEntry: result.randomEntry.value,
          singleEntryView: true,
          journals: result.journals.value,
          ads: result.ads,
          queryParams: _extends({}, transition.queryParams),
          entry_id: entryId,
          uuid: uuid
        };
      });
    },
    afterModel: function afterModel(model) {
      model.day = model.entry.get("day");
      model.month = model.entry.get("month");
      model.year = model.entry.get("year");
      model.journal = model.entry.get("journal");
      model.journalId = model.entry.get("journal.id");

      var mainController = this.controllerFor("main");
      model.currentJournalId = mainController.currentJournal.id;

      if (!model.authenticated && model.me) {
        var user = model.me;
        if (!this.get("globals.lpUserSet")) {
          this.set("globals.lpUserSet", true);
          var userJson = {
            id: user.get("id"),
            firstName: user.get("firstName"),
            lastName: user.get("lastName"),
            emailAddress: user.get("emailAddress")
          };
          _tracker.Tracker.setUser(userJson);
        }
      }
    },


    setupController: function setupController(controller, model, error) {
      Ember.Logger.debug(error.message);
      this._super(controller, model);

      var viewOnly = true;
      controller.set("viewOnly", viewOnly);

      var entry = model.entry;

      controller.set("signupUrl", "/app/#/signup?_src=entry_" + entry.id);

      controller.set("showLovePopup", model.showLovePopup);

      controller.set("showSecondAds", true);

      controller.set("journalUserFirstName", entry.get("journal.user.firstName") ? entry.get("journal.user.firstName") + " is " : "Your friends and families are ");

      // user login status
      controller.set("authenticated", model.authenticated);

      controller.set("singleEntryView", true);

      controller.set("basicCommenter", "");

      // entry mode
      controller.set("entryMode", true);

      if (controller.get("error")) {
        controller.set("error.global", "");
      }

      // pass should show ads logic to main controller
      var journal = model.entry.get("journal");
      var mainController = this.controllerFor("main");

      mainController.set("entryJournalName", model.entry.get("journal.title"));

      var currentPageShouldShowAds = journal.get("shouldShowAds");
      mainController.set("shouldShowAds", currentPageShouldShowAds);

      if (!model.authenticated) {
        mainController.set("isMobile", this.globals.isMobile());
      }
    },

    actions: {
      error: function error(_error, transition) {
        console.log(_error);
        Sentry.captureException(_error, {
          tags: {
            section: "Entries-Routes"
          }
        });
        this.replaceWith('/not-found');
      },


      didTransition: function didTransition() {
        this._super();

        // sticky ads
        Ember.run.next(function () {

          var isDesktop = Ember.$(window).innerWidth() > 767;
          if (isDesktop) {
            setTimeout(function () {
              var stickyAdsContainer = document.querySelector('.sticky-ads-container-outer');
              var momentContainer = document.querySelector('#moments');
              if (stickyAdsContainer && momentContainer) {
                if (stickyAdsContainer.clientHeight < momentContainer.clientHeight) {
                  stickyAdsContainer.style.height = momentContainer.clientHeight + "px";
                }
              }
            }, 3000);
          }
        });
        // sticky ads
      }
    }
  });
});