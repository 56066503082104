define('tinybeans-frontend/routes/main/billing/show', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash'], function (exports, _baseProtectedRoute, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model() {
      var journals = this.modelFor('main').journals;
      return Ember.RSVP.hash({
        journals: this.store.findAll('journal'),
        subscriptions: this.getSubscriptions(journals),
        subscriptionProducts: this.API.getSubscriptionProducts(),
        storeCustomer: this.API.getStoreCustomer(),
        activeSubscrition: this.API.getActiveSubscription(),
        paymentTransactions: this.store.findAll('payment-transaction'), // although this one calls /paymentTransactions, findAll returns everything including the ones serialized in subscriptions! Disgusting! :@
        me: this.store.find('user', 'me')
      });
    },


    afterModel: function afterModel(model) {

      if (model.storeCustomer) {
        model.customer = model.storeCustomer.customer;
        if (model.customer) {
          model.card = model.customer.card;
        }
      }

      model.paymentTransactions = model.paymentTransactions.filter(function (t) {
        return t.get('user.id') === model.me.get('id');
      });

      if (model.journals) {
        model.journal = model.journals.toArray()[0];
      }
    },

    setupController: function setupController(controller, model, error) {
      Ember.Logger.debug(error.message);
      this._super(controller, model);
      if (model.activeSubscrition.subscription) {
        controller.set('isSubscribedUser', true);
      } else {
        controller.set('isSubscribedUser', false);
      }
    },

    getSubscriptions: function getSubscriptions(journals) {
      var _this = this;

      var promises = {};
      _lodash.default.each(journals.toArray(), function (journal) {
        promises[journal.get('id')] = _this.store.query('subscription', {
          journalId: journal.get('id')
        });
      });
      return Ember.RSVP.hash(promises);
    },


    actions: {
      error: function error(_error, transition) {
        console.log(_error);
        Sentry.captureException(_error, {
          tags: {
            section: "Billing-Routes"
          }
        });
        this.replaceWith('/not-found');
      },
      reloadApplicationRoute: function reloadApplicationRoute() {
        // TODO
      },
      reloadPage: function reloadPage() {
        this.refresh();
      }
    },

    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.setProperties({
          renew: undefined,
          subscriptionId: undefined
        });
      }
    }

  });
});