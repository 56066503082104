define('tinybeans-frontend/serializers/checklist', ['exports', 'tinybeans-frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      checklistItems: { embedded: 'always' }
    }
  });
});