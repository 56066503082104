define('tinybeans-frontend/controllers/main/photo-frame/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['campaign'],
    campaign: null
  });
});