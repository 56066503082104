define('tinybeans-frontend/models/emotion', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    URL: _emberData.default.attr('string'),
    userId: (0, _relationships.belongsTo)('user'),
    deleted: _emberData.default.attr('boolean'),
    type: _emberData.default.attr(),

    user: Ember.computed.alias('userId')
  });
});