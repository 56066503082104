define('tinybeans-frontend/routes/main/subscription-products/success', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash', 'tinybeans-frontend/tracker', 'tinybeans-frontend/config/environment'], function (exports, _baseProtectedRoute, _lodash, _tracker, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Premium.category,
    screen: _tracker.Premium.success.screen,
    journalId: null,

    model: function model() {
      var _this = this;

      var checkoutId = localStorage.getItem('sessionId');
      var priceId = localStorage.getItem('currentPriceId');

      return new RSVP.Promise(function (resolve) {
        Ember.run.later(function () {
          _this.API.submitOrSetupPayment(checkoutId, priceId, function (data) {
            resolve({
              premiumSubscription: data.subscriptionData
            });
          });
        }, 1500);
      });
    },
    afterModel: function afterModel(model) {
      var eventLabel = this.globals.isIOS ? "ios" : this.globals.isAndroid ? "android" : "web";
      if (model.premiumSubscription.subscriptionStatus !== "UPCOMING") {
        // emit paid conversion for CJ
        var cj_args = {
          'event': 'cj_success_page',
          'eventCategory': 'premium_complete_page',
          'eventAction': 'click_creditcard_done',
          'eventLabel': eventLabel,
          'orderSubtotal': model.premiumSubscription.plan.amount,
          'orderCurrency': model.premiumSubscription.paymentDetails.currency,
          'orderID': window.crypto.randomUUID()
        };
        if (null !== model.premiumSubscription.checkoutSession.latestInvoice.discount) {
          cj_args.orderCoupon = model.premiumSubscription.checkoutSession.latestInvoice.discount.coupon.name;
          cj_args.wholeOrderDiscount = parseFloat(model.premiumSubscription.checkoutSession.latestInvoice.totalDiscountAmounts[0].amount / 100);
        };
        this.GTM.trackEvent(cj_args);
        if (_environment.default.enable_qa_output) {
          console.log("%cCJ Testing Output: Message sent with following data >>", "color: white; background-color: #fcb900;", cj_args);
        }
      }
    },
    setupController: function setupController(controller, model) {
      controller.set("email", model.premiumSubscription.checkoutSession.customerDetails.email);
      controller.set("planName", model.premiumSubscription.plan.nickname);
      controller.set("startDate", moment.unix(model.premiumSubscription.startDate / 1000).format("MM/DD/YYYY"));
      controller.set("expiryDate", moment.unix(model.premiumSubscription.expiryDate / 1000).format("MM/DD/YYYY"));
      controller.set("isTrial", model.premiumSubscription.trialSubscription);
      controller.set("isStacked", model.premiumSubscription.subscriptionStatus === "UPCOMING");
      controller.set("price", _lodash.default.round(model.premiumSubscription.finalPrice, 2));
    }
  });
});