define('tinybeans-frontend/components/error-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    error: Ember.computed('model.errors', function () {
      var errors = this.get('model.errors');
      return errors ? errors.objectAt(0) : undefined;
    }),

    journalsClass: Ember.computed('model.journals', function () {
      var journalCount = this.get('model.journals.length');
      switch (journalCount) {
        case 1:
          return "row journals col-xs-offset-3 col-sm-offset-4 col-md-offset-4 col-lg-offset-4 col-xs-6 col-sm-4 col-md-4 col-lg-4";
        case 2:
          return "row journals col-sm-offset-1 col-md-offset-2 col-lg-offset-3 col-sm-10 col-md-8 col-lg-6";
        default:
          return "row journals col-sm-offset-1 col-md-offset-1 col-lg-offset-2 col-sm-10 col-md-10 col-lg-10";
      }
    }),

    journalClass: Ember.computed('model.journals', function () {
      var journalCount = this.get('model.journals.length');
      switch (journalCount) {
        case 1:
          return "jc";
        case 2:
          return "jc col-xs-6 col-sm-6 col-md-6 col-lg-6";
        default:
          return "jc col-xs-6 col-sm-4 col-md-4 col-lg-3";
      }
    }),

    message: Ember.computed('error', function () {
      var resources = ['tag', 'collection', 'journal', 'follower', 'child', 'entry'];
      var error = this.get('error');
      if (error) {
        for (var i = 0; i < resources.length; i++) {
          var resource = resources[i];
          var regex = new RegExp(resource, "i");
          if (error.detailedMessage && error.detailedMessage.match(regex)) {
            switch (error.statusCode) {
              case 404:
                return "Page not found, are you sure you are logged in to the right account?";
              // return `Couldn't find ${resource}, maybe it doesn't exist, it was deleted from our system, or you don't have access to it.`;
              case 403:
                return 'Are you sure you are permitted access to this ' + resource + '?';
              default:
                return 'There was a problem accessing this ' + resource + '?';
            }
          }
        }
      }
      return 'Please make sure you typed the url correctly and the information you entered is valid!';
    }),

    body: Ember.computed('error.errorId', function () {
      var error = this.get('error');
      return error && error.errorId ? encodeURIComponent('\n\nError code: ' + error.errorId) : "";
    }),

    actions: {
      switchJournal: function switchJournal(journal) {
        this.sendAction('switchJournal', journal);
      }
    }
  });
});