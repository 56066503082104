define("tinybeans-frontend/components/tagged-children-dropdown", ["exports", "tinybeans-frontend/utils/bootstrap-dropdown"], function (exports, _bootstrapDropdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["child-dropdown-container"],
    editing: false,

    taggedCount: Ember.computed('entry.children.length', 'entry.pets.length', function () {
      return this.get('entry.children.length') + this.get('entry.pets.length');
    }),

    babyButtonClasses: Ember.computed('taggedCount', function () {
      var taggedCount = this.get('taggedCount');
      return taggedCount === 0 ? 'hidden' : '';
    }),

    getDropdown: function getDropdown() {
      var entryID = this.get("entry.id");
      return this.$("#entry-tagged-dropdown-" + entryID);
    },
    didInsertElement: function didInsertElement() {
      var ul = this.$("ul");
      var dropdown = this.getDropdown();
      Ember.$("body").on("click", function (e) {
        if (!ul.is(e.target) && ul.has(e.target).length === 0 && (0, _bootstrapDropdown.isOpenDropdown)(dropdown)) {
          (0, _bootstrapDropdown.closeDropdown)(dropdown);
          this.set('editing', false);
        }
      }.bind(this));
    },


    actions: {
      updateEntry: function updateEntry(entry) {
        this.sendAction('updateEntry', entry);
      },
      hoverTaggedChildren: function hoverTaggedChildren(entryID) {
        var dropdownID = "#entry-tagged-dropdown-" + entryID;
        setTimeout(function () {
          (0, _bootstrapDropdown.openDropdown)(dropdownID);
        });
      },
      unhoverTaggedChildren: function unhoverTaggedChildren(entryID) {
        if (!this.get("editing")) {
          var dropdownID = "#entry-tagged-dropdown-" + entryID;
          setTimeout(function () {
            (0, _bootstrapDropdown.closeDropdown)(dropdownID);
          });
        }
      }
    }
  });
});