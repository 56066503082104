define('tinybeans-frontend/routes/main/collections/edit', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.PhotoCollections.category,
    screen: _tracker.PhotoCollections.momentPicker.screen,

    error: {},
    model: function model(params) {
      var journalId = params.journal_id;
      var path = params.path;
      var year = params.year;
      var month = params.month;
      return Ember.RSVP.hash({
        journalId: journalId,
        year: year,
        month: month,
        path: path,
        me: this.store.find('user', 'me'),
        collection: this.store.queryRecord('collection', {
          journalId: journalId,
          path: path
        }),
        journal: this.store.find('journal', journalId),
        followers: this.store.findAll('follower'),
        followings: this.modelFor('main').followings,
        relations: this.API.getFollowerRelations(),
        children: this.store.findAll('child'),
        entries: this.store.query('entry', {
          filter: {
            journalId: journalId,
            year: year,
            month: month
          }
        })
      });
    },

    doControllerSetup: function doControllerSetup(controller, model) {
      var timeLineMonths = [{
        month: 1,
        name: 'Jan'
      }, {
        month: 2,
        name: 'Feb'
      }, {
        month: 3,
        name: 'Mar'
      }, {
        month: 4,
        name: 'Apr'
      }, {
        month: 5,
        name: 'May'
      }, {
        month: 6,
        name: 'Jun'
      }, {
        month: 7,
        name: 'Jul'
      }, {
        month: 8,
        name: 'Aug'
      }, {
        month: 9,
        name: 'Sep'
      }, {
        month: 10,
        name: 'Oct'
      }, {
        month: 11,
        name: 'Nov'
      }, {
        month: 12,
        name: 'Dec'
      }];
      var selectedIds = Ember.$('#selectedEntryId').text();
      var year = model.year;
      var month = model.month;

      controller.set('prevMonth', this.prevMonth(month, year));
      controller.set('prevYear', this.prevYear(month, year));
      controller.set('nextMonth', this.nextMonth(month, year));
      controller.set('nextYear', this.nextYear(month, year));
      controller.set('yearMinus', this.yearMinus(year));
      controller.set('yearPlus', this.yearPlus(year));
      controller.set('calendarYear', year);
      controller.set('timelineMonths', timeLineMonths);
      controller.set('monthString', this.monthString(month));
      var name = model.collection.get('name');
      model.collection.set('name', '' + (name.length > 35 ? name.substring(0, 35) + '...' : name));

      var xEntries = [];
      Ember.$.each(model.entries.toArray(), function (idx, entry) {
        var entryId = "#" + entry.id;

        entry.hiddenMode = entry.privateMode;

        entry.initialSelectedClass = "";
        entry.initialIconSelectedClass = "glyphicon pre-select";

        if (selectedIds.indexOf(entryId) != -1) {
          entry.initialSelectedClass = "selected";
          entry.initialIconSelectedClass = "glyphicon pre-select glyphicon-ok";
        }

        if (!entry.deleted) {
          xEntries.push(entry);
        }
      });

      controller.set('entries', xEntries);

      var selectedIdsArray = selectedIds.split("#");
      controller.set('numSelectedMoments', selectedIdsArray.length - 1);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.doControllerSetup(controller, model);
    },

    prevMonth: function prevMonth(month, year) {
      var date = new Date(year, month - 2, 1);
      return date.getMonth() + 1;
    },

    prevYear: function prevYear(month, year) {
      var date = new Date(year, month - 2, 1);
      return date.getFullYear();
    },

    nextMonth: function nextMonth(month, year) {
      var date = new Date(year, month, 1);
      return date.getMonth() + 1;
    },

    nextYear: function nextYear(month, year) {
      var date = new Date(year, month, 1);
      return date.getFullYear();
    },

    yearMinus: function yearMinus(year) {
      var date = new Date(year, 1, 1);
      return date.getFullYear() - 1;
    },

    yearPlus: function yearPlus(year) {
      var date = new Date(year, 1, 1);
      return date.getFullYear() + 1;
    },

    monthString: function monthString(month) {
      var selectedMonth = month - 1;
      var monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return monthShortNames[selectedMonth];
    }

  });
});