define('tinybeans-frontend/controllers/main/subscription/cancel', ['exports', 'tinybeans-frontend/controllers/base-controller', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseController, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({

    actions: {
      inviteFollower: function inviteFollower() {
        var self = this;
        this.transitionToRoute('main.journals.follower-list-invite', self.model.journalId);
      },
      onSelectEntityType: function onSelectEntityType(value) {
        if (value !== "none") {
          this.set('selectedPerson', value);
          this.set('awaitingSeat', false);
        } else {
          this.set('awaitingSeat', true);
        }
      },
      cancelContinue: function cancelContinue() {
        var self = this;
        Ember.$(".cancelSubModal").modal("show");
      },
      cancelSub: function cancelSub() {
        this.set('isCancelled', true);
        this.API.billingSession().then(function (res) {
          window.location.replace(res.portalURL);
        });
      },
      sendOffer: function sendOffer() {
        // Leanplum event emit
        _tracker.Tracker.trackEvent({
          category: "cancellation",
          screen: "screen",
          action: 'tinybeansplus cancellation offer'
        });
        //Show the thanks modal
        Ember.$(".cancelSubModal").modal("hide");
        Ember.$(".cancelStayModal").modal("show");
      },
      closeCancel: function closeCancel() {
        Ember.$(".cancelStayModal").modal("hide");
        this.transitionToRoute('main.billing.show');
      },
      setSubscriptionSeat: function setSubscriptionSeat() {
        var followerId = this.get('selectedPerson');
        var seated = this.API.setSubscriptionSeat(followerId);
        var self = this;
        seated.then(function () {
          if (followerId) {
            // this.send('displayNotification', `Your subscription was successfully shared.`);
            self.set('hasSeatedFollower', true);
            self.set('hasSeatsRemaining', false); // Needs to change if we ever have more than one seat
            self.model.set('deletingSeat', false);
            self.route.refresh();
          } else {
            // console.log("No followerid");
          }
        });
      }
    },
    getSeatsInfo: function getSeatsInfo(followers, me) {
      var assigned = false;
      var holders = [];
      _lodash.default.each(followers.caregivers, function (follower) {
        if (parseInt(me) === parseInt(follower.seatGivenBy)) {
          holders.push(follower.user);
        }
      });
      // console.log(holders);
      return holders;
    }
  });
});