define('tinybeans-frontend/models/entry', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    URL: (0, _attr.default)('string'),
    deleted: (0, _attr.default)('boolean'),
    privateMode: (0, _attr.default)('boolean'),
    uuid: (0, _attr.default)('string'),
    clientRef: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    year: (0, _attr.default)('number'),
    month: (0, _attr.default)('number'),
    day: (0, _attr.default)('number'),
    latitude: (0, _attr.default)('number'),
    longitude: (0, _attr.default)('number'),
    locality: (0, _attr.default)('string'),
    region: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    sortOrder: (0, _attr.default)('number'),
    caption: (0, _attr.default)('string'),
    comments: (0, _relationships.hasMany)('comment'),
    emotions: (0, _relationships.hasMany)('emotion'),
    orientation: (0, _attr.default)('string'),
    timestamp: (0, _attr.default)('number'),
    lastUpdatedTimestamp: (0, _attr.default)('number'),
    pinnedTimestamp: (0, _attr.default)('number'),
    // measurementSystem: belongsTo('measurementSystem'),
    blobs: (0, _relationships.belongsTo)('blobs'),
    attachmentType: (0, _attr.default)('string'),
    attachmentUrl: (0, _attr.default)('string'),
    attachmentUrl_mp4: (0, _attr.default)('string'),
    attachmentUrl_webm: (0, _attr.default)('string'),
    attachmentEncoded: (0, _attr.default)('boolean'),
    attachmentEncodingFailed: (0, _attr.default)('boolean'),
    checklistItem: (0, _relationships.belongsTo)('checklistItem'),
    userId: (0, _relationships.belongsTo)('user'),
    user: (0, _attr.default)(), // for single entry
    journalId: (0, _relationships.belongsTo)('journal'),
    children: (0, _relationships.hasMany)('child'),
    pets: (0, _relationships.hasMany)('pet'),

    date: Ember.computed('yea', 'month', 'day', {
      get: function get() {
        return moment(new Date(this.get('year'), this.get('month'), this.get('day')));
      },
      set: function set(key, date) {
        this.set('year', date.year());
        this.set('month', date.month());
        this.set('day', date.date());
        return date;
      }
    }),

    showCaption: Ember.computed('caption', function () {
      return this.get('caption') !== '' && this.get('caption') !== 'Untitled';
    }),

    isText: Ember.computed('type', function () {
      return this.get('type') === 'TEXT';
    }),

    isPhoto: Ember.computed('type', function () {
      return this.get('type') === 'PHOTO' && !this.get('attachmentType');
    }),

    isVideo: Ember.computed('type', function () {
      return this.get('type') === 'PHOTO' && this.get('attachmentType') === 'VIDEO';
    }),

    isVideoProcessed: Ember.computed('isVideo', 'attachmentEncoded', function () {
      return this.get('isVideo') && this.get('attachmentEncoded');
    }),

    safelyFormattedCaption: Ember.computed('caption', function () {
      var safelyEscapedCaption = Ember.Handlebars.Utils.escapeExpression(this.get('caption'));
      var linebrokenCaption = safelyEscapedCaption.replace(/(?:\r\n|\r|\n)/g, '<br />');
      return new Ember.Handlebars.SafeString(linebrokenCaption);
    }),

    shortCaption: Ember.computed('safelyFormattedCaption', function () {
      return this.get('caption.length') < 14 ? this.get('caption') : this.get('caption').slice(0, 14) + "...";
    }),

    videoMessage: Ember.computed('attachmentEncoded', 'attachmentEncodingFailed', function () {
      return this.get('attachmentEncodingFailed') ? 'Oops, the video is unreadable' : 'Your video is still uploading. It may take a few minutes...';
    }),

    u: Ember.computed('userId', 'user', function () {
      return this.get('user') || this.get('userId'); // has to be user then userId for single entry page;
    }),

    journal: Ember.computed.alias('journalId')
  });
});