define('tinybeans-frontend/controllers/main/pets/milestones/checklist', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash'], function (exports, _baseController, _commonsMixin, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {

    currentChecklist: null,
    displayModal: false,

    isOtherPet: Ember.computed('model.petType', 'model.journal', 'model.pet', function () {
      if (this.get('model.petType') === 'Other') {
        localStorage.setItem("isOtherPet", false);
        window.location.href = '/app/#/main/pets/channel/' + this.get('model.journal.id') + '/' + this.get('model.pet.id');
        return false;
      } else {
        localStorage.setItem("isOtherPet", true);
        return true;
      }
    }),

    currentChecklistItems: Ember.computed('model.completedMilestones', 'mdoel', 'model.getPetSubscribedChannels', 'model.petMilestones', 'currentChecklist', 'currentChecklistItems.@each', function () {
      var _this = this;

      var currentChecklist = this.get('model.getPetSubscribedChannels')[0].channel.checklists[0];

      var currentChecklistItems = this.get('model.petMilestones');

      currentChecklistItems = _lodash.default.map(currentChecklistItems, function (item) {

        var ageGroup = void 0,
            ageRange = void 0;
        if (item.perc03 === 84) {
          ageGroup = '7+ yr';
          ageRange = '7+ yr';
        } else if (item.perc03 < 12) {
          ageGroup = item.perc03 + '-' + item.perc97 + '-months';
          ageRange = item.perc03 + '-' + item.perc97 + ' MONTHS';
        } else {
          ageGroup = Number(item.perc03) / 12 + '-' + Number(item.perc97) / 12 + '-years';
          ageRange = Number(item.perc03) / 12 + ' - ' + Number(item.perc97) / 12 + ' YEARS';
        }

        item['ageRangeClass'] = 'row category-title ageRangeClass-' + (currentChecklist.showDateRange ? ageGroup : 999);

        item['ageRange'] = ageRange;

        item['completed'] = false;

        item['completionDate'] = null;

        if (_this.get('model.petCompletedChecklistItems')) {
          _lodash.default.each(_this.get('model.petCompletedChecklistItems').toArray(), function (completedItem) {
            if (completedItem.checklistItem.id == item.id && !completedItem.deleted) {
              item.completed = true;
              var year = Number(moment(completedItem.completedDate).format('YYYY'));
              var month = Number(moment(completedItem.completedDate).format('M'));
              var day = Number(moment(completedItem.completedDate).format('D'));
              if (year === 0 || month === 0 || day === 0) {
                item['completionDate'] = 'Completed date unsure';
              } else {
                var completionDate = new Date(year, month - 1, day);
                item['completionDate'] = 'Completed on ' + moment(completionDate).format(_this.get('usersDateFormat'));
              }
              return false; // stop _.each
            }
          });
        }
        return item;
      });
      currentChecklistItems = currentChecklistItems.sort(function (a, b) {
        return b.perc10 - a.perc10;
      }).reverse();
      var itemGroups = _lodash.default.values(_lodash.default.groupBy(currentChecklistItems, function (item) {
        return item.ageRange;
      }));

      return _lodash.default.map(itemGroups, function (itemGroup) {
        return {
          ageRange: itemGroup[0].ageRange,
          percentage: _this.calculatePercentage(itemGroup),
          items: itemGroup
        };
      });
    }),

    descriptionWithLineBreaks: Ember.computed('currentlySelectedMilestone', function () {
      var milestone = this.get('currentlySelectedMilestone');
      var safelyEscapedCaption = Ember.Handlebars.Utils.escapeExpression(milestone.get('description'));
      var linebrokenCaption = safelyEscapedCaption.replace(/(?:\r\n|\r|\n)/g, '<br />');
      return new Ember.Handlebars.SafeString(linebrokenCaption);
    }),

    progressBarStyle: function progressBarStyle(percentage) {
      return 'width:' + percentage + '%';
    },
    calculatePercentage: function calculatePercentage(items) {
      var numCompleted = 0; // the current one has been ticked
      _lodash.default.each(items, function (item) {
        numCompleted += item.completed ? 1 : 0;
      });
      return (numCompleted * 100 / items.length).toFixed(0);
    },


    actions: {
      closeModalDialog: function closeModalDialog() {
        Ember.$('.modal').modal('hide');
        Ember.$(".modal-backdrop").delay(200).fadeOut(200);
        this.set('displayModal', false);
      },
      saveModalDialog: function saveModalDialog() {
        Ember.$('.modal').modal('hide');
        Ember.$(".modal-backdrop").delay(200).fadeOut(200);
        this.set('displayModal', false);
        location.reload();
      },
      tickMilestone: function tickMilestone(checklistItem, itemGroup) {
        var pet = this.get('model.pet');
        var currentChecklistThemeColor = this.get("currentChecklistThemeColor");

        this.set("currentMilestoneCompleted", Ember.$("div[data-milestone-item='" + checklistItem.id + "'] .completed").is(':visible'));

        checklistItem['themedStyle'] = 'line-height:20px; border-left:15px solid ' + currentChecklistThemeColor;

        checklistItem['milestoneDialogTitle'] = pet.get('name') + '\'s Milestone';

        checklistItem['checklistTitle'] = 'Age';

        this.set('currentlySelectedMilestone', checklistItem);
        this.set('currentGroup', itemGroup);
        this.set('displayModal', true);
      },
      saveMilestone: function saveMilestone(date) {
        var _this2 = this;

        var completeDate = date ? 'Completed on ' + moment(date).format(this.get('usersDateFormat')) : 'Completed date unsure';

        var selectedChecklistItem = this.get('currentlySelectedMilestone');
        var pet = this.get('model.pet');
        var completedItem = this.store.createRecord('petCompletedChecklistItem', {
          completedDate: moment(date),
          checklistItemId: selectedChecklistItem.id
        });

        if (this.get('canEditMilestones')) {
          completedItem.save({
            adapterOptions: {
              petId: pet.id
            }
          }).then(function () {
            Ember.$(".small-spinner").hide();
            Ember.$("div[data-milestone-item='" + selectedChecklistItem.id + "'] .completed").show();
            Ember.$("div[data-milestone-item='" + selectedChecklistItem.id + "'] .notCompleted").hide();
            _this2.send('saveModalDialog');
          }, function () {
            _this2.send('saveModalDialog');
          });
        }
      }
    }
  });
});