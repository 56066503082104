define('tinybeans-frontend/routes/main/collections/add', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.PhotoCollections.category,
    screen: _tracker.PhotoCollections.add.screen,

    error: {},
    model: function model(params) {
      var journalId = params.journal_id;
      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        collection: this.store.createRecord('collection', {
          name: null,
          description: null
        }),
        collections: this.store.query('collection', { journalId: journalId }),
        followers: this.store.query('follower', { filter: { journalId: journalId } }),
        followings: this.modelFor('main').followings
      });
    },

    actions: {
      reloadPage: function reloadPage() {
        this.refresh();
      },

      flip: function flip() {
        Ember.$("#card").flip(true);
      },

      unflip: function unflip() {
        Ember.$("#card").flip(false);
      }
    }
  });
});