define('tinybeans-frontend/components/video-viewer-for-entry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var videoQuery = this.$('video');
      var resizeObserver = new ResizeObserver(function (entries) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = entries[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var entryVideo = _step.value;

            var videoWidth = entryVideo.contentRect.width;
            // this allows to render "as-is" videos wider than 3:4 (vertical) but will add black bars to narrower formats
            var maxHeight = Math.ceil(videoWidth * 4 / 3);
            entryVideo.target.style['max-height'] = maxHeight + 'px';
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      });
      videoQuery.each(function () {
        // here `this` is the inner video element
        resizeObserver.observe(this);
      });
      this.resizeObserver = resizeObserver;
    },
    willDestroyElement: function willDestroyElement() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
      this._super.apply(this, arguments);
    }
  });
});