define('tinybeans-frontend/controllers/main/journals/add-moment', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash', 'tinybeans-frontend/tracker', 'tinybeans-frontend/services/lotame'], function (exports, _baseController, _commonsMixin, _lodash, _tracker, _lotame) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    queryParams: ['year', 'month', 'day'],
    mainController: Ember.inject.controller('main'),
    monthController: Ember.inject.controller('main.journals.month'),
    entriesController: Ember.inject.controller('main.entries.list'),

    entriesToUpload: [],
    privateMode: false,
    share: false,
    submitting: false,

    init: function init() {
      this._super();
      this.GTM.trackEvent({
        'eventCategory': 'userInteraction',
        'eventAction': 'loadAddMomentPage'
      });
    },
    addNewEntry: function addNewEntry(file) {
      var entry = Ember.Object.create({
        elementId: Math.random().toString(36).substring(7),
        file: file,
        date: this.get('date'),
        clientRef: uuid.v4(),
        children: [],
        pets: []
      });
      this.get('entriesToUpload').pushObject(entry);
      return entry;
    },
    updateEntryDates: function updateEntryDates() {
      var _this = this;

      this.highlightDate('.entry-container .date-field');
      _lodash.default.each(this.get('entriesToUpload').toArray(), function (entry) {
        entry.set('date', _this.get('date'));
      });
    },


    dateChanged: Ember.observer('date', function () {
      this.updateEntryDates();
    }),

    render: function render(element) {
      element.find('.preview').remove();
      var img = $('<img src=\'images/not-available.png\'>').width('100%').height('100%');
      element.append(img);
    },
    renderImage: function renderImage(file, element) {
      element.find('.preview').remove();
      var preview = document.createElement('div');
      $(preview).addClass('preview');
      preview.style.backgroundSize = 'contain';
      preview.style.backgroundRepeat = 'no-repeat';
      preview.style.backgroundPosition = 'top center';
      preview.style.height = '290px';
      element.append(preview);
      var reader = new FileReader();
      reader.onload = function (event) {
        preview.style.backgroundImage = 'url(' + event.target.result + ')';
      };
      reader.readAsDataURL(file);
    },


    specificDateSelected: Ember.computed('day', 'month', 'year', function () {
      return this.get('day') && this.get('month') && this.get('year');
    }),

    noEntries: Ember.computed('model.entries', function () {
      return !this.get('model.entries.length') || this.get('model.entries.length') === 0;
    }),

    renderVideo: function renderVideo(file, element, entry) {
      var fileUrl = URL.createObjectURL(file);
      element.find('.preview').remove();
      var vid = $('<video controls class=\'preview\'><source id=\'vid-source\' src=\'' + fileUrl + '\' type=\'video/mp4\'></video>');
      var upgraded = this.get('model.me.hasMemoriesAccess');
      var oldPremium = this.get('model.journal.isUpgraded');
      var isSubscridbed = this.get('isSubscribedUser');
      var maxLengthOld = oldPremium ? 300 : 30;
      var self = this;
      vid[0].ondurationchange = function () {
        if (upgraded) {
          element.append(vid);
        } else {
          bootbox.dialog({
            title: 'Uh oh.',
            message: 'You can\'t add videos longer than ' + maxLengthOld + ' seconds.<br>' + (upgraded ? 'Please reduce video  and try again.' : 'Either edit the video to under 30 seconds or <a href=\"/app/#/main/subscription-products/list?journalid=' + self.get('model.journal.id') + '\">upgrade your Tinybeans</a> to unlock longer videos.'),
            onEscape: function onEscape() {},
            buttons: {
              ok: {
                label: 'OK'
              }
            }
          });
          self.get('entriesToUpload').removeObject(entry);
        }
      };
    },
    highlightDate: function highlightDate(selector) {
      $(selector).animate({
        'background-color': '#bdd6e6'
      }, 100, function () {
        $(selector).animate({
          'background-color': 'white'
        }, 5000);
      });
    },
    readExifDate: function readExifDate(entry) {
      var self = this;
      var file = entry.get('file');
      EXIF.getData(file, function () {
        var date = moment(EXIF.getTag(this, 'DateTimeOriginal') || EXIF.getTag(this, 'DateTime'), 'YYYY:MM:DD');
        if (!date.isValid()) {
          date = moment();
        }
        entry.set('date', date.format(self.get('model.me.dateFormat')));
        self.highlightDate('.entry-container #' + entry.get('elementId') + ' .date-field');
      });
    },
    loadFile: function loadFile(entry) {
      var _this2 = this;

      var file = entry.get('file');
      var elementId = entry.get('elementId');
      var tryAppend = function tryAppend() {
        var element = $('#' + elementId + ' .content');
        if (element.length) {
          if (file.type.match(/image.*/)) {
            _this2.renderImage(file, element);
          } else if (file.type.match(/video.*/)) {
            _this2.renderVideo(file, element, entry);
          } else {
            _this2.render(element);
          }
          return true;
        }
        return false;
      };
      if (!tryAppend()) {
        var retryInterval = window.setInterval(function () {
          if (tryAppend()) {
            window.clearInterval(retryInterval);
          }
        }, 500);
      }
      if (!this.get('specificDateSelected')) {
        this.readExifDate(entry);
      }
    },


    uploadEntry: function uploadEntry(entryIndex, entriesToUpload, canShare) {
      var _this3 = this;

      var self = this;
      self.get('globals').setProperties({
        fileNumber: entryIndex,
        totalFiles: entriesToUpload.length,
        uploadedPercentage: 0
      });
      if (entryIndex < entriesToUpload.length) {
        self.get('globals').set('showProgressBar', true);
        var entry = entriesToUpload.objectAt(entryIndex);
        var momentTime = entry.get('date') ? moment(entry.get('date'), self.get('model.me.dateFormat')) : moment();
        var newEntry = self.store.createRecord('entry', {
          day: momentTime.date(),
          month: momentTime.month() + 1,
          year: momentTime.year(),
          //type: entry.get('file') ? 'PHOTO' : 'TEXT',
          //Commented by Nate and adam ^^^^ breaks upload moments
          caption: entry.get('caption'),
          clientRef: entry.get('clientRef'),
          privateMode: entry.get('privateMode')
        });

        _lodash.default.each(entry.get('children').toArray(), function (child) {
          newEntry.get('children').pushObject(child);
        });

        _lodash.default.each(entry.get('pets').toArray(), function (pet) {
          newEntry.get('pets').pushObject(pet);
        });

        // Pass the file and facebook share token separately as they are not part of the model but are used by the adapter
        newEntry.save({
          adapterOptions: {
            journalId: self.get('model.journal.id'),
            file: entry.get('file')
            // facebookToken: canShare ? entry.get('facebookToken') : undefined
          }
        }).then(function (response) {
          // if (entry.get('facebookToken')) {
          //   this.API.shareEntryOnFacebook(this.get('model.journal.id'), newEntry.get('id'), entry.get('facebookToken'), () => {}, this);
          // }
          var date = new Date(response.get('year'), response.get('month') - 1, response.get('day'));
          _this3.send('displayNotification', 'Moment added to <b>' + moment(date).format(_this3.globals.usersDateFormat()) + '</b>');

          var file = entry.file;
          var momentType = !file ? 'text' : file['type'].split('/')[0] === 'image' ? 'photo' : 'video';
          // const behavior = `moment added : type : ${momentType}`;
          // const lotame = new Lotame();
          // lotame.sendBehavior(behavior, undefined, this.get("globals.userId"));
          self.uploadEntry(entryIndex + 1, entriesToUpload, canShare); // Upload next file
        }, function (e) {
          if (e.status >= 400 && e.status <= 404) {
            bootbox.dialog({
              title: 'Uh oh.',
              message: 'Sorry we couldn\'t upload this file <b> ' + entry.file.name + ' </b>. Other files may have been uploaded. Please try again later or contact us for support (error code ' + e.status + ').',
              onEscape: function onEscape() {},
              buttons: {
                ok: {
                  label: 'OK'
                }
              }
            });
            self.uploadEntry(entryIndex + 1, entriesToUpload, canShare);
          } else {
            console.log('Retrying because...' + e);
            // Wait 5 seconds and retry
            Ember.run.later(function () {
              self.uploadEntry(entryIndex, entriesToUpload, canShare);
            }, 5000);
          }
        });
      } else {
        this.set("submitting", false);
        self.get('globals').set('showProgressBar', false);
        if (this.get('monthController.route')) {
          this.get('monthController.route').refresh();
        }
        if (this.get('entriesController.route')) {
          this.get('entriesController.route').refresh();
        }
      }
    },

    entryIsVideo: function entryIsVideo(entry) {
      // Image or video are not null
      if (entry.get('file')) {
        return entry.get('file').type.indexOf('video/') === 0;
      } else {
        return false;
      }
    },

    createVideoURL: function createVideoURL(entry) {
      // Load the video as a url
      return window.URL.createObjectURL(entry.file);
    },
    goBack: function goBack() {
      var today = moment();
      var prevRoute = this.get('route.prevRoute');
      if (prevRoute) {
        history.back();
      } else {
        this.transitionToRoute('main.journals.month', this.get('model.journalId'), today.year(), today.month() + 1);
      }
    },


    actions: {

      upgradeSub: function upgradeSub() {
        if (typeof AF !== "undefined") AF('pba', 'event', { eventType: 'EVENT', eventValue: { 'label': 'Click on "Start Your Free Trial"', 'from_page': 'Add Moment' }, eventName: 'Click on "Start Your Free Trial"' });

        _tracker.Tracker.trackEvent({
          category: _tracker.Premium.category,
          screen: _tracker.Premium.list.screen,
          action: _tracker.Premium.list.startTrial
        });

        window.location.hash = '#/main/subscription-products/list';
      },

      removeEntry: function removeEntry(entry) {
        this.get('entriesToUpload').removeObject(entry);
        if (!this.get('entriesToUpload.length')) {
          this.addNewEntry();
        }
      },

      toggleChild: function toggleChild(child, entry, isPet) {
        if (!isPet) {
          var childEntries = entry ? [entry] : this.get('entriesToUpload').toArray();

          _lodash.default.each(childEntries.toArray(), function (entry) {
            var childTagged = _lodash.default.any(entry.get('children').toArray(), function (c) {
              return c.id === child.id;
            });
            var numEntries = child.get('numEntries');
            if (childTagged) {
              entry.get('children').removeObject(child);
              child.set('numEntries', numEntries - 1);
            } else {
              entry.get('children').pushObject(child);
              child.set('numEntries', numEntries ? numEntries + 1 : 1);
            }
          });
        } else {
          var petEntries = entry ? [entry] : this.get('entriesToUpload').toArray();
          _lodash.default.each(petEntries.toArray(), function (entry) {
            var petTagged = _lodash.default.any(entry.get('pets').toArray(), function (p) {
              return p.id === child.id;
            });
            var numEntries = child.get('numEntries');
            if (petTagged) {
              entry.get('pets').removeObject(child);
              child.set('numEntries', numEntries - 1);
            } else {
              entry.get('pets').pushObject(child);
              child.set('numEntries', numEntries ? numEntries + 1 : 1);
            }
          });
        }
      },
      useExifDates: function useExifDates() {
        var _this4 = this;

        this.set('specificDateSelected', false);
        $('#btn-exif-date').addClass('active');
        $('#set-date').removeClass('active');
        _lodash.default.each(this.get('entriesToUpload').toArray(), function (entry) {
          if (entry.file) {
            _this4.readExifDate(entry);
          }
        });
      },
      selectDate: function selectDate() {
        this.set('specificDateSelected', true);
        this.updateEntryDates();
      },


      // check free user upload limits
      isLimited: function isLimited(number) {
        var limit = this.get('model.memories_limit.limit');
        if (limit.threshold > 0) {
          return limit.usedTimes >= limit.threshold || number.length > limit.threshold;
        }
        return false;
      },
      addEntries: function addEntries() {
        var self = this;
        var entriesToUpload = this.get('entriesToUpload');
        var lastEntry = entriesToUpload.objectAt(entriesToUpload.get('length') - 1);
        if (!lastEntry.get('caption') && !lastEntry.get('file')) {
          if (entriesToUpload.get('length') > 1) {
            entriesToUpload.removeObject(lastEntry);
          } else {
            lastEntry.set('error', 'You can\'t upload add an empty moment');
            self.set("submitting", false);
            return;
          }
        }
        // this.model.set("submitting", true);
        // Check user limits
        this.API.getMemoriesLimit().then(function (limit) {
          limit = limit.limit;
          if (limit.threshold > 0 && (limit.usedTimes > limit.threshold || entriesToUpload.length > limit.threshold)) {
            // Short circuit if user has exceeded or met limits
            //modal and cancel
            Ember.$(".limitReachedModal").modal("show");
            // this.set("submitting", false);
            self.get('globals').set('showProgressBar', false);
            if (self.get('monthController.route')) {
              self.get('monthController.route').refresh();
            }
            if (self.get('entriesController.route')) {
              self.get('entriesController.route').refresh();
            }
          } else {
            // success
            for (var i = 0; i < entriesToUpload.length; i++) {
              var entry = entriesToUpload.objectAt(i);
              if (entry && entry.get('caption.length') > 10000) {
                self.set('error.message', 'Please fix the errors above to proceed');
                self.set("submitting", false);
                return;
              }
            }
            self.set('error.message', '');

            _tracker.Tracker.trackEvent({
              action: _tracker.AddMoment.saveButton,
              category: _tracker.AddMoment.category,
              screen: _tracker.AddMoment.screen
            });
            self.send('trackOnboarding', 'add-moment', 'save');
            self.goBack();
            var canShare = self.get('canShare');
            self.uploadEntry(0, Ember.copy(entriesToUpload), canShare);
          }
        });
      },
      filesAdded: function filesAdded(entry, files) {
        var self = this;
        var uploads = this.get('entriesToUpload');
        this.API.getMemoriesLimit().then(function (limit) {
          limit = limit.limit;
          var remaining = limit.threshold - limit.usedTimes;
          if (limit.threshold > 0 && (limit.usedTimes >= limit.threshold || files.length > remaining || files.length + uploads.length > limit.threshold)) {
            if (0 === remaining) {
              // reached modal
              Ember.$(".limitReachedModal").modal("show");
            } else {
              // remaining modal
              Ember.$(".limitRemainingModal").modal("show");
            }
          } else {
            entry.set('file', files[0]);
            self.loadFile(entry);
            //enable multiple uploads for all, turn this back
            //if only allow premium user to multiple uploads

            //      if (this.get('model.journal.isUpgraded')) {

            for (var i = 1; i < files.length; i++) {
              var _entry = self.addNewEntry(files[i]);
              self.loadFile(_entry);
            }
            self.addNewEntry();
            //  }
          }
        });
      },
      cancel: function cancel() {
        this.goBack();
        _tracker.Tracker.trackEvent({
          action: _tracker.AddMoment.cancelButton,
          category: _tracker.AddMoment.category,
          screen: _tracker.AddMoment.screen
        });
      }
    }
  });
});