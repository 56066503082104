define("tinybeans-frontend/routes/main", ["exports", "lodash/lodash", "tinybeans-frontend/tracker"], function (exports, _lodash, _tracker) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    defaultLanguage: "en",
    languageCookieName: "user-lang",
    session: Ember.inject.service("session"),
    queryParams: {
      hide_ui: {
        refreshModel: true
      }
    },

    model: function model(params) {
      if (this.get("session.isAuthenticated")) {
        var hideui = params.hide_ui ? params.hide_ui : false;
        return Ember.RSVP.hash({
          me: this.store.find("user", "me"),
          journalsByID: [],
          journals: this.store.findAll("journal"),
          ads: this.API.getAdsStatus(),
          isSubscribedUser: this.API.getActiveSubscription(),
          followings: this.store.findAll("following"),
          hideDownloadPopup: hideui,
          hide_ui: hideui
        });
      }
    },

    afterModel: function afterModel(model) {
      // Get myJournals using followings
      if (model) {
        if (!model.journals.get("length")) {
          this.transitionTo("main.journals.no-journal");
          console.log("No journal");
          return false;
        }
        model.myJournals = _lodash.default.filter(model.journals.toArray(), function (journal) {
          return journal.get("user.id") == model.me.get("id");
        });

        // Existing journals are those journals with a valid id
        model.existingJournals = model.journals.filter(function (journal) {
          return journal.id;
        });

        var defaultJournal = model.journals ? model.journals.objectAt(0) : null;
        if (!model.currentJournal) model.currentJournal = model.defaultJournal = defaultJournal;

        // Update the existing current journal only if the current journal has a valid id
        if (model.currentJournal.id) {
          model.currentExistingJournal = model.currentJournal;
        }

        this.get("globals").set("dateFormat", model.me.get("dateFormat").toUpperCase());
        var user = model.me;

        // Sentry Integration
        /*
        const urlS = window.location.host;
        let env = "";
        if (urlS == "hinata.tinybeans.com") {
            env = "staging";
        } else if (urlS == "tinybeans.com") {
            env = "production";
        } else if (urlS == "localbeans.com") {
            env = "local";
        }
         Sentry.init({
          dsn: "https://f82b18fa873544058c20070c49bc3458@o335820.ingest.sentry.io/5202824",
          release: "Ember-Memories-App@1.0.0",
          integrations: [new Sentry.BrowserTracing()],
          environment:env,
          tracesSampleRate: 1.0,
          beforeSend(event, hint) {
            const route = event.request.url;
            const tag = event.tags ? event.tags.section: '';
            if(route.includes('/billing/show')){
              console.log('Sending to sentry');
              return event;
            }
            if(tag.includes('Add-Moment-Routes')){
              console.log('Sending to sentry');
              return event;
            }
            if(route.includes('/account/show')){
              console.log('Sending to sentry');
              return event;
            }
          },
        });
         Sentry.configureScope(scope => {
          scope.setUser({
            id: user.get("id"),
            email: user.get("emailAddress"),
            name: user.get("fullName")
          });
        }); */

        //Nota added by: milg15 -- Needed for Lotame
        this.set("globals.userId", user.get("id"));

        //AppsFlyer
        if (typeof AF !== "undefined") AF('pba', 'setCustomerUserId', user.get("id"));

        if (!this.get("globals.lpUserSet")) {
          this.set("globals.lpUserSet", true);
          var userJson = {
            id: user.get("id"),
            firstName: user.get("firstName"),
            lastName: user.get("lastName"),
            emailAddress: user.get("emailAddress")
          };

          _tracker.Tracker.setUser(userJson);
        }
      }
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      if (model) {
        if (model.isSubscribedUser.subscription) {
          controller.set('isSubscribedUser', true);
        } else {
          controller.set('isSubscribedUser', false);
        }
        controller.set("currentJournal", model.currentJournal);
        controller.set("defaultJournal", model.defaultJournal);
        controller.set("isMobile", this.globals.isMobile());
        controller.set("myJournals", model.myJournals ? model.myJournals : null);
        controller.set("showModalAfter", "1s");

        if (window.isMobile) {
          window.setTimeout(function () {
            var cookieName = "showedDownloadAppPopup";
            var value = document.cookie.match(new RegExp(cookieName + "=([^;]+)")) || [];
            if (decodeURIComponent(value[1] || "")) {
              controller.set("showDownloadAppPopup", false);
            } else if (model.hideDownloadPopup) {
              // hide_ui parameter only comes from Android app as of 9/8/23 and coupon redemption flow
              controller.set("showDownloadAppPopup", false);
            } else {
              controller.set("showDownloadAppPopup", true);
            }
            _this.GTM.trackEvent({
              eventCategory: "download_app_popup",
              eventAction: "show_after_1s",
              eventLabel: "show",
              eventValue: "1s"
            });
          }, 1000);
        }
      }
    },

    setLanguage: function setLanguage(lang) {
      Ember.$.cookie(this.languageCookieName, lang, {
        expires: 365,
        path: "/"
      });
      moment.locale(lang);
      Ember.ENV.LANGUAGE = lang;
    },

    getLanguage: function getLanguage() {
      // default if not set
      if (Ember.$.cookie("user-lang") === undefined) {
        // no cookie exists yet
        this.setLanguage("en");
      }
      // get selected language from cookie
      var lang = Ember.$.cookie(this.languageCookieName);
      // set global var for language
      Ember.ENV.LANGUAGE = lang;
      moment.locale(lang);
      return Ember.ENV.LANGUAGE;
    },

    reloadWithLanguage: function reloadWithLanguage(code) {
      this.setLanguage(code);
      window.location.reload();
    },

    init: function init() {
      Ember.$("[name='bootstrapSwitch*']").bootstrapSwitch();
      Ember.run.next(function () {});
    },

    actions: {
      error: function error(_error) {
        var _this2 = this;

        _error = _error.errors && _error.errors.length > 0 ? _error.errors[0] : _error;
        if (_error && (_error.statusCode === 400 || _error.statusCode === 401 || _error.statusCode === 403)) {
          // error substate and parent routes do not handle this error
          if (this.get("session.isAuthenticated")) {
            this.get("session").invalidate().then(function () {
              _this2.store.unloadAll();
            });
          }
          this.replaceWith("login");
        } else {
          // other error codes
          console.log("Error: ", _error);
          return true;
        }
      },

      setLanguage: function setLanguage(code) {
        this.reloadWithLanguage(code);
      },

      reloadApplicationRoute: function reloadApplicationRoute() {
        this.refresh();
      },

      reloadEntries: function reloadEntries() {
        this.modelFor("main").journalEntries = [];
        this.modelFor("main").dayEntries = [];
      },

      entryAddCompleted: function entryAddCompleted(entry) {
        // Set the applications month entries if they exist
        var journalEntries = this.modelFor("main").journalEntries;
        if (journalEntries && journalEntries[entry.journalId] && journalEntries[entry.journalId][entry.year] && journalEntries[entry.journalId][entry.year][entry.month]) {
          journalEntries[entry.journalId][entry.year][entry.month].replace(0, 0, entry); // pushObject(entry);
        }
      },

      doTransition: function doTransition() {
        this.transitionTo.apply(this, arguments);
      },


      gotoTopOfPage: function gotoTopOfPage() {
        Ember.$("html, body").animate({
          scrollTop: 0
        }, "slow");
      },

      displayNotification: function displayNotification(message) {
        console.log("------ main.js displayNotification: set content and control visibility ------");
        console.info($("#globalNotification").length);
        $("#globalNotification #messageContent").html(message);
        $("#globalNotification").fadeIn("fast").delay(3000).fadeOut("slow");
      },
      trackOnboarding: function trackOnboarding(page, action) {
        var queryParams = this.get("controller").getProperties("_campaign", "_source", "_num");

        if (queryParams._campaign) {
          this.GTM.trackEvent({
            eventCategory: queryParams._campaign,
            eventAction: action,
            eventLabel: queryParams._source + "_" + queryParams._num,
            eventValue: page
          });
        }
      },


      gotoPremiumPage: function gotoPremiumPage() {
        this.transitionTo("main.subscription-products.list");
      },

      swtichJournalRedirectBack: function swtichJournalRedirectBack(newJournalId) {
        if (this.get("session.isAuthenticated")) {
          var journals = this.modelFor("main").myJournals.toArray();
          var curJournal = _lodash.default.filter(journals, function (journal) {
            return journal.id === newJournalId;
          });

          if (curJournal) {
            var newCurJournal = curJournal[0];
            this.controller.set("currentJournal", newCurJournal);
            this.controller.set("currentExistingJournal", newCurJournal);

            this.controllerFor("main").set("model.currentJournal", newCurJournal);
            this.controllerFor("main").set("model.currentExistingJournal", newCurJournal);

            this.controller.set("showJournalDropdown", true);
          } else {
            this.controller.set("showJournalDropdown", false);
          }
        }
      }
    }
  });
});