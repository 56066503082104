define('tinybeans-frontend/components/main/payment-details/card-option', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    type: 'div',
    autocomplete: "off",

    click: function click() {
      this.click();
    },

    touchStart: function touchStart(event) {
      return this.click(event);
    }

  });
});