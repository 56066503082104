define('tinybeans-frontend/components/main/journals/add-moment/entry-upload', ['exports', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_commonsMixin.default, {
    accept: 'video/mp4,video/x-m4v,video/*,image/gif,image/jpeg,image/png,.mts,.heic',
    error: {},

    multipleUploadEnabled: Ember.computed('model.journal', function () {
      return this.get("forceSingle") ? false : true;
    }),

    didInsertElement: function didInsertElement() {
      this.$('.entry-container').show(200);
    },

    willDestroyElement: function willDestroyElement() {
      this.$('.entry-container').hide(200);
    },

    observeCaption: Ember.observer('entry.caption', function () {
      this.set('error.caption', this.get('entry.caption.length') > 10000 ? 'Please use a caption less than 10,000 letters in length.' : '');
    }),

    change: function change(event) {
      if (event.target.files && event.target.files.length) {
        this.sendAction('filesAdded', this.get('entry'), event.target.files);
      }
    },


    actions: {
      toggleChild: function toggleChild(child, entry) {
        this.sendAction('toggleChild', child, entry, false);
      },
      togglePet: function togglePet(pet, entry) {
        this.sendAction('toggleChild', pet, entry, true);
      },


      togglePrivateMode: function togglePrivateMode() {
        this.set('entry.privateMode', !this.get('entry.privateMode'));
      },

      // toggleShare: function() {
      //   let entry = this.get('entry');
      //   if (!entry.get('share')) { // Going to be set to true
      //     // Add a facebook token to the entry that should be shared
      //     if (!this.get('facebookToken')) {
      //       this.API.loginToFacebook(function(data) {
      //         entry.set('facebookToken', data.facebookAccessToken);
      //         entry.set('share', true);
      //       }, function() {
      //         entry.set('share', false);
      //       });
      //     } else {
      //       entry.set('share', true);
      //     }
      //   } else {
      //     entry.set('share', false);
      //   }
      // },

      removeEntry: function removeEntry(entry) {
        this.sendAction('removeEntry', entry);
      }
    }
  });
});