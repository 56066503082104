define('tinybeans-frontend/controllers/event-signup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['campaign'],
    disabled: false,

    init: function init() {
      var _this = this;

      this._super();
      Ember.run.schedule("afterRender", this, function () {
        $("input").addClass("input-lg");
        _this.initialize();
      });
    },

    initialize: function initialize() {
      this.set('model.error', null);
      this.set('model.user', {
        firstName: "",
        lastName: "",
        username: "",
        emailAddress: "",
        password: "webtemppass" + new Date().getTime(),
        phoneNumber: "",
        sendWelcome: true,
        campaign: this.get('campaign'),
        sendResetPassword: true,
        dateStyle: moment.localeData().longDateFormat('L').toUpperCase().replace(/\//g, '')
      });
      $("input").val("");
    },


    actions: {
      save: function save() {
        var _this2 = this;

        this.set('disabled', true);
        var user = this.get('model.user');
        this.API.insertUser(user, null, function () {
          _this2.initialize();
          swal({
            title: "Congratulations!",
            text: "Yay! Welcome to Tinybeans!",
            type: "success",
            timer: 3000
          });
          _this2.set('disabled', false);
        }, function (error) {
          var detailedMessage = "";
          try {
            detailedMessage = JSON.parse(error.responseText).detailedMessage;
          } catch (e) {}
          swal({
            title: "Oops...",
            text: "Please double check your details! " + detailedMessage,
            type: "error",
            timer: 3000
          });
          _this2.set('disabled', false);
        });
      }
    }
  });
});