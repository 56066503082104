define('tinybeans-frontend/routes/main/children/channel', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Child.category,
    screen: _tracker.Child.screen,

    model: function model(params) {
      var childId = params.child_id;
      var journalId = params.journal_id;
      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        child: this.store.find('child', childId),
        journal: this.store.find('journal', journalId),
        weights: this.store.query('weight', { childId: childId }),
        heights: this.store.query('height', { childId: childId }),
        completedChecklistItems: this.store.query('completedChecklistItem', { childId: childId }),
        followings: this.store.findAll('following'),
        followers: this.store.query('follower', { filter: { journalId: journalId } }),
        subscribedChannels: this.API.getSubscribedChannels(childId),
        subscribedChannelArticles: this.API.getSubscribedChannelArticles(childId)
      });
    }

  });
});