define('tinybeans-frontend/routes/simple-redirect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function model(params) {
      Ember.$.extend(params, this.paramsFor('main'));
      var access_token = params.access_token;
      var redirect = params.redirect;
      var hideui = params.hide_ui ? params.hide_ui : false;
      return Ember.RSVP.hash({
        access_token: access_token,
        redirect: redirect,
        hide_ui: hideui
      });
    },

    afterModel: function afterModel(model) {
      this.get('session').authenticate('authenticator:redirect', { accessToken: model.access_token });
      var url = model.redirect;

      if ('true' === model.hide_ui) {
        // can't use newer URL api here because it doesn't like our hashes
        if (url.indexOf('?') > -1) {
          url += '&hide_ui=true';
        } else {
          url += '?hide_ui=true';
        }
        location.href = url;
      }
      location.href = url;
    }
  });
});