define('tinybeans-frontend/routes/main/children/milestones/checklist', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Child.category,
    screen: _tracker.Child.screen,

    model: function model(params) {
      var childId = params.child_id;
      var journalId = params.journal_id;

      return Ember.RSVP.hash({
        me: this.store.find('user', 'me'),
        journalId: journalId,
        journal: this.store.find('journal', journalId),
        child: this.store.find('child', childId),
        weights: this.store.query('weight', {
          childId: childId
        }),
        heights: this.store.query('height', {
          childId: childId
        }),
        /*   subscribedChannels: this.API.getSubscribedChannels(childId) || [], */
        completedChecklistItems: this.store.query('completedChecklistItem', {
          childId: childId
        }),
        channelSubscriptions: this.store.query('channelSubscription', {
          childId: childId
        }),
        /* full list */
        followings: this.store.findAll('following'),
        followers: this.store.query('follower', {
          filter: {
            journalId: journalId
          }
        }),
        hide_ui: this.modelFor('main').hide_ui

      });
    },
    afterModel: function afterModel(model) {
      model.completedChecklistItems = _lodash.default.filter(model.completedChecklistItems.toArray(), function (item) {
        return !item.get('deleted');
      });
    },


    actions: {

      reloadPage: function reloadPage() {
        this.refresh();
      }
    }

  });
});