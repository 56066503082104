define('tinybeans-frontend/helpers/html-safe', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.htmlSafe = htmlSafe;
  function htmlSafe(params /*, hash*/) {
    var style = params[0];
    return Ember.String.htmlSafe(style);
  }

  exports.default = Ember.Helper.helper(htmlSafe);
});