define('tinybeans-frontend/tracker/index', ['exports', 'tinybeans-frontend/tracker/constants', 'tinybeans-frontend/tracker/leanplum'], function (exports, _constants, _leanplum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Tracker = undefined;
  Object.keys(_constants).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(exports, key, {
      enumerable: true,
      get: function () {
        return _constants[key];
      }
    });
  });
  var Tracker = exports.Tracker = {
    createGTMEvent: function createGTMEvent(event) {
      return event.action.indexOf(_constants.CommonActions.pageView) >= 0 ? {
        event: 'gtm.js',
        virtualPagePath: event.screen
      } : {
        event: 'trackEvent',
        eventCategory: event.category ? event.category : '',
        eventAction: event.action ? event.action : '',
        eventLabel: event.label ? event.label : '',
        eventValue: event.value ? event.value : ''
      };
    },
    setUser: function setUser(user) {
      _leanplum.default.setUser(user);
    },


    trackEvent: function trackEvent(event) {
      // GTM.trackEvent(this.createGTMEvent(event));
      _leanplum.default.trackEvent(event);
    }
  }; // import GTM from './gtm';
});