define('tinybeans-frontend/models/payment-transaction', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    dateCreated: _emberData.default.attr('string'),
    transactionDate: _emberData.default.attr(),
    amountInCents: _emberData.default.attr('number'),
    amountDisplay: _emberData.default.attr('string'),
    discount: _emberData.default.attr('number'),
    productId: _emberData.default.attr('string'),
    last4: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    userId: (0, _relationships.belongsTo)('user'),

    user: Ember.computed.alias('userId')
  });
});