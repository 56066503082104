define("tinybeans-frontend/controllers/main/subscription-products/purchase-voucher-success", ["exports", "tinybeans-frontend/controllers/base-controller"], function (exports, _baseController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function scrollToTop() {
    try {
      window.scrollTo(0, 0);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    } catch (e) {
      console.error(e);
    }
  }

  exports.default = _baseController.default.extend({
    init: function init() {
      scrollToTop();
      Ember.run.schedule("afterRender", this, scrollToTop);
    }
  });
});