define('tinybeans-frontend/controllers/main/children/show', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _lodash, _tracker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseController.default.extend(_commonsMixin.default, {
        rawChecklists: [],
        completedChecklistItems: [],
        sparkleObjects: [],
        currentOffset: 0,
        currentlySelectedMilestoneIndex: 0,
        currentlySelectedAgeRangeIndex: 0,
        currentStartAge: 0,
        currentEndAge: 3,
        validAgeRanges: [0, 3, 6, 9, 12, 18, 24, 36, 48, 60, 72, 84],
        oldScrollTop: 0,

        replaceChildProperties: function replaceChildProperties(str, child) {
            if (str) {
                var search = void 0,
                    replace = void 0;
                for (var propertyName in child) {
                    search = new RegExp('{' + propertyName + '}', 'gi');
                    replace = child[propertyName];
                    str = str.replace(search, replace);
                }

                // Also replace {month}
                search = new RegExp('{month}', 'gi');
                replace = child.ageInMonthsOnly;
                str = str.replace(search, replace);
            }
            return str;
        },


        dashboardChecklistItems: Ember.computed('model.dashboardChecklistItems', 'model.child', function () {
            var _this = this;

            return _lodash.default.map(this.get('model.dashboardChecklistItems').toArray(), function (checklistItem) {
                var themeColor = checklistItem.get('checklist.themeColorString');
                checklistItem.set('themeColorStyle', 'border-left:5px solid ' + themeColor);
                checklistItem.set('themeStyle', 'height:20px; background:' + themeColor);
                checklistItem.set('completed', false);

                var ageRange = "";
                if (_this.get('model.child.isBorn')) {
                    if (checklistItem.get('perc10') < 24) {
                        ageRange = checklistItem.get('perc10') + '-' + checklistItem.get('perc90') + ' MONTHS';
                    } else if (checklistItem.get('perc10') >= 24 && checklistItem.get('perc90') <= 72) {
                        ageRange = Number(checklistItem.get('perc10')) / 12 + '-' + Number(checklistItem.get('perc90')) / 12 + ' YEARS';
                    }
                }
                checklistItem.set('ageRange', ageRange);
                return checklistItem;
            });
        }),

        subscribedChannelArticles: Ember.computed('model.subscribedChannelArticles', function () {
            var _this2 = this;

            var articles = _lodash.default.slice(this.get('model.subscribedChannelArticles').toArray(), 0, 4);
            var child = this.get('model.child');
            return _lodash.default.map(articles, function (article) {
                var title = _this2.replaceChildProperties(article.title, child);
                var content = _this2.replaceChildProperties(article.content, child);
                var contentUrl = _this2.replaceChildProperties(article.contentUrl, child);
                article.title = title && title.length > 20 ? title.substring(0, 20) + "..." : title;
                article.content = content && content.length > 75 ? content.substring(0, 75) + "..." : content;
                article.contentUrl = contentUrl;
                return article;
            });
        }),

        showMoreArticlesLink: Ember.computed('model.subscribedChannelArticles', function () {
            return this.get('model.subscribedChannelArticles.length') > 4;
        }),

        milestoneCount: Ember.computed('model.child', 'model.completedChecklistItems', function () {
            return _lodash.default.filter(this.get('model.completedChecklistItems').toArray(), function (item) {
                return !item.get('deleted');
            }).length;
        }),

        currentFormattedDate: Ember.computed('property', function () {
            return moment().format(this.get('globals.usersDateFormat'));
        }),

        dateSelectionOptionValues: [{
            name: 'today',
            label: 'Today'
        }, {
            name: 'yesterday',
            label: 'Yesterday'
        }, {
            name: 'oneWeekAgo',
            label: 'One week ago'
        }, {
            name: 'twoWeeksAgo',
            label: 'Two weeks ago'
        }, {
            name: 'selectDate',
            label: 'Select date'
        }, {
            name: 'notSure',
            label: 'Not sure'
        }],

        dateSelectionValue: 'today',

        showSpecificDateOption: function () {
            return this.get('dateSelectionValue') == 'selectDate';
        }.property('dateSelectionValue'),

        hideIfDoingMilestoneStyle: function () {
            var self = this;
            if (self.get('doMilestoneCompletion')) {
                return 'height: 0px; overflow: hidden;';
            } else {
                var isCompleted = function isCompleted(checklistItem) {
                    var completedChecklistItems = self.get('completedChecklistItems');
                    for (var i = 0; i < completedChecklistItems.length; i++) {
                        var completedChecklistItem = completedChecklistItems[i];
                        if (!completedChecklistItem.deleted && completedChecklistItem.checklistItemId === checklistItem.id) {
                            return 0;
                        }
                    }
                    return 1; // We want non-completed to come after completed items
                };

                var sortChecklistItems = function sortChecklistItems(checklist) {
                    var checklistItems = (checklist.checklistItems || []).slice();
                    checklistItems.sort(function (a, b) {
                        if (a.perc90 && b.perc90) {
                            if (a.perc90 === b.perc90) {
                                return isCompleted(a) - isCompleted(b);
                            } else {
                                return a.perc90 - b.perc90;
                            }
                        } else {
                            return isCompleted(a) - isCompleted(b);
                        }
                    });
                    checklist.checklistItems = checklistItems;
                    return checklist;
                };

                var rawChecklists = self.get('rawChecklists');
                var newRawChecklists = [];
                for (var i = 0; i < rawChecklists.length; i++) {
                    newRawChecklists.pushObject(sortChecklistItems(rawChecklists[i]));
                }
                self.set('rawChecklists', newRawChecklists);

                self.route.disconnectOutlet({
                    outlet: 'modal-dialog'
                });
                Ember.$(".modal-backdrop").hide();
                return '';
            }
        }.property('doMilestoneCompletion'),

        hasAtLeastOneSubscription: function () {
            return this.get('rawChecklists').length > 0;
        }.property('rawChecklists'),

        init: function init() {
            var self = this;
            var transitionHandler = function transitionHandler() {
                // Read the current url and hide/show the completion as appropriate
                self.set('doMilestoneCompletion', location.hash.indexOf('/main/children/show') == -1);
                if (self.get('doMilestoneCompletion')) {
                    Ember.run.next(function () {
                        Ember.$('body').scrollTop(self.get('oldScrollTop'));
                    });
                }
            };
            this.set('customTransitionHandler', transitionHandler);
            Ember.$(window).bind('popstate', this.get('customTransitionHandler'));
        },

        willDestroy: function willDestroy() {
            Ember.$(window).unbind('popstate', this.get('customTransitionHandler'));
        },

        calcAgeRange: function calcAgeRange(end) {
            if (end) {
                var validAgeRanges = this.get('validAgeRanges');

                for (var i = 1; i < validAgeRanges.length; i++) {
                    if (validAgeRanges[i] >= end) {
                        console.log("valid age range for " + end + " months stops when " + validAgeRanges[i] + " reached " + i);
                        return i - 1;
                    }
                }
            }
            console.log("no age range");
            return null;
        },

        allChecklists: function () {
            var ret = [];
            var rawChecklists = this.get('rawChecklists');
            for (var i = 0; i < rawChecklists.length; i++) {
                var currentSelectedChecklists = rawChecklists[i];
                if (currentSelectedChecklists.checklistItems) {
                    for (var j = 0; j < currentSelectedChecklists.checklistItems.length; j++) {
                        var checklist = currentSelectedChecklists.checklistItems[j];
                        // Also push the checklistItem index and the age range index
                        // Calculate the age range index
                        var checklistItemToDisplay = {
                            checklistItemIndex: i,
                            ageRangeIndex: this.calcAgeRange(checklist.perc90),
                            checklist: checklist
                        };
                        ret.pushObject(checklistItemToDisplay);
                    }
                }
            }
            return ret;
        }.property('rawChecklists'),

        filterChecklists: function () {
            var currentlySelectedMilestoneIndex = this.get('currentlySelectedMilestoneIndex');
            var currentlySelectedAgeRangeIndex = this.get('currentlySelectedAgeRangeIndex');
            //Ember.$('.milestones-in-slick').slick('slickUnfilter');
            Ember.$('.milestones-in-slick').slick('slickFilter', function () {
                return $(this).data("milestone-index") == currentlySelectedMilestoneIndex; // && ($(this).data("age-group-index") == currentlySelectedAgeRangeIndex || $(this).data('age-group-index') == undefined );
            });
            var firstSlideForAge = $('.milestones-in-slick .slick-slide').index($('[data-age-group-index=' + currentlySelectedAgeRangeIndex + ']'));
            Ember.$('.milestones-in-slick').slick('slickGoTo', firstSlideForAge, false);
        }.observes('currentlySelectedAgeRangeIndex', 'currentlySelectedMilestoneIndex'),

        showAgeRange: function () {
            var currentChecklist = this.get('rawChecklists')[this.get('currentlySelectedMilestoneIndex')];
            return currentChecklist.showDateRange;
        }.property('currentlySelectedMilestoneIndex'),

        currentlySelectedRange: function () {
            var currentStartAge = this.get('currentStartAge');
            var currentEndAge = this.get('currentEndAge');
            var displayAsMonths = currentStartAge < 24 || currentEndAge < 24;
            if (displayAsMonths) {
                return currentStartAge + ' - ' + currentEndAge + ' months';
            } else {
                return Math.floor(currentStartAge / 12) + ' - ' + Math.floor(currentEndAge / 12) + ' years';
            }
        }.property('currentStartAge', 'currentEndAge'),

        weight: function () {
            var weights = this.get('weights');
            if (weights && weights.length > 0) {
                return weights[weights.length - 1];
            } else {
                return "?";
            }
        }.property('weights'),

        height: function () {
            var heights = this.get('heights');
            if (heights && heights.length > 0) {
                return heights[heights.length - 1];
            } else {
                return "?";
            }
        }.property('heights'),

        momentsCount: function () {
            var count = this.get('entryCount');
            if (count) {
                return count;
            } else {
                return 0;
            }
        }.property('entryCount'),

        usersDateFormat: function () {
            return this.globals.usersDateFormat(this.get('me.dateStyle.format'));
        }.property('me.dateStyle.format'),

        actions: {

            tickMilestone: function tickMilestone(checklistItem) {
                var _this3 = this;

                var child = this.get('model.child');
                var currentChecklist = checklistItem.get("checklist");
                var currentChecklistThemeColor = currentChecklist.get("themeColorString");

                this.set("currentMilestoneCompleted", Ember.$("div[data-milestone-item='" + checklistItem.id + "'] .completed").is(':visible'));
                checklistItem.set('themeStyle', 'line-height:20px; border-left:15px solid ' + currentChecklistThemeColor);
                checklistItem.set('milestoneDialogTitle', child.get('firstName') + '\'s Milestone');
                checklistItem.set('checklistTitle', currentChecklist.get('title'));

                var replaceChildProperties = function replaceChildProperties(str) {
                    if (str) {
                        var search = void 0,
                            replace = void 0;
                        for (var propertyName in child) {
                            search = new RegExp('{' + propertyName + '}', 'gi');
                            replace = child[propertyName];
                            str = str.replace(search, replace);
                        }
                        // Also replace {month}
                        search = new RegExp('{month}', 'gi');
                        replace = _this3.get('ageInMonthsOnly');
                        str = str.replace(search, replace);
                    }
                    return str;
                };

                var processRelatedArticles = function processRelatedArticles(relatedArticles) {
                    return _lodash.default.map(relatedArticles, function (relatedArticle) {
                        var article = relatedArticle.get('article');
                        if (article) {
                            article.title = replaceChildProperties(article.title);
                            article.content = replaceChildProperties(article.content);
                            article.contentUrl = replaceChildProperties(article.contentUrl);
                        }
                        return relatedArticle;
                    });
                };
                if (checklistItem.get('relatedArticles')) {
                    var valid = _lodash.default.any(checklistItem.get('relatedArticles').toArray(), function (article) {
                        return !article.get('article.deleted');
                    });
                    checklistItem.set('validRelatedArticles', valid);
                    var checkListItemsArr = checklistItem.get('relatedArticles').toArray();
                    var sortedCheckListItems = checkListItemsArr.sort(function (a, b) {
                        return b.get('sortOrder') - a.get('sortOrder');
                    });

                    checklistItem.set('relatedArticles', processRelatedArticles(sortedCheckListItems));
                }

                this.set('currentlySelectedMilestone', checklistItem);
                this.set('displayModal', true);
                this.send('track', 'open-milestone');
            },

            closeModalDialog: function closeModalDialog() {
                Ember.$('.modal').modal('hide');
                Ember.$(".modal-backdrop").delay(200).fadeOut(200);
                this.set('displayModal', false);
            },
            saveMilestone: function saveMilestone(date) {
                var _this4 = this;

                var completeDate = date ? 'Completed on ' + moment(date).format(this.get('usersDateFormat')) : 'Completed date unsure';
                var selectedChecklistItem = this.get('currentlySelectedMilestone');
                var child = this.get('model.child');
                var completedItem = this.store.createRecord('completedChecklistItem', {
                    day: date ? date.date() : null,
                    month: date ? date.month() + 1 : null,
                    year: date ? date.year() : null,
                    checklistItemId: selectedChecklistItem.id
                });

                if (this.get('canEditMilestones')) {
                    completedItem.save({
                        adapterOptions: {
                            childId: child.id
                        }
                    }).then(function () {
                        Ember.$(".small-spinner").hide();
                        selectedChecklistItem.set('completionDate', completeDate);
                        _this4.set("currentlySelectedMilestone", selectedChecklistItem);
                        _this4.set("currentMilestoneCompleted", true);

                        Ember.$("div[data-milestone-item='" + selectedChecklistItem.id + "'] .completed").show();
                        Ember.$("div[data-milestone-item='" + selectedChecklistItem.id + "'] .notCompleted").hide();
                        var completedChecklistItems = _this4.get('model.completedChecklistItems').toArray();
                        completedChecklistItems.push(completedItem);
                        _this4.set('model.completedChecklistItems', completedChecklistItems);
                        _this4.send('closeModalDialog');
                    }, function () {
                        _this4.send('closeModalDialog');
                    });
                }
            },
            removeMilestone: function removeMilestone() {
                var _this5 = this;

                Ember.$(".small-spinner").show();
                Ember.$(".milestone-dialog-inner .completed").hide();
                var checklistItem = this.get('currentlySelectedMilestone');
                var completedItem = _lodash.default.find(this.get('model.completedChecklistItems').toArray(), function (item) {
                    return _this5.get('currentlySelectedMilestone.id') == item.get('checklistItemId');
                });
                completedItem.destroyRecord({
                    adapterOptions: {
                        childId: this.get('model.child.id')
                    }
                }).then(function () {
                    Ember.$(".small-spinner").hide();
                    _this5.set("currentMilestoneCompleted", false);
                    Ember.$("div[data-milestone-item='" + checklistItem.id + "'] .completed").hide();
                    Ember.$("div[data-milestone-item='" + checklistItem.id + "'] .notCompleted").show();
                    Ember.$("div[data-milestone-item='" + checklistItem.id + "'] .milestone-completion-date").text("");

                    _this5.send('closeModalDialog');
                    var milestoneCount = _this5.get('milestoneCount');
                    milestoneCount--;
                    _this5.set('milestoneCount', milestoneCount);
                });
            },
            track: function track(what) {
                var action = 'unknown';

                switch (what) {
                    case 'open-milestone':
                        action = _tracker.Child.openRecommendedMilestone;
                        break;
                    default:
                        break;
                }

                _tracker.Tracker.trackEvent({
                    category: _tracker.Child.category,
                    screen: _tracker.Child.screen,
                    action: action
                });
            }
        }
    });
});