define('tinybeans-frontend/session-stores/application', ['exports', 'ember-simple-auth/session-stores/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    key: 'ember_simple_auth:session',
    cookieExpirationTime: null,
    localstorageSupported: true,
    cookieName: 'access_token',

    init: function init() {
      this._super.apply(this, arguments);
      this.key = 'tinybeansstore';
      var testKey = 'test',
          storage = window.localStorage;
      try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        this.localstorageSupported = true;
        // this._bindToStorageEvents()
      } catch (error) {
        this.localstorageSupported = false;
      }
    },

    persist: function persist(data) {

      if (data.authenticated.facebookAccessToken) this.writeCookie('facebookAccessToken', data.authenticated.facebookAccessToken);
      if (data.authenticated.rememberme) this.writeCookie('rememberme', data.authenticated.rememberme);
      var accessToken = data.authenticated.accessToken || data.authenticated.access_token;
      if (accessToken) {
        this.writeCookie('access_token', data.authenticated.accessToken);
        this.writeCookie('accessToken', data.authenticated.accessToken);
      }
      if (data.authenticated.user && data.authenticated.user.id) {
        this.writeCookie('tb_userId', data.authenticated.tb_userId);
        window.tb_userId = data.authenticated.user.id;
      }
      return Ember.RSVP.resolve();
    },

    restore: function restore() {
      var data = void 0;

      if (this.localstorageSupported) {
        data = localStorage.getItem(this.key);
        if (data != '' && data != null) {

          if (data == '{authenticated: {}}') {
            localStorage.removeItem("tinybeansstore");
            data = { authenticated: { user: {} } };
            data.authenticated.accessToken = this.readCookie('accessToken');
            data.authenticated.facebookAccessToken = this.readCookie('facebookAccessToken');
            data.authenticated.user.id = this.readCookie('tb_userId');
            data.authenticated.rememberme = this.readCookie('rememberme');

            if (this.readCookie('accessToken')) {
              data.authenticated.authenticator = "authenticator:access_token";
            }
          } else {
            data = JSON.parse(data);
          }
        } else {
          data = { authenticated: { user: {} } };
          data.authenticated.accessToken = this.readCookie('accessToken');
          data.authenticated.facebookAccessToken = this.readCookie('facebookAccessToken');
          data.authenticated.user.id = this.readCookie('tb_userId');
          data.authenticated.rememberme = this.readCookie('rememberme');

          if (this.readCookie('accessToken')) {
            data.authenticated.authenticator = "authenticator:access_token";
          }
        }
      } else {
        data = { authenticated: { user: {} } };
        data.authenticated.accessToken = this.readCookie('accessToken');
        data.authenticated.facebookAccessToken = this.readCookie('facebookAccessToken');
        data.authenticated.user.id = this.readCookie('tb_userId');
        data.authenticated.rememberme = this.readCookie('rememberme');

        if (this.readCookie('accessToken')) {
          data.authenticated.authenticator = "authenticator:access_token";
        }
      }

      data = data || { authenticated: {} };
      return Ember.RSVP.resolve(data);
    },

    clear: function clear() {
      this.writeCookie('access_token', null, 0);
      this.writeCookie('accessToken', null, 0);
      this.writeCookie('facebookAccessToken', null, 0);
      this.writeCookie('PLAY_SESSION', null, 0);
      this.writeCookie('rememberme', null, 0);
      if (this.localstorageSupported) {
        localStorage.removeItem(this.key);
      }
      return Ember.RSVP.resolve();
    },
    // _bindToStorageEvents() {
    //   console.log("In session-stores/application.js#_bindToStorageEvents");
    //   Ember.$(window).bind('storage', (e) => {
    //     if (e.originalEvent.key === this.key) {
    //       this.restore().then((data) => {
    //         if (!_.isEqual(data, this._lastData)) {
    //           this._lastData = data;
    //           this.trigger('sessionDataUpdated', data);
    //         }
    //       });
    //     }
    //   });
    // },
    readCookie: function readCookie(key) {
      var value = document.cookie.match(new RegExp(key + '=([^;]+)')) || [];
      return decodeURIComponent(value[1] || '');
    },
    writeCookie: function writeCookie(key, value, expiration) {
      // if (!(key && value)) return;
      var expires = Ember.isEmpty(expiration) ? '' : '; expires=' + new Date(expiration).toUTCString();
      var secure = !!this._secureCookies ? ';secure' : '';
      var path = ";path=/";
      document.cookie = key + '=' + encodeURIComponent(value) + expires + secure + path;
    }
  });
});