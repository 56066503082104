define('tinybeans-frontend/routes/main/account/show', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/config/environment'], function (exports, _baseProtectedRoute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model() {
      var journalId = this.modelFor('main').currentJournal.id;
      return Ember.RSVP.hash({
        user: this.store.find('user', 'me'),
        //TODO don't forget this
        followings: this.modelFor('main').followings,
        journals: this.modelFor('main').journals,
        journal: this.store.find('journal', journalId),
        timeZones: this.API.listTimeZones(),
        measurementSystems: this.API.listMeasurementSystems(),
        dateStyles: this.API.listDateStyles(),
        emailFrequencyTypes: this.API.listEmailFrequencyTypes(),
        isSubscribedUser: this.API.getActiveSubscription(),
        isGeoLocationAllowed: this.API.getIsGeoLocation(),
        currentLocation: this.API.getLocation()
      });
    },


    setupController: function setupController(controller, model, error) {
      Ember.Logger.debug(error.message);
      this._super(controller, model);
      var user = model.user;
      controller.set('user', user);

      //Get Current Location
      var location = void 0;
      if (model.currentLocation) {
        location = model.currentLocation.location;
        if (location) {
          if (location.origin === "USER_DEFINED") {
            if (!location.city) {
              controller.set('currentLocation', location.state + ', ' + location.country);
            } else if (!location.state) {
              controller.set('currentLocation', location.city + ', ' + location.country);
            } else {
              controller.set('currentLocation', location.city + ', ' + location.state + ', ' + location.country);
            }
          }
        }
      } else {
        console.log("No location :(", model.currentLocation);
      }

      // Set if GeoLocation is allowed
      var isGeoAllowed = model.isGeoLocationAllowed.geolocationAllowed;
      controller.set('isLocationChecked', isGeoAllowed);
      if (isGeoAllowed) {
        localStorage.setItem('geolocationAllowed', 'true');
      } else {
        localStorage.setItem('geolocationAllowed', 'false');
      }

      // avatar
      if (user.get("avatars.l")) {
        controller.set('avatar', {
          url: user.get("avatars.l")
        });
      }

      if (model.isSubscribedUser.subscription) {
        controller.set('isSubscribedUser', true);
        controller.set('expiryDate', model.isSubscribedUser.subscription.expiryDateDisplay);
        controller.set('planType', model.isSubscribedUser.subscription.paymentTransaction.amountDisplay);
        controller.set('autoRenewal', model.isSubscribedUser.subscription.renewal == 'MANUAL' ? false : true);
        controller.set('status', model.isSubscribedUser.subscription.status);
      } else {
        controller.set('isSubscribedUser', false);
      }

      hello.init({
        instagram: _environment.default.instagramClientId
      }, {
        redirect_uri: _environment.default.instagram_redirect_uri,
        oauth_proxy: _environment.default.instagram_oauth_proxy
      });

      var emailFrequencyTypes = model.emailFrequencyTypes;
      var emailFrequencyTypesShort = [];

      if (emailFrequencyTypes) {
        Ember.$.each(emailFrequencyTypes, function (idx, emailType) {
          if (emailType.name != "WEEKLY" && emailType.name != "DAILY") {
            emailFrequencyTypesShort.pushObject(emailType);
          }
        });
      }

      controller.set('emailFrequencyTypesShort', emailFrequencyTypesShort);

      //set default first journal as the current journal
      var journal = model.journals.content[0] ? model.journals.content[0] : null;
      if (journal) {
        this.controllerFor("main").set('currentJournal', journal);
        controller.set('journalId', journal.id);
      }

      // Get non-owned followings
    },

    actions: {
      error: function error(_error, transition) {
        console.log(_error);
        Sentry.captureException(_error, {
          tags: {
            section: "Account-Routes"
          }
        });
        this.replaceWith('/not-found');
      },


      edit: function edit(journal, child) {
        this.send("gotoTopOfPage");
        this.transitionTo('main.children.edit', journal.id, child.id);
      },

      editPet: function editPet(journal, pet) {
        this.send("gotoTopOfPage");
        this.transitionTo('main.pets.edit', journal.id, pet.id);
      }

    }
  });
});