define('tinybeans-frontend/components/main/payment-details/edit-card', ['exports', 'tinybeans-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    renewChecked: false,

    didInsertElement: function didInsertElement() {
      this.$('#details').animate({ width: '100%', height: '50%' });
      Ember.$('#existingCardForm [data-numeric]').payment('restrictNumeric');
      Ember.$('#existingCardForm .cc-number').payment('formatCardNumber');
      Ember.$('#existingCardForm .cc-cvc').payment('formatCardCVC');

      Ember.$('#existingCardForm .cc-number').on('input', function () {
        Ember.$("#fancy-cc-number").val(Ember.$(this).val());
        // Trigger the "change" event manually
        var evt = document.createEvent('HTMLEvents');
        evt.initEvent('keyup', false, true);
        document.getElementById('fancy-cc-number').dispatchEvent(evt);
      });

      Ember.$('.cc-month, .cc-year').on('input', function () {
        // Set the value of a hidden input field for Card
        Ember.$('#fancy-cc-expiry').val(Ember.$('.cc-month').val() + '/' + Ember.$('.cc-year').val());
        // Trigger the "change" event manually
        var evt = document.createEvent('HTMLEvents');
        evt.initEvent('change', false, true);
        document.getElementById('fancy-cc-expiry').dispatchEvent(evt);
      });

      $('#existingCardForm').card({
        // a selector or DOM element for the container
        // where you want the card to appear
        container: '.card-wrapper',
        formSelectors: {
          numberInput: 'input#fancy-cc-number',
          expiryInput: 'input#fancy-cc-expiry',
          cvcInput: 'input.cc-cvc'
        },
        placeholders: {
          number: '•••• •••• •••• ••••',
          name: '',
          expiry: '••/••',
          cvc: '•••'
        },
        debug: true
      });
    },


    actions: {
      submitForm: function submitForm(token) {
        var _this = this;

        var params = this.getProperties('renewChecked', 'renew', 'subscriptionId');
        var subscriptionId = params.renewChecked && params.renew ? params.subscriptionId : null;
        this.API.changeExistingCard(token, subscriptionId, function () {
          Ember.$("#existingCardForm .card-errors").html("").addClass("hide");
          var params = _this.getProperties('renew', 'renewChecked');
          if (params.renew && params.renewChecked) {
            $('#willRenew').show();
          }
          $('#notification').show('slow');
          window.setTimeout(function () {
            _this.sendAction("done");
          }, 2000);
          _this.$("*").prop('disabled', false);
        }, function (data) {
          var $form = Ember.$('#existingCardForm');
          $form.find(".card-errors").html(data.responseJSON.message).removeClass("hide");
          $form.find('button').prop('disabled', false);
          $form.find("input:visible").prop("disabled", false);
          $form.closest("#payment-details-modal").find(".progress").addClass("hide");
          $form.closest("#payment-details-modal").find("#progress .progress-bar").removeClass("active").addClass("hide");
        });
      },

      submit: function submit() {
        this.send("getStripeToken");
      },


      getStripeToken: function getStripeToken() {
        var self = this;
        var $form = Ember.$('#existingCardForm');

        var hasErrors = false;
        $form.find("input").parent().removeClass("has-error");

        if (!Ember.$.payment.validateCardNumber($form.find(".cc-number").val())) {
          $form.find(".cc-number").parent().addClass("has-error");
          hasErrors = true;
        }

        if (!Ember.$.payment.validateCardExpiry($form.find(".cc-month").val(), $form.find(".cc-year").val())) {
          $form.find(".cc-month").parent().addClass("has-error");
          $form.find(".cc-year").parent().addClass("has-error");
          hasErrors = true;
        }

        if (!Ember.$.payment.validateCardCVC($form.find(".cc-cvc").val())) {
          $form.find(".cc-cvc").parent().addClass("has-error");
          hasErrors = true;
        }

        if (hasErrors) {
          Ember.$("#card-details-modal .modal-content").effect("shake", { times: 3, distance: 10 }, 80);
          return false;
        }

        // Disable the submit button to prevent repeated clicks
        $form.find('button').prop("disabled", true);
        $form.find("input:visible").prop("disabled", true);
        $form.closest(".modal-body").find("#progress .progress-bar").addClass("active").removeClass("hide");

        Ember.run.begin(self);
        Stripe.setPublishableKey(_environment.default.stripeKey);
        Stripe.card.createToken($form, function (status, response) {
          var $form = Ember.$('#existingCardForm');
          if (response.error) {
            // Show the errors on the form
            console.log("Payment failed: " + response.error.message);
            $form.find('.card-errors').html(response.error.message).removeClass("hide");
            $form.find('button').prop('disabled', false);
            $form.find("input:visible").prop("disabled", false);
            $form.closest("#payment-details-modal").find(".progress").addClass("hide");
            $form.closest("#payment-details-modal").find("#progress .progress-bar").removeClass("active").addClass("hide");
          } else {
            // response contains id and card, which contains additional card details
            var token = response.id;
            // Insert the token into the form so it gets submitted to the server
            $form.append(Ember.$('<input type="hidden" name="stripeToken" />').val(token));
            // and submit
            self.send("submitForm", token);
          }

          Ember.run.end();
        });
      }
    }
  });
});