define('tinybeans-frontend/controllers/main/gift-card/redeem-gift-card', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _baseController, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    error: {},
    submitting: false,
    maxAttempt: false,
    disableFunc: false,
    init: function init() {
      this._super.apply(this, arguments);
      sessionStorage.setItem('redeemAttempts', 0);
    },

    saveButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? 'Please wait ...' : 'Redeem gift card';
    }.property('submitting'),

    actions: {
      redeemGiftCard: function redeemGiftCard() {
        var _this = this;

        var redeemAttempts = sessionStorage.getItem('redeemAttempts');
        if (redeemAttempts >= 20) {
          this.set('maxAttempt', true);
        } else {
          sessionStorage.setItem('redeemAttempts', parseInt(redeemAttempts) + 1);
          var giftCode = this.get('model.giftCode');
          this.set('submitting', true);
          this.API.redeemGiftCard(giftCode, function () {
            _this.set('error.gift', false);
            _this.set('submitting', false);
            _this.transitionToRoute('main.gift-card.redeem-gift-card-success');
          }, function () {
            _this.set('error.gift', true);
            _this.set('submitting', false);
            //self.onApiError(response);
          });
        }
      },
      goBack: function goBack() {
        window.history.back();
      }
    }
  });
});