define('tinybeans-frontend/adapters/completed-checklist-item', ['exports', 'tinybeans-frontend/adapters/application', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _application, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_dataAdapterMixin.default, {
    query: function query(store, type, _query) {
      var url = _query.journalId ? '/api/1/journals/' + _query.journalId + '/checklistItems/since/0' : _query.childId ? '/api/1/children/' + _query.childId + '/checklistItems/since/0' : '';
      return this.ajax(url, 'GET', {});
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      var data = this.serialize(snapshot);
      var childId = snapshot.adapterOptions.childId;
      var url = '/api/1/children/' + childId + '/checklistItems';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('REST').POST(url, data, resolve, reject);
      });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this2 = this;

      var childId = snapshot.adapterOptions.childId;
      var itemId = snapshot.record.get('checklistItemId');
      var url = '/api/1/children/' + childId + '/checklistItems/' + itemId + '/uncomplete';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('REST').POST(url, {}, resolve, reject);
      });
    }
  });
});