define('tinybeans-frontend/serializers/height', ['exports', 'tinybeans-frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      delete payload.status; // Status messes up the JSON parser
      if (!payload.heights) {
        payload.heights = [];
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});