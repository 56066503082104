define('tinybeans-frontend/routes/main/children/list', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Child.category,
    screen: _tracker.Child.list.screen,

    model: function model(params) {
      var journalId = params.journal_id;
      return Ember.RSVP.hash({
        journal: this.store.find('journal', journalId),
        followings: this.modelFor('main').followings
      });
    },


    actions: {
      edit: function edit(journal, child) {
        this.send("gotoTopOfPage");
        this.transitionTo('main.children.edit', journal.id, child.id);
      },

      editPet: function editPet(journal, pet) {
        this.send("gotoTopOfPage");
        this.transitionTo('main.pets.edit', journal.id, pet.id);
      },

      add: function add(journal) {
        this.send("gotoTopOfPage");
        this.transitionTo('main.children.add', journal.id);
      },

      addPet: function addPet(journal) {
        this.send("gotoTopOfPage");
        this.transitionTo('main.pets.add', journal.id);
      },

      done: function done(journal) {
        this.send("gotoTopOfPage");
        this.transitionTo('main.journals.view', journal.id);
      }
    }
  });
});