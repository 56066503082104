define('tinybeans-frontend/mixins/commons-mixin', ['exports', 'tinybeans-frontend/mixins/api-mixin', 'tinybeans-frontend/mixins/lookup-tables', 'lodash/lodash'], function (exports, _apiMixin, _lookupTables, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_apiMixin.default, _lookupTables.default, {
    i18n: Ember.inject.service(),

    routing: Ember.inject.service('-routing'),

    currentRouteName: Ember.computed.reads('routing.currentRouteName'),

    isLoggedOutInDayView: Ember.computed('currentRouteName', 'session.isAuthenticated', function () {
      var authenticated = this.get('session.isAuthenticated');
      var route = this.get('currentRouteName') || '';
      return route === 'main.entries.view' && !authenticated;
    }),

    isMobile: Ember.computed(function () {
      return window.isMobile;
    }),

    isEnv: Ember.computed(function () {
      var url = window.location.host;
      var env = "";
      if (url == "hinata.tinybeans.com") {
        env = "staging";
      } else if (url == "tinybeans.com") {
        env = "production";
      } else if (url == "localbeans.com") {
        env = "local";
      }
      return env;
    }),

    // shouldShowAds: Ember.computed('model.me', 'model.journal', function() {
    //   if(this.get('model.me.hasMemoriesAccess')){
    //     if(this.get('model.journal.isUpgraded')){
    //         return false;
    //     }else{
    //       return true;
    //     }
    //   } else {
    //     return true;
    //   }
    // }),

    shouldShowAds: Ember.computed('isLoggedOutInDayView', 'model.ads', function () {
      var isLoggedOutInDayView = this.get('isLoggedOutInDayView');
      var modelShouldShowAds = this.get('model.ads.ads.shouldShowAds');
      if (isLoggedOutInDayView || modelShouldShowAds) {
        console.log("Show Ads");
        return true;
      } else {
        console.log("Don't show ads.");
        return false;
      }
    }),

    checkPremium: Ember.computed('model.me', function () {
      return this.get('model.me.hasMemoriesAccess');
    }),

    meAsFollower: Ember.computed('model.followings', function () {
      var _this = this;

      if (!this.get('model.followings')) {
        return null;
      }
      return _lodash.default.find(this.get('model.followings').toArray(), function (following) {
        return following.get('journal.id') === _this.get('model.journal.id');
      });
    }),

    isMyJournal: Ember.computed('model.journal', function () {
      return this.get('model.me') && this.get('model.me.id') === this.get('model.journal.user.id');
    }),

    hasAccessToJournal: Ember.computed('model.followings', 'model.journal', function () {
      return this.get('isMyJournal') || this.get('meAsFollower');
    }),

    canManage: Ember.computed('model.journal', function () {
      return this.get('model.me') && (this.get('model.journal.user.id') === this.get('model.me.id') || this.get('meAsFollower') && this.get('meAsFollower.coOwner'));
    }),

    nicerError: Ember.computed('error', 'error.global', function () {
      if (this.get('error.global') === 'The action you are invoking does not exist' || this.get('error.global') === 'Please check the data you have entered and try again') {
        if (this.get('me')) {
          return "You do not have permission to do this. Are you logged in as the correct user for this journal?";
        } else {
          return "Please login first";
        }
      } else {
        return this.get('error.global');
      }
    }),

    canShare: Ember.computed(function () {
      return this.get('model.me') && (this.get('model.journal.user.id') == this.get('model.me.id') || this.get('meAsFollower.coOwner'));
    }),

    canViewMilestones: Ember.computed('isMyJournal', 'meAsFollower', function () {
      return this.get('isMyJournal') || this.get('meAsFollower.viewMilestones');
    }),

    canEditMilestones: Ember.computed('isMyJournal', 'meAsFollower', function () {
      return this.get('isMyJournal') || this.get('meAsFollower.editMilestones');
    }),

    canAddMoments: Ember.computed('isMyJournal', 'meAsFollower', function () {
      return this.get('isMyJournal') || this.get('meAsFollower.addEntries');
    }),

    canViewEntries: Ember.computed('isMyJournal', 'meAsFollower', function () {
      return this.get('isMyJournal') || this.get('meAsFollower.viewEntries');
    }),

    canAddEmotions: Ember.computed('isMyJournal', 'meAsFollower', function () {
      return this.get('isMyJournal') || this.get('meAsFollower');
    }),

    canAddComments: Ember.computed('isMyJournal', 'model.entry', function () {
      return this.get('isMyJournal') || !this.get('model.entry.journal.disableComments');
    }),

    canExport: Ember.computed('model.journal', 'model.me', function () {
      return this.get('model.me') && (this.get('model.journal.user.id') === this.get('model.me.id') || this.get('meAsFollower') && this.get('meAsFollower.coOwner'));
    }),

    usersDateFormat: Ember.computed('model.me.dateStyle.format', function () {
      return this.globals.usersDateFormat(this.get('model.me.dateFormat'));
    }),

    userMeasurementSystem: Ember.computed('property', function () {
      return this.get('model.me.measurementSystem');
    }),

    onboardingCompleted: Ember.computed('model.entries', 'model.journal', 'model.followers', function () {
      return this.get('hasFollwers') && this.get('hasChildren') && this.get('hasPets') && this.get('hasEntries');
    }),

    hasFollwers: Ember.computed.gt('model.followers.length', 1),
    hasChildren: Ember.computed.gt('model.journal.children.length', 0),
    hasPets: Ember.computed.gt('model.journal.pets.length', 0),
    hasEntries: Ember.computed.gt('model.numEntries.numEntries', 0),
    hasOwnJournal: Ember.computed.gt('model.myJournals.length', 0),

    t: function t(str) {
      return this.get('i18n').t(str).string;
    }
  });
});