define('tinybeans-frontend/controllers/main/pets/channel', ['exports', 'tinybeans-frontend/controllers/base-controller', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseController, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    queryParams: ['setup'],

    subscribedChannelArticles: Ember.computed('property', 'model.relatedArticles', function () {
      var articles = this.get('model.relatedArticles');
      _lodash.default.each(articles, function (article) {
        if (!article.deleted) {
          if (article.content && article.content.length > 100) {
            article.intro = article.content.substring(0, 100) + '...';
          }
        }
      });
      return _lodash.default.filter(articles, function (article) {
        return !article.deleted;
      });
    }),

    actions: {
      trackOpenArticle: function trackOpenArticle() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Pet.category,
          screen: _tracker.Pet.screen,
          action: _tracker.Pet.openRecommendedArticle
        });
      }
    }
  });
});