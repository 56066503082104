define('tinybeans-frontend/controllers/reset-password-request', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/navigation'], function (exports, _baseController, _navigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_navigation.default, {

    actions: {
      reset: function reset() {
        var _this = this;

        var emailAddress = this.get('emailAddress');
        if (emailAddress && validateEmail(emailAddress)) {
          this.API.resetPasswordRequest(emailAddress, function () {
            _this.transitionToRoute('login-instructions');
          }, function (error) {
            _this.set('error', error.responseJSON.message);
          });
        } else {
          this.set('error', 'Please insert a valid email address');
        }
      }
    }

  });
});