define('tinybeans-frontend/controllers/main/journals/invite', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/tracker'], function (exports, _baseController, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    i18n: Ember.inject.service(),

    isActiveNavJournals: function isActiveNavJournals() {
      return true;
    },

    submitting: false,

    submitButtonLabel: function () {
      return this.get("i18n").t("Journal.Follower.Add_To_Journal");
    }.property('submitting'),

    fullAccessGranted: function () {
      if (this.get('invite.coOwner')) {
        // Check and disable all other boxes
        this.set('invite.addEntries', true);
        Ember.$('#addEntries').prop('disabled', true);

        this.set('invite.editMilestones', true);
        Ember.$('#editMilestones').prop('disabled', true);

        this.set('invite.viewMilestones', true);
        Ember.$('#viewMilestones').prop('disabled', true);
      } else {
        // Enable all other boxes (except view milestones, that's edit milestones responsibility)
        Ember.$('#addEntries').prop('disabled', false);
        Ember.$('#editMilestones').prop('disabled', false);
      }
    }.observes('invite.coOwner'),

    editMilestonesGranted: function () {
      if (this.get('invite.editMilestones')) {
        this.set('invite.viewMilestones', true);
        Ember.$('#viewMilestones').prop('disabled', true);
      } else {
        Ember.$('#viewMilestones').prop('disabled', false);
      }
    }.observes('invite.editMilestones'),

    actions: {
      selectRelation: function selectRelation() {
        var selectBox = Ember.$("#relationship")[0];
        var selectedIndex = selectBox.selectedIndex;
        var options = Ember.$("#relationship option");
        var selectedValue = options[selectedIndex].value;

        this.set('model.invite.relationship', selectedValue);
      },

      send: function send() {
        var self = this;
        self.set('error', undefined);
        // Make sure the relationship field is checked.

        var invite = self.model.invite;

        var relationshipValid = false;
        var firstNameValid = false;
        var lastNameValid = false;
        var emailAddressValid = false;

        if (!invite.firstName) {
          self.set('error', { firstName: 'Please fill in first name' });
        } else {
          firstNameValid = true;
        }

        if (!invite.lastName) {
          self.set('error', { lastName: 'Please fill in last name' });
        } else {
          lastNameValid = true;
        }

        if (!invite.emailAddress) {
          self.set('error', { emailAddress: 'Please fill in email address' });
        } else {
          emailAddressValid = true;
        }

        if (!invite.relationship) {
          self.set('error', { relationship: 'Please select relationship' });
        } else {
          relationshipValid = true;
        }

        if (relationshipValid && firstNameValid && lastNameValid && emailAddressValid) {
          self.set('submitting', true);
        } else {
          self.set('submitting', false);
          return;
        }

        var followerPermission = Ember.$('input:radio[name=followerPermission]:checked').val();

        if (followerPermission == "coOwner") {

          invite.coOwner = true;
          invite.viewMilestones = true;
          invite.viewEntries = true;
          invite.addEntries = true;
          invite.editMilestones = true;
        } else if (followerPermission == "normalFollower") {

          invite.coOwner = false;
          invite.viewMilestones = true;
          invite.viewEntries = true;
          invite.addEntries = false;
          invite.editMilestones = false;
        } else {
          invite.coOwner = false;
          invite.viewMilestones = true;
          invite.viewEntries = true;
          invite.addEntries = false;
          invite.editMilestones = false;
        }

        this.API.inviteJournalFollower(self.model.journalId, invite, function () {
          self.set('submitting', false);
          self.send("reloadApplicationRoute");
          // self.flash.set('Invite sent successfully');
          Ember.run.next(function () {
            self.transitionToRoute('main.journals.follower-list', self.get('model.journalId'));
          });
          _tracker.Tracker.trackEvent({
            category: _tracker.Followers.category,
            action: _tracker.Followers.invite.saveButton,
            screen: _tracker.Followers.invite.screen
          });
        }, function (xhr, statusText, e) {
          self.set('submitting', false);
          self.onApiError(xhr, statusText, e);
        });
      },

      cancel: function cancel() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Followers.category,
          action: _tracker.Followers.invite.cancelButton,
          screen: _tracker.Followers.invite.screen
        });
        var journalId = this.get('model.journalId');
        this.transitionToRoute('main.journals.follower-list', journalId);
      },

      expandMessage: function expandMessage() {
        this.set('showMessage', true);
      }

    }
  });
});