define('tinybeans-frontend/components/main/payment-details/show-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      toggleChangeCard: function toggleChangeCard() {
        this.sendAction('toggleChangeCard');
      }
    }
  });
});