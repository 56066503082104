define('tinybeans-frontend/components/progress-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    progressCompleted: 0,
    percentageCompletedWidthStyle: 'width: 0%;',

    init: function init() {
      this._super.apply(this, arguments);
      this.get('globals'); // Service is lazy loaded, this ensures it's loaded for observers
    },

    progressObserver: function () {
      var currentFilePercentage = this.get('globals.uploadedPercentage') / 100;
      var filesUploaded = this.get('globals.fileNumber') + currentFilePercentage;
      var totalFiles = this.get('globals.totalFiles');
      var totalPercentage = 100 * filesUploaded / totalFiles;
      this.set('progressCompleted', totalPercentage);
      this.set('percentageCompletedWidthStyle', 'width: ' + totalPercentage + '%');
      this.set('showProgressBar', this.get('globals.showProgressBar'));
    }.observes('globals.uploadedPercentage', 'globals.fileNumber', 'globals.totalFiles'),

    progressStatusText: function () {
      return this.get('progressText') ? this.get('progressText') : '';
    }.property('globals.progressText')
  });
});