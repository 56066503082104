define('tinybeans-frontend/controllers/main/onboarding/step-two', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    queryParams: ['setup'],

    isActiveNavJournals: function isActiveNavJournals() {
      return true;
    },

    actions: {

      clickTrack: function clickTrack(itemClass) {
        var eventLabel = itemClass ? itemClass : "na";
        this.GTM.trackEvent({
          'eventCategory': 'onboarding-pet-first',
          'eventAction': "step-two-click-steps-indicator",
          'eventLabel': eventLabel
        });
      },

      onSave: function onSave() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.step2.screen,
          action: _tracker.Onboarding.step2.saveButton
        });
      },

      onSkip: function onSkip() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.step2.screen,
          action: _tracker.Onboarding.step2.skipButton
        });
      }
    }
  });
});