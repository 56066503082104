define('tinybeans-frontend/models/child', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    fullName: (0, _attr.default)('string'),
    gender: (0, _attr.default)('string'),
    dob: (0, _attr.default)('apidate'),
    deleted: (0, _attr.default)('boolean'),
    bio: (0, _attr.default)('string'),
    timestamp: (0, _attr.default)('number'),
    lastUpdatedTimestamp: (0, _attr.default)('number'),
    user: (0, _relationships.belongsTo)('user', { async: false }),
    avatars: (0, _attr.default)(), // belongsTo('blobs') makes the blobs object shared among all children!
    completedChecklistItems: (0, _relationships.hasMany)('completed-checklist-item', { async: true }),

    limitedFirstName: Ember.computed('firstName', function () {
      return this.get('firstName').length > 23 ? this.get('firstName').substring(0, 15) + "..." : this.get('firstName');
    }),

    isBorn: Ember.computed('dob', function () {
      return moment() > moment(this.get('dob'), 'YYYY-MM-DD');
    }),

    ageThisMonth: Ember.computed('dob', function () {
      var dob = moment(this.get('dob'), 'YYYY-MM-DD');
      var now = moment();
      var diff = now.diff(dob);
      if (diff > 0 && diff / 3600000 <= 24) {
        return 'New Arrival 🎉 😍';
      }
      return now > dob ? moment.preciseDiff(dob, now, false, 'DAY') : 'Not born Yet';
    }),

    ageInMonthsOnly: Ember.computed('property', function () {
      return moment.preciseDiff(moment(this.get('dob'), 'YYYY-MM-DD'), moment(), false, 'MONTH');
    })
  });
});