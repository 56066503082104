define("tinybeans-frontend/templates/main/children/channel", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 10
              },
              "end": {
                "line": 52,
                "column": 10
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/children/channel.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "mosaic-item-container");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "mosaicflow__item");
            var el3 = dom.createTextNode("\n\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "target", "_blank");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4, "class", "img-responsive");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2, "class", "text-left");
            dom.setAttribute(el2, "style", "padding-top:10px;");
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "target", "_blank");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2, "class", "text-left");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1, 1]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element0, [3, 0]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element1, 'href');
            morphs[1] = dom.createElementMorph(element1);
            morphs[2] = dom.createAttrMorph(element2, 'src');
            morphs[3] = dom.createAttrMorph(element3, 'href');
            morphs[4] = dom.createMorphAt(element3, 0, 0);
            morphs[5] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "href", ["get", "subscribedChannelArticle.contentUrl", ["loc", [null, [45, 26], [45, 61]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["trackOpenArticle"], [], ["loc", [null, [45, 80], [45, 109]]], 0, 0], ["attribute", "src", ["get", "subscribedChannelArticle.thumbnailImageUrl", ["loc", [null, [46, 29], [46, 71]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "href", ["get", "subscribedChannelArticle.contentUrl", ["loc", [null, [49, 87], [49, 122]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "subscribedChannelArticle.title", ["loc", [null, [49, 125], [49, 159]]], 0, 0, 0, 0], ["content", "subscribedChannelArticle.intro", ["loc", [null, [50, 35], [50, 69]]], 0, 0, 0, 0]],
          locals: ["subscribedChannelArticle"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 6
            },
            "end": {
              "line": 54,
              "column": 6
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/children/channel.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "mosaicflow");
          dom.setAttribute(el1, "data-item-height-calculation", "attribute");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "subscribedChannelArticles", ["loc", [null, [41, 18], [41, 43]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [41, 10], [52, 19]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/children/channel.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n  .child-channels {\n    background: #f2f7fa;\n  }\n\n  .content-container {\n    background: #f2f7fa;\n  }\n\n  .childShow {\n    background: #fff;\n  }\n\n  #footer .spacer {\n    display: none;\n  }\n\n  .mosaic-item-container {\n    background: #fff;\n    padding: 10px;\n    margin: 10px 5px;\n    border: 1px solid #e8e8e8;\n    border-radius: 2px;\n    -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);\n    -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);\n    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "child-channels");
        dom.setAttribute(el1, "class", "child-channels");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row text-center");
        dom.setAttribute(el3, "style", "padding-top:20px;");
        var el4 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("<h2>Your channel</h2>");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("<p>Recommended articles and tips to help your child grow</p>");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4, 1, 1]), 5, 5);
        return morphs;
      },
      statements: [["inline", "main/children/milestones/child-summary", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [30, 47], [30, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "channelTabClass", "active"], ["loc", [null, [30, 0], [30, 79]]], 0, 0], ["block", "main/children/milestones/channel-articles", [], [], 0, null, ["loc", [null, [39, 6], [54, 52]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});