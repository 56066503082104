define('tinybeans-frontend/helpers/more-than', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.moreThan = moreThan;
  function moreThan(params) {
    // Convert both sides to Number as JSON params are strings and we might compare to a number
    return Number(params[0]) > Number(params[1]);
  }

  exports.default = Ember.Helper.helper(moreThan);
});