define('tinybeans-frontend/routes/main/photo-frame/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      campaign: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var newUrl = 'https://' + window.location.host + '/photo-frame/' + params.campaign;
      window.location = newUrl;
    }
  });
});