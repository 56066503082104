define('tinybeans-frontend/controllers/main/children/milestones/checklist', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash'], function (exports, _baseController, _commonsMixin, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = _baseController.default.extend(_commonsMixin.default, {
        currentChecklist: null,
        displayModal: false,
        selectedCategory: "16",

        checklistCategories: Ember.computed('model.channelSubscriptions', 'selectedCategory', function () {
            var _this = this;

            var allChecklists = [];
            _lodash.default.each(this.get('model.channelSubscriptions').toArray(), function (subscription) {
                var checklists = subscription.get('channel.checklists').toArray();
                checklists = _lodash.default.filter(checklists, function (checklist) {
                    return !checklist.get('deleted');
                });
                checklists = _lodash.default.map(checklists, function (checklist) {
                    checklist.set('iconClass', checklist.id === _this.get('selectedCategory') ? "active cursor-pointer milestone-icon-" + checklist.id : "cursor-pointer milestone-icon-" + checklist.id);
                    checklist.set('iconId', "checklist-" + checklist.id);

                    if (checklist.id == _this.get('selectedCategory')) {
                        checklist.set('themeStyle', "height:20px; background:" + checklist.get('themeColorString'));
                        checklist.set('titleStyle', "color:" + checklist.get('themeColorString'));
                        checklist.set('themeColorString', checklist.get('themeColorString'));
                        // checklist.set('showDateRange', subscription.get('showDateRange'));
                        _this.set("currentChecklistThemeColor", checklist.get('themeColorString'));
                        _this.set("currentChecklist", checklist);
                    }
                    return checklist;
                });
                allChecklists.push.apply(allChecklists, _toConsumableArray(checklists));
            });

            return allChecklists.sort(function (a, b) {
                return a.get('sortOrder') - b.get('sortOrder');
            });
        }),

        currentChecklistItems: Ember.computed('model.completedChecklistItems', 'currentChecklist', 'currentChecklistItems.@each', function () {
            var _this2 = this;

            var currentChecklist = this.get('currentChecklist');
            var currentChecklistItems = currentChecklist.get('checklistItems').toArray();
            currentChecklistItems = _lodash.default.map(currentChecklistItems, function (item) {
                var ageGroup = void 0,
                    ageRange = void 0;
                if (item.get('perc10') > 84) {
                    ageGroup = 'over-7-years';
                    ageRange = '';
                } else if (item.get('perc10') < 24) {
                    ageGroup = item.get('perc10') + '-' + item.get('perc90') + '-months';
                    ageRange = item.get('perc10') + ' - ' + item.get('perc90') + ' MONTHS';
                } else {
                    ageGroup = Number(item.get('perc10')) / 12 + '-' + Number(item.get('perc90')) / 12 + '-years';
                    ageRange = Number(item.get('perc10')) / 12 + ' - ' + Number(item.get('perc90')) / 12 + ' YEARS';
                }
                item.set('ageRangeClass', 'row category-title ageRangeClass-' + (currentChecklist.showDateRange ? ageGroup : 999));
                item.set('ageRange', ageRange);
                item.set('completed', false);
                item.set('completionDate', null);
                _lodash.default.each(_this2.get('model.completedChecklistItems').toArray(), function (completedItem) {
                    if (completedItem.get('checklistItemId') == item.id && !completedItem.get('deleted')) {
                        item.set('completed', true);
                        var year = Number(completedItem.get('year'));
                        var month = Number(completedItem.get('month'));
                        var day = Number(completedItem.get('day'));
                        if (year === 0 || month === 0 || day === 0) {
                            item.set('completionDate', 'Completed date unsure');
                        } else {
                            var completionDate = new Date(year, month - 1, day);
                            item.set('completionDate', 'Completed on ' + moment(completionDate).format(_this2.get('usersDateFormat')));
                        }
                        return false; // stop _.each
                    }
                });
                return item;
            });
            currentChecklistItems = currentChecklistItems.sort(function (a, b) {
                return b.get('perc10') - a.get('perc10');
            }).reverse();
            var itemGroups = _lodash.default.values(_lodash.default.groupBy(currentChecklistItems, function (item) {
                return item.get('ageRange');
            }));

            return _lodash.default.map(itemGroups, function (itemGroup) {
                return {
                    ageRange: itemGroup[0].ageRange,
                    percentage: _this2.calculatePercentage(itemGroup),
                    items: itemGroup
                };
            });
        }),

        descriptionWithLineBreaks: Ember.computed('currentlySelectedMilestone', function () {
            var milestone = this.get('currentlySelectedMilestone');
            var safelyEscapedCaption = Ember.Handlebars.Utils.escapeExpression(milestone.get('description'));
            var linebrokenCaption = safelyEscapedCaption.replace(/(?:\r\n|\r|\n)/g, '<br />');
            return new Ember.Handlebars.SafeString(linebrokenCaption);
        }),

        progressBarStyle: function progressBarStyle(percentage) {
            return 'width:' + percentage + '%';
        },
        calculatePercentage: function calculatePercentage(items) {
            var numCompleted = 0; // the current one has been ticked
            _lodash.default.each(items, function (item) {
                numCompleted += item.get('completed') ? 1 : 0;
            });
            return (numCompleted * 100 / items.length).toFixed(0);
        },


        actions: {
            closeModalDialog: function closeModalDialog() {
                Ember.$('.modal').modal('hide');
                Ember.$(".modal-backdrop").delay(200).fadeOut(200);
                this.set('displayModal', false);
            },
            tickMilestone: function tickMilestone(checklistItem, itemGroup) {
                var _this3 = this;

                var child = this.get('model.child');
                var currentChecklistThemeColor = this.get("currentChecklistThemeColor");
                var currentChecklist = this.get("currentChecklist");

                this.set("currentMilestoneCompleted", Ember.$("div[data-milestone-item='" + checklistItem.id + "'] .completed").is(':visible'));
                checklistItem.set('themeStyle', 'line-height:20px; border-left:15px solid ' + currentChecklistThemeColor);
                checklistItem.set('milestoneDialogTitle', child.get('firstName') + '\'s Milestone');
                checklistItem.set('checklistTitle', 'Age');

                var replaceChildProperties = function replaceChildProperties(str) {
                    if (str) {
                        for (var propertyName in child) {
                            var _search = new RegExp('{' + propertyName + '}', 'gi');
                            var _replace = child[propertyName];
                            str = str.replace(_search, _replace);
                        }
                        // Also replace {month}
                        var search = new RegExp('{month}', 'gi');
                        var replace = _this3.get('ageInMonthsOnly');
                        str = str.replace(search, replace);
                    }
                    return str;
                };

                var processRelatedArticles = function processRelatedArticles(relatedArticles) {
                    return _lodash.default.map(relatedArticles, function (relatedArticle) {
                        var article = relatedArticle.get('article');
                        if (article) {
                            article.title = replaceChildProperties(article.title);
                            article.content = replaceChildProperties(article.content);
                            article.contentUrl = replaceChildProperties(article.contentUrl);
                        }
                        return relatedArticle;
                    });
                };
                if (checklistItem.get('relatedArticles')) {
                    var valid = _lodash.default.any(checklistItem.get('relatedArticles').toArray(), function (article) {
                        return !article.get('article.deleted') || article.get('article.deleted') === false;
                    });
                    checklistItem.set('validRelatedArticles', valid);
                    var checkListItemsArr = checklistItem.get('relatedArticles').toArray();
                    var sortedCheckListItems = checkListItemsArr.sort(function (a, b) {
                        return b.get('sortOrder') - a.get('sortOrder');
                    });

                    checklistItem.set('relatedArticles', processRelatedArticles(sortedCheckListItems));
                }

                this.set('currentlySelectedMilestone', checklistItem);
                this.set('currentGroup', itemGroup);
                this.set('displayModal', true);
            },


            loadChecklist: function loadChecklist(categoryId) {
                Ember.$('.spin').show();
                this.set('selectedCategory', categoryId);

                Ember.$('.spin').fadeOut();
            },

            saveMilestone: function saveMilestone(date) {
                var _this4 = this;

                var completeDate = date ? 'Completed on ' + moment(date).format(this.get('usersDateFormat')) : 'Completed date unsure';
                var selectedChecklistItem = this.get('currentlySelectedMilestone');
                var child = this.get('model.child');
                var completedItem = this.store.createRecord('completedChecklistItem', {
                    day: date ? date.date() : null,
                    month: date ? date.month() + 1 : null,
                    year: date ? date.year() : null,
                    checklistItemId: selectedChecklistItem.id
                });

                if (this.get('canEditMilestones')) {
                    completedItem.save({
                        adapterOptions: {
                            childId: child.id
                        }
                    }).then(function () {
                        Ember.$(".small-spinner").hide();
                        selectedChecklistItem.set('completionDate', completeDate);
                        _this4.set("currentlySelectedMilestone", selectedChecklistItem);
                        _this4.set("currentMilestoneCompleted", true);

                        Ember.$("div[data-milestone-item='" + selectedChecklistItem.id + "'] .completed").show();
                        Ember.$("div[data-milestone-item='" + selectedChecklistItem.id + "'] .notCompleted").hide();
                        var completedChecklistItems = _this4.get('model.completedChecklistItems').toArray();
                        completedChecklistItems.push(completedItem);
                        _this4.set('model.completedChecklistItems', completedChecklistItems);

                        _this4.send('closeModalDialog');
                    }, function () {
                        _this4.send('closeModalDialog');
                    });
                }
            },
            removeMilestone: function removeMilestone() {
                var _this5 = this;

                Ember.$(".small-spinner").show();
                Ember.$(".milestone-dialog-inner .completed").hide();
                var checklistItem = this.get('currentlySelectedMilestone');
                var completedItem = _lodash.default.find(this.get('model.completedChecklistItems').toArray(), function (item) {
                    return _this5.get('currentlySelectedMilestone.id') == item.get('checklistItemId');
                });
                completedItem.destroyRecord({
                    adapterOptions: {
                        childId: this.get('model.child.id')
                    }
                }).then(function () {
                    Ember.$(".small-spinner").hide();
                    _this5.set("currentMilestoneCompleted", false);
                    Ember.$("div[data-milestone-item='" + checklistItem.id + "'] .completed").hide();
                    Ember.$("div[data-milestone-item='" + checklistItem.id + "'] .notCompleted").show();
                    Ember.$("div[data-milestone-item='" + checklistItem.id + "'] .milestone-completion-date").text("");

                    _this5.send('closeModalDialog');
                    var completedChecklistItems = _this5.get('model.completedChecklistItems').toArray();
                    _lodash.default.remove(completedChecklistItems, completedItem);
                    _this5.set('model.completedChecklistItems', completedChecklistItems);
                });
            }
        }
    });
});