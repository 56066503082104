define('tinybeans-frontend/components/google-publisher-tag-with-targeting', ['exports', 'ember-google-publisher-tags/components/google-publisher-tag', 'tinybeans-frontend/config/environment'], function (exports, _googlePublisherTag, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      get = _Ember.get;
  exports.default = _googlePublisherTag.default.extend({
    tracing: false,
    addTargeting: function addTargeting() {
      this.set('targeting', {
        'env': _environment.default.adsEnvironment
      });

      var slot = get(this, 'slot');
      var googletag = window.googletag;
      var adsInViews = window.adsInViews || [];

      if (slot && googletag && googletag.cmd) {
        // console.log('------ OLD slot passed to amz ------');
        // console.info(slot);
        googletag.cmd.push(function () {
          window.apstag.fetchBids({
            slots: [slot],
            timeout: 2000
          },
          /* jshint unused:false */
          function (bids) {
            window.apstag.setDisplayBids();
            var curSlotName = slot.getSlotId().getName();

            if (adsInViews.length === 0) {
              adsInViews.push(slot);
            } else {
              var ids = adsInViews.map(function (e) {
                return e.getSlotId().getName();
              });
              //console.log("IDS__" + ids);
              if (ids.indexOf(curSlotName) === -1) {
                adsInViews.push(slot);
              }
            }
          });
        });
      }
    }
  });
});