define('tinybeans-frontend/routes/main/subscription/manage', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash'], function (exports, _baseProtectedRoute, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  Ember.RSVP.on('error', function (error) {
    Ember.Logger.assert(false, error);
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model() {

      var journalId = this.modelFor('main').currentJournal ? this.modelFor('main').currentJournal.id : NaN;
      if (null === journalId || NaN === journalId) journalid = this.modelFor('main').defaultJournal;
      var journals = this.modelFor('main').journals;

      return Ember.RSVP.hash({
        journalId: journalId,
        journals: journals,
        followers: this.API.getEligibleFollowers(),
        subscriptionForJournal: this.API.getSubscriptionForJournal(journalId),
        hasSeatsRemaining: this.getSeatsRemaining(this.API.hasSubscriptionSeat()),
        subscriptions: this.API.getActiveAndUpcomingSubscriptions(),
        me: this.store.find('user', 'me'),
        seatInfo: this.API.getEligibleFollowers(),
        seated: Array,
        languages: navigator.languages
      });
    },


    actions: {},

    afterModel: function afterModel(model) {
      if (model.journals) {
        model.journal = model.journals.toArray()[0];
      }
      if ('undefined' !== typeof model.hasSeatsRemaining) {
        if ('error' !== model.hasSeatsRemaining.status && parseInt(model.hasSeatsRemaining.seatsLeft) > 0) {
          // console.log("Model: Seats Remain.")
          model.hasSeatsRemaining = true;
        } else {
          // console.log(model.hasSeatsRemaining);
          // console.log("Model: Seats Do Not Remain.")
          model.hasSeatsRemaining = false;
        }
      } else {
        model.hasSeatsRemaining = false;
      }
    },

    setupController: function setupController(controller, model, error) {
      Ember.Logger.debug(error.message);
      this._super(controller, model);

      controller.set('awaitingSeat', true);
      var language_code = model.languages[0] || 'en-US';
      controller.set('userLanguage', language_code);

      // Subscription LP Updated
      Leanplum.addVariablesChangedHandler(function () {
        var vars = Leanplum.getVariables();
        if (vars.Paywall) {
          var paywall_prices = '';
          if ('undefined' !== typeof vars.Paywall[language_code]) {
            paywall_prices = vars.Paywall[language_code];
          } else {
            paywall_prices = vars.Paywall['en-US']; // default or unhandled
          }
          var paywall_products = JSON.parse(vars.Paywall.newProducts);
          controller.set('benefits', paywall_products.benefits);

          // Includes currency
          var prices = {
            monthly: paywall_prices.monthlyPrice,
            yearly: paywall_prices.yearlyPrice
          };
          controller.set('prices', prices);
        }
      });

      if ('ok' === model.followers.status) {
        controller.set('followers', this.filterEligibleFollowers(model.followers, model.me));
        if (controller.get('deletingSeat')) {
          // deleting?
          // console.log("Deleting");
          controller.set('awaitingSeat', true);
          model.hasSeatedFollower = false;
          model.hasSeatsRemaining = true;
          model.seated = [];
          controller.set('deletingSeat', false);
        } else {
          model.seated = this.getSeatsInfo(model.followers, model.me.id);
          if (model.seated.length > 0) {
            model.hasSeatedFollower = true;
            // console.log("Seated: ", model.seated);
          }
        }
      }

      var subscriptions = model.subscriptions.subscriptions || [];
      var subscription = subscriptions.find(function (sub) {
        if (sub) {
          return sub.status === 'ACTIVE' || sub.status === 'TRIAL';
        }
        return false;
      });
      var upcomingSubscription = subscriptions.find(function (sub) {
        if (sub) {
          return sub.status === 'UPCOMING';
        }
        return false;
      });
      // Account type
      // console.log(model.subscriptionForJournal);
      controller.set('accountType', this.getAccountType(model.journals, subscription, model.subscriptionForJournal));
      // console.log("Has seated follower?", model.hasSeatedFollower);
      controller.set('hasPremium', this.hasPremium(model.journals));
      controller.set('hasFreeJournalAccess', this.hasFreeJournalAccess(model.journals));

      if (upcomingSubscription) {
        controller.set('hasUpcomingSubscription', true);
        if (null !== upcomingSubscription.cycle) {
          var planType = upcomingSubscription.cycle.calendarUnit;
          if (planType.toLowerCase().includes('year')) {
            // yearly
            controller.set('upcomingPlanType', 'yearly');
            controller.set('upcomingPlanPrice', controller.get('prices').yearly);
          } else {
            controller.set('upcomingPlanType', 'monthly');
            controller.set('upcomingPlanPrice', controller.get('prices').monthly);
          }
        }
        controller.set('upcomingStartDayText', moment(upcomingSubscription.startDateDisplay, 'DD MMM YYYY').format('MMMM DD, YYYY'));
      }

      if (subscription) {
        // console.log(model.subscriptionForJournal);
        // console.log(subscription);
        controller.set('isSubscribedUser', true);
        controller.set('expiryDate', moment(subscription.expiryDateDisplay, 'DD MMM YYYY').format('MMMM DD, YYYY'));
        if (null !== subscription.cycle) {
          var _planType = subscription.cycle.calendarUnit;
          if (_planType.toLowerCase().includes('year')) {
            // yearly
            controller.set('planType', 'Yearly');
            if (null !== subscription.paymentTransaction.amountDisplay) {
              controller.set('planPrice', subscription.paymentTransaction.amountDisplay);
            } else {
              controller.set('planPrice', controller.get('prices').yearly);
            }
          } else {
            controller.set('planType', 'Monthly');
            if (null !== subscription.paymentTransaction.amountDisplay) {
              controller.set('planPrice', subscription.paymentTransaction.amountDisplay);
            } else {
              controller.set('planPrice', controller.get('prices').monthly);
            }
          }
        } else {
          console.log("planType issue");
          controller.set('planType', 'NaN');
        }
        controller.set('autoRenewal', subscription.renewal == 'MANUAL' ? false : true);
        controller.set('status', subscription.status);
        // controller.set('followers', model.followers); //done above
      } else if (this.hasPremium(model.journals)) {
        if ('lifetime' === model.subscriptionForJournal.subscription.subscriptionProduct.name) {
          controller.set('isLifetime', true);
          controller.set('expiryDate', 'Lifetime');
        }
        controller.set('planFreq', model.subscriptionForJournal.subscription.subscriptionProduct.name);
        controller.set('expiryDate', moment(model.subscriptionForJournal.subscription.expiryDateDisplay, 'DD MMM YYYY').format('MMMM DD, YYYY'));
        controller.set('planPrice', '$' + model.subscriptionForJournal.subscription.subscriptionProduct.amountInDollarsAndCents);
        // controller.set('autoRenewal', (subscription.renewal == 'MANUAL') ? false:true);
      } else {
        controller.set('isSubscribedUser', false);
      }
      if (model.seated.length) {
        controller.set('seated', model.seated);
        controller.set('hasSeatedFollower', true);
      } else {
        controller.set('hasSeatedFollower', false);
      }
      // console.log("Model hasSeatsRemaining", model.hasSeatsRemaining);
      // console.log("Controller hasSeatsRemaining", controller.get('hasSeatsRemaining'));
      if (model.hasSeatsRemaining) {
        controller.set('hasSeatsRemaining', true);
      } else {
        controller.set('hasSeatsRemaining', false);
      }
      // shared?
      if (subscription && parseInt(model.me.id) !== parseInt(subscription.user.id)) {
        controller.set('sharedSubscription', true);
        controller.set('hasSeatedFollower', true);
        controller.set('seated', [subscription.user]);
      }
      // gifted?
      if (subscription && subscription.isGifted) {
        controller.set('giftedSubscription', true);

        var durationInDays = Math.round((subscription.expiryDate - subscription.startDate) / (1000 * 3600 * 24));

        // Convert days into months
        var months = Math.round(durationInDays / 30.44);
        var durationText = void 0;

        if (months < 1) {
          durationText = durationInDays + ' day' + (durationInDays > 1 ? 's' : '') + ' subscription';
        } else {
          durationText = months + ' month' + (months > 1 ? 's' : '') + ' subscription';
        }
        controller.set('durationText', durationText);
      }
    },

    getAccountType: function getAccountType(journals, subscription, subs) {
      if (subscription) {
        return "Tinybeans+";
      } else if (this.hasPremium(journals)) {
        if (this.hasFreeJournalAccess(journals)) {
          this.transitionTo("main.subscription-products.list", { queryParams: { isLegacy: true } });
          return;
        }
        if ('lifetime' === subs.subscription.subscriptionProduct.name) {
          return "Premium Lifetime";
        }
        return "Premium";
      } else {
        this.transitionTo("main.subscription-products.list", { queryParams: { isLegacy: false } });
      }
    },
    getSubscriptions: function getSubscriptions(journals) {
      var _this = this;

      var promises = {};
      _lodash.default.each(journals.toArray(), function (journal) {
        promises[journal.get('id')] = _this.store.query('subscription', {
          journalId: journal.get('id')
        });
      });
      return Ember.RSVP.hash(promises);
    },
    getSeatsRemaining: function getSeatsRemaining(promise) {
      return promise.then(function (data) {
        return data;
      }).catch(function (error) {
        console.log("TB+ API Error: ", error.responseText);
      });
    },
    getSeatsInfo: function getSeatsInfo(followers, me) {
      var holders = [];
      _lodash.default.each(followers.caregivers, function (follower) {
        if (parseInt(me) === parseInt(follower.seatGivenBy)) {
          holders.push(follower.user);
        }
      });
      return holders;
    },
    filterEligibleFollowers: function filterEligibleFollowers(followers, me) {
      var eligible = [];
      _lodash.default.each(followers.caregivers, function (follower) {
        if (null === follower.seatGivenBy) {
          eligible.push(follower.user);
        }
      });
      return eligible;
    },
    hasFreeJournalAccess: function hasFreeJournalAccess(journals) {
      var _this2 = this;

      var result = false;
      _lodash.default.each(journals.toArray(), function (journal) {
        if (!_this2.globals.premiumTier(journal)) {
          result = true;
          return false;
        }
      });
      return result;
    },
    hasPremium: function hasPremium(journals) {
      var _this3 = this;

      var journalStats = [];
      _lodash.default.each(journals.toArray(), function (journal) {
        journalStats.push(_this3.globals.premiumTier(journal));
      });
      return journalStats.includes(true);
    },


    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.setProperties({
          renew: undefined,
          subscriptionId: undefined
        });
      }
    }

  });
});