define('tinybeans-frontend/components/main/children/milestones/channel-articles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super();
      Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },

    afterRenderEvent: function afterRenderEvent() {
      $('.mosaicflow').mosaicflow();
    }
  });
});