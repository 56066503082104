define('tinybeans-frontend/components/site-header', ['exports', 'tinybeans-frontend/mixins/navigation', 'tinybeans-frontend/mixins/gift-guide-mixin'], function (exports, _navigation, _giftGuideMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_navigation.default, _giftGuideMixin.default, {
        session: Ember.inject.service('session'),

        init: function init() {
            this._super.apply(this, arguments);
            this.errors = [];
            this.isOpenMobileJournalDropdown = false;
            this.isOpenMobileProfileDropdown = false;
        },
        didUpdateAttrs: function didUpdateAttrs() {
            this._super.apply(this, arguments);
            this.set('errors', []);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
        },


        shouldShowBottomRowMobile: Ember.computed('isOpenMobileProfileDropdown', 'me', function () {
            var isOpenMobileProfileDropdown = this.get('isOpenMobileProfileDropdown');
            var me = this.get('me');
            return me && !isOpenMobileProfileDropdown;
        }),

        actions: {
            switchJournal: function switchJournal(journal) {
                this.sendAction('switchJournal', journal);
                this.send('closeMobileJournal');
            },

            transition: function transition(route, param) {
                Ember.$(".navbar-my-details").addClass("active");
                var currentJournal = this.get('currentJournal');
                switch (route) {
                    case 'account':
                        route = 'main.account.show';
                        break;
                    case 'billing':
                        route = 'main.billing.show';
                        break;
                    case 'refer-friend':
                        route = 'main.refer-friend.show';
                        break;
                    case 'collection-list':
                        route = 'main.collections.list';
                        break;
                    case 'premium':
                        route = 'main.subscription-products.list';
                        break;
                    case 'export':
                        route = 'main.journals.export';
                        break;
                    case 'follower-list':
                        route = 'main.journals.follower-list';
                        break;
                    case 'add-journal':
                        route = 'main.journals.add';
                        break;
                    case 'journal-list':
                        route = 'main.journals.list';
                        break;
                    case 'manage-journal':
                        if (currentJournal) {
                            route = 'main.journals.edit';
                            param = currentJournal.get('id');
                        }
                        break;
                    case 'children':
                        route = 'main.children.list';
                        break;
                    case 'products':
                        route = 'main.products.list';
                        break;
                    case 'search':
                        route = 'main.journals.search';
                        break;
                    case 'subscription':
                        route = 'main.subscription.manage';
                        break;
                    case 'albums':
                        route = 'main.collections.list';
                        break;
                    default:

                }
                if (param) this.sendAction('doTransition', route, param);else this.sendAction('doTransition', route);
                this.send('closeMobileProfileDropdown');
            },

            gotoPremiumPublic: function gotoPremiumPublic() {
                this.send('transition', 'premium', {
                    queryParams: {
                        overviewOnly: false
                    }
                });
            },
            upgradeToPremium: function upgradeToPremium() {
                this.send('transition', 'premium', {
                    queryParams: {
                        journalid: this.get('currentJournal.id'),
                        overviewOnly: false
                    }
                });
            },
            billingInformation: function billingInformation() {
                this.API.billingSession().then(function (res) {
                    window.location.replace(res.portalURL);
                });
            },


            logout: function logout() {
                this.sendAction('logout');
            },

            openMobileJournal: function openMobileJournal() {
                window.scrollTo(0, 0);
                // blocks window scroll
                var body = $("body");
                body.addClass("modal-open");
                body.addClass("bg-white-only-topnav");
                $("meta[name='theme-color']").attr('content', '#FFFFFF');
                this.set("isOpenMobileJournalDropdown", true);
            },
            closeMobileJournal: function closeMobileJournal() {
                $("body").removeClass("modal-open");
                $("body").removeClass("bg-white-only-topnav");
                $("meta[name='theme-color']").attr('content', '#F9F6F3');
                this.set("isOpenMobileJournalDropdown", false);
            },
            toggleMobileJournal: function toggleMobileJournal() {
                var _this = this;

                setTimeout(function () {
                    var openingMobileJournal = Ember.$("#mobile-journal-navbar").attr("aria-expanded");
                    if (openingMobileJournal === 'true') {
                        _this.send('openMobileJournal');
                    } else {
                        _this.send('closeMobileJournal');
                    }
                });
            },
            openMobileProfileDropdown: function openMobileProfileDropdown() {
                var mobileProfileDropdown = Ember.$("#mobile-profile-navbar");
                this.set("isOpenMobileProfileDropdown", true);
                window.scrollTo(0, 0);
                var body = $("body");
                // blocks window scroll
                body.addClass("modal-open");
                body.addClass("bg-white");
                mobileProfileDropdown.toggleClass('open');
                mobileProfileDropdown.attr("aria-expanded", 'true');
                $("meta[name='theme-color']").attr('content', '#FFFFFF');
            },
            closeMobileProfileDropdown: function closeMobileProfileDropdown() {
                var mobileProfileDropdown = Ember.$("#mobile-profile-navbar");
                this.set("isOpenMobileProfileDropdown", false);
                var body = $("body");
                body.removeClass("modal-open");
                body.removeClass("bg-white");
                mobileProfileDropdown.toggleClass('open');
                mobileProfileDropdown.attr("aria-expanded", 'false');
                $("meta[name='theme-color']").attr('content', '#F9F6F3');
            },
            toggleMobileProfileDropdown: function toggleMobileProfileDropdown() {
                var mobileProfileDropdown = Ember.$("#mobile-profile-navbar");
                var closingMobileProfile = mobileProfileDropdown.attr("aria-expanded");
                if (closingMobileProfile === 'true') {
                    this.send('closeMobileProfileDropdown');
                } else {
                    this.send('openMobileProfileDropdown');
                }
            }
        }
    });
});