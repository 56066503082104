define("tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 21,
                      "column": 14
                    },
                    "end": {
                      "line": 21,
                      "column": 57
                    }
                  },
                  "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["content", "date.label", ["loc", [null, [21, 43], [21, 57]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 20,
                    "column": 12
                  },
                  "end": {
                    "line": 22,
                    "column": 12
                  }
                },
                "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "x-option", [], ["value", ["subexpr", "@mut", [["get", "date.name", ["loc", [null, [21, 32], [21, 41]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [21, 14], [21, 70]]]]],
              locals: ["date"],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 10
                },
                "end": {
                  "line": 23,
                  "column": 10
                }
              },
              "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "dateSelectionOptionValues", ["loc", [null, [20, 20], [20, 45]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 12], [22, 21]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 10
                },
                "end": {
                  "line": 27,
                  "column": 10
                }
              },
              "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "style", "padding-left: 10px;");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "date-picker", [], ["class", "date-field form-control date-popup", "value", ["subexpr", "@mut", [["get", "currentFormattedDate", ["loc", [null, [26, 81], [26, 101]]], 0, 0, 0, 0]], [], [], 0, 0], "dateValue", ["subexpr", "@mut", [["get", "currentFormattedDate", ["loc", [null, [26, 112], [26, 132]]], 0, 0, 0, 0]], [], [], 0, 0], "dateFormat", ["subexpr", "@mut", [["get", "usersDateFormat", ["loc", [null, [26, 144], [26, 159]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [26, 18], [26, 161]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 6
              },
              "end": {
                "line": 29,
                "column": 6
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "date-selection-element text-center");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createElement("small");
            var el4 = dom.createTextNode("When did this happen?");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element5, 3, 3);
            morphs[1] = dom.createMorphAt(element5, 4, 4);
            return morphs;
          },
          statements: [["block", "x-select", [], ["value", ["subexpr", "@mut", [["get", "dateSelectionValue", ["loc", [null, [19, 28], [19, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "selectDate"], 0, null, ["loc", [null, [19, 10], [23, 23]]]], ["block", "if", [["get", "showSpecificDateOption", ["loc", [null, [24, 16], [24, 38]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [24, 10], [27, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 6
              },
              "end": {
                "line": 35,
                "column": 6
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "date-selection-element text-center");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "milestone-dialog-completion-date");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
            return morphs;
          },
          statements: [["content", "currentlySelectedMilestone.completionDate", ["loc", [null, [33, 56], [33, 101]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 8
              },
              "end": {
                "line": 44,
                "column": 8
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "href", "");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "src", "images/milestones/green-tick-small.png");
            dom.setAttribute(el2, "class", "completed");
            dom.setAttribute(el2, "width", "32");
            dom.setAttribute(el2, "height", "32");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [["element", "action", ["removeMilestone", ["get", "currentlySelectedMilestone", ["loc", [null, [41, 48], [41, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 21], [41, 76]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 8
              },
              "end": {
                "line": 46,
                "column": 8
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "images/milestones/grey-tick-small.png");
            dom.setAttribute(el1, "class", "notCompleted cursor-pointer");
            dom.setAttribute(el1, "width", "32");
            dom.setAttribute(el1, "height", "32");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element3);
            return morphs;
          },
          statements: [["element", "action", ["saveCustomDate"], [], ["loc", [null, [45, 118], [45, 145]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child4 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 6
              },
              "end": {
                "line": 53,
                "column": 6
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "milestone-dialog-description");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "descriptionWithLineBreaks", ["loc", [null, [51, 10], [51, 39]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child5 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 12
                  },
                  "end": {
                    "line": 73,
                    "column": 12
                  }
                },
                "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "col-xs-12 milestone-related-article-item");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "target", "_blank");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "col-xs-3 milestone-related-article-thumbnail");
                var el4 = dom.createTextNode("\n                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("img");
                dom.setAttribute(el4, "class", "img-responsive");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "col-xs-9 milestone-related-article-title");
                var el4 = dom.createTextNode("\n                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4, "class", "article-title");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1, 1]);
                var element1 = dom.childAt(element0, [1, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element0, 'href');
                morphs[1] = dom.createAttrMorph(element1, 'src');
                morphs[2] = dom.createAttrMorph(element1, 'title');
                morphs[3] = dom.createMorphAt(dom.childAt(element0, [3, 1]), 0, 0);
                return morphs;
              },
              statements: [["attribute", "href", ["get", "article.article.contentUrl", ["loc", [null, [64, 27], [64, 53]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["get", "article.article.thumbnailImageUrl", ["loc", [null, [66, 32], [66, 65]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["get", "article.article.title", ["loc", [null, [66, 77], [66, 98]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "article.article.title", ["loc", [null, [69, 48], [69, 73]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 10
                },
                "end": {
                  "line": 74,
                  "column": 10
                }
              },
              "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "unless", [["get", "article.article.deleted", ["loc", [null, [62, 22], [62, 45]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [62, 12], [73, 23]]]]],
            locals: ["article"],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 6
              },
              "end": {
                "line": 76,
                "column": 6
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row milestone-related-articles-container");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h3");
            var el4 = dom.createTextNode("Related articles");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element2, 3, 3);
            morphs[1] = dom.createMorphAt(element2, 5, 5);
            return morphs;
          },
          statements: [["content", "currentlySelectedMilestone.relatedArticles.deleted", ["loc", [null, [60, 10], [60, 64]]], 0, 0, 0, 0], ["block", "each", [["get", "currentlySelectedMilestone.relatedArticles", ["loc", [null, [61, 18], [61, 60]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [61, 10], [74, 19]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 79,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "milestone-dialog-inner");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "text-left col-xs-6 milestone-dialog-theme-bar");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "text-right col-xs-6 milestone-dialog-age-range");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row milestone-dialog-title-container");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-xs-12");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("h3");
          dom.setAttribute(el5, "class", "milestone-dialog-title");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "fancyCheckbox unselectable text-center");
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "spin small-spinner");
          dom.setAttribute(el4, "style", "display:none;");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element7, [1]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element6, 'data-dialog-milestone-item');
          morphs[1] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element8, [3]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element7, [3, 1, 1]), 0, 0);
          morphs[4] = dom.createMorphAt(element7, 5, 5);
          morphs[5] = dom.createMorphAt(element7, 7, 7);
          morphs[6] = dom.createMorphAt(dom.childAt(element7, [9]), 3, 3);
          morphs[7] = dom.createMorphAt(element7, 11, 11);
          morphs[8] = dom.createMorphAt(element7, 13, 13);
          return morphs;
        },
        statements: [["attribute", "data-dialog-milestone-item", ["get", "currentlySelectedMilestone.id", ["loc", [null, [3, 38], [3, 67]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "currentlySelectedMilestone.checklistTitle", ["loc", [null, [6, 68], [6, 113]]], 0, 0, 0, 0], ["content", "currentlySelectedMilestone.ageRange", ["loc", [null, [7, 68], [7, 107]]], 0, 0, 0, 0], ["content", "currentlySelectedMilestone.title", ["loc", [null, [12, 45], [12, 81]]], 0, 0, 0, 0], ["block", "unless", [["get", "currentMilestoneCompleted", ["loc", [null, [16, 16], [16, 41]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [16, 6], [29, 17]]]], ["block", "if", [["get", "currentMilestoneCompleted", ["loc", [null, [31, 12], [31, 37]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [31, 6], [35, 13]]]], ["block", "if", [["get", "currentMilestoneCompleted", ["loc", [null, [40, 14], [40, 39]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [40, 8], [46, 15]]]], ["block", "if", [["get", "currentlySelectedMilestone.description", ["loc", [null, [49, 12], [49, 50]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [49, 6], [53, 13]]]], ["block", "if", [["get", "currentlySelectedMilestone.validRelatedArticles", ["loc", [null, [55, 12], [55, 59]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [55, 6], [76, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 80,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/components/main/children/milestones/date-selection-dialog.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-dialog", [], ["title", ["subexpr", "@mut", [["get", "currentlySelectedMilestone.milestoneDialogTitle", ["loc", [null, [1, 22], [1, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "saveButton", false, "cancelButton", true, "close", "close"], 0, null, ["loc", [null, [1, 0], [79, 17]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});