define("tinybeans-frontend/controllers/main/offers/confirmation", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ["utm_campaign", "offer_partner", "exp_date", "offer_type", "show_free", "discount_type", "discount", "description", "monthly_offer", "yearly_offer"],

    actions: {

      transitionToJournalRoute: function transitionToJournalRoute() {
        this.transitionToRoute('main.journals.home');
      }

    }
  });
});