define('tinybeans-frontend/helpers/equals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.equals = equals;
  function equals(params) {
    // Convert both sides to string as JSON params are strings and we might compare to a number
    return String(params[0]) == String(params[1]);
  }

  exports.default = Ember.Helper.helper(equals);
});