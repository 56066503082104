define("tinybeans-frontend/templates/main/children/milestones/checklist", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 14
                  },
                  "end": {
                    "line": 36,
                    "column": 14
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment(" old class \"col-md-1 col-sm-2 col-xs-4 milestone-icon\" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "milestone-icon-spacing");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("p");
                dom.setAttribute(el2, "class", "checklistCategoryTitle");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [3]);
                var element10 = dom.childAt(element9, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createElementMorph(element9);
                morphs[1] = dom.createAttrMorph(element10, 'class');
                morphs[2] = dom.createAttrMorph(element10, 'id');
                morphs[3] = dom.createMorphAt(dom.childAt(element9, [3]), 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["loadChecklist", ["get", "checklistCategory.id", ["loc", [null, [32, 77], [32, 97]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 52], [32, 99]]], 0, 0], ["attribute", "class", ["get", "checklistCategory.iconClass", ["loc", [null, [33, 32], [33, 59]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["get", "checklistCategory.iconId", ["loc", [null, [33, 68], [33, 92]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "checklistCategory.title", ["loc", [null, [34, 52], [34, 79]]], 0, 0, 0, 0]],
              locals: ["checklistCategory"],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.7.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 49,
                        "column": 18
                      },
                      "end": {
                        "line": 60,
                        "column": 18
                      }
                    },
                    "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "ageRangeAndCompletionBar");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "col-xs-1 col-sm-1");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "col-xs-4 col-sm-2");
                    var el3 = dom.createElement("span");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "col-xs-6 col-sm-6 text-left");
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "progress");
                    var el4 = dom.createTextNode("\n                          ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("div");
                    dom.setAttribute(el4, "class", "progress-bar");
                    dom.setAttribute(el4, "role", "progressbar");
                    dom.setAttribute(el4, "aria-valuenow", "60");
                    dom.setAttribute(el4, "aria-valuemin", "0");
                    dom.setAttribute(el4, "aria-valuemax", "100");
                    var el5 = dom.createTextNode("\n                            ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("%");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                        ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                      ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element7 = dom.childAt(fragment, [1]);
                    var element8 = dom.childAt(element7, [5, 1, 1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(dom.childAt(element7, [3, 0]), 0, 0);
                    morphs[1] = dom.createAttrMorph(element8, 'style');
                    morphs[2] = dom.createMorphAt(element8, 1, 1);
                    return morphs;
                  },
                  statements: [["content", "group.ageRange", ["loc", [null, [52, 59], [52, 77]]], 0, 0, 0, 0], ["attribute", "style", ["subexpr", "html-safe", [["subexpr", "main/children/milestones/prog-bar-width", [["get", "group.percentage", ["loc", [null, [55, 112], [55, 128]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 71], [55, 129]]], 0, 0]], [], ["loc", [null, [null, null], [55, 131]]], 0, 0], 0, 0, 0, 0], ["content", "group.percentage", ["loc", [null, [56, 28], [56, 48]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 48,
                      "column": 16
                    },
                    "end": {
                      "line": 61,
                      "column": 16
                    }
                  },
                  "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "currentChecklist.showDateRange", ["loc", [null, [49, 24], [49, 54]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [49, 18], [60, 25]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            var child1 = function () {
              var child0 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.7.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 70,
                          "column": 30
                        },
                        "end": {
                          "line": 73,
                          "column": 30
                        }
                      },
                      "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "src", "images/milestones/green-tick-small.png");
                      dom.setAttribute(el1, "class", "completed cursor-pointer");
                      dom.setAttribute(el1, "width", "32");
                      dom.setAttribute(el1, "height", "32");
                      dom.setAttribute(el1, "style", "margin:0 auto;");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "src", "images/milestones/grey-tick-small.png");
                      dom.setAttribute(el1, "class", "notCompleted cursor-pointer");
                      dom.setAttribute(el1, "width", "32");
                      dom.setAttribute(el1, "height", "32");
                      dom.setAttribute(el1, "style", "margin:0 auto;display:none;");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                var child1 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.7.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 73,
                          "column": 30
                        },
                        "end": {
                          "line": 76,
                          "column": 30
                        }
                      },
                      "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "src", "images/milestones/green-tick-small.png");
                      dom.setAttribute(el1, "class", "completed cursor-pointer");
                      dom.setAttribute(el1, "width", "32");
                      dom.setAttribute(el1, "height", "32");
                      dom.setAttribute(el1, "style", "margin:0 auto;display:none;");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "src", "images/milestones/grey-tick-small.png");
                      dom.setAttribute(el1, "class", "notCompleted cursor-pointer");
                      dom.setAttribute(el1, "width", "32");
                      dom.setAttribute(el1, "height", "32");
                      dom.setAttribute(el1, "style", "margin:0 auto;");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@2.7.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 65,
                        "column": 20
                      },
                      "end": {
                        "line": 87,
                        "column": 20
                      }
                    },
                    "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "milestone-item-block cursor-pointer");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "col-sm-offset-1 col-sm-10 milestone-item");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "tile-checkbox col-sm-1 col-xs-1");
                    var el4 = dom.createTextNode("\n                            ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("div");
                    dom.setAttribute(el4, "class", "fancyCheckbox unselectable");
                    var el5 = dom.createTextNode("\n");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("                            ");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                          ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "col-sm-10 col-xs-10");
                    var el4 = dom.createTextNode("\n                            ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("p");
                    dom.setAttribute(el4, "class", "checklistItemTitle");
                    var el5 = dom.createTextNode("\n                              ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("\n                              ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createElement("br");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createElement("small");
                    dom.setAttribute(el5, "class", "milestone-completion-date");
                    var el6 = dom.createComment("");
                    dom.appendChild(el5, el6);
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("\n                            ");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                          ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var element4 = dom.childAt(element3, [1]);
                    var element5 = dom.childAt(element4, [3, 1]);
                    var morphs = new Array(5);
                    morphs[0] = dom.createAttrMorph(element3, 'data-milestone-item');
                    morphs[1] = dom.createElementMorph(element3);
                    morphs[2] = dom.createMorphAt(dom.childAt(element4, [1, 1]), 1, 1);
                    morphs[3] = dom.createMorphAt(element5, 1, 1);
                    morphs[4] = dom.createMorphAt(dom.childAt(element5, [4]), 0, 0);
                    return morphs;
                  },
                  statements: [["attribute", "data-milestone-item", ["get", "item.id", ["loc", [null, [66, 94], [66, 101]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["tickMilestone", ["get", "item", ["loc", [null, [66, 129], [66, 133]]], 0, 0, 0, 0], ["get", "group", ["loc", [null, [66, 134], [66, 139]]], 0, 0, 0, 0]], [], ["loc", [null, [66, 104], [66, 141]]], 0, 0], ["block", "if", [["get", "item.completed", ["loc", [null, [70, 36], [70, 50]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [70, 30], [76, 37]]]], ["content", "item.title", ["loc", [null, [81, 30], [81, 44]]], 0, 0, 0, 0], ["content", "item.completionDate", ["loc", [null, [82, 76], [82, 99]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: [child0, child1]
                };
              }();
              var child1 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.7.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 94,
                          "column": 30
                        },
                        "end": {
                          "line": 97,
                          "column": 30
                        }
                      },
                      "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "src", "images/milestones/green-tick-small.png");
                      dom.setAttribute(el1, "class", "completed");
                      dom.setAttribute(el1, "width", "32");
                      dom.setAttribute(el1, "height", "32");
                      dom.setAttribute(el1, "style", "margin:0 auto;");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "src", "images/milestones/grey-tick-small.png");
                      dom.setAttribute(el1, "class", "notCompleted");
                      dom.setAttribute(el1, "width", "32");
                      dom.setAttribute(el1, "height", "32");
                      dom.setAttribute(el1, "style", "margin:0 auto;display:none;");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                var child1 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.7.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 97,
                          "column": 30
                        },
                        "end": {
                          "line": 100,
                          "column": 30
                        }
                      },
                      "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "src", "images/milestones/green-tick-small.png");
                      dom.setAttribute(el1, "class", "completed");
                      dom.setAttribute(el1, "width", "32");
                      dom.setAttribute(el1, "height", "32");
                      dom.setAttribute(el1, "style", "margin:0 auto;display:none;");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "src", "images/milestones/grey-tick-small.png");
                      dom.setAttribute(el1, "class", "notCompleted");
                      dom.setAttribute(el1, "width", "32");
                      dom.setAttribute(el1, "height", "32");
                      dom.setAttribute(el1, "style", "margin:0 auto;");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                var child2 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.7.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 107,
                          "column": 30
                        },
                        "end": {
                          "line": 109,
                          "column": 30
                        }
                      },
                      "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("br");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("small");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
                      return morphs;
                    },
                    statements: [["content", "item.completionDate", ["loc", [null, [108, 44], [108, 67]]], 0, 0, 0, 0]],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@2.7.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 87,
                        "column": 20
                      },
                      "end": {
                        "line": 114,
                        "column": 20
                      }
                    },
                    "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "milestone-item-block");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "col-sm-offset-1 col-sm-10 milestone-item");
                    var el3 = dom.createTextNode("\n\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "tile-checkbox col-sm-1 col-xs-1");
                    var el4 = dom.createTextNode("\n                            ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("div");
                    dom.setAttribute(el4, "class", "fancyCheckbox unselectable");
                    var el5 = dom.createTextNode("\n\n");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("                            ");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                          ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "col-sm-10 col-xs-10");
                    var el4 = dom.createTextNode("\n                            ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("p");
                    dom.setAttribute(el4, "class", "checklistItemTitle");
                    var el5 = dom.createTextNode("\n                              ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("\n");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("                            ");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                          ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var element1 = dom.childAt(element0, [1]);
                    var element2 = dom.childAt(element1, [3, 1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createAttrMorph(element0, 'data-milestone-item');
                    morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1]), 1, 1);
                    morphs[2] = dom.createMorphAt(element2, 1, 1);
                    morphs[3] = dom.createMorphAt(element2, 3, 3);
                    return morphs;
                  },
                  statements: [["attribute", "data-milestone-item", ["get", "item.id", ["loc", [null, [88, 79], [88, 86]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "item.completed", ["loc", [null, [94, 36], [94, 50]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [94, 30], [100, 37]]]], ["content", "item.title", ["loc", [null, [106, 30], [106, 44]]], 0, 0, 0, 0], ["block", "if", [["get", "item.completed", ["loc", [null, [107, 36], [107, 50]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [107, 30], [109, 37]]]]],
                  locals: [],
                  templates: [child0, child1, child2]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 62,
                      "column": 16
                    },
                    "end": {
                      "line": 116,
                      "column": 16
                    }
                  },
                  "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element6, 'class');
                  morphs[1] = dom.createMorphAt(element6, 1, 1);
                  return morphs;
                },
                statements: [["attribute", "class", ["get", "item.ageRangeClass", ["loc", [null, [63, 32], [63, 50]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "canEditMilestones", ["loc", [null, [65, 26], [65, 43]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [65, 20], [114, 27]]]]],
                locals: ["item"],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 47,
                    "column": 14
                  },
                  "end": {
                    "line": 117,
                    "column": 14
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "group.ageRange", ["loc", [null, [48, 22], [48, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [48, 16], [61, 23]]]], ["block", "each", [["get", "group.items", ["loc", [null, [62, 24], [62, 35]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [62, 16], [116, 25]]]]],
              locals: ["group"],
              templates: [child0, child1]
            };
          }();
          var child2 = function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 118,
                    "column": 14
                  },
                  "end": {
                    "line": 120,
                    "column": 14
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: ["item"],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 4
                },
                "end": {
                  "line": 125,
                  "column": 4
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "id", "milestone-list");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "milestone-list-inner");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "text-center");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "spin");
              dom.setAttribute(el4, "style", "display:none; margin:0px auto; margin-top:10px; margin-bottom:20px;");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "container");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "row text-center milestone-categories");
              var el5 = dom.createTextNode("\n\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "row category-intro");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5, "class", "col-xs-1");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5, "class", "col-xs-10");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("p");
              dom.setAttribute(el6, "class", "intro");
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("p");
              dom.setAttribute(el6, "class", "description");
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "milestone-item-div");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1, 1, 3]);
              var element12 = dom.childAt(element11, [3, 3]);
              var element13 = dom.childAt(element12, [1]);
              var element14 = dom.childAt(element11, [5]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]), 1, 1);
              morphs[1] = dom.createAttrMorph(element13, 'style');
              morphs[2] = dom.createMorphAt(element13, 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element12, [3]), 0, 0);
              morphs[4] = dom.createMorphAt(element14, 1, 1);
              morphs[5] = dom.createMorphAt(element14, 2, 2);
              return morphs;
            },
            statements: [["block", "each", [["get", "checklistCategories", ["loc", [null, [30, 22], [30, 41]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [30, 14], [36, 23]]]], ["attribute", "style", ["subexpr", "html-safe", [["get", "currentChecklist.titleStyle", ["loc", [null, [42, 52], [42, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [42, 81]]], 0, 0], 0, 0, 0, 0], ["content", "currentChecklist.title", ["loc", [null, [42, 82], [42, 108]]], 0, 0, 0, 0], ["content", "currentChecklist.description", ["loc", [null, [43, 39], [43, 71]]], 0, 0, 0, 0], ["block", "each", [["get", "currentChecklistItems", ["loc", [null, [47, 22], [47, 43]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [47, 14], [117, 23]]]], ["block", "each", [["get", "currentChecklistItems", ["loc", [null, [118, 22], [118, 43]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [118, 14], [120, 23]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 125,
                  "column": 4
                },
                "end": {
                  "line": 129,
                  "column": 4
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "text-center");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              var el3 = dom.createTextNode("No milestones available now");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 2
              },
              "end": {
                "line": 131,
                "column": 2
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.channelSubscriptions", ["loc", [null, [20, 10], [20, 36]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [20, 4], [129, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 131,
                "column": 2
              },
              "end": {
                "line": 135,
                "column": 2
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "text-center");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Please come back when the child is born.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 0
            },
            "end": {
              "line": 136,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.child.isBorn", ["loc", [null, [19, 8], [19, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [19, 2], [135, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 136,
              "column": 0
            },
            "end": {
              "line": 140,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "text-center");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Sorry, you don't have access to this page.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 141,
              "column": 0
            },
            "end": {
              "line": 143,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "main/children/milestones/date-selection-dialog", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [142, 57], [142, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "currentlySelectedMilestone", ["subexpr", "@mut", [["get", "currentlySelectedMilestone", ["loc", [null, [142, 90], [142, 116]]], 0, 0, 0, 0]], [], [], 0, 0], "currentMilestoneCompleted", ["subexpr", "@mut", [["get", "currentMilestoneCompleted", ["loc", [null, [142, 143], [142, 168]]], 0, 0, 0, 0]], [], [], 0, 0], "closeModalDialog", "closeModalDialog", "saveMilestone", "saveMilestone", "removeMilestone", "removeMilestone", "usersDateFormat", ["subexpr", "@mut", [["get", "usersDateFormat", ["loc", [null, [142, 285], [142, 300]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [142, 2], [142, 302]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 144,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/children/milestones/checklist.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n  #footer {\n    margin-top: 0px!important;\n    background: #fff!important;\n  }\n\n  .content-container {\n    padding-bottom: none!important;\n  }\n\n  .childShow {\n    background: #fff!important;\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "main/children/milestones/child-summary", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [16, 47], [16, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "milestoneTabClass", "active"], ["loc", [null, [16, 0], [16, 81]]], 0, 0], ["block", "if", [["get", "canViewMilestones", ["loc", [null, [18, 6], [18, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [18, 0], [140, 7]]]], ["block", "if", [["get", "displayModal", ["loc", [null, [141, 6], [141, 18]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [141, 0], [143, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});