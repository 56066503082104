define('tinybeans-frontend/authorizers/custom-headers', ['exports', 'tinybeans-frontend/config/environment', 'ember-simple-auth/authorizers/base'], function (exports, _environment, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    authorize: function authorize(sessionData, addHeaderFunction) {
      var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      //tb_viewportHeight is the height of the viewable area in the browser in pixels
      //tb_viewportWidth as above
      //tb_touchScreen user is browsing the site with a touch screen
      try {
        addHeaderFunction('tb_viewportHeight', String(height));
        addHeaderFunction('tb_viewportWidth', String(width));
        addHeaderFunction('tb_currentPage', window.location.href);
        addHeaderFunction('tb_emberGitHash', _environment.default.currentRevision);
        addHeaderFunction('tb_touchScreen', Modernizr.touch);
      } catch (error) {
        // Just better to fail silently here if we request values that are unavailable (would rather debug fails than debug itself crashing app)
        //console.error(error);
      }
    }
  });
});