define('tinybeans-frontend/initializers/facebook-sdk', ['exports', 'tinybeans-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'facebook',
    initialize: function initialize() {
      var fbAsyncInit = function fbAsyncInit() {
        FB.init({
          appId: _environment.default.fbAppId,
          version: _environment.default.fbVersion
        });
      };

      (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');

      window.fbAsyncInit = fbAsyncInit;
    }
  };
});