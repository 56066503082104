define('tinybeans-frontend/tracker/constants/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CommonActions = exports.CommonActions = {
    screenView: 'screen view',
    onboardingJournalHolder: 'onboarding journal holder'
  };

  var Coupons = exports.Coupons = {
    category: 'CouponRedemption'
  };

  var Journals = exports.Journals = {
    category: 'journals',
    screen: 'journal list'
  };

  var AddMoment = exports.AddMoment = {
    category: 'add moment',
    screen: 'add moment',
    saveButton: 'add moment save button',
    cancelButton: 'add moment cancel button'
  };

  var Signup = exports.Signup = {
    category: 'sign up or log in user',
    screen: 'signup',
    loginButton: 'log in button',
    resetPassword: 'reset password button',
    signupButton: 'sign up button',

    confirmDetails: {
      screen: 'confirm details',
      continueButton: 'continue button'
    }
  };

  var Login = exports.Login = {
    category: 'sign up or log in user',
    screen: 'log in',
    facebooklogin: 'log in with facebook button',
    facebookSignup: 'sign up with facebook button',
    loginButton: 'log in button',
    resetPassword: 'reset password button',
    signupButton: 'sign up button'
  };

  var Calendar = exports.Calendar = {
    category: 'calendar',
    screen: 'calendar',
    openFollowerList: 'open follower list button',
    openPhotoCollections: 'open photo album button',
    openChildPage: 'open child button',
    addMomentButton: 'add moments button'
  };

  var ResetPassword = exports.ResetPassword = {
    category: 'sign up or log in user',
    screen: 'reset password'
  };

  var Onboarding = exports.Onboarding = {
    category: 'onboarding journal holder',

    stepStart: {
      screen: 'onboarding user location',
      saveButton: 'onboarding user location continue button',
      skipButton: 'onboarding user location skip button'
    },
    step1: {
      screen: 'onboarding add child',
      saveButton: 'onboarding add child save button',
      skipButton: 'onboarding add child skip button'
    },
    step2: {
      screen: 'onboarding add pet screeen view',
      saveButton: 'onboarding add pet save button',
      skipButton: 'onboarding add pet skip button'

    },
    step3: {
      screen: 'onboarding subscription',
      saveButton: 'onboarding subscription start trial button',
      skipButton: 'onboarding subscription skip button'

    },
    /*
    step3: {
      screen: 'onboarding add moment',
      saveButton: 'onboarding add moment save button',
      skipButton: 'onboarding add moment skip button'
     }*/
    step4: {
      screen: 'onboarding add follower',
      saveButton: 'onboarding add follower save button',
      skipButton: 'onboarding add follower skip button',
      advancedButton: 'onboarding add follower advanced settings button',
      copyLink: 'copy invite link'
    },

    step5: {
      screen: 'finish onboarding ',
      continueOnDesktop: 'continue on desktop button',
      continueOnMobile: 'continue on mobile button',
      downloadAppsbutton: 'download apps button'
    }
  };

  var Child = exports.Child = {
    category: 'child',
    screen: 'child',

    add: {
      screen: 'add child',
      saveButton: 'add child save button',
      cancelButton: 'add child cancel button'
    },
    list: {
      screen: 'child list'
    },
    details: {
      screen: 'child details',
      saveButton: 'child details save button',
      removeButton: 'child details remove button'
    },
    weight: {
      screen: 'child weight',
      addWeight: 'child weight add weight button'
    },
    height: {
      screen: 'child height',
      addHeight: 'child height add height button'
    },
    milestones: {
      closeButton: 'milestone details close button',
      completeButton: 'milestone details close button',
      addMomentButton: 'milestone details add a moment button'
    },
    milestoneDetails: {
      screen: 'milestone details',

      closeButton: 'milestone details close button',
      completeButton: 'milestone details complete button'
    },
    dashboardButton: 'dashboard button',
    milestoneButton: 'milestone button',
    channelsButton: 'channels button',
    gotoWeights: 'open weight button',
    gotoHeights: 'open height button',
    openDashboard: 'open dashboard button',
    openMilestones: 'open milestones button',
    openChannels: 'open channels button',
    openDetails: 'open child details button',
    openRecommendedArticle: 'open recommended article',
    openRecommendedMilestone: 'open recommended milestone'
  };

  var Pet = exports.Pet = {
    category: 'pet',
    screen: 'pet',

    add: {
      screen: 'add pet',
      saveButton: 'add pet save button',
      cancelButton: 'add pet cancel button'
    },
    list: {
      screen: 'pet list'
    },
    details: {
      screen: 'pet details',
      saveButton: 'pet details save button',
      removeButton: 'pet details remove button'
    },
    weight: {
      screen: 'pet weight',
      addWeight: 'pet weight add weight button'
    },
    height: {
      screen: 'pet height',
      addHeight: 'pet height add height button'
    },
    milestones: {
      closeButton: 'milestone details close button',
      completeButton: 'milestone details close button',
      addMomentButton: 'milestone details add a moment button'
    },
    milestoneDetails: {
      screen: 'milestone details',

      closeButton: 'milestone details close button',
      completeButton: 'milestone details complete button'
    },
    dashboardButton: 'dashboard button',
    milestoneButton: 'milestone button',
    channelsButton: 'channels button',
    gotoWeights: 'open weight button',
    gotoHeights: 'open height button',
    openDashboard: 'open dashboard button',
    openMilestones: 'open milestones button',
    openChannels: 'open channels button',
    openDetails: 'open child details button',
    openRecommendedArticle: 'open recommended article',
    openRecommendedMilestone: 'open recommended milestone'
  };

  var Followers = exports.Followers = {
    category: 'invite followers',

    list: {
      screen: 'followers list',
      inviteeDetails: 'open invitees details button'
    },
    invite: {
      screen: 'add follower',
      saveButton: 'follower details save button',
      cancelButton: 'follower details cancel button',
      copyLink: 'copy invite link'
    },

    acceptInvite: {
      screen: 'Accept invite landing page'
    },

    invalidInvite: {
      screen: 'invalid invite link'
    }
  };

  var Moments = exports.Moments = {
    category: 'Moments',
    day: {
      screen: 'day'
    },
    entry: {
      screen: 'entry page'
    },
    social: {
      screen: 'social sharing'
    },
    actions: {
      click: 'click'
    },
    addMomentButton: 'add moment button',
    previousDay: 'previous day button',
    nextDay: 'next day button',
    calendarButton: 'open calendar button',
    loveMomentButton: 'love moment button',
    postComment: 'post comment button',
    editCaption: 'edit caption button',
    editDate: 'edit date button',
    pinMoment: 'pin moment button',
    copyLink: 'copy link button',
    shareOnFacebook: 'share on facebook button',
    deleteMoment: 'delete moment button',
    leaveAComment: 'leave a comment text field',
    cancelAddComment: 'cancel add comment button',
    cancelDateChange: 'cancel date change button',
    saveDateChange: 'save date change button',
    deleteEntry: 'delete entry button',
    gotoDayView: 'go to current day button'
  };

  var RandomEntry = exports.RandomEntry = {
    leanplum: {
      screen: {
        day: 'day',
        singleEntry: 'single entry'
      },
      actions: {
        flashbackModuleButton: 'flashback module button'
      },
      entryThumbnail: 'entry thumbnail',
      takeALookLink: 'take a look link',
      clickHereLink: 'click here link',
      entryIllustration: 'entry illustration'
    },
    gtm: {
      category: "random entry events",
      actions: {
        click: "click"
      },
      entryThumbnail: 'entry thumbnail',
      takeALookLink: 'take a look link',
      clickHereLink: 'click here link',
      entryIllustration: 'entry illustration'
    }

  };

  var PhotoCollections = exports.PhotoCollections = {
    category: 'photo album',

    list: {
      screen: 'photo albums',

      openPremiumButton: 'photo calbums open premium button',
      addPhotoCollectionButton: 'add photo collection button',
      openOrganizeCollectionButton: 'open organize photo albums button',
      openCollectionDetailsButton: 'open photo collection details button'
    },
    add: {
      screen: 'add phto collection'
    },
    details: {
      screen: 'photo collection details',

      saveButton: 'photo collection details save button',
      cancelButton: 'photo collection details cancel button'
    },
    organize: {
      screen: 'organize photo albums',

      saveButton: 'organize photo albums save button',
      backButton: 'organize photo albums back button'
    },
    show: {
      screen: 'photo collection',

      slideShowButton: 'photo collection open slide show button',
      shareOnTwitterButton: 'photo collection share to twitter button',
      shareOnEmailButton: 'photo collection share to email button',
      addMomentToCollectionButton: 'add moment to collection button',
      deleteCollectionButton: 'delete collection button',
      openOrganizeCollectionButton: 'photo collection open organize moments button'
    },
    momentPicker: {
      screen: 'photo collection moment picker',

      saveButton: 'photo collection picker save button',
      cancelButton: 'photo collection picker cancel button'
    }
  };

  var Products = exports.Products = {
    category: 'products',
    screen: 'products',

    makeableButton: 'makable button',
    photoBookButton: 'photobooks button',
    tweedWolfButton: 'tweed wolf button'
  };

  var Premium = exports.Premium = {
    category: 'premium',
    list: {
      screen: 'subscription',
      purchaseMonthButton: 'subscription monthly button',
      purchaseYearButton: 'subscription yearly button'
    },
    success: {
      screen: 'subscription success'
    },
    selectJournal: {
      screen: 'select journal',
      completePayment: 'complete payment button',
      applyCoupon: 'apply coupon button'
    }
  };
});