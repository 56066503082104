define('tinybeans-frontend/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    fullName: (0, _attr.default)('string'),
    emailAddress: (0, _attr.default)('string'),
    timestamp: (0, _attr.default)('number'),
    lastUpdatedTimestamp: (0, _attr.default)('number'),
    firstLoggedInTimestamp: (0, _attr.default)('number'),
    lastLoggedInTimestamp: (0, _attr.default)('number'),
    timeZoneOffset: (0, _attr.default)('number'),
    timeZone: (0, _relationships.belongsTo)('nameLabel', { async: false }),
    measurementSystem: (0, _relationships.belongsTo)('nameLabel', { async: false }),
    dateStyle: (0, _relationships.belongsTo)('nameLabel', { async: false }),
    emailFrequencyOnNewComment: (0, _attr.default)(),
    emailFrequencyOnNewEmotion: (0, _attr.default)(),
    avatars: (0, _relationships.belongsTo)('blobs', { async: false }),
    emailWeeklySummary: (0, _attr.default)('boolean'),
    deleted: (0, _attr.default)('boolean'),
    emailOptOut: (0, _attr.default)('boolean'),
    emailMarketingOptOut: (0, _attr.default)('boolean'),
    facebookId: (0, _attr.default)('string'),
    instagramId: (0, _attr.default)('string'),
    instagramUsername: (0, _attr.default)('string'),
    referralCode: (0, _attr.default)('string'),
    referralUrl: (0, _attr.default)('string'),
    hash: (0, _attr.default)('string'),
    hasMemoriesAccess: (0, _attr.default)('boolean'),

    initials: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName').slice(0, 1).capitalize() + "." + this.get('lastName').slice(0, 1).capitalize();
    }),

    fullInitial: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName') || '';
      var lastName = this.get('lastName');
      var lastNameInitial = lastName ? lastName.substring(0, 1).capitalize() : '';
      return (firstName + ' ' + lastNameInitial).trim();
    }),

    dateFormat: Ember.computed('dateStyle', function () {
      switch (this.get('dateStyle.format')) {
        case "MM/dd/yyyy":
          return "MMM DD, YYYY";
        default:
          return "DD MMM YYYY";
      }
    }),

    datePlaceholder: Ember.computed('dateStyle', function () {
      switch (this.get('dateStyle.format')) {
        case "MM/dd/yyyy":
          return "MM DD YYYY";
        default:
          return "DD MM YYYY";
      }
    })
  });
});