define('tinybeans-frontend/components/comment-textarea', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    postOnNewLine: false,
    value: '',

    didInsertElement: function didInsertElement() {
      this.$('textarea').val(this.get('value') || '');
      this.$('textarea').focus();
    },
    insertNewline: function insertNewline() {
      if (this.get('postOnNewLine')) {
        var parentView = this.get('parentView');
        parentView.send('addComment');
      }
    },
    onInput: function onInput() {
      var parentView = this.get('parentView');
      var textArea = this.$('textarea');
      this.set('value', textArea.val());
      parentView.send('checkTextareaContent', textArea.val());
      textArea.height('auto');
      var scrollHeight = textArea.prop('scrollHeight');
      var oneLineHeight = 23;
      // removes "px" suffix
      var paddingTop = textArea.css('paddingTop').slice(0, -2);
      var paddingBottom = textArea.css('paddingBottom').slice(0, -2);
      var finalHeight = scrollHeight - paddingTop - paddingBottom;
      if (finalHeight > oneLineHeight) {
        textArea.height(finalHeight + 40 + 'px');
      } else {
        textArea.height(finalHeight + 'px');
      }
    },
    onFocus: function onFocus() {
      this.$().addClass('focus');
    },
    onBlur: function onBlur() {
      // doing pre-validation as qUnit will randomly call this function and say that $() is undefined
      var component = this.$();
      if (component) component.removeClass('focus');
    }
  });
});