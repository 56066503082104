define('tinybeans-frontend/controllers/main/subscription-products/purchase-voucher', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/config/environment'], function (exports, _baseController, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({

    queryParams: ['journalid'],

    acceptTerms: false,
    journalId: null,
    journals: null,
    user: null,
    giftCards: null,

    selectedGiftCard: function () {
      return this.get('selectedGiftCard');
    }.property('selectedGiftCard'),

    isExistingCustomer: function () {
      if (this.get('card')) {
        return true;
      }
      return false;
    }.property('card'),

    actions: {

      selectGiftCard: function selectGiftCard(giftCardOption) {
        var self = this;
        Ember.$('.gift-card').removeClass('active');
        var id = "#" + giftCardOption.id + " .gift-card";
        Ember.$(id).toggleClass('active');

        self.set('selectedGiftCard', giftCardOption);
      },

      showHelpTip: function showHelpTip() {
        bootbox.dialog({
          title: "How it works",
          message: "We can send the recipient an email with the gift code (and no pricing details). OR we can send you the email to forward to the recipient - just tick the 'send to me' box",
          buttons: {
            ok: {
              label: "Close"
            }
          }
        });
      },

      preValidate: function preValidate() {
        var error = void 0;
        if (!this.get('senderEmail')) {
          error = {};
          error.senderEmail = 'Sorry, this is required';
        }
        if (!this.get('selectedGiftCard')) {
          error = error || {};
          error.card = 'Please select a gift card';
        }

        if (error) {
          error.global = 'Please fix errors to continue';
          this.set('error', error);
          return false;
        }
        return true;
      },


      submitForm: function submitForm(token) {
        var self = this;

        var senderEmail = Ember.$("#senderEmail").val();
        var selectedGiftCard = self.get("selectedGiftCard");
        var gCardId = selectedGiftCard.id;
        var gCardAmount = selectedGiftCard.amountInCents;
        var journalId = self.get('model.journalId');
        var journals = self.get('model.journals');

        var medium = self.globals.isIOS ? "ios" : self.globals.isAndroid ? "android" : "web";

        var couponSelected = $("#couponForm .input-group").is(":visible");
        var couponCode = couponSelected ? Ember.$("#couponForm #couponCodeHolder").val() : undefined;

        if (senderEmail && selectedGiftCard) {
          self.API.submitGiftCardPayment(senderEmail, senderEmail, gCardId, couponCode, token, function (data) {
            Ember.run.next(function () {
              Ember.$("#payment-form .payment-errors").html("").addClass("hide");
              self.set("paymentTransaction", data.paymentTransaction);
              self.set("subscription", data.subscription);

              self.GTM.trackEvent({
                'eventCategory': 'premium_gift_card',
                'eventAction': 'click_payment',
                'eventLabel': medium,
                'eventValue': gCardAmount
              });

              self.send("reloadApplicationRoute");

              if (journalId && journals) {
                self.transitionToRoute('main.subscription-products.purchase-voucher-success').then(function (newRoute) {
                  newRoute.controller.set('journalId', self.get('journalId'));
                  newRoute.controller.set('journals', self.get('journals'));
                });
              } else {
                self.transitionToRoute('main.subscription-products.purchase-voucher-success');
              }
            });
          }, function (data) {

            var $form = Ember.$("form#payment-form");
            $form.find('button').prop("disabled", false);
            $form.find("input:visible").prop("disabled", false);
            Ember.$("#payment-form .payment-errors").html(data.responseJSON.message).removeClass("hide");
          });
        }
      },

      submit: function submit() {
        if (Ember.$("form#payment-form #useExisting").val() === "true") {
          this.send("submitForm");
        } else {
          this.send("getStripeToken");
        }
      },

      getStripeToken: function getStripeToken() {
        var self = this;
        var $form = Ember.$('#payment-form');

        var hasErrors = false;
        $form.find("input").parent().removeClass("has-error");

        if (!Ember.$.payment.validateCardNumber($form.find(".cc-number").val())) {
          $form.find(".cc-number").parent().addClass("has-error");
          hasErrors = true;
        }

        if (!Ember.$.payment.validateCardExpiry($form.find(".cc-month").val(), $form.find(".cc-year").val())) {
          $form.find(".cc-month").parent().addClass("has-error");
          $form.find(".cc-year").parent().addClass("has-error");
          hasErrors = true;
        }

        if (!Ember.$.payment.validateCardCVC($form.find(".cc-cvc").val())) {
          $form.find(".cc-cvc").parent().addClass("has-error");
          hasErrors = true;
        }

        if (!Ember.$(".senderEmail").val() || !validateEmail(Ember.$(".senderEmail").val())) {
          $form.find(".senderEmail").parent().addClass("has-error");
          $form.find(".sender-errors").html("Please enter correct email.").removeClass("hide");
          hasErrors = true;
        }

        var selectedGiftCard = this.get('selectedGiftCard');

        if (!selectedGiftCard) {
          $form.find(".select-gift-errors").html("Please select the gift card you'd like to purchase.").removeClass("hide");
          hasErrors = true;
        }

        if (hasErrors) {
          $form.find('.payment-errors').html("Please fix errors to continue").removeClass("hide");
          Ember.$(".modal-content").effect("shake", {
            times: 3,
            distance: 10
          }, 80);
          return false;
        }

        // Disable the submit button to prevent repeated clicks
        $form.find('button').prop("disabled", true);
        $form.find("input:visible").prop("disabled", true);
        $form.closest("#payment-details-modal").find("#progress .progress-bar").addClass("active").removeClass("hide");

        Ember.run.begin(self);
        Stripe.setPublishableKey(_environment.default.stripeKey);
        Stripe.card.createToken($form, function (status, response) {
          var $form = Ember.$('#payment-form');
          if (response.error) {
            // Show the errors on the form
            var inputName = response.error.param.replace("_", "-");
            console.log("Payment failed: " + response.error.message);
            $form.find('.payment-errors').html(response.error.message).removeClass("hide");
            $form.find("input[data-stripe='" + inputName + "']").parent().addClass("has-error");
            $form.find('button').prop('disabled', false);
            $form.find("input:visible").prop("disabled", false);
            $form.closest("#payment-details-modal").find(".progress").addClass("hide");
            $form.closest("#payment-details-modal").find("#progress .progress-bar").removeClass("active").addClass("hide");
          } else {
            $form.find('.payment-errors').html("").addClass("hide");
            // response contains id and card, which contains additional card details
            var token = response.id;
            // Insert the token into the form so it gets submitted to the server
            $form.append(Ember.$('<input type="hidden" name="stripeToken" />').val(token));
            // and submit
            self.send("submitForm", token);
          }

          Ember.run.end();
        });
      }
    }
  });
});