define('tinybeans-frontend/components/main/payment-details/stripe-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    type: 'text',
    attributeBindings: ['data-stripe', 'data-numeric']
  });
});