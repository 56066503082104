define('tinybeans-frontend/routes/main/children/milestones/complete-milestone', ['exports', 'tinybeans-frontend/routes/main/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model(params) {
      var child_id = params.child_id;
      var milestone_id = params.milestone_id;

      return Ember.RSVP.hash({
        child_id: child_id,
        milestone_id: milestone_id,
        child: this.API.findChild(child_id),
        milestone: this.API.getChecklistItem(milestone_id),
        completedChecklistItems: this.API.getCompletedChecklistItemsForChild(child_id) || [],
        me: this.modelFor('main').me,
        followings: this.modelFor('main').followings,
        journals: this.API.listAccessibleJournals()
      });
    },

    afterModel: function afterModel() {
      this.modelFor('main').completedChecklistItemsByJournal = []; // Clear cache for journals milestone counts
    },

    setupController: function setupController(controller, model) {
      if (model.completedChecklistItems == null) {
        model.completedChecklistItems = [];
      }
      this._super(controller, model);
      controller.set('currentFormattedDate', moment().format(controller.globals.usersDateFormat(controller.get('me.dateFormat'))));

      var child = model.child;
      var childDob = moment(new Date(child.dob));
      var now = moment();
      var duration = moment.duration(now.diff(childDob));
      var num_years = duration.get("years");
      var num_months = duration.get("months");

      var childId = model.child_id;
      var journalId;

      for (var i = 0; i < model.journals.length; i++) {
        var journal = model.journals[i];
        var children = model.journals[i].children ? model.journals[i].children : null;

        if (children) {
          for (var j = 0; j < children.length; j++) {
            if (children[j].id == childId) {
              journalId = journal.id;
            }
          }
        }
      }

      child.journalId = journalId;

      var ageInMonthsOnly = num_years * 12 + num_months;

      function replaceChildProperties(str) {
        if (str) {
          for (var propertyName in child) {
            var _search = new RegExp('{' + propertyName + '}', 'gi');
            var _replace = child[propertyName];
            str = str.replace(_search, _replace);
          }

          // Also replace {month}
          var search = new RegExp('{month}', 'gi');
          var replace = ageInMonthsOnly;
          str = str.replace(search, replace);
        }
        return str;
      }

      // Go through channelSubscriptions and replace {{month}} {{age}} {{firstName}} and other child properties appropriately for each article.
      // Articles can be in two places.
      // Under channelSubscriptions[]->channel[]->relatedArticles[]->article and
      // Under channelSubscriptions[]->channel[]->checklists[]->checklistItems[]->relatedArticles[]->article
      function processRelatedArticles(relatedArticles) {
        if (relatedArticles) {
          for (var _i = 0; _i < relatedArticles.length; _i++) {
            var article = relatedArticles[_i].article;
            if (article) {
              // Find all child properties in title, content and contentUrl
              // For each child property replace '{' + property name + '}' in those
              article.title = replaceChildProperties(article.title);
              article.content = replaceChildProperties(article.content);
              article.contentUrl = replaceChildProperties(article.contentUrl);
            }
          }
        }
      }

      processRelatedArticles(model.milestone.relatedArticles);

      // With all followings for this user check all journals for this child.
      // If this child exists set the permission
      for (var _i2 = 0; _i2 < model.followings.length; _i2++) {
        var following = model.followings[_i2];
        var _journal = following.journal;
        if (_journal.children) {
          for (var _j = 0; _j < _journal.children.length; _j++) {
            if (_journal.children[_j].id == model.child.id) {
              var myJournal = following.journal.user.id == model.me.id;
              var coOwner = following.coOwner;
              var canEditMilestones = following.editMilestones;
              if (myJournal || coOwner || canEditMilestones) {
                controller.set('canEditMilestones', true);
              }
            }
          }
        }
      }
    }
  });
});