define('tinybeans-frontend/authenticators/facebook', ['exports', 'tinybeans-frontend/config/environment', 'tinybeans-frontend/authenticators/token'], function (exports, _environment, _token) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _token.default.extend({
    restore: function restore(properties) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(properties.accessToken)) {
          resolve(properties);
        } else {
          reject();
        }
      });
    },
    authenticateWithFacebookToken: function authenticateWithFacebookToken(token, inviteToken, resolve, reject) {
      var url = _environment.default.apiEndPoint + '/api/1/authenticate';
      var credentials = {
        clientId: _environment.default.clientID,
        deviceId: localStorage.getItem('__leanplum_device_id'),
        facebookAccessToken: token,
        campaign: readCookie('campaign'),
        inviteToken: inviteToken
      };
      var jsonString = JSON.stringify(credentials, null, 4);
      var ajaxRequest = Ember.$.ajax({
        url: url,
        type: 'post',
        data: jsonString,
        contentType: 'application/json',
        dataType: 'json'
      });
      ajaxRequest.then(function (data) {
        Ember.run(function () {
          data.access_token = data.accessToken;
          data.facebookAccessToken = token;
          resolve(data);
        });
      }, function (xhr) {
        Ember.run(function () {
          reject(xhr.responseJSON || xhr.responseText);
        });
      });
    },
    readCookie: function readCookie(key) {
      var value = document.cookie.match(new RegExp(key + '=([^;]+)')) || [];
      return decodeURIComponent(value[1] || '');
    },
    writeCookie: function writeCookie(key, value, expiration) {
      var expires = Ember.isEmpty(expiration) ? '' : '; expires=' + new Date(expiration).toUTCString();
      var secure = !!this._secureCookies ? ';secure' : '';
      var path = ";path=/";
      document.cookie = key + '=' + encodeURIComponent(value) + expires + secure + path;
    },
    loginToFacebook: function loginToFacebook(inviteToken, resolve, reject) {
      var self = this;
      // Check if we already have a cookie from the signup page and use that if so
      var token = self.readCookie('signup-facebookAccessToken');
      if (token) {
        Ember.run(function () {
          self.writeCookie('signup-facebookAccessToken', null, 0);
          self.authenticateWithFacebookToken(token, inviteToken, resolve, reject);
        });
      } else {
        try {
          FB.login(function (fbResponse) {
            if (fbResponse.authResponse) {
              Ember.run(function () {
                self.authenticateWithFacebookToken(fbResponse.authResponse.accessToken, inviteToken, resolve, reject);
              });
            } else {
              reject();
            }
          }, { scope: 'public_profile,email,publish_actions' });
        } catch (error) {
          reject();
        }
      }
    },

    authenticate: function authenticate(options) {
      var self = this;
      var inviteToken = options.inviteToken;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        self.loginToFacebook(inviteToken, resolve, reject);
      });
    }
  });
});