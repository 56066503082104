define('tinybeans-frontend/routes/main/journals/invite', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Followers.category,
    screen: _tracker.Followers.invite.screen,

    model: function model(params) {
      var journalId = params.journal_id;

      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        emailFrequencyTypes: this.API.listEmailFrequencyTypes(),
        followings: this.modelFor('main').followings,
        relations: this.API.getFollowerRelations(),
        invite: {
          firstName: null,
          lastName: null,
          emailAddress: null,
          viewEntries: true,
          addEntries: false,
          viewMilestones: true,
          editMilestones: false,
          message: null
        }
      });
    }
  });
});