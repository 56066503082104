define('tinybeans-frontend/controllers/main/subscription-products/confirmation', ['exports', 'tinybeans-frontend/controllers/base-controller'], function (exports, _baseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({

    journalId: null,

    actions: {
      transitionToJournalRoute: function transitionToJournalRoute(journalId) {
        // For guessing contest removed on 24/March/2017 TODO
        var self = this;

        self.GTM.trackEvent({
          'eventCategory': 'premium_confirmation_page',
          'eventAction': 'click',
          'eventLabel': 'confirmation_start_premium_button'
        });

        self.transitionToRoute('main.journals.view', journalId);
        // For guessing contest removed on 24/March/2017 TODO
      }
    }

  });
});