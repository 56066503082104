define('tinybeans-frontend/components/main/pets/milestones/date-selection-dialog', ['exports', 'tinybeans-frontend/tracker'], function (exports, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    dateSelectionOptionValues: [{
      name: 'today',
      label: 'Today'
    }, {
      name: 'yesterday',
      label: 'Yesterday'
    }, {
      name: 'oneWeekAgo',
      label: 'One week ago'
    }, {
      name: 'twoWeeksAgo',
      label: 'Two weeks ago'
    }, {
      name: 'selectDate',
      label: 'Select date'
    }, {
      name: 'notSure',
      label: 'Not sure'
    }],
    dateSelectionValue: 'today',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var screen = _tracker.Pet.milestoneDetails.screen;

      _tracker.Tracker.trackEvent({
        action: screen + ' ' + _tracker.CommonActions.screenView,
        category: _tracker.Pet.category,
        screen: screen
      });
    },


    showSpecificDateOption: Ember.computed('dateSelectionValue', function () {
      return this.get('dateSelectionValue') == 'selectDate';
    }),

    descriptionWithLineBreaks: Ember.computed('currentlySelectedMilestone', function () {
      var milestone = this.get('currentlySelectedMilestone');
      var safelyEscapedCaption = Ember.Handlebars.Utils.escapeExpression(milestone.description);
      var linebrokenCaption = safelyEscapedCaption.replace(/(?:\r\n|\r|\n)/g, '<br />');
      return new Ember.Handlebars.SafeString(linebrokenCaption);
    }),

    saveCurrentMilestone: function saveCurrentMilestone(date) {
      Ember.$(".small-spinner").show();
      Ember.$(".milestone-dialog-inner .notCompleted").hide();
      Ember.$(".small-spinner").show();
      Ember.$(".milestone-dialog-inner .notCompleted").hide();
      this.sendAction('saveMilestone', date);
    },


    actions: {
      selectDate: function selectDate(value) {
        this.set('dateSelectionValue', value);
      },
      saveCustomDate: function saveCustomDate() {
        // With dateSelectionValue save the date as appropriate
        var dateSelectionValue = this.get('dateSelectionValue');

        if (dateSelectionValue == 'today') {

          this.saveCurrentMilestone(moment());
        } else if (dateSelectionValue == 'yesterday') {
          this.saveCurrentMilestone(moment().subtract(1, 'days'));
        } else if (dateSelectionValue == 'oneWeekAgo') {
          this.saveCurrentMilestone(moment().subtract(1, 'weeks'));
        } else if (dateSelectionValue == 'twoWeeksAgo') {
          this.saveCurrentMilestone(moment().subtract(2, 'weeks'));
        } else if (dateSelectionValue == 'selectDate') {
          this.saveCurrentMilestone(moment(this.get('currentFormattedDate'), this.get('usersDateFormat')));
        } else if (dateSelectionValue == 'notSure') {
          this.saveCurrentMilestone(null);
        } else {
          this.saveCurrentMilestone(null);
        }
      },
      close: function close() {
        this.sendAction('closeModalDialog');
      },
      track: function track(what) {
        var action = 'unknown';

        switch (what) {
          case 'close':
            action = _tracker.Pet.milestoneDetails.closeButton;
            break;
          case 'complete':
            action = _tracker.Pet.milestoneDetails.completeButton;
            break;
          default:

        }
        _tracker.Tracker.trackEvent({
          category: _tracker.Pet.category,
          screen: _tracker.Pet.milestoneDetails.screen,
          action: action
        });
      }
    }
  });
});