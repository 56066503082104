define('tinybeans-frontend/routes/main/gift-card/redeem-gift-card-success', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        me: this.store.find('user', 'me'),
        isSubscribedUser: this.API.getActiveSubscription()
      });
    },

    actions: {},
    setupController: function setupController(controller, model, error) {
      Ember.Logger.debug(error.message);
      var subscription = model.isSubscribedUser.subscription;

      // Subscription LP Updated
      Leanplum.addVariablesChangedHandler(function () {
        var vars = Leanplum.getVariables();
        if (vars.Paywall) {
          var paywall_products = JSON.parse(vars.Paywall.newProducts);
          controller.set('benefits', paywall_products.benefits);
        }
      });

      if (subscription && subscription.isGifted) {
        if (subscription.expiryDateDisplay) {
          controller.set('expiryDate', moment(subscription.expiryDateDisplay, 'DD MMM YYYY').format('MMMM DD, YYYY'));
        }

        if (subscription.expiryDate === null || subscription.startDate === null) {
          controller.set('durationText', "Unknown");
        }

        var durationInDays = Math.round((subscription.expiryDate - subscription.startDate) / (1000 * 3600 * 24));

        // Check for predefined durations
        // Convert days into months
        var months = Math.round(durationInDays / 30.44);
        var durationText = void 0;

        if (months < 1) {
          durationText = durationInDays + ' day' + (durationInDays > 1 ? 's' : '') + ' subscription';
        } else {
          durationText = months + ' month' + (months > 1 ? 's' : '') + ' subscription';
        }
        controller.set('durationText', durationText);
      }
    }
  });
});