define('tinybeans-frontend/utils/bootstrap-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isOpenDropdown = isOpenDropdown;
  exports.toggleDropdown = toggleDropdown;
  exports.openDropdown = openDropdown;
  exports.closeDropdown = closeDropdown;
  /**
   * These functions are for manually firing bootstrap dropdown events.
   * Usually they can be called by using `.dropdown('toggle')` but doing so will erase
   * Ember actions for some reason
   */

  function isOpenDropdown(selector) {
    var dropdown = Ember.$(selector);
    return dropdown.parent().hasClass('open');
  }

  function toggleDropdown(selector) {
    var dropdown = Ember.$(selector);
    var dropdownContainer = dropdown.parent();
    dropdownContainer.toggleClass('open');
    var dropdownButton = dropdownContainer.children('button[data-toggle="dropdown"], button.dropdown-toggle');
    dropdownButton.attr('aria-expanded', function (i, val) {
      return val === 'true' ? 'false' : 'true';
    });
  }

  function openDropdown(selector) {
    var dropdown = Ember.$(selector);
    var dropdownContainer = dropdown.parent();
    dropdownContainer.addClass('open');
    var dropdownButton = dropdownContainer.children('button[data-toggle="dropdown"], button.dropdown-toggle');
    dropdownButton.attr('aria-expanded', 'true');
  }

  function closeDropdown(selector) {
    var dropdown = Ember.$(selector);
    var dropdownContainer = dropdown.parent();
    dropdownContainer.removeClass('open');
    var dropdownButton = dropdownContainer.children('button[data-toggle="dropdown"], button.dropdown-toggle');
    dropdownButton.attr('aria-expanded', 'false');
  }
});