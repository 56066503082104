define('tinybeans-frontend/initializers/tinybeans-backend', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('controller', 'API', 'service:tinybeans-backend');
    application.inject('route', 'API', 'service:tinybeans-backend');
    application.inject('component', 'API', 'service:tinybeans-backend');
    application.inject('adapter', 'REST', 'service:rest-backend');
  }

  exports.default = {
    name: 'tinybeans-backend',
    after: 'ember-i18n',
    initialize: initialize
  };
});