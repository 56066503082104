define("tinybeans-frontend/templates/components/video-viewer-for-entry", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 7,
                "column": 2
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/video-viewer-for-entry.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "src", "images/hidden-mode-icon-orange.png");
            dom.setAttribute(el2, "width", "24");
            dom.setAttribute(el2, "height", "24");
            dom.setAttribute(el2, "class", "hidden-mode-icon");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "hidden-mode-info");
            var el3 = dom.createTextNode("Only owner and the full access user can view this moment");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["hidden-mode-container ", ["subexpr", "if", [["get", "entry.hasCaption", ["loc", [null, [3, 43], [3, 59]]], 0, 0, 0, 0], " smaller-gap"], [], ["loc", [null, [3, 38], [3, 76]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/video-viewer-for-entry.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("video");
          dom.setAttribute(el1, "class", "video-js vjs-default-skin");
          dom.setAttribute(el1, "controls", "");
          dom.setAttribute(el1, "preload", "none");
          dom.setAttribute(el1, "width", "100%");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("source");
          dom.setAttribute(el2, "type", "video/mp4");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("source");
          dom.setAttribute(el2, "type", "video/webm");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "vjs-no-js");
          var el3 = dom.createTextNode("To view this video please enable JavaScript, and consider upgrading to a\n      web browser that ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "//videojs.com/html5-video-support/");
          dom.setAttribute(el3, "target", "_blank");
          var el4 = dom.createTextNode("supports HTML5 video");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [2]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createAttrMorph(element2, 'poster');
          morphs[2] = dom.createAttrMorph(element3, 'src');
          morphs[3] = dom.createAttrMorph(element4, 'src');
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "entry.privateMode", ["loc", [null, [2, 8], [2, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [7, 9]]]], ["attribute", "poster", ["subexpr", "if", [["get", "reorderMode", ["loc", [null, [9, 92], [9, 103]]], 0, 0, 0, 0], ["get", "entry.blobs.s2", ["loc", [null, [9, 104], [9, 118]]], 0, 0, 0, 0], ["get", "entry.blobs.l", ["loc", [null, [10, 4], [10, 17]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [10, 19]]], 0, 0], 0, 0, 0, 0], ["attribute", "src", ["get", "entry.attachmentUrl_mp4", ["loc", [null, [11, 18], [11, 41]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["get", "entry.attachmentUrl_webm", ["loc", [null, [12, 18], [12, 42]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 2
              },
              "end": {
                "line": 22,
                "column": 2
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/video-viewer-for-entry.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "src", "images/hidden-mode-icon-orange.png");
            dom.setAttribute(el2, "width", "24");
            dom.setAttribute(el2, "height", "24");
            dom.setAttribute(el2, "class", "hidden-mode-icon");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "hidden-mode-info");
            var el3 = dom.createTextNode("Only owner and the full access user can view this moment");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["hidden-mode-container ", ["subexpr", "entry.hasCaption", [" smaller-gap"], [], ["loc", [null, [18, 38], [18, 73]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/video-viewer-for-entry.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "video-processing");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "images/video-processing-snail-icon.svg");
          dom.setAttribute(el2, "width", "24");
          dom.setAttribute(el2, "height", "24");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 3]), 0, 0);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "entry.privateMode", ["loc", [null, [17, 8], [17, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 2], [22, 9]]]], ["content", "entry.videoMessage", ["loc", [null, [26, 10], [26, 32]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 7
          }
        },
        "moduleName": "tinybeans-frontend/templates/components/video-viewer-for-entry.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "entry.isVideoProcessed", ["loc", [null, [1, 6], [1, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [28, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});