define('tinybeans-frontend/serializers/collection', ['exports', 'ember-data/serializers/rest', 'ember-data/serializers/embedded-records-mixin'], function (exports, _rest, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _rest.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      earliestEntry: {
        embedded: 'always'
      },
      latestEntry: {
        embedded: 'always'
      },
      pinnedTagEntry: {
        embedded: 'always'
      },
      tagEntries: {
        embedded: 'always'
      }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      delete payload.status; // Status messes up the JSON parser

      // For each tag add a links property that points to the collection entries
      if (requestType === 'query' || requestType === 'findAll') {
        if (payload.tags) {
          for (var i = 0; i < payload.tags.length; i++) {
            if (!payload.tags[i].tagEntries) {
              payload.tags[i].tagEntries = [];
            }
            payload.tags[i].links = {
              tagEntries: '/api/1/journals/' + payload.tags[i].journalId + '/tags/' + payload.tags[i].id
            };
          }
        } else {
          payload.tags = [];
        }

        payload.collections = payload.tags;
        delete payload.tags;
      } else {
        if (!payload.tag.tagEntries) {
          payload.tag.tagEntries = [];
        }
        payload.collection = payload.tag || {};
        delete payload.tag;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalize: function normalize(model, payload, prop) {
      if (payload.userId) {
        // Make the name nicer
        payload.user = payload.userId;
        delete payload.userId;
      }

      if (payload.journalId) {
        payload.journal = payload.journalId;
        delete payload.journalId;
      }

      if (!payload.tagEntries) {
        payload.tagEntries = [];
      }
      return this._super(model, payload, prop);
    }
  }
  //
  // serialize(snapshot, options) {
  //   return {
  //     name: snapshot.attr('name'),
  //     description: snapshot.attr('description'),
  //     isPublic: snapshot.attr('isPublic'),
  //     themeId: 1
  //   };
  // }
  );
});