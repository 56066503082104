define('tinybeans-frontend/serializers/following', ['exports', 'ember-data/serializers/rest', 'ember-data/serializers/embedded-records-mixin'], function (exports, _rest, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _rest.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      user: { embedded: 'always' },
      relationship: { embedded: 'always' },
      emailFrequencyOnNewEvent: { embedded: 'always' },
      journal: { embedded: 'always' }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      delete payload.status; // Status messes up the JSON parser

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});