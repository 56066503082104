define('tinybeans-frontend/initializers/leanplum', ['exports', 'tinybeans-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    try {
      var env = _environment.default.environment;
      if (env === 'production') {
        Leanplum.setAppIdForProductionMode(_environment.default.lpKey, _environment.default.lpSecret);
      } else {
        Leanplum.setAppIdForDevelopmentMode(_environment.default.lpKey, _environment.default.lpSecret);
      }
      Leanplum.start();
    } catch (e) {}
  }

  exports.default = {
    name: 'leanplum',
    initialize: initialize
  };
});