define('tinybeans-frontend/router', ['exports', 'tinybeans-frontend/config/environment', 'tinybeans-frontend/mixins/page-view'], function (exports, _environment, _pageView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_pageView.default, { location: _environment.default.locationType });

  Router.map(function () {
    this.route('login');
    this.route('reset-password-request');
    this.route('signup');

    // deeplinks not handled
    this.route('download-app');

    this.route('main', function () {
      this.route('show', function () {
        this.route('checklist', { path: '/:journal_id/:child_id/checklist/:checklist_item_id' });
      });

      this.route('journals', function () {
        this.route('home');
        this.route('list');

        // add_moment/:journal_id has priority when called from code, so must give journal_id
        this.route('add-moment', { path: 'add_moment' }); // To today or to the EXIF given
        this.route('add-moment', { path: 'add_moment/:journal_id' }); // To today or to the EXIF given
        this.route('month', { path: ':journal_id/:year/:month' });
        this.route('view', { path: ':journal_id' });

        // follower-list/:journal_id has priority when called from code, so must give journal_id
        this.route('follower-list');
        this.route('follower-list', { path: '/followers-list' });
        this.route('follower-list', { path: '/follower-list/:journal_id' });
        this.route('follower-list-invite-start', function () {
          this.route('deeplink');
        });
        this.route('export', { path: 'export/:journal_id' });
        this.route('edit', { path: 'manage/:journal_id' });
        this.route('follower-list-invite', { path: 'invite/:journal_id' });
        this.route('search', { path: 'search/:journal_id' });
        this.route('add');
        this.route('hidden-moment', { path: 'hidden-moment/:journal_id' });
        this.route('loading');
        this.route('no-journal');
      });

      this.route('entries', function () {
        this.route('list', { path: ':journal_id/:year/:month/:day' });
        this.route('view', { path: ':entry_id/:uuid' });
        this.route('loading');
        this.route('error', { path: '/:error' });
      });

      this.route('account', function () {
        this.route('show');
        this.route('loading');
        this.route('confirm-details');
      });

      this.route('children', function () {
        this.route('add', { path: '/add/:journal_id' });
        this.route('edit', { path: 'edit/:journal_id/:child_id' });
        this.route('list', { path: 'list/:journal_id' });

        this.route('milestones', function () {
          this.route('checklist', { path: 'checklist/:journal_id/:child_id/:checklist_id' });
          this.route('weight', { path: 'weight/:journal_id/:child_id' });
          this.route('height', { path: 'height/:journal_id/:child_id' });
          this.route('complete-milestone', { path: 'complete-milestone/:child_id/:milestone_id' });
          this.route('loading');
        });
        this.route('show', { path: 'show/:journal_id/:child_id/checklist/:checklist_id' });
        this.route('channel', { path: 'channel/:journal_id/:child_id' });
        this.route('loading');
      });

      this.route('pets', function () {
        this.route('add', { path: '/add/:journal_id' });
        this.route('edit', { path: 'edit/:journal_id/:pets_id' });

        this.route('milestones', function () {
          this.route('checklist', { path: 'checklist/:journal_id/:pets_id/:checklist_id' });
          this.route('loading');
        });
        this.route('channel', { path: 'channel/:journal_id/:pets_id' });
        this.route('loading');
      });

      this.route('payment-transactions', function () {});

      this.route('payment-details', function () {
        this.route('show', function () {});
      });

      this.route('subscription-products', function () {
        this.route('list');
        //this.route('select-journal');
        this.route('success');
        // this.route('voucher');
        // this.route('purchase-voucher-success');
        // this.route('voucher-success', {path: 'voucher-success/:journal_id'});
        /// this.route('purchase-voucher');
        this.route('loading');
        //remove after jellybean contest finished TODO
        // this.route('confirmation', {path: 'confirmation/:journal_id'});
        //remove after jellybean contest finished TODO
      });

      this.route('refer-friend', function () {
        this.route('show');
        this.route('loading');
      });
      this.route('subscription', function () {
        this.route('manage', function () {});
        this.route('cancel', function () {});
        this.route('loading');
      });
      this.route('billing', function () {
        this.route('show', function () {});
        this.route('loading');
      });

      this.route('products', function () {
        this.route('list');
        this.route('loading');
      });
      this.route('printio');

      this.route('collections', function () {
        this.route('list', { path: '/list/:journal_id/' });
        this.route('show', { path: '/:journal_id/:path' });
        this.route('add', { path: '/add/:journal_id/' });
        this.route('edit', { path: '/edit/:journal_id/:path/:year/:month' });
        this.route('organize', { path: '/organize/:journal_id/:path/' });
        this.route('loading');
      });

      this.route('email-settings', { path: 'email-settings/:token' });

      this.route('onboarding', function () {

        this.route('step-start', { path: '/step-start' });
        this.route('step-one', { path: '/step-one/:journal_id' });
        this.route('step-two', { path: '/step-two/:journal_id' });
        this.route('step-three', { path: '/step-three/:journal_id' });
        this.route('step-four', { path: '/step-four/:journal_id' });
        this.route('step-five', { path: '/step-five/:journal_id' });
      });
      this.route('accept-invite');
      this.route('invalid-invite-token');

      this.route('photo-frame', function () {
        this.route('show');
      });

      this.route('themed-cards', function () {
        this.route('macmillan', function () {
          this.route('show');
        });
      });

      this.route('offers', function () {
        this.route('offer');
        this.route('confirmation');
        this.route('ineligible');
      });

      this.route('gift-card', function () {
        this.route('redeem-gift-card');
        this.route('redeem-gift-card-success');
      });
    });

    this.route('index', { path: '/' });
    this.route('index', { path: 'main/tab_bar/select-index/:index' });

    this.route('logout');
    this.route('channelSubscription');
    this.route('loading');
    this.route('login-instructions');
    this.route('reset-password', { path: '/password/:token' });
    this.route('simple-redirect');
    this.route('mom365');
    this.route('server-error', { path: '/500' });

    // deeplinks handling
    this.route('index', { path: '/home/show' });
    this.route('index', { path: '/main/memoriesCalendar/show' });
    this.route('main.subscription.deeplink', { path: '/main/tinybeansPlus/share-seat' });
    this.route('main.journals.follower-list-invite-start.deeplink', { path: '/main/invite-family/show' });
    // deeplinks not handled by web
    // anything with '/private/*' hits the redirect router
    // this.route('deeplinks', {path: '/private/*path'});
    // this.route('main', {path: '/private/main/memoriesCalendar/show'});main/referrals-settings/show
    this.route('deeplinks', { path: '/discount/show' });
    this.route('deeplinks', { path: '/main/home/show' });
    this.route('deeplinks', { path: '/main/referrals-settings/show' });
    this.route('deeplinks', { path: '/main/albums/smart/flashback' });

    this.route('not-found', { path: '/*path' });
    this.route('event-signup');
    this.route('i', { path: 'i/:token' });
    this.route('social', { path: '/social/:entry_id/:uuid' });
  });

  exports.default = Router;
});