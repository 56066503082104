define('tinybeans-frontend/controllers/main/collections/add', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash'], function (exports, _baseController, _commonsMixin, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {

    submitting: false,
    saveButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? 'Please wait ...' : 'Next';
    }.property('submitting'),

    collectionEnabled: Ember.computed('property', function () {
      return this.globals.photoCollectionEnabled(this.get('model.journal'));
    }),

    actions: {
      save: function save() {
        var _this = this;

        var collection = this.get('model.collection');
        var journalId = this.get("model.journalId");

        if (!collection.get('name')) {
          this.get('flashMessages').clearMessages();
          this.get('flashMessages').add({
            message: 'Oops, album name can\'t be empty',
            timeout: 3000,
            addMessage: true,
            type: 'alert alert-warning'
          });
          return;
        }

        var nameExists = _lodash.default.any(this.get('model.collections').toArray(), function (c) {
          return collection.get('name') === c.get('name');
        });

        if (nameExists) {
          this.get('flashMessages').clearMessages();
          this.get('flashMessages').add({
            message: 'Oops, the album name might already exist',
            timeout: 3000,
            addMessage: true,
            type: 'alert alert-warning'
          });
          return;
        }
        this.set('submitting', true);
        collection.save({ adapterOptions: { journalId: journalId } }).then(function (collection) {
          var d = new Date();
          _this.transitionToRoute('main.collections.edit', journalId, collection.get('path'), d.getFullYear(), d.getMonth() + 1);
          _this.set('submitting', false);
        }, function () {
          _this.set('submitting', false);
          _this.get('flashMessages').clearMessages();
          _this.get('flashMessages').add({
            message: 'Oops, cannot create album.',
            timeout: 3000,
            addMessage: true,
            type: 'alert alert-danger',
            glyphiconClass: 'glyphicon glyphicon-remove'
          });
        });
      },

      upgrade: function upgrade(journalId) {
        this.transitionToRoute('main.subscription-products.list', {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      }
    }
  });
});