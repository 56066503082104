define('tinybeans-frontend/controllers/main/children/milestones/weight', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    selectedMeasurement: null,

    dateSelectionOptionValues: [{
      name: 'today',
      label: 'Today'
    }, {
      name: 'yesterday',
      label: 'Yesterday'
    }, {
      name: 'oneWeekAgo',
      label: 'One week ago'
    }, {
      name: 'twoWeeksAgo',
      label: 'Two weeks ago'
    }, {
      name: 'selectDate',
      label: 'Select date'
    }, {
      name: 'notSure',
      label: 'Not sure'
    }],

    backgroundStyle: Ember.computed('model.child', function () {
      return 'background-image: url(\'' + this.get('model.child.avatars.l') + '\'); background-size: cover; background-position: center;';
    }),

    measurementSystem: Ember.computed('selectedMeasurement', function () {
      return this.get('selectedMeasurement') || this.get('userMeasurementSystem.name');
    }),

    showSpecificDateOption: Ember.computed('dateSelectionValue', function () {
      return this.get('dateSelectionValue') == 'selectDate';
    }),

    weights: Ember.computed('model.weights', 'measurementSystem', function () {
      var _this = this;

      var weights = this.get('model.weights').toArray();
      weights = _lodash.default.map(weights, function (weight) {
        var measurementDate = new Date(weight.get('year'), weight.get('month') - 1, weight.get('day'));
        weight.set('measurementDate', measurementDate);
        weight.set('formattedDate', moment(measurementDate).format(_this.globals.usersDateFormat()));
        var weightMeasurementSystem = weight.get('measurementSystem.name');
        var measurementSystem = _this.get('measurementSystem');
        if (weightMeasurementSystem === 'METRIC' && measurementSystem === 'IMPERIAL') {
          var pound = Math.floor(Number(weight.get('value')) * 35.274 / 16);
          var ounce = Math.round(Number(weight.get('value')) * 35.274 - pound * 16).toFixed(0);
          weight.set('formattedWeight', pound + ' lb ' + ounce + ' oz');
        } else if (weightMeasurementSystem === 'IMPERIAL' && measurementSystem === 'METRIC') {
          weight.set('formattedWeight', (Number(weight.get('value')) * 16 * 0.0283495).toFixed(1) + ' kg');
        } else if (measurementSystem === 'METRIC') {
          weight.set('formattedWeight', weight.get('value').toFixed(1) + ' kg');
        } else {
          var pounds = parseInt(weight.get('value'));
          var ounces = ((weight.get('value') - pounds) * 16).toFixed(0);
          weight.set('formattedWeight', pounds + ' lb ' + ounces + ' oz');
        }
        return weight;
      });
      return weights.reverse().sort(function (a, b) {
        return b.get('measurementDate') - a.get('measurementDate');
      });
    }),

    saveWeight: function saveWeight(date) {
      var _this2 = this;

      var submitWeight = true;

      var year = date.year();
      var month = date.month() + 1;
      var day = date.date();

      var childId = this.get('model.child.id');

      var measurementSystem = this.get('measurementSystem');
      var value = 0;

      if (measurementSystem === "METRIC") {
        value = Number(Ember.$("#newWeightKg").val());
        if (!value || value === 0) {
          submitWeight = false;
          bootbox.dialog({
            title: "Uh oh. ",
            message: "There was an error. Did you enter in the correct number?",
            buttons: {
              cancel: {
                label: "OK",
                className: "btn-mint"
              }
            }
          });
        }
      } else {
        var pound = Number(Ember.$("#newWeightPound").val());
        var ounce = Number(Ember.$("#newWeightOunce").val());
        if (ounce > 16 || !(pound || ounce)) {
          submitWeight = false;
          bootbox.dialog({
            title: "Uh oh.",
            message: "There was an error. Did you enter in the correct number?",
            buttons: {
              cancel: {
                label: "OK",
                className: "btn-mint"
              }
            }
          });
        } else {
          value = pound + ounce / 16;
        }
      }

      if (submitWeight) {
        var weight = this.store.createRecord('weight', {
          year: year,
          month: month,
          day: day,
          value: value,
          measurementSystem: measurementSystem
        });
        weight.save({ adapterOptions: { childId: childId } }).then(function () {
          var weights = _this2.get('model.weights').toArray();
          weights.addObject(weight);
          _this2.set('model.weights', weights);
        }, function (error) {
          console.log(error);
        });
      }
      _tracker.Tracker.trackEvent({
        category: _tracker.Child.category,
        screen: _tracker.Child.weight.screen,
        action: _tracker.Child.weight.addWeight
      });
    },

    actions: {
      selectMeasurement: function selectMeasurement(system) {
        this.set('selectedMeasurement', system);
      },
      selectDate: function selectDate(value) {
        this.set('dateSelectionValue', value);
      },


      deleteSelectedWeight: function deleteSelectedWeight(weight) {
        var _this3 = this;

        var childId = this.get('model.child.id');
        var deletedWeightId = "#" + weight.id;
        Ember.$(deletedWeightId).addClass("deleteHighlight");

        bootbox.dialog({
          title: "Are you sure to delete this weight?",
          message: "Deleted weight value cannot be retrieved.",
          buttons: {
            ok: {
              label: 'OK',
              className: "btn-mint",
              callback: function callback() {
                weight.destroyRecord({
                  adapterOptions: {
                    childId: childId
                  }
                }).then(function () {
                  Ember.$(deletedWeightId).fadeOut();
                  var weights = _this3.get('model.weights').toArray();
                  weights.removeObject(weight);
                  _this3.set('model.weights', weights);
                }, function (xhr) {
                  console.log(xhr);
                });
              }
            },
            cancel: {
              label: "Cancel",
              className: "btn-primary",
              callback: function callback() {
                Ember.$(deletedWeightId).removeClass("deleteHighlight");
              }
            }
          }
        });
      },

      cancelDateChange: function cancelDateChange() {
        var self = this;
        self.route.disconnectOutlet({
          outlet: 'modal-dialog'
        });
        Ember.$(".modal-backdrop").hide();
      },

      saveCustomDate: function saveCustomDate() {
        // With dateSelectionValue save the date as appropriate
        var dateSelectionValue = this.get('dateSelectionValue');
        if (dateSelectionValue == 'today') {
          this.saveWeight(moment());
        } else if (dateSelectionValue == 'yesterday') {
          this.saveWeight(moment().subtract(1, 'days'));
        } else if (dateSelectionValue == 'oneWeekAgo') {
          this.saveWeight(moment().subtract(1, 'weeks'));
        } else if (dateSelectionValue == 'twoWeeksAgo') {
          this.saveWeight(moment().subtract(2, 'weeks'));
        } else if (dateSelectionValue == 'selectDate') {
          this.saveWeight(moment(this.get('currentFormattedDate'), this.get('usersDateFormat')));
        } else {
          this.saveWeight(moment());
        }
      }

    }
  });
});