define('tinybeans-frontend/components/main/children/milestones/child-summary', ['exports', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash'], function (exports, _commonsMixin, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_commonsMixin.default, {

    currentWeight: Ember.computed('model.child', 'model.weights', 'userMeasurementSystem', function () {
      if (_lodash.default.isEmpty(this.get('model.weights').toArray())) return 'Enter weight';
      var weight = _lodash.default.max(this.get('model.weights').toArray(), function (weight) {
        return new Date(weight.get('year'), weight.get('month'), weight.get('day'));
      });

      var weightMeasurementSystem = weight.get('measurementSystem.name');
      var userMeasurementSystem = this.get('userMeasurementSystem.name');

      if (weightMeasurementSystem === 'METRIC' && userMeasurementSystem === 'IMPERIAL') {
        var pound = Math.floor(Number(weight.get('value')) * 35.274 / 16);
        var ounce = Math.round(Number(weight.get('value')) * 35.274 - pound * 16).toFixed(0);
        weight.set('formattedWeight', pound + ' lb ' + ounce + ' oz');
      } else if (weightMeasurementSystem === 'IMPERIAL' && userMeasurementSystem === 'METRIC') {
        weight.set('formattedWeight', (Number(weight.get('value')) * 16 * 0.0283495).toFixed(1) + ' kg');
      } else if (userMeasurementSystem === 'METRIC') {
        weight.set('formattedWeight', weight.get('value').toFixed(1) + ' kg');
      } else {
        var pounds = parseInt(weight.get('value'));
        var ounces = ((weight.get('value') - pounds) * 16).toFixed(0);
        weight.set('formattedWeight', pounds + ' lb ' + ounces + ' oz');
      }

      return weight.get('formattedWeight');
    }),

    currentHeight: Ember.computed('model.child', 'model.heights', 'userMeasurementSystem', function () {
      if (_lodash.default.isEmpty(this.get('model.heights').toArray())) return 'Enter height';
      var height = _lodash.default.max(this.get('model.heights').toArray(), function (height) {
        return new Date(height.get('year'), height.get('month'), height.get('day'));
      });

      var measurementSystem = this.get('userMeasurementSystem.name');
      var heightMeasurementSystem = height.get('measurementSystem.name');

      if (heightMeasurementSystem === 'METRIC' && measurementSystem === 'IMPERIAL') {
        var feet = Math.floor(Number(height.get('value')) * 0.3937 / 12);
        var inches = Math.round((Number(height.get('value')) * 0.3937 - feet * 12).toFixed(0));
        height.set('formattedHeight', feet + ' ft ' + inches + ' in');
      } else if (heightMeasurementSystem === 'IMPERIAL' && measurementSystem === 'METRIC') {
        height.set('formattedHeight', Math.round(Number(height.get('value')) * 2.54).toFixed(0) + ' cm');
      } else if (measurementSystem === 'METRIC') {
        height.set('formattedHeight', height.get('value').toFixed(1) + ' cm');
      } else {
        var _feet = parseInt(height.get('value') / 12);
        var _inches = (height.get('value') - 12 * _feet).toFixed(0);
        height.set('formattedHeight', _feet + ' ft ' + _inches + ' in');
      }
      return height.get('formattedHeight');
    }),

    milestoneCount: Ember.computed('model.child', 'model.completedChecklistItems', function () {
      return _lodash.default.filter(this.get('model.completedChecklistItems').toArray(), function (item) {
        return !item.get('deleted');
      }).length;
    }),

    backgroundStyle: Ember.computed('model.child', function () {
      if (this.get('model.child.avatars.l')) {
        return "background-image: url('" + this.get('model.child.avatars.l') + "'); background-size: cover; background-position: center;";
      } else {
        return "background-image: url('" + "https://tinybeans-public.s3-us-west-2.amazonaws.com/images/avatar-child-l.png" + "'); background-size: contain; background-position: center;"; //'images/generic-baby_nobackground.png'
      }
    })

  });
});