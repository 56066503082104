define("tinybeans-frontend/controllers/main/entries/list", ["exports", "tinybeans-frontend/controllers/base-controller", "tinybeans-frontend/mixins/commons-mixin", "lodash/lodash", "tinybeans-frontend/tracker", "tinybeans-frontend/services/lotame"], function (exports, _baseController, _commonsMixin, _lodash, _tracker, _lotame) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var PREVDAY = -1,
      NEXTDAY = 1;

  exports.default = _baseController.default.extend(_commonsMixin.default, {
    needs: ["main"],
    entriesToUpload: [],
    submitting: false,

    addNewEntry: function addNewEntry(file) {
      var entry = Ember.Object.create({
        elementId: Math.random().toString(36).substring(7),
        file: file,
        children: [],
        pets: []
      });
      entry.set("date", this.get("date").format(this.get("model.me.dateFormat")));
      this.get("entriesToUpload").pushObject(entry);
      return entry;
    },
    render: function render(element) {
      element.find(".preview").remove();
      var img = $("<img src='images/not-available.png'>").width("100%").height("100%");
      element.append(img);
    },
    renderImage: function renderImage(file, element) {
      element.find(".preview").remove();
      var preview = document.createElement("div");
      $(preview).addClass("preview");
      preview.style.backgroundSize = "contain";
      preview.style.backgroundRepeat = "no-repeat";
      preview.style.backgroundPosition = "top center";
      preview.style.height = "290px";
      element.append(preview);
      var reader = new FileReader();
      reader.onload = function (event) {
        preview.style.backgroundImage = "url(" + event.target.result + ")";
      };
      reader.readAsDataURL(file);
    },


    specificDateSelected: Ember.computed("day", "month", "year", function () {
      return this.get("day") && this.get("month") && this.get("year");
    }),

    renderVideo: function renderVideo(file, element, entry) {
      var fileUrl = URL.createObjectURL(file);
      element.find(".preview").remove();
      var vid = $("<video controls class='preview'><source id='vid-source' src='" + fileUrl + "' type='video/mp4'></video>");
      var upgraded = this.get('model.me.hasMemoriesAccess');
      var oldPremium = this.get('model.journal.isUpgraded');
      var isSubscridbed = this.get('isSubscribedUser');
      var maxLengthOld = oldPremium ? 300 : 30;
      var self = this;
      vid[0].ondurationchange = function () {
        if (upgraded) {
          if (oldPremium) {
            element.append(vid);
          } else {
            if (isSubscridbed) {
              element.append(vid);
            } else {
              if (this.duration > maxLengthOld) {
                bootbox.dialog({
                  title: 'Uh oh.',
                  message: 'You can\'t add videos longer than ' + maxLengthOld + ' seconds.<br>' + (oldPremium ? 'Please reduce video  and try again.' : 'Either edit the video to under 30 seconds or <a href=\"/app/#/main/subscription-products/list?journalid=' + self.get('model.journal.id') + '\">upgrade your Tinybeans</a> to unlock longer videos.'),
                  onEscape: function onEscape() {},
                  buttons: {
                    ok: {
                      label: 'OK'
                    }
                  }
                });
                self.get('entriesToUpload').removeObject(entry);
              } else {
                element.append(vid);
              }
            }
          }
        } else {
          bootbox.dialog({
            title: 'Uh oh.',
            message: 'You can\'t add videos longer than ' + maxLengthOld + ' seconds.<br>' + (upgraded ? 'Please reduce video  and try again.' : 'Either edit the video to under 30 seconds or <a href=\"/app/#/main/subscription-products/list?journalid=' + self.get('model.journal.id') + '\">upgrade your Tinybeans</a> to unlock longer videos.'),
            onEscape: function onEscape() {},
            buttons: {
              ok: {
                label: 'OK'
              }
            }
          });
          self.get('entriesToUpload').removeObject(entry);
        }
      };
    },
    highlightDate: function highlightDate(selector) {
      $(selector).animate({
        "background-color": "#bdd6e6"
      }, 100, function () {
        $(selector).animate({
          "background-color": "white"
        }, 5000);
      });
    },
    readExifDate: function readExifDate(entry) {
      var self = this;
      entry.set("date", self.get("date").format(self.get("model.me.dateFormat")));
    },
    loadFile: function loadFile(entry) {
      var _this = this;

      var file = entry.get("file");
      var elementId = entry.get("elementId");
      var tryAppend = function tryAppend() {
        var element = $("#" + elementId + " .content");
        if (element.length) {
          if (file.type.match(/image.*/)) {
            _this.renderImage(file, element);
          } else if (file.type.match(/video.*/)) {
            _this.renderVideo(file, element, entry);
          } else {
            _this.render(element);
          }
          return true;
        }
        return false;
      };
      if (!tryAppend()) {
        var retryInterval = window.setInterval(function () {
          if (tryAppend()) {
            window.clearInterval(retryInterval);
          }
        }, 500);
      }
      if (!this.get("specificDateSelected")) {
        this.readExifDate(entry);
      }
    },
    setupUpload: function setupUpload(year, month, day) {
      this.get("entriesToUpload").clear();
      this.set("date", moment({
        day: day,
        month: month,
        year: year
      }));
      this.addNewEntry();
    },
    didTransition: function didTransition() {
      this.setupUpload(this.get("model.year"), this.get("model.month") - 1, this.get("model.day"));

      this.set("reorderMode", false);
    },
    init: function init() {
      var _this2 = this;

      Ember.run.scheduleOnce("afterRender", function () {
        if (_this2.get("shouldShowAds")) {
          // googletag.cmd.push(function () {
          //   googletag.display('div-gpt-ad-1522951024798-0');
          // });
        }
      });
    },


    updateFancyBox: function () {
      Ember.$(".fancybox").fancybox({
        helpers: {
          title: {
            type: "outside"
          },
          thumbs: {
            width: 50,
            height: 50
          }
        }
      });
    }.observes("model.entries", "model.entries.@each"),

    isMyJournal: function () {
      return this.get("model.me.id") === this.get("model.journal.user.id");
    }.property("model.me", "model.journal"),

    hasAccessToJournal: function () {
      return this.get("isMyJournal") || this.get("meAsFollower");
    }.property("model.me", "model.followings", "model.journal"),

    hasUploadsLeft: function hasUploadsLeft() {
      var limit = this.get('model.memories_limit.limit');
      return !limit.threshold === limit.usedTimes;
    },

    canAddMoments: function () {
      return (this.get("isMyJournal") || this.get("meAsFollower.addEntries")) && this.get('hasUploadsLeft');
    }.property("model.me", "model.followings", "model.journal"),

    // returns entries with pinned entry as first one
    entriesForDay: function () {
      var _this3 = this;

      var entries = _lodash.default.filter(this.get("model.entries").toArray(), function (entry) {
        return entry.get("day") === Number(_this3.get("model.day")) && entry.get("month") === Number(_this3.get("model.month")) && entry.get("year") === Number(_this3.get("model.year"));
      });
      var pinnedEntryToFind = this.get("pinnedEntry");
      // need to search for `pinnedEntryToFind` as it may be a value that isn't inside the entries list
      var pinnedEntries = entries.filter(function (entry) {
        return entry === pinnedEntryToFind;
      });
      var unpinnedEntries = entries.filter(function (entry) {
        return entry !== pinnedEntryToFind;
      });
      return [].concat(_toConsumableArray(pinnedEntries), _toConsumableArray(unpinnedEntries));
    }.property("model.day", "model.month", "model.year", "model.entries", "model.entries.@each", "entryAdded", "pinnedEntry"),

    pinnedEntry: function () {
      var entries = this.get("model.entries").toArray();
      return _lodash.default.max(entries, function (entry) {
        return entry.get("pinnedTimestamp");
      }) || entries[0];
    }.property("model.entries", "model.entries.@each.pinnedTimestamp"),

    showSecondAds: Ember.computed("model.entries", function () {
      return this.get("model.entries.length") >= 1;
    }),

    getDate: function getDate(currentDate, dayMonthYear, whichDay) {
      var date = new Date(currentDate);
      date.setDate(date.getDate() + whichDay);

      switch (dayMonthYear) {
        case "year":
          return date.getFullYear();
        case "month":
          return date.getMonth() + 1;
        case "day":
          return date.getDate();
      }
    },
    doneReorder: function doneReorder() {
      Ember.$("#saveReorder").hide();
      Ember.$("#cancelReorder").hide();
    },


    noEntries: Ember.computed("model.entries", function () {
      return !this.get("model.entries.length") || this.get("model.entries.length") === 0;
    }),

    uploadEntry: function uploadEntry(entryIndex, entriesToUpload) {
      var _this4 = this;

      var self = this;
      self.get("globals").setProperties({
        fileNumber: entryIndex,
        totalFiles: entriesToUpload.length,
        uploadedPercentage: 0
      });
      if (entryIndex < entriesToUpload.length) {
        self.get("globals").set("showProgressBar", true);
        var entry = entriesToUpload.objectAt(entryIndex);
        var momentTime = entry.get("date") ? moment(entry.get("date"), self.get("model.me.dateFormat")) : moment();
        var newEntry = self.store.createRecord("entry", {
          day: momentTime.date(),
          month: momentTime.month() + 1,
          year: momentTime.year(),
          caption: entry.get("caption"),
          privateMode: entry.get("privateMode")
        });

        _lodash.default.each(entry.get("children").toArray(), function (child) {
          newEntry.get("children").pushObject(child);
        });

        _lodash.default.each(entry.get('pets').toArray(), function (pet) {
          newEntry.get('pets').pushObject(pet);
        });

        newEntry.save({
          adapterOptions: {
            journalId: self.get("model.journal.id"),
            file: entry.get("file")
          }
        }).then(function (response) {
          self.route.refresh();
          var months = _this4.get("months");
          _this4.send("displayNotification", "Moment added to <b>" + response.get("day") + " " + months[response.get("month")] + " " + response.get("year") + "</b>");

          var file = entry.file;
          var momentType = !file ? 'text' : file['type'].split('/')[0] === 'image' ? 'photo' : 'video';
          // const behavior = `moment added : type : ${momentType}`;
          // const lotame = new Lotame();
          // lotame.sendBehavior(behavior, undefined, this.get("globals.userId"));

          self.uploadEntry(entryIndex + 1, entriesToUpload); // Upload next file
        }, function (e) {
          if (e.status >= 400 && e.status <= 404) {
            bootbox.dialog({
              title: "Uh oh.",
              message: "Sorry we couldn't upload this file <b> " + entry.file.name + " </b>. Other files may have been uploaded. Please try again later or contact us for support (error code " + e.status + ").",
              onEscape: function onEscape() {},
              buttons: {
                ok: {
                  label: "OK"
                }
              }
            });
            self.uploadEntry(entryIndex + 1, entriesToUpload);
          } else {
            console.log("Retrying because..." + e);
            // Wait 5 seconds and retry
            Ember.run.later(function () {
              self.uploadEntry(entryIndex, entriesToUpload);
            }, 5000);
          }
        });
      } else {
        this.set("submitting", false);
        self.get("globals").set("showProgressBar", false);
        if (this.get("monthController.route")) {
          this.get("monthController.route").refresh();
        }
        if (this.get("entriesController.route")) {
          this.get("entriesController.route").refresh();
        }
      }
    },

    entryIsVideo: function entryIsVideo(entry) {
      // Image or video are not null
      if (entry.get("file")) {
        return entry.get("file").type.indexOf("video/") === 0;
      } else {
        return false;
      }
    },

    createVideoURL: function createVideoURL(entry) {
      // Load the video as a url
      return window.URL.createObjectURL(entry.file);
    },


    currentDate: Ember.computed("model.day", "model.month", "model.year", function () {
      return new Date(this.get("model.year"), this.get("model.month") - 1, this.get("model.day"));
    }),

    dayMonthYearLabel: Ember.computed("model.day", "model.month", "model.year", function () {
      var model = this.get("model");
      var date = moment({
        day: model.day,
        month: model.month - 1,
        year: model.year
      });
      var yearLabel = date.format("YYYY");
      var monthLabel = date.format("MMM");
      var dayLabel = date.format("D");
      var dayMonthYearString = monthLabel + " " + dayLabel;
      var dayMonthYearMobileString = dayMonthYearString + " " + yearLabel;

      return {
        year: yearLabel,
        month: monthLabel,
        day: dayLabel,
        string: dayMonthYearString,
        mobileString: dayMonthYearMobileString
      };
    }),

    // randomEntry: Ember.computed('model', 'model.randomEntry', 'model.randomEntry.id', function() {
    //   let randomEntry =  this.get('model.randomEntry');

    //   if(randomEntry){

    //     if (this.get('authenticated') && this.get('hasAccessToJournal')) {
    //       //logged && has access
    //       //the random entry is not the current entry
    //       // if( randomEntry.id != this.get('model.entry').id && randomEntry.blobs != null){
    //        if( randomEntry.blobs != null){
    //           randomEntry.thumbnailImage = randomEntry.blobs.s2;
    //           randomEntry.showThumbnail = true;

    //           let date = moment(randomEntry.timestamp);
    //           randomEntry.monthYearLabel = date.fromNow();

    //       }else{
    //         return null;
    //       }
    //     } else{
    //       // not logged in, display random illustrations
    //         let thumbnailImageId = Math.floor((Math.random() * 22) + 1);
    //         randomEntry.thumbnailImage = "images/day-page/random-entry/random-entry-icon-" + thumbnailImageId + ".png";
    //         randomEntry.showThumbnail = false;

    //         let date = moment({
    //           day: randomEntry.day,
    //           month: randomEntry.month - 1,
    //           year: randomEntry.year
    //         });

    //         randomEntry.monthYearLabel = date.fromNow();
    //     }
    //   }
    //   return randomEntry;
    // }),

    actions: {
      // refreshAds() {
      //   if (googletag.pubads && Ember.$("#div-gpt-ad-1477419963449-2").html()) {
      //     googletag.pubads().refresh([slot3]);
      //   }

      //   if (googletag.pubads && Ember.$("#div-gpt-ad-1477419963449-3").html()) {
      //     googletag.pubads().refresh([slot4]);
      //   }

      //   if (googletag.pubads && Ember.$("#div-gpt-ad-1477420034962-1").html()) {
      //     googletag.pubads().refresh([slot6]);
      //   }
      // },

      removeEntry: function removeEntry(entry) {
        this.get("entriesToUpload").removeObject(entry);
        if (!this.get("entriesToUpload.length")) {
          this.addNewEntry();
        }
      },

      toggleChild: function toggleChild(child, entry, isPet) {
        if (!isPet) {
          var childEntries = entry ? [entry] : this.get('entriesToUpload').toArray();

          _lodash.default.each(childEntries.toArray(), function (entry) {
            var childTagged = _lodash.default.any(entry.get('children').toArray(), function (c) {
              return c.id === child.id;
            });
            var numEntries = child.get('numEntries');
            if (childTagged) {
              entry.get('children').removeObject(child);
              child.set('numEntries', numEntries - 1);
            } else {
              entry.get('children').pushObject(child);
              child.set('numEntries', numEntries ? numEntries + 1 : 1);
            }
          });
        } else {
          var petEntries = entry ? [entry] : this.get('entriesToUpload').toArray();
          _lodash.default.each(petEntries.toArray(), function (entry) {
            var petTagged = _lodash.default.any(entry.get('pets').toArray(), function (p) {
              return p.id === child.id;
            });
            var numEntries = child.get('numEntries');
            if (petTagged) {
              entry.get('pets').removeObject(child);
              child.set('numEntries', numEntries - 1);
            } else {
              entry.get('pets').pushObject(child);
              child.set('numEntries', numEntries ? numEntries + 1 : 1);
            }
          });
        }
      },
      addEntries: function addEntries() {
        var numEntries = document.getElementById("add-moment");
        var entriesToUpload = this.get('entriesToUpload');
        var lastEntry = entriesToUpload.objectAt(entriesToUpload.get('length') - 1);
        if (!lastEntry.get('caption') && !lastEntry.get('file')) {
          if (entriesToUpload.get('length') > 1) {
            entriesToUpload.removeObject(lastEntry);
          } else {
            lastEntry.set('error', 'You can\'t upload add an empty moment');
            self.set("submitting", false);
            return;
          }
        }

        var self = this;
        numEntries.scrollIntoView();

        this.set("submitting", true);
        // Check user limits
        this.API.getMemoriesLimit().then(function (limit) {
          limit = limit.limit;
          console.log("Used greater than thresh", limit.usedTimes >= limit.threshold);
          console.log("Entries", entriesToUpload.length);
          console.log(entriesToUpload);
          console.log("Diff", limit.threshold - limit.usedTimes);
          if (limit.threshold > 0 && (limit.usedTimes >= limit.threshold || entriesToUpload.length > limit.threshold - limit.usedTimes)) {
            //modal and cancel
            Ember.$(".limitRemainingModal").modal("show");
            // this.set("submitting", false);
            self.get('globals').set('showProgressBar', false);
            if (self.get('monthController.route')) {
              self.get('monthController.route').refresh();
            }
            if (self.get('entriesController.route')) {
              self.get('entriesController.route').refresh();
            }
          } else {
            // success
            for (var i = 0; i < entriesToUpload.length; i++) {
              var entry = entriesToUpload.objectAt(i);
              if (entry && entry.get('caption.length') > 10000) {
                self.set('error.message', 'Please fix the errors above to proceed');
                self.set("submitting", false);
                return;
              }
            }
            self.set('error.message', '');
            // Tracker.trackEvent({
            //   action: AddMoment.saveButton,
            //   category: AddMoment.category,
            //   screen: AddMoment.screen
            // });
            self.GTM.trackEvent({
              eventCategory: "empty-day-view",
              eventAction: "add-moments-section",
              eventLabel: "add-moment"
            });
            self.send('trackOnboarding', 'add-moment', 'save');
            var canShare = self.get('canShare');
            self.uploadEntry(0, Ember.copy(entriesToUpload), canShare);
          }
        });
      },
      filesAdded: function filesAdded(entry, files) {
        var self = this;
        var uploads = this.get("entriesToUpload");
        this.API.getMemoriesLimit().then(function (limit) {
          limit = limit.limit;
          var remaining = limit.threshold - limit.usedTimes;
          if (limit.threshold > 0 && (limit.usedTimes >= limit.threshold || files.length > remaining || files.length + uploads.length > limit.threshold)) {
            if (0 === remaining) {
              // reached modal
              Ember.$(".limitReachedModal").modal("show");
            } else {
              // remaining modal
              Ember.$(".limitRemainingModal").modal("show");
            }
          } else {
            entry.set('file', files[0]);
            self.loadFile(entry);
            for (var i = 1; i < files.length; i++) {
              var _entry = self.addNewEntry(files[i]);
              self.loadFile(_entry);
            }
            self.addNewEntry();
          }
        });
      },
      addMoment: function addMoment() {
        Ember.$(window).scrollTop(0);
        this.transitionToRoute("main.journals.add-moment", this.get("model.journalId"), {
          queryParams: {
            year: this.get("model.year"),
            month: this.model.get("month"),
            day: this.get("model.day")
          }
        }).then(function (route) {
          route.set("prevRoute", "list");
        });
        _tracker.Tracker.trackEvent({
          category: _tracker.Moments.category,
          screen: _tracker.Moments.day.screen,
          action: _tracker.Moments.addMomentButton
        });
      },
      startReorder: function startReorder() {
        this.set("reorderMode", true);
        Ember.$("#entryList").addClass("reorder-mode");
        Ember.$("#moments").sortable({
          items: ".entry"
        });
      },
      saveReorder: function saveReorder() {
        var _this5 = this;

        var self = this;
        Ember.$("#moments").sortable("destroy");
        Ember.$("#entryList").removeClass("reorder-mode");
        var orderedEntries = [];
        Ember.$(".entry-content").each(function () {
          var id = Ember.$(this).data("entry-id");
          orderedEntries.push({
            id: id
          });
        });

        Ember.$("#saveReorder").html("Please wait ...").attr("disabled", "disabled");
        Ember.$("#cancelReorder").attr("disabled", "disabled");

        self.API.reorderJournalEntriesByDayMonthYear(this.get("model.journalId"), this.get("model.day"), this.get("model.month"), this.get("model.year"), orderedEntries, function () {
          _this5.send("reloadEntries");
          _this5.set("reorderMode", false);
        });
      },
      cancelReorder: function cancelReorder() {
        Ember.$("#moments").sortable("destroy");
        Ember.$("#entryList").removeClass("reorder-mode");
        Ember.$("#saveReorder").attr("disabled", "disabled");
        Ember.$("#cancelReorder").html("Please wait ...").attr("disabled", "disabled");
        this.send("reloadEntries");
        this.set("reorderMode", false);
        this.route.refresh();
      },
      showPrevDay: function showPrevDay() {
        var _this6 = this;

        Ember.$(window).scrollTop(0);
        // this.send("refreshAds");
        this.set(".string", "Loading ...");
        Ember.$(".entry-add").css("display", "none");

        var currentDate = this.get("currentDate");
        var prevDay = this.getDate(currentDate, "day", PREVDAY);
        var prevMonth = this.getDate(currentDate, "month", PREVDAY);
        var prevYear = this.getDate(currentDate, "year", PREVDAY);

        this.transitionToRoute("main.entries.list", this.get("model.journalId"), prevYear, prevMonth, prevDay);
        Ember.run.scheduleOnce("afterRender", function () {
          _this6.setupUpload(prevYear, prevMonth - 1, prevDay);
        });
      },
      showNextDay: function showNextDay() {
        var _this7 = this;

        Ember.$(window).scrollTop(0);
        // this.send("refreshAds");
        this.set(".string", "Loading ...");
        Ember.$(".entry-add").css("display", "none");

        var currentDate = this.get("currentDate");
        var nextDay = this.getDate(currentDate, "day", NEXTDAY);
        var nextMonth = this.getDate(currentDate, "month", NEXTDAY);
        var nextYear = this.getDate(currentDate, "year", NEXTDAY);

        this.transitionToRoute("main.entries.list", this.get("model.journalId"), nextYear, nextMonth, nextDay);

        Ember.run.scheduleOnce("afterRender", function () {
          _this7.setupUpload(nextYear, nextMonth - 1, nextDay);
        });
      },


      showMonth: function showMonth() {
        Ember.$(window).scrollTop(0);
        var journalId = this.get("model.journalId");
        this.transitionToRoute("main.journals.month", journalId, this.get("model.year"), this.get("model.month"));
      },

      deleteEntry: function deleteEntry(entry) {
        var entries = this.get("model.entries");
        entries.removeObject(entry);
        entry.destroyRecord();
      },
      addComment: function addComment(entry, details) {
        var _this8 = this;

        this.API.addComment(this.get("model.journalId"), entry.id, details, null, null, entry.get("uuid"), function (data) {
          var comment = _this8.store.createRecord("comment", data.comment);
          entry.get("comments").addObject(comment);
          _tracker.Tracker.trackEvent({
            category: _tracker.Moments.category,
            screen: _tracker.Moments.day.screen,
            action: _tracker.Moments.postCommentButton
          });
        }, function (error) {
          console.log(error);
        });
      },
      deleteComment: function deleteComment(entry, comment) {
        this.API.deleteComment(this.get("model.journalId"), entry.id, comment.get("id"), null, function () {
          entry.get("comments").removeObject(comment);
        }, function (error) {
          console.log(error);
        });
      },
      updateEntry: function updateEntry(entry) {
        this.API.updateEntry(this.get("model.journalId"), entry, function () {}, this);
      },
      toggleEmotion: function toggleEmotion(entry, emotion) {
        var _this9 = this;

        var userId = this.get("model.me").id;

        if (emotion) {
          this.API.deleteEmotion(this.get("model.journalId"), entry.id, emotion.id, function () {
            entry.get("emotions").removeObject(emotion);
          }, this);
        } else {
          this.API.addEmotion(this.get("model.journalId"), entry.id, "LOVE", null, function (data) {
            _tracker.Tracker.trackEvent({
              category: _tracker.Moments.category,
              screen: _tracker.Moments.day.screen,
              action: _tracker.Moments.loveMomentButton
            });
            var lastNameInitial = data.emotion.user.lastName.length >= 1 ? data.emotion.user.lastName.substring(0, 1) : "";
            data.emotion.user.fullName = data.emotion.user.firstName + " " + lastNameInitial;
            emotion = _this9.store.createRecord("emotion", data.emotion);
            emotion.set("userId", _this9.get("model.me"));
            entry.get("emotions").pushObject(emotion);
          }, this, userId);
        }
      },
      gotoDayView: function gotoDayView(journalId, year, month, day) {
        this.transitionToRoute("main.entries.list", journalId, year, month, day);
      },
      gotoReferFriend: function gotoReferFriend() {
        var eventAction = "click_refer_friend_ribbon";
        var label = "goto-button-from-entry-list";

        this.GTM.trackEvent({
          eventCategory: "checkout_refer_friend_page",
          eventAction: eventAction,
          eventLabel: label
        });
        this.transitionToRoute("main.refer-friend.show");
      },
      closeReferFriendBanner: function closeReferFriendBanner() {
        this.set("showReferFriends", false);
        writeCookie("refer-friend-visited", true, 10);

        function writeCookie(key, value, expiration) {
          var expires = expiration ? "" : "; expires=" + new Date(expiration).toUTCString();
          //  var secure  = !!this._secureCookies ? ';secure' : '';
          var path = ";path=/";
          document.cookie = key + "=" + encodeURIComponent(value) + expires + path;
        }

        var eventAction = "close_refer_friend_ribbon";
        var label = "close-button-from-entry-list";

        this.GTM.trackEvent({
          eventCategory: "close_refer_friend_page",
          eventAction: eventAction,
          eventLabel: label
        });
      },


      // gotoPremium() {
      //   this.transitionToRoute('main.subscription-products.list', {
      //     queryParams: {
      //       journalid: this.get("model.journal.id"),
      //       overviewOnly: false
      //     }
      //   });
      // },

      gotoTopOfPage: function gotoTopOfPage() {
        Ember.$("html, body").animate({
          scrollTop: 0
        }, "slow");
      },

      upgradeSub: function upgradeSub() {
        if (typeof AF !== "undefined") AF('pba', 'event', { eventType: 'EVENT', eventValue: { 'label': 'Click on "Start Your Free Trial"', 'from_page': 'Entries List' }, eventName: 'Click on "Start Your Free Trial"' });

        _tracker.Tracker.trackEvent({
          category: _tracker.Premium.category,
          screen: _tracker.Premium.list.screen,
          action: _tracker.Premium.list.startTrial
        });

        window.location.hash = '#/main/subscription-products/list';
      },

      gotoPremium: function gotoPremium() {
        var self = this;
        var journal = self.get("model.journal");
        var journalId = journal.id;

        self.send("gotoTopOfPage");
        self.transitionToRoute("main.subscription-products.list", {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      },

      gotoPremiumFromModal: function gotoPremiumFromModal() {
        var self = this;
        Ember.$(".modal").modal("hide");
        self.send("gotoPremium");
      }
    }
  });
});