define("tinybeans-frontend/templates/main/journals/edit", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 14
              },
              "end": {
                "line": 25,
                "column": 86
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "help-block error");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["content", "error.title", ["loc", [null, [25, 64], [25, 79]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 10
            },
            "end": {
              "line": 27,
              "column": 10
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "for", "title");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "form-input");
          var el3 = dom.createTextNode("\n               ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "help-block description");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element7, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element7, [5]), 0, 0);
          morphs[3] = dom.createMorphAt(element7, 7, 7);
          return morphs;
        },
        statements: [["inline", "t", ["Journal.Title_Prompt"], [], ["loc", [null, [20, 33], [20, 61]]], 0, 0], ["inline", "input", [], ["id", "title", "type", "text", "value", ["subexpr", "@mut", [["get", "model.journal.title", ["loc", [null, [22, 52], [22, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["Journal.Title_Placeholder"], [], ["loc", [null, [22, 84], [22, 115]]], 0, 0]], ["loc", [null, [22, 15], [22, 117]]], 0, 0], ["inline", "t", ["Journal.Title_Prompt_Description"], [], ["loc", [null, [24, 48], [24, 88]]], 0, 0], ["block", "if", [["get", "error.title", ["loc", [null, [25, 20], [25, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [25, 14], [25, 93]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 36,
                      "column": 22
                    },
                    "end": {
                      "line": 36,
                      "column": 62
                    }
                  },
                  "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["content", "theme.label", ["loc", [null, [36, 47], [36, 62]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 20
                  },
                  "end": {
                    "line": 37,
                    "column": 20
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "x-option", [], ["value", ["subexpr", "@mut", [["get", "theme", ["loc", [null, [36, 40], [36, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [36, 22], [36, 75]]]]],
              locals: ["theme"],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 18
                },
                "end": {
                  "line": 38,
                  "column": 18
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "model.coverThemes", ["loc", [null, [35, 28], [35, 45]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [35, 20], [37, 29]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 18
                },
                "end": {
                  "line": 39,
                  "column": 100
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "help-block error");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["content", "error.coverTheme", ["loc", [null, [39, 73], [39, 93]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 14
              },
              "end": {
                "line": 41,
                "column": 14
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1, "for", "coverTheme");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "drop-down");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element6, 1, 1);
            morphs[2] = dom.createMorphAt(element6, 3, 3);
            return morphs;
          },
          statements: [["inline", "t", ["Journal.Cover_Theme"], [], ["loc", [null, [32, 40], [32, 67]]], 0, 0], ["block", "x-select", [], ["value", ["subexpr", "@mut", [["get", "model.journal.coverTheme", ["loc", [null, [34, 36], [34, 60]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [34, 18], [38, 31]]]], ["block", "if", [["get", "error.coverTheme", ["loc", [null, [39, 24], [39, 40]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [39, 18], [39, 107]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 12
            },
            "end": {
              "line": 42,
              "column": 12
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "canManage", ["loc", [null, [31, 20], [31, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [31, 14], [41, 21]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 12
            },
            "end": {
              "line": 49,
              "column": 12
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "journal text-center");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "class", "cover");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "title");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [3]);
          var element5 = dom.childAt(element4, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element5, 'src');
          morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "src", ["get", "model.journal.coverTheme.url", ["loc", [null, [46, 27], [46, 55]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.journal.title", ["loc", [null, [47, 35], [47, 58]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 12
              },
              "end": {
                "line": 69,
                "column": 12
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "form-group");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "checkbox description");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "checkbox-display");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            dom.setAttribute(el4, "class", "help-block description");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "checkbox description");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "checkbox-display");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            dom.setAttribute(el4, "class", "help-block description");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1, 1]);
            var element3 = dom.childAt(element1, [3, 1]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(element2, 1, 1);
            morphs[1] = dom.createMorphAt(element2, 5, 5);
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [7]), 0, 0);
            morphs[3] = dom.createMorphAt(element3, 1, 1);
            morphs[4] = dom.createMorphAt(element3, 5, 5);
            morphs[5] = dom.createMorphAt(dom.childAt(element3, [7]), 0, 0);
            return morphs;
          },
          statements: [["inline", "input", [], ["id", "disableComments", "type", "checkbox", "checked", ["subexpr", "@mut", [["get", "model.journal.disableComments", ["loc", [null, [58, 73], [58, 102]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [58, 20], [58, 104]]], 0, 0], ["inline", "t", ["Journal.Disable_Comments"], [], ["loc", [null, [58, 142], [58, 174]]], 0, 0], ["inline", "t", ["Journal.Disable_Comments_Description"], [], ["loc", [null, [59, 54], [59, 98]]], 0, 0], ["inline", "input", [], ["id", "disableDownloads", "type", "checkbox", "checked", ["subexpr", "@mut", [["get", "model.journal.disableDownloads", ["loc", [null, [64, 74], [64, 104]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [64, 20], [64, 106]]], 0, 0], ["inline", "t", ["Journal.Disable_Downloads"], [], ["loc", [null, [64, 144], [64, 177]]], 0, 0], ["inline", "t", ["Journal.Disable_Downloads_Description"], [], ["loc", [null, [65, 54], [65, 99]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 10
            },
            "end": {
              "line": 70,
              "column": 10
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["Journal.Preferences"], [], ["loc", [null, [53, 16], [53, 43]]], 0, 0], ["block", "if", [["get", "canManage", ["loc", [null, [54, 18], [54, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [54, 12], [69, 19]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 10
            },
            "end": {
              "line": 77,
              "column": 10
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "href", "");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["Journal.Unfollow"], [], ["loc", [null, [73, 16], [73, 40]]], 0, 0], ["element", "action", ["unfollow"], [], ["loc", [null, [75, 25], [75, 47]]], 0, 0], ["inline", "t", ["Journal.Unfollow_Prompt"], [], ["loc", [null, [75, 48], [75, 79]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 14
                },
                "end": {
                  "line": 88,
                  "column": 14
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" Export memories\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 84,
                "column": 12
              },
              "end": {
                "line": 89,
                "column": 12
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h4");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("All your moments are safe with us, but having an extra back up is the smart thing to do");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["Journal.ExportMoments"], [], ["loc", [null, [85, 18], [85, 47]]], 0, 0], ["block", "link-to", ["main.journals.export", ["get", "model.journal.id", ["loc", [null, [87, 48], [87, 64]]], 0, 0, 0, 0]], ["classNames", "btn btn-secondary exbtn-big"], 0, null, ["loc", [null, [87, 14], [88, 26]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 83,
              "column": 8
            },
            "end": {
              "line": 90,
              "column": 8
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "canExport", ["loc", [null, [84, 18], [84, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [84, 12], [89, 19]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 96,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/journals/edit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n  .navbar-journal-details {\n    font-weight: 400;\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "journalEdit");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "transparent-bg margin-min text-center");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        dom.setAttribute(el5, "class", "title");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-offset-2 col-md-8");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6, "type", "submit");
        dom.setAttribute(el6, "class", "btn btn-primary btn-big");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-offset-2 col-md-8");
        dom.setAttribute(el4, "id", "digital-export");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [2, 1, 1]);
        var element9 = dom.childAt(element8, [3]);
        var element10 = dom.childAt(element9, [5]);
        var element11 = dom.childAt(element10, [3]);
        var element12 = dom.childAt(element10, [9]);
        var morphs = new Array(12);
        morphs[0] = dom.createMorphAt(dom.childAt(element8, [1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(element9, 1, 1);
        morphs[2] = dom.createMorphAt(element9, 3, 3);
        morphs[3] = dom.createMorphAt(element10, 1, 1);
        morphs[4] = dom.createMorphAt(element11, 1, 1);
        morphs[5] = dom.createMorphAt(element11, 2, 2);
        morphs[6] = dom.createMorphAt(element10, 5, 5);
        morphs[7] = dom.createMorphAt(element10, 7, 7);
        morphs[8] = dom.createAttrMorph(element12, 'disabled');
        morphs[9] = dom.createElementMorph(element12);
        morphs[10] = dom.createMorphAt(element12, 0, 0);
        morphs[11] = dom.createMorphAt(dom.childAt(element8, [5]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["Journal.Details_Heading"], [], ["loc", [null, [11, 26], [11, 57]]], 0, 0], ["inline", "alert-box", [], ["message", ["subexpr", "@mut", [["get", "error.global", ["loc", [null, [15, 28], [15, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "warning"], ["loc", [null, [15, 8], [15, 57]]], 0, 0], ["inline", "alert-box", [], ["message", ["subexpr", "@mut", [["get", "success.global", ["loc", [null, [15, 78], [15, 92]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "success"], ["loc", [null, [15, 58], [15, 109]]], 0, 0], ["block", "if", [["get", "canManage", ["loc", [null, [18, 16], [18, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [18, 10], [27, 17]]]], ["block", "if", [["get", "isFollower", ["loc", [null, [30, 18], [30, 28]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [30, 12], [42, 19]]]], ["block", "if", [["get", "model.journal.coverTheme", ["loc", [null, [43, 18], [43, 42]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [43, 12], [49, 19]]]], ["block", "if", [["get", "model.following", ["loc", [null, [52, 16], [52, 31]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [52, 10], [70, 17]]]], ["block", "unless", [["get", "canManage", ["loc", [null, [72, 20], [72, 29]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [72, 10], [77, 21]]]], ["attribute", "disabled", ["get", "submitting", ["loc", [null, [79, 47], [79, 57]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["save"], [], ["loc", [null, [79, 18], [79, 35]]], 0, 0], ["content", "saveButtonLabel", ["loc", [null, [79, 106], [79, 125]]], 0, 0, 0, 0], ["block", "if", [["get", "isSubscribedUser", ["loc", [null, [83, 14], [83, 30]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [83, 8], [90, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }());
});