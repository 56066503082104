define('tinybeans-frontend/controllers/main/account/confirm-details', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    i18n: Ember.inject.service(),

    isActiveNavAccount: function isActiveNavAccount() {
      return true;
    },

    user: null,
    followings: null,
    //  avatar: null,
    submitting: false,

    saveButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? this.get("i18n").t('global.label.wait') : this.get("i18n").t('global.label.save');
    }.property('submitting'),

    actions: {

      save: function save() {

        var self = this;
        var user = this.get('user');

        var _user$getProperties = user.getProperties('firstName', 'lastName', 'emailAddress'),
            firstName = _user$getProperties.firstName,
            lastName = _user$getProperties.lastName,
            emailAddress = _user$getProperties.emailAddress;

        var error = {
          firstName: !firstName ? 'First Name is Required' : undefined,
          lastName: !lastName ? 'Last Name is Required' : undefined,
          emailAddress: !emailAddress ? 'Email Address is Required' : undefined
        };
        if (!firstName || !lastName || !emailAddress) {
          this.set('error', error);
          this.onApiError();
          return;
        }

        self.set('submitting', true);

        this.API.confirmDetails(this.model.user.serialize(), function (data, statusText, xhr) {
          self.set('submitting', false);
          self.set('error', {});
          self.onApiSuccess(data, statusText, xhr);

          //console.log("update API with user confirmed info.");

          //leanplum tracking
          _tracker.Tracker.trackEvent({
            action: _tracker.Signup.confirmDetails.continueButton,
            category: _tracker.Signup.category
          });

          //gtm here
          self.GTM.trackEvent({
            'eventCategory': 'confirm details events',
            'eventAction': 'click',
            'eventLabel': 'continue button'
          });

          self.transitionToRoute('main.onboarding.step-start');
        }, function (response) {
          self.set('submitting', false);
          self.onApiError(response);
        });
      }

    }
  });
});