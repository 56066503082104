define('tinybeans-frontend/routes/deeplinks', ['exports', 'tinybeans-frontend/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    beforeModel: function beforeModel() {
      this.router.transitionTo('download-app');
    }
  });
});