define('tinybeans-frontend/controllers/base-controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        mainController: Ember.inject.controller('main'),
        session: Ember.inject.service('session'),

        myJournals: null,
        route: null,
        authenticated: false,
        storesLink: '/app/#/main/products/list',
        freemiumAvailable: true,

        refreshWithQueryParams: function refreshWithQueryParams(params) {
            var self = this;
            if (!params) {
                params = {};
            }
            var usableParams = {};
            var queryParams = self.queryParams;
            if (queryParams) {
                for (var key in params) {
                    if (Ember.$.inArray(key, queryParams) !== -1) {
                        usableParams[key] = params[key];
                    }
                }
            }
            var paramsForSelect = self.queryParamsForSelect;
            if (paramsForSelect && paramsForSelect.length > 0) {
                var paramsNotForSelect = {};
                for (var paramKey in usableParams) {
                    if (paramKey && usableParams.hasOwnProperty(paramKey)) {
                        if (Ember.$.inArray(paramKey, paramsForSelect) === -1) {
                            var value = usableParams[paramKey];
                            paramsNotForSelect[paramKey] = value;
                        }
                    }
                }
                self.transitionToRoute({
                    queryParams: usableParams
                });
                self.set('params', paramsNotForSelect);
                setTimeout(function () {
                    self.set('params', params);
                }, 100);
            } else {
                self.transitionToRoute({
                    queryParams: usableParams
                });
            }
        },

        actions: {
            subscriptionProducts: function subscriptionProducts() {
                var currentJournal = this.get('mainController.currentJournal');
                this.transitionToRoute('main.subscription-products.list', {
                    queryParams: {
                        journalid: currentJournal.id,
                        overviewOnly: false
                    }
                });
            },
            subscriptionProductsOverview: function subscriptionProductsOverview() {
                var currentJournal = this.get('mainController.currentJournal');
                this.transitionToRoute('main.subscription-products.list', {
                    queryParams: {
                        journalid: currentJournal.id,
                        overviewOnly: false
                    }
                });
            },
            home: function home() {
                this.send('closeSideNav');
                this.transitionToRoute('main.journals.home');
            },

            followers: function followers() {
                this.send('closeSideNav');
                Ember.$(".navbar-family-friends").addClass("active");
                this.transitionToRoute('main.journals.followers');
            },

            switchJournal: function switchJournal(journal) {
                this.send('closeSideNav');
                Ember.$('.spin').css('visibility', 'visible');
                Ember.$('table.calendar').css('visibility', 'hidden');

                if (this.get('session.isAuthenticated')) {
                    this.transitionToRoute('main.journals.view', journal.id);
                }
            },

            toggleAddPanel: function toggleAddPanel() {
                Ember.$('.add-panel').toggleClass("toggled");
            },

            toggleSidebar: function toggleSidebar() {
                //  e.preventDefault();
                Ember.$("#wrapper").toggleClass("toggled");

                var hasToggled = Ember.$("#wrapper").hasClass("toggled");

                if (hasToggled) {
                    Ember.$('#slide-show-control').hide();
                    Ember.$('#trigger-layer').show();
                } else {
                    Ember.$('#slide-show-control').show();
                    Ember.$('#trigger-layer').hide();
                }
            },

            //mobile hamberger
            showMobileNav: function showMobileNav() {

                Ember.$('#content').css('min-height', Ember.$(window).height());
                Ember.$('nav.mobile-nav').css('opacity', 1);

                //set the width of primary content container -> content should not scale while animating
                var contentWidth = Ember.$('#content').width();

                //set the content with the width that it has originally
                Ember.$('#content').css('width', contentWidth);

                //display a layer to disable clicking and scrolling on the content while menu is shown
                Ember.$('#contentLayer').css('display', 'block');

                //disable all scrolling on mobile devices while menu is shown
                Ember.$('#container').bind('touchmove', function (e) {
                    e.preventDefault();
                });

                //set margin for the whole container with a Ember.$ UI animation
                Ember.$("#container").animate({
                    "marginLeft": ["70%", 'easeOutExpo']
                }, {
                    duration: 700
                });
            },

            hideMobileNav: function hideMobileNav() {
                //enable all scrolling on mobile devices when menu is closed
                Ember.$('#container').unbind('touchmove');

                //set margin for the whole container back to original state with a Ember.$ UI animation
                Ember.$("#container").animate({
                    "marginLeft": ["-1", 'easeOutExpo']
                }, {
                    duration: 700,
                    complete: function complete() {
                        Ember.$('#content').css('width', 'auto');
                        Ember.$('#contentLayer').css('display', 'none');
                        Ember.$('nav.mobile-nav').css('opacity', 0);
                        Ember.$('#content').css('min-height', 'auto');
                    }
                });
            },

            journals: function journals() {
                this.send('closeSideNav');
                Ember.$(".navbar-journal-details").addClass("active");
                var currentJournal = this.get('mainController.currentJournal');
                if (currentJournal) {
                    this.transitionTo('main.journals.edit', currentJournal.id);
                } else {
                    this.transitionToRoute('main.journals.settings');
                }
            },

            children: function children() {
                this.send('closeSideNav');
                Ember.$(".navbar-children").addClass("active");
                var currentJournal = this.get('mainController.currentJournal');
                this.transitionToRoute('main.children.list', currentJournal.id);
            },

            currentJournalFollowers: function currentJournalFollowers() {
                this.send('closeSideNav');
                Ember.$(".navbar-family-friends").addClass("active");
                var currentJournal = this.get('mainController.currentJournal');
                this.transitionToRoute('main.journals.follower-list', currentJournal.id);
            },

            account: function account() {
                this.send('closeSideNav');
                Ember.$(".navbar-my-details").addClass("active");
                this.transitionToRoute('main.account.show');
            },

            setupJournal: function setupJournal() {
                this.send('closeSideNav');
                Ember.$(".navbar-start-journal").addClass("active");

                this.transitionToRoute('main.journals.add');
            },

            store: function store() {
                this.send('closeSideNav');
                Ember.$(".navbar-photobook").addClass("active");

                var win = window.open(this.get('storesLink'), '_self');
                win.focus();
            },

            features: function features(journalId) {
                this.send('closeSideNav');
                Ember.$(".navbar-premium-features").addClass("active");

                if (journalId === undefined && this.parentController) {
                    if (this.parentController.model) {
                        journalId = this.parentController.model.currentJournal;
                    }
                }

                this.transitionToRoute('main.subscription-products.list', {
                    queryParams: {
                        journalid: journalId,
                        overviewOnly: false
                    }
                });
            },

            gotoPremiumPublic: function gotoPremiumPublic() {
                this.send('closeSideNav');
                Ember.$(".navbar-premium-features").addClass("active");
                this.transitionToRoute('main.subscription-products.list', {
                    queryParams: {
                        overviewOnly: false
                    }
                });
            },

            upgradeToPremium: function upgradeToPremium() {
                if (this.get('session.isAuthenticated')) {
                    this.send('closeSideNav');
                    var currentJournal = this.get('mainController.currentJournal');
                    this.transitionToRoute('main.subscription-products.list', {
                        queryParams: {
                            journalid: currentJournal.id,
                            overviewOnly: false
                        }
                    });
                }
            },

            closeSideNav: function closeSideNav() {
                Ember.$('.spin').show();
                this.send('gotoTopOfPage');

                Ember.$(".navbar-refer-a-friend").removeClass("active");
                Ember.$(".navbar-upgrade-to-premium").removeClass("active");
                Ember.$(".navbar-premium-features").removeClass("active");
                Ember.$(".navbar-photo-collections").removeClass("active");
                Ember.$(".navbar-export-moments").removeClass("active");
                Ember.$(".navbar-journal-details").removeClass("active");
                Ember.$(".navbar-family-friends").removeClass("active");
                Ember.$(".navbar-photobook").removeClass("active");
                Ember.$(".navbar-children").removeClass("active");
                Ember.$(".navbar-my-details").removeClass("active");
                Ember.$(".navbar-billing").removeClass("active");
                Ember.$(".navbar-store").removeClass("active");
                Ember.$(".navbar-start-journal").removeClass("active");

                Ember.$("#wrapper").removeClass("toggled");
                Ember.$('#trigger-layer').hide();
                Ember.$('#banner').show();
            },

            gotoTopOfPage: function gotoTopOfPage() {
                Ember.$("html, body").animate({
                    scrollTop: 0
                }, "slow");
            },

            gotoCollection: function gotoCollection(journalId) {
                this.send('closeSideNav');
                Ember.$(".navbar-photo-collections").addClass("active");
                this.transitionToRoute('main.collections.list', journalId);
            },

            gotoReferFriend: function gotoReferFriend() {
                this.send('closeSideNav');
                Ember.$(".navbar-refer-a-friend").addClass("active");
                this.transitionToRoute('main.refer-friend.show');
            },

            gotoDigitalExport: function gotoDigitalExport(journalId) {
                this.send('closeSideNav');
                Ember.$(".navbar-export-moments").addClass("active");
                this.transitionToRoute('main.journals.export', journalId);
            },

            //search page
            gotoSearch: function gotoSearch(journalId) {
                this.send('closeSideNav');
                Ember.$(".navbar-search").addClass("active");

                this.transitionToRoute('main.journals.search', journalId);
            },

            billing: function billing() {
                this.send('closeSideNav');
                Ember.$(".navbar-billing").addClass("active");
                this.transitionToRoute('main.billing.show');
            },

            printio: function printio() {
                this.send('closeSideNav');
                this.transitionToRoute('main.printio');
            },

            //close amazon ads
            closeAmazonAds: function closeAmazonAds() {
                var d = new Date();
                //Set cookie expired in 1 day
                d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
                var expires = d.toGMTString();

                document.cookie = "CloseAmazonAds=true;expires=" + expires;
                Ember.$("#amazon-happy-ads-container").attr("style", "display:none!important");
            },
            //close amazon ads


            logout: function logout() {
                var _this = this;

                this.send('closeSideNav');
                try {
                    FB.getLoginStatus(function (response) {
                        if (response.status === 'connected') {
                            FB.logout();
                        } else if (response.status === 'not_authorized') {
                            FB.logout();
                        } else {}
                    });
                } catch (error) {}

                this.get('session').invalidate().then(function () {
                    _this.store.unloadAll();
                });
                this.transitionToRoute('login');
            },

            login: function login() {
                // Entries, collections and subscript-products/list can be both public and private. We'll manually redirect back for those on login
                // Technically we could do this on all login requests without harm but it's nicer not to use the from parameter unless it's really required
                if (window.location.href.indexOf("entries") !== -1 || window.location.href.indexOf("collections") !== -1 || window.location.href.indexOf("subscription-products/list") !== -1) {
                    this.transitionToRoute('login', {
                        queryParams: {
                            from: window.location.href
                        }
                    });
                } else {
                    this.transitionToRoute('login');
                }
            },

            signup: function signup() {
                this.transitionToRoute('signup');
            }
        },

        onApiSuccess: function onApiSuccess() {
            this.set('success', {
                global: 'Details saved successfully'
            });
            Ember.$("html, body").animate({
                scrollTop: 0
            }, "slow");
        },

        onApiError: function onApiError(xhr) {
            if (xhr.status === 404) {
                this.set('error', {});
                this.set('error.global', 'The action you are invoking does not exist');
            } else if (xhr.status > 299) {
                var responseText = xhr.responseText;
                if (!responseText) {
                    this.set('error', {
                        global: 'An error has occurred'
                    });
                } else {
                    var data = Ember.$.parseJSON(responseText);
                    this.set('error', {});
                    var fieldErrors = data.errors;
                    if (fieldErrors) {
                        for (var i = 0; i < fieldErrors.length; i++) {
                            var fieldError = fieldErrors[i];
                            this.set('error.' + fieldError.key, fieldError.message);
                        }
                    }
                    this.set('error.global', 'Please check the data you have entered and try again');
                }
            }
            Ember.$("html, body").animate({
                scrollTop: 0
            }, "slow");
        },

        notYetImplemented: function notYetImplemented(msg) {
            var error = {};
            error.identification = 'Not yet implemented';
            error.global = msg ? msg : 'This feature hasn\'t been implemented yet!';
            this.set('error', error);
            Ember.$("html, body").animate({
                scrollTop: 0
            }, "slow");
        }

    });
});