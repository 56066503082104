define('tinybeans-frontend/models/channel-subscription', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    sortOrder: _emberData.default.attr('number'),
    deleted: _emberData.default.attr('boolean'),
    channel: (0, _relationships.belongsTo)('channel'),
    userId: (0, _relationships.belongsTo)('user'),
    childId: (0, _relationships.belongsTo)('child'),

    child: Ember.computed.alias('childId'),
    user: Ember.computed.alias('userId')
  });
});