define('tinybeans-frontend/routes/main/children/edit', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker', 'lodash/lodash'], function (exports, _baseProtectedRoute, _tracker, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Child.category,
    screen: _tracker.Child.details.screen,

    model: function model(params) {
      var journalId = params.journal_id;
      var childId = params.child_id;
      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        childId: childId,
        followings: this.modelFor('main').followings,
        journals: this.store.findAll('journal'),
        relations: this.API.getFollowerRelations(),
        genders: this.API.listGenders()
      });
    },
    afterModel: function afterModel(model) {
      model.child = _lodash.default.find(model.journal.get('children').toArray(), function (child) {
        return child.id === model.childId;
      });
    }
  });
});