define('tinybeans-frontend/authenticators/token', ['exports', 'ember-simple-auth/authenticators/base', 'tinybeans-frontend/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    key: 'tinybeansstore',

    clear: function clear() {
      if (localStorage) {
        localStorage.removeItem(this.key);
      } else {
        this.writeCookie(this.key, null, 0);
      }
      this.writeCookie('accessToken', null, 0);
      this.writeCookie('access_token', null, 0);
      this.writeCookie('facebookAccessToken', null, 0);
      this.writeCookie('PLAY_SESSION', null, 0);
      this.writeCookie('rememberme', null, 0);
      this._lastData = {};
    },
    invalidateSession: function invalidateSession() {
      var url = _environment.default.apiEndPoint + '/api/1/logout';
      return Ember.$.ajax({
        url: url,
        type: 'get',
        contentType: 'application/json',
        dataType: 'json'
      });
    },
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve) {
        resolve(data);
      });
    },
    authenticate: function authenticate(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    },
    invalidate: function invalidate(data) {
      var _this = this;

      return this.invalidateSession(data).then(function () {
        _this.clear();
      });
    },


    readCookie: function readCookie(key) {
      var value = document.cookie.match(new RegExp(key + '=([^;]+)')) || [];
      return decodeURIComponent(value[1] || '');
    },

    writeCookie: function writeCookie(key, value, expiration) {
      // if (!(key && value)) return;
      var expires = Ember.isEmpty(expiration) ? '' : '; expires=' + new Date(expiration).toUTCString();
      var secure = !!this._secureCookies ? ';secure' : '';
      var path = ";path=/";
      document.cookie = key + '=' + encodeURIComponent(value) + expires + secure + path;
    }
  });
});