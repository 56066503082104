define('tinybeans-frontend/routes/main/journals/export', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash'], function (exports, _baseProtectedRoute, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({

    model: function model(params) {
      var journalId = params.journal_id;
      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        followings: this.store.findAll('following'),
        followers: this.store.query('follower', { filter: { journalId: journalId } }),
        isSubscribedUser: this.API.getActiveSubscription()
      });
    },
    afterModel: function afterModel(model) {
      if (!(model.isSubscribedUser.subscription || this.hasPremium([model.journal]))) {
        this.transitionTo('main.journals.edit', model.journalId);
      }
    },
    hasPremium: function hasPremium(journals) {
      var _this = this;

      var journalStats = [];
      _lodash.default.each(journals.toArray(), function (journal) {
        journalStats.push(_this.globals.premiumTier(journal));
      });
      return journalStats.includes(true);
    }
  });
});