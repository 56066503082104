define('tinybeans-frontend/helpers/main/children/milestones/prog-bar-width', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mainChildrenMilestonesProgBarWidth = mainChildrenMilestonesProgBarWidth;
  function mainChildrenMilestonesProgBarWidth(param /*, hash*/) {
    return 'width:' + param + '%';
  }

  exports.default = Ember.Helper.helper(mainChildrenMilestonesProgBarWidth);
});