define('tinybeans-frontend/routes/main/entries/list', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker', 'tinybeans-frontend/config/environment'], function (exports, _baseProtectedRoute, _tracker, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Moments.category,
    screen: _tracker.Moments.day.screen,

    model: function model(params) {
      var journalId = params.journal_id;
      var month = params.month;
      var year = params.year;
      var day = params.day;

      return Ember.RSVP.hash({
        journalId: journalId,
        day: day,
        month: month,
        year: year,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId), // Will be cached most of the time
        memoriesAccess: this.API.hasMemoriesAccess(journalId),
        memories_limit: this.API.getMemoriesLimit(),
        ads: this.API.getAdsStatus(journalId),
        entries: this.store.query('entry', {
          filter: {
            journalId: journalId,
            year: year,
            month: month,
            day: day
          }
        }),
        followings: this.modelFor('main').followings,
        followers: this.store.query('follower', {
          filter: {
            journalId: journalId
          }
        })

      });
    },
    afterModel: function afterModel(model) {
      model.entries = model.entries.toArray().sort(function (a, b) {
        if (a.get('sortOrder') && b.get('sortOrder')) {
          return b.get('sortOrder') - a.get('sortOrder');
        } else {
          return a.get('timestamp') - b.get('timestamp');
        }
      });
    },
    setupController: function setupController(controller, model, error) {
      Ember.Logger.debug(error.message);
      this.API.getRandomEntryByJournalId(model.journalId, function (e) {
        controller.set('model.randomEntry', e);
      }, function () {});
      this._super(controller, model);
      // Subscription LP Updated
      Leanplum.addVariablesChangedHandler(function () {
        var vars = Leanplum.getVariables();
        if (vars.Paywall) {
          var paywall = vars.Paywall;
          paywall = JSON.parse(paywall.products);
          controller.set('trialCopy', paywall.title);
          controller.set('trialFeatures', paywall.paidFeatures);
        } else {
          var paidFeatures = ['Unlimited video and photo storage', 'Private sharing of memories with friends and family', 'Free shipping on photo books', 'Events, advice, activities and more curated for your family'];
          controller.set('trialCopy', 'Try us risk-free for 30 days');
          controller.set('trialFeatures', paidFeatures);
        }
      });
      // Free User Limits
      if (model.memories_limit.limit.threshold < 0 || !(model.memories_limit.limit.threshold === model.memories_limit.limit.usedTimes)) {
        controller.set('canUploadMemories', true);
        if (model.memories_limit.limit.threshold < 0) {
          model.memories_limit.limit.usedTimes = 0;
        }
        if (model.memories_limit.limit.usedTimes < model.memories_limit.limit.threshold) {
          controller.set('uploadsRemaining', model.memories_limit.limit.threshold - model.memories_limit.limit.usedTimes);
        } else {
          controller.set('uploadsRemaining', "0");
        }
      } else {
        controller.set('canUploadMemories', false);
        controller.set('uploadsRemaining', "0");
      }
      controller.set('memoriesThreshold', model.memories_limit.limit.threshold);
      controller.set('entriesToUpload', []);

      if (model.memoriesAccess.hasMemoriesAccess) {
        controller.set('isSubscribedUser', true);
      } else {
        controller.set('isSubscribedUser', false);
      }
      controller.set('route', this);
    },


    init: function init() {},

    actions: {
      error: function error(_error, transition) {
        console.log(_error);
        Sentry.captureException(_error, {
          tags: {
            section: "Entries-Routes"
          }
        });
        this.replaceWith('/not-found');
      },


      didTransition: function didTransition() {
        this._super();
        // sticky ads
        Ember.run.next(function () {
          var isDesktop = Ember.$(window).innerWidth() > 767;
          if (isDesktop) {
            setTimeout(function () {
              var stickyAdsContainer = document.querySelector('.sticky-ads-container-outer');
              var momentContainer = document.querySelector('#moments');
              if (stickyAdsContainer && momentContainer) {
                if (stickyAdsContainer.clientHeight < momentContainer.clientHeight) {
                  stickyAdsContainer.style.height = momentContainer.clientHeight + 'px';
                }
              }
            }, 3000);
          }
        });
        // sticky ads
      }

    }
  });
});