define('tinybeans-frontend/routes/base-route', ['exports', 'tinybeans-frontend/tracker'], function (exports, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    setupController: function setupController(controller, model) {
      // apply model
      this._super(controller, model);

      // apply route
      controller.set('route', this);

      // need to convert model into an Ember object so that we can access its properties via get() and set()
      controller.set('model', Ember.Object.create(model));

      // apply controller's query params as refreshable params in router
      var controllerQueryParams = controller.get('queryParams');
      if (controllerQueryParams) {
        var queryParams = {};
        for (var i = 0; i < controllerQueryParams.length; i++) {
          queryParams[controllerQueryParams[i]] = {
            refreshModel: true
          };
        }
        this.set('queryParams', queryParams);
      }

      this.globals.get('lastRoutes').pushObject({
        routeName: this.get('routeName'),
        routeParams: this.paramsFor(this.get('routeName'))
      });
      this.globals.get('lastRouteObjects').pushObject(this);
      if (model) {
        this.router.setJournalForPageViewTracking(model.journal);
      }
    },

    actions: {
      didTransition: function didTransition() {
        //SheMedia
        //For SheMedia, when route changes, we manually refresh the top ad banner
        if (window.blogherads && SHEMEDIA_SLOTS['skm-ad-banner-1']) {
          window.blogherads.reloadAds([SHEMEDIA_SLOTS['skm-ad-banner-1']]);
          window.blogherads.reloadAds([SHEMEDIA_SLOTS['skm-ad-tinybanner-1']]);
        }

        var screen = this.get('screen');

        _tracker.Tracker.trackEvent({
          action: screen + ' ' + _tracker.CommonActions.screenView,
          category: this.get('category'),
          screen: screen
        });

        if ('didTransition' in this.controller) {
          this.controller.didTransition();
        }
      },
      displayNotification: function displayNotification(message) {
        $('#globalNotification #messageContent').html(message);
        $('#globalNotification').fadeIn('fast').delay(3000).fadeOut('slow');
      },


      closeSideNav: function closeSideNav() {
        Ember.$('.spin').show();
        this.send('gotoTopOfPage');

        Ember.$(".navbar-refer-a-friend").removeClass("active");
        Ember.$(".navbar-upgrade-to-premium").removeClass("active");
        Ember.$(".navbar-premium-features").removeClass("active");
        Ember.$(".navbar-photo-collections").removeClass("active");
        Ember.$(".navbar-export-moments").removeClass("active");
        Ember.$(".navbar-journal-details").removeClass("active");
        Ember.$(".navbar-family-friends").removeClass("active");
        Ember.$(".navbar-photobook").removeClass("active");
        Ember.$(".navbar-children").removeClass("active");
        Ember.$(".navbar-my-details").removeClass("active");
        Ember.$(".navbar-billing").removeClass("active");
        Ember.$(".navbar-store").removeClass("active");
        Ember.$(".navbar-start-journal").removeClass("active");

        Ember.$("#wrapper").removeClass("toggled");
        Ember.$('#trigger-layer').hide();
        Ember.$('#banner').show();
      },

      switchJournal: function switchJournal(journal) {
        this.send('closeSideNav');
        Ember.$('.spin').css('visibility', 'visible');
        Ember.$('table.calendar').css('visibility', 'hidden');

        if (this.get('session.isAuthenticated')) {
          this.transitionTo('main.journals.view', journal.get('id'));
        }
      }
    }
  });
});