define('tinybeans-frontend/components/file-input-area', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    type: 'file',
    classNames: ['file-uploader'],
    attributeBindings: ['accept', 'multiple', 'title'],
    accept: 'video/mp4,video/x-m4v,video/*,image/gif,image/jpeg,image/png', // avoiding image/* since uncompressed files can be ridiculously huge
    title: ' ', // Setting title to this gets rid of "No File Chosen" tooltip in Chrome

    change: function change(event) {
      if (event.target.files && event.target.files.length > 0) {
        this.sendAction('onFilesAdded', event.target.files);
      }
    }
  });
});