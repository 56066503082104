define('tinybeans-frontend/components/main/payment-details/card-number-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    type: 'text',
    attributeBindings: ['data-stripe', 'data-numeric'],

    keyPress: function keyPress(event) {
      Ember.$(".card-holder").removeClass("card_" + this.cardType);
      this.cardType = Ember.$.payment.cardType(Ember.$(event.target).val());
      if (this.cardType == "null") {
        Ember.$(".card-holder").addClass("glyphicon-lock");
      } else {
        Ember.$(".card-holder").addClass("card_" + this.cardType);
      }
    }
  });
});