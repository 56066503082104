define('tinybeans-frontend/routes/main/onboarding/step-five', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Onboarding.category,
    screen: _tracker.Onboarding.step5.screen,

    model: function model() {
      return Ember.RSVP.hash({
        journals: this.store.findAll('journal'),
        token: this.API.oneTimeToken()
        //      hasAddedFollower: params.followerAdded,
        //      hasEntryUploaded: params.entryUploaded,
        //      hasChildAdded: params.childAdded
      });
    },


    setupController: function setupController(controller, model) {

      this._super(controller, model);

      var journal = model.journals.objectAt(0);
      if (journal) {
        controller.set('journalId', journal.id);
      }
      //
      //     controller.set("hasAddedFollower", model.followerAdded ? model.followerAdded: false);
      //     controller.set("hasUploadedEntry", model.entryUploaded ? model.entryUploaded: false);
      //     controller.set("hasAddedChild", model.childAdded ? model.childAdded: false);
    },

    renderTemplate: function renderTemplate() {
      this.render('main/onboarding/step-five', { controller: 'main.onboarding.step-five' });
    },

    actions: {
      willTransition: function willTransition() {}
    }

  });
});