define('tinybeans-frontend/routes/download-app', ['exports', 'tinybeans-frontend/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    setupController: function setupController() {
      Ember.run.schedule('afterRender', this, function () {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        console.log(userAgent);
        if (userAgent.includes("Android")) {
          document.getElementById("dl-android").style.display = "block";
        } else if (userAgent.includes("iPhone")) {
          console.log("Apple");
          document.getElementById("dl-apple").style.display = "block";
        }
      });
    }
  });
});