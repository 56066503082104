define('tinybeans-frontend/routes/main/email-settings', ['exports', 'tinybeans-frontend/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        token: params.token,
        user: this.API.getEmailSettings(params.token),
        // user: this.store.find('user', 'me'),
        followings: this.API.getEmailFollowings(params.token),
        timeZones: this.API.listTimeZones(),
        measurementSystems: this.API.listMeasurementSystems(),
        dateStyles: this.API.listDateStyles(),
        emailFrequencyTypes: this.API.listEmailFrequencyTypes()
      });
    }
  });
});