define("tinybeans-frontend/tracker/gtm", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var GTM = {
    trackEvent: function trackEvent(event) {
      //console.log("Gtm tracking");
      window.dataLayer.push(event);
    }
  };

  exports.default = GTM;
});