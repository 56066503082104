define('tinybeans-frontend/controllers/main/onboarding/step-five', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    //  queryParams: ['followerAdded'],
    //  queryParams: ['entryUploaded'],
    //  queryParams: ['childAdded'],

    isActiveNavJournals: function isActiveNavJournals() {
      return true;
    },

    appDownloadLink: Ember.computed(function () {
      return 'https://tinybeans.app.link/cSF3BmRwEC?oneTimeToken=' + this.get('model.token.token');
    }),

    actions: {

      downloadApp: function downloadApp() {
        //branch IO goes here
        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.step5.screen,
          action: _tracker.Onboarding.step5.downloadAppsbutton
        });
        window.location.href = this.get('appDownloadLink');
      },

      onboardingGotoNextDesktop: function onboardingGotoNextDesktop() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.step5.screen,
          action: _tracker.Onboarding.step5.continueOnDesktop
        });

        var journalId = this.get('journalId');
        var d = new Date();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();
        this.transitionToRoute('main.journals.month', journalId, year, month);
      },

      onboardingGotoSettings: function onboardingGotoSettings() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.step5.screen,
          action: _tracker.Onboarding.step5.continueOnDesktop
        });

        this.transitionToRoute('main.account.show');
      },

      onboardingGotoNextMobile: function onboardingGotoNextMobile() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.step5.screen,
          action: _tracker.Onboarding.step5.continueOnMobile
        });

        var journalId = this.get('journalId');
        var d = new Date();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();
        this.transitionToRoute('main.journals.month', journalId, year, month);
      },

      clickTrack: function clickTrack(itemClass) {
        var eventLabel = itemClass ? itemClass : "na";
        this.GTM.trackEvent({
          'eventCategory': 'finish onboarding',
          'eventAction': "step-five-click-steps-indicator",
          'eventLabel': eventLabel
        });
      }

    }
  });
});