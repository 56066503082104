define('tinybeans-frontend/controllers/main/themed-cards/macmillan/show', ['exports', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _commonsMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_commonsMixin.default, {
        actions: {
            showMonth: function showMonth() {
                $(window).scrollTop(0);
                var journalId = this.get('model.journal.id');
                var date = new Date();
                var year = date.getFullYear(),
                    month = date.getMonth();
                this.transitionToRoute('main.journals.month', journalId, year, month);
            }
        }
    });
});