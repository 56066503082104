define('tinybeans-frontend/controllers/main/email-settings', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _baseController, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    user: null,
    followings: null,
    avatar: null,
    timeZones: null,
    measurementSystems: null,
    dateStyles: null,
    emailFrequencyTypes: null,
    submitting: false,
    token: null,

    saveButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? this.t('global.label.wait') : this.t('global.label.save');
    }.property('submitting'),

    actions: {

      save: function save() {

        var self = this;
        var user = self.get('model.user');
        var token = self.get('model.token');

        self.set('submitting', true);

        this.API.saveEmailSettings(token, self.get('model.followings'), user, function (data, statusText, xhr) {
          self.set('submitting', false);
          self.onApiSuccess(data, statusText, xhr);
        }, function (xhr, statusText, e) {
          self.set('submitting', false);
          self.onApiError(xhr, statusText, e);
        });
      }
    }
  });
});