define('tinybeans-frontend/initializers/ajax-prefilter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    Ember.$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
      var token = readCookie('accessToken');
      if (token) {
        jqXHR.setRequestHeader('Authorization', token);
      }
    });
  }

  exports.default = {
    name: 'ajax-prefilter',
    initialize: initialize
  };
});