define("tinybeans-frontend/templates/components/comment-textarea", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/components/comment-textarea.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("textarea");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(10);
        morphs[0] = dom.createAttrMorph(element0, 'value');
        morphs[1] = dom.createAttrMorph(element0, 'placeholder');
        morphs[2] = dom.createAttrMorph(element0, 'rows');
        morphs[3] = dom.createAttrMorph(element0, 'data-action');
        morphs[4] = dom.createAttrMorph(element0, 'data-label');
        morphs[5] = dom.createAttrMorph(element0, 'data-category');
        morphs[6] = dom.createAttrMorph(element0, 'data-trackable');
        morphs[7] = dom.createAttrMorph(element0, 'oninput');
        morphs[8] = dom.createAttrMorph(element0, 'onfocus');
        morphs[9] = dom.createAttrMorph(element0, 'onblur');
        return morphs;
      },
      statements: [["attribute", "value", ["get", "value", ["loc", [null, [2, 10], [2, 15]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "placeholder", ["get", "placeholder", ["loc", [null, [3, 16], [3, 27]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "rows", ["get", "rows", ["loc", [null, [4, 9], [4, 13]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-action", ["get", "data-action", ["loc", [null, [5, 16], [5, 27]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-label", ["get", "data-label", ["loc", [null, [6, 15], [6, 25]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-category", ["get", "data-category", ["loc", [null, [7, 18], [7, 31]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-trackable", ["get", "data-trackable", ["loc", [null, [8, 19], [8, 33]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", [["get", "onInput", ["loc", [null, [9, 19], [9, 26]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [9, 28]]], 0, 0], 0, 0, 0, 0], ["attribute", "onfocus", ["subexpr", "action", [["get", "onFocus", ["loc", [null, [10, 19], [10, 26]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [10, 28]]], 0, 0], 0, 0, 0, 0], ["attribute", "onblur", ["subexpr", "action", [["get", "onBlur", ["loc", [null, [11, 18], [11, 24]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [11, 26]]], 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());
});