define("tinybeans-frontend/locales/en/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    global: {
      label: {
        wait: "Please wait",
        save: "Save changes",
        or: "or",
        now: "now",
        signup: "sign up",
        login: "Login",
        itsfree: "it's free!",
        cancel: "Cancel"
      },
      error: {
        generic: "One or more validation errors was encountered with your request",
        field: {
          required: "Sorry, this is required"
        },
        notFollowing: "You are not following this journal. If you know the journal owner contact them to give you access!",
        notLoggedIn: "Please login first.",
        unknown: "Action couldn't be completed. Please contact the journal owner to review your access."
      }
    },

    Navigation: {
      Journal: "Journal",
      Journals: "Journals",
      Journal_Details: "Journal settings",
      Account: "Settings",
      My_Details: "My details",
      Albums: "Albums",
      Calendar: "Calendar",
      Followers: "Family & friends",
      Children: "Children",
      StartJournal: "Start my own journal",
      Store: "Photobooks",
      PrintIO: "Printing",
      Logout: "Logout",
      Login: "Log in",
      Sign_Up: "Sign up"
    },

    Application: {
      Uploads_Cancel_Warning: "Uploads that are in progress will be cancelled",
      Uploading: "Uploading",
      out_of: "out of",
      files: "files",
      Uploaded: "Uploaded",
      Connection_Interrupted: "Uh oh. The connection is interrupted. Upload will resume when everything is back to normal"
    },

    Account: {
      Account: "Your account settings",
      Email_Preferences_Title: "Account",
      Profile: "Profile",
      Photo: "Photo",
      Preferences: "Preferences",
      Timezones: "Timezone",
      Measurement_Systems: "Measurement system",
      Date_Styles: "Date style",
      Notifications: "Notifications",
      Other_Notifications: "New journal memories added to",
      Email_Preferences: "Email preferences",
      Connected_Accounts: "Connected accounts",
      Weekly_Flash_Back_Preferences: "Flashback email settings",
      Instagram: "Instagram",
      Instagram_Remove: "Remove",
      Instagram_Connect: "Connect to Instagram",
      Instagram_Sub_Heading: "You can add your Instagram photos to your Tinybeans journal by connecting your accounts. From now on, when you upload an photo to Instagram, use the #tinybeanskids hashtag and that photo will appear in your journal, like magic.",
      Change_Password: "Change password",
      Password_Mismatch: "Passwords must match",
      Instagram_Title: "Connect with Instagram and tag with #tinybeanskids to upload Instagram images directly to your journal",
      First_Name_Placeholder: "First name",
      Last_Name_Placeholder: "Last name",
      Email_Placeholder: "Email",
      Password_Placeholder: "New password",
      Password_Confirm_Placeholder: "Confirm password",
      Unsubscribe_From_Tinybeans: "I want to unsubscribe from all Tinybeans emails. You will no longer receive updates from families you follow.",
      Email_Marketing_Opt_Out: "I want to unsubscribe from important news and updates about Tinybeans, including special offers from our partners.",
      Send_Weekly_Email: "I want to receive a weekly digest of all my moments",
      Email_Frequency_Loved_Moments: "Email frequency on loved moments",
      Email_Frequency_New_Comment: "Email frequency on new comments",
      Start_My_Own_Journal: "Start my own journal",
      Start_My_Own_Journal_Instructions: "You have no journal yet, please create your own journal",
      Continue_Button_Label: "Continue",
      Confirm_Details_Heading: "Welcome to Tinybeans"
    },

    Children: {
      Children: "Children",
      Pets: "Pets",
      Add: "Add",
      Delete_Child: "Remove",
      Child_Details: "Fill in your child details",
      Family_Journal_Setup_Complete: "We've gone ahead and setup your family journal for you. You can upload photos and enjoy all the special moments about your children.",
      Tell_Us_About_Your_Family: "Just before you go ahead and do that, please tell us about your children.",
      Profile: "Child's name",
      Gender: "Gender",
      Male: "Boy",
      Female: "Girl",
      Unsure: "Unsure",
      DOB: "Date of birth or due date",
      Photo: "Photo",
      Instagram_Tag: "Instagram tag",
      Save_And_Continue: "Save and continue",
      Save_And_Add_Another: "Save and add another child",
      Skip: "Skip",
      Cancel: "Cancel",
      Add_Child_Prompt: "Add child",
      Delete_Confirm_Dialog: {
        Remove_Child: "Remove profile",
        Remove_Child_Text: "Are you sure?",
        Cancel: "Cancel"
      },
      First_Name_Placeholder: "Child's first name",
      Last_Name_Placeholder: "Child's last name",
      Connect_Child_Instagram_Title: "Images with this hashtag in Instagram will be copied to your journal"
    },

    Pet: {
      Pet: "Pet",
      Add: "Add",
      Delete_Pet: "Remove",
      Pet_Details: "Fill in your pet details",
      Family_Journal_Setup_Complete: "We've gone ahead and setup your family journal for you. You can upload photos and enjoy all the special moments about your children.",
      Tell_Us_About_Your_Family: "Just before you go ahead and do that, please tell us about your pets.",
      Profile: "Pet's name",
      Sex: "Sex",
      Male: "Male",
      Female: "Female",
      Birthday: "Date of birth",
      Photo: "Photo",
      Instagram_Tag: "Instagram tag",
      Save_And_Continue: "Save and continue",
      Save_And_Add_Another: "Save and add another pet",
      Update_Pet: "Update",
      Skip: "Skip",
      Cancel: "Cancel",
      Add_Pet_Prompt: "Add pet",
      Delete_Confirm_Dialog: {
        Remove_Pet: "Remove profile",
        Remove_Pet_Text: "Are you sure?",
        Cancel: "Cancel"
      },
      Name_Placeholder: "Pet's name",
      Breed_Placeholder: "Pet’s breed",
      Type_Placeholder: "Pet type",
      Other_Type_Placeholder: "Bird, Turtle, Iguana, etc",
      Connect_Child_Instagram_Title: "Images with this hashtag in Instagram will be copied to your journal"
    },

    Journal: {
      Title: "Create journal",
      Default_Title_Prompt: "Journal's name",
      Journals: "Journals",
      Edit: "Edit",
      Add_Journal_Prompt: "Start your own Tinybeans journal",
      Moment_Count: "Moments",
      Details_Heading: "Journal details",
      Title_Prompt: "Album title",
      Title_Prompt_Description: "(For example, 'The little rascals').",
      Title_Placeholder: "Title",
      Cover_Theme: "Cover theme",
      Add_Followers: "Add family and friends",
      Preferences: "Preferences",
      Follower_Suffix: "family and friends",
      Followers_Suffix: "family and friends",
      New_Moment_Email_Frequency: "New memory email",
      Disable_Comments: "Disable comments",
      Disable_Comments_Description: "Tick this checkbox to prevent all users and visitors from posting comments to your journal",
      Disable_Downloads: "Disable downloads",
      Disable_Downloads_Description: "Tick this checkbox to prevent all users and visitors from downloading moments you post to your journal",
      Unfollow: "Unfollow",
      Unfollow_Prompt: "I no longer wish to follow this journal. Please remove it from my dashboard",
      Unfollow_Confirmation: {
        Unfollow: "Remove profile",
        Unfollow_Text: "Are you sure?",
        Cancel: "Cancel"
      },

      Follower: {
        Followers: "Family and friends",
        Add: "Add family or friend",
        Is_You: "This is you",
        Is_Owner: "Journal creator",
        Remove: "Remove this family or friend",
        Can_Post_Moments: "Post moments",
        Can_View_Milestones: "View milestones",
        Can_Edit_Milestones: "Edit milestones",
        Invite_Details: "Invite details",
        Add_FAF: "Add family and friends",
        Update_Relationship: "Update relationships to journals you follow",
        Profile: "Profile",
        Profile_Description: "The family or friend you invite will receive an email every day you share a memory. Didn't we say it was easy?",
        Message: "Message",
        Message_Description: "Add a personal message",
        Permissions: "What they can do:",
        Add_Moments_Description: "This person can add memories to your journal",
        View_Milestones_Description: "This person can view your children's developmental milestones",
        Edit_Milestones_Description: "This person can add and edit your children's developmental milestones",
        Cancel: "Cancel",
        First_Name_Placeholder: "First name",
        Last_Name_Placeholder: "Last name",
        Email_Placeholder: "Email address",
        Add_To_Journal: "Add this person",
        Copy_Link: "Copy link"
      },
      Moments_This_Month: "memories this month",
      Add_Moment: "Add moments",
      Add_Collection: "Add an album",
      Prev_Year_Title: "Previous year",
      Next_Year_Title: "Next year",
      Prev_Month_Title: "Previous month",
      Next_Month_Title: "Next month",

      ExportMoments: "Export memories",
      PhotoCollections: "Photo albums"
    },

    Entries: {
      Add_Moment: "Add moments",
      Reorder: "Reorder",
      Save: "Save",
      Cancel: "Cancel",
      No_Moment: "No memories yet, please add one",
      Added_Moment: "Memory",
      To_Journal: "added to",
      Video_Processing: '"Sorry, the video is unreadable.',
      Video_Processing_Line2: "please try again in a couple of minutes",
      Name_Placeholder: "Your name",
      Comment_Placeholder: "Comment",
      Tag_Child: "Who's in this moment?",
      Memory_Moved: "Memory moved to"
    },

    Add_Moments_Dialog: {
      Tag_Prompt: "Who's in these photos?",
      Use_Photo_Date: "Use date from photos",
      Add_Image_Prompt: "Add image or video",
      Photo_Placeholder_Text: "Write about this moment, and remember it forever",
      Tag_Child: "Who's in this moment?",
      Hidden_Moment: "Hidden memory - only people with full access can see this",
      Share_Facebook: "Share on Facebook",
      Skip: "Skip",
      Add_Moment: {
        one: "Add a moment",
        other: "Add moments"
      }
    },

    Login: {
      Title: "Login",
      Lead: "Please enter your email and password to login",
      Help: "I forgot my password or username",
      Facebook: "Continue with Facebook",
      Button_Label: "Login",
      Placeholder: {
        Email: "Email address",
        Password: "Password"
      },
      Remember_Me: "Remember me",
      Check_Email: "Please check your email to continue",
      What_We_Sent: "We've sent you an email with your username and instructions on how to reset your password",
      What_We_Sent_Reminder: "Please check your email to continue",
      Return: "Try logging in again",
      Upgrade_Prompt: "Login to upgrade"
    },

    Reset: {
      Title: "Password reset",
      Instructions: "Enter a new password. Try to use a combination of symbols, numbers and words",
      Token_Invalid_Message: "It looks like the reset link has either expired or been used. For security, we expire links after some time has passed. Try resetting your password again (we send you an email with instructions).",
      Reset_Password: "Reset password",
      Change_Password: "Change password",
      Return: "Try logging in again",
      Placeholder: {
        Email: "Email address",
        Password: "Password"
      },
      Request: {
        Title: "I need help logging in",
        Prompt: "Don't worry. It happens to everyone.",
        Instructions: "Enter your email address and we'll send you an email with instructions on how to reset your password",
        Continue: "Continue",
        Return: "Try logging in again"
      }
    },

    Signup: {
      Title: "Sign up",
      TitleBottom: "Start a tradition that grows with your family",
      Sub_Heading: " Share your precious photos with the family and important friends who care",
      Prompt: "Signing up is easy and only takes a minute.",
      Placeholder: {
        Email: "Email",
        Password: "Password",
        First_Name: "First name",
        Last_Name: "Last name"
      },
      Facebook: "Continue with Facebook",
      SetupComplete: "You’ve just added your first memory to your new Tinybeans journal.",
      Paragraph2: "The final thing you need to do to get fully up and running is start inviting your family to follow your journal.",
      Thankyou: "Sharing precious memories with the people who care, is the fun part.",
      DownloadIPhoneApp: "Download the iPhone app",
      DownloadAndroidApp: "download the Android app",
      DownloadApp: "to upload photos on the go",
      InviteFriends: "Invite family and friends to your journal",
      Or: "or",
      Skip: "skip this step",
      PostFirstMoment: "Post your very first moment"
    },

    Email_Prompt: {
      No_Valid_Email: "We need your email address in case you need to reset your password.",
      Please_Add: "It helps us keep Tinybeans extra secure for you",
      Email_Placeholder: "Email"
    },

    footer: {
      tagline: "Tinybeans is your family's nurture network",
      faq: "FAQ",
      about: "About",
      terms: "Terms",
      privacy: "Privacy",
      media: "Media",
      blog: "Blog",
      store: "Store"
    },

    Subscription: {
      Title: "Tinybeans Memories are little rays of sunshine for your family and important friends. With Tinybeans Premium you all get more of the good stuff.",
      Feature1: "Add multiple photos",
      Feature_Description1: "Upload multiple photos at once and easily fill gaps in your earlier months.",
      Feature2: "Export your moments",
      Feature_Description2: "Back up your Tinybeans photos and videos in one click.",
      Feature3: "Beautiful photo albums",
      Feature_Description3: "Create online albums to send to people who aren't on Tinybeans.",
      Feature_Description3_android: "* Arriving for Android in May 2015",
      Feature4: "Share longer videos",
      Feature_Description4: "Free yourself from the 30 second video with 5 minutes videos.",
      Feature5: "Become a Tinybeans VIP",
      Feature_Description5: "Priority customer support and the inside scoop on what's in the works.",
      Sub_Heading: "Begin sharing even more sunshine today",
      Price_Currency: "$",
      Monthly_Plan: "Monthly Plan",
      Monthly_Price: "8",
      Monthly_Price_Unit: "/month",
      Monthly_Description: "Your family and friends will receive all the Tinybeans premium benefits, one month at a time. Recurring billing.",
      Monthly_Button_Label: "Buy a month",
      Yearly_Plan: "Yearly Plan",
      Yearly_Price: "80",
      Yearly_Price_Unit: "/year",
      Yearly_Description: "Your family and friends will receive all the Tinybeans premium benefits for a whole year. Recurring billing.",
      Yearly_Button_Label: "Buy a year",
      Select_Journal: "Select a journal to upgrade",
      Upgrade_To_Premium: "upgrade your Tinybeans",
      Card_Details: "Card details",
      Please_Select_Journal: "Please select a journal"
    },

    SignupPregnancy: {
      Title: "The sweetest way to record your pregnancy",
      Sub_Heading: "Privately share and organize moments that matter",
      SignupButtonLabel: "Sign up for free",
      TitleBottom: "Start a tradition that grows with your family",
      BottomSignupButtonLabel: "Sign up for free"
    },

    ExportMoment: {
      Title: "Export memories",
      Sub_Heading: "You can export your Tinybeans photos and videos. When the files are ready, we'll email it to you.",
      ChoosePeriodLabel: "Choose a period of time:",
      From: "From:",
      To: "To:",
      CannotAccess: "You might not have access to export this journal. You can ask the journal owner to give you access to this."
    },

    Collection: {
      Add_Collection: "Add new album",
      Add_Collection_Title: "Title",
      Add_Collection_Description: "Description",
      No_Access_Message: "You don't have access to this page.",
      Upgrade_Message: "Please upgrade this journal to Tinybeans Premium to enable album feature.",
      Upgrade_Button_Label: "upgrade your Tinybeans",
      List_Organize_Collection: "Organize albums",
      List_Organize_Collection_Message: "You can drag and drop album cover to re-order.",
      Add_Moment_Title: "Add moments to album",
      Add_Moment_Sub_Heading: "Select moments to add them to this album",
      No_Moment_Message: "There are no moments for this month. Try adding moments from past months or adding new moments in your journal",
      Prev_Month: "prev month",
      Next_Month: "next month",
      Moments_Selected: "moments selected",
      List_Title: "Photo albums",
      List_TitleOrganizeMoments: "Organize albums",
      List_Sub_Heading: "Photo albums are great for organizing and sharing your moments",
      Add_New_Collection: "Add new album",
      Cover_Title_1: "Send a link to your child's first birthday photos to all those who celebrated.",
      Cover_Title_2: "Share a summary of all the special milestones that you won't want to forget.",
      Cover_Title_3: "Celebrate the holidays with a slideshow of the year just passed.",
      Organize_No_Moment_Message: "No moments to organize.",
      Organize_Title: "Organize moments",
      Organize_Instruction: "Drag and drop moments in ",
      Back_To: "back to",
      Add_Moments_Short_Button_Label: "Add moments",
      Back_To_Top: "Back to top",
      Back_To_All_Collections: "Back to all albums",
      Collection_No_Moments: "This album has no moments.",
      Add_Moments_To_This_Collection: "Add moments to this album",
      Start_Slideshow_Button_Label: "Start slideshow",
      Stop_Slideshow_Button_Label: "Stop",
      Share_Email: "Share by email",
      Share_Twitter: "Share by Twitter"
    },

    Onboarding: {
      Save_And_Continue: "Save and continue"
    }
  };
});