define('tinybeans-frontend/routes/main/onboarding/step-three', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker', 'tinybeans-frontend/config/environment', 'lodash/lodash', 'tinybeans-frontend/utils/helper'], function (exports, _baseProtectedRoute, _tracker, _environment, _lodash, _helper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Onboarding.category,
    screen: _tracker.Onboarding.step3.screen,

    model: function model(params) {
      var journalId = params.journal_id || (this.modelFor('main').currentJournal ? this.modelFor('main').currentJournal.id : NaN);

      return Ember.RSVP.hash({
        journalId: journalId,
        hasAddedChild: params.childAdded,
        hasAddedEntry: false,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        followings: this.modelFor('main').followings,
        memoriesAccess: this.API.hasMemoriesAccess(journalId),
        memories_limit: this.API.getMemoriesLimit(),
        languages: navigator.languages,
        followers: this.store.query('follower', {
          filter: {
            journalId: journalId
          }
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var language_code = model.languages[0] || 'en-US';
      controller.set('userLanguage', language_code);

      // Subscription LP Updated
      Leanplum.addVariablesChangedHandler(function () {
        var vars = Leanplum.getVariables();
        if (vars.Paywall) {
          var paywall_prices = '';
          if ('undefined' !== typeof vars.Paywall[language_code]) {
            paywall_prices = vars.Paywall[language_code];
          } else {
            paywall_prices = vars.Paywall['en-US']; // default or unhandled
          }
          var paywall_products = JSON.parse(vars.Paywall.newProducts);
          controller.set('title', 'Pick your plan!');
          controller.set('paidFeatures', paywall_products.paidFeatures);
          controller.set('freeFeatures', paywall_products.freeFeatures);

          // Includes currency
          var prices = (0, _helper.calculatePrices)(paywall_prices);
          controller.set('monthlyPriceId', paywall_prices.monthlyStripePriceId);
          controller.set('annualPriceId', paywall_prices.yearlyStripePriceId);

          controller.set('prices', prices);
        }
      });
      if (model.memoriesAccess.hasMemoriesAccess) {
        controller.set('isSubscribedUser', true);
      }
      var params = controller.getProperties(['year', 'month', 'day']);
      if (params.year && params.month && params.day) {
        controller.set('date', moment(new Date(params.year, params.month - 1, params.day)).format(this.globals.usersDateFormat()));
      } else {
        controller.set('date', moment().format(this.globals.usersDateFormat()));
      }
      controller.set('entriesToUpload', []);
      controller.set("hasAddedChild", model.hasAddedChild);
      controller.set("hasAddedEntry", model.hasAddedEntry);
      controller.addNewEntry();

      // controller.set("numChildren", journal.)

      // emit free signup conversion for CJ
      if (null === this.modelFor('main').isSubscribedUser.subscription) {
        var cj_args = {
          'event': 'cj_user_signup_free',
          'eventCategory': 'confirmation_page',
          'eventAction': 'continued free',
          'eventLabel': 'web',
          'orderID': window.crypto.randomUUID()
        };
        this.GTM.trackEvent(cj_args);
        if (_environment.default.enable_qa_output) {
          console.log("%cCJ Testing Output: Message sent with following data >>", "color: white; background-color: #fcb900;", cj_args);
        }
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({ year: '', month: '', day: '' });
      }
    }
  });
});