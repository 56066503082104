define("tinybeans-frontend/templates/components/nav-no-ads", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/components/nav-no-ads.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "banner");
        dom.setAttribute(el1, "class", "navbar navbar-default");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "header");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        return morphs;
      },
      statements: [["inline", "site-header", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [3, 24], [3, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "me", ["subexpr", "@mut", [["get", "model.me", ["loc", [null, [3, 33], [3, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "session", ["subexpr", "@mut", [["get", "session", ["loc", [null, [3, 50], [3, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "journals", ["subexpr", "@mut", [["get", "model.existingJournals", ["loc", [null, [4, 13], [4, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "currentJournal", ["subexpr", "@mut", [["get", "model.currentExistingJournal", ["loc", [null, [5, 19], [5, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "switchJournal", "switchJournal", "logout", "logout", "doTransition", "doTransition"], ["loc", [null, [3, 4], [7, 33]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});