define('tinybeans-frontend/controllers/login', ['exports', 'tinybeans-frontend/tracker'], function (exports, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      window.location.replace("/login");
    },

    session: Ember.inject.service('session'),
    i18n: Ember.inject.service(),
    queryParams: ['from', 'upgradePrompt', 'inviteToken', 'journal', 'redirectionError'],
    from: null,
    upgradePrompt: null,

    identification: '',
    password: '',
    rememberMe: true,
    error: {},
    authenticator: 'authenticator:access-token',

    submitting: false,

    invitationMessage: Ember.computed('redirectionError', 'inviteToken', function () {
      var _getProperties = this.getProperties('journal', 'inviteToken', 'redirectionError'),
          journal = _getProperties.journal,
          inviteToken = _getProperties.inviteToken,
          redirectionError = _getProperties.redirectionError;

      if (journal && inviteToken) {
        return (redirectionError ? "You're already a Tinybeans member." : "") + '\n      Login to join the ' + journal + ' journal';
      }
    }),

    invitationMessageType: Ember.computed('redirectionError', function () {
      return this.get('redirectionError') ? 'warning' : 'success';
    }),

    buttonLabel: function () {
      //console.log(this.get('inviteToken'), this.get('from'));
      var submitting = this.get('submitting');
      return submitting ? this.get('i18n').t("global.label.wait") : this.get('i18n').t("Login.Button_Label");
    }.property('submitting'),

    onSessionIsAuthenticated: function onSessionIsAuthenticated() {
      var isAuthenticated = this.get('session.isAuthenticated');

      if (isAuthenticated) {
        // VWO Optimiser tracking
        window._vis_opt_queue = window._vis_opt_queue || [];
        window._vis_opt_queue.push(function () {
          _vis_opt_goal_conversion(200);
        });
        var transition = this.get('session.attemptedTransition');
        if (transition) {
          transition.retry();
        } else if (this.get('from')) {
          window.location.href = this.get('from');
        } else {
          this.transitionToRoute('main.journals.home');
        }
      }
    },

    isOnLogin: function () {
      return true;
    }.property(),

    actions: {
      authenticate: function authenticate() {
        var self = this;
        self.set('submitting', true);
        var options = {
          identification: this.get('identification'),
          password: this.get('password'),
          rememberMe: this.get('rememberMe'),
          inviteToken: this.get('inviteToken')
        };
        var error = void 0;
        if (!options.identification) {
          error = {};
          error.identification = this.get('i18n').t("global.error.field.required");
          error.global = this.get('i18n').t("global.error.generic");
        }
        if (!options.password) {
          error = error || {};
          error.password = this.get('i18n').t("global.error.field.required");
          error.global = this.get('i18n').t("global.error.generic");
        }
        this.set('error', error);
        if (error) {
          self.set('submitting', false);
        } else {
          this.get('session').authenticate('authenticator:access-token', options).then(function () {
            self.set('submitting', false);
            self.onSessionIsAuthenticated();
            _tracker.Tracker.trackEvent({
              action: _tracker.Login.loginButton,
              category: _tracker.Login.category
            });

            self.GTM.trackEvent({
              'eventCategory': 'login page events',
              'eventAction': 'success logged in',
              'eventLabel': 'default login'
            });
          }, function (error) {
            var authError = {};
            authError.global = error.message;
            self.set('error', authError);
          });
        }
      },

      authenticateWithFacebook: function authenticateWithFacebook() {
        var _this = this;

        this.get('session').authenticate('authenticator:facebook', {
          inviteToken: this.get('inviteToken')
        }).then(function () {
          var data = _this.get('session.data.authenticated');
          if (data.didCreateUser) {
            _this.GTM.trackEvent({
              'eventCategory': 'signup',
              'eventAction': 'facebook_signup',
              'eventLabel': 'login_page'
            });
            //this.transitionToRoute('main.onboarding.step-start');
            _this.transitionToRoute('main.account.confirm-details');
          } else {
            _this.onSessionIsAuthenticated();
          }
          _tracker.Tracker.trackEvent({
            action: data.didCreateUser ? _tracker.Login.facebookSignup : _tracker.Login.facebooklogin,
            category: _tracker.Login.category,
            screen: _tracker.Login.screen
          });
        });
      },

      signup: function signup() {
        this.transitionToRoute('signup');
      }
    }
  });
});