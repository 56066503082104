define('tinybeans-frontend/controllers/main/onboarding/step-four', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    //  queryParams: ['entryAdded','childAdded'],

    isActiveNavJournals: function isActiveNavJournals() {
      return true;
    },

    submitButtonLabel: function () {
      return this.t("Journal.Follower.Add_To_Journal");
    }.property('submitting'),

    actions: {
      inviteSent: function inviteSent(action) {
        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          action: action == 'save' ? _tracker.Onboarding.step3.saveButton : action == 'copy' ? _tracker.Onboarding.step3.copyLink : undefined,
          screen: _tracker.Onboarding.step4.screen
        });
        this.GTM.trackEvent({
          'eventCategory': 'onboarding-child-first',
          'eventAction': 'step-four-click',
          'eventLabel': 'add-follower'
        });
        var journalId = this.get('journalId');
        this.transitionToRoute('main.onboarding.step-five', journalId);
      },


      expandMessage: function expandMessage() {
        this.set('showMessage', true);
      },

      cancel: function cancel() {
        var journalId = this.get('journalId');
        this.transitionToRoute('main.onboarding.step-five', journalId);
        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.step4.screen,
          action: _tracker.Onboarding.step4.skipButton
        });
        this.GTM.trackEvent({
          'eventCategory': 'onboarding-child-first',
          'eventAction': 'step-four-click',
          'eventLabel': 'skip-add-follower'
        });
      },


      clickTrack: function clickTrack(itemClass) {
        var eventLabel = itemClass ? itemClass : "na";
        this.GTM.trackEvent({
          'eventCategory': 'onboarding-child-first',
          'eventAction': "step-four-click-steps-indicator",
          'eventLabel': eventLabel
        });
      }

    }
  });
});