define('tinybeans-frontend/adapters/invitee', ['exports', 'tinybeans-frontend/adapters/application', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _application, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_dataAdapterMixin.default, {
    // Query means get followers for a journal (use query on journal instead?
    query: function query(store, type, _query) {
      var url = "/api/1/journals/" + _query.filter.journalId + "/invites";
      return this.ajax(url, 'GET', {});
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var url = '/api/1/journals/' + snapshot.adapterOptions.journalId + '/invites/' + snapshot.id;
      return this.ajax(url, 'Delete', {});
    }
    //
    // updateRecord(store, type, snapshot) {
    //   let data = this.serialize(snapshot);
    //   // debugger;
    //   if (data.relationship) {
    //     data.relationship = data.relationship.name;
    //   }
    //   var journalId = parseInt(snapshot.adapterOptions.journalId);
    //   var url = `/api/1/journals/${journalId}/invites/${ snapshot.id}`;
    //   return $.ajax({url: url, type: 'post', data: JSON.stringify(data), dataType: 'json'});
    // }

  });
});