define("tinybeans-frontend/templates/main/onboarding/step-one", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 51,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/onboarding/step-one.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n    #main-nav-wrapper, #footer { display:none;}\n    .progress-bar-container, #globalNotification {display:none!important;}\n    .content-container { padding-top:20px!important;}\n\n    .step-row{\n        margin-bottom: 15px;\n    }\n\n    .step-active{\n        background-color: #039398 !important;\n    }\n    \n    .step-indicator {\n        width: 100px;\n        height: 7.5px;\n        margin: 0 3.5px 0 0;\n        border-radius: 250px;\n        background-color: #dedee5;\n        display: inline-block;\n    }\n\n    @media screen and (max-width: 468px) {\n    .step-indicator {\n        width: 55px;\n        height: 7.5px;\n        margin: 0 3.5px 0 0;\n        border-radius: 250px;\n        background-color: #dedee5;\n        display: inline-block;\n    }\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "onboarding-step-one");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row step-row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-12 text-center");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "step-indicator");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "step-indicator step-active");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "step-indicator");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "step-indicator");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "step-indicator");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 1]), 3, 3);
        return morphs;
      },
      statements: [["inline", "edit-child-info-onboarding", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [47, 43], [47, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "child", ["subexpr", "@mut", [["get", "model.child", ["loc", [null, [47, 55], [47, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "editing", false, "displayNotification", "displayNotification", "doTransition", "doTransition", "reloadPage", "reloadPage", "onSave", ["subexpr", "action", ["onSave"], [], ["loc", [null, [47, 182], [47, 199]]], 0, 0], "onSkip", ["subexpr", "action", ["onSkip"], [], ["loc", [null, [47, 207], [47, 224]]], 0, 0]], ["loc", [null, [47, 8], [47, 226]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});