define('tinybeans-frontend/components/main/collections/slide-show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super();
      Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },

    afterRenderEvent: function afterRenderEvent() {

      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("msie") != -1 || ua.indexOf("opera") != -1) {
        Ember.$('body').css('overflow', 'hidden');
        Ember.$('html').css('overflow', 'hidden');
      }

      Ember.$(function () {
        Ember.$('#bannerscollection_zoominout_opportune').bannerscollection_zoominout({
          skin: 'opportune',
          responsive: true,
          width: 1920,
          height: 1200,
          width100Proc: true,
          height100Proc: true,
          fadeSlides: 1,
          showNavArrows: true,
          showBottomNav: false,
          autoHideBottomNav: true,
          pauseOnMouseOver: false,
          circleAlpha: 50,
          enableTouchScreen: true
        });
      });

      Ember.$('.slide-show-image').load(function () {});
    }
  });
});