define('tinybeans-frontend/initializers/global-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('controller', 'globals', 'service:globals');
    application.inject('controller', 'i18n', 'service:i18n');
    application.inject('route', 'i18n', 'service:i18n');
    application.inject('component', 'i18n', 'service:i18n');
    application.inject('route', 'globals', 'service:globals');
    application.inject('component', 'globals', 'service:globals');
    application.inject('adapter', 'globals', 'service:globals');
    application.inject('transform', 'globals', 'service:globals');

    application.inject('controller', 'GTM', 'service:google-tag-manager');
    application.inject('route', 'GTM', 'service:google-tag-manager');
    application.inject('component', 'GTM', 'service:google-tag-manager');
  }

  exports.default = {
    name: 'global-service',
    after: 'ember-i18n',
    initialize: initialize
  };
});