define('tinybeans-frontend/models/checklist', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    contentUrl: (0, _attr.default)('string'),
    sortOrder: (0, _attr.default)('number'),
    deleted: (0, _attr.default)('boolean'),
    timestamp: (0, _attr.default)('number'),
    lastUpdatedTimestamp: (0, _attr.default)('number'),
    featureImageUrl: (0, _attr.default)('string'),
    thumbnailImageUrl: (0, _attr.default)('string'),
    disclaimerText: (0, _attr.default)('string'),
    showDateRange: (0, _attr.default)('boolean'),
    themeColorString: (0, _attr.default)('string'),
    checklistItems: (0, _relationships.hasMany)('checklistItem'),
    type: (0, _attr.default)('string')
  });
});