define("tinybeans-frontend/controllers/main", ["exports", "tinybeans-frontend/mixins/navigation", "lodash/lodash", "tinybeans-frontend/mixins/commons-mixin"], function (exports, _navigation, _lodash, _commonsMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_commonsMixin.default, _navigation.default, {
    queryParams: ["_campaign", "_source", "_num", "hide_ui"],
    hide_ui: false,
    missingEmail: false,
    showModalAfter: 0,
    segment: "",
    showDownloadAppPopup: false,
    showedAnnouncementPopup: true,

    init: function init() {
      Ember.run.schedule("afterRender", this, function () {
        Ember.$(document).on("click", ".navbar-collapse.in", function (e) {
          if (Ember.$(e.target).is("a")) {
            Ember.$(this).collapse("hide");
          }
        });

        Ember.$(document).ready(function () {
          Ember.$("a").tooltip();
        });

        var body = $("body");
        var scrollActive = false;
        $(window).scroll(function () {
          var nextscrollActive = window.pageYOffset > 0;
          if (nextscrollActive !== scrollActive) {
            scrollActive = nextscrollActive;
            if (scrollActive) {
              body.addClass("scroll-active");
            } else {
              body.removeClass("scroll-active");
            }
          }
        });
      });
    },

    progressCompleted: function () {
      console.log("main proessgress" + this.get("globals.uploadedPercentage"));
    }.property("globals.uploadedPercentage", "globals.fileNumber", "globals.totalFiles"),

    currentJournal: function () {
      return this.get("model.currentJournal");
    }.property("model.currentJournal"),

    adToShow: Ember.computed('currentRouteName', 'session.isAuthenticated', 'isLoggedOutInDayView', function () {
      var route = this.get('currentRouteName') || '';
      var adId = this.get('isLoggedOutInDayView') ? 'tinybeans_webapp_dayview_atf_leadboard' : 'tinybeans_leaderboard_top';
      var routeIsLoading = route.includes('loading');
      return {
        adId: adId,
        loading: routeIsLoading
      };
    }),

    entryJournalName: Ember.computed("model.entryJournalName", function () {
      return this.get("model.entryJournalName");
    }),

    showJournalDropdown: Ember.computed("model.currentJournal", "model.myJournals", function () {
      var _this = this;

      //if current user has access to current journal
      if (this.get("model.myJournals")) {
        var myJournals = this.get("model.myJournals").toArray();
        return _lodash.default.find(myJournals, function (accessibleJournal) {
          return accessibleJournal.get("journal.id") === _this.get("model.currentJournal.id");
        });
      } else {
        return false;
      }
    }),

    toggleShemediaAdsBadge: function () {
      var currentJournal = this.get("model.currentJournal");
      var shemediaBottomAds = document.querySelector("#bhbar");
      var shemediaLogo = document.querySelector("#blogherads-branding-container");
      var shouldShowAds = true;

      if (currentJournal) {
        shouldShowAds = this.get('shouldShowAds');
        if (shouldShowAds === true) {
          if (shemediaBottomAds) shemediaBottomAds.style.display = "block";
          if (shemediaLogo) shemediaLogo.style.display = "block";
        } else {
          if (shemediaLogo) shemediaLogo.style.display = "none";
          if (shemediaBottomAds) shemediaBottomAds.style.display = "none";
        }
      }
    }.observes("model.currentJournal"),

    actions: {
      gotoPremium: function gotoPremium() {
        var self = this;
        var journal = self.get("model.journal");
        var journalId = journal.id;

        self.send("gotoTopOfPage");
        self.transitionToRoute("main.subscription-products.list", {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      }
    }
  });
});