define('tinybeans-frontend/models/pet-completed-checklist-item', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    createdDate: (0, _attr.default)('apidate'),
    updatedDate: (0, _attr.default)('apidate'),
    completedDate: (0, _attr.default)('apidate'),
    checklistItemId: (0, _attr.default)('number')

  });
});