define('tinybeans-frontend/components/main/journals/month/pet-component', ['exports', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_commonsMixin.default, {

    milestoneCount: Ember.computed('pet', function () {
      var _this = this;

      var promise = this.API.completedChecklistItems(this.get('pet.id'));
      promise.then(function (pet) {
        _this.set('model.petMilestoneCount', pet.length);
        return _this.get('model.petMilestoneCount');
      });
    }),

    actions: {}

  });
});