define('tinybeans-frontend/adapters/payment-transaction', ['exports', 'tinybeans-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findAll: function findAll() {
      var url = '/api/store/paymentTransactions';
      return this.ajax(url, 'GET');
    }
  });
});