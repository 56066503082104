define("tinybeans-frontend/helpers/combine-strings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.combineStrings = combineStrings;
  function combineStrings(params) {
    var stringOut = "";
    for (var i = 0; i < params.length; i++) {
      if (!params[i]) {
        break;
      } else {
        stringOut += params[i];
      }
    }
    return stringOut;
  }

  exports.default = Ember.Helper.helper(combineStrings);
});