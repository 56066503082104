define('tinybeans-frontend/controllers/main/journals/export', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _baseController, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    dateFormat: 'YYYY-MM-DD',
    submitting: false,

    submitButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? 'Please wait ...' : 'Start export';
    }.property('submitting'),

    exportEnabled: Ember.computed('model.journal', function () {
      return this.get('globals').journalExportEnabled(this.get('model.journal'));
    }),

    actions: {
      startExport: function startExport(journalId, exportStartDate, exportEndDate) {
        var _this = this;

        var dateFormat = this.get('usersDateFormat');
        var formattedStartDate = exportStartDate ? moment(exportStartDate, dateFormat).format('YYYY-MM-DD') : null;
        var formattedEndDate = exportEndDate ? moment(exportEndDate, dateFormat).format('YYYY-MM-DD') : null;

        if (formattedStartDate == null || formattedEndDate == null || formattedEndDate < formattedStartDate) {
          this.set('submitting', false);
          this.get('flashMessages').clearMessages();
          this.get('flashMessages').add({
            message: 'Oops, a problem occurred, please check your start and end date of the export',
            sticky: true,
            exportMessage: true,
            type: 'alert alert-warning',
            glyphiconClass: 'glyphicon glyphicon-warning-sign'
          });
          // this.route.refresh();
        } else {
          this.set('submitting', true);
          this.API.exportJournal(journalId, formattedStartDate, formattedEndDate, function () {
            _this.set('submitting', false);
            _this.get('flashMessages').clearMessages();
            _this.get('flashMessages').add({
              message: 'Success! Your journal\'s photos and videos will be emailed to you when it\'s ready. Please be patient if there are lots of large files in your journal. Our working bees are heading to the hive now.',
              sticky: true,
              exportMessage: true,
              type: 'alert alert-success',
              glyphiconClass: 'glyphicon glyphicon-ok'
            });
            // this.transitionToRoute('main.journals.month', journalId, date.year(), date.month() + 1);
          }, function (xhr, statusText, e) {
            _this.onApiError(xhr, statusText, e);
            _this.set('submitting', false);
            _this.get('flashMessages').clearMessages();
            _this.get('flashMessages').add({
              message: 'Oops, a problem occurred, please try later',
              sticky: true,
              exportMessage: true,
              type: 'alert alert-danger',
              glyphiconClass: 'glyphicon glyphicon-remove'
            });
          });
        }
      }
    }
  });
});