define('tinybeans-frontend/components/main/payment-details/coupon-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    tagName: 'a',
    click: function click() {
      this.toggleCouponLink();
    }
  });
});