define('tinybeans-frontend/models/completed-checklist-item', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    URL: (0, _attr.default)('string'),
    year: (0, _attr.default)('number'),
    month: (0, _attr.default)('number'),
    day: (0, _attr.default)('number'),
    timestamp: (0, _attr.default)('number'),
    lastUpdatedTimestamp: (0, _attr.default)('number'),
    deleted: (0, _attr.default)('boolean'),
    checklistItemId: (0, _attr.default)('number'),
    childId: (0, _relationships.belongsTo)('child'),
    userId: (0, _relationships.belongsTo)('user'),

    child: Ember.computed.alias('childId')
  });
});