define("tinybeans-frontend/components/main/photobook-popup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    submitting: false,

    init: function init() {
      var _this = this;

      this._super();
      Ember.run.scheduleOnce("afterRender", function () {
        $(".close").click(function () {
          _this.GTM.trackEvent({
            eventCategory: "photobook_popup",
            eventAction: "close_photobook_modal",
            eventLabel: "close_button"
          });
        });
      });
    },


    actions: {
      close: function close() {
        this.send("setCookie");
        this.GTM.trackEvent({
          eventCategory: "photobook_popup",
          eventAction: "close_photobook_modal"
        });
      },
      checkoutPhotobook: function checkoutPhotobook() {
        this.GTM.trackEvent({
          eventCategory: "photobook_popup",
          eventAction: "click_checkout_out_button"
        });
        window.location.href = "/photobooks";
        this.send("setCookie");
      },


      readCookie: function readCookie(key) {
        var value = document.cookie.match(new RegExp(key + "=([^;]+)")) || [];
        return decodeURIComponent(value[1] || "");
      },

      writeCookie: function writeCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },

      setCookie: function setCookie() {
        this.send("writeCookie", "showedPhotobookPopup", "true", 1);
      }
    }
  });
});