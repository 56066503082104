define('tinybeans-frontend/utils/helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.calculatePrices = calculatePrices;
  function calculatePrices(paywall_prices) {
    var MICRO = 1 / 1000000.0;

    function extractPriceInfo(value) {
      var regex = /^([^\d]+)?([\d.,]+)$/;
      var match = value.match(regex);

      if (!match) {
        return {
          currencyCode: '',
          amount: value
        };
      }

      var currencyCode = match[1] ? match[1].trim() : '';
      var rawAmount = match[2];

      var standardizedAmount = rawAmount.replace(',', '.');
      var amount = parseFloat(standardizedAmount);

      if (isNaN(amount)) {
        return {
          currencyCode: '',
          amount: value
        };
      }

      return {
        currencyCode: currencyCode,
        amount: amount
      };
    }

    var yearlyPriceInfo = extractPriceInfo(paywall_prices.yearlyPrice);
    var yearlyVal = yearlyPriceInfo.amount * MICRO;
    var monthlyVal = extractPriceInfo(paywall_prices.monthlyPrice).amount * MICRO;
    var discount = (monthlyVal * 12 - yearlyVal) / (monthlyVal * 12) * 100;
    var monthlyAmount = parseFloat(extractPriceInfo(paywall_prices.yearlyPrice).amount / 12).toFixed(2);
    return {
      monthly: paywall_prices.monthlyPrice,
      yearly: {
        price: paywall_prices.yearlyPrice,
        pricePerMonth: yearlyPriceInfo.currencyCode + monthlyAmount,
        discount: Math.round(discount)
      }
    };
  }
});