define('tinybeans-frontend/components/main/journals/add-moment/child-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    style: Ember.computed('selected', function () {
      return this.get('selected') ? 'display:inline-block; border: 2px solid #ffd017; height: 34px; width: 34px; margin-left: -2px; margin-top: -2px;' : 'display:inline-block;';
    })
  });
});