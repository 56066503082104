define('tinybeans-frontend/controllers/main/journals/follower-list-invite', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/tracker'], function (exports, _baseController, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    i18n: Ember.inject.service(),
    main: Ember.inject.controller(),

    isActiveNavJournals: function isActiveNavJournals() {
      return true;
    },

    submitting: false,

    submitButtonLabel: function () {
      return this.get("i18n").t("Journal.Follower.Add_To_Journal");
    }.property('submitting'),

    fullAccessGranted: function () {
      if (this.get('invite.coOwner')) {
        // Check and disable all other boxes
        this.set('invite.addEntries', true);
        Ember.$('#addEntries').prop('disabled', true);

        this.set('invite.editMilestones', true);
        Ember.$('#editMilestones').prop('disabled', true);

        this.set('invite.viewMilestones', true);
        Ember.$('#viewMilestones').prop('disabled', true);
      } else {
        // Enable all other boxes (except view milestones, that's edit milestones responsibility)
        Ember.$('#addEntries').prop('disabled', false);
        Ember.$('#editMilestones').prop('disabled', false);
      }
    }.observes('invite.coOwner'),

    editMilestonesGranted: function () {
      if (this.get('invite.editMilestones')) {
        this.set('invite.viewMilestones', true);
        Ember.$('#viewMilestones').prop('disabled', true);
      } else {
        Ember.$('#viewMilestones').prop('disabled', false);
      }
    }.observes('invite.editMilestones'),

    childName: Ember.computed('model.children', function () {
      var children = this.get('model.children');
      return children.get('length') === 1 ? children.objectAt(0).get('firstName') : "your children";
    }),

    actions: {
      inviteSent: function inviteSent(action) {
        this.send('trackOnboarding', 'add-follower', action);
        _tracker.Tracker.trackEvent({
          category: _tracker.Followers.category,
          action: action == 'save' ? _tracker.Followers.invite.saveButton : action == 'copy' ? _tracker.Followers.invite.copyLink : undefined,
          screen: _tracker.Followers.invite.screen
        });
        this.send('displayNotification', '<b>' + this.get('model.invite.firstName') + ' added to Family & Friends </b><br /> Who else would love to see ' + this.get('childName') + '\u2019s moments? Add them now.');
        var journalId = this.get('model.journalId');
        this.transitionToRoute('main.journals.follower-list', journalId);
      },


      cancel: function cancel() {
        var journalId = this.get('model.journalId');
        this.transitionToRoute('main.journals.follower-list', journalId);
        _tracker.Tracker.trackEvent({
          category: _tracker.Followers.category,
          action: _tracker.Followers.invite.cancelButton,
          screen: _tracker.Followers.invite.screen
        });
      },

      expandMessage: function expandMessage() {
        this.set('showMessage', true);
      }

    }
  });
});