define('tinybeans-frontend/routes/main/journals/hidden-moment', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model(params) {
      var journalId = params.journal_id;

      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        followers: this.store.query('follower', { filter: { journalId: journalId } }),
        followings: this.modelFor('main').followings

      });
    },
    //
    // doControllerSetup: function (controller, model) {
    //     var me = model.me;
    //     var followers = model.followers;
    //
    //     var entries = model.entries;
    //
    //     var journal = model.journal;
    //     var journalId= model.journalId;
    //     if(journal){
    //       controller.get('controllers.main').set('currentJournal', journal);
    //     }
    //
    //     var isMyJournal = me != null && model.journal.user.id == me.id;
    //
    //     var meAsFollower = findFollower(model.followings, model.journal.id);
    //     var followings = model.followings;
    //
    //     controller.set('canAddMoments', isMyJournal || (meAsFollower && meAsFollower.addEntries));
    //     controller.set('canViewMilestones', isMyJournal || (meAsFollower && meAsFollower.viewMilestones));
    //     controller.set('canEditMilestones', isMyJournal || (meAsFollower && meAsFollower.editMilestones));
    //     controller.set('canViewEntries', isMyJournal || (meAsFollower && meAsFollower.viewEntries));
    //
    //     function findFollower(followings, journalId) {
    //       var follower = null;
    //       if (followings && me) {
    //         Ember.$.each(followings, function (idx, following) {
    //           if (following.journal.id == journalId) {
    //             follower = following;
    //           }
    //         });
    //       }
    //       return follower;
    //     }
    //     var myFollowing = null;
    //     Ember.$.each(followers, function (idx, follower) {
    //       if (follower.user.id == me.id) {
    //         myFollowing = follower;
    //       }
    //     });
    //     controller.set('canShare', model.journal.user.id == me.id || (myFollowing && myFollowing.coOwner));
    //     var img = new Image();
    //     img.src = 'images/singleupload.png';
    //
    //     // These two lines merely load up the images into cache
    //     if ( !this.globals.isMobile() ) {
    //       var img2 = new Image();
    //       img2.src = 'images/multipleupload.gif';
    //     }
    //     else {
    //       var img2 = new Image();
    //       img2.src = 'images/mobileupload.gif';
    //     }
    //
    // },
    //
    // setupController: function (controller, model) {
    //   var self = this;
    //   self._super(controller, model);
    //   self.doControllerSetup(controller, model);
    //
    //   controller.set("setup",model.setup);
    //
    //   // Set the application controller to wire up the callbacks for adding entries in the background
    //   controller.get('controllers.main').set('entryUploadCompletedCallback', function(entry) {
    //     self.refresh();
    //   });
    // },
    //
    // makeCellsSquare: function() {
    //   var $table = Ember.$('table.square-cells');
    //   var size = $table.find('td').width();
    //   $table.find('td').height(size);
    // },
    //
    // renderTemplate: function (controller, model) {
    //   var self = this;
    //   this.render();
    // },
    actions: {
      gotoPremiumPage: function gotoPremiumPage() {
        this.transitionTo('main.subscription-products.list', {
          queryParams: {
            journalid: this.get('model.journalId'),
            overviewOnly: false
          }
        });
      }
    }

  });
});