define('tinybeans-frontend/serializers/entry', ['exports', 'ember-data/serializers/rest', 'ember-data/serializers/embedded-records-mixin', 'lodash/lodash'], function (exports, _rest, _embeddedRecordsMixin, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _rest.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      blobs: { embedded: 'always' },
      comments: { embedded: 'always' },
      emotions: { embedded: 'always' },
      children: { embedded: 'always' },
      pets: { embedded: 'always' },
      journalId: { embedded: 'always' },
      checklistItem: { embedded: 'always' }
    },

    serialize: function serialize(snapshot) {
      var children = _lodash.default.map(snapshot.hasMany('children'), function (child) {
        return parseInt(child.id);
      });
      var pets = _lodash.default.map(snapshot.hasMany('pets'), function (pet) {
        return parseInt(pet.id);
      });
      return {
        day: snapshot.attr('day'),
        month: snapshot.attr('month'),
        year: snapshot.attr('year'),
        caption: snapshot.attr('caption'),
        privateMode: snapshot.attr('privateMode'),
        clientRef: snapshot.attr('clientRef'),
        type: snapshot.attr('type'),
        children: children,
        pets: pets
      };
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      delete payload.status; // Status messes up the JSON parser

      if (!payload.entries) {
        payload.entries = []; // Meant to come back as an array always
      }

      if (payload.entry) {
        delete payload.entry.userId;
        if (payload.entry.journalId && payload.entry.journal) {
          payload.entry.journalId = payload.entry.journal;
        }
        payload.entries[0] = payload.entry;
        delete payload.entry;
      }

      _lodash.default.each(payload.entries, function (entry) {
        if (entry.journalId && entry.journal) {
          entry.journalId = entry.journal;
        }
        entry.children = _lodash.default.map(entry.children, function (child) {
          return { id: child.id || child.childId };
        });
        entry.pets = _lodash.default.map(entry.pets, function (pet) {
          return { id: pet.id || pet.petId };
        });
      });

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});