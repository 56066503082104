define('tinybeans-frontend/tracker/tb', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var TB = {
    trackEvent: function trackEvent(event) {
      console.log('TB tracking ' + event);
    }
  };

  exports.default = TB;
});