define("tinybeans-frontend/templates/main/pets/milestones/checklist", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.7.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 56,
                        "column": 30
                      },
                      "end": {
                        "line": 59,
                        "column": 30
                      }
                    },
                    "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "src", "images/milestones/green-tick-small.png");
                    dom.setAttribute(el1, "class", "completed cursor-pointer");
                    dom.setAttribute(el1, "width", "32");
                    dom.setAttribute(el1, "height", "32");
                    dom.setAttribute(el1, "style", "margin:0 auto;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "src", "images/milestones/grey-tick-small.png");
                    dom.setAttribute(el1, "class", "notCompleted cursor-pointer");
                    dom.setAttribute(el1, "width", "32");
                    dom.setAttribute(el1, "height", "32");
                    dom.setAttribute(el1, "style", "margin:0 auto;display:none;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.7.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 59,
                        "column": 30
                      },
                      "end": {
                        "line": 62,
                        "column": 30
                      }
                    },
                    "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "src", "images/milestones/green-tick-small.png");
                    dom.setAttribute(el1, "class", "completed cursor-pointer");
                    dom.setAttribute(el1, "width", "32");
                    dom.setAttribute(el1, "height", "32");
                    dom.setAttribute(el1, "style", "margin:0 auto;display:none;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "src", "images/milestones/grey-tick-small.png");
                    dom.setAttribute(el1, "class", "notCompleted cursor-pointer");
                    dom.setAttribute(el1, "width", "32");
                    dom.setAttribute(el1, "height", "32");
                    dom.setAttribute(el1, "style", "margin:0 auto;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 51,
                      "column": 20
                    },
                    "end": {
                      "line": 73,
                      "column": 20
                    }
                  },
                  "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "milestone-item-block cursor-pointer");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-sm-offset-1 col-sm-10 milestone-item");
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "tile-checkbox col-sm-1 col-xs-1");
                  var el4 = dom.createTextNode("\n                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "fancyCheckbox unselectable");
                  var el5 = dom.createTextNode("\n");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("                            ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                          ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "col-sm-10 col-xs-10");
                  var el4 = dom.createTextNode("\n                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("p");
                  dom.setAttribute(el4, "class", "checklistItemTitle");
                  var el5 = dom.createTextNode("\n                              ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                              ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("br");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("small");
                  dom.setAttribute(el5, "class", "milestone-completion-date");
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                            ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                          ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var element4 = dom.childAt(element3, [1]);
                  var element5 = dom.childAt(element4, [3, 1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createAttrMorph(element3, 'data-milestone-item');
                  morphs[1] = dom.createElementMorph(element3);
                  morphs[2] = dom.createMorphAt(dom.childAt(element4, [1, 1]), 1, 1);
                  morphs[3] = dom.createMorphAt(element5, 1, 1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element5, [4]), 0, 0);
                  return morphs;
                },
                statements: [["attribute", "data-milestone-item", ["get", "item.id", ["loc", [null, [52, 94], [52, 101]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["tickMilestone", ["get", "item", ["loc", [null, [52, 129], [52, 133]]], 0, 0, 0, 0], ["get", "group", ["loc", [null, [52, 134], [52, 139]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 104], [52, 141]]], 0, 0], ["block", "if", [["get", "item.completed", ["loc", [null, [56, 36], [56, 50]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [56, 30], [62, 37]]]], ["content", "item.title", ["loc", [null, [67, 30], [67, 44]]], 0, 0, 0, 0], ["content", "item.completionDate", ["loc", [null, [68, 76], [68, 99]]], 0, 0, 0, 0]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            var child1 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.7.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 80,
                        "column": 30
                      },
                      "end": {
                        "line": 83,
                        "column": 30
                      }
                    },
                    "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "src", "images/milestones/green-tick-small.png");
                    dom.setAttribute(el1, "class", "completed");
                    dom.setAttribute(el1, "width", "32");
                    dom.setAttribute(el1, "height", "32");
                    dom.setAttribute(el1, "style", "margin:0 auto;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "src", "images/milestones/grey-tick-small.png");
                    dom.setAttribute(el1, "class", "notCompleted");
                    dom.setAttribute(el1, "width", "32");
                    dom.setAttribute(el1, "height", "32");
                    dom.setAttribute(el1, "style", "margin:0 auto;display:none;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.7.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 83,
                        "column": 30
                      },
                      "end": {
                        "line": 86,
                        "column": 30
                      }
                    },
                    "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "src", "images/milestones/green-tick-small.png");
                    dom.setAttribute(el1, "class", "completed");
                    dom.setAttribute(el1, "width", "32");
                    dom.setAttribute(el1, "height", "32");
                    dom.setAttribute(el1, "style", "margin:0 auto;display:none;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "src", "images/milestones/grey-tick-small.png");
                    dom.setAttribute(el1, "class", "notCompleted");
                    dom.setAttribute(el1, "width", "32");
                    dom.setAttribute(el1, "height", "32");
                    dom.setAttribute(el1, "style", "margin:0 auto;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              var child2 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.7.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 93,
                        "column": 30
                      },
                      "end": {
                        "line": 95,
                        "column": 30
                      }
                    },
                    "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("br");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("small");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "item.completionDate", ["loc", [null, [94, 44], [94, 67]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 73,
                      "column": 20
                    },
                    "end": {
                      "line": 100,
                      "column": 20
                    }
                  },
                  "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "milestone-item-block");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-sm-offset-1 col-sm-10 milestone-item");
                  var el3 = dom.createTextNode("\n\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "tile-checkbox col-sm-1 col-xs-1");
                  var el4 = dom.createTextNode("\n                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "fancyCheckbox unselectable");
                  var el5 = dom.createTextNode("\n\n");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("                            ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                          ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "col-sm-10 col-xs-10");
                  var el4 = dom.createTextNode("\n                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("p");
                  dom.setAttribute(el4, "class", "checklistItemTitle");
                  var el5 = dom.createTextNode("\n                              ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("                            ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                          ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var element1 = dom.childAt(element0, [1]);
                  var element2 = dom.childAt(element1, [3, 1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element0, 'data-milestone-item');
                  morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1]), 1, 1);
                  morphs[2] = dom.createMorphAt(element2, 1, 1);
                  morphs[3] = dom.createMorphAt(element2, 3, 3);
                  return morphs;
                },
                statements: [["attribute", "data-milestone-item", ["get", "item.id", ["loc", [null, [74, 79], [74, 86]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "item.completed", ["loc", [null, [80, 36], [80, 50]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [80, 30], [86, 37]]]], ["content", "item.title", ["loc", [null, [92, 30], [92, 44]]], 0, 0, 0, 0], ["block", "if", [["get", "item.completed", ["loc", [null, [93, 36], [93, 50]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [93, 30], [95, 37]]]]],
                locals: [],
                templates: [child0, child1, child2]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 16
                  },
                  "end": {
                    "line": 102,
                    "column": 16
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element6, 'class');
                morphs[1] = dom.createMorphAt(element6, 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["get", "item.ageRangeClass", ["loc", [null, [49, 32], [49, 50]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "canEditMilestones", ["loc", [null, [51, 26], [51, 43]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [51, 20], [100, 27]]]]],
              locals: ["item"],
              templates: [child0, child1]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 14
                },
                "end": {
                  "line": 104,
                  "column": 15
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "ageRangeAndCompletionBar");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-1 col-sm-1");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-4 col-sm-2");
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-6 col-sm-6 text-left");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "progress");
              var el4 = dom.createTextNode("\n                          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "progress-bar");
              dom.setAttribute(el4, "role", "progressbar");
              dom.setAttribute(el4, "aria-valuenow", "60");
              dom.setAttribute(el4, "aria-valuemin", "0");
              dom.setAttribute(el4, "aria-valuemax", "100");
              var el5 = dom.createTextNode("\n                            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("%");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("          \n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var element8 = dom.childAt(element7, [5, 1, 1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element7, [3, 0]), 0, 0);
              morphs[1] = dom.createAttrMorph(element8, 'style');
              morphs[2] = dom.createMorphAt(element8, 1, 1);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["content", "group.ageRange", ["loc", [null, [40, 59], [40, 77]]], 0, 0, 0, 0], ["attribute", "style", ["subexpr", "html-safe", [["subexpr", "main/children/milestones/prog-bar-width", [["get", "group.percentage", ["loc", [null, [43, 112], [43, 128]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 71], [43, 129]]], 0, 0]], [], ["loc", [null, [null, null], [43, 131]]], 0, 0], 0, 0, 0, 0], ["content", "group.percentage", ["loc", [null, [44, 28], [44, 48]]], 0, 0, 0, 0], ["block", "each", [["get", "group.items", ["loc", [null, [48, 24], [48, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [48, 16], [102, 25]]]]],
            locals: ["group"],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 109,
                "column": 4
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "milestone-list");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "milestone-list-inner");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "text-center");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "spin");
            dom.setAttribute(el4, "style", "display:none; margin:0px auto; margin-top:10px; margin-bottom:20px;");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "container");
            var el4 = dom.createTextNode("\n\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "milestone-item-div");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3, 2]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "currentChecklistItems", ["loc", [null, [37, 22], [37, 43]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [37, 14], [104, 24]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 109,
                "column": 4
              },
              "end": {
                "line": 113,
                "column": 4
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "text-center");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("No milestones available");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 115,
              "column": 2
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.petMilestones", ["loc", [null, [20, 10], [20, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [20, 4], [113, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 115,
              "column": 2
            },
            "end": {
              "line": 119,
              "column": 2
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "text-center");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("No milestones available");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 120,
              "column": 0
            },
            "end": {
              "line": 122,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "main/pets/milestones/date-selection-dialog", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [121, 53], [121, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "currentlySelectedMilestone", ["subexpr", "@mut", [["get", "currentlySelectedMilestone", ["loc", [null, [121, 86], [121, 112]]], 0, 0, 0, 0]], [], [], 0, 0], "currentMilestoneCompleted", ["subexpr", "@mut", [["get", "currentMilestoneCompleted", ["loc", [null, [121, 139], [121, 164]]], 0, 0, 0, 0]], [], [], 0, 0], "closeModalDialog", "closeModalDialog", "saveMilestone", "saveMilestone", "usersDateFormat", ["subexpr", "@mut", [["get", "usersDateFormat", ["loc", [null, [121, 248], [121, 263]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [121, 2], [121, 265]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 123,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/pets/milestones/checklist.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n  #footer {\n    margin-top: 0px!important;\n    background: #fff!important;\n  }\n\n  .content-container {\n    padding-bottom: none!important;\n  }\n\n  .childShow {\n    background: #fff!important;\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "main/pets/milestones/pet-summary", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [16, 41], [16, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "milestoneTabClass", "active"], ["loc", [null, [16, 0], [16, 75]]], 0, 0], ["block", "if", [["get", "isOtherPet", ["loc", [null, [19, 8], [19, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [19, 2], [119, 9]]]], ["block", "if", [["get", "displayModal", ["loc", [null, [120, 6], [120, 18]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [120, 0], [122, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});