define('tinybeans-frontend/routes/main/children/milestones/weight', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Child.category,
    screen: _tracker.Child.weight.screen,

    model: function model(params) {
      var childId = params.child_id;
      var journalId = params.journal_id;

      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.modelFor('main').me,
        child: this.store.find('child', childId),
        weights: this.store.query('weight', {
          childId: childId
        }),
        followers: this.store.query('follower', {
          filter: {
            journalId: journalId
          }
        }),
        followings: this.modelFor('main').followings,
        journal: this.store.find('journal', journalId)
      });
    },

    renderTemplate: function renderTemplate() {
      this.render('main/children/milestones/weight', {
        controller: 'main.children.milestones.weight'
      });
    },

    actions: {
      reloadPage: function reloadPage() {
        this.refresh();
      }
    }

  });
});