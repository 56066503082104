define('tinybeans-frontend/routes/main/invalid-invite-token', ['exports', 'tinybeans-frontend/tracker'], function (exports, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    category: _tracker.Followers.category,
    screen: _tracker.Followers.invalidInvite.screen
  });
});