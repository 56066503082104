define("tinybeans-frontend/mixins/gift-guide-mixin", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isBetween: function isBetween(start, end) {
      var startTime = new Date(start).getTime();
      var endTime = new Date(end).getTime();
      var now = new Date().getTime();

      return startTime < now && now < endTime;
    },
    isSpringGuideActive: Ember.computed(function () {
      return this.isBetween("Mon Mar 10 2019 10:00:00 GMT-0400 (EDT)", "Tue Apr 30 2019 23:59:00 GMT-0400 (EDT)");
    }),
    isMothersDayActive: Ember.computed(function () {
      return this.isBetween("Sun Apr 29 2017 12:00:00 GMT-0400 (EDT)", "Mon May 21 2018 23:59:00 GMT-0400 (EDT)");
    }),

    isFathersDayActive: Ember.computed(function () {
      return this.isBetween("Fri Jun 01 2018 12:00:00 GMT-0400 (EDT)", "Mon June 25 2018 23:59:00 GMT-0400 (EDT)");
    }),

    isHolidaySeasonActive: Ember.computed(function () {
      return this.isBetween("Mon Nov 19 2018 10:00:00 GMT-0500 (Eastern Standard Time)", "Mon Jan 7 2019 00:00:00 GMT-0500 (Eastern Standard Time)");
    })
  });
});