define('tinybeans-frontend/routes/main/pets/milestones/checklist', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Pet.category,
    screen: _tracker.Pet.screen,

    model: function model(params) {
      var petId = params.pets_id;
      var journalId = params.journal_id;

      return Ember.RSVP.hash({
        me: this.store.find('user', 'me'),
        journalId: journalId,
        journal: this.store.find('journal', journalId),
        pet: this.store.peekRecord('pet', petId),
        petCompletedChecklistItems: this.API.completedChecklistItems(petId),
        getPetSubscribedChannels: this.API.getPetSubscribedChannels(petId)
      });
    },
    afterModel: function afterModel(model) {
      model.petMilestoneCount = model.petCompletedChecklistItems ? model.petCompletedChecklistItems.length : 0;
      model.petType = model.getPetSubscribedChannels[0].channel.title ? model.getPetSubscribedChannels[0].channel.title : "";
      model.relatedArticles = model.getPetSubscribedChannels[0].channel.relatedArticles ? model.getPetSubscribedChannels[0].channel.relatedArticles : [];
      if (model.getPetSubscribedChannels[0].channel.checklists.length > 0) {
        model.petMilestones = model.getPetSubscribedChannels[0].channel.checklists[0].checklistItems;
      } else {
        model.petMilestones = [];
      }

      var dob = moment(model.pet.data.adoptionDate, 'YYYY-MM-DD');
      var now = moment();
      model.timeSinceAdopt = now > dob ? moment.preciseDiff(dob, now, false, 'DAY') : 'Add adoption day';
    },


    actions: {
      reloadPage: function reloadPage() {
        this.refresh();
      }
    }

  });
});