define('tinybeans-frontend/components/edit-child-info-onboarding', ['exports', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_commonsMixin.default, {
    submitting: false,
    queryParams: ['childAdded'],

    title: Ember.computed('child.firstName', 'child.lastName', function () {
      var child = this.get('model.child');
      return this.get('editing') ? child.get('firstName') + " " + child.get('lastName') : this.t('Children.Add_Child_Prompt');
    }),

    formattedDOB: Ember.computed('model.child.dob', {
      get: function get() {
        return moment(this.get('model.child.dob'), 'YYYY-MM-DD').format(this.get('usersDateFormat'));
      },
      set: function set(key, value) {
        var formatted = moment(value, this.get('usersDateFormat')).format('YYYY-MM-DD');
        this.set('model.child.dob', formatted);
        return value;
      }
    }),

    saveButtonLabel: Ember.computed('submitting', function () {
      var submitting = this.get('submitting');
      return submitting ? this.t('global.label.wait') : this.t('global.label.save');
    }),

    genders: Ember.computed('model.genders', function () {
      var _this = this;

      var genders = this.get('model.genders');
      genders.forEach(function (g) {
        g.label = _this.get('i18n').t('Children.' + g.label);
      });
      return genders;
    }),

    actions: {
      selectGender: function selectGender(value) {
        this.get('model.child').set('gender', value);
      },
      selectRelationship: function selectRelationship(value) {
        this.set('model.userRelationshipToChild', value);
      },
      deleteConfirm: function deleteConfirm() {
        var self = this;
        bootbox.dialog({
          title: this.t('Children.Delete_Confirm_Dialog.Remove_Child'),
          message: this.t('Children.Delete_Confirm_Dialog.Remove_Child_Text'),
          buttons: {
            ok: {
              label: 'OK',
              callback: function callback() {
                self.get('model.child').destroyRecord().then(function () {
                  self.sendAction('doTransition', 'main.children.list', self.get('model.journal.id'));
                });
              }
            },
            cancel: {
              label: this.t('Children.Delete_Confirm_Dialog.Cancel'),
              className: "btn-cancel"
            }
          }
        });
      },
      cancel: function cancel() {
        var journalId = this.get('model.journal.id');
        this.sendAction('doTransition', 'main.onboarding.step-two', journalId);

        this.GTM.trackEvent({
          'eventCategory': 'onboarding-child-first',
          'eventAction': 'step-one-click',
          'eventLabel': 'skip-add-child'
        });
        if (this.onSkip) {
          this.onSkip();
        }
      },
      save: function save(addMore) {
        var _this2 = this;

        this.GTM.trackEvent({
          'eventCategory': 'onboarding-child-first',
          'eventAction': 'step-one-click',
          'eventLabel': 'add-child'
        });

        this.set('submitting', true);
        var file = Ember.$('input[type=file]')[0].files[0];
        var journalId = this.get('model.journal.id');
        var error = void 0;
        var errorMessage = 'Sorry, this is required';
        if (!this.get('model.child.firstName')) {
          error = {};
          error.firstName = errorMessage;
        }
        if (!this.get('model.child.lastName')) {
          error = error || {};
          error.lastName = errorMessage;
        }
        if (!this.get('model.child.gender')) {
          error = error || {};
          error.gender = errorMessage;
        }
        if (!this.get('model.child.dob')) {
          error = error || {};
          error.dob = errorMessage;
        }
        if (!this.get('hasChildren') && !this.get('model.userRelationshipToChild')) {
          error = error || {};
          error.relationship = errorMessage;
        }

        if (error) {
          this.set('error', error);
          this.set('submitting', false);
          return;
        }

        var d = new Date();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();

        this.get('model.child').save({ adapterOptions: { file: file, journalId: journalId, userRelationshipToChild: this.get('model.userRelationshipToChild') } }).then(function (response) {
          if (!_this2.get('editing')) {
            _this2.get('model.journal.children').pushObject(response);
          }
          //this.sendAction('displayNotification', `Child <b>${response.get('fullName')}</b> was added successfully!`);
          if (_this2.get('setup')) {
            if (addMore) {
              _this2.sendAction('reloadPage');
            } else {
              _this2.sendAction('doTransition', 'main.journals.month', journalId, year, month, { queryParams: { setup: _this2.get('setup') || false } });
            }
          } else {
            _this2.sendAction('doTransition', 'main.onboarding.step-two', journalId);
          }
          _this2.set('submitting', false);
          if (_this2.onSave) {
            _this2.onSave();
          }
        }, function () {
          // this.onApiError(errors);
          _this2.set('submitting', false);
        });
      }
    }

  });
});