define('tinybeans-frontend/models/blobs', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    t: (0, _attr.default)('string'),
    s: (0, _attr.default)('string'),
    s2: (0, _attr.default)('string'),
    m: (0, _attr.default)('string'),
    l: (0, _attr.default)('string'),
    p: (0, _attr.default)('string'),
    o: (0, _attr.default)('string'),
    o2: (0, _attr.default)('string'),

    safeLargeStyle: Ember.computed('l', function () {
      return Ember.String.htmlSafe("background-image:url('" + this.get('l') + "'); background-size: contain; background-position: center; background-repeat: no-repeat; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px;");
    })
  });
});