define('tinybeans-frontend/models/following', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    URL: (0, _attr.default)('string'),
    viewEntries: (0, _attr.default)('boolean'),
    addEntries: (0, _attr.default)('boolean'),
    viewMilestones: (0, _attr.default)('boolean'),
    editMilestones: (0, _attr.default)('boolean'),
    coOwner: (0, _attr.default)('boolean'),
    sortOrder: (0, _attr.default)('number'),
    relationship: (0, _relationships.belongsTo)('nameLabel', { async: false }),
    emailFrequencyOnNewEvent: (0, _attr.default)(),
    timestamp: (0, _attr.default)('number'),
    journalId: (0, _relationships.belongsTo)('journal'),
    journal: (0, _relationships.belongsTo)('journal'), // used if we get followings (followings is the first thing loaded so it's good to use the embedded journal objects here)
    user: (0, _relationships.belongsTo)('user', { async: false }),
    emailWeeklySummary: (0, _attr.default)('boolean'),
    deleted: (0, _attr.default)('boolean'),
    emailOptOut: (0, _attr.default)('boolean'),
    emailMarketingOptOut: (0, _attr.default)('boolean'),
    facebookId: (0, _attr.default)('string'),
    instagramId: (0, _attr.default)('string'),
    instagramUsername: (0, _attr.default)('string'),
    referralCode: (0, _attr.default)('string'),
    sendFlashback: (0, _attr.default)('boolean')
  });
});