define('tinybeans-frontend/serializers/checklist-item', ['exports', 'tinybeans-frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      relatedArticles: { embedded: 'always' },
      checklist: { embedded: 'always' }
      // checklistId: {embedded: 'always'},
    }
  });
});