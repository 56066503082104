define("tinybeans-frontend/templates/components/main/photobook-popup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 4
            },
            "end": {
              "line": 71,
              "column": 4
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/main/photobook-popup.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "photobook-popup-inner");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "images/photobooks/photobook-deco-1.png");
          dom.setAttribute(el2, "width", "100%");
          dom.setAttribute(el2, "height", "auto");
          dom.setAttribute(el2, "alt", "deco");
          dom.setAttribute(el2, "align", "left");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          var el3 = dom.createTextNode("FREE expedited photo book shipping to 🇺🇸");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("If you’ve left ordering a little late, Tinybeans has the perfect surprise gift for you. ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n            Between now and December 15th, get free expedited shipping within the United States when you add ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("b");
          var el4 = dom.createTextNode("2 or\n                more photos books");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" to the same order. ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("You just pay the standard shipping rate to receive your photo books by December 24th (and Premium Members get\n            totally free expedited shipping!)");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-primary btn-checkout");
          var el3 = dom.createTextNode("Check it out");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "images/photobooks/photobook-deco-2.png");
          dom.setAttribute(el2, "width", "100%");
          dom.setAttribute(el2, "height", "auto");
          dom.setAttribute(el2, "alt", "deco");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 11]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["checkoutPhotobook"], [], ["loc", [null, [67, 53], [68, 33]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 72,
            "column": 6
          }
        },
        "moduleName": "tinybeans-frontend/templates/components/main/photobook-popup.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n    .photobooks-popup-container {\n        background: #ffe2df;\n    }\n\n    .photobooks-popup-container .modal-body {\n        text-align: center;\n        background: #ffe2df;\n        padding: 10px 30px;\n    }\n\n    @media screen and (max-width: 768px) {\n        .photobooks-popup-container .modal-body .photobook-popup-inner {\n            padding: 10px;\n        }\n    }\n\n    .photobooks-popup-container .modal-body .photobook-popup-inner {\n        padding: 10px 30px;\n    }\n\n    .photobooks-popup-container .modal-body h2 {\n        font-weight: bold;\n        font-size: 22pt;\n        text-align: left;\n    }\n\n    .photobooks-popup-container .modal-body p {\n        padding: 15px 0px 0px 0px;\n        font-size: 13pt;\n        text-align: left;\n    }\n\n    .photobooks-popup-container .modal-header {\n        background: #ffe2df;\n        border: none !important;\n    }\n\n    .photobooks-popup-container .close-photobook-popup {\n        background: none;\n        border: none;\n    }\n\n    .photobooks-popup-container .modal-header .close {\n        margin-top: -2px;\n        opacity: 0.3;\n        color: var(--color-primary);\n    }\n\n    .photobooks-popup-container .btn-checkout {\n        margin-top: 20px;\n    }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "photobooks-popup-container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["block", "modal-dialog", [], ["title", "", "saveButton", false, "closeButton", false, "close", "close"], 0, null, ["loc", [null, [55, 4], [71, 21]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});