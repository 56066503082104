define('tinybeans-frontend/controllers/main/journals/add', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _baseController, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    isActiveNavJournals: function isActiveNavJournals() {
      return true;
    },

    journal: null,
    coverThemes: null,
    ownsThisJournal: true,

    isFollower: Ember.computed(function () {
      return true;
    }),

    title: function () {
      var journal = this.get('model.journal');
      var title = journal.title ? journal.title : this.t("Journal.Title");
      return title;
    }.property('journal.title'),

    submitting: false,

    saveButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? 'Please wait ...' : 'Save Changes';
    }.property('submitting'),

    actions: {
      save: function save() {
        var _this = this;

        var journal = this.get('model.journal');
        this.set('submitting', true);
        journal.save().then(function () {
          _this.set('submitting', false);
          _this.send('reloadApplicationRoute');
          // this.get('model.journals').pushObject(result);
          _this.transitionToRoute('main.journals.list');
        });
      }
    }
  });
});