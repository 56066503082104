define('tinybeans-frontend/routes/base-protected-route', ['exports', 'tinybeans-frontend/routes/base-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'tinybeans-frontend/helpers/storage'], function (exports, _baseRoute, _authenticatedRouteMixin, _storage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var lastViewedJournalStorage = new _storage.Storage('last-viewed-journal');

  exports.default = _baseRoute.default.extend(_authenticatedRouteMixin.default, {

    setupController: function setupController(controller, model) {
      var self = this;
      self._super(controller, model);
      if (model && model.journal && this.controllerFor('main')) this.controllerFor('main').set('model.currentJournal', model.journal);

      // Only set currentExistingJournal when the journal has a valid id
      if (model && model.journal && model.journal.id) {
        if (this.controllerFor('main')) {
          this.controllerFor('main').set('model.currentExistingJournal', model.journal);
        }

        var userId = this.get('session.data.authenticated.user.id');

        if (userId) {
          lastViewedJournalStorage.byUser(userId).set(model.journal.id);
        }
      }

      controller.set('error', {});
    }
  });
});