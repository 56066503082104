define("tinybeans-frontend/templates/components/mobile-sidebar", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 2
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          dom.setAttribute(el1, "class", "divider");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "class", "navbar-item navbar-refer-a-friend");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "/guides/mothers-day/");
          dom.setAttribute(el2, "target", "_blank");
          dom.setAttribute(el2, "style", "font-weight:500;");
          dom.setAttribute(el2, "class", "color-sydney-sunrise");
          var el3 = dom.createTextNode("Shop Mother's Day Gift Guide");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 2
            },
            "end": {
              "line": 26,
              "column": 2
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          dom.setAttribute(el1, "class", "divider");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "class", "navbar-item navbar-refer-a-friend");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "/guides/fathers-day/");
          dom.setAttribute(el2, "target", "_blank");
          dom.setAttribute(el2, "style", "font-weight:500;");
          dom.setAttribute(el2, "class", "color-sydney-sunrise");
          var el3 = dom.createTextNode("Shop Father's Day Gift Guide");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 2
            },
            "end": {
              "line": 33,
              "column": 2
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          dom.setAttribute(el1, "class", "divider");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "class", "navbar-item navbar-refer-a-friend");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "/guides/holiday/");
          dom.setAttribute(el2, "target", "_blank");
          dom.setAttribute(el2, "style", "font-weight:500;");
          dom.setAttribute(el2, "class", "color-sydney-sunrise");
          var el3 = dom.createTextNode("Shop Holiday Gift Guide");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 2
            },
            "end": {
              "line": 46,
              "column": 2
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          dom.setAttribute(el1, "class", "divider");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "class", "navbar-item");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "/guides/spring/");
          dom.setAttribute(el2, "target", "_blank");
          dom.setAttribute(el2, "style", "font-weight:500;");
          dom.setAttribute(el2, "class", "color-sydney-sunrise");
          var el3 = dom.createTextNode("Shop Spring Essentials");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 2
            },
            "end": {
              "line": 55,
              "column": 2
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "class", "navbar-upgrade-to-premium");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "#");
          var el3 = dom.createTextNode("Beanstalk Features");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          dom.setAttribute(el1, "class", "divider");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element17);
          return morphs;
        },
        statements: [["element", "action", ["upgradeToPremium"], [], ["loc", [null, [52, 82], [52, 111]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 57,
              "column": 2
            },
            "end": {
              "line": 63,
              "column": 2
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "class", "navbar-photo-collections");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "#");
          var el3 = dom.createTextNode("\n      Photo albums");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          dom.setAttribute(el1, "class", "divider");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element16);
          return morphs;
        },
        statements: [["element", "action", ["transition", "collection-list", ["get", "currentJournal.id", ["loc", [null, [59, 121], [59, 138]]], 0, 0, 0, 0]], [], ["loc", [null, [59, 81], [59, 140]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child6 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 71,
                  "column": 4
                },
                "end": {
                  "line": 76,
                  "column": 4
                }
              },
              "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "role", "presentation");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "role", "menuitem");
              dom.setAttribute(el2, "class", "navbar-family-friends");
              dom.setAttribute(el2, "tabindex", "-1");
              dom.setAttribute(el2, "href", "#");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "role", "presentation");
              dom.setAttribute(el1, "class", "divider");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element8);
              morphs[1] = dom.createMorphAt(element8, 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["transition", "follower-list", ["get", "currentJournal.id", ["loc", [null, [73, 118], [73, 135]]], 0, 0, 0, 0]], [], ["loc", [null, [73, 80], [73, 137]]], 0, 0], ["inline", "t", ["Navigation.Followers"], [], ["loc", [null, [73, 138], [73, 166]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 4
              },
              "end": {
                "line": 82,
                "column": 4
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "role", "menuitem");
            dom.setAttribute(el2, "class", "navbar-children");
            dom.setAttribute(el2, "tabindex", "-1");
            dom.setAttribute(el2, "href", "#");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [2, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createElementMorph(element9);
            morphs[2] = dom.createMorphAt(element9, 1, 1);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "checkPremium", ["loc", [null, [71, 10], [71, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [71, 4], [76, 11]]]], ["element", "action", ["transition", "children", ["get", "currentJournal.id", ["loc", [null, [78, 107], [78, 124]]], 0, 0, 0, 0]], [], ["loc", [null, [78, 74], [78, 126]]], 0, 0], ["inline", "t", ["Navigation.Children"], [], ["loc", [null, [79, 8], [79, 35]]], 0, 0]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 103,
                "column": 4
              },
              "end": {
                "line": 108,
                "column": 4
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "role", "menuitem");
            dom.setAttribute(el2, "class", "navbar-start-journal");
            dom.setAttribute(el2, "tabindex", "-1");
            dom.setAttribute(el2, "href", "#");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element7);
            morphs[1] = dom.createMorphAt(element7, 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["transition", "add-journal"], [], ["loc", [null, [105, 79], [105, 116]]], 0, 0], ["inline", "t", ["Navigation.StartJournal"], [], ["loc", [null, [105, 117], [105, 148]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 2
            },
            "end": {
              "line": 114,
              "column": 2
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "class", "navbar-journal-details");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "#");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          dom.setAttribute(el1, "class", "divider");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "class", "navbar-photobook");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "#");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "class", "navbar-my-details");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "#");
          var el3 = dom.createTextNode("\n         ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3, "class", "avatar-small child");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          dom.setAttribute(el1, "class", "divider");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "class", "navbar-billing");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "#");
          var el3 = dom.createTextNode("Billing");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          dom.setAttribute(el1, "class", "divider");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "presentation");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "menuitem");
          dom.setAttribute(el2, "tabindex", "-1");
          dom.setAttribute(el2, "href", "#");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1, 1]);
          var element11 = dom.childAt(fragment, [7, 1]);
          var element12 = dom.childAt(fragment, [9, 1]);
          var element13 = dom.childAt(element12, [1]);
          var element14 = dom.childAt(fragment, [13, 1]);
          var element15 = dom.childAt(fragment, [19, 1]);
          var morphs = new Array(11);
          morphs[0] = dom.createElementMorph(element10);
          morphs[1] = dom.createMorphAt(element10, 0, 0);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[3] = dom.createElementMorph(element11);
          morphs[4] = dom.createElementMorph(element12);
          morphs[5] = dom.createAttrMorph(element13, 'src');
          morphs[6] = dom.createMorphAt(element12, 3, 3);
          morphs[7] = dom.createElementMorph(element14);
          morphs[8] = dom.createMorphAt(fragment, 17, 17, contextualElement);
          morphs[9] = dom.createElementMorph(element15);
          morphs[10] = dom.createMorphAt(element15, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["transition", "manage-journal"], [], ["loc", [null, [67, 79], [67, 119]]], 0, 0], ["inline", "t", ["Navigation.Journal_Details"], [], ["loc", [null, [67, 120], [67, 154]]], 0, 0], ["block", "if", [["get", "canManage", ["loc", [null, [70, 10], [70, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [70, 4], [82, 11]]]], ["element", "action", ["transition", "products"], [], ["loc", [null, [86, 77], [86, 111]]], 0, 0], ["element", "action", ["transition", "account"], [], ["loc", [null, [92, 76], [92, 109]]], 0, 0], ["attribute", "src", ["get", "me.avatars.m", ["loc", [null, [93, 47], [93, 59]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["Navigation.My_Details"], [], ["loc", [null, [93, 64], [93, 93]]], 0, 0], ["element", "action", ["transition", "billing"], [], ["loc", [null, [99, 71], [99, 104]]], 0, 0], ["block", "unless", [["get", "hasOwnJournal", ["loc", [null, [103, 14], [103, 27]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [103, 4], [108, 15]]]], ["element", "action", ["logout"], [], ["loc", [null, [111, 48], [111, 67]]], 0, 0], ["inline", "t", ["Navigation.Logout"], [], ["loc", [null, [111, 68], [111, 93]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child7 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 122,
                  "column": 6
                },
                "end": {
                  "line": 133,
                  "column": 6
                }
              },
              "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "role", "presentation");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "role", "menuitem");
              dom.setAttribute(el2, "class", "navbar-family-friends");
              dom.setAttribute(el2, "tabindex", "-1");
              dom.setAttribute(el2, "href", "#");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "role", "presentation");
              dom.setAttribute(el1, "class", "divider");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "role", "presentation");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "role", "menuitem");
              dom.setAttribute(el2, "class", "navbar-children");
              dom.setAttribute(el2, "tabindex", "-1");
              dom.setAttribute(el2, "href", "#");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "role", "presentation");
              dom.setAttribute(el1, "class", "divider");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1, 1]);
              var element2 = dom.childAt(fragment, [5, 1]);
              var morphs = new Array(4);
              morphs[0] = dom.createElementMorph(element1);
              morphs[1] = dom.createMorphAt(element1, 0, 0);
              morphs[2] = dom.createElementMorph(element2);
              morphs[3] = dom.createMorphAt(element2, 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["transition", "follower-list", ["get", "currentJournal.id", ["loc", [null, [124, 120], [124, 137]]], 0, 0, 0, 0]], [], ["loc", [null, [124, 82], [124, 139]]], 0, 0], ["inline", "t", ["Navigation.Followers"], [], ["loc", [null, [124, 140], [124, 168]]], 0, 0], ["element", "action", ["transition", "children", ["get", "currentJournal.id", ["loc", [null, [130, 109], [130, 126]]], 0, 0, 0, 0]], [], ["loc", [null, [130, 76], [130, 128]]], 0, 0], ["inline", "t", ["Navigation.Children"], [], ["loc", [null, [130, 129], [130, 156]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 160,
                  "column": 6
                },
                "end": {
                  "line": 165,
                  "column": 6
                }
              },
              "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "role", "presentation");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "role", "menuitem");
              dom.setAttribute(el2, "class", "navbar-start-journal");
              dom.setAttribute(el2, "tabindex", "-1");
              dom.setAttribute(el2, "href", "#");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "role", "presentation");
              dom.setAttribute(el1, "class", "divider");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(element0, 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["transition", "manage-journal"], [], ["loc", [null, [162, 81], [162, 121]]], 0, 0], ["inline", "t", ["Navigation.StartJournal"], [], ["loc", [null, [162, 122], [162, 153]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 116,
                "column": 4
              },
              "end": {
                "line": 170,
                "column": 4
              }
            },
            "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "role", "menuitem");
            dom.setAttribute(el2, "class", "navbar-journal-details");
            dom.setAttribute(el2, "tabindex", "-1");
            dom.setAttribute(el2, "href", "#");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n       ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "role", "menuitem");
            dom.setAttribute(el2, "class", "navbar-photobook");
            dom.setAttribute(el2, "tabindex", "-1");
            dom.setAttribute(el2, "href", "/photobooks");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.setAttribute(el1, "style", "border-top:1px solid #999;");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "role", "menuitem");
            dom.setAttribute(el2, "class", "navbar-my-details");
            dom.setAttribute(el2, "tabindex", "-1");
            dom.setAttribute(el2, "href", "#");
            var el3 = dom.createTextNode("\n          \n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("<li role=\"presentation\"><a role=\"menuitem\" tabindex=\"-1\" href=\"#\" {{action 'setupJournal'}}>{{t \"Navigation.StartJournal\"}}</a></li>");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("<li role=\"presentation\" class=\"divider\"></li>");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "role", "menuitem");
            dom.setAttribute(el2, "class", "navbar-billing");
            dom.setAttribute(el2, "tabindex", "-1");
            dom.setAttribute(el2, "href", "#");
            var el3 = dom.createTextNode("Billing");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "role", "menuitem");
            dom.setAttribute(el2, "tabindex", "-1");
            dom.setAttribute(el2, "href", "#");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1, 1]);
            var element4 = dom.childAt(fragment, [15, 1]);
            var element5 = dom.childAt(fragment, [23, 1]);
            var element6 = dom.childAt(fragment, [29, 1]);
            var morphs = new Array(10);
            morphs[0] = dom.createElementMorph(element3);
            morphs[1] = dom.createMorphAt(element3, 0, 0);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[3] = dom.createMorphAt(dom.childAt(fragment, [9, 1]), 0, 0);
            morphs[4] = dom.createElementMorph(element4);
            morphs[5] = dom.createMorphAt(element4, 1, 1);
            morphs[6] = dom.createElementMorph(element5);
            morphs[7] = dom.createMorphAt(fragment, 27, 27, contextualElement);
            morphs[8] = dom.createElementMorph(element6);
            morphs[9] = dom.createMorphAt(element6, 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["transition", "manage-journal"], [], ["loc", [null, [118, 81], [118, 121]]], 0, 0], ["inline", "t", ["Navigation.Journal_Details"], [], ["loc", [null, [118, 122], [118, 156]]], 0, 0], ["block", "if", [["get", "canManage", ["loc", [null, [122, 12], [122, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [122, 6], [133, 13]]]], ["inline", "t", ["Navigation.Store"], [], ["loc", [null, [138, 85], [138, 109]]], 0, 0], ["element", "action", ["transition", "account"], [], ["loc", [null, [145, 76], [145, 109]]], 0, 0], ["inline", "t", ["Navigation.My_Details"], [], ["loc", [null, [147, 10], [147, 39]]], 0, 0], ["element", "action", ["transition", "billing"], [], ["loc", [null, [156, 73], [156, 106]]], 0, 0], ["block", "unless", [["get", "hasOwnJournal", ["loc", [null, [160, 16], [160, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [160, 6], [165, 17]]]], ["element", "action", ["logout"], [], ["loc", [null, [168, 50], [168, 69]]], 0, 0], ["inline", "t", ["Navigation.Logout"], [], ["loc", [null, [168, 70], [168, 95]]], 0, 0]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 114,
              "column": 2
            },
            "end": {
              "line": 171,
              "column": 2
            }
          },
          "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "currentJournal", ["loc", [null, [116, 10], [116, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [116, 4], [170, 11]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 186,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/components/mobile-sidebar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("ul");
        dom.setAttribute(el1, "class", "sidebar-nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "role", "presentation");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "btn");
        dom.setAttribute(el3, "style", "background:none; color:#ccc; margin-top:10px; margin-left:0px; padding-left:0px; margin-bottom:10px; line-height:30px;");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "src", "/app/images/header-sidebar/Close.svg");
        dom.setAttribute(el4, "class", "");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("<span class=\"tinybeans-icon-close\" style=\"color:#6bc2bf; font-size:30px; line-height:30px;\"></span>");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "style", "font-size:14px;color:#ccc; line-height:30px;vertical-align:middle;");
        var el5 = dom.createTextNode("Close");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "style", "color:#999;");
        dom.setAttribute(el3, "class", "cursor-pointer");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "class", "avatar-small child");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("b");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "role", "presentation");
        dom.setAttribute(el2, "class", "divider");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "role", "presentation");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "role", "menuitem");
        dom.setAttribute(el3, "class", "navbar-search");
        dom.setAttribute(el3, "tabindex", "-1");
        dom.setAttribute(el3, "href", "#");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "glyphicon glyphicon-search");
        dom.setAttribute(el4, "style", "display:inline;");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" Search");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "role", "presentation");
        dom.setAttribute(el2, "class", "divider");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "role", "presentation");
        dom.setAttribute(el2, "class", "divider");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "role", "presentation");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("<a target=\"_blank\" href=\"https://tinybeans.app.link/pAHpgPiTUK\" class=\"appStore\"></a>");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "role", "menuitem");
        dom.setAttribute(el3, "tabindex", "-1");
        dom.setAttribute(el3, "href", "https://tinybeans.app.link/pAHpgPiTUK");
        dom.setAttribute(el3, "target", "_blank");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "class", "btn-apple");
        dom.setAttribute(el4, "src", "/app/images/footer/site-footer-svg/app-store.svg");
        dom.setAttribute(el4, "style", "display:inline-block; height:40px; margin-top: 13px");
        dom.setAttribute(el4, "alt", "download tinybeans for iPhone");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "role", "presentation");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("<a target=\"_blank\" href=\"https://tinybeans.app.link/H86sTypTUK\" class=\"googlePlay\"></a>");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "role", "menuitem");
        dom.setAttribute(el3, "tabindex", "-1");
        dom.setAttribute(el3, "href", "https://tinybeans.app.link/H86sTypTUK");
        dom.setAttribute(el3, "target", "_blank");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "class", "btn-google");
        dom.setAttribute(el4, "src", "/app/images/footer/site-footer-png/google-play.png");
        dom.setAttribute(el4, "alt", "download tinybeans for android");
        dom.setAttribute(el4, "style", "display:inline-block; height:40px;margin: 20px 0;");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element18 = dom.childAt(fragment, [0]);
        var element19 = dom.childAt(element18, [1]);
        var element20 = dom.childAt(element19, [1]);
        var element21 = dom.childAt(element19, [3]);
        var element22 = dom.childAt(element21, [0]);
        var element23 = dom.childAt(element18, [9, 1]);
        var morphs = new Array(13);
        morphs[0] = dom.createElementMorph(element20);
        morphs[1] = dom.createAttrMorph(element21, 'title');
        morphs[2] = dom.createElementMorph(element21);
        morphs[3] = dom.createAttrMorph(element22, 'src');
        morphs[4] = dom.createMorphAt(dom.childAt(element21, [2]), 0, 0);
        morphs[5] = dom.createMorphAt(element18, 5, 5);
        morphs[6] = dom.createMorphAt(element18, 6, 6);
        morphs[7] = dom.createMorphAt(element18, 7, 7);
        morphs[8] = dom.createElementMorph(element23);
        morphs[9] = dom.createMorphAt(element18, 13, 13);
        morphs[10] = dom.createMorphAt(element18, 17, 17);
        morphs[11] = dom.createMorphAt(element18, 19, 19);
        morphs[12] = dom.createMorphAt(element18, 21, 21);
        return morphs;
      },
      statements: [["element", "action", ["toggleSidebar"], [], ["loc", [null, [3, 12], [3, 38]]], 0, 0], ["attribute", "title", ["get", "currentJournal.longTitle", ["loc", [null, [9, 15], [9, 39]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["switchJournal", ["get", "currentJournal", ["loc", [null, [8, 75], [8, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 50], [8, 91]]], 0, 0], ["attribute", "src", ["get", "currentJournal.user.avatars.m", ["loc", [null, [9, 80], [9, 109]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "currentJournal.shortTitle", ["loc", [null, [9, 117], [9, 146]]], 0, 0, 0, 0], ["block", "if", [["get", "isMothersDayActive", ["loc", [null, [13, 8], [13, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 2], [19, 9]]]], ["block", "if", [["get", "isFathersDayActive", ["loc", [null, [20, 8], [20, 26]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [20, 2], [26, 9]]]], ["block", "if", [["get", "isHolidaySeasonActive", ["loc", [null, [27, 8], [27, 29]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [27, 2], [33, 9]]]], ["element", "action", ["transition", "search", ["get", "currentJournal.id", ["loc", [null, [35, 99], [35, 116]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 68], [35, 118]]], 0, 0], ["block", "if", [["get", "isSpringGuideActive", ["loc", [null, [40, 8], [40, 27]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [40, 2], [46, 9]]]], ["block", "unless", [["get", "isSubscribedUser", ["loc", [null, [50, 12], [50, 28]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [50, 2], [55, 13]]]], ["block", "if", [["get", "checkPremium", ["loc", [null, [57, 8], [57, 20]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [57, 2], [63, 9]]]], ["block", "if", [["subexpr", "eq", [["get", "journals.length", ["loc", [null, [65, 12], [65, 27]]], 0, 0, 0, 0], 1], [], ["loc", [null, [65, 8], [65, 30]]], 0, 0]], [], 6, 7, ["loc", [null, [65, 2], [171, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }());
});