define('tinybeans-frontend/routes/main/offers/ineligible', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = _baseProtectedRoute.default.extend({
    model: function model(params) {},
    afterModel: function afterModel() {},
    setupController: function setupController(controller, model) {}
  });
});