define('tinybeans-frontend/controllers/main/gift-card/redeem-gift-card-success', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _baseController, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {

    actions: {
      goToHome: function goToHome() {
        this.transitionToRoute("main.journals.home");
      }
    }
  });
});