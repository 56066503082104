define('tinybeans-frontend/routes/main/journals/edit', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash'], function (exports, _baseProtectedRoute, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model(params) {
      var journalId = params.journal_id;
      var journals = this.modelFor('main').journals;
      return Ember.RSVP.hash({
        user: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        journals: journals,
        followings: this.store.findAll('following'),
        coverThemes: this.API.listCoverThemes(),
        emailFrequencyTypes: this.API.listEmailFrequencyTypes(),
        followers: this.store.query('follower', { filter: { journalId: journalId } }),
        memoriesAccess: this.API.hasMemoriesAccess(journalId),
        isSubscribedUser: this.API.getActiveSubscription()
      });
    },

    afterModel: function afterModel(model) {
      model.following = _lodash.default.find(model.followings.toArray(), function (following) {
        return following.get('journal.id') === model.journal.id;
      });
      model.me = model.user;
    },


    setupController: function setupController(controller, model) {

      this._super(controller, model);
      var journal = model.journal;
      controller.set('currentJournalId', model.journal.id);
      journal.set('coverTheme', _lodash.default.find(model.coverThemes.toArray(), function (theme) {
        return theme.name == journal.get('coverTheme.name');
      }));
      if (model.isSubscribedUser.subscription || this.hasPremium(model.journals)) {
        controller.set('isSubscribedUser', true);
      } else {
        controller.set('isSubscribedUser', false);
      }

      //Digital Export
      var myFollowing = null;
      Ember.$.each(model.followers.toArray(), function (idx, follower) {
        if (follower.get('user.id') === model.me.id) {
          myFollowing = follower;
        }
      });

      controller.set('exportEnabled', this.globals.journalExportEnabled(model.journal));
      controller.set('canExport', model.journal.get('user.id') == model.user.id || myFollowing && myFollowing.get('coOwner'));
      controller.set('collectionEnabled', this.globals.photoCollectionEnabled(model.journal));
      controller.set('canCreateCollection', model.journal.get('user.id') == model.user.id || myFollowing && myFollowing.get('coOwner'));
    },
    hasPremium: function hasPremium(journals) {
      var _this = this;

      var journalStats = [];
      _lodash.default.each(journals.toArray(), function (journal) {
        journalStats.push(_this.globals.premiumTier(journal));
      });
      return journalStats.includes(true);
    }
  });
});