define('tinybeans-frontend/controllers/main/onboarding/step-start', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    queryParams: ['setup'],
    currentHometown: null,

    isActiveNavJournals: function isActiveNavJournals() {
      return true;
    },

    actions: {

      onboardingGotoNext: function onboardingGotoNext() {
        var _this = this;

        // Submit Hometown Field
        var data = this.get('currentHometown');
        if (data !== null) {
          // Homeown isnt required

          this.API.submitLocation(data, function (data, statusText, xhr) {
            _this.set('errorSave', {});
            _this.onApiSuccess(data, statusText, xhr);

            var journalId = _this.get('journalId');
            _this.transitionToRoute('main.onboarding.step-one', journalId);

            _this.GTM.trackEvent({
              'eventCategory': 'onboarding-user-location',
              'eventAction': 'step-start-user-location-button',
              'eventLabel': 'continue-button'
            });

            _tracker.Tracker.trackEvent({
              category: _tracker.Onboarding.category,
              screen: _tracker.Onboarding.stepStart.screen,
              action: _tracker.Onboarding.stepStart.saveButton
            });
          }, function (response) {
            _this.onApiError(response);
            var errorSave = {
              hometown: response.status !== 200 ? "Something went wrong saving hometown, try again later." : undefined
            };
            _this.set('errorSave', errorSave);
          });
        }
      },

      initAutocomplete: function initAutocomplete() {
        var _this2 = this;

        var hometownField = document.getElementById("hometown");
        var options = {
          strictBounds: false,
          types: ["geocode"]
        };
        var autocomplete = new google.maps.places.Autocomplete(hometownField, options);

        hometownField.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        google.maps.event.addListener(autocomplete, "place_changed", function () {
          var place = autocomplete.getPlace();
          var address = place.formatted_address;
          var value = address.split(",");
          var count = value.length;
          var country = void 0,
              state = void 0,
              city = void 0,
              z = void 0,
              postalCode = void 0;

          if (count === 3) {
            country = value[count - 1];
            state = value[count - 2];
            city = value[count - 3];
            z = state.split(" ");
            postalCode = z[2];
          } else {
            country = value[count - 1];
            city = value[count - 2];
          }

          var origin = 'USER_DEFINED';
          var latitude = place.geometry.location.lat();
          var longitude = place.geometry.location.lng();

          var currentLocation = {
            country: country,
            state: state,
            city: city,
            origin: origin,
            postalCode: postalCode,
            longitude: longitude,
            latitude: latitude
          };

          if (currentLocation) {
            if (!currentLocation.city) {
              _this2.set('location', currentLocation.state + ',' + currentLocation.country);
            } else if (!currentLocation.state) {
              _this2.set('location', currentLocation.city + ', ' + currentLocation.country);
            } else if (!currentLocation.country) {
              _this2.set('location', currentLocation.city + ',' + currentLocation.state + ',' + currentLocation.country);
            }
          }

          _this2.set('currentHometown', currentLocation);
        });
      },

      cancel: function cancel() {
        var journalId = this.get('journalId');

        _tracker.Tracker.trackEvent({
          category: _tracker.Onboarding.category,
          screen: _tracker.Onboarding.stepStart.screen,
          action: _tracker.Onboarding.stepStart.skipButton
        });

        this.transitionToRoute('main.onboarding.step-one', journalId);
      }
    }

  });
});