define("tinybeans-frontend/helpers/humanize", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanize = humanize;
  function humanize(params /*, hash*/) {
    var lower = params[0].toLowerCase();
    return lower[0].toUpperCase() + lower.substring(1).replace(/([a-z])(?=[A-Z])/g, "$1 ");
  }

  exports.default = Ember.Helper.helper(humanize);
});