define('tinybeans-frontend/controllers/i', ['exports', 'tinybeans-frontend/controllers/base-controller'], function (exports, _baseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    error: {},

    prefix: Ember.computed('model.inviteDetails', function () {
      var children = this.get('model.inviteDetails.children');
      return children && children.length ? '' : 'the';
    }),

    whatToFollow: Ember.computed('model.inviteDetails', function () {
      var children = this.get('model.inviteDetails.children');
      return children && children.length ? '' + children.join(', ').replace(/,(?=[^,]+$)/, ' and') : '' + this.get('model.inviteDetails.journalTitle');
    }),

    suffix: Ember.computed('model.inviteDetails', function () {
      var children = this.get('model.inviteDetails.children');
      return children && children.length ? '\'s moments and memories' : ' Tinybeans journal';
    }),
    actions: {
      signupAndAccept: function signupAndAccept() {
        var _this = this;

        var error = {};

        var _get$getProperties = this.get('model').getProperties('firstName', 'lastName', 'emailAddress'),
            firstName = _get$getProperties.firstName,
            lastName = _get$getProperties.lastName,
            emailAddress = _get$getProperties.emailAddress;

        error.firstName = firstName ? undefined : 'First Name is required';
        error.lastName = lastName ? undefined : 'Last Name is required';
        error.emailAddress = emailAddress && validateEmail(emailAddress) ? undefined : 'Please enter a valid email address';
        if (error.firstName || error.lastName || error.emailAddress) {
          this.set('error', error);
          return;
        }
        this.API.acceptInvite({
          token: this.get('model.token'),
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress
        }, function (data) {
          _this.GTM.trackEvent({
            'eventCategory': 'signup',
            'eventAction': 'invite_signup',
            'eventLabel': 'invite_landing_page'
          });
          _this.get('session').authenticate('authenticator:redirect', { accessToken: data.accessToken }).then(function () {
            _this.transitionToRoute('main.journals.view', data.follower.journalId);
          });
        }, function (errors) {
          _this.onApiError(errors);
          if (_this.get('error.emailAddress') == "User already exists") {
            _this.transitionToRoute('login', {
              queryParams: {
                inviteToken: _this.get('model.token'),
                journal: _this.get('model.inviteDetails.journalTitle'),
                redirectionError: true
              }
            });
          }
        });
      }
    }
  });
});