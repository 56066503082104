define('tinybeans-frontend/routes/main/offers/confirmation', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash', 'tinybeans-frontend/tracker', 'tinybeans-frontend/config/environment'], function (exports, _baseProtectedRoute, _lodash, _tracker, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var RSVP = Ember.RSVP;
  exports.default = _baseProtectedRoute.default.extend({
    journalId: null,
    category: _tracker.Coupons.category,
    screen: 'confirmation page',

    model: function model(params) {
      var _this = this;

      var checkoutId = localStorage.getItem('sessionId') || '';
      var priceId = localStorage.getItem('currentPriceId') || '';
      var purchased = false;

      // Offer specific params
      var campaign = params.utm_campaign || '';
      var partner_name = params.offer_partner || '';
      var exp = params.exp_date || '';
      var type = params.offer_type || "monthly";
      var show_free = params.show_free || false;
      var monthly_amount = params.monthly_offer || 0;
      var yearly_amount = params.yearly_offer || 0;
      var is_new_user = params.is_new_user || true;
      var description = params.description || "";
      var premiumSub = {};
      if (checkoutId.length && priceId.length) {
        premiumSub = new RSVP.Promise(function (resolve) {
          Ember.run.later(function () {
            _this.API.submitSPayment(checkoutId, priceId, function (data) {
              resolve(data.subscriptionData);
            });
          }, 0);
        });
        purchased = true;
      }

      return Ember.RSVP.hash({
        languages: navigator.languages,
        continuedFree: purchased ? false : true,
        premiumSubscription: premiumSub,
        campaign: {
          name: campaign,
          partner_name: partner_name,
          monthly_amount: monthly_amount,
          yearly_amount: yearly_amount,
          exp_date: exp,
          offer_type: type,
          show_free: show_free,
          isNew: is_new_user,
          offerDescription: description
        }
      });
    },
    afterModel: function afterModel(model) {
      var self = this;

      if (!model.continuedFree) {
        _tracker.Tracker.trackEvent({
          category: _tracker.Coupons.category,
          screen: 'confirmation page',
          action: 'confirmation page view',
          parameters: { 'CampaignName': model.campaign.name, 'OfferActionTaken': 'subscription purchased' }
        });
        // emit paid confirmation conversion event for CJ
        var uuid = 'undefined' !== typeof window.crypto.randomUUID ? window.crypto.randomUUID() : null;
        var cj_args = {
          'event': 'cj_purchase_confirmation',
          'eventCategory': 'confirmation_page',
          'eventAction': 'click_creditcard_done',
          'eventLabel': 'web',
          'orderSubtotal': model.premiumSubscription.plan.amount, // full price before discount
          'orderCurrency': model.premiumSubscription.paymentDetails.currency,
          'orderID': uuid
        };
        if (null !== model.premiumSubscription.checkoutSession.latestInvoice.discount) {
          cj_args.orderCoupon = model.premiumSubscription.checkoutSession.latestInvoice.discount.coupon.name;
          cj_args.wholeOrderDiscount = parseFloat(model.premiumSubscription.checkoutSession.latestInvoice.totalDiscountAmounts[0].amount / 100);
        };
        this.GTM.trackEvent(cj_args);
        if (_environment.default.enable_qa_output) {
          console.log("%cCJ Testing Output: Message sent with following data >>", "color: white; background-color: #fcb900;", cj_args);
        }
      } else {
        _tracker.Tracker.trackEvent({
          category: _tracker.Coupons.category,
          screen: 'confirmation page',
          action: 'confirmation page view',
          parameters: { 'CampaignName': model.campaign.name, 'OfferActionTaken': 'continue for free' }
        });
      }
    },
    setupController: function setupController(controller, model) {
      var language_code = 'en-US';
      controller.set('userLanguage', language_code);
      var default_prices = {
        monthly: '$7.99',
        yearly: '$74.99',
        monthlyStripePriceId: _environment.default.stripeMonthlyPrice,
        yearlyStripePriceId: _environment.default.stripeAnnualPrice
      };
      var prices = {};
      var lp_prices = {};
      controller.set('campaign', model.campaign);

      // Subscription LP Updated
      Leanplum.addVariablesChangedHandler(function () {
        var vars = Leanplum.getVariables();
        if (vars.Paywall) {
          var paywall_prices = '';
          if ('undefined' !== typeof vars.Paywall[language_code]) {
            paywall_prices = vars.Paywall[language_code];
          } else {
            paywall_prices = vars.Paywall['en-US']; // default or unhandled
          }

          // Includes currency
          lp_prices = {
            monthly: paywall_prices.monthlyPrice,
            yearly: paywall_prices.yearlyPrice,
            monthlyStripePriceId: paywall_prices.monthlyStripePriceId,
            yearlyStripePriceId: paywall_prices.yearlyStripePriceId
          };
          prices = _extends({}, default_prices, lp_prices);
          controller.set('monthlyPriceId', prices.monthlyStripePriceId);
          controller.set('annualPriceId', prices.yearlyStripePriceId);
          controller.set('prices', prices);
          console.log('LP loaded. Dynamic Paywall data used.');
        }
      });

      if (JSON.stringify(lp_prices) === '{}') {
        console.log('LP not loaded. Backup Paywall data used.');
      }

      prices = _extends({}, default_prices, lp_prices);
      controller.set('monthlyPriceId', prices.monthlyStripePriceId);
      controller.set('annualPriceId', prices.yearlyStripePriceId);

      controller.set('prices', prices);

      if (0 === parseInt(model.campaign.monthly_amount) && 0 === parseInt(model.campaign.yearly_amount)) {
        // no discount page
        console.log("No discount set.");
        controller.set('showDiscount', false);
      } else {
        controller.set('showDiscount', true);
      }
      if (0 !== model.campaign.monthly_amount && 'monthly' === model.campaign.offer_type) {
        controller.set('showMonthlyDiscount', true);
        controller.set('discountAmount', model.campaign.monthly_amount);
        console.log("show monthly discount.");
        // percent
        controller.set('discountedPrice', '$' + (parseFloat(prices.monthly.replace('$', '').replace('A', '')).toFixed(2) * ((100 - parseInt(model.campaign.monthly_amount)) / 100)).toFixed(2));
      }
      if (0 !== model.campaign.yearly_amount && 'annually' === model.campaign.offer_type) {
        console.log("show annual discount.");
        controller.set('showAnnualDiscount', true);
        controller.set('discountAmount', model.campaign.yearly_amount);
        // percent
        controller.set('discountedPrice', '$' + (parseFloat(prices.yearly.replace('$', '').replace('A', '')).toFixed(2) * ((100 - parseInt(model.campaign.yearly_amount)) / 100)).toFixed(2));
      }

      controller.set('expirationDate', moment.unix(model.campaign.exp_date).format("MMM. D, YYYY"));
      controller.set('offerTypeDisplay', '%');
      if ('monthly' === model.campaign.offer_type) {
        controller.set('showMonthly', true);
        controller.set('showAnnual', false);
        controller.set('planTypeText', 'monthly');
      } else if ('annually' === model.campaign.offer_type) {
        controller.set('showMonthly', false);
        controller.set('showAnnual', true);
        controller.set('planTypeText', 'yearly');
      } else {
        controller.set('showMonthly', true);
        controller.set('showAnnual', true);
      }
      controller.set('continuedFree', model.continuedFree);
      controller.set('campaign', model.campaign);

      if (!model.continuedFree) {
        controller.set("email", model.premiumSubscription.checkoutSession.customerDetails.email);
        if (model.premiumSubscription.plan.interval.includes('month')) {
          controller.set("planName", 'Monthly');
        } else {
          controller.set("planName", 'Yearly');
        }
        controller.set("startDate", moment.unix(model.premiumSubscription.startDate / 1000).format("MM/DD/YYYY"));
        controller.set("expiryDate", moment.unix(model.premiumSubscription.expiryDate / 1000).format("MM/DD/YYYY"));
        controller.set("isTrial", model.premiumSubscription.trialSubscription);
        controller.set("price", _lodash.default.round(model.premiumSubscription.finalPrice, 2));
      } else {
        controller.set('planName', 'Free');
      }
    }
  });
});