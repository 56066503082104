define("tinybeans-frontend/templates/components/nav-with-ads", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/components/nav-with-ads.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "banner");
        dom.setAttribute(el1, "class", "navbar navbar-default show-nav-ads");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "header");
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" desktop ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "hidden-xs");
        dom.setAttribute(el3, "style", " margin:0 auto; margin-top:10px; width:728px; height:90px;display:block;  text-align:center; background: transparent;");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" mobile ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "top-mobile-banner");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "hidden-lg hidden-md hidden-sm");
        dom.setAttribute(el4, "style", "height:50px;");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "style", "margin:0 auto; text-align:center;  margin-top:5px; width:320px; height:50px;background: transparent;");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [8, 1, 1]), 1, 1);
        morphs[2] = dom.createMorphAt(element0, 11, 11);
        return morphs;
      },
      statements: [["inline", "ad-component-freestar", [], ["adId", "tinybeans_leaderboard_top", "gotoPremium", ["subexpr", "action", ["gotoPremium"], [], ["loc", [null, [7, 81], [7, 103]]], 0, 0], "leaderboard", true, "whyAds", false], ["loc", [null, [7, 12], [7, 136]]], 0, 0], ["inline", "ad-component-freestar", [], ["adId", "tinybeans_leaderboard_top", "gotoPremium", ["subexpr", "action", ["gotoPremium"], [], ["loc", [null, [16, 89], [16, 111]]], 0, 0]], ["loc", [null, [16, 20], [16, 114]]], 0, 0], ["inline", "site-header", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [22, 28], [22, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "me", ["subexpr", "@mut", [["get", "model.me", ["loc", [null, [22, 37], [22, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "session", ["subexpr", "@mut", [["get", "session", ["loc", [null, [22, 54], [22, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "journals", ["subexpr", "@mut", [["get", "model.existingJournals", ["loc", [null, [23, 21], [23, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "currentJournal", ["subexpr", "@mut", [["get", "model.currentExistingJournal", ["loc", [null, [24, 27], [24, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "switchJournal", "switchJournal", "logout", "logout", "doTransition", "doTransition"], ["loc", [null, [22, 8], [26, 41]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});