define('tinybeans-frontend/components/file-to-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    files: [],

    actions: {
      uploadFile: function uploadFile(file) {
        this.get('files').pushObject(file);
      }
    }
  });
});