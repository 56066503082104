define('tinybeans-frontend/routes/main/subscription-products/select-journal', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Premium.category,
    screen: _tracker.Premium.selectJournal.screen,

    model: function model(params) {
      return Ember.RSVP.hash({
        followings: this.modelFor('main').followings,
        subscriptionProducts: this.API.getSubscriptionProducts(),
        storeCustomer: this.API.getStoreCustomer(),
        selectedProductId: params.curSelectedProductId,
        journalId: params.journalid,
        journals: this.store.findAll('journal')
      });
    },

    afterModel: function afterModel(model) {
      model.selectedProduct = _lodash.default.find(model.subscriptionProducts.toArray(), function (product) {
        return product.id == model.selectedProductId;
      });
      if (!model.journalId) {
        model.journalId = model.followings.toArray()[0].get('journal.id');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('journalId', model.journalId);
      controller.set('selectedProduct', model.selectedProduct);
      if (!model.selectedProduct || !model.journalId || !model.journals.findBy('id', model.journalId)) {
        var href = window.location.href;
        this.transitionTo('not-found', href.substr(href.indexOf('#') + 2));
      }
    }
  });
});