define('tinybeans-frontend/serializers/user', ['exports', 'ember-data/serializers/rest', 'ember-data/serializers/embedded-records-mixin'], function (exports, _rest, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _rest.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      avatars: { embedded: 'always' },
      timeZone: { embedded: 'always' },
      measurementSystem: { embedded: 'always' },
      dateStyle: { embedded: 'always' },
      emailFrequencyOnNewComment: { embedded: 'always' },
      emailFrequencyOnNewEmotion: { embedded: 'always' }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      delete payload.status; // Status messes up the JSON parser

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalize: function normalize(model, payload, prop) {
      if (payload.emailAddress === "unknown") {
        payload.emailAddress = "";
      }
      return this._super(model, payload, prop);
    }
  });
});