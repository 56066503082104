define("tinybeans-frontend/templates/main/collections/organize", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 13,
                      "column": 14
                    },
                    "end": {
                      "line": 15,
                      "column": 14
                    }
                  },
                  "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("p");
                  dom.setAttribute(el1, "class", "collection-flash-message");
                  var el2 = dom.createElement("span");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element8 = dom.childAt(fragment, [1]);
                  var element9 = dom.childAt(element8, [0]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element8, 'class');
                  morphs[1] = dom.createAttrMorph(element9, 'class');
                  morphs[2] = dom.createMorphAt(element8, 2, 2);
                  return morphs;
                },
                statements: [["attribute", "class", ["get", "flash.type", ["loc", [null, [14, 60], [14, 70]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "flash.glyphiconClass", ["loc", [null, [14, 88], [14, 108]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "flash.message", ["loc", [null, [14, 119], [14, 136]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 12
                  },
                  "end": {
                    "line": 16,
                    "column": 12
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "flash.organizeMessage", ["loc", [null, [13, 20], [13, 41]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 14], [15, 21]]]]],
              locals: ["flash"],
              templates: [child0]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              var child0 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.7.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 37,
                          "column": 26
                        },
                        "end": {
                          "line": 39,
                          "column": 26
                        }
                      },
                      "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "src", "images/hidden-mode-icon-orange.png");
                      dom.setAttribute(el1, "class", "hidden-moment-icon");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@2.7.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 34,
                        "column": 20
                      },
                      "end": {
                        "line": 45,
                        "column": 20
                      }
                    },
                    "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    dom.setAttribute(el1, "class", "moment cursor-pointer text-left col-xs-12 col-sm-3 col-md-2");
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2, "title", "Delete this from collection");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "moment-inner");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("img");
                    dom.setAttribute(el3, "class", "drag-overlay hidden-xs");
                    dom.setAttribute(el3, "src", "images/drag-overlay.png");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("img");
                    dom.setAttribute(el3, "class", "moment-thumbnail");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2, "title", "Pin this as collection cover");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var element4 = dom.childAt(element3, [0]);
                    var element5 = dom.childAt(element3, [2]);
                    var element6 = dom.childAt(element5, [5]);
                    var element7 = dom.childAt(element3, [4]);
                    var morphs = new Array(9);
                    morphs[0] = dom.createAttrMorph(element3, 'data-momentOrder');
                    morphs[1] = dom.createAttrMorph(element3, 'id');
                    morphs[2] = dom.createAttrMorph(element4, 'class');
                    morphs[3] = dom.createElementMorph(element4);
                    morphs[4] = dom.createMorphAt(element5, 1, 1);
                    morphs[5] = dom.createAttrMorph(element6, 'src');
                    morphs[6] = dom.createAttrMorph(element7, 'id');
                    morphs[7] = dom.createAttrMorph(element7, 'class');
                    morphs[8] = dom.createElementMorph(element7);
                    return morphs;
                  },
                  statements: [["attribute", "data-momentOrder", ["get", "tagEntry.id", ["loc", [null, [35, 113], [35, 124]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["get", "tagEntry.id", ["loc", [null, [35, 132], [35, 143]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "tagEntry.initialIconSelectedClass", ["loc", [null, [35, 160], [35, 193]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["deleteEntry", ["get", "tagEntry", ["loc", [null, [35, 219], [35, 227]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 196], [35, 229]]], 0, 0], ["block", "if", [["get", "tagEntry.entry.privateMode", ["loc", [null, [37, 32], [37, 58]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [37, 26], [39, 33]]]], ["attribute", "src", ["get", "tagEntry.entry.blobs.s2", ["loc", [null, [41, 37], [41, 60]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["get", "tagEntry.id", ["loc", [null, [43, 67], [43, 78]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "tagEntry.pinClass", ["loc", [null, [43, 89], [43, 106]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["pinMoment", ["get", "tagEntry", ["loc", [null, [43, 51], [43, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 30], [43, 61]]], 0, 0]],
                  locals: ["tagEntry"],
                  templates: [child0]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 32,
                      "column": 16
                    },
                    "end": {
                      "line": 47,
                      "column": 16
                    }
                  },
                  "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  dom.setAttribute(el1, "id", "moments");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "each", [["get", "tagEntries", ["loc", [null, [34, 28], [34, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [34, 20], [45, 29]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 47,
                      "column": 16
                    },
                    "end": {
                      "line": 49,
                      "column": 16
                    }
                  },
                  "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode(" ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "t", ["Collection.No_Moment_Message"], [], ["loc", [null, [48, 42], [48, 78]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 12
                  },
                  "end": {
                    "line": 51,
                    "column": 12
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "id", "collectionMoments");
                dom.setAttribute(el1, "class", "row text-center");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "if", [["get", "tagEntries", ["loc", [null, [32, 22], [32, 32]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [32, 16], [49, 23]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          var child2 = function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 16
                  },
                  "end": {
                    "line": 64,
                    "column": 204
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "tinybeans-icon-arrow-left");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Back to ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
                return morphs;
              },
              statements: [["content", "model.collection.name", ["loc", [null, [64, 175], [64, 200]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 4
                },
                "end": {
                  "line": 73,
                  "column": 4
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "id", "organize-collection");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "container");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row");
              dom.setAttribute(el3, "id", "flashMessage");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "col-sm-8 col-sm-offset-2 col-md-10 col-md-offset-1 text-center");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("h3");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("p");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode(" ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("a");
              dom.setAttribute(el6, "href", "#");
              var el7 = dom.createElement("b");
              var el8 = dom.createComment("");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "container");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row col-sm-8 col-sm-offset-2 col-md-10 col-md-offset-1 text-center");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "row");
              dom.setAttribute(el4, "style", "display:none;");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5, "id", "selectedEntryId");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("br");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("br");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "row");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("button");
              dom.setAttribute(el5, "type", "submit");
              dom.setAttribute(el5, "class", "btn btn-primary btn-big");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("br");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("br");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("p");
              var el6 = dom.createTextNode("\n                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n              ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("br");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("br");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("br");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("br");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var element11 = dom.childAt(element10, [1]);
              var element12 = dom.childAt(element11, [3, 1]);
              var element13 = dom.childAt(element12, [3]);
              var element14 = dom.childAt(element13, [2]);
              var element15 = dom.childAt(element10, [3, 1]);
              var element16 = dom.childAt(element15, [8]);
              var element17 = dom.childAt(element16, [1]);
              var morphs = new Array(10);
              morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element12, [1]), 0, 0);
              morphs[2] = dom.createMorphAt(element13, 0, 0);
              morphs[3] = dom.createElementMorph(element14);
              morphs[4] = dom.createMorphAt(dom.childAt(element14, [0]), 0, 0);
              morphs[5] = dom.createMorphAt(element15, 1, 1);
              morphs[6] = dom.createAttrMorph(element17, 'disabled');
              morphs[7] = dom.createElementMorph(element17);
              morphs[8] = dom.createMorphAt(element17, 0, 0);
              morphs[9] = dom.createMorphAt(dom.childAt(element16, [6]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "flashMessages.queue", ["loc", [null, [12, 20], [12, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 12], [16, 21]]]], ["inline", "t", ["Collection.Organize_Title"], [], ["loc", [null, [21, 18], [21, 51]]], 0, 0], ["inline", "t", ["Collection.Organize_Instruction"], [], ["loc", [null, [22, 17], [22, 56]]], 0, 0], ["element", "action", ["backToCollection", ["get", "model.journalId", ["loc", [null, [22, 88], [22, 103]]], 0, 0, 0, 0], ["get", "model.path", ["loc", [null, [22, 104], [22, 114]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 60], [22, 116]]], 0, 0], ["content", "model.collection.name", ["loc", [null, [22, 129], [22, 154]]], 0, 0, 0, 0], ["block", "main/collections/delete-reorder", [], [], 1, null, ["loc", [null, [30, 12], [51, 48]]]], ["attribute", "disabled", ["get", "submitting", ["loc", [null, [61, 51], [61, 61]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["save"], [], ["loc", [null, [61, 22], [61, 39]]], 0, 0], ["content", "saveButtonLabel", ["loc", [null, [61, 110], [61, 129]]], 0, 0, 0, 0], ["block", "link-to", ["main.collections.show", ["get", "model.journalId", ["loc", [null, [64, 51], [64, 66]]], 0, 0, 0, 0], ["get", "model.path", ["loc", [null, [64, 67], [64, 77]]], 0, 0, 0, 0]], ["invokeAction", ["subexpr", "action", ["track", "back"], [], ["loc", [null, [64, 91], [64, 114]]], 0, 0]], 2, null, ["loc", [null, [64, 16], [64, 216]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 73,
                  "column": 4
                },
                "end": {
                  "line": 75,
                  "column": 4
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              dom.setAttribute(el1, "class", "no-access-message text-center");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["Collection.No_Access_Message"], [], ["loc", [null, [74, 48], [74, 84]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 77,
                "column": 2
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "canShare", ["loc", [null, [8, 10], [8, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [8, 4], [75, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 77,
                "column": 2
              },
              "end": {
                "line": 82,
                "column": 2
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "text-center");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "text-center");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "#");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "tinybeans-icon-arrow-left");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("b");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(element2, 2, 2);
            morphs[3] = dom.createMorphAt(dom.childAt(element2, [4]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["Collection.Organize_No_Moment_Message"], [], ["loc", [null, [78, 27], [78, 72]]], 0, 0], ["element", "action", ["backToCollection", ["get", "model.journalId", ["loc", [null, [80, 37], [80, 52]]], 0, 0, 0, 0], ["get", "model.path", ["loc", [null, [80, 53], [80, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [80, 9], [80, 65]]], 0, 0], ["inline", "t", ["Collection.Back_To"], [], ["loc", [null, [80, 123], [80, 149]]], 0, 0], ["content", "model.collection.name", ["loc", [null, [80, 153], [80, 178]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 0
            },
            "end": {
              "line": 83,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasEntries", ["loc", [null, [7, 8], [7, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [7, 2], [82, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 83,
              "column": 0
            },
            "end": {
              "line": 90,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-offset-2 col-md-8 text-center");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          dom.setAttribute(el3, "class", "btn btn-primary btn-primary-reverse-outline btn-big");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["Collectin.Upgrade_Message"], [], ["loc", [null, [86, 9], [86, 42]]], 0, 0], ["element", "action", ["upgrade", ["get", "model.journalId", ["loc", [null, [87, 37], [87, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [87, 18], [87, 54]]], 0, 0], ["inline", "t", ["Collection.Upgrade_Button_Label"], [], ["loc", [null, [87, 115], [87, 154]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 91,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/collections/organize.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n  .navbar-photo-collections {\n    font-weight: 400;\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "collectionEnabled", ["loc", [null, [6, 6], [6, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [6, 0], [90, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});