define('tinybeans-frontend/components/main/journals/follower-details', ['exports', 'lodash/lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    dirty: false,
    reset: false,
    submitting: false,
    collapsed: false,

    relationshipClass: Ember.computed('follower.relationship', function () {
      return this.get('follower.relationship') ? '' : 'highlighted';
    }),

    editingRelationshipDisabled: Ember.computed('follower.user.id', 'model.me', 'model.journal.user.id', function () {
      return !this.get('canManage') || this.get('followerIsOwner') && !this.get('followerIsMe');
    }),

    editingAccessDisabled: Ember.computed('follower.user.id', 'model.me', 'model.journal.user.id', function () {
      return !this.get('canManage') || this.get('followerIsOwner');
    }),

    addEntriesDisabled: Ember.computed('editingAccessDisabled', 'follower.coOwner', function () {
      return this.get('editingAccessDisabled') || this.get('follower.coOwner');
    }),

    viewMilestonesDisabled: Ember.computed('editingAccessDisabled', 'follower.editMilestones', function () {
      return this.get('addEntriesDisabled') || this.get('follower.coOwner') || this.get('follower.editMilestones');
    }),

    editMilestonesDisabled: Ember.computed('editingAccessDisabled', 'follower.coOwner', function () {
      return this.get('editingAccessDisabled') || this.get('follower.coOwner');
    }),

    highlight: Ember.computed('follower.relationship.name', function () {
      return this.get('follower.relationship') ? '' : 'border:2px dotted blue;';
    }),

    followerIsMe: Ember.computed('follower.user.id', 'model.me.id', function () {
      return this.get('follower.user.id') === this.get('model.me.id');
    }),

    followerIsOwner: Ember.computed('follower.user.id', 'follower.journal.user.id', function () {
      return this.get('follower.user.id') === this.get('model.journal.user.id');
    }),

    addEntriesTooltip: Ember.computed('editingAccessDisabled', 'follower.coOwner', 'follower.addEntries', function () {
      return this.get('editingAccessDisabled') ? 'Not permitted' : this.get('follower.coOwner') ? 'Follower already has full access' : this.get('follower.addEntries') ? 'Click to revoke' : 'Click to grant';
    }),

    viewMilestonesTooltip: Ember.computed('editingAccessDisabled', 'follower.coOwner', 'follower.editMilestones', 'follower.viewMilestones', function () {
      return this.get('editingAccessDisabled') ? 'Not permitted' : this.get('follower.coOwner') ? 'Follower already has full access' : this.get('follower.editMilestones') ? 'Followers who can add milestones can view milestones' : this.get('follower.viewMilestones') ? 'Click to revoke' : 'Click to grant';
    }),

    editMilestonesTooltip: Ember.computed('editingAccessDisabled', 'follower.coOwner', 'follower.editMilestones', function () {
      return this.get('editingAccessDisabled') ? 'Not permitted' : this.get('follower.coOwner') ? 'Follower already has full access' : this.get('follower.editMilestones') ? 'Click to revoke' : 'Click to grant';
    }),

    coOwnerTooltip: Ember.computed('editingAccessDisabled', 'follower.coOwner', function () {
      return this.get('editingAccessDisabled') ? 'Not permitted' : this.get('follower.coOwner') ? 'Click to revoke' : 'Click to grant';
    }),

    canDelete: Ember.computed('followerIsMe', 'followerIsOwner', function () {
      return this.get('canManage') && !(this.get('followerIsMe') || this.get('followerIsOwner'));
    }),

    followerChanged: Ember.observer('follower.{addEntries,viewMilestones,editMilestones,coOwner}', 'follower.relationship.name', function (obj, path) {
      if (this.get('follower.coOwner')) {
        this.set('follower.addEntries', true);
        this.set('follower.viewMilestones', true);
        this.set('follower.editMilestones', true);
      }
      if (this.get('follower.editMilestones')) {
        this.set('follower.viewMilestones', true);
      }
      this.saveFollower(this.get('follower'));

      if (path !== 'follower.relationship.name') {
        this.GTM.trackEvent({
          'eventCategory': 'interact-with-follower-list-page',
          'eventAction': 'change-access',
          'eventLabel': ''
        });
      }
    }),

    relationshipForName: function relationshipForName(value) {
      return _lodash.default.find(this.get('model.relations').toArray(), function (relation) {
        return relation.name === value;
      });
    },


    actions: {
      selectRelationship: function selectRelationship(value) {
        // debugger;
        var relationship = this.relationshipForName(value);
        if (relationship) {
          if (!this.get('follower.relationship') || !this.get('follower.relationship.name')) {
            this.GTM.trackEvent({
              'eventCategory': 'interact-with-follower-list-page',
              'eventAction': 'set-relationship',
              'eventLabel': ''
            });
          }
          this.set('follower.relationship', relationship);
        }
      },
      collapse: function collapse() {
        this.set('collapsed', true);
      },
      expand: function expand() {
        this.set('collapsed', false);
      },
      remove: function remove() {
        var _this = this;

        bootbox.dialog({
          title: 'Remove follower',
          message: 'Are you sure?',
          buttons: {
            ok: {
              label: 'OK',
              className: "btn-secondary",
              callback: function callback() {
                _this.removeFollower(_this.get('follower'));
              }
            },
            cancel: {
              label: 'Cancel',
              className: "btn-primary"
            }
          }
        });
      }
    }
  });
});