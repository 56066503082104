define('tinybeans-frontend/components/main/payment-details/payment-form', ['exports', 'tinybeans-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    card: Ember.computed('model.storeCustomer.customer.card', function () {
      return this.get('model.storeCustomer.customer.card');
    }),

    journalId: Ember.computed('model.journalId', '_journalId', function () {
      return this.get('_journalId') || this.get('model.journalId');
    }),

    didInsertElement: function didInsertElement() {
      if (this.get('card') && this.get('showCardSelection')) {
        this.send('useExistingCard');
      }
      Ember.$('#payment-form [data-numeric]').payment('restrictNumeric');
      Ember.$('#payment-form .cc-number').payment('formatCardNumber');
      Ember.$('#payment-form .cc-cvc').payment('formatCardCVC');

      Ember.$('#payment-form .cc-number').on('input', function () {
        Ember.$("#fancy-cc-number").val(Ember.$(this).val());
        // Trigger the "change" event manually
        var evt = document.createEvent('HTMLEvents');
        evt.initEvent('keyup', false, true);
        document.getElementById('fancy-cc-number').dispatchEvent(evt);
      });

      Ember.$('.cc-month, .cc-year').on('input', function () {
        // Set the value of a hidden input field for Card
        Ember.$('#fancy-cc-expiry').val(Ember.$('.cc-month').val() + '/' + Ember.$('.cc-year').val());
        // Trigger the "change" event manually
        var evt = document.createEvent('HTMLEvents');
        evt.initEvent('change', false, true);
        document.getElementById('fancy-cc-expiry').dispatchEvent(evt);
      });

      $('#payment-form').card({
        // a selector or DOM element for the container
        // where you want the card to appear
        container: '.card-wrapper',
        formSelectors: {
          numberInput: 'input#fancy-cc-number',
          expiryInput: 'input#fancy-cc-expiry',
          cvcInput: 'input.cc-cvc'
        },
        placeholders: {
          number: '•••• •••• •••• ••••',
          name: '',
          expiry: '••/••',
          cvc: '•••'
        },
        debug: true
      });
    },


    totalAmount: Ember.computed('price', function () {
      // selectedGiftCard from voucher page || price from select-journal page
      var amount = this.get('price') / 100;
      return amount ? amount.toFixed(2) : amount;
    }),

    totalDiscountedAmount: Ember.computed('discountedPrice', 'price', function () {
      var self = this;
      var discountedPrice = self.get('discountedPrice');
      var originalPrice = self.get('originalPrice');
      var currentPrice = self.get('price');

      if (originalPrice === null) {
        return null;
      }

      if (typeof discountedPrice == 'number' && discountedPrice > 0) {
        if (self.get('giftCardId') && currentPrice !== originalPrice) {
          // we know the original gift card value changed, so throw away the coupon
          self.set("discountedPrice", null);
          self.set("originalPrice", self.get("price"));
          self.doCouponApply(false);
          return null;
        }

        var amount = discountedPrice / 100;
        return amount ? amount.toFixed(2) : amount;
      }
      return null;
    }),

    currencyUnit: Ember.computed('selectedGiftCard', function () {
      return this.get('totalAmount') ? this.get('selectedGiftCard.currencyUnit') || "US$" : '';
    }),

    submitForm: function submitForm(token) {
      var _this = this;

      var self = this;
      var $form = Ember.$("form#payment-form");
      var couponCode = $form.find("#couponCode").val();
      var journalId = this.get('journalId');
      var productId = this.get('selectedProduct.id');
      $form.closest("#payment-details-modal").find(".progress").removeClass("hide");
      $form.closest("#payment-details-modal").find("#progress .progress-bar").addClass("active").removeClass("hide");

      $form.find('button').prop("disabled", true);
      this.sendAction('track', 'pay');

      self.API.submitPayment(journalId, productId, couponCode, token, function (data) {
        Ember.run.next(function () {
          Ember.$("#payment-form .payment-errors").html("").addClass("hide");
          _this.set("paymentTransaction", data.paymentTransaction);
          _this.set("subscription", data.subscription);
          _this.set('selectedProduct', _this.get('selectedProduct'));
          _this.paymentCallback(data);
        });
      }, function (data) {

        var $form = Ember.$("form#payment-form");
        $form.find('button').prop("disabled", false);
        $form.find("input:visible").prop("disabled", false);
        $form.closest("#payment-details-modal").find(".progress").addClass("hide");
        $form.closest("#payment-details-modal").find("#progress .progress-bar").removeClass("active").addClass("hide");
        Ember.$("#payment-form .payment-errors").html(data.responseJSON.message).removeClass("hide");
      });
    },


    getStripeToken: function getStripeToken() {
      var self = this;
      var $form = Ember.$('#payment-form');

      var hasErrors = false;
      $form.find("input").parent().removeClass("has-error");

      if (!Ember.$.payment.validateCardNumber($form.find(".cc-number").val())) {
        $form.find(".cc-number").parent().addClass("has-error");
        hasErrors = true;
      }

      if (!Ember.$.payment.validateCardExpiry($form.find(".cc-month").val(), $form.find(".cc-year").val())) {
        $form.find(".cc-month").parent().addClass("has-error");
        $form.find(".cc-year").parent().addClass("has-error");
        hasErrors = true;
      }

      if (!Ember.$.payment.validateCardCVC($form.find(".cc-cvc").val())) {
        $form.find(".cc-cvc").parent().addClass("has-error");
        hasErrors = true;
      }

      if (hasErrors) {
        $form.find('.payment-errors').html("Please fix errors to continue").removeClass("hide");
        Ember.$(".modal-content").effect("shake", {
          times: 3,
          distance: 10
        }, 80);
        return false;
      }

      // Disable the submit button to prevent repeated clicks
      $form.find('button').prop("disabled", true);
      $form.find("input:visible").prop("disabled", true);
      $form.closest("#payment-details-modal").find("#progress .progress-bar").addClass("active").removeClass("hide");

      Ember.run.begin(self);
      Stripe.setPublishableKey(_environment.default.stripeKey);
      Stripe.card.createToken($form, function (status, response) {
        var $form = Ember.$('#payment-form');
        if (response.error) {
          // Show the errors on the form
          var inputName = response.error.param.replace("_", "-");
          console.log("Payment failed: " + response.error.message);
          $form.find('.payment-errors').html(response.error.message).removeClass("hide");
          $form.find("input[data-stripe='" + inputName + "']").parent().addClass("has-error");
          $form.find('button').prop('disabled', false);
          $form.find("input:visible").prop("disabled", false);
          $form.closest("#payment-details-modal").find(".progress").addClass("hide");
          $form.closest("#payment-details-modal").find("#progress .progress-bar").removeClass("active").addClass("hide");
        } else {
          $form.find('.payment-errors').html("").addClass("hide");
          // response contains id and card, which contains additional card details
          var token = response.id;
          // Insert the token into the form so it gets submitted to the server
          $form.append(Ember.$('<input type="hidden" name="stripeToken" />').val(token));
          // and submit
          self.submitForm(token);

          //todo use new card fix
          console.log("generate new token" + token);

          //todo use new card fix
        }

        Ember.run.end();
      });
    },

    doCouponApply: function doCouponApply(submitIfSuccessful) {
      var self = this;
      var couponCode = Ember.$("#couponForm #couponCodeHolder").val();
      Ember.$("#couponForm #couponResult").html(couponCode ? "Applying coupon, please wait..." : "Invalid coupon");

      var productId = this.get('selectedProduct.id');
      var giftCardId = this.get('giftCardId');

      var journalId = parseInt(self.get('journalId'));
      this.sendAction('track', 'apply-coupon');

      this.API.applyCoupon(journalId, productId, giftCardId, couponCode, function (data) {
        console.log("success applied coupon");
        Ember.$("#couponForm #couponResult").html("Your coupon code has been applied");
        Ember.$("#couponForm #couponCodeHolder").parent().addClass("has-success").removeClass("has-error");
        Ember.$("#couponForm").closest("form").find("#couponCode").val(Ember.$("#couponForm #couponCodeHolder").val());
        self.set("originalPrice", self.get("price"));
        self.set("discountedPrice", data.discountedPrice); // this has to be after set originalPrice
        self.set('couponApplied', true);

        if (submitIfSuccessful) {
          if (Ember.$("form#payment-form #useExisting").val() === "true") {
            self.submitForm();
          } else {
            self.getStripeToken();
          }
        }
      }, function () {
        console.log("fail coupon");
        Ember.$("#couponForm #couponResult").html("Invalid coupon");
        //Ember.$("#couponForm #couponResultIcon").removeClass("glyphicon-usd glyphicon-ok text-success").addClass("glyphicon-remove text-danger");
        Ember.$("#couponForm #couponCodeHolder").parent().addClass("has-error");
        Ember.$("#couponForm").closest("form").find("#couponCode").val("");
        // if (submitIfSuccessful) {
        //   Ember.$("#payment-form .payment-errors").html("Coupon code is invalid").removeClass("hide");
        // }
        return false;
      });
    },

    price: Ember.computed('discountedPrice', 'selectedProduct', function () {
      var discountedPrice = this.get('discountedPrice');
      if (discountedPrice !== null && discountedPrice !== undefined) {
        return discountedPrice;
      }
      return this.get('selectedProduct.amountInCents');
    }),

    transition: function transition(route, param) {
      if (!param) this.sendAction('doTransition', route);
    },

    actions: {
      toggleCouponLink: function toggleCouponLink() {
        Ember.$("#couponForm .input-group").toggle();
      },


      useExistingCard: function useExistingCard() {
        Ember.$("#useNewCard").removeClass("btn-primary active").addClass("btn-default");
        Ember.$("#useExistingCard").removeClass("btn-default").addClass("btn-primary active");
        Ember.$("#newCardHolder").hide();
        Ember.$("#existingCardHolder").show();
        Ember.$("#payment-form #useExisting").val("true");
      },

      useNewCard: function useNewCard() {
        Ember.$("#useExistingCard").removeClass("btn-primary active").addClass("btn-default");
        Ember.$("#useNewCard").removeClass("btn-default").addClass("btn-primary active");
        Ember.$("#existingCardHolder").hide();
        Ember.$("#newCardHolder").show();
        Ember.$("#payment-form #useExisting").val("false");
      },

      submit: function submit() {
        Ember.$("#payment-form .payment-errors").html("");
        if (this.preValidate && !this.preValidate()) {
          return;
        }

        if (this.get("acceptTerms")) {
          var couponSelected = $("#couponForm .input-group").is(":visible");
          var couponCode = Ember.$("#couponForm #couponCodeHolder").val();
          var couponApplied = this.get('couponApplied');
          if (couponSelected && couponCode && !couponApplied) {
            if (!this.doCouponApply(true)) {
              return;
            }
          }
          if (this.submit) {
            this.submit();
          } else {
            if (Ember.$("form#payment-form #useExisting").val() === "true") {
              this.submitForm();
            } else {
              this.getStripeToken();
            }
          }
        } else {
          Ember.$("#payment-form .payment-errors").html("Please accept terms and conditions to continue").removeClass("hide");
        }
      },

      applyCoupon: function applyCoupon() {
        var self = this;
        self.doCouponApply(false);
      }
    }
  });
});