define('tinybeans-frontend/routes/main/subscription-products/voucher', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({

    model: function model(params) {
      var code = params.code;
      return Ember.RSVP.hash({
        followings: this.modelFor('main').followings,
        journals: this.store.findAll('journal'),
        code: code
      });
    }
  });
});