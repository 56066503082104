define('tinybeans-frontend/routes/main/journals/view', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model(params) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      if (params.setup) {
        // Avoid setup=undefined ugliness in url
        this.transitionTo('main.journals.month', params.journal_id, year, month, { queryParams: { setup: params.setup } });
      } else {
        this.transitionTo('main.journals.month', params.journal_id, year, month);
      }
    }
  });
});