define('tinybeans-frontend/controllers/main/journals/follower-list-invite-start', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash'], function (exports, _baseController, _commonsMixin, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    queryParams: ['setup'],

    isActiveNavJournals: function isActiveNavJournals() {
      return true;
    },

    submitButtonLabel: function () {
      return this.t("Journal.Follower.Add_To_Journal");
    }.property('submitting'),

    actions: {

      send: function send() {
        var self = this;
        this.set('error', {});
        // Make sure the relationship field is checked.
        if (!this.get('model.invite.firstName')) {
          this.set('error.firstName', 'First Name is Required');
        }
        if (!this.get('model.invite.lastName')) {
          this.set('error.lastName', 'Last Name is Required');
        }
        if (!this.get('model.invite.emailAddress')) {
          this.set('error.emailAddress', 'Please enter follower\'s email address');
        }
        if (!self.get('model.invite.relationship')) {
          self.set('error.relationship', 'Please select relationship');
        }
        if (!_lodash.default.isEmpty(this.get('error'))) return;
        self.set('submitting', true);

        var invite = self.get('model.invite');

        var followerPermission = Ember.$('input:radio[name=followerPermission]:checked').val();

        invite.viewMilestones = true;
        invite.viewEntries = true;
        if (followerPermission == "coOwner") {
          invite.coOwner = true;
          invite.addEntries = true;
          invite.editMilestones = true;
        } else if (followerPermission == "normalFollower") {
          invite.coOwner = false;
          invite.addEntries = false;
          invite.editMilestones = false;
        } else if (followerPermission == "family") {
          invite.coOwner = false;
          invite.addEntries = true;
          invite.editMilestones = true;
        }

        this.API.inviteJournalFollower(self.journalId, invite, function () {
          self.send("reloadApplicationRoute");
          self.set('flash', 'Invite sent successfully');
          Ember.run.next(function () {
            // self.transitionToRoute('main.journals.follower-list', self.journalId);
            //change add follower to be first of onboarding
            var journalId = self.get('journalId');
            var d = new Date();
            var month = d.getMonth() + 1;
            var year = d.getFullYear();

            if (self.get('setup')) {
              self.transitionToRoute('main.journals.month', journalId, year, month, {
                queryParams: {
                  setup: true
                }
              });
            } else {
              self.transitionToRoute('main.journals.month', journalId, year, month);
            }
          });
        }, function (xhr, statusText, e) {
          self.set('submitting', false);
          self.onApiError(xhr, statusText, e);
        });
      },

      skip: function skip() {
        var journalId = this.get('journalId');
        var d = new Date();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();
        this.transitionToRoute('main.journals.month', journalId, year, month, {
          queryParams: {
            setup: true
          }
        });
      },

      expandMessage: function expandMessage() {
        this.set('showMessage', true);
      }

    }
  });
});