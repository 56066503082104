define('tinybeans-frontend/routes/main/offers/offer', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/config/environment', 'lodash/lodash', 'tinybeans-frontend/utils/helper'], function (exports, _baseProtectedRoute, _environment, _lodash, _helper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _baseProtectedRoute.default.extend({
    model: function model(params) {
      Ember.$.extend(params, this.paramsFor('main'));
      var campaign = params.utm_campaign;
      var partner_name = params.offer_partner;
      var monthly_code = params.coupon_code_monthly || "";
      var yearly_code = params.coupon_code_yearly || "";
      var exp = params.exp_date || Date.now();
      var monthly_amount = params.monthly_offer || 0;
      var yearly_amount = params.yearly_offer || 0;
      var show_free = params.show_free || "1";
      var type = params.offer_type || "none";
      var discount_amount = params.discount || 0;
      var is_new_user = params.is_new_user || false;
      var description = params.description || "";
      var landing_page_title = params.landing_page_title || "Select your plan";
      var hideSaveMoney = params.hide_save_money || false;
      var hideBestValue = params.hide_best_value || false;
      return Ember.RSVP.hash({
        isSubscribed: this.API.getActiveSubscription(),
        languages: navigator.languages,
        campaign: {
          name: campaign,
          partner_name: partner_name,
          monthly_code: monthly_code,
          yearly_code: yearly_code,
          discount_amount: discount_amount,
          exp_date: exp,
          offer_type: type,
          monthly_amount: monthly_amount,
          yearly_amount: yearly_amount,
          show_free: show_free,
          isNew: is_new_user,
          offerDescription: description,
          landingPageTitle: landing_page_title,
          showSaveMoney: hideSaveMoney !== "true" && hideSaveMoney !== true,
          showBestValue: hideBestValue !== "true" && hideBestValue !== true
        }
      });
    },
    beforeModel: function beforeModel() {
      var _this = this;

      var isSubscribed = this.modelFor('main') ? this.modelFor('main').isSubscribedUser : {};
      var isPremium = this.modelFor('main').myJournals.toArray().some(function (journal) {
        return _this.globals.premiumTier(journal);
      });

      // redirect to ineligible if user has a subscription
      if (!_lodash.default.isEmpty(isSubscribed.subscription) || isPremium) {
        this.transitionTo('main.offers.ineligible');
      } else {
        if (!this.get('session.isAuthenticated')) {
          console.log('not logged in');
          this.transitionTo('login');
        }
      }
    },
    afterModel: function afterModel(model) {
      if ("true" === model.campaign.isNew) {
        var uuid = 'undefined' !== typeof window.crypto.randomUUID ? window.crypto.randomUUID() : null;
        var cj_args = {
          'event': 'cj_purchase_confirmation_free',
          'eventCategory': 'offer_page',
          'eventAction': 'user signup',
          'eventLabel': 'web',
          'orderID': uuid
        };
        this.GTM.trackEvent(cj_args);
        if (_environment.default.enable_qa_output) {
          console.log("%cCJ Testing Output: Message sent with following data >>", "color: white; background-color: #fcb900;", cj_args);
        }
      } else {
        console.log(model.campaign.isNew);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var language_code = model.languages[0] || 'en-US';
      controller.set('userLanguage', language_code);
      var default_prices = {
        monthly: '$7.99',
        yearly: '$74.99',
        monthlyStripePriceId: _environment.default.stripeMonthlyPrice,
        yearlyStripePriceId: _environment.default.stripeAnnualPrice
      };
      var prices = {};
      var lp_prices = {};

      controller.set('showSaveMoney', model.campaign.showSaveMoney);
      controller.set('showBestValue', model.campaign.showBestValue);
      // main object holding offer details
      controller.set('campaign', model.campaign);

      // Subscription LP Updated
      Leanplum.addVariablesChangedHandler(function () {
        var vars = Leanplum.getVariables();
        if (vars.Paywall) {
          var paywall_prices = '';
          if ('undefined' !== typeof vars.Paywall[language_code]) {
            paywall_prices = vars.Paywall[language_code];
          } else {
            paywall_prices = vars.Paywall['en-US']; // default or unhandled
          }
          var paywall_products = JSON.parse(vars.Paywall.newProducts);
          controller.set('paidFeatures', paywall_products.paidFeatures);

          // Includes currency
          lp_prices = {
            monthly: paywall_prices.monthlyPrice,
            yearly: paywall_prices.yearlyPrice,
            monthlyStripePriceId: paywall_prices.monthlyStripePriceId,
            yearlyStripePriceId: paywall_prices.yearlyStripePriceId
          };
          prices = _extends({}, default_prices, lp_prices);
          controller.set('monthlyPriceId', prices.monthlyStripePriceId);
          controller.set('annualPriceId', prices.yearlyStripePriceId);
          controller.set('prices', prices);
          console.log('LP loaded. Dynamic Paywall data used.');
        }
      });

      if (JSON.stringify(lp_prices) === '{}') {
        console.log('LP not loaded yet. Backup Paywall data used.');
      }

      prices = _extends({}, default_prices, lp_prices);

      controller.set('monthlyPriceId', prices.monthlyStripePriceId);
      controller.set('annualPriceId', prices.yearlyStripePriceId);

      controller.set('prices', prices);

      controller.set('monthly_code', model.campaign.monthly_code);
      controller.set('yearly_code', model.campaign.yearly_code);

      controller.set('expirationDate', moment.unix(model.campaign.exp_date).format("MMM. D, YYYY"));

      if (model.isSubscribed.subscription) {
        controller.set('isSubscribed', true);
      } else {
        controller.set('isSubscribed', false);
      }

      if ("1" === model.campaign.show_free) {
        controller.set('showFree', true);
      } else {
        controller.set('showFree', false);
      }

      controller.set('offerTypeDisplay', '%');
      controller.set('offerTypeDisplayLeft', false);

      if (0 === parseInt(model.campaign.monthly_amount) && 0 === parseInt(model.campaign.yearly_amount)) {
        // no discount page
        console.log("No discount set.");
        controller.set('showMonthlyDiscount', false);
        controller.set('showAnnualDiscount', false);
      } else {
        if (0 !== model.campaign.monthly_amount) {
          controller.set('showMonthlyDiscount', true);
          console.log("show monthly discount.");
          // percent
          controller.set('monthlyDiscountedPrice', '$' + (parseFloat(prices.monthly.replace('$', '').replace('A', '')).toFixed(2) * ((100 - parseInt(model.campaign.monthly_amount)) / 100)).toFixed(2));
        }
        if (0 !== model.campaign.yearly_amount) {
          console.log("show annual discount.");
          controller.set('showAnnualDiscount', true);
          // percent
          controller.set('annualDiscountedPrice', '$' + (parseFloat(prices.yearly.replace('$', '').replace('A', '')).toFixed(2) * ((100 - parseInt(model.campaign.yearly_amount)) / 100)).toFixed(2));
        }
      }
    }
  });
});