define('tinybeans-frontend/models/collection', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    URL: (0, _attr.default)('string'),
    shareUrl: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    path: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    themeId: (0, _attr.default)('number'),
    isPublic: (0, _attr.default)('boolean', { defaultValue: true }),
    deleted: (0, _attr.default)('boolean'),
    timestamp: (0, _attr.default)('number'),
    lastUpdatedTimestamp: (0, _attr.default)('number'),
    user: (0, _relationships.belongsTo)('user'),
    journal: (0, _relationships.belongsTo)('journal'),
    sortOrder: (0, _attr.default)('number'),
    tagEntries: (0, _relationships.hasMany)('collection-entry'), // This is lazily loaded using the links attribute
    earliestEntry: (0, _relationships.belongsTo)('entry', { async: false }),
    latestEntry: (0, _relationships.belongsTo)('entry', { async: false }),
    pinnedTagEntry: (0, _relationships.belongsTo)('collection-entry', { async: false })
  });
});