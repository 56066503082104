define('tinybeans-frontend/adapters/pet-checklist-item', ['exports', 'tinybeans-frontend/adapters/application', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _application, _dataAdapterMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_dataAdapterMixin.default, {
        query: function query(store, type, _query) {
            var url = '/api/1/pets/' + _query.petId + '/checklistItems';
            return this.ajax(url, 'GET', {});
        }
    });
});