define('tinybeans-frontend/components/day-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service('-routing'),

    dayMonthYearLabel: Ember.computed('model.day', 'model.month', 'model.year', function () {
      var date = moment({
        day: this.get('model.day'),
        month: this.get('model.month') - 1,
        year: this.get('model.year')
      });
      var yearLabel = date.format('YYYY');
      var monthLabel = date.format('MMM');
      var dayLabel = date.format('D');
      var dayMonthYearString = monthLabel + " " + dayLabel;
      var dayMonthYearMobileString = dayMonthYearString + " " + yearLabel;

      return {
        year: yearLabel,
        month: monthLabel,
        day: dayLabel,
        string: dayMonthYearString,
        mobileString: dayMonthYearMobileString
      };
    }),

    actions: {
      showMonth: function showMonth() {
        this.sendAction('showMonth');
      },

      showNextDay: function showNextDay() {
        this.sendAction('showNextDay');
      },
      showPrevDay: function showPrevDay() {
        this.sendAction('showPrevDay');
      },
      addMoment: function addMoment() {
        this.sendAction('addMoment');
      },
      gotoDayView: function gotoDayView(journalId, year, month, day) {
        this.sendAction('gotoDayView', journalId, year, month, day);
      }
    }
  });
});