define('tinybeans-frontend/serializers/journal', ['exports', 'ember-data/serializers/rest', 'ember-data/serializers/embedded-records-mixin', 'lodash/lodash'], function (exports, _rest, _embeddedRecordsMixin, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _rest.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      user: {
        embedded: 'always'
      },
      children: {
        embedded: 'always'
      },
      pets: {
        embedded: 'always'
      },
      coverTheme: {
        embedded: 'always'
      },
      features: {
        embedded: 'always'
      }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      delete payload.status; // Status messes up the JSON parser

      if (payload.journal && payload.journal.journal) {
        payload.journal = payload.journal.journal;
        if (payload.journal.children) {
          payload.journal.children = payload.journal.children.sort(function (a, b) {
            return moment(b.dob, 'YYYY-MM-DD').diff(a.dob, 'YYYY-MM-DD');
          });
        }
      }
      if (payload.journals) {
        _lodash.default.each(payload.journals, function (journal) {
          if (journal.children) {
            journal.children = journal.children.sort(function (a, b) {
              return moment(b.dob, 'YYYY-MM-DD').diff(a.dob, 'YYYY-MM-DD');
            });
          }
        });
      }
      if ((requestType === 'findAll' || requestType === 'query') && !payload.journals) {
        payload.journals = [];
      }

      //payload.links = { entries: "/journals" + payload.journal.id }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});