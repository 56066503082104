define('tinybeans-frontend/mixins/api-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    onApiSuccess: function onApiSuccess() {
      this.set('success', { global: 'Details saved successfully' });
      this.set('error', {});
      $("html, body").animate({ scrollTop: 0 }, "slow");
    },
    onApiError: function onApiError(response) {
      if (response && response.responseJSON) {
        this.set('error', {});
        var errors = response.responseJSON.errors;
        if (errors) {
          for (var i = 0; i < errors.length; i++) {
            var error = errors[i];
            this.set('error.' + error.key, error.message);
          }
        }
      }
      this.set('error.global', 'Please check the data you have entered and try again');
      $("html, body").animate({ scrollTop: 0 }, "slow");
      this.set('success', {});
    }
  });
});