define('tinybeans-frontend/services/google-tag-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Service.extend({
    defaultEvent: {
      event: 'trackEvent',
      eventCategory: '',
      eventAction: '',
      eventLabel: '',
      eventValue: ''
    },

    defaultPageView: {
      event: 'gtm.js',
      virtualPagePath: ''
    },

    createPayload: function createPayload(type, o) {
      var data = o || {};
      var defaultPayload = this['default' + type];
      var payload = {};
      // Object.keys(defaultPayload).forEach(function (key) {
      //   payload[key] = data.hasOwnProperty(key) ? data[key] : defaultPayload[key];
      // });
      payload = _extends({}, defaultPayload, data); // combine default payload with data args so we can send unique variables

      return payload;
    },

    trackEvent: function trackEvent(o) {
      window.dataLayer.push(this.createPayload('Event', o));
    },

    trackPageView: function trackPageView(path) {
      window.dataLayer.push(this.createPayload('PageView', { virtualPagePath: path }));
    }
  });
});