define('tinybeans-frontend/models/subscription', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    startDateDisplay: _emberData.default.attr('string'),
    expiryDateDisplay: _emberData.default.attr('string'),
    expiryDate: _emberData.default.attr('number'),
    startDate: _emberData.default.attr('number'),
    renewal: _emberData.default.attr('string'),
    subscriptionProductId: _emberData.default.attr('number'),
    subscriptionProduct: _emberData.default.attr(),
    journal: (0, _relationships.belongsTo)('journal'),
    paymentTransaction: (0, _relationships.belongsTo)('paymentTransaction')
  });
});