define('tinybeans-frontend/routes/main/journals/search', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model(params) {
      var journalId = params.journal_id;

      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        isSubscribedUser: this.API.getActiveSubscription(),
        followers: this.store.findAll('follower', { filter: { journalId: journalId } }),
        followings: this.modelFor('main').followings

      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (model.isSubscribedUser.subscription) {
        controller.set('isSubscribedUser', true);
      } else {
        controller.set('isSubscribedUser', false);
      }
      controller.set("resultEntries", null);
      controller.set("pageNumber", 1);
      controller.set('sortOrder', "DD");
      controller.set('showLoadMore', false);
      controller.set('hasResults', true);
      controller.set('resultCount', 0);

      var img = new Image();
      img.src = 'images/singleupload.png';

      // These two lines merely load up the images into cache
      if (!this.globals.isMobile()) {
        var img2 = new Image();
        img2.src = '/app/images/multipleupload.gif';
      } else {
        var _img = new Image();
        _img.src = '/app/images/mobileupload.gif';
      }
    }
  });
});