define('tinybeans-frontend/components/main/followers/add-follower-form', ['exports', 'npm:hashids/cjs'], function (exports, _cjs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    submitting: false,
    inviteMethod: 'email',

    submitButtonLabel: Ember.computed('submitting', function () {
      return this.get("i18n").t(this.get('onboarding') ? "Onboarding.Save_And_Continue" : "Journal.Follower.Add_To_Journal");
    }),

    cancelButtonLabel: Ember.computed(function () {
      return this.get('onboarding') ? "Skip" : "Cancel";
    }),

    getLinkDisabled: Ember.computed('model.invite.firstName', 'model.invite.lastName', function () {
      return !this.get('model.invite.firstName') || !this.get('model.invite.lastName');
    }),

    link: Ember.computed('model.invite.token', function () {
      var token = this.get('model.invite.token');
      return token ? location.origin + '/i/' + token : undefined;
    }),

    tokenObserver: Ember.observer('model.invite.firstName', 'model.invite.lastName', function () {
      if (!(this.get('model.invite.firstName') && this.get('model.invite.lastName'))) {
        this.set('model.invite.token', undefined);
      }
    }),

    saveFollower: function saveFollower(onSuccess) {
      var _this = this;

      var invite = this.get('model.invite');
      this.API.inviteJournalFollower(this.get('model.journalId'), invite, function () {
        _this.set('submitting', false);
        if (onSuccess) onSuccess();
      }, function (xhr, statusText, e) {
        _this.set('submitting', false);
        _this.onApiError(xhr, statusText, e);
      });
    },


    emailLinkClass: Ember.computed('showTokenInvite', 'inviteMethod', function () {
      return this.get('showTokenInvite') ? 'underlined-' + (this.get('inviteMethod') === 'email' ? 'teel' : 'inactive') : '';
    }),

    actions: {
      generateLink: function generateLink() {
        if (this.get('inviteMethod') == 'token' && this.get('model.invite.firstName') && this.get('model.invite.lastName')) {
          var salt = this.get('model.invite.firstName') + ' ' + this.get('model.invite.lastName');
          var now = Date.now();
          var hash = new _cjs.default(salt, 7);
          this.set('model.invite.token', 'W-' + hash.encode(now));
        } else {
          this.set('model.invite.token', undefined);
        }
      },
      changeMethod: function changeMethod(method) {
        if (this.get('showTokenInvite')) this.set('inviteMethod', method);
      },
      copyLink: function copyLink() {
        var _this2 = this;

        this.set('model.invite.emailAddress', undefined);
        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';

        textArea.value = this.get('link');

        document.body.appendChild(textArea);

        textArea.select();

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Copying text command was ' + msg);
        } catch (err) {
          console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
        this.saveFollower(function () {
          bootbox.alert({
            size: 'small',
            title: '',
            message: '<div class=\'text-center\'>Link copied!</div><div style=\'text-align:center;overflow-wrap:break-word;\'>The link will only work for ' + _this2.get('model.invite.firstName') + '.<br /> <br /> Paste and send this link to ' + _this2.get('model.invite.firstName') + ' in any messaging platform you prefer, then come back when your\u2019e done.</div>',
            buttons: {
              ok: {
                label: 'Ok! Got it.'
              }
            },
            callback: function callback() {
              _this2.sendAction('inviteSent');
            }
          }).find('.modal-footer').css({ 'text-align': 'center', 'border-top': 'none' });
        });
      },
      send: function send() {
        this.set('submitting', true);
        var self = this;
        self.set('error', undefined);

        var invite = self.model.invite;
        var error = void 0;
        if (!invite.firstName) {
          error = {};
          error.firstName = 'Please fill in first name';
        }

        if (!invite.lastName) {
          error = error || {};
          error.lastName = 'Please fill in last name';
        }

        if (!validateEmail(invite.emailAddress)) {
          error = error || {};
          error.emailAddress = 'Enter an email, or click “don\'t know email”';
        }

        if (error) {
          this.set('error', error);
          self.set('submitting', false);
          return;
        }

        invite.viewMilestones = true;
        invite.viewEntries = true;

        this.saveFollower(function () {
          self.sendAction('inviteSent');
        });
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });
});