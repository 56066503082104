define("tinybeans-frontend/templates/components/main/journals/add-moment/child-component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 6
          }
        },
        "moduleName": "tinybeans-frontend/templates/components/main/journals/add-moment/child-component.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "overflow: hidden; height: 50px; width: 45px; padding-left: 2px; padding-top:2px; float:left; cursor: pointer;text-align: center;");
        var el2 = dom.createElement("img");
        dom.setAttribute(el2, "class", "avatar avatar-tiny child");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "style", "font-size: 12px;");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'src');
        morphs[1] = dom.createAttrMorph(element1, 'title');
        morphs[2] = dom.createAttrMorph(element1, 'style');
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [2]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "src", ["concat", [["get", "child.avatars.m", ["loc", [null, [4, 11], [4, 26]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["concat", [["get", "child.fullName", ["loc", [null, [5, 13], [5, 27]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["subexpr", "html-safe", [["get", "style", ["loc", [null, [7, 22], [7, 27]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [7, 29]]], 0, 0], 0, 0, 0, 0], ["content", "child.limitedFirstName", ["loc", [null, [9, 32], [9, 58]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());
});