define('tinybeans-frontend/adapters/entry', ['exports', 'tinybeans-frontend/adapters/application', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _application, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_dataAdapterMixin.default, {
    REST: Ember.inject.service(),

    findRecord: function findRecord(store, type, id, snapshot) {
      var url = '/api/1/entries/' + id + '/uuid/' + snapshot.adapterOptions.uuid;
      return this.ajax(url, 'GET');
      // return new Ember.RSVP.Promise(function(resolve, reject) {
      //   Ember.$.getJSON(url).then(function(data) {
      //     Ember.run(null, resolve, data);
      //   }, function(jqXHR) {
      //     jqXHR.then = null; // tame jQuery's ill mannered promises
      //     Ember.run(null, reject, jqXHR);
      //   });
      // });
    },

    query: function query(store, type, _query) {
      var url;
      if (_query.filter.day) {
        url = '/api/1/journals/' + _query.filter.journalId + '/entries?day=' + _query.filter.day + '&month=' + _query.filter.month + '&year=' + _query.filter.year + '&idsOnly=true';
      } else {
        url = '/api/1/journals/' + _query.filter.journalId + '/entries?month=' + _query.filter.month + '&year=' + _query.filter.year + '&idsOnly=true';
      }

      return this.ajax(url, 'GET', {});
    },
    createRecord: function createRecord(store, type, snapshot) {
      var self = this;
      var data = this.serialize(snapshot);
      var file = snapshot.adapterOptions.file;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        self.get('REST').amazonPostWithFile('/api/1/journals/' + snapshot.adapterOptions.journalId + '/entries', data, file, function (data) {
          if (snapshot.adapterOptions.facebookToken) {
            // Todo: share on facebook
          }
          Ember.run(null, resolve, data);
        }, reject, function () {
          // Simply set the upload status to print "retrying" (the retry itself is handled below)
          self.get('globals').set('uploadPaused', true);
        }, function (filePercentage) {
          self.get('globals').set('uploadedPercentage', filePercentage);
        });
      });
    },


    deleteRecord: function deleteRecord(store, type, snapshot) {
      var url = '/api/1/journals/' + snapshot.record.get('journalId.id') + '/entries/' + snapshot.id;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: 'DELETE',
          url: url,
          dataType: 'json'
        }).then(function (data) {
          Ember.run(null, resolve, data);
        }, function (jqXHR) {
          jqXHR.then = null; // tame jQuery's ill mannered promises
          Ember.run(null, reject, jqXHR);
        });
      });
    }

  });
});