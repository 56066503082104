define('tinybeans-frontend/controllers/main/products/list', ['exports', 'tinybeans-frontend/tracker'], function (exports, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      track: function track(what) {
        var action = 'unknown';
        switch (what) {
          case 'makeable':
            action = _tracker.Products.makeableButton;
            break;
          case 'photobooks':
            action = _tracker.Products.photoBookButton;
            break;
          case 'tweedwolf':
            action = _tracker.Products.tweedWolfButton;
            break;
          default:
            break;
        }
        _tracker.Tracker.trackEvent({
          category: _tracker.Products.category,
          screen: _tracker.Products.screen,
          action: action
        });
      }
    }
  });
});