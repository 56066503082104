define('tinybeans-frontend/components/modal-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    closeButton: true,
    saveButton: false,
    saveButtonLabel: 'Save',
    closeButtonLabel: 'Close',
    // oldTop: 0,

    didInsertElement: function didInsertElement() {
      var self = this;
      var $modal = self.$('.modal');

      //this.set('oldTop', Ember.$('body').scrollTop());
      //Ember.$('body').css('top', -(Ember.$('body').scrollTop()) + 'px').addClass('noscroll');


      if (this.get('noDismiss')) {
        $modal.modal({
          keyboard: false,
          backdrop: 'static'
        });
      } else {
        $modal.modal('show');
      }
      $modal.on("hidden.bs.modal", function () {
        self.send('close');
      });
    },

    showFooter: function () {
      return this.get('closeButton') || this.get('saveButton');
    }.property(),

    actions: {
      close: function close() {
        // send primary action to controller
        //Ember.$('body').removeClass('noscroll');
        //Ember.$('body').scrollTop(this.get('oldTop'));
        this.sendAction('close');
      },
      save: function save() {
        this.$('.modal').modal('hide');
        this.sendAction('save');
      }
    }

  });
});