define('tinybeans-frontend/services/tinybeans-backend', ['exports', 'tinybeans-frontend/services/rest-backend', 'tinybeans-frontend/config/environment', 'tinybeans-frontend/routes/main/gift-card/redeem-gift-card'], function (exports, _restBackend, _environment, _redeemGiftCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _restBackend.default.extend({
    loginToFacebook: function loginToFacebook(resolve, reject) {
      var self = this;
      try {
        FB.login(function (fbResponse) {
          if (fbResponse.authResponse) {
            Ember.run(function () {
              self.authenticateWithFacebookToken(fbResponse.authResponse.accessToken, resolve, reject);
            });
          } else {
            reject();
          }
        }, {
          scope: 'public_profile,email,publish_actions'
        });
      } catch (error) {
        console.log("Facebook error: " + error);
      }
    },
    authenticateWithFacebookToken: function authenticateWithFacebookToken(token, resolve, reject) {
      var url = _environment.default.apiEndPoint + '/api/1/authenticate';
      var credentials = {
        clientId: _environment.default.clientID,
        facebookAccessToken: token
      };
      var jsonString = JSON.stringify(credentials, null, 4);
      var ajaxRequest = Ember.$.ajax({
        url: url,
        type: 'post',
        data: jsonString,
        contentType: 'application/json',
        dataType: 'json'
      });
      ajaxRequest.then(function (data) {
        Ember.run(function () {
          data.access_token = data.accessToken;
          data.facebookAccessToken = token;
          resolve(data);
        });
      }, function (xhr) {
        Ember.run(function () {
          reject(xhr.responseJSON || xhr.responseText);
        });
      });
    },


    insertUser: function insertUser(user, referrerCode, success, error) {
      var data = {
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.emailAddress,
        password: user.password,
        emailAddress: user.emailAddress,
        file: user.file,
        sendWelcome: true,
        campaign: user.campaign,
        abTest: user.abTest,
        referralCode: referrerCode,
        sendResetPassword: true,
        phoneNumber: user.phoneNumber,
        sendSMS: user.phoneNumber ? true : null,
        clientId: _environment.default.clientID,
        deviceId: localStorage.getItem('__leanplum_device_id'),
        dateStyle: moment.localeData().longDateFormat('L').toUpperCase().replace(/\//g, '')
      };
      return this.POST('/api/1/users', data, success, error);
    },

    resetPasswordRequest: function resetPasswordRequest(emailAddress, success, error) {
      var data = {
        clientId: _environment.default.clientID,
        emailAddress: emailAddress
      };
      return this.POST('/api/1/resetPassword', data, success, error);
    },

    resetPassword: function resetPassword(password, token, success, error) {
      var data = {
        clientId: _environment.default.clientID,
        token: token,
        password: password
      };
      return this.POST('/api/1/updatePassword', data, success, error);
    },

    validateResetPasswordToken: function validateResetPasswordToken(token, success, error) {
      var url = '/api/1/validateResetPassword/' + token;
      return this.LOAD(url, function (result) {
        return result.isValid;
      }, error);
    },

    // shareEntryOnFacebook(journalId, entryId, facebookToken, success, error) {
    //   var data = {
    //     facebookAccessToken: facebookToken
    //   };
    //   return this.POST('/api/1/journals/' + journalId + '/entries/' + entryId + '/facebook', data, success, error);
    // },

    listEmailFrequencyTypes: function listEmailFrequencyTypes(success, error) {
      return this.LOAD('/api/1/notification-frequencies', function (result) {
        if (success) {
          return success(result.notificationFrequencies);
        }
        return result.notificationFrequencies;
      }, error);
    },
    getFollowerRelations: function getFollowerRelations(success, error) {
      return this.LOAD('/api/1/follower-relations', function (result) {
        if (success) {
          return success(result.relations);
        }
        return result.relations;
      }, error);
    },
    inviteJournalFollower: function inviteJournalFollower(journalId, invite, success, error) {
      var data = {
        firstName: invite.firstName,
        lastName: invite.lastName,
        emailAddress: invite.emailAddress,
        coOwner: invite.coOwner,
        viewEntries: invite.viewEntries,
        addEntries: invite.addEntries,
        viewMilestones: invite.viewMilestones,
        editMilestones: invite.editMilestones,
        message: invite.message,
        relationship: invite.relationship,
        emailFrequencyOnNewEvent: invite.emailFrequencyOnNewEvent,
        token: invite.token
      };
      return this.POST('/api/1/journals/' + journalId + '/invites', data, success, error);
    },
    listJournalFollowers: function listJournalFollowers(journalId, success, error) {
      return this.LOAD('/api/1/journals/' + journalId + '/followers', function (result) {
        var followers = result.followers;
        if (success) {
          return success(followers);
        }
        return followers;
      }, error);
    },
    listTimeZones: function listTimeZones(success, error) {
      return this.LOAD('/api/1/time-zones', function (result) {
        if (success) {
          return success(result.timeZones);
        }
        return result.timeZones;
      }, error);
    },
    listMeasurementSystems: function listMeasurementSystems(success, error) {
      return this.LOAD('/api/1/measurement-systems', function (result) {
        if (success) {
          return success(result.measurementSystems);
        }
        return result.measurementSystems;
      }, error);
    },
    listDateStyles: function listDateStyles(success, error) {
      return this.LOAD('/api/1/date-styles', function (result) {
        if (success) {
          return success(result.dateStyles);
        }
        return result.dateStyles;
      }, error);
    },
    saveMe: function saveMe(file, user, newPassword, success, error) {
      var data = {
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.emailAddress,
        timezone: user.timeZone ? user.timeZone.name : null,
        measurementSystem: user.measurementSystem ? user.measurementSystem.name : null,
        dateStyle: user.dateStyle ? user.dateStyle.name : null,
        emailFrequencyOnNewComment: user.emailFrequencyOnNewComment ? user.emailFrequencyOnNewComment.name : null,
        emailFrequencyOnNewEmotion: user.emailFrequencyOnNewComment ? user.emailFrequencyOnNewEmotion.name : null,
        emailWeeklySummary: user.emailWeeklySummary,
        emailMarketingOptOut: user.emailMarketingOptOut,
        emailOptOut: user.emailOptOut,
        instagramId: user.instagramId,
        instagramUsername: user.instagramUsername
      };
      if (newPassword) {
        data.newPassword = newPassword;
      }

      return this.POSTWITHFILE('/api/1/users/me', data, success, error, file);
    },
    confirmDetails: function confirmDetails(user, success, error) {
      var data = {
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.emailAddress
      };
      return this.POSTWITHFILE('/api/1/users/me', data, success, error);
    },
    updateFollowing: function updateFollowing(journal, following, success, error) {
      var data = {
        emailFrequencyOnNewEvent: following.get('emailFrequencyOnNewEvent.name') || null
      };
      return this.POST('/api/1/followings/' + journal.get('id'), data, success, error);
    },
    reorderJournalEntriesByDayMonthYear: function reorderJournalEntriesByDayMonthYear(journalId, day, month, year, orderedEntries, success, error) {
      var url = '/api/1/journals/' + journalId + '/entries/' + year + '/' + month + '/' + day + '/reorder';
      var data = {
        entries: orderedEntries
      };
      return this.POST(url, data, function (result) {
        var entries = result.entries;
        if (!entries) {
          entries = [];
        }
        if (success) {
          return success(entries);
        }
        return entries;
      }, error);
    },
    addComment: function addComment(journalId, entryId, details, basicCommenter, userToken, entryUUID, success, error) {
      var data = {
        details: details,
        uuid: entryUUID,
        name: basicCommenter,
        userToken: userToken
      };
      return this.POST('/api/1/journals/' + journalId + '/entries/' + entryId + '/comments', data, success, error);
    },
    deleteComment: function deleteComment(journalId, entryId, id, userToken, success, error) {
      return this.DELETE('/api/1/journals/' + journalId + '/entries/' + entryId + '/comments/' + id + (userToken ? "?userToken=" + userToken : ""), success, error);
    },

    addEmotion: function addEmotion(journalId, entryId, type, userToken, success, error, userId) {
      var data = {
        type: type,
        userToken: userToken
      };

      // const behavior = 'emotion added';
      // formerly sent behavior to lotame

      return this.POST('/api/1/journals/' + journalId + '/entries/' + entryId + '/emotions', data, success, error);
    },

    deleteEmotion: function deleteEmotion(journalId, entryId, id, success, error) {
      return this.DELETE('/api/1/journals/' + journalId + '/entries/' + entryId + '/emotions/' + id, success, error);
    },
    updateEntry: function updateEntry(journalId, entry, success, error) {
      var data = {
        day: entry.get('day'),
        month: entry.get('month'),
        year: entry.get('year'),
        caption: entry.get('caption'),
        pinnedTimestamp: entry.get('pinnedTimestamp'),
        children: []
      };
      var children = entry.get('children');
      $.each(children.toArray(), function (idx, child) {
        data.children.push(child.id);
      });
      return this.POST('/api/1/journals/' + journalId + '/entries/' + entry.id, data, success, error);
    },
    getRandomEntry: function getRandomEntry(entryId, uuid, success, error) {
      return this.GET('/api/1/randomEntryDate/' + entryId + "/" + uuid, success, error);
    },
    getRandomEntryByJournalId: function getRandomEntryByJournalId(journalId, success, error) {
      return this.GET('/api/1/randomEntryByJournal/' + journalId, success, error);
    },


    listGenders: function listGenders(success, error) {
      return this.LOAD('/api/1/genders', function (result) {
        if (success) {
          return success(result.genders);
        }
        return result.genders;
      }, error);
    },

    numEntries: function numEntries(journalId, success, error) {
      return this.GET('/api/1/journals/' + journalId + '/numEntries', success, error);
    },


    getSubscriptionProducts: function getSubscriptionProducts(success, error) {
      return this.LOAD('/api/store/subscriptionProducts', function (result) {
        if (success) {
          return success(result.subscriptionProducts);
        }
        return result.subscriptionProducts;
      }, error);
    },

    getSubscriptionForJournal: function getSubscriptionForJournal(journalId, success, error) {
      return this.GET('/api/store/subscription/' + journalId, function (result, data, statusText, xhr) {
        return xhr;
      }, error);
    },
    getStoreCustomer: function getStoreCustomer(success, error) {
      return this.LOAD('/api/store/storeCustomer', function (result) {
        if (success) {
          return success(result.storeCustomer);
        }
        return result.storeCustomer;
      }, error);
    },

    submitPayment: function submitPayment(journalId, productId, couponCode, stripeToken, success, error) {
      var data = {
        journalId: journalId,
        productId: productId,
        couponCode: couponCode,
        stripeToken: stripeToken
      };

      return this.POST('/api/store/submitPayment', data, success, error);
    },

    submitPremiumFeedback: function submitPremiumFeedback(feedback, success, error) {
      var data = {
        feedback: feedback
      };
      return this.POST('/api/store/premiumFeedback', data, success, error);
    },

    //voucher Zulily
    applyVoucher: function applyVoucher(inputVoucherCode, selectedJournalId, success, error) {
      //POST to /api/store/applyVoucher
      var data = {
        voucherCode: inputVoucherCode,
        journalId: selectedJournalId
      };

      return this.POST('/api/store/applyVoucher', data, success, error);
    },

    getReferralCode: function getReferralCode(success, error) {
      // api/1/referralCode
      return this.LOAD('/api/1/referralCode', function (result) {
        if (success) {
          return success(result.referralCode);
        }
        return result.referralCode;
      }, error);
    },

    submitReferrals: function submitReferrals(emailAddress, success, error) {
      //api/1/referrals
      var data = {
        emailAddress: emailAddress
      };

      return this.POST('/api/1/referrals', data, success, error);
    },
    //gift card page
    getGiftCards: function getGiftCards() {
      var url = '/api/store/giftcards';
      return this.LOAD(url);
    },

    applyCoupon: function applyCoupon(journalId, productId, giftCardId, couponCode, success, error) {
      var data = {
        journalId: journalId,
        productId: productId,
        giftcardId: giftCardId,
        couponCode: couponCode
      };

      return this.POST('/api/store/applyCoupon', data, success, error);
    },

    submitGiftCardPayment: function submitGiftCardPayment(senderEmail, recipientEmail, gCardId, couponCode, userStripeToken, success, error) {
      // POST    /api/store/submitGiftCardPayment
      var data = {
        sender: senderEmail,
        recipient: recipientEmail,
        giftCardId: gCardId,
        couponCode: couponCode,
        stripeToken: userStripeToken
      };
      return this.POST('/api/store/submitGiftCardPayment', data, success, error);
    },

    changeRenewalType: function changeRenewalType(subscriptionId, renewalType, success, error) {
      var data = {
        subscriptionId: parseInt(subscriptionId),
        type: renewalType
      };

      return this.POST('/api/store/subscription/changeRenewalType', data, success, error);
    },

    changeExistingCard: function changeExistingCard(stripeToken, subscriptionId, success, error) {
      var data = {
        stripeToken: stripeToken,
        subscriptionId: subscriptionId
      };

      return this.POST('/api/store/changeExistingCard', data, success, error);
    },

    listCoverThemes: function listCoverThemes(success, error) {
      return this.LOAD('/api/1/coverThemes', function (result) {
        if (success) {
          return success(result.coverThemes);
        }
        return result.coverThemes;
      }, error);
    },

    //search page
    searchEntriesByKeyword: function searchEntriesByKeyword(keyword, sortOrder, pageNumber, requestLength, journalId, success, error) {
      //api/1/journals/{journalId}/search
      return this.LOAD('/api/1/journals/' + journalId + '/search?term=' + keyword + "&sort=" + sortOrder + "&page=" + pageNumber + "&length=" + requestLength, function (result) {
        if (success) {
          return success(result);
        }
        return result;
      }, error);
    },

    getTips: function getTips(success, error) {
      // get cards
      var url = '/api/1/recommendedTips';
      return this.LOAD(url, function (result) {
        if (success) {
          return success(result.article);
        }
        return result.article;
      }, error);
    },

    getEmailSettings: function getEmailSettings(token, success, error) {
      return this.LOAD('/api/1/users/email-settings/' + token, function (result) {
        if (success) {
          return success(result.user);
        }
        return result.user;
      }, error);
    },

    getEmailFollowings: function getEmailFollowings(token, success, error) {
      return this.LOAD('/api/1/users/email-followings/' + token, function (result) {
        if (success) {
          return success(result.followings);
        }
        return result.followings;
      }, error);
    },

    saveEmailSettings: function saveEmailSettings(token, followings, user, success, error) {
      var data = {
        emailFrequencyOnNewComment: user.emailFrequencyOnNewComment ? user.emailFrequencyOnNewComment.name : null,
        emailFrequencyOnNewEmotion: user.emailFrequencyOnNewComment ? user.emailFrequencyOnNewEmotion.name : null,
        emailWeeklySummary: user.emailWeeklySummary,
        emailMarketingOptOut: user.emailMarketingOptOut,
        emailOptOut: user.emailOptOut,
        followings: followings
      };

      return this.POST('/api/1/users/email-settings/' + token, data, success, error);
    },

    //get subscribed milestones categories for child
    getSubscribedChannels: function getSubscribedChannels(childId, success, error) {
      ///api/1/children/{childId}/subscriptions
      return this.LOAD('/api/1/children/' + childId + '/subscriptions', function (result) {
        if (success) {
          return success(result.channelSubscriptions);
        }
        return result.channelSubscriptions;
      }, error);
    },

    getSubscribedChannelArticles: function getSubscribedChannelArticles(childId, success, error) {
      ///api/1/children/{childId}/articles
      return this.LOAD('/api/1/children/' + childId + '/articles', function (result) {
        if (success) {
          return success(result.article);
        }
        return result.article;
      }, error);
    },

    //get subscribed milestones categories for pet
    getPetSubscribedChannels: function getPetSubscribedChannels(petId, success, error) {
      ///api/1/pets/{petId}/subscriptions
      return this.LOAD('/api/1/pets/' + petId + '/subscriptions', function (result) {
        if (success) {
          return success(result.channelSubscriptions);
        }
        return result.channelSubscriptions;
      }, error);
    },

    //get completed pet milestones categories for pet
    completedChecklistItems: function completedChecklistItems(petId, success, error) {
      ///api/1/pets/{petId}/checklistItems
      return this.LOAD('/api/1/pets/' + petId + '/checklistItems', function (result) {
        if (success) {
          return success(result.completedChecklistItems);
        }
        return result.completedChecklistItems;
      }, error);
    },

    // TODO refine backend for collections

    saveEntriesToCollection: function saveEntriesToCollection(journalId, tagId, selectedEntryIds, success, error) {

      var url = '/api/1/journals/' + journalId + '/tags/' + tagId + '/addEntries';
      var data = {
        entryIds: selectedEntryIds
      };
      return this.POST(url, data, success, error);
    },

    saveReorderedCollection: function saveReorderedCollection(journalId, tagId, newTagEntryIds, success, error) {
      ///api/1/journals/{journalId}/tags/{tagId}/reorderEntries
      var url = '/api/1/journals/' + journalId + '/tags/' + tagId + '/reorderEntries';
      var data = {
        tagEntryIds: newTagEntryIds
      };
      return this.POST(url, data, success, error);
    },

    updateCollectionInfo: function updateCollectionInfo(journalId, tagId, collection, success, error) {
      //POST    /api/1/journals/{journalId}/tags/{tagId}     Api1.updateTag
      var url = '/api/1/journals/' + journalId + '/tags/' + tagId;
      var data = {
        name: collection.get('name'),
        description: collection.get('description'),
        //this can be set to false in future
        isPublic: collection.get('isPublic'),
        themeId: 1
      };
      return this.POST(url, data, success, error);
    },

    deleteCollection: function deleteCollection(journalId, selectedTagIds, success, error) {
      // /api/1/journals/1/deleteTags=

      var url = '/api/1/journals/' + journalId + '/deleteTags';
      var data = {
        tagIds: selectedTagIds
      };
      return this.POST(url, data, success, error);
    },
    saveCollectionsOrder: function saveCollectionsOrder(journalId, newTagIds, success, error) {
      ///api/1/journals/{journalId}/reorderTags
      var url = '/api/1/journals/' + journalId + '/reorderTags';
      var data = {
        tagIds: newTagIds
      };
      return this.POST(url, data, success, error);
    },

    pinEntryToCollection: function pinEntryToCollection(pinnedTagEntryId, success, error) {
      ///api/1/tagEntries/{tagEntryId}/pin
      var url = '/api/1/tagEntries/' + pinnedTagEntryId + '/pin';
      var data = {
        tagEntryId: pinnedTagEntryId
      };
      return this.POST(url, data, success, error);
    },

    removeEntriesFromCollection: function removeEntriesFromCollection(journalId, tagId, selectedEntryIds, success, error) {
      var url = '/api/1/journals/' + journalId + '/tags/' + tagId + '/removeEntries';
      var data = {
        tagEntryIds: selectedEntryIds
      };
      return this.POST(url, data, success, error);
    },

    logProductView: function logProductView(journalId, productId, success, error) {
      var data = {
        journalId: journalId,
        productId: productId
      };

      return this.POST('/api/1/logProductView', data, success, error);
    },

    exportJournal: function exportJournal(journalId, exportStartDate, exportEndDate, success, error) {
      var data = {
        startDate: exportStartDate,
        endDate: exportEndDate
      };
      return this.POST('/api/1/journals/' + journalId + '/export?startDate=' + exportStartDate + '&endDate=' + exportEndDate, data, success, error, function (result) {
        if (success) {
          return success(result.status);
        }
        return result.status;
      }, error);
    },
    updateJournalFollower: function updateJournalFollower(journalId, follower, success, error) {
      var data = {
        addEntries: follower.get('addEntries'),
        viewMilestones: follower.get('viewMilestones'),
        editMilestones: follower.get('editMilestones'),
        coOwner: follower.get('coOwner'),
        relationship: follower.get('relationship') ? follower.get('relationship.name') : null
      };
      var url = '/api/1/journals/' + journalId + '/' + (follower.pending ? 'invites' : 'followers') + '/' + follower.id;
      return this.POST(url, data, success, error);
    },
    getFollower: function getFollower(journalId, followerId, success, error) {
      return this.GET('/api/1/journals/' + journalId + '/followers/' + followerId, success, error);
    },
    exchangeToken: function exchangeToken(oneTimeToken, success, error) {
      var data = {
        clientId: _environment.default.clientID,
        oneTimeToken: oneTimeToken
      };
      return this.POST('/api/1/exchangeToken', data, success, error);
    },
    oneTimeToken: function oneTimeToken(success, error) {
      return this.GET('/api/1/oneTimeToken', success, error);
    },
    getInvite: function getInvite(token, success, error) {
      return this.GET('/api/1/invites/' + token, function (result) {
        if (success) success(result);
      }, function (response) {
        if (error) error(response);
      });
    },
    acceptInvite: function acceptInvite(data, success, error) {
      return this.POST('/api/1/invites', data, success, error);
    },
    findUserByEntryAndToken: function findUserByEntryAndToken(entryId, token, success, error) {
      return this.GET('/api/1/userByEntryAndToken/' + entryId + '/' + token, success, error);
    },


    // Stripe API Calls

    createCheckoutSession: function createCheckoutSession(priceId, coupon, success, error) {
      var data = {
        priceId: priceId,
        coupon: coupon
      };
      // console.log(data);
      this.POST('/api/1/premiumSubscriptions/checkout-session', data, success, error);
    },
    createCheckoutSessionCustom: function createCheckoutSessionCustom(priceId, coupon, successURL, cancelURL, success, error) {
      var data = {
        priceId: priceId,
        coupon: coupon,
        successUrl: successURL,
        cancelUrl: cancelURL
      };
      // console.log(data);
      this.POST('/api/1/premiumSubscriptions/checkout-session', data, success, error);
    },
    billingSession: function billingSession(success, error) {
      return this.LOAD('/api/1/premiumSubscriptions/billing-session', function (result) {
        return result;
      }, error);
    },


    submitSPayment: function submitSPayment(sessionId, priceId, success, error) {
      var data = {
        planId: priceId,
        sessionId: sessionId
      };
      return this.POST('/api/1/premiumSubscription', data, success, error);
    },

    submitOrSetupPayment: function submitOrSetupPayment(sessionId, priceId, success, error) {
      var data = {
        planId: priceId,
        sessionId: sessionId
      };
      return this.POST('/api/2/premiumSubscription', data, success, error);
    },

    getActiveSubscription: function getActiveSubscription(success, error) {
      return this.LOAD('/api/1/premiumSubscription', function (result) {
        return result;
      }, error);
    },

    getActiveAndUpcomingSubscriptions: function getActiveAndUpcomingSubscriptions(success, error) {
      return this.LOAD('/api/2/premiumSubscription', function (result) {
        return result;
      }, error);
    },

    getUserSubscriptions: function getUserSubscriptions(success, error) {
      return this.LOAD('/api/1/premiumSubscriptions', function (result) {
        return result;
      }, error);
    },

    getEligibleFollowers: function getEligibleFollowers(success, error) {
      return this.LOAD('/api/1/premiumSubscription/eligible-followers', function (result) {
        return result;
      }, error);
    },

    getJournalStats: function getJournalStats(success, error) {
      return this.GET('/api/1/storageSubscription/eligibility', success, error);
    },

    setSubscriptionSeat: function setSubscriptionSeat(followerUserId, success, error) {
      return this.PUT('/api/1/premiumSubscription/' + followerUserId + '/seat', function (result) {
        return result;
      }, error);
    },

    removeSubscriptionSeat: function removeSubscriptionSeat(followerUserId, success, error) {
      return this.DELETE('/api/1/premiumSubscription/' + followerUserId + '/seat', success, error);
    },

    hasSubscriptionSeat: function hasSubscriptionSeat() {
      // return this.GET('/api/1/premiumSubscription/seats', function(result){
      //   console.log("returning");
      //   return result;
      // }, function(data, textStatus, jqXHR){
      //   if ( data.responseJSON && 'No active TB+ subscription found.' === data.responseJSON.detailedMessage) {
      //   }
      //   return false;
      // }, false); // don't bubble
      var url = _environment.default.apiEndPoint + '/api/1/premiumSubscription/seats';
      return new Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(data) {
            resolve(data);
          },
          error: function error(_error) {
            reject(_error);
          }
        });
      });
    },

    getStripeCustomer: function getStripeCustomer(success, error) {
      return this.LOAD('/api/1/premiumSubscriptions/storeCustomer', function (result) {
        return result;
      }, error);
    },

    getPaymentHistory: function getPaymentHistory(success, error) {
      return this.LOAD('/api/1/premiumSubscriptions/paymentHistory', function (result) {
        return result;
      }, error);
    },

    hasMemoriesAccess: function hasMemoriesAccess(journalId, success, error) {
      return this.GET('/api/1/premiumSubscription/' + journalId + '/hasMemoriesAccess', success, error);
    },

    // User Limits
    getLimits: function getLimits(success, error) {
      return this.GET('/api/1/feature-limits', success, error);
    },
    getMemoriesLimit: function getMemoriesLimit(success, error) {
      return this.GET('/api/1/users/me/limits/memories', success, error);
    },

    // Location API

    submitLocation: function submitLocation(data, success, error) {
      return this.POST('/api/1/users/location', data, success, error);
    },

    getLocation: function getLocation() {
      var url = _environment.default.apiEndPoint + '/api/1/users/location';
      return Ember.$.ajax({
        url: url,
        global: false,
        success: function success(data, statusText, xhr) {
          console.log("Success");
          return data;
        },
        error: function error() {}
      });
    },

    allowGeoLocation: function allowGeoLocation(success, error) {
      return this.POST('/api/1/users/geolocation-allowed', success, error);
    },

    getIsGeoLocation: function getIsGeoLocation(success, error) {
      return this.LOAD('/api/1/users/geolocation-allowed', function (result) {
        return result;
      }, error);
    },
    // Ads API
    getAdsStatus: function getAdsStatus(journalId, success, error) {
      console.log(journalId);
      if (journalId) {
        return this.GET('/api/1/ads?journalId=' + journalId, function (result) {
          return result;
        }, error);
      } else {
        return this.GET('/api/1/ads', function (result) {
          return result;
        }, error);
      }
    },

    redeemGiftCard: function redeemGiftCard(giftCode, success, error) {
      var data = {
        giftCode: giftCode
      };
      return this.POST('/api/1/gifts/redeem', data, success, error);
    }

  });
});