define("tinybeans-frontend/controllers/main/entries/view", ["exports", "tinybeans-frontend/controllers/base-controller", "tinybeans-frontend/mixins/commons-mixin", "lodash/lodash"], function (exports, _baseController, _commonsMixin, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PREVDAY = -1,
      NEXTDAY = 1;
  /* jshint unused:false */
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    needs: ["main"],
    queryParams: ["lovePopup", "userToken", "from"],
    error: {},

    init: function init() {
      var _this = this;

      this._super();
      Ember.run.schedule("afterRender", this, function () {
        if (_this.get("shouldShowAds")) {}
        // googletag.cmd.push(function() {
        //   googletag.display("div-gpt-ad-1522951024798-0");
        // });


        // this current only works once, need fix to make it trigger on page load
        var diffJournal = _this.get("currentJournalDiffEntryJournal");
        var journalId = _this.get("model.journalId");
        if (diffJournal) {
          _this.send("resetCurrentJournalAndReload", journalId);
        }
      });
    },
    didRender: function didRender() {
      this._super();
    },


    updateFancyBox: function () {
      Ember.$(".fancybox").fancybox({
        helpers: {
          title: {
            type: "outside"
          },
          thumbs: {
            width: 50,
            height: 50
          }
        }
      });
    }.observes("model.entries", "model.entries.@each"),

    initialize: function initialize() {
      Ember.run.next(function () {
        var key = "refer-friend-visited";
        var referFriendCookie = document.cookie.match(new RegExp(key + "=([^;]+)")) || [];
        var referFriendCookieFound = decodeURIComponent(referFriendCookie[1] || "");

        if (!referFriendCookieFound) {
          Ember.$("#refer-friend-ribbon-container").show();
        }
      });
    },


    dayMonthLabel: function () {
      var date = moment({
        day: this.get("model.day"),
        month: this.get("model.month") - 1,
        year: this.get("model.year")
      });
      return date.format("D") + " " + date.format("MMM");
    }.property("model.day", "model.month", "model.year"),

    dayMonthYearLabel: Ember.computed("model.day", "model.month", "model.year", function () {
      var model = this.get("model");
      var date = moment({
        day: model.day,
        month: model.month - 1,
        year: model.year
      });
      var yearLabel = date.format("YYYY");
      var monthLabel = date.format("MMM");
      var dayLabel = date.format("D");
      var dayMonthYearString = monthLabel + " " + dayLabel;
      var dayMonthYearMobileString = dayMonthYearString + " " + yearLabel;

      return {
        year: yearLabel,
        month: monthLabel,
        day: dayLabel,
        string: dayMonthYearString,
        mobileString: dayMonthYearMobileString
      };
    }),

    getDate: function getDate(currentDate, dayMonthYear, whichDay) {
      var date = new Date(currentDate);
      date.setDate(date.getDate() + whichDay);

      switch (dayMonthYear) {
        case "year":
          return date.getFullYear();
        case "month":
          return date.getMonth() + 1;
        case "day":
          return date.getDate();
      }
    },


    currentDate: Ember.computed("model.day", "model.month", "model.year", function () {
      return new Date(this.get("model.year"), this.get("model.month") - 1, this.get("model.day"));
    }),

    refreshAds: function refreshAds() {
      // console.log("refresh ads");
      // googletag.cmd.push(function() {
      //     googletag.pubads().refresh([slot2]); //slot 2
      //     googletag.pubads().refresh([slot3]); //slot 3
      //     googletag.pubads().refresh([slot4]); //slot 4
      // });
    },


    meAsFollower: Ember.computed("model.followings", function () {
      var _this2 = this;

      if (!this.get("model.followings")) {
        return null;
      }
      return _lodash.default.find(this.get("model.followings").toArray(), function (following) {
        return following.get("journal.id") === _this2.get("model.journal.id");
      });
    }),

    isMyJournal: Ember.computed("model.journal", function () {
      if (!this.get("model.me")) {
        return null;
      }
      return this.get("model.me") && this.get("model.me.id") === this.get("model.journal.user.id");
    }),

    hasAccessToJournal: Ember.computed("model.followings", "model.journal", function () {
      return this.get("isMyJournal") || this.get("meAsFollower");
    }),

    currentJournalDiffEntryJournal: Ember.computed("model.entry", "model.currentJournalId", function () {
      return this.get("model.entry.journal.id") !== this.get("model.currentJournalId");
    }),

    entryJournalName: Ember.computed("model.entry", function () {
      return this.get("model.entry.journal.title");
    }),

    actions: {
      // refreshAds() {
      //   if (googletag.pubads && Ember.$("#div-gpt-ad-1477419963449-2").html()) {
      //     googletag.pubads().refresh([slot3]);
      //   }

      //   if (googletag.pubads && Ember.$("#div-gpt-ad-1477419963449-3").html()) {
      //     googletag.pubads().refresh([slot4]);
      //   }

      //   if (googletag.pubads && Ember.$("#div-gpt-ad-1477420034962-1").html()) {
      //     googletag.pubads().refresh([slot6]);
      //   }
      // },

      addMoment: function addMoment() {
        this.transitionToRoute("main.journals.add-moment", this.get("model.journalId"), {
          queryParams: {
            year: this.get("model.year"),
            month: this.model.get("month"),
            day: this.get("model.day")
          }
        }).then(function (route) {
          route.set("prevRoute", "view");
        });
      },
      showPrevDay: function showPrevDay() {
        Ember.$(window).scrollTop(0);
        // this.send("refreshAds");

        this.set(".string", "Loading ...");
        Ember.$(".entry-add").css("display", "none");

        var currentDate = this.get("currentDate");
        var prevDay = this.getDate(currentDate, "day", PREVDAY);
        var prevMonth = this.getDate(currentDate, "month", PREVDAY);
        var prevYear = this.getDate(currentDate, "year", PREVDAY);

        this.transitionToRoute("main.entries.list", this.get("model.journalId"), prevYear, prevMonth, prevDay);
      },
      showNextDay: function showNextDay() {
        Ember.$(window).scrollTop(0);
        // this.send("refreshAds");
        this.set(".string", "Loading ...");
        Ember.$(".entry-add").css("display", "none");

        var currentDate = this.get("currentDate");
        var nextDay = this.getDate(currentDate, "day", NEXTDAY);
        var nextMonth = this.getDate(currentDate, "month", NEXTDAY);
        var nextYear = this.getDate(currentDate, "year", NEXTDAY);

        this.transitionToRoute("main.entries.list", this.get("model.journalId"), nextYear, nextMonth, nextDay);
      },


      showMonth: function showMonth() {
        Ember.$(window).scrollTop(0);
        var journalId = this.get("model.journalId");

        this.transitionToRoute("main.journals.month", journalId, this.get("model.year"), this.get("model.month"));
      },

      deleteEntry: function deleteEntry(entry) {
        var entries = this.get("model.entries");
        entries.removeObject(entry);
        entry.destroyRecord();
      },
      addComment: function addComment(entry, details, basicCommenter) {
        var _this3 = this;

        var userToken = this.get("userToken");
        this.API.addComment(this.get("model.journalId"), entry.id, details, basicCommenter, userToken, entry.get("uuid"), function (data) {
          var comment = _this3.store.createRecord("comment", data.comment);
          if (_this3.get("userToken")) {
            comment.canDelete = true;
          }
          entry.get("comments").addObject(comment);
        }, function () {
          _this3.send("displayError", _this3.get("i18n").t("global.error.unknown"));
        });
      },
      deleteComment: function deleteComment(entry, comment) {
        var _this4 = this;

        var userToken = this.get("userToken");
        this.API.deleteComment(this.get("model.journalId"), entry.id, comment.get("id"), userToken ? userToken : null, function () {
          entry.get("comments").removeObject(comment);
        }, function () {
          _this4.send("displayError", _this4.get("i18n").t("global.error.unknown"));
        });
      },
      updateEntry: function updateEntry(entry) {
        this.API.updateEntry(this.get("model.journalId"), entry, function () {}, this);
      },
      toggleEmotion: function toggleEmotion(entry, emotion) {
        var _this5 = this;

        var userToken = this.get("userToken");
        var userId = this.get("model.me").id;

        if (emotion) {
          this.API.deleteEmotion(this.get("model.journalId"), entry.id, emotion.id, function () {
            entry.get("emotions").removeObject(emotion);
          }, this);
        } else {
          this.API.addEmotion(this.get("model.journalId"), entry.id, "LOVE", userToken, function (data) {
            var lastNameInitial = data.emotion.user.lastName.length >= 1 ? data.emotion.user.lastName.substring(0, 1) : "";
            data.emotion.user.fullName = data.emotion.user.firstName + " " + lastNameInitial;
            emotion = _this5.store.createRecord("emotion", data.emotion);
            emotion.set("userId", _this5.get("model.me"));
            entry.get("emotions").pushObject(emotion);
          }, function () {
            _this5.send("displayError", _this5.get("i18n").t("global.error.unknown"));
          }, userId);
        }
      },
      displayError: function displayError(error) {
        this.set("error.global", error);
        this.send("gotoTopOfPage");
      },
      gotoDayView: function gotoDayView(journalId, year, month, day) {
        this.transitionToRoute("main.entries.list", journalId, year, month, day);
      },
      gotoReferFriend: function gotoReferFriend() {
        var eventAction = "click_refer_friend_ribbon";
        var label = "goto-button-from-entry-view";

        this.GTM.trackEvent({
          eventCategory: "checkout_refer_friend_page",
          eventAction: eventAction,
          eventLabel: label
        });
        this.transitionToRoute("main.refer-friend.show");
      },
      closeReferFriendBanner: function closeReferFriendBanner() {
        Ember.$("#refer-friend-ribbon-container").hide();
        writeCookie("refer-friend-visited", true, 10);

        function writeCookie(key, value, expiration) {
          var expires = expiration ? "" : "; expires=" + new Date(expiration).toUTCString();
          //  var secure  = !!this._secureCookies ? ';secure' : '';
          var path = ";path=/";
          document.cookie = key + "=" + encodeURIComponent(value) + expires + path;
        }

        var eventAction = "close_refer_friend_ribbon";
        var label = "close-button-from-entry-view";

        this.GTM.trackEvent({
          eventCategory: "close_refer_friend_page",
          eventAction: eventAction,
          eventLabel: label
        });
      },


      gotoTopOfPage: function gotoTopOfPage() {
        Ember.$("html, body").animate({
          scrollTop: 0
        }, "slow");
      },

      gotoPremium: function gotoPremium() {
        var self = this;
        var journal = self.get("model.journal");
        var journalId = journal.id;

        self.send("gotoTopOfPage");

        self.transitionToRoute("main.subscription-products.list", {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      },

      gotoPremiumFromModal: function gotoPremiumFromModal() {
        var self = this;
        Ember.$(".modal").modal("hide");
        self.send("gotoPremium");
      },

      gotoLogin: function gotoLogin() {
        this.transitionToRoute("login");
      },
      resetCurrentJournalAndReload: function resetCurrentJournalAndReload(journalId) {
        var self = this;
        self.send("swtichJournalRedirectBack", journalId);
      }
    }
  });
});