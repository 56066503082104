define('tinybeans-frontend/components/main/update-email', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    submitting: false,

    init: function init() {
      var _this = this;

      this._super();
      Ember.run.scheduleOnce('afterRender', function () {
        $('.close').click(function () {
          _this.GTM.trackEvent({
            'eventCategory': 'complete_user_profile',
            'eventAction': 'close_email_modal',
            'eventLabel': 'close_button'
          });
        });
      });
    },


    me: Ember.computed('model', function () {
      return this.get('model.me') || this.get('model.user');
    }),

    actions: {
      close: function close() {
        this.GTM.trackEvent({
          'eventCategory': 'complete_user_profile',
          'eventAction': 'close_email_modal'
        });
      },
      save: function save() {
        var _this2 = this;

        var me = this.get('me');
        if (!/.+@.+\..+/.test(me.get('emailAddress'))) {
          this.set('error', "Please enter a valid email Address");
          return;
        }

        this.GTM.trackEvent({
          'eventCategory': 'complete_user_profile',
          'eventAction': 'click_save_email_button'
        });

        this.set('submitting', true);
        this.API.saveMe(null, me.serialize(), null, function () {
          _this2.set('submitting', false);
          $('.modal').modal('hide');
          _this2.GTM.trackEvent({
            'eventCategory': 'complete_user_profile',
            'eventAction': 'email_save_successful'
          });
        }, function (xhr) {
          _this2.set('submitting', false);
          var error = '';
          if (xhr.responseJSON.errors.length) {
            if (xhr.responseJSON.errors[0].message === "This email is already used") {
              error = "This email is already used. Please login to your account using your email and password instead";
            } else {
              error = xhr.responseJSON.errors[0].message;
            }
          } else {
            error = "Couldn't update your email address please contact support";
          }
          _this2.set('error', error);
        });
      }
    }
  });
});