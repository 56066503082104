define("tinybeans-frontend/helpers/emoji", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getEmoji = getEmoji;
  var emojiMap = exports.emojiMap = {
    "emoji_haha": "😀",
    "emoji_kisses": "😘",
    "emoji_sad": "😢",
    "emoji_love": "😍",
    "emoji_proud": "👏",
    "emoji_laugh_tears": "😂"
  };
  function getEmoji(key) {
    return emojiMap[key];
  }
});