define('tinybeans-frontend/controllers/main/children/channel', ['exports', 'tinybeans-frontend/controllers/base-controller', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseController, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    queryParams: ['setup'],

    backgroundStyle: function () {
      if (this.get('child.avatars.l')) {
        return "background-image: url('" + this.get('model.child.avatars.l') + "'); background-size: cover; background-position: center;";
      } else {
        return "background-image: url('" + "https://tinybeans-public.s3-us-west-2.amazonaws.com/images/avatar-child-l.png" + "'); background-size: cover; background-position: center;"; //'images/generic-baby_nobackground.png'
      }
    }.property('model.child'),

    subscribedChannelArticles: Ember.computed('property', function () {
      var _this = this;

      var childJSON = this.get('model.child').toJSON();
      var articles = this.get('model.subscribedChannelArticles').toArray();
      _lodash.default.each(articles, function (article) {
        if (!article.deleted) {
          if (article.content && article.content.length > 100) {
            article.intro = article.content.substring(0, 100) + '...';
          }
          article.title = _this.replaceChildProperties(article.title, childJSON);
          article.content = _this.replaceChildProperties(article.content, childJSON);
          article.contentUrl = _this.replaceChildProperties(article.contentUrl, childJSON);
        }
      });
      return _lodash.default.filter(articles, function (article) {
        return !article.deleted;
      });
    }),

    replaceChildProperties: function replaceChildProperties(str, child) {
      if (str) {
        for (var propertyName in child) {
          var _search = new RegExp('{' + propertyName + '}', 'gi');
          var _replace = child[propertyName];
          str = str.replace(_search, _replace);
        }

        // Also replace {month}
        var search = new RegExp('{month}', 'gi');
        var replace = child.ageInMonthsOnly;
        str = str.replace(search, replace);
      }
      return str;
    },


    actions: {
      trackOpenArticle: function trackOpenArticle() {
        _tracker.Tracker.trackEvent({
          category: _tracker.Child.category,
          screen: _tracker.Child.screen,
          action: _tracker.Child.openRecommendedArticle
        });
      }
    }
  });
});