define('tinybeans-frontend/models/feature', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    URL: (0, _attr.default)('string'),
    scopeMap: (0, _attr.default)(),
    dateCreated: (0, _attr.default)('date')
  });
});