define('tinybeans-frontend/components/main/subscriptions/journal-subscription', ['exports', 'lodash/lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',

    canRenew: Ember.computed('subscription', function () {
      var _this = this;

      var hasPremiumSubscription = _lodash.default.any(this.get('subscriptions').toArray(), function (subscription) {
        return subscription.get('expiryDate') > _this.get('subscription.expiryDate');
      });

      return !hasPremiumSubscription && this.get('subscription.paymentTransaction.user.id') == this.get('model.me.id');
    }),

    canUpgrade: Ember.computed('subscription', function () {
      var _this2 = this;

      if (this.get('subscription.subscriptionProduct.code') !== 'PRE1') {
        return false;
      }

      var subscriptions = this.get('subscriptions').toArray();

      var hasPremiumSubscription = _lodash.default.any(subscriptions, function (subscription) {
        return subscription.get('expiryDate') > _this2.get('subscription.expiryDate');
      });
      return !hasPremiumSubscription && this.get('journal') && this.get('subscription.paymentTransaction.user.id') == this.get('model.me.id');
    }),

    canPause: Ember.computed('journal', 'subscription', function () {
      return this.get('journal') && this.get('subscription.paymentTransaction') && this.get('subscription.paymentTransaction.user.id') == this.get('model.me.id');
    }),

    /*
    yearlySubscriptionProduct: Ember.computed('model.subscriptionProducts', function() {
      return _.find(this.get('model.subscriptionProducts').toArray(), product => {
        return product.code === 'PRE12';
      });
    }),*/

    isLifetimeSubscription: Ember.computed('subscription', function () {
      return this.get('subscription.subscriptionProduct.code') === 'LIFETIME';
    }),

    actions: {
      changeRenewalType: function changeRenewalType(subscription, type) {
        this.changeRenewalType(subscription, type);
      },
      upgradeToYearly: function upgradeToYearly(journal) {
        this.upgradeToYearly(journal, this.get('subscription'));
      }
    }
  });
});