define('tinybeans-frontend/routes/index', ['exports', 'tinybeans-frontend/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    readCookie: function readCookie(name) {
      return (name = new RegExp('(?:^|;\\s*)' + ('' + name).replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + '=([^;]*)').exec(document.cookie)) && name[1];
    },

    beforeModel: function beforeModel() {
      var self = this;
      if (!self.get('session.isAuthenticated')) {
        // Check for a facebookAccessToken cookie
        var facebookAccessToken = self.readCookie('signup-facebookAccessToken');
        if (facebookAccessToken) {
          self.get('session').authenticate('authenticator:facebook').then(function () {
            // Detect if the user has a journal and create/redirect if so
            if (self.get('session.didCreateUser')) {
              self.transitionTo('main.children.add', { queryParams: { setup: true } });
            } else {
              self.transitionTo('main.journals.home');
            }
          });
        } else {
          // Check for a rememberme cookie from the play framework
          // var playCookie = self.readCookie('rememberme');
          // if (playCookie) {
          //   self.get('session').authenticate('authenticator:play-cookie').then(function() {
          //     self.transitionTo('main.journals.home');
          //   });
          // }
          // else {
          self.transitionTo('login');
          // }
        }
      } else {
        self.transitionTo('main.journals.home');
      }
    }
  });
});