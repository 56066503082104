define('tinybeans-frontend/components/main/journals/follower-list-edit', ['exports', 'lodash/lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    relationship: Ember.computed('follower', function () {
      return this.get('follower.relationship.name');
    }),

    editingEnabled: Ember.computed('editingDisabled', function () {
      return !this.get('editingDisabled');
    }),

    followerTitle: Ember.computed('property', function () {
      var user = this.get('follower.user');
      return user.get('fullName') + ' (' + user.get('emailAddress') + ')';
    }),

    followerIsMe: Ember.computed('follower', function () {
      return this.get('follower.user.id') === this.get('model.user.id');
    }),

    followerIsOwner: Ember.computed('follower', 'model.journal', function () {
      return this.get('follower.user.id') === this.get('model.journal.user.id');
    }),

    coOwnerObserver: Ember.observer('follower.coOwner', function () {
      if (this.get('follower.coOwner')) {
        this.set('follower.addEntries', true);
        this.set('follower.editMilestones', true);
        this.set('follower.viewMilestones', true);
      }
    }),

    disabled: Ember.computed('editingDisabled', 'followerIsMe', 'followerIsOwner', 'follower.coOwner', function () {
      return this.get('editingDisabled') || this.get('followerIsMe') || this.get('followerIsOwner') || this.get('follower.coOwner');
    }),

    actions: {
      selectRelationship: function selectRelationship(value) {
        var relationship = _lodash.default.find(this.get('model.relations').toArray(), function (relation) {
          return relation.name === value;
        });
        if (this.get('follower.relationship')) {
          this.set('follower.relationship.name', value);
          this.set('follower.relationship.label', relationship.label);
        }
      },
      close: function close() {
        this.sendAction('close');
      },
      saveFollower: function saveFollower() {
        if (!this.get('follower.relationship.name')) {
          this.set('error', { relationship: 'Please select relationship' });
          return;
        }

        this.saveFollower(this.get('follower'));
      },
      remove: function remove() {
        var _this = this;

        bootbox.dialog({
          title: 'Remove follower',
          message: 'Are you sure?',
          buttons: {
            ok: {
              label: 'OK',
              className: "btn-secondary",
              callback: function callback() {
                _this.removeFollower();
              }
            },
            cancel: {
              label: 'Cancel',
              className: "btn-primary"
            }
          }
        });
      }
    }
  });
});