define('tinybeans-frontend/routes/main/refer-friend/show', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    error: {},
    model: function model() {
      return Ember.RSVP.hash({
        me: this.store.find('user', 'me')
      });
    },


    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var referralUrl = model.me.get("referralUrl");
      var me = model.me;
      var referralMessageTwitter = "Join Tinybeans using this code and we’ll both get a little gift.";
      controller.set('me', me);
      controller.set('referralCode', model.me.get("referralCode"));
      controller.set('referralUrl', referralUrl);
      controller.set('referralFacebookUrl', "http://www.facebook.com/sharer/sharer.php?u=" + referralUrl);
      controller.set('emailReferralUrl', "mailto:?subject=Check%20this%20out%21&body=Hey%21%0A%0ATinybeans%20is%20the%20best%20way%20for%20you%20to%20share%20photos%20of%20your%20children%20and%20their%20milestones%20with%20family.%20It%20is%20also%20the%20easiest%20way%20to%20keep%20a%20record%20of%20the%20special%20moments%20you%20never%20want%20to%20forget.%20I%20thought%20you%27d%20like%20it.%0A%0AIt%27s%20a%20free%20app%2C%20but%20we%20will%20both%20get%20a%20month%20of%20Premium%20%28+%20extra%20features%29%20after%20you%20join.%0A%0ACheck%20it%20out%20here%3A%0A" + referralUrl);
      controller.set('emailScreenshot', "mailto:info@tinybeans.com?subject=Check%20out%20my%20screenshot");
      controller.set('referralTwitterUrl', "https://twitter.com/intent/tweet?original_referer=" + referralUrl + "&text=" + referralMessageTwitter + "&tw_p=tweetbutton&url=" + referralUrl);
      controller.set('submitting', false);
      controller.set('tempEmailAddress', null);

      //random A/B version
      // controller.set('showVersionA', Math.random() >= 0.5);
    },

    renderTemplate: function renderTemplate() {
      var _this = this;

      this.render('main/refer-friend/show', {
        controller: 'main.refer-friend.show'
      });

      Ember.run.next(function () {
        Ember.$(window).scrollTop(0);

        //google tracking
        var self = _this;

        if (Ember.$('#VB').length) {
          console.log("VB");
          self.GTM.trackEvent({
            'eventCategory': 'showReferFriendPageVB',
            'eventAction': 'load',
            'eventLabel': 'VB'
          });
        }

        Ember.$('.VB').click(function () {
          var elementId = $(this).attr("id") || "btnVB";
          // console.log(elementId);
          self.GTM.trackEvent({
            'eventCategory': 'showReferFriendVB',
            'eventAction': 'click',
            'eventLabel': elementId
          });
        });
      }, 200);
    },

    actions: {
      reloadPage: function reloadPage() {
        this.refresh();
      }
    }
  });
});