define('tinybeans-frontend/routes/main/pets/add', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Pet.category,
    screen: _tracker.Pet.add.screen,

    model: function model(params) {
      var journalId = params.journal_id;
      return Ember.RSVP.hash({
        journalId: journalId,
        me: this.store.find('user', 'me'),
        journal: this.store.find('journal', journalId),
        pet: this.store.createRecord('pet'),
        followings: this.modelFor('main').followings,
        journals: this.store.findAll('journal')
      });
    },

    afterModel: function afterModel(model) {
      //model.child.set('name', model.me.get('name'));
      model.avatar = null;
    },

    actions: {
      reloadPage: function reloadPage() {
        this.refresh();
      }
    }

  });
});