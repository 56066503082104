define('tinybeans-frontend/controllers/main/collections/edit', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {

    saveButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? 'Please wait ...' : 'Add moments to album';
    }.property('submitting'),

    collectionEnabled: Ember.computed('property', function () {
      return this.globals.photoCollectionEnabled(this.get('model.journal'));
    }),

    actions: {
      showDate: function showDate(journalId, year, month) {
        // if (googletag.pubads && Ember.$("#div-gpt-ad-1477419963449-0").html() != "") {
        //   googletag.pubads().refresh([slot1]);
        // }
        // if (googletag.pubads && Ember.$("#div-gpt-ad-1477419963449-1").html() != "") {
        //   googletag.pubads().refresh([slot2]);
        // }
        // if (googletag.pubads && Ember.$("#div-gpt-ad-1477420034962-0").html() != "") {
        //   googletag.pubads().refresh([slot5]);
        // }
        if (Number(this.model.month) === Number(month) && Number(this.model.year) === Number(year)) {
          return; // Don't transition to current page
        }
        Ember.$('.spin').css('visibility', 'visible');
        this.transitionToRoute('main.collections.edit', journalId, this.get('model.collection.path'), year, month);
      },

      selectEntry: function selectEntry(entry) {
        var entryIcon = "#" + entry.id + " span";
        var entryId = "#" + entry.id;

        var exitingText = Ember.$('#selectedEntryId').text();
        var hasSelected = Ember.$(entryId).hasClass('selected');
        //  let existingNumOfSelectedMoments = parseInt(Ember.$('#numSelectedMoments').text());

        if (hasSelected) {
          Ember.$(entryId).removeClass('selected');
          Ember.$(entryIcon).removeClass('glyphicon-ok');

          if (exitingText.indexOf(entry.id) != -1) {
            var newString = "#" + entry.id;
            var newText = exitingText.replace(newString, '');
            Ember.$('#selectedEntryId').text(newText);
            var selectedIds = Ember.$('#selectedEntryId').text();
            var selectedIdsArray = selectedIds.split("#");
            var selectedMomentsLength = selectedIdsArray.length - 1;
            this.set('numSelectedMoments', selectedMomentsLength);
          }
        } else {
          Ember.$(entryIcon).addClass('glyphicon-ok');
          Ember.$(entryId).addClass('selected');
          if (exitingText.indexOf(entry.id) == -1) {
            var _newText = exitingText + "#" + entry.id;
            Ember.$('#selectedEntryId').text(_newText);
            var _selectedIds = Ember.$('#selectedEntryId').text();
            var _selectedIdsArray = _selectedIds.split("#");
            var _selectedMomentsLength = _selectedIdsArray.length - 1;
            this.set('numSelectedMoments', _selectedMomentsLength);
          }
        }
      },

      save: function save() {
        var _this = this;

        var selectedIds = Ember.$('#selectedEntryId').text().split("#");
        var idLength = selectedIds.length - 1;
        var entryIds = selectedIds.splice(1, idLength);
        var collection = this.get('model.collection');

        var journalId = this.get('model.journalId');

        var selectedEntryIds = [];
        Ember.$.each(entryIds, function (idx, entryId) {
          entryId = parseInt(entryId);
          selectedEntryIds.push(entryId);
        });

        this.set('submitting', true);
        this.API.saveEntriesToCollection(journalId, collection.get('id'), selectedEntryIds, function () {
          _this.set('submitting', false);
          _this.get('flashMessages').clearMessages();
          _this.get('flashMessages').add({
            message: 'New moments saved',
            timeout: 3000,
            showMessage: true,
            type: 'alert alert-success',
            glyphiconClass: 'glyphicon glyphicon-ok'
          });

          _this.send("reloadApplicationRoute");
          _this.transitionToRoute('main.collections.show', journalId, collection.get('path'));
        }, function () {
          this.set('submitting', false);
          bootbox.dialog({
            title: "Oops",
            message: "Moments couldn't be added to album.",
            buttons: {
              cancel: {
                label: "Cancel",
                className: "btn-default"
              }
            }
          });
        });
        this.send('track', 'save');
      },

      showPrevMonth: function showPrevMonth() {
        this.transitionToRoute('main.collections.edit', this.get('model.journalId'), this.get('model.collection.path'), this.get('prevYear'), this.get('prevMonth'));
      },
      showNextMonth: function showNextMonth() {
        this.transitionToRoute('main.collections.edit', this.get('model.journalId'), this.get('model.collection.path'), this.get('nextYear'), this.get('nextMonth'));
      },
      showPrevYear: function showPrevYear() {
        this.transitionToRoute('main.collections.edit', this.get('model.journalId'), this.get('model.collection.path'), this.get('yearMinus'), this.get('model.month'));
      },
      showNextYear: function showNextYear() {
        this.transitionToRoute('main.collections.edit', this.get('model.journalId'), this.get('model.collection.path'), this.get('yearPlus'), this.get('model.month'));
      },

      showCurrentMonth: function showCurrentMonth() {
        var d = new Date();
        var currentYear = d.getFullYear();
        var currentMonth = d.getMonth() + 1;
        var journalId = this.get('model.journalId');
        var path = this.get('model.collection.path');

        this.transitionToRoute('main.collections.edit', journalId, path, currentYear, currentMonth);
      },

      upgrade: function upgrade(journalId) {
        this.transitionToRoute('main.subscription-products.list', {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      },

      track: function track(what) {
        var action = 'unknown';

        switch (what) {
          case 'save':
            action = _tracker.PhotoCollections.momentPicker.saveButton;
            break;
          case 'cancel':
            action = _tracker.PhotoCollections.momentPicker.cancelButton;
            break;
          default:
            break;
        }
        _tracker.Tracker.trackEvent({
          category: _tracker.PhotoCollections.category,
          screen: _tracker.PhotoCollections.momentPicker.screen,
          action: action
        });
      }
    }

  });
});