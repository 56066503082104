define('tinybeans-frontend/components/helpful-arrow', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // This component creates a helpful arrow to point to things
    text: '',
    arrange: 'left-of',
    pointTo: 'body',
    resizeHandler: null,
    imageURL: "images/sidePointer.png",
    currentOrder: 0,
    showWhenOrder: 0,
    showArrow: false,
    isVisible: false,

    showAsRequired: function () {
      this.set('isVisible', this.get('currentOrder') == this.get('showWhenOrder'));
    }.observes('currentOrder'),

    setupAsRequired: function () {
      if (this.get('isVisible')) {
        this.didInsertElement();
      }
    }.observes('isVisible'),

    // pointTo is a jquery selector
    // arrange is left-of, right-of, above or below  (where the arrow is in relation to element it points to)
    // text: the text to display
    setupPointer: function setupPointer(element, viewElement) {
      this.set('text', element.text);
      function afterRenderEvent() {
        var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (windowWidth < 1200 && (element.arrange == 'right-of' || element.arrange == 'left-of')) {
          element.arrange = 'above';
        }
        if (element.arrange == 'above' || element.arrange == 'below') {
          this.set('imageURL', 'images/downArrow.png');
        } else {
          this.set('imageURL', 'images/sidePointer.png');
        }
        if (Ember.$(element.pointTo).length > 0) {
          var pos = Ember.$(element.pointTo).offset();
          var imageLeftOffset = Ember.$(element.pointTo).width() / 2 - 25; // Half the images width and half the elements width
          var arrowOffsetTop = element.arrange == 'above' ? -92 : element.arrange == 'right-of' ? 15 : element.arrange == 'below' ? -92 - Ember.$(element.pointTo).height() : -70;
          var arrowOffsetLeft = element.arrange == 'above' ? imageLeftOffset : element.arrange == 'right-of' ? 35 : element.arrange == 'below' ? imageLeftOffset : -40;

          // Rotation
          var arrowRotation = element.arrange == 'above' ? 0 : element.arrange == 'right-of' ? 90 : element.arrange == 'below' ? 180 : 270;

          viewElement.find('.arrow-image').css({
            '-ms-transform': 'rotate(' + arrowRotation + 'deg)', /* IE 9 */
            '-webkit-transform': 'rotate(' + arrowRotation + 'deg)', /* Chrome, Safari, Opera */
            'transform': 'rotate(' + arrowRotation + 'deg)'
          });

          viewElement.find(".arrow-div").css({
            'top': pos.top + arrowOffsetTop + "px",
            'left': pos.left + arrowOffsetLeft + "px"
          });

          var textOffsetTop = element.arrange == 'above' ? -100 - viewElement.find(".helpful-arrow-text").height() / 2 : element.arrange == 'right-of' ? 67 - viewElement.find(".helpful-arrow-text").height() / 2 : element.arrange == 'below' ? 115 - viewElement.find(".helpful-arrow-text").height() / 2 : -52 - viewElement.find(".helpful-arrow-text").height() / 2;
          var textOffsetLeft = element.arrange == 'above' ? imageLeftOffset - viewElement.find(".helpful-arrow-text").width() / 2 : element.arrange == 'right-of' ? 115 : element.arrange == 'below' ? imageLeftOffset - viewElement.find(".helpful-arrow-text").width() / 2 : -70 - viewElement.find(".helpful-arrow-text").width();

          viewElement.find(".helpful-arrow-text").css({
            'position': 'absolute',
            'top': pos.top + textOffsetTop + 'px',
            'left': pos.left + textOffsetLeft + 'px'
          });
          if (this.get('isVisible')) {
            $("html, body").animate({ scrollTop: pos.top - 300 < 0 ? 0 : pos.top - 300 }, "fast");
          }
        } else {
          console.log(element.pointTo + " is an invalid selector");
        }
      }
      Ember.run.scheduleOnce('afterRender', this, afterRenderEvent);
    },

    willDestroyElement: function willDestroyElement() {
      Ember.$(window).unbind('resize', this.get('resizeHandler'));
    },

    didInsertElement: function didInsertElement() {
      var self = this;
      if (!self.get('showArrow')) {
        self.set('isVisible', false);
        return;
      }
      if (!self.get('currentOrder')) {
        self.set('currentOrder', 0);
      }
      this.set('isVisible', this.get('currentOrder') == this.get('showWhenOrder'));
      var resizeHandler = function resizeHandler() {
        if (self.get('lastElementWidth') != Ember.$(self.get('element')).width()) {
          self.didInsertElement();
        }
      };
      self.set('lastElementWidth', Ember.$(self.get('element')).width());
      self.set('resizeHandler', resizeHandler);
      Ember.$(window).bind('resize', self.get('resizeHandler'));
      // Get the absolute position of point-to
      var element = { pointTo: self.get('pointTo'), arrange: self.get('arrange'), text: self.get('text') };
      var viewElement = self.$();
      self.setupPointer(element, viewElement);
    },

    actions: {
      hideThis: function hideThis() {
        // Go to the next element in the list of helpers or just hide this
        var currentOrder = this.get('currentOrder');
        this.set('currentOrder', currentOrder + 1); // Go to the next element
      }
    }
  });
});