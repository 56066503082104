define("tinybeans-frontend/templates/main/refer-friend/show", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/refer-friend/show.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("style");
          var el2 = dom.createTextNode("\n    #banner,\n    #footer,\n    .premium-banner,\n    #navbar {\n      display: none!important;\n    }\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 85,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/refer-friend/show.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container refer-friend");
        dom.setAttribute(el1, "id", "refer-friend-page");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row text-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm-8 col-sm-offset-2 col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2");
        dom.setAttribute(el3, "style", "text-align:center; padding-top:20px;");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        var el5 = dom.createTextNode("Tinybeans rewards");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" show versionB");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        dom.setAttribute(el2, "id", "VB");
        var el3 = dom.createTextNode("\n          ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm-6 col-sm-offset-3 refer-form text-center");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createTextNode("Send out your unique referral link to friends, and when they sign up you both get free Premium.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "refer-friend-option text-center");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h5");
        var el6 = dom.createTextNode("Your unique referral link is:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5, "class", "text-center dont-break-out");
        dom.setAttribute(el5, "style", "line-height:120%; margin-bottom: 10px;");
        var el6 = dom.createElement("b");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "class", "btn btn-primary btn-lg VB");
        dom.setAttribute(el5, "id", "referEmailBtnVB");
        var el6 = dom.createTextNode(" Email my friends");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "how-works");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "href", "#");
        dom.setAttribute(el6, "id", "referFriendMoreDetailsBtnVB");
        dom.setAttribute(el6, "class", "VB");
        dom.setAttribute(el6, "style", "font-size:0.8em;");
        var el7 = dom.createTextNode("How it works");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n             ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm-6 col-sm-offset-3 text-center");
        var el4 = dom.createTextNode("\n             ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("Another way to help us spread the word");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n     ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n         ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm-6 col-sm-offset-3 text-center");
        var el4 = dom.createTextNode("\n                 ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", " refer-friend-option");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h5");
        dom.setAttribute(el5, "style", "font-weight:bold; font-size: 20px;");
        var el6 = dom.createTextNode("Post reviews");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("Post a review of Tinybeans in a parenting forum and send us a screenshot to get free Premium. ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "href", "#");
        dom.setAttribute(el6, "id", "postReviewSeeDetailsBtnVB");
        dom.setAttribute(el6, "class", "VB");
        var el7 = dom.createTextNode("See details.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "class", "btn btn-primary VB");
        dom.setAttribute(el5, "id", "emailScreenshotBtnVB");
        var el6 = dom.createTextNode("Email screenshot");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n         ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n     ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" show versionB");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" {{#if displayReferPopup}}\n    {{#modal-dialog title=\"Refer friend\" close='hideReferPopup' }}\n      <div class=\"row refer-form\" id=\"refer-form\">\n        <form role=\"form\">\n          <div class=\"form-group\">\n            <div class=\"col-md-10 col-md-offset-1\">\n              <p>You can gain access to awesome extra features by telling your friends about Tinybeans</p>\n              <div class=\"message-container\">\n                <div class=\"alert alert-success\" role=\"alert\" style=\"display:none;\">Hurrah! You've just referred a friend to Tinybeans.<br/> Why stop there? For every friend you refer, you'll get another month of Tinybean Premium.</div>\n                <div class=\"alert alert-danger\" role=\"alert\" style=\"display:none;\">Oops, there's an error, please try again.</div>\n              </div>\n            </div>\n            <div class=\"col-sm-10 col-sm-offset-1 refer-friend-form\">\n              <div class=\"input-group text-center\"> {{input id=\"referrralUserEmailAddress\" type=\"text\" class=\"form-control lg-input\" placeholder=\"Enter your friend's email address\"}} <span class=\"input-group-addon cursor-pointer btn-save\" {{action \"save\"}}>Send now</span></div>\n            </div>\n            <div class=\"col-sm-10 col-sm-offset-1 text-left\">\n              <p><br/><br/>Or simply copy and paste your unique link below and share with your friends<br/></p>\n            </div>\n            <div class=\"col-sm-10 col-sm-offset-1 copy-link-form\">\n              <div class=\"input-group text-center referralUrlContainer\">{{referralUrl}}<span class=\"input-group-addon cursor-pointer btn-copy\" id=\"basic-addon2\" {{action \"copyToClipboard\"}} style=\"border:none;\"><a href=\"#\" id=\"btn-copy\" class=\"link-mint btn-copy\">Copy link</a></span></div>\n            </div>\n          </div>\n        </form>\n      </div>\n    {{/modal-dialog}}\n  {{/if}} ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [5, 1, 3]);
        var element2 = dom.childAt(element1, [5]);
        var element3 = dom.childAt(element1, [7, 1]);
        var element4 = dom.childAt(element0, [9, 1, 1]);
        var element5 = dom.childAt(element4, [3, 1]);
        var element6 = dom.childAt(element4, [5]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 0]), 0, 0);
        morphs[2] = dom.createAttrMorph(element2, 'href');
        morphs[3] = dom.createElementMorph(element3);
        morphs[4] = dom.createElementMorph(element5);
        morphs[5] = dom.createAttrMorph(element6, 'href');
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "globals.isNative", ["loc", [null, [1, 6], [1, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [10, 7]]]], ["content", "referralUrl", ["loc", [null, [28, 102], [28, 117]]], 0, 0, 0, 0], ["attribute", "href", ["concat", ["mailto:?subject=Check%20this%20out%21&body=Hey%21%0A%0ATinybeans%20is%20the%20best%20way%20for%20you%20to%20share%20photos%20of%20your%20children%20and%20their%20milestones%20with%20family.%20It%20is%20also%20the%20easiest%20way%20to%20keep%20a%20record%20of%20the%20special%20moments%20you%20never%20want%20to%20forget.%20I%20thought%20you%27d%20like%20it.%0A%0AIt%27s%20a%20free%20app%2C%20but%20we%20will%20both%20get%20a%20month%20of%20Premium%20%28+%20extra%20features%29%20after%20you%20join.%0A%0ACheck%20it%20out%20here%3A%0A", ["get", "referralUrl", ["loc", [null, [29, 614], [29, 625]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["showDetailsPopup", "refer"], [], ["loc", [null, [31, 28], [31, 65]]], 0, 0], ["element", "action", ["showDetailsPopup", "screen"], [], ["loc", [null, [46, 125], [46, 163]]], 0, 0], ["attribute", "href", ["get", "emailScreenshot", ["loc", [null, [47, 82], [47, 97]]], 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  }());
});