define('tinybeans-frontend/components/entry-component', ['exports', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash', 'tinybeans-frontend/tracker', 'tinybeans-frontend/utils/bootstrap-dropdown', 'tinybeans-frontend/helpers/emoji'], function (exports, _commonsMixin, _lodash, _tracker, _bootstrapDropdown, _emoji) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend(_commonsMixin.default, {
    addingComment: false,
    editingDate: false,
    editedEntryDate: null,
    category: _tracker.Moments.category,
    screen: _tracker.Moments.entry.screen,
    editingTagged: false,
    queryParams: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.queryParams = _extends({}, this.get('model.queryParams'));
      var entry_id = this.get('model.entry_id');
      var uuid = this.get('model.uuid');
      var emoji = (0, _emoji.getEmoji)(this.queryParams.utm_content || '');
      if (emoji && entry_id && uuid) {
        this.set('details', emoji);
        this.showPostButton = true;
        this.set('addingComment', true);
      }
    },

    isForCurrentDay: function () {
      var entry = this.get('entry');
      return entry.get('day') == this.get('model.day') && entry.get('month') == this.get('model.month') && entry.get('year') == this.get('model.year');
    }.property('model.day', 'model.month', 'model.year', 'entry', 'entry.day', 'entry.month', 'entry.year'),

    journalHasChildrenOrPet: Ember.computed(function () {
      return !!this.get('model.journal.children.length') || !!this.get('model.journal.pets.length');
    }),

    dateFormat: function () {
      return this.get('model.me.dateFormat');
    }.property(),

    fromPrompt: Ember.computed('entry.checklistItem', function () {
      var checklistItem = this.get('entry.checklistItem');
      return checklistItem && checklistItem.get('checklist.type') === 'PROMPTS';
      // return checklistItem !== null;
    }),

    promptStyle: Ember.computed('entry.checklistItem', function () {
      var checklistItem = this.get('entry.checklistItem');
      if (checklistItem) {
        return 'padding: 10px; font-weight: 600; text-align: center; border-radius: 10px 10px 0 0;';
      }
      return null;
    }),

    showTagIconInToolbar: Ember.computed('entry.isPhoto', 'entry.isVideo', function () {
      return !this.get('entry.isPhoto') && !this.get('entry.isVideo');
    }),

    captionStyle: Ember.computed('entry.checklistItem', function () {
      var checklistItem = this.get('entry.checklistItem');
      if (checklistItem) {
        return 'padding: 10px; border-radius: 0 0 10px 10px;';
      }
      return null;
    }),

    displayDate: function () {
      var entry = this.get('entry');
      var date = moment().year(entry.get('year')).month(entry.get('month') - 1).date(entry.get('day'));
      return date.format('DD MMM YYYY');
    }.property(),

    avatar: Ember.computed('model.me', function () {
      return this.get('model.me') ? this.get('model.me.avatars.m') : '//tinybeans-public.s3-us-west-2.amazonaws.com/images/avatar-child-m.png';
    }),

    entryUser: Ember.computed(function () {
      return this.get('entry.u');
    }),

    canDeleteEntry: Ember.computed('model', 'entry', 'meAsFollower', 'isMyJournal', function () {
      return this.get('isMyJournal') || this.get('model.me.id') == this.get('entry.u.id') || this.get('meAsFollower.coOwner');
    }),

    canEditCaption: Ember.computed('model', 'entry', 'meAsFollower', 'isMyJournal', function () {
      var isMine = this.get('entry.u.id') == this.get('model.me.id');
      return isMine || this.get('isMyJournal') || this.get('meAsFollower.coOwner');
    }),

    canAddComment: Ember.computed('model', 'entry', 'meAsFollower', 'isMyJournal', function () {
      return this.get('isMyJournal') || this.get('model.me') && !this.get('model.journal.disableComments');
    }),

    canDownload: Ember.computed('model', 'entry', 'meAsFollower', 'isMyJournal', function () {
      // if (this.get('basicViewMode')) {
      //   return false;
      // }
      var isMine = this.get('entry.u.id') == this.get('model.me.id');

      return !this.get('model.journal.disableDownloads') || this.get('isMyJournal') || isMine || this.get('meAsFollower.coOwner');
    }),

    canToggleEmotion: Ember.computed('model', 'entry', 'meAsFollower', 'isMyJournal', function () {
      return this.get('isMyJournal') || this.get('meAsFollower');
    }),

    canViewComments: Ember.computed('model', 'entry', 'meAsFollower', 'isMyJournal', function () {
      return !this.get('model.journal.disableComments');
    }),

    canDeleteComment: function canDeleteComment(comment) {
      var isMine = this.get('model.me.id') && comment.get('user.id') == this.get('model.me.id');
      return this.get('isMyJournal') || isMine || comment.canDelete;
    },
    entryContainsChild: function entryContainsChild(child) {
      return this.get('entry.childIs') && this.get('entry.childIs').toArray().contains(child);
    },


    emotionsWithFirstName: Ember.computed('entry.emotions.@each', function () {
      return this.get('entry.emotions').toArray().filter(function (emotion) {
        return emotion.get('user.firstName') && !emotion.get('deleted');
      });
    }),

    emotionFromMe: Ember.computed('entry.emotions.@each', function () {
      var _this = this;

      return _lodash.default.find(this.get('entry.emotions').toArray(), function (emotion) {
        return emotion.get('user.id') == _this.get('model.me.id') && !emotion.get('deleted');
      });
    }),

    taggedMessageForMobile: Ember.computed('emotionsWithFirstName', 'entry.emotions', 'entry.emotions.@each', 'entry.emotions.@each.firstName', 'entry.emotions.@each.id', 'model.me.id', function () {
      var emotionsCount = this.get('entry.emotions.length');
      var emotionsWithFirstNameCount = this.get('emotionsWithFirstName.length');
      if (emotionsCount === 0) return '';
      if (emotionsCount === 1) {
        if (!emotionsWithFirstNameCount) {
          return emotionsCount + ' people';
        }
        if (this.get('emotionsWithFirstName.0.user.id') === this.get('model.me.id')) {
          return 'You';
        }
        return this.get('emotionsWithFirstName.0.user.firstName');
      }
      return emotionsCount + ' people';
    }),

    firstThreeEmotions: Ember.computed('emotionsWithFirstName', function () {
      var _this2 = this;

      var emotions = this.get('emotionsWithFirstName').toArray();
      return emotions.filter(function (_, i) {
        return !_this2.get('emotionsWithFirstName.' + i + '.deleted');
      }).slice(0, 3);
    }),

    firstTwoEmotions: Ember.computed('emotionsWithFirstName', function () {
      var _this3 = this;

      var emotions = this.get('emotionsWithFirstName').toArray();
      return emotions.filter(function (_, i) {
        return !_this3.get('emotionsWithFirstName.' + i + '.deleted');
      }).slice(0, 2);
    }),

    emotionsCountMinusTwo: Ember.computed('entry.emotions.@each', 'emotionsWithFirstName', function () {
      var emotionsWithFirstName = this.get('emotionsWithFirstName').toArray();
      // don't show number of likes if no one has name and last name
      if (!emotionsWithFirstName.length) {
        return 0;
      }
      var emotionsCount = this.get('entry.emotions').toArray().length;
      return emotionsCount > 2 ? emotionsCount - 2 : 0;
    }),

    getCommentAge: function getCommentAge(comment) {
      var postTime = comment.get('timestamp');
      var timeDifference = Date.now() - postTime;
      var timeDifferenceMoment = moment.duration(timeDifference);
      var timeDifferenceMinutes = timeDifferenceMoment.minutes();
      var timeDifferenceHours = timeDifferenceMoment.hours();
      var timeDifferenceDays = timeDifferenceMoment.days();
      var timeDifferenceWeeks = timeDifferenceMoment.weeks();
      var timeDifferenceMonths = timeDifferenceMoment.months();
      var timeDifferenceYears = timeDifferenceMoment.years();
      if (timeDifferenceYears >= 1) return timeDifferenceYears + 'y';
      if (timeDifferenceMonths >= 1) return timeDifferenceMonths + 'mo';
      if (timeDifferenceWeeks >= 1) return timeDifferenceWeeks + 'w';
      if (timeDifferenceDays >= 1) return timeDifferenceDays + 'd';
      if (timeDifferenceHours >= 1) return timeDifferenceHours + 'h';
      if (timeDifferenceMinutes >= 1) return timeDifferenceMinutes + 'm';
      return 'now';
    },
    getCommenterName: function getCommenterName(comment) {
      var user = comment.get('user');
      return user && user.fullName ? user.fullName : user.firstName ? user.firstName : 'Anonymous';
    },
    dateLabel: function dateLabel(comment) {
      var user = comment.get('user');

      //Bug fixes
      //old one won't work, it changes comment dates to current date (Today)
      //let dateStr = moment(comment.timestamp).format('DD MMM YYYY');

      //Use comment.get('timestamp') works
      var dateStr = moment(comment.get('timestamp')).format(this.get('dateFormat') || "DD MMM YYYY");

      return 'Posted by ' + (user && user.fullName ? user.fullName : user.firstName ? user.firstName : 'Anonymous') + ' on ' + dateStr;
    },


    commentCountLabel: Ember.computed('entry', 'entry.comments.@each', function () {
      var entry = this.get('entry');
      var count = _lodash.default.filter(entry.get('comments').toArray(), function (comment) {
        return !comment.get('deleted');
      }).length;
      return count == 1 ? '1' : count;
    }),
    emotionCountLabel: Ember.computed('entry.emotions.@each', function () {
      var entry = this.get('entry');
      var count = 0;
      if (entry.get('emotions')) count = _lodash.default.filter(entry.get('emotions').toArray(), function (emotion) {
        return !emotion.get('deleted');
      }).length;
      return count;
    }),

    children: Ember.computed('model.journal', 'entry', function () {
      var _this4 = this;

      var journal = this.get('model.journal');
      var entry = this.get('entry');
      var children = journal.get('children');
      return _lodash.default.map(children.toArray(), function (child) {
        return {
          id: child.id,
          avatars: child.avatars,
          entry: entry,
          tagged: _this4.isChildTagged(entry, child)
        };
      });
    }),

    pets: Ember.computed('model.journal', 'entry', function () {
      var _this5 = this;

      var journal = this.get('model.journal');
      var entry = this.get('entry');
      var pets = journal.get('pets');
      return _lodash.default.map(pets.toArray(), function (pet) {
        return {
          id: pet.id,
          avatars: pet.avatars,
          entry: entry,
          tagged: _this5.isPetTagged(entry, pet)
        };
      });
    }),

    singleEntryView: Ember.computed(function () {
      var singleEntryView = this.get('model.singleEntryView');
      if (singleEntryView) {
        return this.get('model.singleEntryView');
      }
      return null;
    }),

    actions: {
      toggleEmotion: function toggleEmotion() {
        if (this.get('hasAccessToJournal') || this.get('userToken')) {
          var myEmotion = this.get('emotionFromMe');
          var entry = this.get('entry');
          this.sendAction('toggleEmotion', entry, myEmotion);
          this.GTM.trackEvent({
            'eventCategory': _tracker.Moments.entry.screen,
            'eventAction': _tracker.Moments.actions.click,
            'eventLabel': _tracker.Moments.loveMomentButton
          });
        } else if (this.get('model.me')) {
          this.sendAction('displayError', this.get('i18n').t('global.error.notFollowing'));
        } else {
          this.sendAction('displayError', this.get('i18n').t('global.error.notLoggedIn'));
        }
      },

      triggerAddComment: function triggerAddComment() {
        if (this.get('hasAccessToJournal') || this.get('userToken')) {
          this.set('addingComment', true);
          this.GTM.trackEvent({
            'eventCategory': _tracker.Moments.entry.screen,
            'eventAction': _tracker.Moments.actions.click,
            'eventLabel': _tracker.Moments.leaveAComment
          });
        } else if (this.get('model.me')) {
          this.sendAction('displayError', this.get('i18n').t('global.error.notFollowing'));
        } else {
          this.sendAction('displayError', this.get('i18n').t('global.error.notLoggedIn'));
        }
      },

      copyLink: function copyLink(entry) {
        var entryLink = "https://tinybeans.com/app/#/main/entries/" + entry.get('id') + "/" + entry.get('uuid');
        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.copyLink
        });

        bootbox.confirm({
          title: "Copy this link",
          message: " <p><input class='form-control active' type='text' id='entryLink' value='" + entryLink + "' /></p>",
          buttons: {
            confirm: {
              label: "Copy",
              className: "copy-button btn-primary"
            },
            cancel: {
              label: "Close",
              className: "btn btn-big btn-gray-secondary btn-gray"
            }
          },
          callback: function callback(copy) {
            if (copy) {
              // Select the content
              document.querySelector("#entryLink").select();

              // Copy to the clipboard
              document.execCommand('copy');
              Ember.$('.copy-button.btn-primary').text("Copied");

              return false;
            }
          }
        });
      },

      addComment: function addComment() {
        var details = this.get('details');

        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.postComment
        });

        if (details) {
          var entry = this.get('entry');
          this.sendAction('addComment', entry, details);
          this.set('details', null);
          this.set('addingComment', false);
        } else {
          this.set('addingComment', false);
        }
      },
      checkTextareaContent: function checkTextareaContent(content) {
        this.set('showPostButton', !!content.length);
      },


      cancelAddComment: function cancelAddComment() {
        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.cancelAddComment
        });

        this.set('commentError', null);
        this.set('nameError', null);
        this.set('addingComment', false);
      },

      deleteComment: function deleteComment(comment) {
        var _this6 = this;

        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.deleteMoment
        });

        bootbox.dialog({
          title: 'Delete Comment',
          message: 'You are about to delete this comment. There is no undo. Are you sure?',
          onEscape: function onEscape() {},

          buttons: {
            ok: {
              label: 'OK',
              callback: function callback() {
                var entry = _this6.get('entry');
                _this6.sendAction('deleteComment', entry, comment);
              }
            },
            cancel: {
              label: 'Cancel',
              className: "btn-cancel",
              callback: function callback() {}
            }
          }
        });
      },


      editCaption: function editCaption() {
        var _this7 = this;

        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.editCaption
        });

        var entry = this.get('entry');
        var message = $('<div>');
        var errorDiv = $('<div class="help-block error">');
        var textarea = Ember.$('<textarea/>').addClass('form-control').val(entry.get('caption') !== 'Untitled' ? entry.get('caption') : '');
        message.append(textarea).append(errorDiv);
        bootbox.dialog({
          title: 'Please enter a caption',
          message: message,
          onEscape: function onEscape() {},
          buttons: {
            ok: {
              label: 'OK',
              className: 'btn-primary',
              callback: function callback() {
                var caption = $('.bootbox').find('textarea').val();
                if (caption.length > 10000) {
                  $('.bootbox').find('.error').text('Please use a caption less than 10,000 letters in length.');
                  return false;
                }
                entry.set('caption', Ember.$('.bootbox').find('textarea').val());
                entry.set('hasCaption', !!entry.get('caption'));
                _this7.sendAction('updateEntry', entry);
              }
            },
            cancel: {
              label: 'Cancel',
              className: "btn-default",
              callback: function callback() {}
            }
          }
        });
        setTimeout(function () {
          Ember.$('.bootbox').find('textarea').focus();
        }, 1000);
      },

      editTaggedChildren: function editTaggedChildren(entryID) {
        var _this8 = this;

        if (this.get('journalHasChildrenOrPet')) {
          var dropdownID = '#entry-tagged-dropdown-' + entryID;
          Ember.$(dropdownID).parent().on('hide.bs.dropdown', function () {
            _this8.set('editingTagged', false);
          });
          setTimeout(function () {
            _this8.set('editingTagged', true);
            (0, _bootstrapDropdown.openDropdown)(dropdownID);
          });
        }
      },
      editDate: function editDate() {

        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.editDate
        });

        this.set('editingDate', true);
        var entry = this.get('entry');
        var dateFormat = this.globals.usersDateFormat(this.get('model.me.dateFormat'));
        this.set('editedEntryDate', moment().year(entry.get('year')).month(entry.get('month') - 1).date(entry.get('day')).format(dateFormat));
      },
      cancelDateChange: function cancelDateChange() {
        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.cancelDateChange
        });

        this.set('editingDate', false);
      },
      saveDateChange: function saveDateChange() {
        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.saveDateChange
        });

        var entry = this.get('entry');
        var dateFormat = this.globals.usersDateFormat(this.get('model.me.dateStyle.format'));
        var timestamp = moment(this.get('editedEntryDate'), dateFormat);
        entry.set('day', timestamp.date());
        entry.set('month', timestamp.month() + 1);
        entry.set('year', timestamp.year());

        this.sendAction('updateEntry', entry);
        this.set('editingDate', false);
      },
      deleteEntry: function deleteEntry() {
        var _this9 = this;

        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.deleteEntry
        });

        bootbox.dialog({
          title: 'Delete moment',
          message: '<p style="text-align:center;"> <br/><span style="color:red; font-weight:bold;"><span class="glyphicon glyphicon-warning-sign" style="font-weight:3em;"></span><br/>Deleting this moment will permanently remove it from this journal for everyone. There is no undo.</span></p>',
          onEscape: function onEscape() {},
          buttons: {
            ok: {
              label: 'OK',
              callback: function callback() {
                var basicViewMode = _this9.get('basicViewMode');
                if (!basicViewMode) {
                  var entry = _this9.get('entry');
                  _this9.sendAction('deleteEntry', entry);
                }
              }
            },
            cancel: {
              label: 'Cancel',
              className: "btn-cancel",
              callback: function callback() {}
            }
          }
        });
      },
      pin: function pin(entry) {
        if (this.get('pinned')) {
          return;
        }
        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.pinMoment
        });

        entry.set('pinnedTimestamp', new Date().getTime());
        this.sendAction('updateEntry', entry);
      },


      // shareEntryOnFacebook(entry) {
      //   if (!this.get('facebookToken')) {
      //     this.API.loginToFacebook(data => {
      //       this.set('facebookToken', data.facebookAccessToken);
      //       this.API.shareEntryOnFacebook(this.get('model.journalId'), this.get('entry.id'), this.get('facebookToken'), function() {
      //         bootbox.dialog({
      //           title: 'Shared on facebook',
      //           message: 'This moment has been shared to facebook',

      //           buttons: {
      //             cancel: {
      //               label: 'Ok',
      //               className: "btn-default"
      //             }
      //           }
      //         });

      //         // entry.sharedOnFacebook.pushObject(true);
      //       }, this);
      //     });
      //   } else {
      //     this.API.shareEntryOnFacebook(entry.journalId, entry.id, this.get('facebookToken'), function() {
      //       entry.sharedOnFacebook.pushObject(true);
      //     }, this);
      //   }
      // },

      lightbox: function lightbox() {
        var entry = this.get('entry');
        Ember.$.fancybox.open([{
          href: entry.get('blobs.l')
        }]);
      },
      updateEntry: function updateEntry(entry) {
        this.sendAction('updateEntry', entry);
      },
      gotoDayView: function gotoDayView(journalId, year, month, day) {
        this.GTM.trackEvent({
          'eventCategory': _tracker.Moments.entry.screen,
          'eventAction': _tracker.Moments.actions.click,
          'eventLabel': _tracker.Moments.gotoDayView
        });

        Ember.$(window).scrollTop(0);
        this.sendAction('gotoDayView', journalId, year, month, day);
      }
    }

  });
});