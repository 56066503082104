define("tinybeans-frontend/mixins/page-view", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    defaultEvent: {
      event: "trackEvent",
      eventCategory: "",
      eventAction: "",
      eventLabel: "",
      eventValue: ""
    },

    defaultPageView: {
      event: "vpv",
      url: "",
      uid: "",
      premium: "",
      showAds: "",
      campaign: "",
      segment: ""
    },

    setJournalForPageViewTracking: function setJournalForPageViewTracking(journal) {
      this.set("journal", journal);
    },


    createPayload: function createPayload(type, o) {
      var data = o || {};
      var defaultPayload = this["default" + type];
      var payload = {};
      Object.keys(defaultPayload).forEach(function (key) {
        payload[key] = data.hasOwnProperty(key) ? data[key] : defaultPayload[key];
      });
      return payload;
    },

    trackEvent: function trackEvent(o) {
      window.dataLayer.push(this.createPayload("Event", o));
    },

    trackPageView: function trackPageView(path) {
      //check if current journal is premium, if not able to tell pass [na], not available;
      var journalUpgraded = this.get('model.me.hasMemoriesAccess');
      var journalShouldShowAd = false;

      //currently only 3 page could potentially have ads
      var pagesContainsAds = ["main.entries.list", "main.entries.view", "main.journals.month"];
      var pageCanHaveAdsUnit = pagesContainsAds.indexOf(path) != -1;

      if (this.get("journal") && this.get("journal.shouldShowAds") && pageCanHaveAdsUnit) {
        journalShouldShowAd = true;
      }
      var currentJournalPremium = journalUpgraded || journalShouldShowAd === false;
      var currentUserId = window.tb_userId;

      //if  prev segment cookie can be read, otherwise default ''
      var segmentName = "";
      //  segmentName = sessionStorage.getItem("segment");
      //   --------- FOR LOGIN AB TEST REMOVE AFTER TEST FINISHED ON 18 Feb 2019 ----------
      //  if(!currentUserId && path.indexOf("main.entries.view") != -1){
      //        // If user visit view page && they haven't login, clear old sessionSegment, set new one
      //       sessionStorage.removeItem('segment');
      //       segmentName =  this.setSegment();
      //       sessionStorage.setItem('segment',segmentName);

      //  }
      //--------- FOR LOGIN AB TEST REMOVE AFTER TEST FINISHED ON 18 Feb 2019 ----------

      var newPath = path + (!this.get("journal") ? "/[na]" : currentJournalPremium ? "/[premium]" : "/[nonPremium]");
      window.dataLayer.push(this.createPayload("PageView", {
        url: newPath,
        uid: currentUserId,
        premium: currentJournalPremium,
        showAds: journalShouldShowAd,
        segment: segmentName
      }));

      // SHE Media pageview tracking starts
      // Only send tracking when the page has shouldShowAds tag === true
      if (journalShouldShowAd) {
        if (blogherads) {
          blogherads.adq.push(function () {
            blogherads.trackPageView();
          });
        }
      }
      // SHE Media pageview tracking ends
    },

    pageviewToTagManager: function () {
      try {
        //this.trackPageView(this.get('url'));

        this.trackPageView(this.get("currentPath"));

        // Also use our own analytics tracker
        if (window.tb_userId) {
          var eventTracker = window.newEventTrackerWithUserId(window.tb_userId);
          eventTracker.trackPageView();
        }
      } catch (e) {}
    }.on("didTransition"),

    readCookie: function readCookie(key) {
      var value = document.cookie.match(new RegExp(key + "=([^;]+)")) || [];
      return decodeURIComponent(value[1] || "");
    },

    setSegment: function setSegment() {
      var newSegment = "";
      var journalIdStr = this.get("journal.id").toString();

      if (journalIdStr) {
        var lastIndex = journalIdStr.length - 1;
        newSegment = journalIdStr[lastIndex] == "2" ? "seenNewLogin" : "seenDefaultLogin";
      }
      return newSegment;
    }
  });
});