define('tinybeans-frontend/controllers/main/subscription-products/success', ['exports', 'tinybeans-frontend/services/lotame'], function (exports, _lotame) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {

      transitionToJournalRoute: function transitionToJournalRoute() {
        var self = this;
        var subscriptionProductName = this.get('planName');
        var surveyResponse = this.get('surveyResponse');
        var surveyMessage = "User purchased: (" + subscriptionProductName + "), the feedback is: " + surveyResponse;

        if (typeof AF !== "undefined") AF('pba', 'event', { eventType: 'EVENT', eventValue: { 'label': 'Convert to Paid' }, eventName: 'Convert to Paid' });

        localStorage.removeItem('currentPriceId');
        localStorage.removeItem('sessionId');

        // const behavior = 'beanstalk trial started';
        //Have to test in stagging to make sure it works.
        // const lotame = new Lotame()
        // lotame.sendBehavior(behavior, undefined, this.get("globals.userId"))
        if (surveyResponse) {
          this.API.submitPremiumFeedback(surveyMessage, function () {}, function () {});
          self.GTM.trackEvent({
            'eventCategory': 'premium_success_page',
            'eventAction': 'click',
            'eventLabel': 'submit_feedback_button'
          });

          this.transitionToRoute('main.journals.home');
        } else {
          this.transitionToRoute('main.journals.home');
        }
      }

    }

  });
});