define("tinybeans-frontend/templates/main/onboarding/step-five", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 12
            },
            "end": {
              "line": 64,
              "column": 12
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/onboarding/step-five.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "href", "/");
          dom.setAttribute(el1, "class", "tny-btn");
          var el2 = dom.createTextNode("Go to Tinybeans.com");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 66,
              "column": 12
            },
            "end": {
              "line": 68,
              "column": 12
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/onboarding/step-five.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "href", "https://www.hinata.tinybeans.com");
          dom.setAttribute(el1, "class", "tny-btn");
          var el2 = dom.createTextNode("Go to Tinybeans.com");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 12
            },
            "end": {
              "line": 72,
              "column": 12
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/onboarding/step-five.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "href", "https://www.tinybeans.com");
          dom.setAttribute(el1, "class", "tny-btn");
          var el2 = dom.createTextNode("Go to Tinybeans.com");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 91,
            "column": 6
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/onboarding/step-five.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n    #main-nav-wrapper, #footer { display:none;}\n    .progress-bar-container, #globalNotification {display:none!important;}\n    .content-container { padding-top:20px!important;}\n\n    .step-row{\n        margin-bottom: 15px;\n    }\n\n    .step-active{\n        background-color: #039398 !important;\n    }\n    \n    .step-indicator {\n        width: 100px;\n        height: 7.5px;\n        margin: 0 3.5px 0 0;\n        border-radius: 250px;\n        background-color: #dedee5;\n        display: inline-block;\n    }\n\n  @media screen and (max-width: 468px) {\n    .step-indicator {\n        width: 55px;\n        height: 7.5px;\n        margin: 0 3.5px 0 0;\n        border-radius: 250px;\n        background-color: #dedee5;\n        display: inline-block;\n    }\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row step-row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-12 text-center");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "step-indicator");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "step-indicator");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "step-indicator");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "step-indicator step-active");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        dom.setAttribute(el2, "style", "padding-bottom: 15px;");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-10 col-xs-offset-1 text-center");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "text-onboard-body");
        var el5 = dom.createTextNode("Step 5 of 5");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "text-onboard-head");
        var el5 = dom.createTextNode("Your account is all setup!");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-4 text-center");
        dom.setAttribute(el3, "style", "padding-bottom: 15px;");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "src", "https://d32onc8xm8gfkc.cloudfront.net/public/721cd503-d874-4a7a-abd5-02d72878ded2.png");
        dom.setAttribute(el4, "width", "250");
        dom.setAttribute(el4, "height", "250");
        dom.setAttribute(el4, "alt", "");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "text-subtitle-2");
        var el5 = dom.createTextNode("Start saving photos and videos of your family to memories");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "class", "tny-btn");
        var el5 = dom.createTextNode("Go to Memories");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      \n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-4 text-center");
        dom.setAttribute(el3, "style", "padding-bottom: 15px;");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "src", "https://d32onc8xm8gfkc.cloudfront.net/public/43f98aa7-5eb2-49dd-b587-177928320a1c.png");
        dom.setAttribute(el4, "width", "250");
        dom.setAttribute(el4, "height", "250");
        dom.setAttribute(el4, "alt", "");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "text-subtitle-2");
        var el5 = dom.createTextNode("Discover advice, things to do, and products tailored for your family");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            \n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            \n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n         \n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-4 text-center");
        dom.setAttribute(el3, "style", "padding-bottom: 15px;");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "src", "https://d32onc8xm8gfkc.cloudfront.net/public/8d0c52bc-a02a-49c8-a1a2-1f8a26720f31.png");
        dom.setAttribute(el4, "width", "250");
        dom.setAttribute(el4, "height", "250");
        dom.setAttribute(el4, "alt", "");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "text-subtitle-2");
        var el5 = dom.createTextNode("Continue managing your Tinybeans account settings");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "class", "tny-btn");
        var el5 = dom.createTextNode("Go to Account Settings");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row text-center");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "hidden-lg hidden-md hidden-sm");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5, "class", "text-subtitle-2");
        var el6 = dom.createTextNode("Our app makes sharing photo even easier.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "");
        dom.setAttribute(el5, "class", "tny-btn");
        var el6 = dom.createTextNode("Download the app");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "");
        var el6 = dom.createTextNode("Continue with the web version");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(element1, [1, 5]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element1, [5, 5]);
        var element5 = dom.childAt(element0, [9, 1, 1]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element5, [7]);
        var morphs = new Array(7);
        morphs[0] = dom.createElementMorph(element2);
        morphs[1] = dom.createMorphAt(element3, 5, 5);
        morphs[2] = dom.createMorphAt(element3, 7, 7);
        morphs[3] = dom.createMorphAt(element3, 9, 9);
        morphs[4] = dom.createElementMorph(element4);
        morphs[5] = dom.createElementMorph(element6);
        morphs[6] = dom.createElementMorph(element7);
        return morphs;
      },
      statements: [["element", "action", ["onboardingGotoNextDesktop"], [], ["loc", [null, [56, 31], [56, 70]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "isEnv", ["loc", [null, [62, 22], [62, 27]]], 0, 0, 0, 0], "local"], [], ["loc", [null, [62, 18], [62, 36]]], 0, 0]], [], 0, null, ["loc", [null, [62, 12], [64, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "isEnv", ["loc", [null, [66, 22], [66, 27]]], 0, 0, 0, 0], "staging"], [], ["loc", [null, [66, 18], [66, 38]]], 0, 0]], [], 1, null, ["loc", [null, [66, 12], [68, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "isEnv", ["loc", [null, [70, 22], [70, 27]]], 0, 0, 0, 0], "production"], [], ["loc", [null, [70, 18], [70, 41]]], 0, 0]], [], 2, null, ["loc", [null, [70, 12], [72, 19]]]], ["element", "action", ["onboardingGotoSettings"], [], ["loc", [null, [78, 31], [78, 67]]], 0, 0], ["element", "action", ["downloadApp"], [], ["loc", [null, [86, 43], [86, 68]]], 0, 0], ["element", "action", ["onboardingGotoNextMobile"], [], ["loc", [null, [87, 27], [87, 65]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});