define("tinybeans-frontend/templates/main/collections/list", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 10
                },
                "end": {
                  "line": 14,
                  "column": 10
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "collection-flash-message");
              var el2 = dom.createElement("span");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [1]);
              var element15 = dom.childAt(element14, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element14, 'class');
              morphs[1] = dom.createAttrMorph(element15, 'class');
              morphs[2] = dom.createMorphAt(element14, 2, 2);
              return morphs;
            },
            statements: [["attribute", "class", ["get", "flash.type", ["loc", [null, [13, 56], [13, 66]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "flash.glyphiconClass", ["loc", [null, [13, 83], [13, 103]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "flash.message", ["loc", [null, [13, 114], [13, 131]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 8
              },
              "end": {
                "line": 15,
                "column": 8
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "flash.listMessage", ["loc", [null, [12, 16], [12, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 10], [14, 17]]]]],
          locals: ["flash"],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 14
              },
              "end": {
                "line": 22,
                "column": 24
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["Collection.List_TitleOrganizeMoments"], [], ["loc", [null, [21, 28], [21, 72]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 24
              },
              "end": {
                "line": 24,
                "column": 24
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                        ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["Collection.List_Title"], [], ["loc", [null, [23, 28], [23, 57]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 12
              },
              "end": {
                "line": 28,
                "column": 12
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["Collection.List_Organize_Collection_Message"], [], ["loc", [null, [27, 14], [27, 65]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child4 = function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 12
              },
              "end": {
                "line": 30,
                "column": 12
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["Collection.List_Sub_Heading"], [], ["loc", [null, [29, 14], [29, 49]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child5 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 14
                },
                "end": {
                  "line": 37,
                  "column": 14
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "href", "#");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element13);
              morphs[1] = dom.createMorphAt(element13, 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["addCollection", ["get", "model.journalId", ["loc", [null, [36, 53], [36, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 28], [36, 71]]], 0, 0], ["inline", "t", ["Collection.Add_New_Collection"], [], ["loc", [null, [36, 72], [36, 109]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 16
                  },
                  "end": {
                    "line": 42,
                    "column": 16
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  | ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "href", "#");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element12 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element12);
                morphs[1] = dom.createMorphAt(element12, 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["toggleOrganizeMode"], [], ["loc", [null, [41, 23], [41, 54]]], 0, 0], ["inline", "t", ["Collection.List_Organize_Collection"], [], ["loc", [null, [41, 64], [41, 107]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 14
                },
                "end": {
                  "line": 43,
                  "column": 14
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "unless", [["get", "organizeMode", ["loc", [null, [40, 26], [40, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [40, 16], [42, 27]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 12
              },
              "end": {
                "line": 45,
                "column": 12
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "unless", [["get", "organizeMode", ["loc", [null, [35, 24], [35, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [35, 14], [37, 25]]]], ["block", "if", [["get", "collections", ["loc", [null, [39, 20], [39, 31]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [39, 14], [43, 21]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child6 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 57,
                      "column": 18
                    },
                    "end": {
                      "line": 72,
                      "column": 18
                    }
                  },
                  "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "col-lg-4 col-md-4 col-sm-6 text-center moment");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "collection");
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "collection-inner");
                  var el4 = dom.createTextNode("\n                          ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("a");
                  dom.setAttribute(el4, "href", "#");
                  var el5 = dom.createTextNode("\n                            ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("div");
                  dom.setAttribute(el5, "class", "collection-cover-overlay");
                  var el6 = dom.createTextNode("\n                            ");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                            ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("div");
                  dom.setAttribute(el5, "class", "collection-cover");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                          ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "row");
                  var el4 = dom.createTextNode("\n                          ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("h5");
                  dom.setAttribute(el4, "class", "collection-title");
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [1, 1]);
                  var element10 = dom.childAt(element9, [1, 1]);
                  var element11 = dom.childAt(element10, [3]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element9, 'id');
                  morphs[1] = dom.createElementMorph(element10);
                  morphs[2] = dom.createAttrMorph(element11, 'style');
                  morphs[3] = dom.createMorphAt(dom.childAt(element9, [3, 1]), 0, 0);
                  return morphs;
                },
                statements: [["attribute", "id", ["get", "collection.id", ["loc", [null, [59, 51], [59, 64]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["showCollection", ["get", "collection", ["loc", [null, [61, 64], [61, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [61, 38], [61, 76]]], 0, 0], ["attribute", "style", ["subexpr", "html-safe", [["get", "collection.coverUrl", ["loc", [null, [64, 51], [64, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [64, 72]]], 0, 0], 0, 0, 0, 0], ["content", "collection.name", ["loc", [null, [68, 55], [68, 74]]], 0, 0, 0, 0]],
                locals: ["collection"],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 14
                  },
                  "end": {
                    "line": 74,
                    "column": 14
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "id", "moments");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "collections", ["loc", [null, [57, 26], [57, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [57, 18], [72, 27]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 12
                },
                "end": {
                  "line": 76,
                  "column": 12
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "main/collections/delete-reorder", [], [], 0, null, ["loc", [null, [55, 14], [74, 50]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 78,
                    "column": 16
                  },
                  "end": {
                    "line": 91,
                    "column": 16
                  }
                },
                "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "col-lg-4 col-md-4 col-sm-6 text-center moment");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "collection");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "collection-inner");
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("a");
                dom.setAttribute(el4, "href", "#");
                var el5 = dom.createTextNode("\n                          ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", "collection-cover");
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                        ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "row");
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("h5");
                dom.setAttribute(el4, "class", "collection-title");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1, 1]);
                var element7 = dom.childAt(element6, [1, 1]);
                var element8 = dom.childAt(element7, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element6, 'id');
                morphs[1] = dom.createElementMorph(element7);
                morphs[2] = dom.createAttrMorph(element8, 'style');
                morphs[3] = dom.createMorphAt(dom.childAt(element6, [3, 1]), 0, 0);
                return morphs;
              },
              statements: [["attribute", "id", ["get", "collection.id", ["loc", [null, [80, 49], [80, 62]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["showCollection", ["get", "collection", ["loc", [null, [82, 62], [82, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [82, 36], [82, 74]]], 0, 0], ["attribute", "style", ["subexpr", "html-safe", [["get", "collection.coverUrl", ["loc", [null, [83, 49], [83, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [83, 70]]], 0, 0], 0, 0, 0, 0], ["content", "collection.name", ["loc", [null, [87, 53], [87, 72]]], 0, 0, 0, 0]],
              locals: ["collection"],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 76,
                  "column": 12
                },
                "end": {
                  "line": 93,
                  "column": 12
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "collections", ["loc", [null, [78, 24], [78, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [78, 16], [91, 25]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 10
              },
              "end": {
                "line": 99,
                "column": 10
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            dom.setAttribute(el1, "style", "display:none;");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "id", "selectedEntryId");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "organizeMode", ["loc", [null, [54, 18], [54, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [54, 12], [93, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child7 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 101,
                  "column": 12
                },
                "end": {
                  "line": 122,
                  "column": 12
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-lg-4 col-md-4 col-sm-6 text-center");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "collection");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "collection-cover cover-sample-1");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              dom.setAttribute(el3, "class", "collection-title");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-lg-4 col-md-4 col-sm-6 text-center");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "collection");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "collection-cover cover-sample-2");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              dom.setAttribute(el3, "class", "collection-title");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-lg-4 col-md-4 col-sm-6 text-center");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "collection");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "collection-cover cover-sample-3");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              dom.setAttribute(el3, "class", "collection-title");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 3]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 1, 3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["Collection.Cover_Title_1"], [], ["loc", [null, [105, 47], [105, 79]]], 0, 0], ["inline", "t", ["Collection.Cover_Title_2"], [], ["loc", [null, [112, 47], [112, 79]]], 0, 0], ["inline", "t", ["Collection.Cover_Title_3"], [], ["loc", [null, [119, 47], [119, 79]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 122,
                  "column": 12
                },
                "end": {
                  "line": 124,
                  "column": 12
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-lg-12 text-center");
              var el2 = dom.createTextNode(" No collection yet");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 100,
                "column": 10
              },
              "end": {
                "line": 126,
                "column": 10
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "canShare", ["loc", [null, [101, 18], [101, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [101, 12], [124, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child8 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 129,
                  "column": 12
                },
                "end": {
                  "line": 145,
                  "column": 12
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-lg-4 col-md-4 col-sm-6 text-center");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "collection cursor-pointer");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "collection-inner");
              var el4 = dom.createTextNode("\n\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "add-new-collection");
              var el5 = dom.createTextNode("\n                      ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5, "class", "glyphicon glyphicon-plus");
              var el6 = dom.createTextNode("\n                      ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("h5");
              dom.setAttribute(el4, "class", "collection-title");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element5);
              morphs[1] = dom.createMorphAt(dom.childAt(element5, [3, 1]), 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["addCollection", ["get", "model.journalId", ["loc", [null, [131, 80], [131, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [131, 55], [131, 98]]], 0, 0], ["inline", "t", ["Collection.Add_Collection"], [], ["loc", [null, [141, 49], [141, 82]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 128,
                "column": 10
              },
              "end": {
                "line": 146,
                "column": 10
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "canShare", ["loc", [null, [129, 18], [129, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [129, 12], [145, 19]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child9 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 150,
                  "column": 8
                },
                "end": {
                  "line": 156,
                  "column": 8
                }
              },
              "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "text-center");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "class", "btn btn-primary");
              dom.setAttribute(el2, "type", "button");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "href", "#");
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [1]);
              var element4 = dom.childAt(element2, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createElementMorph(element3);
              morphs[1] = dom.createMorphAt(element3, 0, 0);
              morphs[2] = dom.createElementMorph(element4);
              morphs[3] = dom.createMorphAt(element4, 1, 1);
              return morphs;
            },
            statements: [["element", "action", ["saveCollectionOrder"], [], ["loc", [null, [152, 58], [152, 90]]], 0, 0], ["content", "saveButtonLabel", ["loc", [null, [152, 91], [152, 110]]], 0, 0, 0, 0], ["element", "action", ["cancelCollectionOrder"], [], ["loc", [null, [153, 24], [153, 58]]], 0, 0], ["inline", "t", ["global.label.cancel"], [], ["loc", [null, [153, 60], [153, 87]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 149,
                "column": 6
              },
              "end": {
                "line": 157,
                "column": 6
              }
            },
            "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "organizeMode", ["loc", [null, [150, 14], [150, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [150, 8], [156, 15]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 162,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "collections-list");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "container");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          dom.setAttribute(el3, "id", "flashMessage");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-sm-8 col-sm-offset-2 col-md-10 col-md-offset-1 text-center");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("h3");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          dom.setAttribute(el5, "class", "sub_headings");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createTextNode("\n\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-sm-8 col-sm-offset-2 col-md-10 col-md-offset-1  text-center");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [1, 1]);
          var element17 = dom.childAt(element16, [3, 1]);
          var element18 = dom.childAt(element16, [5, 1]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element17, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element17, [3]), 1, 1);
          morphs[3] = dom.createMorphAt(dom.childAt(element17, [5]), 1, 1);
          morphs[4] = dom.createMorphAt(element18, 1, 1);
          morphs[5] = dom.createMorphAt(element18, 2, 2);
          morphs[6] = dom.createMorphAt(element18, 4, 4);
          morphs[7] = dom.createMorphAt(element16, 7, 7);
          return morphs;
        },
        statements: [["block", "each", [["get", "flashMessages.queue", ["loc", [null, [11, 16], [11, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 8], [15, 17]]]], ["block", "if", [["get", "organizeMode", ["loc", [null, [20, 20], [20, 32]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [20, 14], [24, 31]]]], ["block", "if", [["get", "organizeMode", ["loc", [null, [26, 18], [26, 30]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [26, 12], [30, 19]]]], ["block", "if", [["get", "canShare", ["loc", [null, [34, 18], [34, 26]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [34, 12], [45, 19]]]], ["block", "if", [["get", "collections", ["loc", [null, [53, 16], [53, 27]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [53, 10], [99, 17]]]], ["block", "if", [["get", "noCollectionYet", ["loc", [null, [100, 16], [100, 31]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [100, 10], [126, 17]]]], ["block", "unless", [["get", "organizeMode", ["loc", [null, [128, 20], [128, 32]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [128, 10], [146, 21]]]], ["block", "if", [["get", "collections", ["loc", [null, [149, 12], [149, 23]]], 0, 0, 0, 0]], [], 9, null, ["loc", [null, [149, 6], [157, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 162,
              "column": 0
            },
            "end": {
              "line": 169,
              "column": 0
            }
          },
          "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-offset-2 col-md-8 text-center");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          dom.setAttribute(el3, "class", "btn btn-primary btn-primary-reverse-outline btn-big");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["Collectin.Upgrade_Message"], [], ["loc", [null, [165, 9], [165, 42]]], 0, 0], ["element", "action", ["upgrade", ["get", "model.journalId", ["loc", [null, [166, 37], [166, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [166, 18], [166, 54]]], 0, 0], ["inline", "t", ["Collection.Upgrade_Button_Label"], [], ["loc", [null, [166, 115], [166, 154]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 170,
            "column": 0
          }
        },
        "moduleName": "tinybeans-frontend/templates/main/collections/list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n  .navbar-photo-collections {\n    font-weight: 400;\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "collectionEnabled", ["loc", [null, [7, 6], [7, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [7, 0], [169, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});