define('tinybeans-frontend/mixins/navigation', ['exports', 'lodash/lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),

    hasOwnJournal: function () {
      var journals = this.get('journals');
      var me = this.get('me');
      if (journals) {
        for (var i = 0; i < journals.get('length'); i++) {
          if (journals.objectAt(i).get('user') === me) {
            return true;
          }
        }
      }
      return false;
    }.property('me', 'journals.@each'),

    meAsFollower: Ember.computed('currentJournal', 'model.followings', function () {
      var _this = this;

      if (!this.get('model.followings')) {
        return null;
      }
      return _lodash.default.find(this.get('model.followings').toArray(), function (following) {
        return following.get('journal.id') === _this.get('currentJournal.id');
      });
    }),

    canManage: Ember.computed('currentJournal', function () {
      return this.get('model.me') && (this.get('currentJournal.user.id') === this.get('model.me.id') || this.get('meAsFollower') && this.get('meAsFollower.coOwner'));
    }),

    shouldShowAds: Ember.computed('model.ads', function () {
      if (this.get('model.ads.ads.shouldShowAds')) {
        console.log("Show nav ad");
        return true;
      } else {
        console.log("Don't show nav ad");
        return false;
      }
    }),

    checkPremium: Ember.computed('model.me', function () {
      return this.get('model.me.hasMemoriesAccess');
    }),

    isMobile: Ember.computed(function () {
      return window.isMobile;
    }),

    isEnv: Ember.computed(function () {
      var url = window.location.host;
      var env = "";
      if (url == "hinata.tinybeans.com") {
        env = "staging";
      } else if (url == "tinybeans.com") {
        env = "production";
      } else if (url == "localbeans.com") {
        env = "local";
      }
      return env;
    }),

    actions: {
      switchJournal: function switchJournal(journal) {
        this.send('closeSideNav');
        Ember.$('.spin').css('visibility', 'visible');
        Ember.$('table.calendar').css('visibility', 'hidden');

        if (this.get('session.isAuthenticated')) {
          this.transitionToRoute('main.journals.view', journal.get('id'));
        }
      },

      logout: function logout() {
        var _this2 = this;

        this.get('session').invalidate().then(function () {
          _this2.store.unloadAll();
          sessionStorage.clear();
          window.location = '/';
        });
      },

      closeSideNav: function closeSideNav() {
        Ember.$('.spin').show();
        this.send('gotoTopOfPage');

        Ember.$(".navbar-refer-a-friend").removeClass("active");
        Ember.$(".navbar-upgrade-to-premium").removeClass("active");
        Ember.$(".navbar-premium-features").removeClass("active");
        Ember.$(".navbar-photo-collections").removeClass("active");
        Ember.$(".navbar-export-moments").removeClass("active");
        Ember.$(".navbar-journal-details").removeClass("active");
        Ember.$(".navbar-family-friends").removeClass("active");
        Ember.$(".navbar-photobook").removeClass("active");
        Ember.$(".navbar-children").removeClass("active");
        Ember.$(".navbar-my-details").removeClass("active");
        Ember.$(".navbar-billing").removeClass("active");
        Ember.$(".navbar-store").removeClass("active");
        Ember.$(".navbar-start-journal").removeClass("active");

        Ember.$("#wrapper").removeClass("toggled");
        Ember.$('#trigger-layer').hide();
        Ember.$('#banner').show();
      },

      toggleSidebar: function toggleSidebar() {
        //  e.preventDefault();
        Ember.$("#wrapper").toggleClass("toggled");

        var hasToggled = Ember.$("#wrapper").hasClass("toggled");

        if (hasToggled) {
          Ember.$('#slide-show-control').hide();
          Ember.$('#trigger-layer').show();
        } else {
          Ember.$('#slide-show-control').show();
          Ember.$('#trigger-layer').hide();
        }
      },

      gotoTopOfPage: function gotoTopOfPage() {
        Ember.$("html, body").animate({
          scrollTop: 0
        }, "slow");
      }
    }
  });
});