define('tinybeans-frontend/services/rest-backend', ['exports', 'tinybeans-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend({
    lastRoutes: [],
    lastRouteObjects: [],

    LOAD: function LOAD(url, success, error) {
      url = _environment.default.apiEndPoint + url;
      var self = this;
      return Ember.$.ajax({
        url: url
      }).then(function (data) {
        if (success) {
          return success(data);
        }
        return data;
      }, function (xhr, textStatus, errorThrown) {
        if (error) {
          return error(xhr, textStatus, errorThrown);
        } else {
          //        todo handle errors more gracefully
          var status = xhr.status;
          if (self.lastRouteObjects[self.lastRouteObjects.length - 1]) {
            if (status === 404) {
              self.lastRouteObjects[self.lastRouteObjects.length - 1].transitionTo('server-error');
            } else if (status === 400 || status === 401 || status === 403) {
              self.lastRouteObjects[self.lastRouteObjects.length - 1].transitionTo('not-authorised');
            } else {
              self.lastRouteObjects[self.lastRouteObjects.length - 1].transitionTo('server-error');
            }
          }
          return undefined;
        }
      });
    },
    GET: function GET(url, _success, _error) {
      var bubble = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      url = _environment.default.apiEndPoint + url;
      var self = this;
      return Ember.$.ajax({
        type: 'get',
        url: url,
        global: bubble,
        //contentType: 'application/json',
        dataType: 'json',
        success: function success(data, statusText, xhr) {
          if (_success) {
            return _success.call(self, data, statusText, xhr);
          } else {
            return self.handleAjaxSuccess(data, statusText, xhr);
          }
        },
        error: function error(xhr, statusText, e) {
          if (_error) {
            _error.call(self, xhr, statusText, e);
          } else {
            self.handleAjaxError(xhr, statusText, e);
          }
        }
      });
    },
    uploadParts: function uploadParts(s3bucket, createdMultipartUpload, fileParts, curPart, completedParts, jsonData, fileKey, url, resolve, reject, resumableError, partProgressBarCallback) {
      var self = this;
      var params = void 0;
      if (!fileParts || curPart >= fileParts.length) {
        // Now assemble that completed upload
        params = {
          Bucket: createdMultipartUpload.Bucket,
          Key: createdMultipartUpload.Key,
          UploadId: createdMultipartUpload.UploadId,
          MultipartUpload: {
            Parts: completedParts
          }
        };
        s3bucket.completeMultipartUpload(params, function (err) {
          if (err) {
            console.log(err, err.stack); // an error occurred
            // Wait 5 seconds and attempt to resume
            Ember.run.later(function () {
              self.get('globals').set('uploadPaused', false);
              self.uploadParts(s3bucket, createdMultipartUpload, fileParts, curPart, completedParts, jsonData, fileKey, url, resolve, reject, resumableError, partProgressBarCallback);
            }, 5000);
            resumableError.call(err);
          } else {
            jsonData.remoteFileName = fileKey;
            self.POST(url, jsonData, resolve, reject);
          }
        });
        return;
      }
      //console.log("Uploading part " + curPart + " which has length " + fileParts[curPart].size);
      // Now with that object split the file up into parts and start uploading the parts
      params = {
        Bucket: createdMultipartUpload.Bucket,
        Key: createdMultipartUpload.Key,
        PartNumber: curPart + 1,
        UploadId: createdMultipartUpload.UploadId,
        Body: fileParts[curPart]
      };
      s3bucket.uploadPart(params, function (partErr, partData) {
        if (partErr) {
          console.log(partErr, partErr.stack); // an error occurred
          // Wait 5 seconds and attempt to resume
          Ember.run.later(function () {
            self.get('globals').set('uploadPaused', false);
            self.uploadParts(s3bucket, createdMultipartUpload, fileParts, curPart, completedParts, jsonData, fileKey, url, resolve, reject, resumableError, partProgressBarCallback);
          }, 5000);
          resumableError.call(partErr);
        } else {
          var part = {
            ETag: partData.ETag,
            PartNumber: curPart + 1
          };
          completedParts.push(part);
          curPart = curPart + 1; // Onto the next part
          self.uploadParts(s3bucket, createdMultipartUpload, fileParts, curPart, completedParts, jsonData, fileKey, url, resolve, reject, resumableError, partProgressBarCallback);
        }
      }).on('httpUploadProgress', function (evt) {
        var percentComplete = evt.loaded / evt.total;
        percentComplete = parseInt(percentComplete * 100);
        if ((typeof partProgressBarCallback === 'undefined' ? 'undefined' : _typeof(partProgressBarCallback)) === (typeof Function === 'undefined' ? 'undefined' : _typeof(Function))) {
          var partPercentComplete = (curPart / fileParts.length + percentComplete / 100 / fileParts.length) * 100;
          partProgressBarCallback(partPercentComplete);
        }
      });
    },


    // Todo: Should be a valid replacement for the POST function below
    amazonPostWithFile: function amazonPostWithFile(url, jsonData, file, resolve, reject, resumableError, fileProgressBarCallback) {
      var self = this;

      if (!file || !file.slice) {
        // If file.slice isn't supported by this browser (some older browsers)
        self.POSTWITHFILE(url, jsonData, resolve, reject, file, fileProgressBarCallback); // Non Amazon way
        return;
      }

      if (file) {
        // set the default config object
        AWS.config.region = 'us-east-1';
        AWS.config.update({
          credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: _environment.default.aws_identity
          })
        });
        var s3bucket = new AWS.S3({
          params: {
            Bucket: _environment.default.aws_bucket
          },
          httpOptions: {
            timeout: 500000
          }
        });
        var fileKey = uuid.v4() + file.name.slice(-5); // Take part of the filename as well
        var params = {
          Bucket: _environment.default.aws_bucket,
          Key: fileKey,
          ContentType: file.type
        };

        var fileParts = [];
        // Split the file into pieces
        var partSize = 5 * 1024 * 1024;
        var i = 0;
        while (i + partSize < file.size) {
          fileParts.push(file.slice(i, i + partSize));
          i += partSize;
        }
        fileParts.push(file.slice(i));

        s3bucket.createMultipartUpload(params, function (err, createdMultipartUpload) {
          if (err) {
            self.POSTWITHFILE(url, jsonData, resolve, reject, file, fileProgressBarCallback);
            //console.log(err, err.stack); // an error occurred
            //error.call(err);
          } else {
            // Call upload part here
            self.uploadParts(s3bucket, createdMultipartUpload, fileParts, 0, [], jsonData, fileKey, url, resolve, reject, resumableError, fileProgressBarCallback);
          }
        });
      } else {
        self.POST(url, jsonData, resolve, reject);
      }
    },


    // API ENDPOINT FOR ADD-A-PET
    updatePetPut: function updatePetPut(url, data, resolve, reject, file, fileProgressBarCallback) {
      url = _environment.default.apiEndPoint + url;
      var jsonString = JSON.stringify(data, null, 4);
      // JSONify data, add the file if required, perform a manual ajax query
      var formData = new FormData();
      formData.append('body', jsonString);
      if (file) {
        formData.append('file', file);
      }
      Ember.$.ajax({
        xhr: function xhr() {
          var xhr = new window.XMLHttpRequest();

          xhr.upload.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              percentComplete = parseInt(percentComplete * 100);
              if ((typeof fileProgressBarCallback === 'undefined' ? 'undefined' : _typeof(fileProgressBarCallback)) === (typeof Function === 'undefined' ? 'undefined' : _typeof(Function))) {
                fileProgressBarCallback(percentComplete);
              }
            }
          }, false);

          return xhr;
        },
        type: 'put',
        url: url,
        data: formData,
        // THIS MUST BE DONE FOR FILE UPLOADING
        contentType: false,
        processData: false
      }).then(function (data) {
        Ember.run(null, resolve, data);
      }, function (jqXHR) {
        jqXHR.then = null; // tame jQuery's ill mannered promises
        Ember.run(null, reject, jqXHR);
      });
    },


    // API ENDPOINT FOR ADD-A-PET
    addPetPost: function addPetPost(url, data, resolve, reject, file, fileProgressBarCallback) {
      url = _environment.default.apiEndPoint + url;
      var jsonString = JSON.stringify(data, null, 4);
      // JSONify data, add the file if required, perform a manual ajax query
      var formData = new FormData();
      formData.append('body', jsonString);
      if (file) {
        formData.append('file', file);
      }
      Ember.$.ajax({
        xhr: function xhr() {
          var xhr = new window.XMLHttpRequest();

          xhr.upload.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              percentComplete = parseInt(percentComplete * 100);
              if ((typeof fileProgressBarCallback === 'undefined' ? 'undefined' : _typeof(fileProgressBarCallback)) === (typeof Function === 'undefined' ? 'undefined' : _typeof(Function))) {
                fileProgressBarCallback(percentComplete);
              }
            }
          }, false);

          return xhr;
        },
        type: 'post',
        url: url,
        data: formData,
        // THIS MUST BE DONE FOR FILE UPLOADING
        contentType: false,
        processData: false
      }).then(function (data) {
        Ember.run(null, resolve, data);
      }, function (jqXHR) {
        jqXHR.then = null; // tame jQuery's ill mannered promises
        Ember.run(null, reject, jqXHR);
      });
    },


    // Todo: Should be a valid replacement for the POST function below
    POSTWITHFILE: function POSTWITHFILE(url, data, resolve, reject, file, fileProgressBarCallback) {
      url = _environment.default.apiEndPoint + url;
      var jsonString = JSON.stringify(data, null, 4);
      // JSONify data, add the file if required, perform a manual ajax query
      var formData = new FormData();
      formData.append('data', jsonString);
      if (file) {
        formData.append('file', file);
      }
      Ember.$.ajax({
        xhr: function xhr() {
          var xhr = new window.XMLHttpRequest();

          xhr.upload.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              percentComplete = parseInt(percentComplete * 100);
              if ((typeof fileProgressBarCallback === 'undefined' ? 'undefined' : _typeof(fileProgressBarCallback)) === (typeof Function === 'undefined' ? 'undefined' : _typeof(Function))) {
                fileProgressBarCallback(percentComplete);
              }
            }
          }, false);

          return xhr;
        },
        type: 'post',
        url: url,
        data: formData,
        // THIS MUST BE DONE FOR FILE UPLOADING
        contentType: false,
        processData: false
      }).then(function (data) {
        Ember.run(null, resolve, data);
      }, function (jqXHR) {
        jqXHR.then = null; // tame jQuery's ill mannered promises
        Ember.run(null, reject, jqXHR);
      });
    },
    POST: function POST(url, data, resolve, reject) {
      url = _environment.default.apiEndPoint + url;
      var jsonString = JSON.stringify(data, null, 4);
      Ember.$.ajax({
        type: 'post',
        url: url,
        data: jsonString,
        contentType: 'application/json',
        dataType: 'json'
      }).then(function (data) {
        Ember.run(null, resolve, data);
      }, function (jqXHR) {
        jqXHR.then = null; // tame jQuery's ill mannered promises
        Ember.run(null, reject, jqXHR);
      });
    },
    PUT: function PUT(url, success, error) {
      url = _environment.default.apiEndPoint + url;
      var self = this;
      return Ember.$.ajax({
        type: 'put',
        url: url,
        contentType: 'application/json',
        dataType: 'json'
      }).then(function (data) {
        if (success) {
          return success(data);
        }
        return data;
      }, function (xhr, textStatus, errorThrown) {
        if (error) {
          return error(xhr, textStatus, errorThrown);
        } else {
          //        todo handle errors more gracefully
          var status = xhr.status;
          if (self.lastRouteObjects[self.lastRouteObjects.length - 1]) {
            if (status === 404) {
              self.lastRouteObjects[self.lastRouteObjects.length - 1].transitionTo('server-error');
            } else if (status === 400 || status === 401 || status === 403) {
              self.lastRouteObjects[self.lastRouteObjects.length - 1].transitionTo('not-authorised');
            } else {
              self.lastRouteObjects[self.lastRouteObjects.length - 1].transitionTo('server-error');
            }
          }
          return undefined;
        }
      });
    },
    DELETE: function DELETE(url, _success2, _error2) {
      url = _environment.default.apiEndPoint + url;
      var self = this;
      Ember.$.ajax({
        type: 'delete',
        url: url,
        dataType: 'json',
        success: function success(data, statusText, xhr) {
          if (_success2) {
            _success2.call(self, data, statusText, xhr);
          } else {
            self.handleAjaxSuccess(data, statusText, xhr);
          }
        },
        error: function error(xhr, statusText, e) {
          if (_error2) {
            _error2.call(self, xhr, statusText, e);
          } else {
            self.handleAjaxError(xhr, statusText, e);
          }
        }
      });
    },
    handleAjaxSuccess: function handleAjaxSuccess() {},
    handleAjaxError: function handleAjaxError() {}
  });


  // String printf format
  if (!String.prototype.format) {
    String.prototype.format = function () {
      var args = arguments;
      return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] !== 'undefined' ? args[number] : match;
      });
    };
  }

  // String get query param
  if (!String.prototype.param) {
    String.prototype.param = function (key) {
      var url = this;
      var qIndex = url.indexOf('?');
      if (qIndex !== -1 && qIndex < url.length - 1) {
        if (!url.substr(qIndex + 1)) {
          return;
        }
        var params = url.substr(qIndex + 1).split('&');
        for (var i = 0; i < params.length; ++i) {
          var param = params[i].split('=');
          if (!params[i]) {
            return;
          }
          if (param.length === 1) {
            if (param === key) {
              return '';
            }
          } else if (param.length === 2) {
            if (param[0] === key) {
              return decodeURIComponent(param[1].replace(/\+/g, " "));
            }
          }
        }
      }
      return undefined;
    };
  }

  // String set query params
  if (!String.prototype.params) {
    String.prototype.params = function () {
      var url = this;
      if (arguments.length > 0) {
        // augmenting string
        var params = arguments[0];
        for (var key in params) {
          if (key && params.hasOwnProperty(key)) {
            var value = params[key];
            // todo hack - testing for value 'undefined' and 'null'
            if (value !== undefined && value !== 'undefined' && value !== 'null') {
              if (url.indexOf('?') === -1) {
                url += '?' + key + '=' + value;
              } else {
                url += '&' + key + '=' + value;
              }
            }
          }
        }
        return url;
      } else {
        // parsing string
        var result = {};
        var qIndex = url.indexOf('?');
        if (qIndex !== -1 && qIndex < url.length - 1) {
          var _params = url.substr(qIndex + 1).split('&');
          if (!url.substr(qIndex + 1)) {
            return;
          }
          for (var i = 0; i < _params.length; ++i) {
            if (!_params[i]) {
              return;
            }
            var param = _params[i].split('=');
            if (param.length === 1) {
              result[param] = '';
            } else if (param.length === 2) {
              var _key = param[0];
              if (_key) {
                result[_key] = decodeURIComponent(param[1].replace(/\+/g, " "));
              }
            }
          }
        }
        return result;
      }
    };
  }

  // Date now
  if (!Date.now) {
    Date.now = function () {
      return new Date().getTime();
    };
  }
});