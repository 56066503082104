define('tinybeans-frontend/components/main/entries/entry-pet', ['exports', 'lodash/lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNameBindings: ['divClass'],
    attributeBindings: ['src'],
    targetObject: Ember.computed.alias('parentView'),

    divClass: function () {
      var editingTagged = this.get('editingTagged');
      var petTagged = this.get('petTagged');
      var hiddenConditionCss = !editingTagged && !petTagged ? 'hidden' : '';
      var canToggleCss = this.canTogglePet() ? 'cursor-pointer' : '';
      return 'child-selector ' + hiddenConditionCss + ' ' + canToggleCss;
    }.property('petTagged', 'editingTagged'),

    taggedStyle: function () {
      var tagged = this.get('petTagged');
      var editingTagged = this.get('editingTagged');
      if (tagged && editingTagged) {
        return "border: 3px solid orange; height: 34px; width: 34px; margin-left: 0px; margin-top: -2px;";
      } else {
        return "";
      }
    }.property('petTagged', 'editingTagged'),

    entryTitle: function () {
      var pet = this.get('pet');
      return this.canTogglePet() ? 'Select to tag ' + pet.get('name') + ' in this moment' : '';
    }.property(),

    canTogglePet: function canTogglePet() {
      var isMyJournal = this.get('model.me.id') === this.get('model.journal.user.id');
      var isMine = this.get('entry.u.id') == this.get('model.me.id');
      return isMyJournal || isMine;
    },

    petTagged: Ember.computed('entry', 'entry.pets.@each', function () {
      var _this = this;

      if (!this.get('entry.pets')) return false;
      return _lodash.default.find(this.get('entry.pets').toArray(), function (pet) {
        return pet.id === _this.get('pet.id');
      });
    }),

    click: function click() {
      if (this.canTogglePet()) {
        if (this.get('petTagged')) this.get('entry.pets').removeObject(this.get('pet'));else this.get('entry.pets').addObject(this.get('pet'));

        this.sendAction('updateEntry', this.get('entry'));
      }
    }
  });
});