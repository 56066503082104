define('tinybeans-frontend/routes/i', ['exports', 'tinybeans-frontend/routes/base-route', 'tinybeans-frontend/tracker'], function (exports, _baseRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    session: Ember.inject.service('session'),
    category: _tracker.Followers.category,
    screen: _tracker.Followers.acceptInvite.screen,

    model: function model(params) {
      return Ember.RSVP.hashSettled({
        token: params.token,
        inviteDetails: this.API.getInvite(params.token)
      }).then(function (result) {
        return {
          token: result.token.value,
          inviteDetails: result.inviteDetails.value,
          fetching: true
        };
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var inviteDetails = model.inviteDetails;
      if (!inviteDetails || inviteDetails.deleted || inviteDetails.consumed) {
        this.GTM.trackEvent({
          'eventCategory': 'invite followers',
          'eventAction': 'capture invalid token',
          'eventLabel': !inviteDetails ? 'tokenNotExist' : 'tokenRedeemed'
        });
        _tracker.Tracker.trackEvent({
          action: 'capture invalid token',
          category: 'invite followers',
          screen: 'sms invite landing page'
        });
        this.transitionTo('main.invalid-invite-token');
      } else if (this.get('session.isAuthenticated')) {
        this.API.acceptInvite({ token: model.token }, function (data) {
          model.fetching = false;
          var date = new Date();
          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          _this.transitionTo('main.journals.month', data.follower.journalId, year, month, { queryParams: { invitationAccepted: true } });
        }, function () {
          model.fetching = false;
        });
      } else if (inviteDetails.invitee) {
        model.fetching = false;
        model.firstName = inviteDetails.invitee.firstName;
        model.lastName = inviteDetails.invitee.lastName;
      }
    }
  });
});