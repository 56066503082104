define('tinybeans-frontend/controllers/main/account/show', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash'], function (exports, _baseController, _commonsMixin, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {
    i18n: Ember.inject.service(),

    isActiveNavAccount: function isActiveNavAccount() {
      return true;
    },

    user: null,
    followings: null,
    avatar: null,
    timeZones: null,
    measurementSystems: null,
    dateStyles: null,
    emailFrequencyTypes: null,
    emailFrequencyTypesShort: null,
    submitting: false,
    password: null,
    newPassword: null,
    location: null,
    currentTab: '',
    currentHometown: null,

    init: function init() {
      var _this = this;

      Ember.run.schedule('afterRender', this, function () {
        _this.set('currentTab', 'myProfile');
      });
    },


    navOptions: Ember.computed(function () {
      var navOptions = ["My Profile", "My Family", "Payment", "Notification Preferences"];
      return navOptions;
    }),

    saveButtonLabel: function () {
      var submitting = this.get('submitting');
      return submitting ? this.get("i18n").t('global.label.wait') : this.get("i18n").t('global.label.save');
    }.property('submitting'),

    connectInstagramTitle: function () {
      return this.t('Account.Instagram_Title');
    }.property(),

    switchTab: function switchTab(name) {
      if (name !== this.get('currentTab')) {
        var oldTabs = document.getElementById(this.get('currentTab'));
        var tabName = document.getElementById(name);
        tabName.classList.add('active');
        oldTabs.classList.remove('active');
      }
      this.set('currentTab', name);
    },

    actions: {
      selectTimeZone: function selectTimeZone(value) {
        var selectedObj = this.get('model.timeZones').findBy('name', value);
        var tz = this.store.createRecord('nameLabel', selectedObj);
        this.set("model.user.timeZone", tz);
      },
      selectMeasurementSystem: function selectMeasurementSystem(value) {
        var selectedObj = this.get('model.measurementSystems').findBy('name', value);
        var ms = this.store.createRecord('nameLabel', selectedObj);
        this.set("model.user.measurementSystem", ms);
      },
      selectDateStyle: function selectDateStyle(value) {
        var selectedObj = this.get('model.dateStyles').findBy('name', value);
        var ds = this.store.createRecord('nameLabel', selectedObj);
        this.set("model.user.dateStyle", ds);
      },
      selectCommentEmailFrequency: function selectCommentEmailFrequency(value) {
        var selectedObj = this.get('model.emailFrequencyTypes').findBy('name', value);
        this.set("model.user.emailFrequencyOnNewComment", selectedObj);
      },
      selectEmotionEmailFrequency: function selectEmotionEmailFrequency(value) {
        var selectedObj = this.get('model.emailFrequencyTypes').findBy('name', value);
        this.set("model.user.emailFrequencyOnNewEmotion", selectedObj);
      },
      selectEventEmailFrequency: function selectEventEmailFrequency(value, component) {
        component.model.set("name", value); // Because we can't access that individual follower from the model
      },


      initAutocomplete: function initAutocomplete() {
        var _this2 = this;

        var hometownField = document.getElementById("hometown");
        var options = {
          strictBounds: false,
          types: ["geocode"]
        };

        var autocomplete = new google.maps.places.Autocomplete(hometownField, options);

        hometownField.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        google.maps.event.addListener(autocomplete, "place_changed", function () {
          var place = autocomplete.getPlace();
          var address = place.formatted_address;
          var value = address.split(",");
          var count = value.length;
          var country = void 0,
              state = void 0,
              city = void 0,
              z = void 0,
              postalCode = void 0;

          if (count === 3) {
            country = value[count - 1];
            state = value[count - 2];
            city = value[count - 3];
            z = state.split(" ");
            postalCode = z[2];
          } else {
            country = value[count - 1];
            city = value[count - 2];
          }

          var origin = 'USER_DEFINED';
          var latitude = place.geometry.location.lat();
          var longitude = place.geometry.location.lng();

          var currentLocation = {
            country: country,
            state: state,
            city: city,
            origin: origin,
            postalCode: postalCode,
            longitude: longitude,
            latitude: latitude
          };

          if (currentLocation) {
            if (!currentLocation.city) {
              _this2.set('location', currentLocation.state + ',' + currentLocation.country);
            } else if (!currentLocation.state) {
              _this2.set('location', currentLocation.country);
            } else if (!currentLocation.country) {
              _this2.set('location', currentLocation.city + ',' + currentLocation.state + ',' + currentLocation.country);
            }
          }

          _this2.set('currentHometown', currentLocation);
        });
      },

      save: function save() {
        var _this3 = this;

        var self = this;
        var newPassword = void 0;
        var file = null;
        var user = this.get('user');

        var _user$getProperties = user.getProperties('firstName', 'lastName', 'emailAddress'),
            firstName = _user$getProperties.firstName,
            lastName = _user$getProperties.lastName,
            emailAddress = _user$getProperties.emailAddress;

        var _getProperties = this.getProperties('password', 'passwordConfirm'),
            password = _getProperties.password,
            passwordConfirm = _getProperties.passwordConfirm;

        if (this.get('currentTab') === 'myProfile') {

          // Submit Hometown Field
          var data = this.get('currentHometown');
          if (data !== null) {
            // Homeown isnt required
            this.API.submitLocation(data, function (data, statusText, xhr) {
              self.set('errorSave', {});
              self.onApiSuccess(data, statusText, xhr);
            }, function (response) {
              self.onApiError(response);
              var errorSave = {
                hometown: response.status !== 200 ? "Something went wrong saving hometown, try again later." : undefined
              };
              _this3.set('errorSave', errorSave);
            });
          }

          // Submit isGeoLocation Allowed
          if (localStorage.getItem('geolocationAllowed') !== this.get('isLocationChecked').toString()) {
            this.API.allowGeoLocation(function (data, statusText, xhr) {
              self.set('errorSave', {});
              self.onApiSuccess(data, statusText, xhr);
            }, function (response) {
              self.onApiError(response);
              var errorSave = {
                location: response.status !== 'ok' ? "SomWething went wrong saving location information, try again later." : undefined
              };
              _this3.set('errorSave', errorSave);
            });
          }

          var passwordMismatch = (password || passwordConfirm) && password !== passwordConfirm;

          var error = {
            firstName: !firstName ? 'First Name is Required' : undefined,
            lastName: !lastName ? 'Last Name is Required' : undefined,
            emailAddress: !emailAddress ? 'Email Address is Required' : undefined,
            password: passwordMismatch ? this.t('Account.Password_Mismatch') : undefined
          };
          if (!firstName || !lastName || passwordMismatch) {
            this.set('error', error);
            this.onApiError();
            return;
          }
          if (password && password.length > 0) {
            newPassword = password;
            self.set('password', null);
            self.set('passwordConfirm', null);
          }
        }

        /**/
        self.set('submitting', true);
        if (this.get('currentTab') === 'myProfile') {
          // Avatar Upload
          file = Ember.$('input[type=file]')[0].files[0];

          // For each journal save email frequency
          var followings = this.model.followings;
          // followings.save();
          Ember.$.each(followings.toArray(), function (idx, following) {
            // following.save();
            self.API.updateFollowing(following.get('journal'), following, function () {});
          });
        }
        this.API.saveMe(file, this.model.user.serialize(), newPassword, function (data, statusText, xhr) {
          self.set('submitting', false);
          self.globals.dateFormat = self.get('model.user.dateFormat');
          self.set('error', {});
          self.onApiSuccess(data, statusText, xhr);
        }, function (response) {
          self.set('submitting', false);
          self.onApiError(response);
        });
      },

      instagramRemove: function instagramRemove() {
        var self = this;
        self.set('user.instagramId', '');
        self.set('user.instagramUsername', '');
        self.set('user.instagramAccessToken', '');
      },

      instagramConnect: function instagramConnect() {
        var self = this;

        hello('instagram').login({ response_type: 'code' }).then(function () {
          // Trigger a reload of the user
          hello('instagram').api('/me').then(function (r) {
            self.set('user.instagramId', r.id);
            self.set('user.instagramUsername', r.name);
            hello('instagram').logout(); // Clear the users local cookie/storage of this (only the backend needs it)
          });
        }, function (e) {
          console.log("Signin error: " + e.error.message);
        });
      },

      activate: function activate(name) {
        if (name !== this.get('currentTab')) {
          var oldTabs = document.getElementById(this.get('currentTab'));
          var tabName = document.getElementById(name);
          tabName.classList.add('active');
          oldTabs.classList.remove('active');
        }
        this.set('currentTab', name);
      },

      switchTabs: function switchTabs(name) {
        name = _lodash.default.camelCase(name);
        this.set('currentTab', name);
      },
      gotoAddBeans: function gotoAddBeans() {
        this.switchTab('myProfile');
        this.transitionToRoute("main.children.list", this.get('journalId'));
      },
      gotoFamily: function gotoFamily() {
        this.switchTab('myProfile');
        this.transitionToRoute("main.journals.follower-list", this.get('journalId'));
      },
      gotoBilling: function gotoBilling() {
        this.switchTab('myProfile');
        this.transitionToRoute("main.billing.show");
      },
      gotoBeanstalk: function gotoBeanstalk() {
        this.switchTab('myProfile');
        this.transitionToRoute("main.subscription-products.list");
      },
      billingInformation: function billingInformation() {
        this.switchTab('myProfile');
        this.API.billingSession().then(function (res) {
          window.location.replace(res.portalURL);
        });
      }
    }
  });
});