define('tinybeans-frontend/routes/main/gift-card/redeem-gift-card', ['exports', 'tinybeans-frontend/routes/base-protected-route'], function (exports, _baseProtectedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        me: this.store.find('user', 'me'),
        giftCode: undefined
      });
    },

    actions: {
      willTransition: function willTransition() {
        this.controller.set('disableFunc', this.controller.get('maxAttempt'));
      }
    }
  });
});