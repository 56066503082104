define('tinybeans-frontend/routes/signup', ['exports', 'tinybeans-frontend/routes/base-route', 'tinybeans-frontend/tracker'], function (exports, _baseRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    category: _tracker.Signup.category,
    screen: _tracker.Signup.screen,

    session: Ember.inject.service('session'),
    error: {},

    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('main.journals.home');
      }
    },

    model: function model(params) {
      var attemptedSignupLogin = document.cookie.indexOf("signup-first-name") >= 0;
      var firstName = readCookie("signup-first-name");
      var lastName = readCookie("signup-last-name");
      var emailAddress = readCookie("signup-email-address");
      var abTest = readCookie('signup-ab-test');
      writeCookie("signup-first-name", null, 0);
      writeCookie("signup-last-name", null, 0);
      writeCookie("signup-email-address", null, 0);
      writeCookie("signup-ab-test", null, 0);

      var campaign = readCookie("campaign");
      if (params._src) {
        campaign = params._src;
        writeCookie("campaign", campaign); // Keep track of campaign on refresh
      }

      var referrerCode = readCookie("referral-code");
      if (params.referralCode) {
        referrerCode = params.referralCode;
        writeCookie("referral-code", referrerCode); // Keep track of campaign on refresh
      }

      return Ember.RSVP.hash({
        user: {
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress,
          file: null,
          campaign: campaign || null,
          abTest: abTest,
          //passwordless
          sendResetPassword: true
          //passwordless
        },

        referrerCode: referrerCode,
        attemptedLogin: attemptedSignupLogin
      });
    },

    renderTemplate: function renderTemplate(controller, model) {
      var self = this;

      // self.render('loading');
      if (model.attemptedLogin) {
        self.API.insertUser(model.user, model.referrerCode, function (data) {
          self.get('session').authenticate('authenticator:token', data).then(function () {
            self.set('submitting', false);
            writeCookie("campaign", null, 0);

            var eventLabelText = model.referrerCode ? model.referrerCode : "no_referrer";

            //Send event tracking to GA as sign up conversion
            self.GTM.trackEvent({
              'eventCategory': 'signup',
              'eventAction': 'default_signup',
              'eventLabel': eventLabelText
            });
            if (typeof AF !== "undefined") AF('pba', 'event', { eventType: 'EVENT', eventValue: { 'label': 'Create TB account' }, eventName: 'Create TB account' });
            self.transitionTo('main.onboarding.step-start');
          }, function () {
            self.set('submitting', false);
            self.transitionTo('login');
          });
        }, function (xhr) {
          controller.set('submitting', false);
          controller.onApiError(xhr);
          self.render('signup');
        });
      } else {
        self.render('signup');
      }
    }
  });
});