define('tinybeans-frontend/models/journal', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    URL: (0, _attr.default)('string'),
    title: (0, _attr.default)('string', { defaultValue: '' }),
    timestamp: (0, _attr.default)('number'),
    disableComments: (0, _attr.default)('boolean'),
    disableEmotions: (0, _attr.default)('boolean'),
    disableAnonymousComments: (0, _attr.default)('boolean'),
    disableDownloads: (0, _attr.default)('boolean'),
    deleted: (0, _attr.default)('boolean'),
    shouldShowAds: (0, _attr.default)('boolean'),
    user: (0, _relationships.belongsTo)('user', { async: false }), // Must use async: false for embedded records
    children: (0, _relationships.hasMany)('child'),
    pets: (0, _relationships.hasMany)('pet'),
    // coverTheme: belongsTo('nameLabel', { async: false }),
    features: (0, _relationships.hasMany)('feature'),
    coverTheme: (0, _attr.default)(),
    flashbackEmail: (0, _attr.default)(),

    //currentSubscription: belongsTo('subscription'),
    //latestSubscription: belongsTo('subscription')

    isUpgraded: Ember.computed('features', function () {
      var premium = false;
      if (this.get('features')) {
        this.get('features').forEach(function (feature) {
          var scopeMap = JSON.parse(feature.get('scopeMap'));
          if (scopeMap['Tier'] && scopeMap['Tier'] === 'premium') {
            premium = true;
          }
        });
      }
      return premium;
    }),

    longTitle: Ember.computed('title', function () {
      return this.get('title.length') > 30 ? this.get('title').substring(0, 27) + "..." : this.get('title');
    }),

    mediumTitle: Ember.computed('title', function () {
      return this.get('title.length') > 23 ? this.get('title').substring(0, 20) + "..." : this.get('title');
    }),

    shortTitle: Ember.computed('title', function () {
      return this.get('title.length') > 16 ? this.get('title').substring(0, 13) + "..." : this.get('title');
    })
  });
});