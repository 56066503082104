define('tinybeans-frontend/models/article', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    timestamp: (0, _attr.default)('number'),
    lastUpdatedTimestamp: (0, _attr.default)('number'),
    sortOrder: (0, _attr.default)('number'),
    deleted: (0, _attr.default)('boolean'),
    checklistItemId: (0, _attr.default)('number'),
    article: (0, _attr.default)()
  });
});