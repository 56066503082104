define('tinybeans-frontend/components/alert-box', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    type: 'info',

    class: function () {
      return 'alert alert-' + this.get('type');
    }.property('type')
  });
});