define('tinybeans-frontend/routes/main/journals/month', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'lodash/lodash', 'tinybeans-frontend/tracker', 'tinybeans-frontend/config/environment'], function (exports, _baseProtectedRoute, _lodash, _tracker, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Calendar.category,
    screen: _tracker.Calendar.screen,

    model: function model(params) {
      var journalId = params.journal_id;
      var month = params.month;
      var year = params.year;

      return Ember.RSVP.hash({
        journalId: journalId,
        month: month,
        year: year,
        journals: this.modelFor('main').journals,
        myJournals: this.modelFor('main').myJournals,
        followings: this.modelFor('main').followings,
        me: this.store.find('user', 'me'),
        ads: this.API.getAdsStatus(journalId),
        journal: this.store.find('journal', journalId), // Will be cached most of the time
        entries: this.store.query('entry', {
          filter: {
            journalId: journalId,
            year: year,
            month: month
          }
        }),
        followers: this.store.query('follower', {
          filter: {
            journalId: journalId
          }
        }),
        completedChecklistItems: this.store.query('completedChecklistItem', {
          journalId: journalId
        }),
        pets: this.store.query('pet', {
          journalId: journalId
        }),
        collections: this.store.query('collection', {
          journalId: journalId
        }),
        numEntries: this.API.numEntries(journalId),
        setup: params.setup,
        forceToRefresh: params.forceToRefresh
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.entries = model.entries.toArray();
      model.checklistCountByChild = _lodash.default.countBy(model.completedChecklistItems.toArray(), function (item) {
        return item.get('child.id');
      });

      model.petMilestoneCount = 0;

      //let currentPageShouldShowAds = model.journal.get('shouldShowAds');
      //this.controllerFor('main').set('shouldShowAds', currentPageShouldShowAds);

      var currentPageShouldShowAds = this.get('shouldShowAds');
      this.controllerFor('main').set('shouldShowAds', currentPageShouldShowAds);

      this.controllerFor('main').set('currentJournal', model.journal);
      Ember.set(this.modelFor('main'), 'currentJournal', model.journal);

      if (!this.get('globals.dismissedNotifications.FOLLOWERLIST') && model.followers.get('length') > 1 && model.journal.get('user.id') === model.me.get('id')) {
        var myFollowerObject = model.followers.find(function (follower) {
          return follower.get('user.id') === model.me.get('id');
        });
        if (myFollowerObject) {
          if (!myFollowerObject.get('relationship')) {
            var self = this;
            var handler = function handler(self) {
              self.set('globals.dismissedNotifications.FOLLOWERLIST', true);
              self.transitionTo('main.journals.follower-list', model.journalId);
            };
            toastr.error('Click here to confirm your family’s relationship to your children and the access levels you have granted them.', 'Verify your family & friends', {
              onclick: handler,
              onCloseClick: function onCloseClick() {
                _this.set('globals.dismissedNotifications.FOLLOWERLIST', true);
              }
            });
          }
        }
      }

      this.set('globals.routeShouldShowAds', true);
    },


    setupController: function setupController(controller, model) {
      var self = this;
      self._super(controller, model);
      // Subscription LP Updated
      Leanplum.addVariablesChangedHandler(function () {
        var vars = Leanplum.getVariables();
        if (vars.Paywall) {
          var paywall = vars.Paywall;
          paywall = JSON.parse(paywall.products);
          controller.set('trialCopy', paywall.title);
          controller.set('trialFeatures', paywall.paidFeatures);
        } else {
          var paidFeatures = ['Unlimited video and photo storage', 'Private sharing of memories with friends and family', 'Free shipping on photo books', 'Events, advice, activities and more curated for your family'];
          controller.set('trialCopy', 'Try us risk-free for 30 days');
          controller.set('trialFeatures', paidFeatures);
        }
      });
      Ember.run.schedule("afterRender", this, function () {
        // Run any javascript functions such as ads here
        if (!Modernizr.emoji) {
          if (Ember.$('.emoji')) {
            Ember.$('.emoji').emoji(24);
          }
        }
        this.makeCellsSquare();
        Ember.$('table.calendar').css('visibility', 'visible');
        Ember.$('.spin').css('visibility', 'hidden');
      });

      this.controllerFor('main').set('model.currentJournal', model.journal);
    },

    makeCellsSquare: function makeCellsSquare() {
      var $table = Ember.$('table.square-cells');
      var size = $table.find('td').width();
      $table.find('td > .day').height(size - 4);
    },

    renderTemplate: function renderTemplate(controller, model) {
      var self = this;
      this.render();
      if (model.setup === 'true') {
        model.dialogTitle = 'Congratulations ' + model.me.get('firstName');
        this.render('setup_complete', {
          outlet: 'modal-dialog'
        });
      }
      Ember.$(window).on('resize', function () {
        self.makeCellsSquare();
      });

      Ember.run.next(function () {
        Ember.$(window).scrollTop(0);
        Ember.$("#onboarding-completed-message").delay(10000).fadeOut(4000);
      }, 200);
    },

    prevMonth: function prevMonth(month, year) {
      var date = new Date(year, month - 2, 1);
      return date.getMonth() + 1;
    },

    prevYear: function prevYear(month, year) {
      var date = new Date(year, month - 2, 1);
      return date.getFullYear();
    },

    nextMonth: function nextMonth(month, year) {
      var date = new Date(year, month, 1);
      return date.getMonth() + 1;
    },

    nextYear: function nextYear(month, year) {
      var date = new Date(year, month, 1);
      return date.getFullYear();
    },

    yearMinus: function yearMinus(year) {
      var date = new Date(year, 1, 1);
      return date.getFullYear() - 1;
    },

    yearPlus: function yearPlus(year) {
      var date = new Date(year, 1, 1);
      return date.getFullYear() + 1;
    },

    followersMinusOwner: function followersMinusOwner(followers) {
      if (followers) {
        return followers.slice(1, 4);
      }
    },

    onboardingStepCompleted: function onboardingStepCompleted(step1, step2, step3) {
      var completedSteps = 0;
      if (!step1) {
        completedSteps += 1;
      }
      if (!step2) {
        completedSteps += 1;
      }
      if (!step3) {
        completedSteps += 1;
      }

      return completedSteps;
    },

    onboardingCompleted: function onboardingCompleted(step1, step2, step3) {
      return step1 && step2 && step3;
    },

    showCompleteMessage: function showCompleteMessage(initialComplete, onboardingCompleted) {
      var showMessage = false;
      if (!initialComplete && onboardingCompleted) {
        this.globals.onboardingInitialComplete = true;
        showMessage = true;
      }
      return showMessage;
    },
    setupAds: function setupAds() {

      //Charles' suggested 25102016
      Ember.$(window).scrollTop(0);
      Ember.$("#onboarding-completed-message").delay(10000).fadeOut(4000);

      var hasCheckOutPremiumCookie = false;

      try {
        hasCheckOutPremiumCookie = checkHasShownCheckOutPremium();
        // console.log(hasReferFriendCookie);
      } catch (err) {
        //console.log("cookie read error");
      }

      if (hasCheckOutPremiumCookie) {
        Ember.$("#check-out-premium").hide();
      } else {
        Ember.$("#check-out-premium").show();
      }

      function checkHasShownCheckOutPremium() {

        //alreadyShownReferFriend
        var cookieName = "alreadyShownCheckOutPremium=";
        var ca = document.cookie.split(';');
        var foundCookie = false;
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }if (c.indexOf(cookieName) === 0) {
            return true;
          }
        }

        return foundCookie;
      }
    },
    renderBanner: function renderBanner() {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      var date = moment(new Date()).format("YYYY-MM-DD");
      var url = "https://cms.tinybeans.com/banners/banner.json";

      var current_url = window.location.host;
      if (current_url == "hinata.tinybeans.com") {
        url = "https://cms.tinybeans.com/banners/banner-test.json";
      } else if (current_url == "localbeans.com") {
        url = "https://cms.tinybeans.com/banners/banner-test.json";
      }

      fetch(url, requestOptions).then(function (response) {
        return response.json();
      }).then(function (result) {
        if (!_lodash.default.isEmpty(result)) {
          if (!moment(date).isAfter(result.validUntil)) {
            document.getElementById('notification-banner').classList.remove('notification-hide');
            if (result.onclick) {
              result.message = "<a id='banner-anchor' href=' '  target='_blank' rel='noopener noreferrer' >" + result.message + "</a>";
              document.getElementById("notification-text").innerHTML = result.message;
              document.getElementById("banner-anchor").href = result.onclick;
            } else {
              document.getElementById("notification-text").innerHTML = result.message;
            }
            document.getElementById("notification-banner").style.background = result.bgColor;
            document.getElementById("notification-text").style.color = result.fontColor;
            document.getElementById("banner-anchor").style.color = result.fontColor;
          }
        }
      }).catch(function (error) {
        return console.log('error', error);
      });
    },


    actions: {
      didTransition: function didTransition() {
        this._super();
        // this.renderBanner();
        Ember.run.schedule("afterRender", this, function () {
          // this.setupAds();
          if (!Modernizr.emoji) {
            if (Ember.$('.emoji')) {
              Ember.$('.emoji').emoji(24);
            }
          }
          this.makeCellsSquare();
          Ember.$('table.calendar').css('visibility', 'visible');
          Ember.$('.spin').css('visibility', 'hidden');
        });
      }
    }
  });
});