define('tinybeans-frontend/components/edit-pet-info', ['exports', 'tinybeans-frontend/mixins/commons-mixin'], function (exports, _commonsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component$exte;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend(_commonsMixin.default, (_Ember$Component$exte = {
    submitting: false,
    queryParams: ['childAdded'],
    measurementSystem: '',
    size: [],

    formattedBirthday: Ember.computed('model.pet.birthday', {
      get: function get() {
        return moment(this.get('model.pet.birthday'), 'YYYY-MM-DD').format(this.get('usersDateFormat'));
      },
      set: function set(key, value) {
        var formatted = moment(value, this.get('usersDateFormat')).format('YYYY-MM-DD');
        this.set('model.pet.birthday', formatted);
        return value;
      }
    }),

    formattedAdoption: Ember.computed('model.pet.adoptionDate', {
      get: function get() {
        return moment(this.get('model.pet.adoptionDate'), 'YYYY-MM-DD').format(this.get('usersDateFormat'));
      },
      set: function set(key, value) {
        var formatted = moment(value, this.get('usersDateFormat')).format('YYYY-MM-DD');
        this.set('model.pet.adoptionDate', formatted);
        return value;
      }
    }),

    saveButtonLabel: Ember.computed('submitting', function () {
      var submitting = this.get('submitting');
      return submitting ? this.t('global.label.wait') : this.t('global.label.save');
    })

  }, _defineProperty(_Ember$Component$exte, 'size', Ember.computed('userMeasurementSystem', 'model.pet', 'size', function () {
    var measurementSystem = this.get('userMeasurementSystem.name');
    this.set('measurementSystem', measurementSystem);

    var setSize = this.get('model.pet.size');
    if (this.get('model.pet.species') === 'DOG' || !this.get('editing')) {
      if (measurementSystem === 'METRIC') {
        this.set('size', ["Small (0-9 kg)", "Medium (9-23 kg)", "Large ( 23 kg+)"]);
      } else {
        this.set('size', ["Small (0-20 lbs)", "Medium (20-50 lbs)", "Large (50+ lbs)"]);
      }
    } else {
      if (measurementSystem === 'METRIC') {
        this.set('size', ["Small (0-4 kg)", "Medium (4-6 kg)", "Large ( 6 kg+)"]);
      } else {
        this.set('size', ["Small (0-8 lbs)", "Medium (9-13 lbs)", "Large (14+ lbs)"]);
      }
    }

    switch (setSize) {
      case "SMALL":
        this.get('model.pet').set('size', this.get('size')[0]);
        break;
      case "MEDIUM":
        this.get('model.pet').set('size', this.get('size')[1]);
        break;
      case "LARGE":
        this.get('model.pet').set('size', this.get('size')[2]);
        break;
      default:
        break;
    }
    return this.get('size');
  })), _defineProperty(_Ember$Component$exte, 'isDog', Ember.computed(function () {
    var species = this.get('model.pet.species');
    var isDog = "DOG" === species ? true : false;
    return isDog;
  })), _defineProperty(_Ember$Component$exte, 'isCat', Ember.computed(function () {
    var species = this.get('model.pet.species');
    var isCat = "CAT" === species ? true : false;
    return isCat;
  })), _defineProperty(_Ember$Component$exte, 'isOther', Ember.computed(function () {
    var species = this.get('model.pet.species');
    var isOther = "OTHER" === species ? true : false;
    return isOther;
  })), _defineProperty(_Ember$Component$exte, 'sex', Ember.computed(function () {
    var sex = ["Male", "Female", "Unsure"];
    var setSex = this.get('model.pet.sex');
    switch (setSex) {
      case "MALE":
        this.get('model.pet').set('sex', sex[0]);
        break;
      case "FEMALE":
        this.get('model.pet').set('sex', sex[1]);
        break;
      case "UNSURE":
        this.get('model.pet').set('sex', sex[2]);
        break;
      default:
        break;
    }
    return sex;
  })), _defineProperty(_Ember$Component$exte, 'userID', Ember.computed('model.journal.user.id', function () {
    var userID = this.get('model.journal.user.id');
    return userID;
  })), _defineProperty(_Ember$Component$exte, 'actions', {
    toggleType: function toggleType(value) {
      this.get('model.pet').set('species', value.toUpperCase());
      if (value === "Other") {
        this.set('isOther', true);
      } else {
        this.set('isOther', false);
      }

      if (!this.get('editing')) {
        if (value === 'Dog') {
          if (this.get('measurementSystem') === 'METRIC') {
            this.set('size', ["Small (0-9 kg)", "Medium (9-23 kg)", "Large ( 23 kg+)"]);
          } else {
            this.set('size', ["Small (0-20 lbs)", "Medium (20-50 lbs)", "Large (50+ lbs)"]);
          }
        } else {
          if (this.get('measurementSystem') === 'METRIC') {
            this.set('size', ["Small (0-4 kg)", "Medium (4-6 kg)", "Large ( 6 kg+)"]);
          } else {
            this.set('size', ["Small (0-8 lbs)", "Medium (9-13 lbs)", "Large (14+ lbs)"]);
          }
        }
      }

      this.get('model.pet').set('breed', "");
      this.get('model.pet').set('size', "");
      this.get('model.pet').set('sex', "");
      this.get('model.pet').set('birthday', "");
      this.get('model.pet').set('adoptionDate', "");
    },
    selectSize: function selectSize(value) {
      if (value.includes('Small')) {
        value = 'Small';
      }

      if (value.includes('Medium')) {
        value = 'Medium';
      }

      if (value.includes('Large')) {
        value = 'Large';
      }
      this.get('model.pet').set('size', value.toUpperCase());
    },
    selectSex: function selectSex(value) {
      this.get('model.pet').set('sex', value.toUpperCase());
    },
    deleteConfirm: function deleteConfirm() {
      var self = this;
      bootbox.dialog({
        title: this.t('Pet.Delete_Confirm_Dialog.Remove_Pet'),
        message: this.t('Pet.Delete_Confirm_Dialog.Remove_Pet_Text'),
        buttons: {
          ok: {
            label: 'OK',
            callback: function callback() {
              self.get('model.pet').destroyRecord().then(function () {
                self.sendAction('doTransition', 'main.children.list', self.get('model.journal.id'));
              });
            }
          },
          cancel: {
            label: this.t('Pet.Delete_Confirm_Dialog.Cancel'),
            className: "btn-cancel"
          }
        }
      });
    },
    save: function save(addMore) {
      var _this = this;

      this.set('submitting', true);
      var file = Ember.$('input[type=file]')[0].files[0];
      var journalId = this.get('model.journal.id');
      var error = void 0;
      var errorMessage = 'Sorry, this is required';

      if (this.get('editing')) {
        this.get('model.pet').set('sex', this.get('model.pet.sex').toUpperCase());

        if (this.get('model.pet.size')) {
          if (this.get('model.pet.size').includes('Small')) {
            this.get('model.pet').set('size', 'SMALL');
          }

          if (this.get('model.pet.size').includes('Medium')) {
            this.get('model.pet').set('size', 'MEDIUM');
          }

          if (this.get('model.pet.size').includes('Large')) {
            this.get('model.pet').set('size', 'LARGE');
          }
        }
      }

      if (this.get('model.pet.species') === 'OTHER') {
        this.get('model.pet').set('sex', 'UNSURE');
        this.get('model.pet').set('size', 'SMALL');
      }

      if (!this.get('model.pet.name')) {
        error = {};
        error.name = errorMessage;
      }

      if (!this.get('model.pet.species')) {
        this.get('model.pet').set('species', 'DOG');
      }

      if (!this.get('model.pet.sex')) {
        this.get('model.pet').set('sex', 'UNSURE');
      }

      if (!this.get('model.pet.size')) {
        this.get('model.pet').set('size', null);
      }

      if (!this.get('model.pet.birthday') || this.get('model.pet.birthday') === 'Invalid date') {
        error = error || {};
        error.birthday = errorMessage;
      }

      if (!this.get('model.pet.adoptionDate')) {
        if (moment(this.get('model.pet.adoptionDate')).isBefore(this.get('model.pet.birthday'))) {
          errorMessage = 'Adoption day cannot be prior to Birthday';
          error = error || {};
          error.adoptDate = errorMessage;
        }
      }

      if (this.get('model.pet.adoptionDate') === 'Invalid date') {
        errorMessage = 'Invalid Adoption date';
        error = error || {};
        error.adoptDate = errorMessage;
      }

      if (error) {
        this.set('error', error);
        this.set('submitting', false);
        return;
      }

      var d = new Date();
      var month = d.getMonth() + 1;
      var year = d.getFullYear();

      this.get('model.pet').save({
        adapterOptions: {
          file: file,
          journalId: journalId
        }
      }).then(function (response) {
        if (_this.onSave) _this.onSave();
        _this.sendAction('displayNotification', 'Pet <b>' + response.get('name') + '</b> was added successfully!');
        if (_this.get('setup')) {
          if (addMore) {
            _this.sendAction('reloadPage');
          } else {
            _this.sendAction('doTransition', 'main.journals.month', journalId, year, month, {
              queryParams: {
                setup: _this.get('setup') || false
              }
            });
          }
        } else {
          _this.sendAction('doTransition', 'main.children.list', journalId);
        }
        _this.set('submitting', false);
      }, function () {
        // this.onApiError(errors);
        _this.set('submitting', false);
      });
    }
  }), _Ember$Component$exte));
});