define('tinybeans-frontend/routes/main/journals/list', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseProtectedRoute.default.extend({
        category: _tracker.Journals.category,
        screen: _tracker.Journals.screen,

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            // Setup myJournals
            var myJournals = [];
            model.journals.forEach(function (journal) {
                if (journal.get('user').id === model.me.id) {
                    myJournals.push(journal);
                }
            });

            controller.set('myJournals', myJournals);
            controller.set('hasOwnJournal', model.myJournals && model.myJournals.length > 0);
        }
    });
});