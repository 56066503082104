define('tinybeans-frontend/serializers/channel-subscription', ['exports', 'tinybeans-frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      channel: { embedded: 'always' }
    }
  });
});