define('tinybeans-frontend/models/channel', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    URL: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    sortOrder: _emberData.default.attr('string'),
    featureImageUrl: _emberData.default.attr('string'),
    thumbnailImageUrl: _emberData.default.attr('string'),
    deleted: _emberData.default.attr('boolean'),
    mandatory: _emberData.default.attr('boolean'),
    defaulted: _emberData.default.attr('boolean'),
    checklists: (0, _relationships.hasMany)('checklist')
  });
});