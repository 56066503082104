define('tinybeans-frontend/routes/main/onboarding/step-start', ['exports', 'tinybeans-frontend/routes/base-protected-route', 'tinybeans-frontend/tracker'], function (exports, _baseProtectedRoute, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseProtectedRoute.default.extend({
    category: _tracker.Onboarding.category,
    screen: _tracker.Onboarding.stepStart.screen,

    model: function model() {
      return Ember.RSVP.hash({
        journals: this.store.findAll('journal')
      });
    },
    afterModel: function afterModel(model, transition) {
      var journal = model.journals.objectAt(0);
      if (journal) {
        this.router.transitionTo('main.onboarding.step-three', journal.id);
      }
    },


    setupController: function setupController(controller, model) {

      this._super(controller, model);

      var journal = model.journals.objectAt(0);
      if (journal) {
        controller.set('journalId', journal.id);
      }
    },

    renderTemplate: function renderTemplate() {
      this.render('main/onboarding/step-start', { controller: 'main.onboarding.step-start' });
    },

    actions: {
      didTransition: function didTransition() {}
    }

  });
});