define('tinybeans-frontend/controllers/main/collections/list', ['exports', 'tinybeans-frontend/controllers/base-controller', 'tinybeans-frontend/mixins/commons-mixin', 'lodash/lodash', 'tinybeans-frontend/tracker'], function (exports, _baseController, _commonsMixin, _lodash, _tracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend(_commonsMixin.default, {

    saveButtonLabel: Ember.computed('submitting', function () {
      var submitting = this.get('submitting');
      return submitting ? 'Please wait ...' : 'Save changes';
    }),

    collections: Ember.computed('model.collections', function () {
      var collections = _lodash.default.map(this.get('model.collections').toArray(), function (collection) {
        if (collection.get('deleted')) return null;
        var cover = collection.get('pinnedTagEntry.entry.blobs.s2') || collection.get('latestEntry.entry.blobs.s2') || collection.get('earliestEntry.entry.blobs.s2') || 'images/collection-default-cover.gif';
        collection.set('coverUrl', 'background:url(\'' + cover + '\') center center no-repeat;');

        //trim collection title
        if (collection.get('name').length > 35) {
          collection.set('name', collection.get('name').substring(0, 35) + "...");
        }

        return collection;
      });

      collections = _lodash.default.compact(collections);

      return collections.toArray().sort(function (a, b) {
        return b['sortOrder'] - a['sortOrder'];
      });
    }),

    track: function track(action) {
      _tracker.Tracker.trackEvent({
        category: _tracker.PhotoCollections.category,
        screen: _tracker.PhotoCollections.list.screen,
        action: action
      });
    },


    actions: {

      gotoTopOfPage: function gotoTopOfPage() {
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
      },

      showCollection: function showCollection(collection) {
        var self = this;
        var journalId = collection.get('journal.id');
        var path = collection.get('path');
        self.transitionToRoute('main.collections.show', journalId, path);
        this.track(_tracker.PhotoCollections.list.openCollectionDetailsButton);
      },

      upgrade: function upgrade(journalId) {
        this.transitionToRoute('main.subscription-products.list', {
          queryParams: {
            journalid: journalId,
            overviewOnly: false
          }
        });
      },

      addCollection: function addCollection(journalId) {
        var self = this;
        self.send("gotoTopOfPage");
        self.transitionToRoute('main.collections.add', journalId);
        this.track(_tracker.PhotoCollections.list.addPhotoCollectionButton);
      },

      toggleOrganizeMode: function toggleOrganizeMode() {
        var self = this;
        var organizeMode = self.get('organizeMode');
        if (organizeMode) {
          self.set('organizeMode', false);
        } else {
          self.set('organizeMode', true);
          this.track(_tracker.PhotoCollections.list.openOrganizeCollectionButton);
        }
      },

      cancelCollectionOrder: function cancelCollectionOrder() {
        var self = this;
        self.send("reloadApplicationRoute");
        self.route.refresh();
      },

      saveCollectionOrder: function saveCollectionOrder() {
        var self = this;
        var journalId = self.get('model.journalId');
        var orderedCollections = [];

        Ember.$('#moments .collection').each(function () {
          var id = Ember.$(this).attr('id');
          orderedCollections.push(parseInt(id));
        });

        self.set('submitting', true);
        self.API.saveCollectionsOrder(journalId, orderedCollections, function () {
          // success
          Ember.get(self, 'flashMessages').clearMessages();
          Ember.get(self, 'flashMessages').add({
            message: 'Album order saved.',
            timeout: 3000,
            listMessage: true,
            addMessage: true,
            type: 'alert alert-success'
          });
          self.set('submitting', false);
          self.set('organizeMode', false);
          self.send("reloadApplicationRoute");
          self.route.refresh();
        }, function () {
          //fail
          self.set('submitting', false);
          self.set('organizeMode', false);
          Ember.get(self, 'flashMessages').clearMessages();
          Ember.get(self, 'flashMessages').add({
            message: 'Oops, couldn\'t save album order.',
            timeout: 3000,
            listMessage: true,
            addMessage: true,
            type: 'alert alert-warning'
          });
        });
      }

    }

  });
});