define('tinybeans-frontend/adapters/application', ['exports', 'ember-data/adapters/rest', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-data'], function (exports, _rest, _dataAdapterMixin, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _rest.default.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:customHeaders',
    namespace: 'api/1',

    handleResponse: function handleResponse(status, headers, payload) {
      if (payload.status === "error" || payload.status === "exception") {
        payload.statusCode = status;
        return new _emberData.default.AdapterError([payload]);
      }
      return this._super.apply(this, arguments);
    }
  });
});