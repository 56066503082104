define('tinybeans-frontend/components/drop-zone', ['exports', 'ui-dropzone/components/drop-zone'], function (exports, _dropZone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dropZone.default;
    }
  });
});